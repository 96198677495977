<template>
  <div class="wechatStatistics">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="业绩统计" name="achievement">
        <div class="nav">
          <div class="batchName">
            加粉方式：
            <el-select
              v-model="achievementForm.addFansMode"
              clearable
              multiple
              collapse-tags
              placeholder="请选择加粉方式"
            >
              <el-option
                v-for="item in sourceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="callTime">
            时间范围：
            <el-date-picker
              v-model="achievementForm.gmtCreatedDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              align="right"
              style="margin-right: 20px"
            ></el-date-picker>
          </div>
        </div>
        <div class="nav">
          <div class="batchName">
            服务器：
            <i style="margin-left: 17px"></i>
            <el-select
              v-model="wechatClientGroupIdServer"
              placeholder="请选择服务器"
              @change="wechatClientGroupFocus(wechatClientGroupIdServer)"
              clearable
            >
              <el-option
                v-for="item in wechatServerList"
                :key="item.server"
                :label="item.server"
                :value="item.server"
              >
              </el-option>
            </el-select>
          </div>
          <div class="batchName">
            机器分组：
            <el-select
              v-model="addWechatClientGroupId"
              placeholder="请选择机器分组"
              clearable
              :disabled="!wechatClientGroupIdServer"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in wechatClientGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="operate">
          <el-button type="primary" @click="achievementTnquire(1)"
            >查询</el-button
          >
          <el-button
            type="success"
            @click="downloadAchievement"
            :disabled="downloadAchievementDis"
            >导出</el-button
          >
        </div>
        <!-- 业绩统计列表 -->
        <el-table
          :data="
            achievementTableData.slice(
              (this.achievementPageNo - 1) * this.achievementPageSize,
              (this.achievementPageNo - 1) * this.achievementPageSize +
                this.achievementPageSize
            )
          "
          stripe
          style="width: 100%"
          border=""
          v-loading="loadingTable"
          :summary-method="getSummaries"
          show-summary
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="operator"
            label="跟进运营"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="fanCount"
            label="进粉量"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>进粉量</span>
              <el-tooltip
                class="item"
                effect="light"
                content="通过好友申请数量"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="chatCount"
            label="互动量"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>互动量</span>
              <el-tooltip
                class="item"
                effect="light"
                content="客户标签为L的数量"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="payCount"
            label="支付量"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>支付量</span>
              <el-tooltip
                class="item"
                effect="light"
                content="报名科目数量"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="passRateLabel"
            label="通过率"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>通过率</span>
              <el-tooltip
                class="item"
                effect="light"
                content="进粉量/(已提交好友申请+通过好友申请)"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="chatRateLabel"
            label="互动率"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>互动率</span>
              <el-tooltip
                class="item"
                effect="light"
                content="L的量/进粉量"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="payRateLabel"
            label="支付率"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>支付率</span>
              <el-tooltip
                class="item"
                effect="light"
                content="支付次数/进粉量"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="achievementPageSizeChange"
          @current-change="achievementCurrentPageNo"
          :current-page.sync="achievementPageNo"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="achievementTableData.length"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="转粉统计" name="fans">
        <div class="nav">
          <div class="batchName">
            加粉方式：
            <el-select
              v-model="fansForm.addFansMode"
              clearable
              placeholder="请选择加粉方式"
            >
              <el-option
                v-for="item in sourceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="callTime">
            时间范围：
            <el-date-picker
              v-model="fansForm.gmtCreatedDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              align="right"
              style="margin-right: 20px"
            ></el-date-picker>
          </div>
        </div>
        <div class="operate">
          <el-button type="primary" @click="fansInquire(1)">查询</el-button>
          <el-button
            type="success"
            @click="downloadFans"
            :disabled="downloadFansDis"
            >导出</el-button
          >
        </div>
        <!-- 转粉统计列表 -->
        <el-table
          :data="
            fansTableData.slice(
              (this.fansPageNo - 1) * this.fansPageSize,
              (this.fansPageNo - 1) * this.fansPageSize + this.fansPageSize
            )
          "
          stripe
          style="width: 100%"
          border=""
          v-loading="loadingTable"
          :summary-method="getSummariesFans"
          show-summary
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="operator"
            label="跟进运营"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="payPersonCount"
            label="支付人数"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="vipFanCount"
            label="转粉人数"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>转粉人数</span>
              <el-tooltip
                class="item"
                effect="light"
                content="标记为已转粉的人数"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="vipPayRateLabel"
            label="转粉执行率"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>转粉执行率</span>
              <el-tooltip
                class="item"
                effect="light"
                content="转粉人数/支付人数"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="addFanCount"
            label="新增粉丝量"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="unsubscribeCount"
            label="删粉量"
            min-width="150"
            align="center"
          >
            <template slot="header">
              <span>当日删粉量</span>
              <el-tooltip
                class="item"
                effect="light"
                content="S的人数"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="unsubscribeRateLabel"
            label="删粉率"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="totalFanCount"
            label="累计粉丝量"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="totalUnsubscribeCount"
            label="累计删粉人数"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="totalUnsubscribeRateLabel"
            label="累计删粉率"
            min-width="150"
            align="center"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="fansPageSizeChange"
          @current-change="fansCurrentPageNo"
          :current-page.sync="fansPageNo"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="fansTableData.length"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: "wechatStatistics",
  data() {
    return {
      wechatClientGroupIdServer: "",
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
      ],
      addWechatClientGroupId: [],
      wechatClientGroupList: [],
      downloadAchievementDis: true,
      downloadFansDis: true,
      fansTableTotal: {},
      achievementListTotal: {},
      activeName: "achievement",
      loadingTable: false,
      achievementTableData: [],
      achievementPageNo: 1,
      achievementPageSize: 10,
      achievementTotal: 0,
      fansTableData: [],
      fansPageNo: 1,
      fansPageSize: 10,
      fansTotal: 0,
      achievementForm: {
        addFansMode: [],
        gmtCreatedDate: [],
      },
      fansForm: {
        addFansMode: "",
        gmtCreatedDate: [],
      },
      sourceList: [
        {
          label: "挂机微信",
          value: "HANGUP_WECHAT",
        },
        {
          label: "坐席沟通",
          value: "CLIENT_TALKING_WECHAT",
        },
        {
          label: "挂机短信点击",
          value: "HANGUP_SMS_CLICK_WECHAT",
        },
        {
          label: "手动加粉",
          value: "MANUEL_WECHAT",
        },
        {
          label: "盲加家长粉",
          value: "FLAG_WECHAT",
        },
        {
          label: "YX加粉",
          value: "UN_FLAG_WECHAT",
        },
        {
          label: "AI机器人",
          value: "ROBOT_WECHAT",
        },
        {
          label: "二次加粉",
          value: "NOTIFY_WECHAT",
        },
        {
          label: "短信回调加粉",
          value: "SMS_CLICK_WECHAT",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-微信统计";
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.achievementForm.gmtCreatedDate = [start, end];
    this.fansForm.gmtCreatedDate = [start, end];
    this.theWeek();
    this.achievementTnquire(1);
  },
  methods: {
    // 机器分组获取焦点
    wechatClientGroupFocus(val) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      this.addWechatClientGroupId = [];
      this.getWechatClientGroupList(data);
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatClientGroupList = list;
        }
      });
    },
    // 选择tab
    handleClick(tab) {
      if (tab.name === "achievement") {
        this.achievementTnquire(1);
      }
      if (tab.name === "fans") {
        this.fansInquire(1);
      }
    },
    // 查询业绩列表
    achievementTnquire(index) {
      if (index) {
        this.achievementPageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.achievementForm.gmtCreatedDate) {
        gmtCreatedStart = this.achievementForm.gmtCreatedDate[0];
        gmtCreatedEnd = this.achievementForm.gmtCreatedDate[1];
      }
      let data = {
        // pageNo: this.achievementPageNo,
        // pageSize: 10,
        source: this.achievementForm.addFansMode.join(","),
        wechatServer: this.wechatClientGroupIdServer,
        wechatGroupName: this.addWechatClientGroupId.join(","),
        start: gmtCreatedStart,
        end: gmtCreatedEnd,
      };
      this.downloadAchievementDis = true;
      this.loadingTable = true;
      this.$http("/wechat/report/performance", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.list;
            let summary = res.data.summary;
            list.forEach((item) => {
              item.passRateLabel = (item.passRate * 100).toFixed(2) + "%";
              item.chatRateLabel = (item.chatRate * 100).toFixed(2) + "%";
              item.payRateLabel = (item.payRate * 100).toFixed(2) + "%";
            });
            if (list.length > 0) {
              this.downloadAchievementDis = false;
            }
            this.achievementTableData = list;
            this.achievementListTotal = summary;
            this.achievementListTotal.passRateLabel =
              (summary.passRate * 100).toFixed(2) + "%";
            this.achievementListTotal.chatRateLabel =
              (summary.chatRate * 100).toFixed(2) + "%";
            this.achievementListTotal.payRateLabel =
              (summary.payRate * 100).toFixed(2) + "%";
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 业绩表---每页显示
    achievementPageSizeChange(pageSize) {
      this.achievementPageSize = pageSize;
      this.achievementPageNo = 1;
      // this.achievementTnquire(1);
    },
    // 业绩表---翻页
    achievementCurrentPageNo(pageNo) {
      // this.achievementTnquire(pageNo);
    },
    // 业绩表---合计行
    getSummaries(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        for (let i in this.achievementListTotal) {
          if (i == column.property) {
            arr[index] = this.achievementListTotal[i];
          }
        }
      });
      return arr;
    },
    // 查询转粉列表
    fansInquire(index) {
      if (index) {
        this.fansPageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.fansForm.gmtCreatedDate) {
        gmtCreatedStart = this.fansForm.gmtCreatedDate[0];
        gmtCreatedEnd = this.fansForm.gmtCreatedDate[1];
      }
      let data = {
        // pageNo: this.fansPageNo,
        // pageSize: 10,
        source: this.fansForm.addFansMode,
        start: gmtCreatedStart,
        end: gmtCreatedEnd,
      };
      this.downloadFansDis = true;
      this.loadingTable = true;
      this.$http("/wechat/report/fanInfoStatistics", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.list;
            let summary = res.data.summary;
            list.forEach((item) => {
              item.vipPayRateLabel = (item.vipPayRate * 100).toFixed(2) + "%";
              item.unsubscribeRateLabel =
                (item.unsubscribeRate * 100).toFixed(2) + "%";
              item.totalUnsubscribeRateLabel =
                (item.totalUnsubscribeRate * 100).toFixed(2) + "%";
            });
            if (list.length > 0) {
              this.downloadFansDis = false;
            }
            this.fansTableData = list;
            this.fansTableTotal = summary;
            this.fansTableTotal.vipPayRateLabel =
              (summary.vipPayRate * 100).toFixed(2) + "%";
            this.fansTableTotal.unsubscribeRateLabel =
              (summary.unsubscribeRate * 100).toFixed(2) + "%";
            this.fansTableTotal.totalUnsubscribeRateLabel =
              (summary.totalUnsubscribeRate * 100).toFixed(2) + "%";
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 转粉表---每页显示
    fansPageSizeChange(pageSize) {
      this.fansPageSize = pageSize;
      // this.fansInquire(1);
    },
    // 转粉表---翻页
    fansCurrentPageNo(pageNo) {
      // this.fansInquire(pageNo);
    },
    // 转粉表---合计行
    getSummariesFans(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        for (let i in this.fansTableTotal) {
          if (i == column.property) {
            arr[index] = this.fansTableTotal[i];
          }
        }
      });
      return arr;
    },
    // 点击导出--业绩表
    downloadAchievement() {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.achievementForm.gmtCreatedDate) {
        gmtCreatedStart = this.achievementForm.gmtCreatedDate[0];
        gmtCreatedEnd = this.achievementForm.gmtCreatedDate[1];
      }
      let data = {
        source: this.achievementForm.addFansMode.join(","),
        wechatServer: this.wechatClientGroupIdServer,
        wechatGroupName: this.addWechatClientGroupId.join(","),
        start: gmtCreatedStart,
        end: gmtCreatedEnd,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        this.$constants.baseURL + "/wechat/report/performance/export?" + url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      });
    },
    // 点击导出--转粉表
    downloadFans() {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.fansForm.gmtCreatedDate) {
        gmtCreatedStart = this.fansForm.gmtCreatedDate[0];
        gmtCreatedEnd = this.fansForm.gmtCreatedDate[1];
      }
      let data = {
        source: this.fansForm.addFansMode,
        start: gmtCreatedStart,
        end: gmtCreatedEnd,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href =
        this.$constants.baseURL +
        "/wechat/report/fanInfoStatistics/export?" +
        url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      });
    },
    // 每周
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav {
  margin-bottom: 20px;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
</style>

