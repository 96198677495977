import Vue from "vue";
import VueRouter from "vue-router";
// 一级菜单
import Login from "@/components/Login";
import Menu from "@/components/Menu";
// 二级菜单
import PassengerNum from "@/components/menu/PassengerNum";
import PassengerSelect from "@/components/menu/PassengerSelect";
import SMSmarketing from "@/components/menu/SMSmarketing";
import MarketingRecord from "@/components/menu/MarketingRecord";

import EquipmentManager from "@/components/menu/EquipmentManager";
import MachineCalling from "@/components/menu/MachineCalling";
import GroupList from "@/components/menu/GroupList";
import ImportMac from "@/components/menu/ImportMac";
import PersonalCenter from "@/components/menu/PersonalCenter";

import UserManagement from "@/components/menu/UserManagement";
import Client from "@/components/menu/Client";
import Team from "@/components/menu/Team";
import Project from "@/components/menu/Project";
import Call from "@/components/menu/Call";
import Route from "@/components/menu/Route";
import CustomService from "@/components/menu/CustomService";
import Task from "@/components/menu/Task";
import Monitor from "@/components/menu/Monitor";
import question from "@/components/menu/question";
import UserList from "@/components/menu/UserList";
import AnswerList from "@/components/menu/AnswerList";
import questionTemplate from "@/components/menu/questionTemplate";
import callbackList from "@/components/menu/callbackList";
import customerState from "@/components/menu/customerState";
import roleList from "@/components/menu/roleList";
import analysisReport from "@/components/menu/analysisReport";
import latentGuest from "@/components/menu/latentGuest";
import UserBlacklist from "@/components/menu/UserBlacklist";
import labelManage from "@/components/menu/labelManage";
import clientCallReport from "@/components/menu/clientCallReport";
import bill from "@/components/menu/bill";
// import menuManagement from '@/components/menu/menuManagement'
import lineAnalysis from "@/components/menu/lineAnalysis";
import reordDownload from "@/components/menu/recordingdown";
import connectionAnalysis from "@/components/menu/connectionAnalysis";
import prologueManage from "@/components/menu/prologueManage";
import singleDeduction from "@/components/menu/singleDeduction";
import serverManage from "@/components/menu/serverManage";
import prologueList from "@/components/menu/prologueList";
import costStatistics from "@/components/menu/costStatistics";
import wechat from "@/components/menu/wechat";
import machineList from "@/components/menu/machineList";
import requestLogList from "@/components/menu/requestLogList";
import gameDataAnalysis from "@/components/menu/gameDataAnalysis";
import wechatStatistics from "@/components/menu/wechatStatistics";

import subjectGroup from "@/components/menu/subjectGroup";
import wechatSubjectGroup from "@/components/menu/wechatSubjectGroup";
import alarmGroup from "@/components/menu/alarmGroup";
import qualityTestList from "@/components/menu/qualityTestList";
import achievementList from "@/components/menu/achievementList";
import setTemplate from "@/components/menu/setTemplate";
import mechanismStatistics from "@/components/menu/mechanismStatistics";
import tripartiteData from "@/components/menu/tripartiteData";
import wechatLabel from "@/components/menu/wechatLabel";
import otherSet from "@/components/menu/otherSet";
import informationSet from "@/components/menu/informationSet";
import operationRecord from "@/components/menu/operationRecord";
import functionSwitch from "@/components/menu/functionSwitch";
import commonConfig from "@/components/menu/commonConfig";

import smmSmarketingRecords from "@/components/menu/child/smmSmarketingRecords";
import MachineCallingRecord from "@/components/menu/child/MachineCallingRecord";
import Detail from "@/components/menu/child/Detail";
import Charts from "@/components/menu/child/Charts";
import Nodata from "@/components/menu/child/Nodata";

import speechcraft from "@/components/ef/speechcraft";
import tagtrack from "@/components/ef/tagtrack";
import speechFlow from "@/components/ef/panel";
import knowledgeBase from "@/components/ef/knowledgeBase";
import knowledgeEntry from "@/components/ef/knowledgeEntry";
import knowledgeFlow from "@/components/ef/knowledgeFlow";
import intentionLibrary from "@/components/ef/intentionLibrary";
import globalConfig from "@/components/ef/globalConfig";
import voiceOver from "@/components/ef/voiceOver";
import knowledgePanle from "@/components/ef/knowledgePanle";
import banned from "@/components/menu/banned";
import bannedCollect from "@/components/menu/bannedCollect";
import bannedDetail from "@/components/menu/bannedDetail";
import bannedLine from "@/components/menu/bannedLine";
import liquor from "@/components/menu/liquor"; //白酒
import liquorOrder from "@/components/menu/liquorOrder"; //白酒
import liquorUser from "@/components/menu/liquorUser"; //白酒
import liquorProduct from "@/components/menu/liquorProduct"; //白酒

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    components: {
      first: Login,
    },
  },
  {
    path: "/menu",
    name: "Menu",
    components: {
      first: Menu,
    },
    children: [
      {
        path: "/importMac",
        name: "ImportMac",
        components: {
          second: ImportMac,
        },
      },
      {
        path: "/groupList",
        name: "GroupList",
        components: {
          second: GroupList,
        },
      },
      {
        path: "/machineCalling",
        name: "MachineCalling",
        components: {
          second: MachineCalling,
        },
      },
      {
        path: "/passengerNum",
        name: "PassengerNum",
        components: {
          second: PassengerNum,
        },
        children: [
          {
            path: "/charts",
            name: "Charts",
            components: {
              third: Charts,
            },
          },
          {
            path: "/nodata",
            name: "Nodata",
            components: {
              third: Nodata,
            },
          },
        ],
      },
      {
        path: "/passengerSelect",
        name: "PassengerSelect",
        components: {
          second: PassengerSelect,
        },
      },
      {
        path: "/SMSmarketing",
        name: "SMSmarketing",
        components: {
          second: SMSmarketing,
        },
      },
      {
        path: "/smmSmarketingRecords",
        name: "smmSmarketingRecords",
        components: {
          second: smmSmarketingRecords,
        },
      },
      {
        path: "/marketingRecord",
        name: "MarketingRecord",
        components: {
          second: MarketingRecord,
        },
        children: [
          {
            path: "/machineCallingRecord",
            name: "MachineCallingRecord",
            components: {
              third: MachineCallingRecord,
            },
          },
        ],
      },
      {
        path: "/equipmentManager",
        name: "EquipmentManager",
        components: {
          second: EquipmentManager,
        },
      },
      {
        path: "/personalCenter",
        name: "PersonalCenter",
        components: {
          second: PersonalCenter,
        },
      },
      {
        path: "/detail",
        name: "Detail",
        components: {
          second: Detail,
        },
      },
      {
        path: "/usermanagement",
        name: "UserManagement",
        components: {
          second: UserManagement,
        },
      },
      {
        path: "/client",
        name: "Client",
        components: {
          second: Client,
        },
      },
      {
        path: "/team",
        mane: "team",
        components: {
          second: Team,
        },
      },
      {
        path: "/project",
        name: "project",
        components: {
          second: Project,
        },
      },
      {
        path: "/call",
        name: "call",
        components: {
          second: Call,
        },
      },
      {
        path: "/route",
        name: "Route",
        components: {
          second: Route,
        },
      },
      {
        path: "/customService",
        name: "CustomService",
        components: {
          second: CustomService,
        },
      },
      {
        path: "/task",
        name: "Task",
        components: {
          second: Task,
        },
      },
      {
        path: "/monitor",
        name: "Monitor",
        components: {
          second: Monitor,
        },
      },
      {
        path: "/question",
        name: "question",
        components: {
          second: question,
        },
      },
      {
        path: "/userList",
        name: "UserList",
        components: {
          second: UserList,
        },
      },
      {
        path: "/answerList",
        name: "AnswerList",
        components: {
          second: AnswerList,
        },
      },
      {
        path: "/questionTemplate",
        name: "questionTemplate",
        components: {
          second: questionTemplate,
        },
      },
      {
        path: "/callbackList",
        name: "callbackList",
        components: {
          second: callbackList,
        },
      },
      {
        path: "/customerState",
        name: "customerState",
        components: {
          second: customerState,
        },
      },
      {
        path: "/roleList",
        name: "roleList",
        components: {
          second: roleList,
        },
      },
      {
        path: "/analysisReport",
        name: "analysisReport",
        components: {
          second: analysisReport,
        },
      },
      {
        path: "/latentGuest",
        name: "latentGuest",
        components: {
          second: latentGuest,
        },
      },
      {
        path: "/labelManage",
        name: "labelManage",
        components: {
          second: labelManage,
        },
      },
      {
        path: "/userBlacklist",
        name: "UserBlacklist",
        components: {
          second: UserBlacklist,
        },
      },
      {
        path: "/clientCallReport",
        name: "clientCallReport",
        components: {
          second: clientCallReport,
        },
      },
      {
        path: "/bill",
        name: "bill",
        components: {
          second: bill,
        },
      },
      // {
      //   path:'/menuManagement',
      //   name:'menuManagement',
      //   components:{
      //     second: menuManagement
      //   }
      // },
      {
        path: "/lineAnalysis",
        name: "lineAnalysis",
        components: {
          second: lineAnalysis,
        },
      },
      {
        path: "/reordDownload",
        name: "reordDownload",
        components: {
          second: reordDownload,
        },
      },
      {
        path: "/connectionAnalysis",
        name: "connectionAnalysis",
        components: {
          second: connectionAnalysis,
        },
      },
      {
        path: "/prologueManage",
        name: "prologueManage",
        components: {
          second: prologueManage,
        },
      },
      {
        path: "/singleDeduction",
        name: "singleDeduction",
        components: {
          second: singleDeduction,
        },
      },
      {
        path: "/serverManage",
        name: "serverManage",
        components: {
          second: serverManage,
        },
      },
      {
        path: "/prologueList",
        name: "prologueList",
        components: {
          second: prologueList,
        },
      },
      {
        path: "/costStatistics",
        name: "costStatistics",
        components: {
          second: costStatistics,
        },
      },
      {
        path: "/wechat",
        name: "wechat",
        components: {
          second: wechat,
        },
      },
      {
        path: "/machineList",
        name: "machineList",
        components: {
          second: machineList,
        },
      },
      {
        path: "/requestLogList",
        name: "requestLogList",
        components: {
          second: requestLogList,
        },
      },
      {
        path: "/gameDataAnalysis",
        name: "gameDataAnalysis",
        components: {
          second: gameDataAnalysis,
        },
      },
      {
        path: "/wechatStatistics",
        name: "wechatStatistics",
        components: {
          second: wechatStatistics,
        },
      },
      {
        path: "/wechatSubjectGroup",
        name: "wechatSubjectGroup",
        components: {
          second: wechatSubjectGroup,
        },
      },
      {
        path: "/subjectGroup",
        redirect: "/subjectGroup/wechatSubjectGroup",
        name: "subjectGroup",
        components: {
          second: subjectGroup,
        },
        children: [
          {
            path: "/subjectGroup/wechatSubjectGroup",
            name: "wechatSubjectGroup",
            components: {
              third: wechatSubjectGroup,
            },
          },
          {
            path: "/subjectGroup/xylkSubjectGroup",
            name: "xylkSubjectGroup",
            components: {
              third: wechatSubjectGroup,
            },
          },
        ],
      },
      {
        path: "/alarmGroup",
        name: "alarmGroup",
        components: {
          second: alarmGroup,
        },
      },
      {
        path: "/qualityTestList",
        name: "qualityTestList",
        components: {
          second: qualityTestList,
        },
      },
      {
        path: "/achievementList",
        name: "achievementList",
        components: {
          second: achievementList,
        },
      },
      {
        path: "/setTemplate",
        name: "setTemplate",
        components: {
          second: setTemplate,
        },
      },
      {
        path: "/mechanismStatistics",
        name: "mechanismStatistics",
        components: {
          second: mechanismStatistics,
        },
      },
      {
        path: "/tripartiteData",
        name: "tripartiteData",
        components: {
          second: tripartiteData,
        },
      },
      {
        path: "/liquor",
        name: "liquor",
        components: {
          second: liquor,
        },
      },
      {
        path: "/liquorOrder",
        name: "liquorOrder",
        components: {
          second: liquorOrder,
        },
      },
      {
        path: "/liquorUser",
        name: "liquorUser",
        components: {
          second: liquorUser,
        },
      },
      {
        path: "/liquorProduct",
        name: "liquorProduct",
        components: {
          second: liquorProduct,
        },
      },
      {
        path: "/wechatLabel",
        name: "wechatLabel",
        components: {
          second: wechatLabel,
        },
      },
      {
        path: "/otherSet",
        name: "otherSet",
        components: {
          second: otherSet,
        },
      },
      {
        path: "/informationSet",
        name: "informationSet",
        components: {
          second: informationSet,
        },
      },
      {
        path: "/operationRecord",
        name: "operationRecord",
        components: {
          second: operationRecord,
        },
      },
      {
        path: "/speechcraft",
        name: "speechcraft",
        components: {
          second: speechcraft,
        },
      },
      {
        path: "/tagtrack",
        name: "tagtrack",
        components: {
          second: tagtrack,
        },
      },
      {
        path: "/speechFlow",
        name: "speechFlow",
        components: {
          second: speechFlow,
        },
        children: [
          {
            path: "/speechFlow/knowledgeBase",
            name: "knowledgeBase",
            components: {
              third: knowledgeBase,
            },
            children: [
              {
                path: "/speechFlow/knowledgeBase/knowledgeEntry",
                name: "knowledgeEntry",
                components: {
                  four: knowledgeEntry,
                },
              },
              {
                path: "/speechFlow/knowledgeBase/knowledgeFlow",
                name: "knowledgeFlow",
                components: {
                  four: knowledgeFlow,
                },
              },
            ],
          },
          {
            path: "/speechFlow/intentionLibrary",
            name: "intentionLibrary",
            components: {
              third: intentionLibrary,
            },
          },
          {
            path: "/speechFlow/voiceOver",
            name: "voiceOver",
            components: {
              third: voiceOver,
            },
          },
          {
            path: "/speechFlow/globalConfig",
            name: "globalConfig",
            components: {
              third: globalConfig,
            },
          },
        ],
      },
      {
        path: "/knowledgePanle",
        name: "knowledgePanle",
        components: {
          second: knowledgePanle,
        },
      },
      {
        path: "/banned",
        name: "banned",
        components: {
          second: banned,
        },
      },
      {
        path: "/bannedCollect",
        name: "bannedCollect",
        components: {
          second: bannedCollect,
        },
      },
      {
        path: "/bannedDetail",
        name: "bannedDetail",
        components: {
          second: bannedDetail,
        },
      },
      {
        path: "/bannedLine",
        name: "bannedLine",
        components: {
          second: bannedLine,
        },
      },
      {
        path: "/functionSwitch",
        name: "functionSwitch",
        components: {
          second: functionSwitch,
        },
      },
      {
        path: "/commonConfig",
        name: "commonConfig",
        components: {
          second: commonConfig,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
