<template>
  <div
    ref="select"
    class="area-selector"
    tabindex="0"
    @blur="selectBlur"
    @mouseenter="inputHover = true"
    @mouseleave="inputHover = false"
  >
    <input
      class="select-calss"
      :class="{ isFocus: isArea }"
      readonly="readonly"
      v-model="inputText"
      @click="addinput"
      @blur="inputBlur"
      :disabled="disabled"
    />
    <div class="area-selector-tk" v-show="isArea">
      <div class="rg-level-tabs">
        <ul>
          <li @click="addressChange('province')">
            <span href="javascript:void(0);" :class="{ active: isProvince }"
              >省/直辖市</span
            >
          </li>
          <li @click="addressChange('city')">
            <span href="javascript:void(0);" :class="{ active: isCity }"
              >市</span
            >
          </li>
          <li @click="addressChange('county')">
            <span href="javascript:void(0);" :class="{ active: ispCounty }"
              >区/县</span
            >
          </li>
          <li @click="addressChange('town')">
            <span href="javascript:void(0);" :class="{ active: isTown }"
              >乡/镇/街道</span
            >
          </li>
          <li @click="addressChange('detailed')">
            <span href="javascript:void(0);" :class="{ active: isDetailed }"
              >详细地址</span
            >
          </li>
        </ul>
        <div class="operation-button">
          <i class="el-icon-delete" @click="areaDelect"></i>
          <i style="display: inline-block; margin-right: 8px"></i>
          <i class="el-icon-check" @click="areaCheck"></i>
        </div>
      </div>
      <div class="rg-results-container">
        <ul class="rg-results" v-if="!isDetailed">
          <div v-if="area.length > 0">
            <li
              @click="locationChange(item)"
              v-for="item in area"
              :key="item.key"
              class="rg-item"
              :class="{ active: isClick[item.id] == item.value }"
            >
              {{ item.value }}
            </li>
          </div>
          <li v-if="area.length < 1" class="rg-message-box">无匹配项目</li>
        </ul>
        <div class="area-text" v-if="isDetailed">
          <textarea
            rows="10"
            cols="50"
            v-model="detailedArea"
            @input="addArea"
            @blur="areaBlur"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "areaSelect",
  props: ["detailedAddress", "disabled"],
  data() {
    return {
      inputHover: false,
      inputText: "",
      inputArea: "",
      detailedArea: "",
      textList: [],
      areaList: [],
      isArea: false,
      isProvince: true,
      isCity: false,
      ispCounty: false,
      isTown: false,
      isDetailed: false,
      isClick: {},
      area: [
        // {id:'p',key:'1',value:'北京市'},
      ],
    };
  },
  watch: {
    detailedAddress(val) {
      this.setData(val);
    },
  },
  mounted() {},
  methods: {
    //div失去焦点
    selectBlur() {
      if (this.inputHover) {
        this.isArea = true;
      } else {
        this.isArea = false;
      }
    },
    //input失去焦点
    inputBlur() {
      if (this.inputHover) {
        this.isArea = true;
      } else {
        this.isArea = false;
      }
    },
    //area失去焦点
    areaBlur() {
      if (this.inputHover) {
        this.isArea = true;
      } else {
        this.isArea = false;
      }
    },
    // 清空内容
    areaDelect() {
      this.inputText = "";
      this.inputArea = "";
      this.detailedArea = "";
      this.textList = [];
      this.areaList = [];
      this.isClick = {};
      let areas = "";
      this.$emit("parentMethod", areas);
      this.addressChange("province");
    },
    // 确定内容
    areaCheck() {
      this.isArea = false;
    },
    //点击地址input
    addinput() {
      if (this.isArea) return;
      this.isArea = true;
      this.addressChange("province");
    },
    //详细地址input
    addArea() {
      this.inputText = this.inputArea;
      let text = this.inputText;
      this.inputText = text + this.detailedArea;
      let areas = this.inputText;
      this.$emit("parentMethod", areas);
    },
    //点击省/直辖市等
    addressChange(val) {
      if (val === "province") {
        this.isProvince = true;
        this.isCity = false;
        this.ispCounty = false;
        this.isTown = false;
        this.isDetailed = false;
        let params = {
          type: "PROVINCE",
        };
        this.getDistinctInfo(params, "p");
      }
      if (val === "city") {
        this.isProvince = false;
        this.isCity = true;
        this.ispCounty = false;
        this.isTown = false;
        this.isDetailed = false;
        let params = {
          type: "CITY",
          province: this.textList[0],
        };
        this.getDistinctInfo(params, "c");
      }
      if (val === "county") {
        this.isProvince = false;
        this.isCity = false;
        this.ispCounty = true;
        this.isTown = false;
        this.isDetailed = false;
        let params = {
          type: "AREA",
          province: this.textList[0],
          city: this.textList[1],
        };
        this.getDistinctInfo(params, "n");
      }
      if (val === "town") {
        this.isProvince = false;
        this.isCity = false;
        this.ispCounty = false;
        this.isTown = true;
        this.isDetailed = false;
        let params = {
          type: "STREET",
          province: this.textList[0],
          city: this.textList[1],
          area: this.textList[2],
        };
        this.getDistinctInfo(params, "t");
      }
      if (val === "detailed") {
        this.isProvince = false;
        this.isCity = false;
        this.ispCounty = false;
        this.isTown = false;
        this.isDetailed = true;
      }
    },
    //选择地址
    locationChange(val) {
      if (val.id === "p") {
        this.textList[0] = val.value;
        this.textList[1] = "";
        this.textList[2] = "";
        this.textList[3] = "";
        this.areaList[0] = val.key;
        this.areaList[1] = "";
        this.areaList[2] = "";
        this.areaList[3] = "";
        this.detailedArea = "";
        delete this.isClick["c"];
        delete this.isClick["n"];
        delete this.isClick["t"];
        this.addressChange("city");
      }
      if (val.id === "c") {
        this.textList[1] = val.value;
        this.textList[2] = "";
        this.textList[3] = "";
        this.areaList[1] = val.key;
        this.areaList[2] = "";
        this.areaList[3] = "";
        this.detailedArea = "";
        delete this.isClick["n"];
        delete this.isClick["t"];
        this.addressChange("county");
      }
      if (val.id === "n") {
        this.textList[2] = val.value;
        this.textList[3] = "";
        this.areaList[2] = val.key;
        this.areaList[3] = "";
        this.detailedArea = "";
        delete this.isClick["t"];
        this.addressChange("town");
      }
      if (val.id === "t") {
        this.textList[3] = val.value + " ";
        this.areaList[3] = val.key;
        this.detailedArea = "";
        this.addressChange("detailed");
        // this.isArea = !this.isArea
      }
      this.isClick[val.id] = val.value;
      this.inputText = this.textList.join(" ");
      this.inputArea = this.textList.join(" ");
      let areas = "";
      let newlist = this.areaList.filter((d) => d);
      areas = newlist.join(",");
      // this.$emit('parentMethod',areas);  //使用$emit()引入父组件中的方法
      this.$emit("parentMethod", this.inputText); //使用$emit()引入父组件中的方法
    },
    // 获取地址
    getDistinctInfo(params, type) {
      this.$http("/distinctInfo", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = [];
          if (res.data.length > 0) {
            res.data.forEach((item, i) => {
              if (item) {
                let obj = {
                  id: type,
                  key: i,
                  value: item,
                };
                list.push(obj);
              }
            });
          }
          this.area = list;
        }
      });
    },
    // 数据处理
    setData(val) {
      if (!val) {
        this.isArea = false;
        this.inputText = "";
        this.detailedArea = "";
        this.textList = [];
        this.isClick = {};
      } else {
        // console.log(val)
        this.$emit("parentObtain", val);
        this.inputText = val;
        this.detailedArea = "";
        this.textList = [];
        this.isClick = {};
        let text = val;
        text = text.split(" ");
        for (let i in text) {
          if (i < 4) {
            this.textList[i] = text[i];
          }
          if (i > 3) {
            this.detailedArea += text[i];
          }
        }
        if (this.textList[0]) {
          this.isClick["p"] = this.textList[0];
        }
        if (this.textList[1]) {
          this.isClick["c"] = this.textList[1];
        }
        if (this.textList[2]) {
          this.isClick["n"] = this.textList[2];
        }
        if (this.textList[3]) {
          this.isClick["t"] = this.textList[3];
        }
      }
    },
  },
};
</script>
<style scoped>
.select-calss:disabled {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
.area-selector:focus {
  outline: 0;
}
input:focus {
  outline: 0;
  border-color: #409eff;
}
.isFocus {
  border-color: #409eff !important;
}
.area-selector {
  position: relative;
  /* width: 400px; */
  z-index: 2;
  box-sizing: border-box;
}
/* 地区弹框 头 */
.area-selector-tk {
  position: absolute;
  top: 48px;
  width: 450px;
  border: 1px solid #d6d7d7;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  line-height: 25px;
}
.operation-button {
  position: absolute;
  display: inline-block;
  right: 12px;
  top: 10px;
}
div .rg-level-tabs {
  padding: 10px 10px 0;
  border-bottom: 1px solid #e6e7e7;
  background-color: #f5f5f5;
}
div .rg-level-tabs ul {
  position: relative;
  bottom: -1px;
  padding: 0;
  margin: 0;
  text-align: left;
}
div .rg-level-tabs ul li {
  display: inline-block;
}
div .rg-level-tabs ul li span {
  display: inline-block;
  padding: 7px 10px 3px;
  font-size: 14px;
  color: #6a737d;
  text-decoration: none;
  cursor: pointer;
  line-height: 100%;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 3px 3px 0 0;
  font-family: Helvetica Neue Light, HelveticaNeue-Light, Helvetica Neue,
    Calibri, Helvetica, Arial;
}
div .rg-level-tabs ul li span.active {
  color: #24292e;
  background-color: #fff;
  border-color: #dfe2e5;
  border-bottom-color: #fff;
}
/* 地区弹框 内容 */
div .rg-results-container {
  background-color: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  clear: both;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
div .rg-results-container .area-text {
  margin: 0 auto;
  padding: 20px 10px;
  text-align: center;
}
div .rg-results-container .area-text textarea {
  font-size: 15px;
  border-color: #c0c4cc;
  color: #606266;
}
div .rg-results-container ul.rg-results {
  background-color: #fff;
  list-style: none;
  margin: 0;
  padding: 5px;
  width: 100%;
}
div .rg-results-container ul.rg-results li.rg-item {
  display: inline-block;
  border-radius: 2px;
  margin-right: 5px;
  color: #777;
}
div .rg-results-container ul.rg-results > li {
  margin: 0;
  overflow: hidden;
  padding: 3px 10px;
  position: relative;
  text-align: left;
  white-space: nowrap;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif;
}
div .rg-results-container ul.rg-results li.rg-item.active {
  background-color: #55a2e6;
  color: #fff;
}
div .rg-results-container ul.rg-results > li.rg-message-box {
  height: 30px;
  line-height: 30px;
  text-align: center;
  box-sizing: content-box;
  font-size: 14px;
  cursor: default;
}
/* input隐藏光标且显示文字 */
.select-calss {
  width: 100%;
  line-height: 36px;
  color: transparent;
  text-shadow: 0 0 0 #606266;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
