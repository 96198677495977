<template>
  <el-autocomplete
    v-model="testNumber"
    @input="inputNumber"
    :fetch-suggestions="testNumberSearch"
    placeholder="请输入号码"
    @select="testNumberSelect"
    style="width: 100%"
  ></el-autocomplete>
</template>
<script>
export default {
  name: "autocompleteMobile",
  props: ["name"],
  data() {
    return {
      testNumber: ""
    };
  },
  methods: {
    testNumberSearch(queryString, cb) {
      let restaurants = [];
      restaurants = this.$common.getCookie(this.name + "Number");
      if (restaurants) {
        restaurants = JSON.parse(restaurants);
      } else {
        restaurants = [];
      }
      let results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    inputNumber(val) {
      this.$emit("change", val);
    },
    testNumberSelect(item) {
      this.$emit("change", item.value);
    }
  }
};
</script>
<style scoped>
</style>

