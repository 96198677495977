<template>
  <div class="achievementList">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="科目" name="LESSON">
        <wechatLabelItem
          :roleMenu="roleMenu"
          :loginName="loginName"
          :lableType="activeName"
          v-if="achShow"
        >
        </wechatLabelItem>
      </el-tab-pane>
      <el-tab-pane label="录音质检" name="RECORDING">
        <wechatLabelItem
          :roleMenu="roleMenu"
          :loginName="loginName"
          :lableType="activeName"
          v-if="bindShow"
        ></wechatLabelItem>
      </el-tab-pane>
      <el-tab-pane label="客户分类" name="CUSTOMER_TAG">
        <wechatLabelItem
          :roleMenu="roleMenu"
          :loginName="loginName"
          :lableType="activeName"
          v-if="payCountShow"
        ></wechatLabelItem>
      </el-tab-pane>
      <el-tab-pane label="其他" name="COMMON">
        <wechatLabelItem
          :roleMenu="roleMenu"
          :loginName="loginName"
          :lableType="activeName"
          v-if="monthlyShow"
        ></wechatLabelItem>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import wechatLabelItem from "./wechatLabelItem"
export default {
  name: "wechatLabel",
  props: ["roleMenu","loginName"],
  components: {
    wechatLabelItem,
  },
  data() {
    return {
      activeName: "LESSON",
      achShow: false,
      bindShow: false,
      payCountShow: false,
      monthlyShow: false,
    };
  },
  mounted() {
    this.achShow = true;
  },
  methods: {
    // 切换tab
    handleClick(val) {
      if (this.activeName === "LESSON") {
        this.achShow = true;
        this.bindShow = false;
        this.payCountShow = false;
        this.monthlyShow = false;
      }
      if (this.activeName === "COMMON") {
        this.achShow = false;
        this.bindShow = false;
        this.payCountShow = false;
        this.monthlyShow = true;
      }
      if (this.activeName === "RECORDING") {
        this.achShow = false;
        this.payCountShow = false;
        this.monthlyShow = false;
        this.bindShow = true;
      }
      if (this.activeName === "CUSTOMER_TAG") {
        this.achShow = false;
        this.bindShow = false;
        this.monthlyShow = false;
        this.payCountShow = true;
      }
    }
  }
};
</script>
<style scoped>
.a_nav {
  margin: 0 0px 20px 0px;
}
.a_nav .callTime {
  display: inline-block;
  margin-right: 20px;
}
.achievementList .el-pagination {
  float: right;
  margin-top: 20px;
}
.achievementList .el-table {
  margin-top: 20px;
}
.connectedTable >>> .el-dialog__body::after {
  display:block;
  content:'';
  clear:both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 0 0 20px 0;
}
</style>

