<template>
  <div class="costStatistics">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="日报表" name="day">
        <div class="nav">
          <div class="callTime">日期：
            <el-date-picker
              v-model="conditionDailycost.callEndAtStart"
              value-format="yyyy-MM-dd"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              align="right">
            </el-date-picker>
          </div>
          <div class="companyName">用户名称：
            <el-select v-model="conditionDailycost.userLoginName" placeholder="请选择客户名称" filterable remote :loading="loadingLoginName" :remote-method="remoteMethodLoginName" clearable @focus="userNameFocus" @change="userLoginNameChage">
              <el-option
                v-for="item in loginNameList"
                :key="item.id"
                :value="item.loginName"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="taskName">项目名称：
            <el-select v-model="conditionDailycost.taskName" placeholder="请选择项目名称" filterable remote :loading="loadingLine" :remote-method="taskMethod" clearable @focus="taskNameFocus" @change="taskNameChange">
              <el-option
                v-for="item in taskNameList"
                :key="item.id"
                :value="item.id"
                :label="item.projectName"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquireDayCost()">查询</el-button>
            <!-- <el-button type="success" @click="exportDayCost()" :disabled="isExportCost">导出</el-button> -->
          </div>
        </div>
        <div style="margin-bottom: 5px;">
          上次更新时间: {{lastUpdateTime}}
        </div>
        <!-- 日统计表 -->
        <el-table :data="costDayTableData" stripe style="width: 100%" border class='tableDataClass' v-loading="loadingList">
          <el-table-column type="index" label="编号" width="60" align="center"></el-table-column>
          <el-table-column prop="date" label="日期" min-width="100" align="center"></el-table-column>
          <el-table-column prop="userName" label="客户名称" min-width="150" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="100" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="calledCount" label="拨打量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="newSourceCount" label="新资源量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="connectedCount" label="接通量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="distributedCount" label="转接量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="orderCount" label="成单量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="fee" label="话费成本(元)" min-width="110" align="center"></el-table-column>
          <el-table-column prop="onlineCount" label="上线人天" min-width="100" align="center"></el-table-column>
          <el-table-column prop="perOrder" label="人均单量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="costPerCall" label="每单话费成本" min-width="125" align="center"></el-table-column>
          <el-table-column prop="costPerSource" label="每单资源成本(拨打)" min-width="150" align="center"></el-table-column>
          <el-table-column prop="costPerNewSource" label="每单资源成本(资源)" min-width="150" align="center"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleDayCostSizeChange"
          @current-change="currentPage_dayCostData"
          :current-page.sync="currentPageExpenses"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="totalExpenses">
        </el-pagination>
        <!-- 数据说明 -->
        <div class="dataExplain_class">数据说明:</div>
        <div v-for="(item,index) in dataExplain" :key="index" class="dataExplain_class">
          <span>{{item}}</span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="月报表" name="month">
        <div class="nav">
          <div class="callTime">日期：
            <el-date-picker
              v-model="conditionMonthcost.callEndAtStart"
              type="month"
              value-format="yyyy-MM-dd"
              placeholder="选择月">
            </el-date-picker>
          </div>
          <div class="companyName">用户名称：
            <el-select v-model="conditionMonthcost.userLoginName" placeholder="请选择客户名称" filterable remote :loading="loadingLoginName" :remote-method="remoteMethodLoginName" clearable @focus="userNameFocus" @change="monthUserLoginNameChage">
              <el-option
                v-for="item in loginNameList"
                :key="item.id"
                :value="item.loginName"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="taskName">项目名称：
            <el-select v-model="conditionMonthcost.taskName" placeholder="请选择线路" filterable remote :loading="loadingLine" :remote-method="taskRemoteMethod" clearable @focus="taskNameMonthFocus" @change="monthTaskNameChange">
              <el-option
                v-for="item in taskNameList"
                :key="item.id"
                :value="item.id"
                :label="item.projectName"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquireMonthCost()">查询</el-button>
            <!-- <el-button type="success" @click="exportMonthCost()" :disabled="isExportAnaltsis">导出</el-button> -->
          </div>
        </div>
        <div style="margin-bottom: 5px;">
          上次更新时间: {{lastUpdateTime}}
        </div>
        <!-- 月报表 -->
        <el-table :data="costMouthTableData" stripe style="width: 100%" border class='tableDataClass' v-loading="loadingList">
          <el-table-column type="index" label="编号" width="60" align="center"></el-table-column>
          <el-table-column prop="date" label="月份" min-width="100" align="center"></el-table-column>
          <el-table-column prop="userName" label="客户名称" min-width="150" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="100" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="calledCount" label="拨打量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="newSourceCount" label="新资源量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="connectedCount" label="接通量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="distributedCount" label="转接量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="orderCount" label="成单量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="fee" label="话费成本(元)" min-width="110" align="center"></el-table-column>
          <el-table-column prop="onlineCount" label="上线人天" min-width="100" align="center"></el-table-column>
          <el-table-column prop="perOrder" label="人均单量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="costPerCall" label="每单话费成本" min-width="125" align="center"></el-table-column>
          <el-table-column prop="costPerSource" label="每单资源成本(拨打)" min-width="150" align="center"></el-table-column>
          <el-table-column prop="costPerNewSource" label="每单资源成本(资源)" min-width="150" align="center"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleMonthCostChange"
          @current-change="currentPage_monthCostdata"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <!-- 数据说明 -->
        <div class="dataExplain_class">数据说明:</div>
        <div v-for="(item,index) in dataExplain" :key="index" class="dataExplain_class">
          <span>{{item}}</span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: 'costStatistics',
  data () {
    return {
      conditionMonthcost: {
        callEndAtStart: "",
        taskName: '',
        userLoginName: ''
      },
      pageSizeConcurrent: 10,
      dataExplain: [
        "1. 分项目+客户，按日、月统计拨打情况",
        "2. 拨打量，当天所有的拨打数量（不算回拨的）",
        "3. 新资源量，当天所有导入的资源量，不算重播的",
        "4. 转接量，转接到坐席的数量",
        "5. 话费成本，通话时长（转接时长+坐席通话时长）*线路成本",
        "6. 上线人数，与成单扣除统计人数一致，上午接入电话即算0.5人，下午接入电话即算0.5人",
        "7. 人均单量，成单量/上线人数",
      ],
      pickerOptions: {
        disabledDate(time) {
            const start = new Date();
            start.setHours(23);
            start.setMinutes(59);
            start.setSeconds(59);
            start.setMilliseconds(59);
            return time.getTime() > start;
        },
        onPick({ maxDate, minDate }){
          if(minDate){
            sessionStorage.setItem('theTime', minDate.getTime());
          }
        },
        shortcuts: [{
          text: '本月记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本周记录',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('thisWeek');
            start = parseInt(start);
            picker.$emit('pick', [new Date(start), end]);
          }
        }, {
          text: '本日记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '此刻',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('theTime');
            if(start){
              start = parseInt(start);
              picker.$emit('pick', [new Date(start), end]);
              sessionStorage.removeItem('theTime');
            }
          }
        }]
      },
      defaultTime:['00:00:00','23:59:59'],
      conditionDailycost: {
        callEndAtStart: [],
        taskName: '',
        userLoginName: ''
      },
      taskNameList: [],
      loadingLine: false,
      costDayTableData: [],
      costMouthTableData: [],
      listTotal: [],
      loadingList: false,
      currentPage: null,
      currentPageExpenses: null,
      pageSize:10,
      pageSizeExpenses:10,
      total: null,
      totalExpenses: null,
      expensesFilterCity: false,
      order: {},
      activeName: "day",
      isExportAnaltsis: true,
      isExportCost: true,
      loginNameList: [],
      loadingLoginName: false,
      ExpensesTotal: [],
      concurrentTimeList: [],
      concurrentValueList: [],
      lastUpdateTime: ''
    }
  },
  mounted () {
    document.title = "惠销平台管理系统-成本统计";
    this.theWeek();
    let end = new Date(),
        start = new Date();
        start.setTime(start.getTime());
    start = this.$common.transformTime(start.valueOf()).substr(0,10);
    end = this.$common.transformTime(end.valueOf()).substr(0,10);
    this.conditionMonthcost.callEndAtStart = start;
    this.conditionDailycost.callEndAtStart = [start, end];
    this.inquireDayCost();
    this.getLastUpdateTime("COST_STATISTICS_DAY");
  },
  methods: {
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
    // 用户名称选择---日报表
    userLoginNameChage(val) {
      this.conditionDailycost.taskName = "";
    },
    // 项目名称选择---日报表
    taskNameChange(val) {
      this.loginNameList = [];
      this.taskNameList.forEach(item => {
        if (val === item.id) {
          this.loginNameList.push({
            id: 1,
            loginName: item.userLoginName,
            label: item.userName
          })
          this.$nextTick(() => {
            this.conditionDailycost.userLoginName = item.userLoginName;
          })
        }
      })
    },
    // 用户名称选择---月报表
    monthUserLoginNameChage(val) {
      this.conditionMonthcost.taskName = "";
    },
    // 项目名称选择---月报表
    monthTaskNameChange(val) {
      this.loginNameList = [];
      this.taskNameList.forEach(item => {
        if (val === item.id) {
          this.loginNameList.push({
            id: 1,
            loginName: item.userLoginName,
            label: item.userName
          })
          this.$nextTick(() => {
            this.conditionMonthcost.userLoginName = item.userLoginName;
          })
        }
      })
    },
    // 获取更新时间
    getLastUpdateTime(method) {
      let data = {
        method: method
      }
      this.lastUpdateTime = '';
      this.$http("/report/getLastUpdateTime",null,'get',null,data).then(res => {
        if (res.result === 200) {
          let lastUpdateTime = res.data.lastUpdateTime;
          this.lastUpdateTime = this.$common.transformTime(lastUpdateTime);
        }
      })
    },
    // 用户名称选择框获取焦点
    userNameFocus(){
      this.inquireLoginName({"pageNo":1,"pageSize":20,"enabled": true});
    },
    //搜索用户名称
    remoteMethodLoginName(userName){
      this.loadingLoginName = true;
      setTimeout(() => {
        this.loadingLoginName = false;
        let params = {
            pageSize:20,
            pageNo:1,
            userName:userName,
            enabled: true
          }
          this.inquireLoginName(params)
      }, 200);
    },
    // 查询用户名称
    inquireLoginName(params){
      this.loginNameList = [];
      this.$http('/user/list',null,'get',null,params).then(res=>{
        if(res.result == 200){
          let newuserNameList = res.data.list;
          newuserNameList.map(item=>{
            item.label = item.userName;
            this.loginNameList.push(item);
          })
        }
      })
    },
    // 切换tab标签页
    handleClick(tab, event){
      this.isExportAnaltsis = true;
      this.isExportCost = true;
      if (this.activeName === "month") {
        this.inquireMonthCost();
        this.getLastUpdateTime("COST_STATISTICS_MOUTH");
      } 
      if (this.activeName === "day") {
        this.inquireDayCost();
        this.getLastUpdateTime("COST_STATISTICS_DAY");
      }
    },
    // 点击查询---月报表
    inquireMonthCost(index){
      if (!index) {
        this.currentPage = 1;
      }
      // 时间转换
      let time = this.conditionMonthcost.callEndAtStart;
      let timeStart = new Date(time);
      let sm = timeStart.getMonth();
      timeStart.setMonth(sm);
      timeStart.setDate(1);
      timeStart.setHours(0);
      timeStart.setMinutes(0);
      timeStart.setSeconds(0);
      let timeEnd = new Date(timeStart);
      let em = timeEnd.getMonth()+1;
      timeEnd.setMonth(em);
      timeEnd = timeEnd - 1000;
      timeStart = this.$common.transformTime(timeStart).substr(0,10);
      timeEnd = this.$common.transformTime(timeEnd).substr(0,10);
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        userLoginName: this.conditionMonthcost.userLoginName,
        projectId: this.conditionMonthcost.taskName,
        start: timeStart,
        end: timeEnd,
        type: 'month'
      }
      this.isExportAnaltsis = true;
      this.getConnectionAnalysisList(data);
      // this.getListTotal(data);
    },
    // 查询表格---月报表
    getConnectionAnalysisList(data){
      this.loadingLine = true;
      this.loadingList = true;
      this.$service('/report/costList',null,'get',null,data).then(res => {
        setTimeout(() => {
          this.loadingLine = false;
          this.loadingList = false;
        }, 200);
        if (res.result === 200) {
          let list = res.data.data;
          this.total = res.data.total;
          this.costMouthTableData = list;
          if (this.costMouthTableData && this.costMouthTableData.length > 0) {
            this.isExportAnaltsis = false;
          } else {
            this.isExportAnaltsis = true;
          }
        }
      })
    },
    // 查询表格合计---月报表
    getListTotal(data){
      this.$service('/call400Stats/routeAnalysis/total',data).then(res => {
        if (res.result === 200) {
          let data = res.data;
          data['answerRate'] = '0.00%'
          if (data['connectCount'] > 0) {
            data['answerRate'] = (data['connectCount'] / data['callCount'] *100).toFixed(2) + '%';
          }
          this.listTotal = data;
        }
      })
    },
    // 当前页---月报表
    currentPage_monthCostdata(index){
      this.currentPage = index;
      this.inquireMonthCost(index);
    },
    // 显示条数---月报表
    handleMonthCostChange(val){
      this.pageSize = val;
      this.inquireMonthCost();
    },
    inquireProject(params){//查询项目名称
      this.taskNameList = [];
      this.loadingLine = true;
      this.$http('/sip/project/list',null,'get',null,params).then(res=>{
        this.loadingLine = false;
        if(res.result == 200){
          let newProjectNameList = res.data.list;
          newProjectNameList.map(item=>{
            this.taskNameList.push(item);
          })
        }
      })
    },
    // 日项目名称选择框获取焦点
    taskNameFocus() {
      let params = {
        pageSize:20,
        pageNo:1,
        loginName: this.conditionDailycost.userLoginName
      }
      this.inquireProject(params)
    },
    // 月项目名称选择框获取焦点
    taskNameMonthFocus() {
      let params = {
        pageSize:20,
        pageNo:1,
        loginName: this.conditionMonthcost.userLoginName
      }
      this.inquireProject(params)
    },
    // 搜索月报表
    taskRemoteMethod(projectName){
      let params = {
        pageSize:20,
        pageNo:1,
        projectName:projectName,
        loginName: this.conditionDailycost.userLoginName
      }
      this.inquireProject(params)
    },
    // 点击查询---日报表
    inquireDayCost(index) {
      if (!index) {
        this.currentPageExpenses = 1;
      }
      let timeStart = '';
      let timeEnd = '';
      if (this.conditionDailycost.callEndAtStart) {
        timeStart = this.conditionDailycost.callEndAtStart[0]
        timeEnd = this.conditionDailycost.callEndAtStart[1]
      }
      let data = {
        pageNo: this.currentPageExpenses,
        pageSize: this.pageSizeExpenses,
        userLoginName: this.conditionDailycost.userLoginName,
        projectId: this.conditionDailycost.taskName ,
        start: timeStart,
        end: timeEnd,
        type: 'day'
      }
      this.isExportCost = true;
      this.getDayCostList(data);
      // this.getDayCostTotal(data);
    },
    // 查询---日报表
    getDayCostList(data){
      this.loadingList = true;
      this.$http("/report/costList",null,"get",null,data).then(res => {
        setTimeout(() => {
          this.loadingList = false;
        }, 200);
        if (res.result === 200) {
          let list = res.data.data;
          this.totalExpenses = res.data.total;
          this.costDayTableData = list;
          if (this.costDayTableData && this.costDayTableData.length > 0) {
            this.isExportCost = false;
          } else {
            this.isExportCost = true;
          }
        }
      })
    },
    // 当前页---日报表 
    currentPage_dayCostData(index){
      this.currentPageExpenses = index;
      this.inquireDayCost(index);
    },
    // 显示条数---日报表
    handleDayCostSizeChange(val){
      this.pageSizeExpenses = val;
      this.inquireDayCost();
    },
    // 搜索项目名称---日报表
    taskMethod(projectName){
      let params = {
        pageSize:20,
        pageNo:1,
        projectName:projectName,
        loginName: this.conditionDailycost.userLoginName
      }
      this.inquireProject(params)
    },
    // 查询合计---日报表
    getDayCostTotal(data) {
      this.$http("/call400Stats/routeDetail/total",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let data = res.data;
          this.ExpensesTotal = data;
        }
      })
    },
   
    // 点击导出---日报表
    exportDayCost() {
      let params = {
        pageNo: this.currentPageExpenses,
        pageSize: this.pageSizeExpenses,
        routeId: this.conditionDailycost.taskName,
        userLoginName: this.conditionDailycost.userLoginName
      }
      if(this.conditionDailycost.callEndAtStart){
        params.startTime=this.conditionDailycost.callEndAtStart[0];
        params.endTime=this.conditionDailycost.callEndAtStart[1];
      }
      let url = "";
      for(let i in params){
        if(params[i]){
          url+=(i+'='+params[i]+'&')
        }
      }
      let href = this.$constants.baseURL+"/call400Stats/routeDetail/export?"+url;
      // let href = 'http://192.168.1.129:9997'+"/call400Stats/stats/export?"+url;
      // window.location.href = href;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then(data=>{
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      })
    },
    // 点击导出---月报表
    exportMonthCost() {
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      }
      
      let url = "";
      for(let i in params){
        if(params[i]){
          url+=(i+'='+params[i]+'&')
        }
      }
      let href = this.$constants.baseURL+"/call400Stats/routeAnalysis/export?"+url;
      // let href = 'http://192.168.1.129:9997'+"/call400Stats/stats/export?"+url;
      // window.location.href = href;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then(data=>{
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      })
    },
  }
}
</script>
<style scoped>
.costStatistics >>> .el-tabs__content {
  overflow: auto;
}
.dataExplain_class {
  font-size: 14px;
  color: #606266;
}
.el-table{
  margin-bottom:20px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav{
  width: 1200px;
}
.nav .el-input,.nav .el-select {
  width: 300px;
}
.nav .callTime .el-input {
  width: 430px;
} 
.companyName,.callTime,.taskName,.batchName {
  width: 700px;
  margin-bottom: 20px;
}
.callTime >>> .el-input__inner{
  width: 430px;
}
.callTime,.batchName {
  margin-right: 6px;
}
.callTime,.batchName{
  float: right;
  text-align: left;
}
.batchName .el-input{
  width: 230px;
}
.operate{
  margin-bottom: 20px;
}
.checkbox >>> .el-checkbox__label{
  font-size: 16px;
  color: #333;
}
</style>


