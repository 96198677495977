<template>
  <div class="setTemplate">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="外呼策略模板" name="smsTemplate">
        <sms-template v-if="smsTemplateShow" :roleMenu="roleMenu"></sms-template>
      </el-tab-pane>
      <!-- <el-tab-pane label="挂机短信模板" name="secondTouchTemplate">
        <second-touch-template v-if="secondTouchTemplateShow"></second-touch-template>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import smsTemplate from './smsTemplate.vue'
import secondTouchTemplate from './secondTouchTemplate.vue'
export default {
  name: "setTemplate",
  props: ['roleMenu'],
  components: {
    smsTemplate,
    secondTouchTemplate
  },
  data() {
    return {
      smsTemplateShow: false,
      secondTouchTemplateShow: false,
      activeName: "smsTemplate"
    }
  },
  mounted() {
    this.smsTemplateShow = true;
  },
  methods: {
    // 选择tab
    handleClick() {
      if (this.activeName === "smsTemplate") {
        this.smsTemplateShow = true;
        this.secondTouchTemplateShow = false;
      }
      if (this.activeName === "secondTouchTemplate") {
        this.smsTemplateShow = false;
        this.secondTouchTemplateShow = true;
      }
    }
  }
}
</script>
<style scoped>
  
</style>