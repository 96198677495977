<template>
  <div class="operationRecordTask">
    <div class="nav_menu_m">
      <div class="nav_item">用户名称：
        <el-select 
          v-model="condition.loginName" 
          placeholder="请选择用户" 
          filterable 
          remote 
          :loading="loadingName" 
          :remote-method="NameRemoteMethod" 
          @change="loginNameChange" 
          @focus="NameRemoteFocus" 
          clearable
        >
          <el-option
            v-for="item in userNameList"
            :key="item.id"
            :value="item.loginName"
            :label="item.userName"
          ></el-option>
        </el-select>
      </div>
      <div class="nav_item">时间：
        <el-date-picker
          v-model="condition.gmtCreated"
          type="daterange"
          unlink-panels
          :clearable="false"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="callPickerOptions"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </div>
    </div>
    <div class="nav_menu_m">
      <div class="nav_item">
        事件类型：
        <el-select 
          v-model="condition.eventType" 
          placeholder="请选择事件类型" 
          @change="eventTypeChange" 
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="nav_item">
        主体：
        <el-input
          v-model="condition.subject"
          placeholder="请输入主体"
          clearable
        >
        </el-input>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquire(1)">查询</el-button>
    </div>
    <!-- 话务统计表 -->
    <el-table 
      :data="tableData" 
      stripe 
      border 
      v-loading="loadingTable" 
      max-height="100vh"
    >
      <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
      <el-table-column prop="typeLabel" label="类型" align="center"></el-table-column>
      <el-table-column prop="userLoginName" label="账号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="subject" label="主体" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="operator" label="操作人" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="eventTypeLabel" label="事件" align="center"></el-table-column>
      <el-table-column prop="content" label="备注" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="gmtCreated" label="时间" width="170" align="center"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      @size-change="sizeChange"
      :current-page.sync="pageNo"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "operationRecordTask",
  data() {
    return {
      options: [{
        value: 'CLOSE',
        label: '关闭任务',
      }, {
        value: 'PAUSE',
        label: '暂停任务',
      }, {
        value: 'START',
        label: '开始任务',
      }, {
        value: 'DELETE',
        label: '删除任务',
      }, {
        value: 'RESTART',
        label: '重新启动',
      }, {
        value: 'ADD',
        label: '新增任务',
      }],
      loadingName: false,
      userNameList: [],
      condition: {
        loginName: "",
        gmtCreated: [],
        eventType: "",
        subject: ""
      },
      tableData: [],
      loadingTable: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      callPickerOptions:{
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [{
          text: '本月记录',
          onClick(picker) {
            let start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            let end = new Date();
            end.setDate(1);
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            end.setMilliseconds(0);
            let m = end.getMonth()+1;
            end.setMonth(m)
            end = end - 1000;
            picker.$emit('pick', [start, new Date(end)]);
          }
        }, {
          text: '本周记录',
          onClick(picker) {
            let start = sessionStorage.getItem('thisWeek');
            start = parseInt(start);
            const end = start + 7*24*3600*1000-1000;
            picker.$emit('pick', [new Date(start), new Date(end)]);
          }
        }, {
          text: '本日记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(59);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  mounted() {
    this.theWeek();
    let end = new Date();
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreated = [start,end];
    this.inquire(1);
  },
  methods: {
    // 事件类型选择
    eventTypeChange(val) {
      // console.log(val)
    },
    // 点击查询
    inquire(index) {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (index) {
        this.pageNo = index;
      }
      if(this.condition.gmtCreated && this.condition.gmtCreated.length>0){
        gmtCreatedStart = this.condition.gmtCreated[0];
        gmtCreatedEnd = this.condition.gmtCreated[1];
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        userLoginName: this.condition.loginName,
        subject: this.condition.subject,
        type: "TASK",
        eventType: this.condition.eventType,
        startTime: gmtCreatedStart,
        endTime: gmtCreatedEnd
      }
      this.getRecordList(data);
    },
    // 查询操作记录
    getRecordList(data) {
      this.$service("/record/list",data).then(res => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach(item => {
            switch (item.type) {
              case "ACCOUNT":
                item.typeLabel = "账号日志";
                break;
              case "TASK":
                item.typeLabel = "任务日志";
                break;
            }
            switch (item.eventType) {
              case "CLOSE":
                item.eventTypeLabel= "关闭任务";
                break;
              case "PAUSE":
                item.eventTypeLabel= "暂停任务";
                break;
              case "START":
                item.eventTypeLabel= "开始任务";
                break;
              case "DELETE":
                item.eventTypeLabel= "删除任务";
                break;
              case "RESTART":
                item.eventTypeLabel= "重新启动";
                break;
              case "ADD":
                item.eventTypeLabel= "新增任务";
                break;
              case "LOGIN":
                item.eventTypeLabel= "登录";
                break;
              case "LOG_OUT":
                item.eventTypeLabel= "退出登录";
                break;
              case "UPDATE":
                item.eventTypeLabel= "修改";
                break;
              case "RESET_PASSWORD":
                item.eventTypeLabel= "重置密码";
                break;
            }
          });
          this.tableData = list;
          this.total = res.data.total;
        }
      })
    },
    //当前页
    currentPage_data(index){
      this.inquire(index)
    },
    //显示条数--分页
    sizeChange(index){
      this.pageSize = index;
      this.inquire(1);
    },
    // 用户名称获取焦点
    NameRemoteFocus(){
      this.inquireUserList({pageSize: 1000,pageNo: 1,enabled: true});
    },
    //搜索用户名称
    NameRemoteMethod(userName) {
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize:1000,
          pageNo:1,
          userName:userName,
          enabled: true
        }
        this.inquireUserList(params)
      }, 200);
    },
    // 选择用户名称
    loginNameChange(val) {

    },
    inquireUserList(params){//查询公司名称
      this.$http('/user/list', null, 'get', null, params).then(res => {
        if(res.result == 200){
          let list = res.data.list;
          this.userNameList = list;
        }
      })
    },
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
  },
}
</script>
<style scoped>
.nav_menu_m {
  margin-bottom: 20px;
}
.nav_menu_m .nav_item {
  display: inline-block;
  margin-right: 20px;
}
.nav_menu_m .nav_item .el-input,
.nav_menu_m .nav_item .el-cascader,
.nav_menu_m .nav_item .el-select {
  width: 250px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>