<template>
  <div class="marketingRecord">
    <el-container>
      <!-- 导航 -->
      <el-header>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          active-text-color="#3BC1C4"
          router
        >
          <el-menu-item index="/SMSmarketingRecord">短信营销记录</el-menu-item>
          <el-menu-item index="/machineCallingRecord" v-show="bol"
            >机器外呼记录</el-menu-item
          >
        </el-menu>
      </el-header>
      <!-- 内容 -->
      <el-main>
        <router-view name="third"></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "MarketingRecord",
  data() {
    return {
      msg: "营销记录",
      activeIndex: "/SMSmarketingRecord",
      bol: true,
    };
  },
  mounted() {
    this.judgeMenu();
  },
  methods: {
    judgeMenu() {
      //判断菜单
      let smark = sessionStorage.getItem("machineCalling");
      if (smark == "-1") {
        this.bol = false;
      } else {
        this.bol = true;
        let path = this.$route.path;
        if (path == "/machineCallingRecord") {
          this.activeIndex = "/machineCallingRecord";
        } else if (path == "/SMSmarketingRecord") {
          this.activeIndex = "/SMSmarketingRecord";
        }
      }
    },
  },
};
</script>

<style scoped="scoped">
.marketingRecord {
  box-shadow: 0px 0px 1px 1px lightgray;
  margin-bottom: 20px;
  min-height: 760px;
}
</style>
