<template>
  <div class="lineAnalysis">
    <div class="nav">
      <div class="callTime">日期：
        <el-date-picker
          v-model="condition.callEndAtStart"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00','23:59:59']"
          align="right">
        </el-date-picker>
      </div>
      <div class="province-style">省份：
        <el-select v-model="condition.province" placeholder="请选择省份">
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :value="item.id"
            :label="item.provinceName"
          ></el-option>
        </el-select>
      </div>
      <div class="line-style">线路：
        <el-select v-model="condition.lineId" placeholder="请选择线路">
          <el-option
            v-for="item in lineList"
            :key="item.id"
            :value="item.id"
            :label="item.lineName"
          ></el-option>
        </el-select>
      </div>
      <div class="city-style">城市：
        <el-select v-model="condition.city" placeholder="请选择城市">
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :value="item.id"
            :label="item.cityName"
          ></el-option>
        </el-select>
      </div>
      <div class="operate">
        <el-button type="primary" @click="inquire()">查询</el-button>
      </div>
    </div>
    <div class="table-style">
      <el-table :data="tableData" stripe style="width: 100%" @sort-change="batchOrder" border>
        <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
        <el-table-column prop="gmtCreated" label="日期" min-width="237" align="left" sortable='custom'></el-table-column>
        <el-table-column prop="line" label="线路" min-width="237" align="left" sortable='custom'></el-table-column>
        <el-table-column prop="province" label="省份" min-width="130" align="left" sortable='custom'></el-table-column>
        <el-table-column prop="city" label="城市" min-width="130" align="left"></el-table-column>
        <el-table-column prop="connectionRate" label="接通率" min-width="130" align="left" sortable='custom'></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="currentPage_data"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name:'lineAnalysis',
  data(){
    return {
      pickerOptions: {
        disabledDate(time) {
            const start = new Date();
            start.setHours(23);
            start.setMinutes(59);
            start.setSeconds(59);
            start.setMilliseconds(59);
            return time.getTime() > start;
        },
        onPick({ maxDate, minDate }){
          if(minDate){
            sessionStorage.setItem('theTime', minDate.getTime());
          }
        },
        shortcuts: [{
          text: '本月记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本周记录',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('thisWeek');
            start = parseInt(start);
            picker.$emit('pick', [new Date(start), end]);
          }
        }, {
          text: '本日记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '此刻',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('theTime');
            if(start){
              start = parseInt(start);
              picker.$emit('pick', [new Date(start), end]);
              sessionStorage.removeItem('theTime');
            }
          }
        }]
      },
      tableData:[],
      currentPage:null,
      total:null,
      condition:{
        callEndAtStart:'',
        province:'',
        city:'',
        lineId:''
      },
      provinceList:[],
      cityList:[],
      lineList:[],
    }
  },
  mounted(){
    let start = new Date();
    let end = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setTime(end.getTime() - 3600 * 1000 * 24);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.callEndAtStart = [start,end];
    this.theWeek();
    this.inquire();
  },
  methods:{
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
    currentPage_data(index){//当前页

    },
    inquire(){//查询
      
    },
    batchOrder({column, prop, order}){//排序
      console.log(column, prop, order)
    },
  },
  beforeDestroy(){
    
  }
}
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination{
  float: right;
}
.nav{
  width: 1200px;
}
.nav .el-input,.nav .el-select {
  width: 300px;
}
.province-style,.callTime,.city-style,.line-style {
  width: 700px;
  margin-bottom: 20px;
}
.callTime,.line-style{
  float: right;
}
</style>