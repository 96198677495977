<template>
  <div class="serverGroup">
    <div class="s_nav">
      <div class="s_nav_item">
        用户名称：
        <el-select
          v-model="userLoginName"
          filterable
          remote
          :remote-method="remoteMethodLoginName"
          @focus="NameRemoteFocus"
          clearable
          placeholder="请选择用户名称"
        >
          <el-option
            v-for="(item,index) in userLoginNameList"
            :key="index"
            :label="item.userName"
            :value="item.loginName"
          ></el-option>
        </el-select>
      </div>
      <div class="s_nav_item">
        分组名称：
        <el-input v-model="name" placeholder="请输入分组名称" clearable></el-input>
      </div>
    </div>
    <div class="btn_server">
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button type="primary" @click="serverAdd('add')">新增</el-button>
    </div>
    <!-- 服务器表格 -->
    <el-table
      :data="serverGroupTableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
    >
      <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
      <el-table-column
        prop="userLoginName"
        label="用户登录名称"
        min-width="120"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="name" label="分组名称" min-width="120" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="serverCount" label="服务器数量" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="serverClick(scope.row)"
          >{{scope.row.serverCount}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" min-width="200" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="gmtCreated" label="创建时间" width="170" align="center"></el-table-column>
      <el-table-column prop label="操作" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="serverAdd('modify', scope.row )">修改</el-button>
          <el-button type="text" size="small" @click="serverDelect(scope.row)" style="color: #ff0000b0">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="serverGroupPageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="serverGroupTotal"
      :pager-count="5"
    ></el-pagination>
    <!-- 服务器分组详情列表 -->
    <el-dialog
      :visible.sync="detailsTableShow"
      width="700px"
      class="connectedTable"
      @close="detailsTableShowClose"
    >
      <template slot="title">
        <i class="el-icon-data-analysis dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">服务器分组详情</span>
      </template>
      <el-table :data="detailsTableData" border="" style="width: 100%" size="mini">
        <el-table-column type="index" label="编号" width="50" align="center"></el-table-column>
        <el-table-column prop="serverName" label="服务器名称" min-width="100"></el-table-column>
        <el-table-column prop="gmtCreated" label="创建时间" width="150" align="center"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="detailsPageNoChange"
        :current-page.sync="detailsPageNo"
        :page-size="10"
        :page-sizes="[10, 20, 30, 40, 100]"
        layout="total, prev, pager, next, jumper"
        :total="detailsTotal"
        :pager-count="5"
      ></el-pagination>
    </el-dialog>
    <!-- 新增or修改 -->
    <el-dialog
      :visible.sync="serverGroupShow"
      width="500px"
      class="connectedTable"
      @close="serverGroupClose"
    >
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">{{serverGroupTitle}}</span>
      </template>
      <el-form :model="serverGroupForm" label-position="left" :inline="true">
        <el-form-item label="分组名称：" label-width="100px">
          <el-input v-model="serverGroupForm.name" placeholder="请选择分组名称" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="用户名称：" v-if="serverGroupTitle === '新增'" label-width="100px">
          <el-select
            v-model="serverGroupForm.userLoginName"
            filterable
            remote
            :remote-method="remoteMethodLoginName"
            @focus="NameRemoteFocus"
            clearable
            placeholder="请选择用户名称"
            style="width: 350px"
          >
            <el-option
              v-for="(item,index) in userLoginNameList"
              :key="index"
              :label="item.userName"
              :value="item.loginName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务器：" v-if="serverGroupTitle === '新增'" label-width="100px">
          <el-select
            v-model="serverGroupForm.serverNameList"
            clearable
            placeholder="请选择服务器"
            multiple
            collapse-tags
            filterable
            style="width: 350px"
          >
            <el-option
              v-for="(item,index) in serverNamesList"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" label-width="100px">
          <el-input
            v-model="serverGroupForm.remark"
            type="textarea"
            :rows="4"
            placeholder="请输入备注"
            clearable
            style="width: 350px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button key="submit" type="primary" @click="serverGroupOk">确定</el-button>
          <el-button key="back" @click="serverGroupShow = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "serverGroup",
  data() {
    return {
      serverGroupForm: {
        name: "",
        userLoginName: "",
        serverNameList: [],
        remark: ""
      },
      userLoginNameList: [],
      serverNamesList: [],
      serverGroupShow: false,
      serverGroupTitle: "",
      serverGroupPageNo: 1,
      serverGroupPageSize: 10,
      serverGroupTotal: 0,
      serverGroupTableData: [],
      loadingTable: false,
      detailsTableShow: false,
      detailsTableData: [],
      detailsPageNo: 1,
      detailsTotal: 0,
      groupId: "",
      userLoginName: "",
      name: ""
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击删除
    serverDelect(val) {
      let data = {
        id: val.id
      }
      this.$confirm("删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
      .then(() => {
        this.$service('/serverInfo/deleteServerGroup',data).then(res=>{
          if(res.result == 200){
            this.inquire();
            this.$message({message:'删除成功',type:'success'});
          }
        })
      })
      .catch(() => {});
    },
    // 点击新增
    serverAdd(key, val) {
      if (key === "add") {
        this.getServerInfo({pageNo: 1, pageSize: 1000});
        this.serverGroupTitle = "新增";
      }
      if (key === "modify") {
        this.serverGroupTitle = "修改";
        this.serverGroupForm.name = val.name;
        this.serverGroupForm.remark = val.remark;
        this.groupId = val.id;
      }
      this.serverGroupShow = true;
    },
    // 确定新增
    serverGroupOk() {
      if (this.serverGroupTitle === "新增") {
        let data = {
          userLoginName: this.serverGroupForm.userLoginName,
          name: this.serverGroupForm.name,
          remark: this.serverGroupForm.remark,
          serverNameList: this.serverGroupForm.serverNameList,
        }
        this.$service("/serverInfo/addServerGroup",data).then(res => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.serverGroupShow = false;
            this.inquire(1);
          }
        })
      }
      if (this.serverGroupTitle === "修改") {
        let data = {
          id: this.groupId,
          name: this.serverGroupForm.name,
          remark: this.serverGroupForm.remark,
        }
        this.$service("/serverInfo/modifyServerGroup",data).then(res => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.serverGroupShow = false;
            this.inquire();
          }
        })
      }
    },
    // 关闭新增
    serverGroupClose() {
      this.serverGroupForm = {
        name: "",
        userLoginName: "",
        serverNameList: [],
        remark: ""
      };
      this.groupId = "";
    },
    // 用户名称搜索
    remoteMethodLoginName(userName) {
      this.inquireRemoteUser(userName);
    },
    // 用户名称获取焦点时
    NameRemoteFocus() {
      this.inquireRemoteUser();
    },
    //查询用户列表
    inquireUserList(params) {
      this.$http("/user/list", null, "get", null, params).then(res => {
        if (res.result == 200) {
          let list = res.data.list;
          this.userLoginNameList = list;
        }
      });
    },
    //查询列表
    inquireRemoteUser(userName) {
      let params = {
        pageSize: 50,
        pageNo: 1,
        userName: userName,
        primaryAccount: true,
        enabled: true
      };
      this.inquireUserList(params);
    },
    // 查询服务器信息
    getServerInfo(data) {
      this.$http("/serverInfo/list", null, "get", null, data).then(res => {
        if (res.result === 200) {
          let list = res.data.records;
          this.serverNamesList = list;
        }
      });
    },
    // 点击查看服务器
    serverClick(val) {
      this.groupId = val.id;
      this.detailsTableShow = true;
      this.inquireDetails(1);
    },
    // 关闭服务器分组详情列表
    detailsTableShowClose() {
      this.detailsTableData = [];
      this.groupId = "";
    },
    // 点击查询服务器分组详情列表
    inquireDetails(index) {
      if (index) {
        this.detailsPageNo = index;
      }
      let data = {
        pageNo: this.detailsPageNo,
        pageSize: 10,
        groupId: this.groupId
      };
      this.getServerGroupItemList(data);
    },
    // 查询服务器分组详情列表
    getServerGroupItemList(data) {
      this.detailsTableData = [];
      this.$http(
        "/serverInfo/serverGroupItemList",
        null,
        "get",
        null,
        data
      ).then(res => {
        if (res.result === 200) {
          let list = res.data.records;
          this.detailsTableData = list;
          this.detailsTotal = res.data.total;
        }
      });
    },
    // 服务器分组详情---分页
    detailsPageNoChange(index) {
      this.inquireDetails(index);
    },
    // 点击查询服务器分组列表
    inquire(index) {
      if (index) {
        this.serverGroupPageNo = index;
      }
      let data = {
        pageNo: this.serverGroupPageNo,
        pageSize: this.serverGroupPageSize,
        userLoginName: this.userLoginName,
        name: this.name
      };
      this.getServerGroupList(data);
    },
    // 服务器分组列表---分页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 服务器分组列表---每页
    sizeChange(index) {
      this.serverGroupPageSize = index;
      this.inquire(1);
    },
    // 查询服务器分组列表
    getServerGroupList(data) {
      this.loadingTable = true;
      this.$http("/serverInfo/serverGroupList", null, "get", null, data).then(
        res => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach(item => {
              item.gmtCreated;
            });
            this.serverGroupTableData = list;
            this.serverGroupTotal = res.data.total;
          }
        }
      ).catch(err => {
        this.loadingTable = false;
      })
    }
  }
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.serverGroup .connectedTable >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
.serverGroup .connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.s_nav {
  margin-bottom: 20px;
}
.s_nav .s_nav_item {
  display: inline-block;
}
.s_nav .s_nav_item .el-select, .s_nav .s_nav_item .el-input {
  width: 250px;
  margin-right: 20px;
}
</style>
