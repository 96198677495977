<template>
  <div class="otherSet">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="短信长链分组管理" name="first">
        <longUrlGroupManage
          v-if="shortGroupManageShow"
          :loginName="loginName"
        ></longUrlGroupManage>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import longUrlGroupManage from "./longUrlGroupManage";
export default {
  name: "otherSet",
  props: ["loginName"],
  components: {
    longUrlGroupManage,
  },
  data() {
    return {
      activeName: "first",
      shortGroupManageShow: false,
    };
  },
  mounted() {
    this.shortGroupManageShow = true;
  },
  methods: {
    handleClick(val) {
      console.log(val);
    },
  },
};
</script>
<style scoped></style>
