<template>
  <el-steps :active="active" direction="vertical" :space="110">
    <el-step v-for="(item ,i) in stepsList" :key="i" :status="item.finishStatus">
      <template slot="title">
        <div>批次编号：{{item.batchNumber}}</div>
      </template>
      <template slot="description">
        <div>项目名称：{{item.projectName}}</div>
        <div>任务名称：{{item.taskName}} (拨打总数：{{item.totalCallCount}})</div>
        <div>队列名称：{{item.teamName}} (在线：{{item.onlineCount}},等待任务：{{item.waitingTaskCount}})</div>
        <div>线路名称：{{item.routeName}}</div>
      </template>
    </el-step>
  </el-steps>
</template>
<script>
export default {
  name: "taskSteps",
  props: {
    active: {
      // 当前步骤
      type: Number,
      default: 0
    },
    stepsList: {
      // 任务步骤数据
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style scoped>
</style>
