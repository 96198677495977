import Vue from 'vue'
import loadingDialog from '../pages/loadingDialog.vue'

const LoadingConstructor = Vue.extend(loadingDialog)

LoadingConstructor.prototype.close = function() {
  this.visible = false;
};

const loading = (options) => {
  let instance = new LoadingConstructor({
    el: document.createElement('div'),
    data: options
  });
  
  document.body.appendChild(instance.$el);
  Vue.nextTick(() => {
    instance.visible = true;
  });
  
  return instance
}

export default {
  install() {
    if (!Vue.$loading) {
      Vue.$loading = loading
    }
    Vue.mixin({
      created() {
        this.$loading = Vue.$loading
      }
    })
  }
}
