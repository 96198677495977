<template>
  <div class="menu">
    <audio id="remoteAudio"></audio>
    <audio id="localAudio"></audio>
    <el-container class="nav" v-if="showContainer">
      <!-- 左侧边栏 -->
      <el-aside class="aside" ref="left" id="leftAside">
        <el-menu
          :default-active="index"
          :collapse="isCollapse"
          class="el-menu-vertical-demo"
          background-color="#2d3a4b"
          text-color="#bfcbd9"
          active-text-color="#4095dc"
          router
          :collapse-transition="false"
        >
          <el-submenu v-for="item in menu" :index="item.num" :key="item.num">
            <template slot="title">
              <i :class="item.extraMsg.class"></i>
              <span>{{ item.cn }}</span>
            </template>
            <el-menu-item-group
              v-for="k in item.subMenus"
              :key="k.num"
              class="el-menu"
            >
              <el-menu-item :index="k.extraMsg.index">
                <i style="margin-left: 5px"></i>
                {{ k.cn }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
        <div class="version-number" v-if="versionNumberShow">
          版本号：{{ versionNumber }}
        </div>
      </el-aside>

      <!-- 右边 -->
      <el-container class="box" ref="right">
        <!-- 顶部 -->
        <el-header class="header_class">
          <svg
            data-v-4e6f274c=""
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            class="sign"
            @click="transform()"
            ref="icon"
          >
            <path
              data-v-4e6f274c=""
              d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"
            ></path>
          </svg>
          <div
            class="el-dropdown-my"
            :class="{
              'el-dropdown-my-p': !isPc,
              borderLeft: !webSipPhoneEnabled,
            }"
            ref="showInfo"
          >
            <div
              class="phone"
              v-if="$route.path == '/customService' && webSipPhoneEnabled"
            >
              <span v-if="hangUpShow">
                {{ minute >= 10 ? minute : "0" + minute }}:{{
                  second >= 10 ? second : "0" + second
                }}
              </span>
              <el-button
                class="button"
                size="mini"
                type="danger"
                @click="register"
                v-if="registerShow"
                >注册</el-button
              >
              <el-button
                class="button"
                size="mini"
                type="danger"
                v-else
                @click="unregister"
                >取消注册</el-button
              >
              <el-button
                class="button"
                size="mini"
                type="danger"
                @click="accept"
                v-if="accepted"
                >接听</el-button
              >
              <el-button
                class="button"
                size="mini"
                type="danger"
                @click="hangUp"
                v-if="hangUpShow"
                >挂断</el-button
              >
            </div>
            <el-tooltip
              v-if="roleMenu && roleMenu !== 'SIP_CLIENT'"
              :content="'5分钟内有' + errorNum + '个告警'"
              placement="left"
            >
              <el-button
                id="bellBtn"
                size="small"
                icon="el-icon-bell"
                class="newErrors"
                round
                @click="errorDrawerShow = true"
                >{{ errorNum }}</el-button
              >
            </el-tooltip>
            <div
              class="signal"
              v-if="$route.path == '/customService' && webSipPhoneEnabled"
            >
              <el-tooltip>
                <div slot="content">{{ statusLabel }}</div>
                <span
                  class="range green"
                  v-if="statusLabel == '注册成功'"
                ></span>
                <span
                  class="range orange"
                  v-else-if="statusLabel == '通话中'"
                ></span>
                <span class="range red" v-else></span>
              </el-tooltip>
            </div>
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ userName }}
              </span>
              <el-dropdown-menu
                slot="dropdown"
                class="showList"
                :class="{ showPasswd: !isAdmin }"
              >
                <el-dropdown-item v-if="!isAdmin">
                  <span>域名：{{ domain }}</span>
                  <el-button
                    type="text"
                    size="small"
                    v-clipboard:copy="domain"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    >复制</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item v-if="!isAdmin">
                  <span>密码：{{ clientPasswd }}</span>
                  <el-button
                    type="text"
                    size="small"
                    v-clipboard:copy="clientPasswd"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    >复制</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item v-if="clinetNameShow">
                  <a href="javascript:void(0)" @click="customerClick"
                    >修改名称</a
                  >
                </el-dropdown-item>
                <el-dropdown-item v-if="isAdmin">
                  <a href="javascript:void(0)" @click="userInformation"
                    >用户信息</a
                  >
                </el-dropdown-item>
                <el-dropdown-item v-if="isAdmin">
                  <a href="javascript:void(0)" @click="passwordClick"
                    >修改密码</a
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <a href="javascript:void(0)" @click="logOut()">退出</a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <!-- 中间内容 -->
        <el-main class="nav_main">
          <div class="nav_div">
            <router-view
              name="second"
              v-if="isRouterAlive"
              :accountType="accountType"
              :inWhitelist="inWhitelist"
              :server="server"
              :roleMenu="roleMenu"
              :loginName="loginName"
              :parentLoginName="parentLoginName"
              :uuid="uuid"
              :powers="powers"
              :WebSocket="WebSocket"
              :userName="userName"
              :clientPasswd="clientPasswd"
            ></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- 修改客服名称 -->
    <el-dialog
      title="修改名称"
      class="customDialog"
      :visible.sync="customerShow"
      width="350px"
      @close="closeDialog"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改名称</span>
      </template>
      <el-form label-position="left">
        <el-form-item label="客服名称：" label-width="85px">
          <el-input
            v-model="customerName"
            placeholder="请输入客服名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="customerName_submit">确定</el-button>
        <el-button @click="customerName_cencel">取消</el-button>
      </div>
    </el-dialog>
    <!-- 修改客服密码 -->
    <el-dialog
      title="修改密码"
      class="customDialog"
      :visible.sync="passwordDialogShow"
      width="400px"
      @close="closePasswdDialog"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改密码</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="密码：" required>
          <el-input
            v-model="password"
            show-password
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码：" required>
          <el-input
            v-model="newPassword"
            show-password
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" required>
          <el-input
            v-model="confirmPassword"
            show-password
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="passwordName_submit">确定</el-button>
        <el-button @click="passwordName_cancel">取消</el-button>
      </div>
    </el-dialog>
    <!-- 用户信息 -->
    <el-dialog
      title="用户信息"
      class="userInformationDialog"
      :visible.sync="userInformationShow"
      @close="userInformationClose"
      width="420px"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">用户信息</span>
      </template>
      <el-form label-position="left">
        <el-form-item label="用户名：" :label-width="labelWidth">
          <div class="name_m">
            <div>
              <span v-if="!isUserNameInput">{{ userName }}</span>
              <el-input
                v-if="isUserNameInput"
                v-model="userNameModify"
                placeholder="请输入用户名"
              ></el-input>
            </div>
            <div style="width: 80px">
              <el-button
                v-if="!isUserNameInput"
                type="text"
                @click="modifyUserName"
                >修改</el-button
              >
              <el-button
                v-if="isUserNameInput"
                type="text"
                @click="submit_username"
                >保存</el-button
              >
              <el-button
                v-if="isUserNameInput"
                type="text"
                @click="cancel_username"
                >取消</el-button
              >
            </div>
          </div>
        </el-form-item>
        <el-form-item label="登录名：" :label-width="labelWidth">
          <span>{{ loginName }}</span>
        </el-form-item>
        <el-form-item label="话费余额：" :label-width="labelWidth">
          <span>{{ phoneBalance }}</span>
          <span v-if="isOrderDeduction">
            (其中冻结 {{ orderDeduction }} ) 元
            <el-tooltip class="item" effect="light" placement="top-start">
              <div slot="content">
                <div>系统自动按照实时成单量冻结相应金额,</div>
                <div>冻结金额将统一在当日结算时扣除</div>
              </div>
              <i class="el-icon-question icon-color"></i>
            </el-tooltip>
          </span>
        </el-form-item>
        <el-form-item label="短信余额：" :label-width="labelWidth">
          <span>{{ balance }}</span>
        </el-form-item>
        <el-form-item label="透支额度：" :label-width="labelWidth">
          <span>{{ overdraft }}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 报错信息抽屉 -->
    <el-drawer
      :visible.sync="errorDrawerShow"
      direction="rtl"
      :with-header="false"
      class="drawerError_style"
    >
      <div style="padding: 20px">
        <span style="font-weight: bold; line-height: 30px">告警信息</span>
        <div>
          弹框提示
          <el-switch v-model="errorNotifyShow" active-color="#13ce66">
          </el-switch>
        </div>
      </div>
      <div class="drawer_time_line">
        <el-timeline>
          <el-timeline-item
            v-for="(item, i) in errorList"
            :key="i"
            :timestamp="item.timestamp"
            placement="top"
          >
            <el-card>
              <p>{{ item.info }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import SIP from "sip.js";
export default {
  name: "Menu",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      showContainer: false,
      errorNotifyShow: true,
      errorTimer: null,
      errorList: [],
      errorNum: 0,
      errorDrawerShow: false,
      isOrderDeduction: false,
      orderDeduction: "",
      accountType: "",
      server: "",
      versionNumberShow: true,
      versionNumber: this.$constants.versionNumber,
      roleMenu: "",
      parentLoginName: "",
      isRouterAlive: false,
      msg: "菜单Menu",
      userName: "",
      bol: false,
      show: false,
      menu: [],
      isCollapse: false,
      count: 1,
      clientPasswd: "",
      domain: "",
      customerName: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      customerShow: false,
      clinetNameShow: false,
      isAdmin: false,
      passwordDialogShow: false,
      userInformationShow: false,
      loginName: "",
      phoneBalance: "",
      balance: "",
      overdraft: "",
      labelWidth: "90px",
      uuid: "",
      WebSocket: null,
      requestsNumber: 0,
      message: "",
      inWhitelist: false,
      isUserNameInput: false,
      userNameModify: "",
      powers: [],
      userTimes: null,
      isPc: false,
      ua: null,
      minute: 0,
      second: 0,
      statusLabel: "未连接",
      userAgent: null,
      currentSession: null,
      convrsationOptions: {
        media: {
          constraints: {
            audio: true,
            video: false,
          },
          render: {
            remote: document.getElementById("remoteAudio"),
            local: document.getElementById("localAudio"),
          },
        },
      },
      webSipPhoneDomain: "",
      webSipPhoneEnabled: false,
      registerShow: true,
      hangUpShow: false,
      accepted: false,
    };
  },
  computed: {
    index() {
      let path = "/" + this.$route.path.split("/")[1];
      return path;
    },
  },
  watch: {
    //监听路由
    "$route.path": {
      immediate: true,
      handler(newValue) {
        if (
          newValue == "/customService" ||
          newValue == "/callbackList" ||
          newValue == "/informationSet"
        ) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }
      },
    },
  },
  mounted() {
    window.onresize = () => {
      this.IsPC();
    };
    // this.userNews();
    this.userRefresh();
    //用户列表
    this.menuPermission();
    let errorData = this.$common.getCookie("warningInfo");
    if (errorData) {
      this.errorList = JSON.parse(errorData);
      this.errorNum = this.errorList.length;
    } else {
      this.errorList = [];
      this.errorNum = 0;
    }
    this.errorTimer = setInterval(() => {
      if (this.errorList.length <= 0) {
        return;
      }
      let nowTime = new Date().getTime();
      for (let i in this.errorList) {
        let diff = nowTime - this.errorList[i].time;
        if (diff > 300000) {
          this.errorList.splice(i, 1);
          this.errorNum--;
        }
      }
      this.$common.setCookie("warningInfo", JSON.stringify(this.errorList), 1);
    }, 10000);
  },
  methods: {
    register() {
      this.userAgent.register();
    },
    unregister() {
      this.userAgent.unregister();
    },
    sipRegister() {
      var config = {
        uri: this.userName + "@" + this.webSipPhoneDomain.split(":")[0],
        wsServers: "ws://" + this.webSipPhoneDomain,
        authorizationUser: this.userName,
        displayName: this.userName,
        password: this.clientPasswd,
        allowLegacyNotifications: true,
        autostart: true,
        register: false,
        rel100: SIP.C.supported.SUPPORTED,
      };
      this.userAgent = new SIP.UA(config);

      this.userAgent.on("connecting", (args) => {
        console.log("开始连接");
      });
      // 连接完毕
      this.userAgent.on("connected", () => {
        console.log("连接完毕");
      });
      // 主动取消注册或注册后定期重新注册失败
      this.userAgent.on("unregistered", (response, cause) => {
        this.registerShow = true;
        console.log("主动取消注册或注册后定期重新注册失败");
        this.statusLabel = "注册失败";
      });
      // 注册成功
      this.userAgent.on("registered", () => {
        this.registerShow = false;
        this.statusLabel = "注册成功";
      });
      // websocket 连接失败
      this.userAgent.on("disconnected", () => {
        console.log("连接失败");
        this.statusLabel = "注册失败";
      });
      this.userAgent.on("invite", (session) => {
        console.log("有电话呼入");
        this.accepted = true;
        this.currentSession = session;
        this.sessionEvent(session);
      });
    },
    sessionEvent(session) {
      session.on("rejected", (response, cause) => {
        console.log("拒绝");
      });
      session.on("bye", (response, cause) => {
        console.log("挂断");
        this.statusLabel = "注册成功";
        this.hangUpShow = false;
        this.accepted = false;
        this.minute = 0;
        this.second = 0;
      });
      session.on("accepted", (response, cause) => {
        console.log("接听成功");
        this.hangUpShow = true;
        this.statusLabel = "通话中";
        setInterval(() => {
          this.second += 1;
          if (this.second == 60) {
            this.second = 0;
            this.minute += 1;
          }
        }, 1000);
      });
      session.on("cancel", (response, cause) => {
        console.log("取消");
      });
    },
    accept() {
      this.currentSession.accept(this.convrsationOptions);
    },
    hangUp() {
      if (this.currentSession instanceof Object) {
        if (this.currentSession.hasAnswer) {
          this.currentSession.bye();
        } else if (this.currentSession.isCanceled == false) {
          this.currentSession.cancel();
        } else {
          this.currentSession.reject();
        }
      }
    },
    modifyUserName() {
      this.isUserNameInput = true;
      this.userNameModify = this.userName;
    },
    // 保存修改用户名
    submit_username() {
      this.isUserNameInput = true;
      this.$service("/user/updateUserName", {
        userName: this.userNameModify,
      }).then((res) => {
        if (res.result === 200) {
          this.isUserNameInput = false;
          this.$message.success("保存成功");
          this.getUserInfo();
        }
      });
    },
    // 取消修改用户名
    cancel_username() {
      this.isUserNameInput = false;
    },
    // 关闭用户信息
    userInformationClose() {
      this.isUserNameInput = false;
      this.userNameModify = "";
    },
    userInformation() {
      //点击用户信息
      this.userInformationShow = true;
      this.getUserInfo();
    },
    // 查看用户信息
    getUserInfo() {
      this.$http("/user/userInfo", null).then((res) => {
        if (res.result === 200) {
          this.isOrderDeduction = false;
          this.orderDeduction = res.data.orderDeduction;
          if (this.orderDeduction && this.orderDeduction > 0) {
            this.isOrderDeduction = true;
          }
          this.userName = res.data.userName;
          this.loginName = res.data.loginName;
          this.phoneBalance = res.data.phoneBalance + " 元";
          this.balance = res.data.balance + " 条";
          this.overdraft = res.data.overdraft + " 元";
        }
      });
    },
    reload() {
      //刷新页面
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    passwordClick() {
      //点击修改密码
      this.passwordDialogShow = true;
    },
    passwordName_submit() {
      //确定修改密码
      let data = {
        password: this.password,
        newPassword: this.newPassword,
      };
      if (data.password && data.newPassword) {
        if (data.newPassword === this.confirmPassword) {
          this.$http("/user/resetPassword", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "修改成功", type: "success" });
              this.passwordName_cancel();
            }
          });
        } else {
          this.$message({ message: "新密码与确认密码不一致", type: "warning" });
        }
      } else {
        this.$message({ message: "密码不能为空", type: "warning" });
      }
    },
    passwordName_cancel() {
      //取消修改密码
      this.passwordDialogShow = false;
    },
    closePasswdDialog() {
      //关闭密码修改
      this.password = "";
      this.newPassword = "";
      this.confirmPassword = "";
    },
    customerName_submit() {
      //确定修改名称
      let data = {
        name: this.customerName,
      };
      this.$http("/sip/client/modifySelfInfo", data).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "修改成功", type: "success" });
          this.customerShow = false;
        }
      });
    },
    customerName_cencel() {
      //取消修改名称
      this.customerShow = false;
    },
    customerClick() {
      //点击修改名称
      this.customerShow = true;
    },
    closeDialog() {
      //关闭客服修改
      this.customerShow = false;
    },
    // 账号覆盖刷新
    userRefresh() {
      if (!this.userTimes) {
        this.userTimes = setInterval(() => {
          this.$http("/user/isAlive", null).then((res) => {
            if (res.result === 200) {
              if (this.loginName !== res.data.userLoginName) {
                if (this.userTimes) {
                  clearInterval(this.userTimes);
                  this.userTimes = null;
                }
                this.$alert("会话已过期, 请刷新页面", "提示", {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    this.menuPermission("refresh");
                    this.$message({
                      type: "success",
                      message: "页面已刷新",
                    });
                  },
                });
              }
            }
          });
        }, 60 * 1000);
      }
    },
    // 账号覆盖页面刷新导航
    userRefreshGotoUrl(res) {
      let route = "";
      let newArr = this.$common.deepClone(res.data.menus);
      if (newArr && newArr.length > 0) {
        let menu = newArr[0].subMenus;
        if (menu) {
          route = JSON.parse(menu[0].extraMsg).index;
        } else {
          route = JSON.parse(newArr[0].extraMsg).index;
        }
      }
      if (res.data.indexUrl) {
        route = res.data.indexUrl;
      }
      this.$router.push(route);
    },
    menuPermission(key_type) {
      //菜单权限
      this.showContainer = false;

      this.$http("/user/userInfo", null).then((res) => {
        if (res.result == 200) {
          this.roleMenu = res.data.role;
          this.server = res.data.server;
          this.accountType = res.data.accountType;
          this.orderDeduction = res.data.orderDeduction;
          this.powers = res.data.powers;
          this.isOrderDeduction = false;
          if (this.orderDeduction && this.orderDeduction > 0) {
            this.isOrderDeduction = true;
          }
          let menuArr = this.$common.deepClone(res.data.menus),
            str = "",
            menu = null,
            i = 0,
            j = 0;
          this.userName = res.data.userName;
          this.parentLoginName = res.data.parentLoginName;
          this.loginName = res.data.loginName;
          this.phoneBalance = res.data.phoneBalance + " 元";
          this.balance = res.data.balance + " 条";
          this.overdraft = res.data.overdraft + " 元";
          this.clientPasswd = res.data.clientPasswd;
          this.customerName = res.data.userName;
          let index = str.indexOf("/machineCalling");
          sessionStorage.setItem("machineCalling", index);

          if (res.data.role == "SIP_CLIENT") {
            this.clinetNameShow = true;
            //查询坐席状态
            this.$http("/sip/client/regist", null, "get", null, null).then(
              (registRes) => {
                if (registRes.result === 200) {
                  this.domain = registRes.data.domain;
                  if (this.$route.path == "/customService") {
                    this.webSipPhoneDomain = registRes.data.webSipPhoneDomain;
                    this.webSipPhoneEnabled = registRes.data.webSipPhoneEnabled;
                    if (this.webSipPhoneEnabled) {
                      // 创建软电话
                      this.sipRegister();
                    }
                  }
                  // 是否可以使用开场白
                  this.$http("/grayInfo", null, "get", null, {
                    type: "prologue",
                    user: registRes.data.number,
                  }).then((grayInfoRes) => {
                    this.isRouterAlive = true;
                    this.showContainer = true;
                    this.$nextTick(() => {
                      this.IsPC();
                    });
                    if (grayInfoRes.result === 200) {
                      this.inWhitelist = grayInfoRes.data.inWhitelist;
                      if (grayInfoRes.data.inWhitelist) {
                        // 菜单设置
                        menuArr.map((item) => {
                          item.extraMsg = JSON.parse(item.extraMsg);
                          menu = item.subMenus;
                          item.num = i.toString();
                          i++;
                          if (menu) {
                            menu.forEach((val, k) => {
                              val.extraMsg = JSON.parse(val.extraMsg);
                              val.num = j.toString();
                              j++;
                              str += val.extraMsg.index + "--";
                            });
                          }
                        });
                        this.menu = menuArr;
                      } else {
                        // 菜单设置
                        menuArr.map((item) => {
                          item.extraMsg = JSON.parse(item.extraMsg);
                          menu = item.subMenus;
                          item.num = i.toString();
                          i++;
                          if (menu) {
                            for (let n = 0; n < menu.length; n++) {
                              menu[n].extraMsg = JSON.parse(menu[n].extraMsg);
                              if (
                                menu[n].extraMsg.index === "/prologueManage"
                              ) {
                                menu.splice(n, 1);
                                n--;
                              }
                              menu[n].num = j.toString();
                              j++;
                              str += menu[n].extraMsg.index + "--";
                            }
                          }
                        });
                        this.menu = menuArr;
                      }
                    }
                  });
                }
              }
            );
            // 检测浏览器
            let myBrowser = this.myBrowser();
            if (myBrowser !== "Chrome") {
              this.$notify({
                title: "警告",
                type: "warning",
                offset: 60,
                duration: 0,
                dangerouslyUseHTMLString: true,
                message:
                  '<div>推荐使用谷歌浏览器，否则可能会有系统兼容问题， <a href="https://www.google.cn/chrome/" target="blank">点击下载谷歌浏览器</a></div>',
              });
            }
          }
          this.uuid = res.data.nettyUuid;
          if (res.data.role != "SIP_CLIENT") {
            this.isRouterAlive = true;
            this.showContainer = true;
            this.$nextTick(() => {
              this.IsPC();
            });
            // 菜单设置
            menuArr.map((item) => {
              item.extraMsg = JSON.parse(item.extraMsg);
              menu = item.subMenus;
              item.num = i.toString();
              i++;
              if (menu) {
                menu.forEach((val, k) => {
                  val.extraMsg = JSON.parse(val.extraMsg);
                  val.num = j.toString();
                  j++;
                  str += val.extraMsg.index + "--";
                });
              }
            });
            this.menu = menuArr;
            this.createWebSocket();
          }
          if (key_type === "refresh") {
            this.userRefreshGotoUrl(res);
          }
        }
      });
    },
    transform() {
      //菜单折叠
      this.isCollapse = !this.isCollapse;
      if (this.isCollapse) {
        //折叠
        this.versionNumberShow = false;
        this.$refs.showInfo.classList.add("showInfo-cover");
        this.$refs.icon.classList.add("rotate");
        this.$refs.icon.classList.remove("rotate-cover");
        //手机端默认收起菜单
        if (!this.isPc) {
          this.$refs.left.$el.classList.add("el-aside-cover-phone");
          this.$refs.right.$el.classList.add("box-cover-phone");
        } else {
          this.$refs.left.$el.classList.add("el-aside-cover");
          this.$refs.right.$el.classList.add("box-cover");
        }
      } else {
        //打开
        this.versionNumberShow = true;
        this.$refs.right.$el.classList.remove("box-cover");
        this.$refs.left.$el.classList.remove("el-aside-cover");
        this.$refs.showInfo.classList.remove("showInfo-cover");
        this.$refs.icon.classList.remove("rotate");
        this.$refs.icon.classList.add("rotate-cover");
        //手机端默认收起菜单
        if (!this.isPc) {
          this.$refs.left.$el.classList.remove("el-aside-cover-phone");
          this.$refs.right.$el.classList.remove("box-cover-phone");
        } else {
          this.$refs.left.$el.classList.remove("el-aside-cover");
          this.$refs.right.$el.classList.remove("box-cover");
        }
      }
    },
    // 检测浏览器
    myBrowser() {
      let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      let isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
      let isIE =
        userAgent.indexOf("compatible") > -1 &&
        userAgent.indexOf("MSIE") > -1 &&
        !isOpera; //判断是否IE浏览器
      let isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
      let isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
      let isSafari =
        userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
      let isChrome =
        userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
      if (isIE) {
        let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        let fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
          return "IE7";
        } else if (fIEVersion == 8) {
          return "IE8";
        } else if (fIEVersion == 9) {
          return "IE9";
        } else if (fIEVersion == 10) {
          return "IE10";
        } else if (fIEVersion == 11) {
          return "IE11";
        } else {
          return "0";
        } //IE版本过低
        return "IE";
      }
      if (isOpera) {
        return "Opera";
      }
      if (isEdge) {
        return "Edge";
      }
      if (isFF) {
        return "FF";
      }
      if (isSafari) {
        return "Safari";
      }
      if (isChrome) {
        return "Chrome";
      }
    },
    // 判断手机or电脑端
    IsPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      this.isPc = flag; //true为PC端，false为手机端
      //手机端默认收起菜单
      if (!this.isPc) {
        this.isCollapse = false;
      } else {
        this.isCollapse = true;
      }
      this.transform();
    },
    logOut() {
      //注销账号
      this.$http("/user/logout", null).then((res) => {
        if (res.result == 200) {
          sessionStorage.setItem("machineCalling", -1);
          this.show = false;
          this.$router.push("/");
          if (this.WebSocket) {
            this.WebSocket.close();
            this.WebSocket = null;
          }
          this.$notify.close();
          if (this.userAgent) {
            this.userAgent.unregister(); // 注销
            this.userAgent.stop({
              register: true,
            });
          }
        }
      });
    },
    // 复制成功
    onCopy(e) {
      this.$message({ message: "复制成功", type: "success" });
    },
    // 复制失败
    onError(e) {
      this.$message({ message: "复制失败", type: "error" });
    },
    clickadd() {
      this.$copyText(this.message).then(
        (e) => {
          this.$message.success("复制成功");
        },
        (e) => {
          this.$message.warning("复制失败");
        }
      );
    },
    createWebSocket() {
      //创建websocket
      if (this.WebSocket) {
        this.WebSocket.close();
        this.WebSocket = null;
        this.$notify.close();
      }
      if (!window.WebSocket) {
        window.WebSocket = window.MozWebSocket;
      }
      if (process.env.VUE_APP_ENVIRONMENT === "prod") {
        this.WebSocket = new WebSocket(
          "wss://huixiao.huiyu.org.cn/api/ws/clientHandler"
        );
      } else {
        this.WebSocket = new WebSocket(
          "wss://huixiao.test.huiyu.org.cn/api/ws/clientHandler"
        );
      }

      this.WebSocket.onmessage = (event) => {
        //接收数据
        let msg = JSON.parse(event.data);
        if (msg.eventType == "NOTIFICATION") {
          let errorTime = new Date().getTime();
          let timestamp = this.$common.transformTime(errorTime).substring(10);
          this.errorNum++;
          this.shake("bellBtn");
          this.errorList.unshift({
            time: errorTime,
            timestamp: timestamp,
            info: msg.message,
          });
          this.$common.setCookie(
            "warningInfo",
            JSON.stringify(this.errorList),
            1
          );
          if (!this.errorDrawerShow && this.errorNotifyShow) {
            let position = msg.position,
              notifyType = msg.type,
              duration = msg.duration,
              title = msg.title,
              message = msg.message;
            this.message = message;
            this.$notify({
              title: title,
              onClick: this.clickadd,
              message: message,
              position: position,
              type: notifyType,
              duration: duration,
              offset: 45,
            });
          }
        }
      };
      this.WebSocket.onopen = () => {
        //发送数据
        let data = {
          uuid: this.uuid,
          type: "LOGIN",
          subUuid: this.getUuid(),
        };
        this.WebSocket.send(JSON.stringify(data));
      };
      this.WebSocket.onclose = () => {
        // 断开连接后3秒后重连
        setTimeout(() => {
          this.createWebSocket();
        }, 3000);
      };
    },
    getUuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    shake(elemId) {
      let elem = document.getElementById(elemId);
      if (elem) {
        elem.classList.add("shake");
        setTimeout(() => {
          elem.classList.remove("shake");
        }, 800);
      }
    },
  },
  beforeDestroy() {
    if (this.WebSocket) {
      this.WebSocket.close();
      this.WebSocket.onclose = () => {
        console.log("WebSocket连接关闭");
      };
      this.WebSocket = null;
      this.$notify.close();
    }
    if (this.errorTimer) {
      clearInterval(this.errorTimer);
      this.errorTimer = null;
    }
    if (this.userTimes) {
      clearInterval(this.userTimes);
      this.userTimes = null;
    }
  },
};
</script>
<style>
.shake {
  animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.drawerError_style .el-drawer__body {
  height: 100%;
}
.drawerError_style .drawer_time_line {
  padding: 20px;
  overflow-x: auto;
  height: 88%;
}
.el-dropdown-my {
  display: inline-block;
  position: absolute;
  right: 160px;
  min-width: 100px;
  height: 50px;
  transition: all 0.5s;
}
.el-dropdown-my-p {
  right: 0 !important;
}
.newErrors {
  /* position: absolute; */
  display: inline-block;
}
.el-tabs__header {
  margin-bottom: 22px;
}
.projectQuestion-form .el-form-item {
  margin-bottom: 2px;
}
.dialog-title-my {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
}
.my-icon {
  font-size: 20px;
}
.my-title {
  font-weight: bold;
}
.download-tips .el-dialog {
  margin-top: 35vh !important;
  border-radius: 20px;
}
.download-tips .el-dialog__header {
  padding: 0;
}
.download-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}
.download-text {
  display: inline-block;
  position: relative;
  font-size: 17px;
  width: 170px;
  margin-left: 10px;
}
.loadingClass {
  font-size: 40px;
  color: #409eff;
}
.icon-color {
  color: #909399;
}
.nav_menu {
  width: 960px;
  position: relative;
}
.nav_menu .companyName,
.nav_menu .callTime,
.nav_menu .callCount,
.nav_menu .taskName,
.nav_menu .batchName {
  width: 530px;
  margin-bottom: 20px;
}
.nav_menu .el-input,
.nav_menu .el-select {
  width: 300px;
}
.nav_menu .callTime .el-input {
  width: 200px;
}
</style>

<style scoped="scoped">
.name_m {
  display: flex;
  justify-content: space-between;
}
.nav {
  width: 100%;
  height: 100%;
}
.box .nav_main {
  height: 100%;
  margin-top: 50px;
  background-color: #f0f2f5;
  color: #333;
  overflow: inherit;
}
.nav_div {
  /* min-width: 1315px; */
  padding: 24px;
  background: rgb(255, 255, 255);
  overflow: auto;
}
.nav_div::after {
  display: table;
  content: "";
  clear: both;
}
.version-number {
  font-size: 12px;
  text-align: left;
  width: 160px;
  color: #c0c4cc;
  background-color: #2d3a4b;
  height: 20px;
  line-height: 20px;
  text-indent: 8px;
}
.el-menu-vertical-demo {
  height: calc(100% - 20px);
  overflow: auto;
}
.userInformationDialog .el-input {
  width: 185px;
}
.el-dropdown-link {
  padding: 0 20px 0 10px;
}
.borderLeft {
  border-left: 1px solid lightgray;
}
.customDialog >>> .el-dialog__body {
  padding: 10px 20px;
}
.el-menu >>> .el-menu-item-group__title {
  padding: 0;
}
.menu {
  width: 100%;
  height: 100%;
}
.el-aside {
  width: 160px !important;
  height: 100%;
  /* min-height: 700px; */
  position: fixed;
  z-index: 4;
  background-color: #2d3a4b;
  color: #333;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box {
  margin-left: 160px;
  background: #f0f2f5;
  transition: all 0.5s;
  overflow-x: auto;
}
.el-header {
  width: 100%;
  background-color: white;
  color: #333;
  height: 50px !important;
  line-height: 50px;
  padding: 0;
  position: fixed;
  z-index: 999;
  box-shadow: 1px 1px 1px lightgray;
}
.sign {
  display: inline-block;
  font-size: 20px;
  vertical-align: 30%;
  margin-left: 10px;
  color: gray;
  margin: 10px 0px 0 10px;
}
.logo {
  height: 50px;
  margin-left: 5px;
}
.el-dropdown {
  display: inline-block;
  min-width: 100px;
  text-align: center;
  color: black;
  font-size: 16px;
  transition: all 0.5s;
}
.showPasswd {
  width: 250px !important;
}
.showList {
  width: 125px;
  line-height: 30px;
  border-radius: 5px;
  position: absolute;
  right: 170px;
  top: 60px;
  background: white;
  text-align: center;
  padding: 5px 0;
}
.showList a {
  display: block;
  color: black;
  font-size: 14px;
}
.showList span {
  display: inline-block;
  color: black;
  font-size: 12px;
}
.showList a:nth-of-type(2) {
  margin-top: 5px;
}

/* 折叠动画 */
.el-aside-cover {
  width: 64px !important;
  height: 100%;
  position: fixed;
  z-index: 4;
  background-color: #2d3a4b;
  color: #333;
}
.el-aside-cover-phone {
  width: 0 !important;
  height: 100%;
  position: fixed;
  z-index: 4;
  background-color: #2d3a4b;
  color: #333;
}
.box-cover-phone {
  margin-left: 0;
}
.box-cover {
  margin-left: 64px;
}
.showInfo-cover {
  right: 64px;
}
.showList-cover {
  right: 74px;
}
.rotate {
  /* transition: all .8s; */
  transform: rotate(180deg);
}
.rotate-cover {
  /* transition: all .8s; */
  transform: rotate(0deg);
}
.phone {
  display: inline-block;
}
.phone .text {
  margin-right: 10px;
}
.phone .button {
  margin: 0 10px 0 0;
}
.range {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}
.green {
  background-color: #52c41a;
}
.orange {
  background-color: #e6a23c;
}
.red {
  background-color: red;
}
.signal {
  border-left: 1px solid lightgray;
  display: inline-block;
  padding: 0 10px;
}
</style>
