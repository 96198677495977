<template>
  <div class="client">
    <div class="nav">
      <div class="callStatus">
        <div class="callStatus_item">
          用户名称：
          <el-select v-model="userNameId" placeholder="请选择账号"  filterable remote :loading="loadingLoginName" :remote-method="remoteMethodLoginName" @focus="userNameRemoteFocus" @change="userNameChange">
            <el-option
              v-for="item in userNameIdList"
              :key="item.id"
              :value="item.loginName"
              :label="item.userName"
            ></el-option>
          </el-select>
        </div>
        <div class="callStatus_item">
          状态：
          <el-select v-model="callStatus" placeholder="请选择状态" clearable>
            <el-option
              v-for="item in statusList"
              :key="item.status"
              :value="item.status"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
        <div class="callStatus_item">
          坐席名称：
          <el-input v-model="callerName" placeholder="请输入坐席名称" clearable></el-input>
        </div>
      </div>
      <div class="callStatus">
        <div class="callStatus_item">
          坐席编号：
          <i style="margin-left: 16px;"></i>
          <el-input v-model="callerNumber" placeholder="请输入坐席编号" clearable></el-input>
        </div>
        <div class="callStatus_item">
          队列：
          <el-select v-model="teamId" placeholder="请选择队列" clearable>
            <el-option
              v-for="item in teamList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
        <el-checkbox v-model="ignoreDisabled" style="margin: 0 0 0 40px">过滤禁用</el-checkbox>
        <el-tooltip content="勾选会过滤掉已经禁用的坐席" placement="right" effect="light">
          <i class="el-icon-question" style="color: #909399"></i>
        </el-tooltip>
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button v-if="roleMenu === 'SUPER_MANAGER'" type="primary" @click="()=>{addClient=true,clientPlayTitle='批量新增坐席'}" class="button-mar">新增坐席</el-button>
    <el-dropdown v-if="roleMenu === 'SUPER_MANAGER'" trigger="click" @command="handleCommand" style="margin-left: 10px;">
      <el-button type="primary">
        批量操作<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="enableBatch">批量启用</el-dropdown-item>
        <el-dropdown-item command="disableBatch">批量禁用</el-dropdown-item>
        <el-dropdown-item command="batchUpdatePassword">批量修改密码</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 坐席管理表 -->
    <el-table ref="multipleTable" :data="tableData" stripe style="width: 100%" border v-loading="loading" @selection-change="numberHandleSelectionChange">
      <el-table-column type="selection" width="45" align="center" v-if="roleMenu === 'SUPER_MANAGER'"></el-table-column>
      <el-table-column type="index" prop label="编号" width="60" align="center" v-if="roleMenu !== 'SUPER_MANAGER'"></el-table-column>
      <el-table-column prop="name" label="坐席名称" width='150' align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="number" label="坐席编号" width='150' align="center"></el-table-column>
      <el-table-column prop label="状态" width="110" align="center">
        <template slot-scope="scope">
          <el-tag :type="statusType['type_'+scope.row.id]" size="small">{{scope.row.clientStatus}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop label="网页登录密码" width="130" align="center" class="loginPasswd" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="passwdShow['passwd_'+scope.row.id] !== 'onVisible'">{{scope.row.loginPasswd}}</span>
          <!-- <span v-show="passwdShow['passwd_'+scope.row.id] == 'onVisible'">******</span>
          <i class="iconfont icon-yanjing mark1" @click="transformPasswd(scope.row.id)" ref="iconPasswd" v-show="passwdShow['passwd_'+scope.row.id] !== 'onVisible'"></i>
          <i class="iconfont icon-yanjing1 mark1" @click="transformPasswd(scope.row.id)" ref="iconPasswd" v-show="passwdShow['passwd_'+scope.row.id] == 'onVisible'"></i> -->
        </template>
      </el-table-column>
      <el-table-column prop='clientPasswd' label="软电话登录密码" width="150" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="ip" label="登录ip" width="160" align="center"></el-table-column>
      <el-table-column prop="realm" label="服务器ip" width="160" align="center"></el-table-column>
      <el-table-column prop="server" label="服务器" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="agent" label="代理客户端" min-width="285" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="gmtCreated" label="创建时间" width="170" align="center"></el-table-column>
      <el-table-column prop label="操作" :width="roleMenu === 'SUPER_MANAGER' ? 120 : 70" align="center" fixed="right">
        <template slot-scope="scope">
          <!-- <el-button type="text" size="small" @click="deleteShow(scope.row)">删除</el-button> -->
          <el-button type="text" size="small" @click="reviseShow(scope.row)">修改</el-button>
          <span v-if="roleMenu === 'SUPER_MANAGER'" style="margin-left: 10px;">
            <el-button type="text" size="small" @click="unProhibit(scope.row)" v-if="scope.row.orgStatus=='DISABLED'">启用</el-button>
            <el-button type="text" size="small" @click="prohibit(scope.row)" v-if="scope.row.orgStatus!=='DISABLED'">禁用</el-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40, 100]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增 -->
    <el-dialog :visible.sync="addClient" custom-class="el-addClient" width="400px" @close="addClient_cancel">
      <template slot="title">
				<i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">{{clientPlayTitle}}</span>
			</template>
      <el-form :model="form" :label-position="labelLeft" label-width="100px">
        <el-form-item label="账号：">
          <b>{{userNameLabel}}</b>
        </el-form-item>
        <el-form-item label="新增数量：" required v-if="clientPlayTitle === '批量新增坐席'">
          <el-input v-model="form.count" placeholder="请输入数量"></el-input>
        </el-form-item>
        <el-form-item label="新密码：" required v-if="clientPlayTitle === '批量修改密码'">
          <el-input v-model="form.newLoginPasswd" placeholder="请输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="addClient_submit()">提 交</el-button>
        <el-button @click="addClient = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="修改"
      @close="closeDialog"
      :visible.sync="reviseClient"
      custom-class="el-addClient"
      width="400px"
    >
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">修改</span>
      </template>
      <el-form :model="form" :label-position="labelLeft" label-width="100px">
        <el-form-item label="名称：" required>
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="密码：" required>
          <el-input
            v-model="form.loginPasswd"
            placeholder="请输入密码"
            autocomplete="new-password"
            ref="password"
            :type="type"
          ></el-input>
          <i class="iconfont icon-yanjing mark" @click="transform()" ref="icon"></i>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="reviseClient_submit()">提 交</el-button><el-button @click="reviseClient_cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "../../assets/icon-font/iconfont.css";
export default {
  name: "client",
  props:["roleMenu"],
  data() {
    return {
      clientPlayTitle: "",
      ignoreDisabled: true,
      callerName: "",
      teamList: [],
      teamId: "",
      userNameLabel: "",
      loadingLoginName:false,
      loading:false,
      passwdShow:{},
      statusType:{},
      type: "text",
      clientNumber: "",
      reviseClient: false,
      yanjing1Show: true,
      yanjingShow: false,
      passWordShow: true,
      textShow: false,
      deletedShow: false,
      tableData: null,
      currentPage: null,
      total: null,
      addClient: false,
      labelLeft: "left",
      form: {
        count: "",
        name: "",
        loginPasswd: "",
        userLoginName: "",
        newLoginPasswd: ""
      },
      formWidth: "90px",
      formsWidth: "60px",
      statusList: [
        {
          status: "USER_NOT_REGISTERED",
          label: "未注册"
        },
        {
          status: "USER_REGISTERED",
          label: "已注册"
        },
        {
          status: "WAITING_TASK",
          label: "等待任务"
        },
        {
          status: "DISABLED",
          label: "禁用"
        },
        {
          status: "TALKING",
          label: "通话中"
        },
        {
          status: "WRITING",
          label: "整理中"
        }
      ],
      callStatus: "",
      callerNumber: "",
      userNameId:'',
      userNameIdList:[],
      pageSize:10,
      server: '',
      numberMmultipleSelection: [],
      unifiedLoading: null
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-坐席管理";
    this.inquireUserList();
  },
  methods: {
    //查询队列
    inquireTeamName(params){
      this.teamList = [];
      this.$http('/sip/team/list',null,'get',null,params).then(res=>{
        if(res.result == 200){
          let list = res.data.records;
          this.teamList = list;
        }
      })
    },
    // 账号搜索框获取焦点
    userNameRemoteFocus() {
      let params = {
            pageSize:20,
            pageNo:1,
            enabled: true
          }
      this.inquireLoginName(params)
    },
    //搜索用户名称
    remoteMethodLoginName(userName){
      this.loadingLoginName = true;
      setTimeout(() => {
        this.loadingLoginName = false;
        let params = {
            pageSize:20,
            pageNo:1,
            userName:userName,
            enabled: true
          }
          this.inquireLoginName(params)
      }, 200);
    },
    //查询用户名称
    inquireLoginName(params){
      this.userNameIdList = [];
      this.$http('/user/list',null,'get',null,params).then(res=>{
        if(res.result == 200){
          let newuserNameList = res.data.list;
          newuserNameList.map(item=>{
            this.userNameIdList.push(item);
          })
          // this.userLoginName = this.loginNameList[0].loginName;
        }
      })
    },
    // 点击批量操作
    handleCommand(val) {
      if (val === "enableBatch") {
        this.batchEnabled();
      }
      if (val === "disableBatch") {
        this.batchDisable();
      }
      if (val === "batchUpdatePassword") {
        this.addClient = true;
        this.clientPlayTitle = "批量修改密码";
      }
    },
    // 表格坐席多选
    numberHandleSelectionChange(val) {
      this.numberMmultipleSelection = val;
    },
    // 批量启用
    batchEnabled() {
      if (this.numberMmultipleSelection.length > 0) {
        this.$confirm("批量启用, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.unifiedLoading = this.$loading();
          let list = [];
          this.numberMmultipleSelection.forEach(item => {
            list.push(item.number)
          })
          let data = {
            clientNumberList: list,
            userLoginName: this.userNameId
          };
          let path = "https://" + this.server + this.$constants.basePath;
          this.$service("/sip/client/enableBatch", data,'post',path,null).then(res => {
            this.unifiedLoading.close();
            if (res.result == 200) {
              this.$message({ message: "批量启用成功", type: "success" });
              this.$refs.multipleTable.clearSelection();
              this.inquire();
            } 
          });
        })
        .catch(() => {
          this.unifiedLoading.close();
        });
      } else {
        this.$message({message: "请选择坐席", type: "warning"});
      }
    },
    // 批量禁用
    batchDisable() {
      if (this.numberMmultipleSelection.length > 0) {
        this.$confirm("批量禁用, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.unifiedLoading = this.$loading();
          let list = [];
          this.numberMmultipleSelection.forEach(item => {
            list.push(item.number)
          })
          let data = {
            clientNumberList: list,
            userLoginName: this.userNameId
          };
          let path = "https://" + this.server + this.$constants.basePath;
          this.$service("/sip/client/disableBatch", data,'post',path,null).then(res => {
            this.unifiedLoading.close();
            if (res.result == 200) {
              this.$message({ message: "批量禁用成功", type: "success" });
              this.inquire();
              this.$refs.multipleTable.clearSelection();
            } 
          });
        })
        .catch(() => {
          this.unifiedLoading.close();
        });
      } else {
        this.$message({message: "请选择坐席", type: "warning"});
      }
    },
    // 选择账号名称
    userNameChange(val){
      this.userNameId = val;
      this.userNameIdList.forEach(item => {
        if (item.loginName === val) {
          this.server = item.server;
          this.userNameLabel = item.userName;
        }
      })
      this.teamId = "";
      this.inquireTeamName({
        "pageNo":1,
        "pageSize":1000,
        "loginName":val
      })
    },
    inquireUserList() {//查询用户--账号
      let params = {
        pageSize: 1000,
        pageNo: 1,
        enabled: true
      }
      this.$http('/user/list', null, 'get', null, params).then(res => {
        if (res.result == 200) {
          let list = res.data.list;
          this.userNameIdList = list;
        }
      }).then(()=>{
        this.$http("/user/userInfo", null).then(res=>{
          if (res.result == 200){
            this.userNameId = res.data.loginName;
            this.userNameIdList.forEach(item => {
              if (item.loginName === this.userNameId) {
                this.server =item.server;
                this.userNameLabel = item.userName;
              }
            })
            this.inquire(1);
            this.inquireTeamName({
              "pageNo":1,
              "pageSize":1000,
              "loginName":this.userNameId
            })
          }
        })
      })
    },
    currentPage_data(index) {//当前页
      this.inquire(index);
    },
    sizeChange(index) {//显示条数--分页
      this.pageSize = index;
      this.inquire(1);
    },
    inquire(index) {//查询列表
      if (index) {
        this.currentPage = index;
      }
      let params = {
          pageSize: this.pageSize,
          pageNo: index || this.currentPage,
          status: this.callStatus,
          number: this.callerNumber,
          loginName: this.userNameId,
          teamId: this.teamId,
          name: this.callerName,
        };
      if (this.ignoreDisabled) {
        params.ignoreDisabled = this.ignoreDisabled
      }
      this.inquireClientList(params);
    },
    inquireClientList(params){//查询坐席管理列表
      this.loading = true;
      this.$http("/sip/client/list", null, "get", null, params).then(res => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.list;
          this.total = res.data.count;
          for(let i=0;i<list.length;i++){
            if(list[i].status == "USER_REGISTERED"){
              this.statusType['type_'+list[i].id] = "success";
            }else if(list[i].status == "USER_NOT_REGISTERED"){
              this.statusType['type_'+list[i].id] = "info";
            }else if(list[i].status == "DISABLED"){
              this.statusType['type_'+list[i].id] = "danger";
            }else{
              this.statusType['type_'+list[i].id] = "warning";
            }
          }
          list.map(item => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.orgStatus = item.status;
            switch (item.status) {
              case "WAITING_TASK":
                item.clientStatus = "等待任务";
                break;
              case "USER_REGISTERED":
                item.clientStatus = "已注册";
                break;
              case "USER_NOT_REGISTERED":
                item.clientStatus = "未注册";
                break;
              case "DISABLED":
                item.clientStatus = "禁用";
                break;
              case "TALKING":
                item.clientStatus = "通话中";
                break;
              case "WRITING":
                item.clientStatus = "整理中";
                break;
            }
          });
          this.tableData = list;
        }
      });
    },
    addClient_submit() {
      if (this.clientPlayTitle === '批量新增坐席') {
        //新增提交
        let data = {
          userLoginName: this.userNameId,
          count: this.form.count
        };
        if (!data.count) {
          this.$message({ message: "信息不完整", type: "warning" });
          return;
        }
        let path = "https://" + this.server + this.$constants.basePath;
        this.unifiedLoading = this.$loading();
        this.$service("/sip/client/addBatch", data,'post',path,null).then(res => {
          this.unifiedLoading.close();
          if (res.result == 200) {
            this.$message({ message: "新增坐席成功", type: "success" });
            this.addClient = false;
            this.inquire(1);
          }
        });
      }
      if (this.clientPlayTitle === '批量修改密码') {
        // 确定批量修改密码
        let data = {
          userLoginName: this.userNameId,
          newLoginPasswd: this.form.newLoginPasswd
        }
        this.unifiedLoading = this.$loading();
        this.$http("/sip/client/batchUpdatePasswordUserLoginName",data).then(res => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            this.$message({ message: "批量修改成功", type: "success" });
            this.addClient = false;
            this.inquire();
          }
        })
      }
    },
    addClient_cancel() {
      //取消新增提交
      this.form.count = "";
      this.form.newLoginPasswd = "";
    },
    deleteShow(val) {
      //点击删除
      this.id = val.id;
      this.$confirm("删除坐席, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
        .then(() => {
          let params = {
            clientNumber: val.number
          };
          this.$http("/sip/client/delete", null, "get", null, params).then(
            res => {
              if (res.result == 200) {
                this.$message({ message: "删除成功", type: "success" });
                this.inquire();
              } 
            }
          );
        })
        .catch(() => {});
    },
    reviseShow(val) {
      //点击修改
      this.clientNumber = val.number;
      this.server = val.server;
      this.form.name = val.name;
      this.form.userLoginName = val.userLoginName;
      this.form.loginPasswd = val.loginPasswd;
      this.reviseClient = true;
    },
    reviseClient_submit() {//提交修改
      let data = {
        clientNumber: this.clientNumber,
        name: this.form.name,
        userLoginName: this.form.userLoginName,
        loginPasswd: this.form.loginPasswd
      };
      let path = "https://" + this.server + this.$constants.basePath;
      if (data.name && data.loginPasswd) {
        this.unifiedLoading = this.$loading();
        this.$service("/sip/client/modify", data,'post',path,null).then(res => {
          this.unifiedLoading.close();
          if (res.result == 200) {
            this.inquire();
            this.$message({ message: "修改成功", type: "success" });
          }
        });
      } else {
        this.$message({ message: "信息不完整", type: "warning" });
      }
      this.reviseClient = false;
    },
    reviseClient_cancel() {//取消修改
      this.reviseClient = false;
    },
    closeDialog() {//关闭新增弹窗
      this.form.name = "";
      this.form.userLoginName = "";
      this.form.loginPasswd = "";
    },
    transform() {//弹窗密码显示与隐藏
      if (this.type == "password") {
        this.$refs.icon.classList.remove("icon-yanjing1");
        this.$refs.icon.classList.add("icon-yanjing");
        this.type = "text";
      } else {
        this.$refs.icon.classList.remove("icon-yanjing");
        this.$refs.icon.classList.add("icon-yanjing1");
        this.type = "password";
      }
    },
    transformPasswd(id){//表格密码显示与隐藏
      if(!this.passwdShow['passwd_'+id]){
        this.passwdShow['passwd_'+id] = "onVisible";
      }else{
        this.passwdShow['passwd_'+id] =null;
      }
    },
    prohibit(val) {//点击禁用
      this.$confirm("【"+val.name+"】禁用, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
        .then(() => {
          let data = {
            clientNumber: val.number,
            userLoginName: val.userLoginName,
            disabled: true
          };
          let path = "https://" + val.server + this.$constants.basePath;
          this.unifiedLoading = this.$loading();
          this.$service("/sip/client/modify", data,'post',path,null).then(res => {
            this.unifiedLoading.close();
            if (res.result == 200) {
              this.$message({ message: "禁用成功", type: "success" });
              this.inquire();
            } 
          });
        })
        .catch(() => {});
    },
    unProhibit(val){//点击启用
      this.$confirm("【"+val.name+"】启用, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
        .then(() => {
          let data = {
            clientNumber: val.number,
            userLoginName: val.userLoginName,
            disabled: false,
          };
          let path = "https://" + val.server + this.$constants.basePath;
          this.unifiedLoading = this.$loading();
          this.$service("/sip/client/modify", data,'post',path,null).then(res => {
            this.unifiedLoading.close();
            if (res.result == 200) {
              this.$message({ message: "启用成功", type: "success" });
              this.inquire();
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped="scoped">
.mark1{
  right: 8px !important;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.el-select {
  width: 360px;
}
.callStatus .callStatus_item {
  display: inline-block;
}
.callStatus .el-select {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.callStatus .el-input {
  width: 260px;
  margin: 0 16px 20px 0;
}
.callerNumber {
  width: 500px;
  margin: 0 0 20px 0;
}
.callerNumber .el-input {
  width: 260px;
}
.loginPasswd {
  position: relative;
}
.loginPasswd span{
  display: inline-block;
}
.iconfont {
  position: absolute;
  right: 20px;
}
.passWordClose {
  display: none;
}
.textClose {
  display: none;
}
.button-mar {
  margin-top: 10px;
}
.mark {
  font-size: 20px;
  position: absolute;
  top: 2px;
  right: 18px;
  color: #708998;
}
.el-form {
  position: relative;
}
</style>
<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>

