<template>
  <div class="functionSwitch">
    <div class="nav"></div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button @click="addClick">新增</el-button>
    <el-switch
      v-model="enabled"
      @change="enabledChange"
      active-color="#13ce66"
      inactive-color="#ff4949"
      active-text="过滤已禁用"
      style="margin-left: 30px">
    </el-switch>
    <el-table 
      :data="tableData" 
      stripe 
      style="width: 100%" 
      border 
      v-loading="tableLoading"
    >
			<el-table-column type='index' prop="" label="编号" width="60" align='center'></el-table-column>
			<el-table-column prop="name" label="功能名称" width="" align='center'></el-table-column>
			<el-table-column prop="code" label="code" width="" align='center'></el-table-column>
			<el-table-column prop="remark" label="备注" width="" align='center'></el-table-column>
      <el-table-column prop="" label="启用" width="100" align='center'>
				<template slot-scope="scope">
					<el-switch
            v-model="scope.row.enabled"
            @change="enabledRowChange(scope.row)"
            :disabled="scope.row.switchLoading"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
				</template>
			</el-table-column>
      <el-table-column prop label="操作" width="90" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modifyClick(scope.row)">修改</el-button>
				</template>
      </el-table-column>
		</el-table>
		<!-- 分页 -->
		<el-pagination 
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增 -->
    <el-dialog
      :visible.sync="addSwitchInfoShow"
      custom-class="el-addProject"
      @close="addSwitchInfoClose"
      width="500px"
      class="addProjectClass"
    >
      <template slot="title">
        <div v-if="addTitle === '新增'">
          <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
          <span class="dialog-title-my my-title">{{ addTitle }}</span>
        </div>
        <div v-if="addTitle === '修改'">
          <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
          <span class="dialog-title-my my-title">{{ addTitle }}</span>
        </div>
      </template>
      <el-form label-position="left" label-width="105px">
        <el-form-item label="功能名称：" required>
          <el-input v-model="addForm.switchInfoName" placeholder="请输入功能名称"></el-input>
        </el-form-item>
        <el-form-item label="code：" required>
          <el-input v-model="addForm.switchInfoCode" placeholder="请输入code"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input  type="textarea" :rows="4" v-model="addForm.switchInfoRemark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="addForm.switchInfoEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="addSwitchInfo_submit()" :loading="loading">确定</el-button>
        <el-button @click="addSwitchInfoShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "functionSwitch",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 10,
      tableData: [],
      enabled: true,
      addSwitchInfoShow: false,
      loading: false,
      tableLoading: false,
      addTitle: "",
      addForm: {
        id: "",
        switchInfoName: "",
        switchInfoCode: "",
        switchInfoRemark: "",
        switchInfoEnabled: true
      }
    }
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      if (this.enabled) {
        data.enabled = true
      }
      this.getTableList(data);
    },
    // 查询列表数据
    getTableList(data) {
      this.tableLoading = true;
      this.$http("/functionSwitch/list",null,"get",null,data).then(res => {
        this.tableLoading = false;
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach(item => {
            item.switchLoading = false;
          });
          this.tableData = list;
          this.total = res.data.total;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    // 翻页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 页数
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 过滤查询
    enabledChange(val) {
      this.inquire(1);
    },
    // 点击新增
    addClick() {
      this.addTitle = "新增";
      this.addSwitchInfoShow = true;
    },
    // 点击修改
    modifyClick(val) {
      this.addTitle = "修改";
      this.addForm = {
        id: val.id,
        switchInfoName: val.name,
        switchInfoCode: val.code,
        switchInfoRemark: val.remark,
        switchInfoEnabled: val.enabled
      }
      this.addSwitchInfoShow = true;
    },
    // 确定新增
    addSwitchInfo_submit() {
      let data = {
        name: this.addForm.switchInfoName,
        code: this.addForm.switchInfoCode,
        remark: this.addForm.switchInfoRemark,
        enabled: this.addForm.switchInfoEnabled,
      }
      if (!data.name) {
        this.$message.warning("请输入功能名称");
        return
      }
      if (!data.code) {
        this.$message.warning("请输入code");
        return
      }
      this.loading = true;
      if (this.addTitle === "新增") {
        this.$service("/functionSwitch/addSwitchInfo",data).then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.addSwitchInfoShow = false;
            this.inquire(1);
          }
        }).catch((err) => {
          this.loading = false;
        });
      }
      if (this.addTitle === "修改") {
        data.id = this.addForm.id;
        this.$service("/functionSwitch/updateSwitchInfo",data).then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.addSwitchInfoShow = false;
            this.inquire();
          }
        }).catch((err) => {
          this.loading = false;
        });
      }
    },
    // 关闭新增
    addSwitchInfoClose() {
      this.addForm = {
        id: "",
        switchInfoName: "",
        switchInfoCode: "",
        switchInfoRemark: "",
        switchInfoEnabled: true
      }
    },
    enabledRowChange(val) {
      let data = {
        id: val.id,
        name: val.name,
        code: val.code,
        remark: val.remark,
        enabled: val.enabled
      }
      val.switchLoading = true;
      this.$service("/functionSwitch/updateSwitchInfo",data).then(res => {
        val.switchLoading = false;
        if (res.result === 200) {
          if (data.enabled) {
            this.$message.success("启用成功");
          } else {
            this.$message.success("禁用成功");
          }
          this.inquire();
        }
      }).catch((err) => {
        val.switchLoading = false;
      });
    }
  },
}
</script>
<style scoped>
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>