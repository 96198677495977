<template>
	<div class="equipmentManager">
		<el-button type='primary' @click='addEquipment=true'>新增设备</el-button>

		<el-table :data="tableData" stripe style="width:100%" border>
			<el-table-column type='index' prop="" label="序号" width="60" align='center'></el-table-column>
			<el-table-column prop="boxNumber" label="设备ID" width="" align='center'> </el-table-column>
			<el-table-column prop="boxName" label="设备名称" width="" align='center'> </el-table-column>
			<el-table-column prop="locationCity" label="位置" width="300" align='center'> </el-table-column>
			<el-table-column prop='status' label="状态" width="120" align='center'></el-table-column>
			<el-table-column prop="gmtCreated" label="开通时间" width="170" align='center'> </el-table-column>
			<el-table-column prop="expireDate" label="到期时间" width="170" align='center'> </el-table-column>
			<el-table-column prop="" label="操作" width="120" align='center'>
				<template slot-scope="scope">
					<el-button type='text' size="small" @click="remove(scope.$index)" style='width: 60px;'>解绑</el-button>
				</template>
			</el-table-column>
		</el-table>

		<!--分页功能-->
		<el-pagination @current-change="currentPage_data" :current-page.sync="currentPage" :page-size="10"
			layout="prev, pager, next, jumper" :total="total" :pager-count="5"></el-pagination>

		<!--新增设备-->
		<el-dialog :visible.sync="addEquipment" custom-class='el-addEquipment' width="500px">
			<div slot="title" class="dialog-title">
				<h3>新增设备</h3>
			</div>
			<el-form :model="form">
				<el-form-item label="" :label-width="formWidth">
					<b>设备品牌：</b>
					<el-select v-model="form.brand" placeholder="请选择" @change='checked(form.brand)'>
						<el-option v-for="item in equipmentBrand" :key="item" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>设备ID<i style="margin-left: 15px;"></i>：</b>
					<el-input v-model="form.boxNumber" placeholder="请输入设备ID"></el-input>
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>设备Key<i style="margin-left: 3px;"></i>：</b>
					<el-input v-model="form.key" placeholder='请输入key值' :disabled='keyBol'></el-input>
				</el-form-item>
				<el-form-item label="" :label-width="formWidth" class='address'>
					<b>位置信息：</b>
					<v-distpicker hide-area :province="form.province" :city="form.city" @province="province" @city="city">
					</v-distpicker>
					<el-input v-model="form.address" placeholder='请填写详细地址'></el-input>
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>设备名称：</b>
					<el-input v-model="form.boxName" placeholder='请输入设备名称'></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" align="center">
				<el-button type="primary" @click="addEquipment_submit()">提 交</el-button>
				<el-button @click="addEquipment_cancel">取 消</el-button>
			</div>
		</el-dialog>



	</div>
</template>

<script>

	export default {
		name: 'EquipmentManager',
		data() {
			return {
				msg: '设备管理',
				tableData: [],
				total: null,
				currentPage: 1,

				addEquipment: false,
				equipmentBrand: ['鑫客宝-T2', '鑫客宝-U'],
				keyBol: true,
				form: {
					brand: '鑫客宝-T2',
					boxNumber: '',
					key: '选择鑫客宝-T2时key默认',
					province: '浙江省',
					city: '杭州市',
					address: '',
					boxName: ''
				},
				formWidth: '30px'
			}
		},
		mounted() {
			// 请求设备列表
			this.inquire();
		},
		methods: {
			province(val) {//省
				this.form.province = val.value
			},
			city(val) {//市
				this.form.city = val.value
			},
			checked(val) {//鑫客宝-T2、惠销
				if (val == '鑫客宝-T2') {
					this.form.key = '选择鑫客宝-T2时key默认';
					this.keyBol = true;
				} else {
					this.form.key = '';
					this.keyBol = false;
				}
			},
			remove(index) {//解绑操作				
				this.$confirm('此操作将解绑该设备, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {//解绑操作						
					let data = {
						boxId: this.tableData[index].id
					};
					this.$http('/box/deleteBox', data).then(res => {
						if (res.result == 200) {
							this.$message({ message: '解绑成功！', type: 'success' });
							this.inquire();
						}
					});
				}).catch({});

			},
			currentPage_data(val) {//请求当前页数据	
				this.inquire();
			},
			inquire() {// 查询数据
				let data = {
					pageNo: this.currentPage,
					pageSize: 10
				};
				this.$http('/box/list', data).then(res => {
					if (res.result === 200) {
						let list = res.data.list;
						this.total = res.data.count; //总条数
						//数据处理
						list.map(item => {
							item.gmtCreated = this.$common.transformTime(item.gmtCreated);
							item.expireDate = this.$common.transformTime(item.expireDate);
							item.locationCity = item.locationProvince + ' ' + item.locationCity + ' ' + item.address;
							if (item.status == 'USER_NOT_REGISTERED') {
								item.status = '不在线';
							} else {
								item.status = '在线';
							}
						});

						this.tableData = list;
					}
				});
			},
			addEquipment_submit() {//新增设备
				if (this.form.boxName != '' && this.form.boxNumber != '' && this.form.key != '' && this.form.address != '') {
					let data = {
						brandName: this.form.brand,
						boxNumber: this.form.boxNumber,
						boxSecret: this.form.key,
						boxName: this.form.boxName,
						locationProvince: this.form.province,
						locationCity: this.form.city,
						address: this.form.address
					};
					this.$http('/box/addBox', data).then(res => {
						if (res.result == 200) {
							this.$message({ message: '新增设备成功！', type: 'success' });
							this.addEquipment_cancel();
							this.inquire();
						}
					});
				} else {
					this.$message({ message: '信息填写不完整！', type: 'warning' });
				}
			},
			addEquipment_cancel() {//取消
				this.form = {
					boxName: '',
					province: '浙江省',
					city: '杭州市',
					address: '',
					boxNumber: '',
					brand: '鑫客宝',
					key: '选择鑫客宝时key默认'
				}
				this.addEquipment = false;
				this.keyBol = true;
			}
		}
	}
</script>


<style scoped="scoped">
	.el-button {
		width: 120px;
	}

	.el-table {
		margin: 20px 0 30px 0px;
	}

	.count {
		font-style: normal;
	}

	.el-pagination {
		float: right;
		text-align: right;
	}

	.el-addEquipment {
		width: 40%;
		height: 300px;
		color: red;
		font-weight: 900;
	}

	.el-addEquipment .el-input,
	.el-addEquipment .el-select {
		width: 304px;
	}

	.el-addEquipment .el-button {
		width: 120px;
	}

	.el-dialog b {
		color: #657180;
		font-size: 17px;
	}

	.address {
		width: 550px;
	}

	.address .el-input {
		margin: 10px 0px 0px 89px;
	}

	.address>>>.distpicker-address-wrapper {
		display: inline-block;
		width: 350px;
	}

	.address>>>.distpicker-address-wrapper select {
		width: 150px;
		font-size: 14px;
	}
</style>