<template>
  <div class="telephoneTraffic">
    <div class="nav_menu_m">
      <div class="nav_item">跟进运营：
        <el-input v-model="userLoginName" placeholder="请输入跟进运营" clearable></el-input>
      </div>
      <div class="nav_item">支付时间：
        <el-date-picker
          v-model="condition.gmtCreatedZ"
          type="daterange"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="callPickerOptions"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </div>
    </div>
    <div class="nav_menu_m">
      <div class="nav_item">机器编号：
        <el-input v-model="clientCode" placeholder="请输入机器编号" clearable></el-input>
      </div>
      <div class="nav_item">开课时间：
        <el-date-picker
          v-model="condition.gmtCreatedK"
          type="daterange"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="openCourseOptions"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </div>
    </div>
    <div class="nav_menu_m">
      <div class="nav_item">课程名称：
         <el-select 
          v-model="lessonName" 
          clearable 
          filterable 
          placeholder="请选择课程名称"
        >
          <el-option
            v-for="(item,index) in lessonNameList"
            :key="index"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquireClick">查询</el-button>
      <el-button type="success" v-if="roleMenu=='SUPER_MANAGER' || 'WECHAT_MANAGER' || 'WECHAT_PRODUCT_MANAGER'" @click="exportClick" :disabled="derivedDis">导出</el-button>
      <el-button @click="calssOpenClick">更新开课时间</el-button>
    </div>
    <!-- 作业帮订单列表 -->
    <el-table :data="tableData" stripe style="width: 100%" border v-loading="loading">
      <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
      <el-table-column prop="lessonName" label="课程名称" width align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="skuName" label="商品名称" width align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="currentOperator" label="跟进运营" width align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="clientCode" label="机器编号" width align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="customerCode" label="客户编号" width align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="wechatNickName" label="微信昵称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="payTime" label="支付时间" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="courseOpenTime" label="开课时间" align="center" show-overflow-tooltip></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      @size-change="sizeChange"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 更新开课时间 -->
    <el-dialog
      title="更新开课时间"
      :visible.sync="classOpenModfityShow"
      @close="classOpenModfityClose"
      width="500px"
    >
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">更新开课时间</span>
      </template>
      <el-form label-position="right" label-width="110px">
        <el-form-item label="支付时间：" required>
          <el-date-picker
            v-model="buyTime"
            type="daterange"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="callPickerOptions"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开课时间：" required>
          <el-upload
            class="upload-demo"
            drag
            :data="mationUploadData"
            :action="mationUrl"
            :on-success="successMationUpload"
            :on-error="errorMationUpload"
            ref="mationUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="true"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
          <div class="titlt-show">
            需要三列：课程名称、手机号码、开课时间，顺序需要保持一致
          </div>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button key="back" @click="classOpenModfityShow = false">取消</el-button>
          <el-button key="submit" type="primary" @click="classOpenModfity_submit" :loading="dialogLoading">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:'zuoyebangOrder',
  props: ["roleMenu"],
  data(){
    return {
      dialogLoading: false,
      classOpenModfityShow: false,
      buyTime: [],
      fileList: [],
      mationUploadData: {},
      mationUrl: this.$constants.baseURL + "/thirdPart/importThirdPartOrder",
      clientCode: "",
      loading: false,
      weekPickerOptions:{
        firstDayOfWeek:1
      },
      openCourseOptions:{
        shortcuts: [{
          text: '本月记录',
          onClick(picker) {
            let start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            let end = new Date();
            end.setDate(1);
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            end.setMilliseconds(0);
            let m = end.getMonth()+1;
            end.setMonth(m)
            end = end - 1000;
            picker.$emit('pick', [start, new Date(end)]);
          }
        }, {
          text: '本周记录',
          onClick(picker) {
            let start = sessionStorage.getItem('thisWeek');
            start = parseInt(start);
            const end = start + 7*24*3600*1000-1000;
            picker.$emit('pick', [new Date(start), new Date(end)]);
          }
        }, {
          text: '本日记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(59);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      callPickerOptions:{
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [{
          text: '本月记录',
          onClick(picker) {
            let start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            let end = new Date();
            end.setDate(1);
            end.setHours(0);
            end.setMinutes(0);
            end.setSeconds(0);
            end.setMilliseconds(0);
            let m = end.getMonth()+1;
            end.setMonth(m)
            end = end - 1000;
            picker.$emit('pick', [start, new Date(end)]);
          }
        }, {
          text: '本周记录',
          onClick(picker) {
            let start = sessionStorage.getItem('thisWeek');
            start = parseInt(start);
            const end = start + 7*24*3600*1000-1000;
            picker.$emit('pick', [new Date(start), new Date(end)]);
          }
        }, {
          text: '本日记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(59);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      condition:{
        gmtCreatedZ:[],
        gmtCreatedK:[],
        openCourseTimeStart:'',
        openCourseTimeEnd:'',
        payTimeStart:"",
        payTimeEnd:'',
      },
      numberList:[],
      currentPage:null,
      total:null,
      tableData:[],
      pageSize:10,
      number:null,
      userLoginName: "",
      lessonName: "",
      lessonNameList: [],
      derivedDis: true,
    }
  },
  mounted(){
    let end = new Date();
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    // end = end.getTime() + (2*24*3600*1000);
    end = this.$common.transformTime(end);
    this.condition.gmtCreatedZ = [start, end]
    this.theWeek();
    this.inquire(1);
    this.getFindWechatLabelList();
  },
  methods:{
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
    // 点击开课时间
    calssOpenClick() {
      this.classOpenModfityShow = true;
    },
    // 确定开课时间
    classOpenModfity_submit() {
      this.classOpenModfityShow = false;
      let payTimeStart = "";
      let payTimeEnd = "";
      if(this.buyTime && this.buyTime.length>0){
        payTimeStart = this.buyTime[0];
        payTimeEnd = this.buyTime[1];
      }
      if (!payTimeStart) {
        this.$message.warning("请选择支付时间");
        return
      }
      this.mationUploadData.payTimeStart = payTimeStart;
      this.mationUploadData.payTimeEnd = payTimeEnd;
      this.dialogLoading = true;
      this.$refs.mationUpload.submit();
    },
    // 关闭开课时间
    classOpenModfityClose() {
      this.buyTime = [];
      this.$refs.mationUpload.clearFiles();
      this.dialogLoading = false;
      this.mationUploadData = {};
    },
    // 更新绑定信息上传成功
    successMationUpload(res, file, fileList){
      if(res.result == 200){
        this.$message({ message: "开课时间更新成功！", type: "success" });
        this.classOpenModfityShow = false;
        this.dialogLoading = false;
        this.inquireClick();
        this.$refs.mationUpload.clearFiles();
      }
    },
    // 更新绑定信息上传失败
    errorMationUpload(err, file, fileList){
      this.dialogLoading = false;
      let message = JSON.parse(err.message)
      this.$message.warning(message.message)
    },
    handleExceed(files, fileList) {//限制上传文件数量提示
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 查询课程 
    getFindWechatLabelList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
        category: "LESSON",
        enabled: true
      }
      this.$http("wechatLabel/findWechatLabelList",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let list = res.data.data;
          this.lessonNameList = list;
        }
      })
    },
    // 点击导出
    exportClick() {
      this.dataProcessing();//查询参数处理
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        userLoginName: this.userLoginName,
        clientCode: this.clientCode,
        payTimeStart: this.condition.payTimeStart,
        payTimeEnd: this.condition.payTimeEnd,
        lessonName: this.lessonName,
        openCourseTimeStart: this.condition.openCourseTimeStart,
        openCourseTimeEnd: this.condition.openCourseTimeEnd,
      }
      let url = "";
      for(let i in params){
        if(params[i]){
          url+=(i+'='+params[i]+'&')
        }
      }
      let href = this.$constants.baseURL+"/thirdPart/export?"+url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then(data=>{
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      })
    },
    inquire(index){//查询
      this.dataProcessing();//查询参数处理
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        userLoginName: this.userLoginName,
        clientCode: this.clientCode,
        payTimeStart: this.condition.payTimeStart,
        payTimeEnd: this.condition.payTimeEnd,
        lessonName: this.lessonName,
        openCourseTimeStart: this.condition.openCourseTimeStart,
        openCourseTimeEnd: this.condition.openCourseTimeEnd,
      }
      this.inquireReportList(params);
    },
    dataProcessing(){//查询参数处理
      let openCourseTimeStart = "";
      let openCourseTimeEnd = "";
      let payTimeStart = "";
      let payTimeEnd = "";
      if(this.condition.gmtCreatedZ && this.condition.gmtCreatedZ.length>0){
        payTimeStart = this.condition.gmtCreatedZ[0];
        payTimeEnd = this.condition.gmtCreatedZ[1];
      }
      if(this.condition.gmtCreatedK && this.condition.gmtCreatedK.length>0){
        openCourseTimeStart = this.condition.gmtCreatedK[0];
        openCourseTimeEnd = this.condition.gmtCreatedK[1];
      }
      this.condition.openCourseTimeStart = openCourseTimeStart;
      this.condition.openCourseTimeEnd = openCourseTimeEnd;
      this.condition.payTimeStart = payTimeStart;
      this.condition.payTimeEnd = payTimeEnd;
    },
    inquireReportList(params){//查询微信坐席业绩信息
      this.loading = true;
      this.derivedDis = true;
      this.$http('/thirdPart/thirdPartOrderList',null,'get',null,params).then(res=>{
        setTimeout(() => {
          this.loading = false
        }, 200);
        if(res.result == 200){
          let list = res.data.records;
          this.total = res.data.total;
          this.tableData = list;
          if (this.tableData.length > 0) {
            this.derivedDis = false;
          }
        }
      })
    },
    currentPage_data(index){//当前页
      this.inquire(index)
    },
    sizeChange(index){//显示条数--分页
      this.pageSize = index;
      this.inquire(1);
    },
    inquireClick(){//点击查询
      this.inquire(1);
    },
  }
}
</script>
<style scoped>
.nav_menu_m {
  margin-bottom: 20px;
}
.nav_menu_m .nav_item {
  display: inline-block;
  margin-right: 20px;
}
.nav_menu_m .nav_item .el-input,
.nav_menu_m .nav_item .el-select {
  width: 250px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.titlt-show {
  font-size: 12px;
  color: #E6A23C;
  line-height: 18px;
}
</style>