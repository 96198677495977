<template>
  <div class="myPagination">
    <div class="paginationClass">
      <el-button 
        class="btn-next btn-first" 
        @click="inquireTotal" 
        v-if="totalShow"
      >查询总条数</el-button>
      <el-button 
        class="btn-next btn-first" 
        @click="inquireOne" 
        :disabled="disabledPrevBtn" 
        v-if="onePageNoShow"
      >第一页</el-button>
      <el-button 
        icon="el-icon-arrow-left" 
        class="btn-next btn-first" 
        @click="hanlePageNo('prev')" 
        :disabled="disabledPrevBtn"
      >上一页</el-button>
      <div class="currentPage-class">{{pageNo}}</div>
      <el-button 
        class="btn-next btn-first" 
        @click="hanlePageNo('next')" 
        :disabled="disabledNextBtn"
      >下一页
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
      <el-dropdown @command="handlePageSize" trigger="click" v-if="pageSizeShow">
        <el-button class="btn-next btn-first" style="margin-left: 0">
          {{pageSize}}条/页<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="10">10条/页</el-dropdown-item>
          <el-dropdown-item command="20">20条/页</el-dropdown-item>
          <el-dropdown-item command="50">50条/页</el-dropdown-item>
          <el-dropdown-item command="100">100条/页</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: "myPagination",
  props: {
    totalShow: {
      // total显示
      type: Boolean,
      default: false
    },
    onePageNoShow: {
      // 第一页显示
      type: Boolean,
      default: false
    },
    pageSizeShow: {
      // 页数显示
      type: Boolean,
      default: false
    },
    tableList: {
      // 表格数据
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
    }
  },
  computed: {
    // 禁用上一页
    disabledPrevBtn() {
      if (this.pageNo === 1) {
        return true
      }
      return false
    },
    // 禁用下一页
    disabledNextBtn() {
      if (this.tableList.length < this.pageSize) {
        return true
      }
      return false
    },
  },
  mounted() {
    
  },
  methods: {
    // 查询总条数
    inquireTotal() {
      this.$emit("inquireTotal");
    },
    // 查询第一页
    inquireOne() {
      this.pageNo = 1;
      this.$emit("inquireOne", 1);
    },
    // 翻页
    hanlePageNo(key) {
      if (key === 'prev') {
        this.pageNo--
      }
      if (key === 'next') {
        this.pageNo++
      }
      this.$emit("currentPageNo", this.pageNo);
    },
    // 条数
    handlePageSize(pageSize) {
      this.pageNo = 1;
      this.pageSize = Number(pageSize);
      this.$emit("currentPageSize", this.pageSize);
    },
  },
}
</script>
<style scoped>
.paginationClass{
  position: relative;
  display: flex;
  /* justify-content: flex-end; */
}
.paginationClass .currentPage-class{
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  color: #606266;
  padding: 0 8px;
}
.paginationClass .btn-next{
  padding: 0;
  margin: 0 5px;
  background-color: #f5f7fa;
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
  background-size: 16px;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
  box-sizing: border-box;
  font-size: 13px;
  border: none;
}
.paginationClass .btn-first{
  font-size: 12px;
  padding: 0 10px;
}
</style>