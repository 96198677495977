<template>
  <div class="alarmGroup">
    <div class="nav">
      <div class="projectStatus">
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button type="primary" @click="addCategoryClick">新增类别</el-button>
    <!-- 拨打队列表 -->
    <el-table :data="categoryTableData" stripe style="width: 100%" border v-loading="loading">
      <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
      <el-table-column prop="category" label="类别" width align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="order" label="排序" width="80" align="center"></el-table-column>
      <el-table-column prop="enabled" label="是否启用" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.enabled">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column prop="queryUserCount" label="查看项目组员数" width="150" align="center"></el-table-column>
      <el-table-column prop="notificationUserCount" label="通知抑制组员数" width="150" align="center"></el-table-column>
      <el-table-column prop="gmtCreated" label="创建时间" width="170" align="center"></el-table-column>
      <el-table-column prop label="操作" width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="canGroup(scope.row, 'user')">项目查看管理</el-button>
          <el-button type="text" size="small" @click="canGroup(scope.row, 'alarm')">通知抑制管理</el-button>
          <!-- <el-button type="text" size="small" @click="modifyName(scope.row)">修改</el-button> -->
          <!-- <el-button type="text" size="small" @click="deleteShow(scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增or修改 -->
    <el-dialog :visible.sync="addCategory" custom-class="el-addCategory-m" width="400px" @close="addCategoryClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">{{dialogTitle}}</span>
      </template>
      <el-form :model="form" :label-position="labelLeft" label-width="100px">
        <el-form-item label="类别名称：" required>
          <el-input v-model="form.category" placeholder="请输入类别名称"></el-input>
        </el-form-item>
        <!-- <el-form-item label="是否启动：">
          <el-switch
            v-model="autoStartEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item> -->
        <div class="button-center">
          <el-button type="primary" @click="addCategory_submit()">提 交</el-button>
          <el-button @click="addCategory = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 操作 -->
    <!-- 用户管理 -->
    <el-dialog
      :visible.sync="userloginNameShow"
      custom-class="el-addCategory"
      width="550px"
      :title="userLoginNameDialog"
      @close="userloginNameClose"
    >
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">{{userLoginNameDialog}}</span>
      </template>
      <el-transfer
        filterable
        filter-placeholder="请输入用户名称"
        v-model="userLoginNameValue"
        :data="userLoginNameData"
        :titles="['可选择','使用中']"
        @change="handleChangeClient"
        class="transferClass"
      ></el-transfer>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="chenageCategory_submit()">提 交</el-button>
        <el-button @click="userloginNameShow = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "alarmGroup",
  data() {
    return {
      userLoginNameDialog: "",
      dialogTitle: "",
      autoStartEnabled: false,
      loading: false,
      userLoginNameData: [],
      userLoginNameValue: [],
      pinyin: [],
      categoryTableData: null,
      currentPage: null,
      total: null,
      addCategory: false,
      labelLeft: "left",
      userloginNameShow: false,
      deletedShow: false,
      id: "",
      userLoginNameList: [],
      routeIds: [],
      form: {
        category: ""
      },
      formWidth: "60px",
      pageSize:10,
    };
  },
  props:['roleMenu'],
  watch: {
  },
  mounted() {
    document.title = "惠销平台管理系统-项目类别管理";
    this.inquire(1);
  },
  methods: {
    // 点击新增
    addCategoryClick() {
      this.dialogTitle = "新增类别";
      this.addCategory = true;
    },
    modifyName(val){//点击修改
      this.dialogTitle = "修改类别";
      this.addCategory = true;
      this.id = val.id;
      this.form.category = val.category;
      this.autoStartEnabled = val.enabled;
    },
    currentPage_data(index) {//当前页
      this.inquire(index);
    },
    sizeChange(index) {//显示条数--分页
      this.currentPage = 1;
      this.pageSize = index;
      this.inquire();
    },
    inquire(index) {//查询列表
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: index || this.currentPage,
      };
      this.projectCategoryList(params);
    },
    projectCategoryList(params){//查询拨打列队列表
      this.loading = true
      this.$http("/projectCategory/list", null, "get", null, params).then(res => {
        setTimeout(() => {
          this.loading = false
        }, 200);
        if (res.result == 200) {
          let list = res.data.records;
          this.total = res.data.total;
          list.map(item => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          });
          this.categoryTableData = list;
        }
      });
    },
    inquireUserLoginName() {//请求用户数据
      let params = {
        pageSize: 1000,
        pageNo: 1,
        // primaryAccount: true,
        enabled: true
      };
      // 清空数据
      this.userLoginNameData = [];
      this.pinyin = [];
      this.$http("/user/list", null, "get", null, params).then(res => {
        if (res.result == 200) {
          let list = res.data.list;
          list.forEach((client, index) => {
            this.pinyin.push(client.loginName);
            this.userLoginNameData.push({
              label: client.userName,
              key: client.loginName,
              pinyin: this.pinyin[index],
              // disabled: client.used
            });
          });
          this.userLoginNameValue.forEach(i => {
            this.userLoginNameData.forEach((j,k) => {
              if (i === j.key) {
                this.userLoginNameData[k].disabled = false;
              }
            })
          })
        }
      });
    },
    //新增提交
    addCategory_submit() {
      let params = {
        category: this.form.category,
        // enabled: this.autoStartEnabled
      };
      if (!this.form.category) {
        this.$message({ message: "信息不完整", type: "warning" });
        return;
      }
      if (this.dialogTitle === "新增类别") {
        this.$service("/projectCategory/addCategory",params).then(res => {
          if (res.result == 200) {
            this.$message({ message: "新增类别成功", type: "success" });
            this.addCategory = false;
            this.inquire(1);
          }
        });
      }
      if (this.dialogTitle === "修改类别") {
        
      }
    },
    // 关闭新增类别
    addCategoryClose() {
      this.form.category = "";
      this.autoStartEnabled = false;
    },
    //点击类别管理
    canGroup(val, key) {
      this.id = val.id;
      this.userLoginNameList = [];
      let userLoginNameList = [];
      this.userLoginNameValue = [];
      if (key === "user") {
        this.userLoginNameDialog = "项目查看管理";
        userLoginNameList = val.userLoginNameListForQuery;
      }
      if (key === "alarm") {
        this.userLoginNameDialog = "通知抑制管理";
        userLoginNameList = val.userLoginNameListForNotification;
      }
      userLoginNameList.forEach(c => {
        this.userLoginNameValue.push(c);
        this.userLoginNameList.push(c);
      });
      this.userloginNameShow = true;
      this.inquireUserLoginName();
    },
    //坐席管理穿梭框变动
    handleChangeClient(value, direction, movedKeys) {
      this.userLoginNameList = value;
    },
    //确认坐席管理
    chenageCategory_submit() {
      let newUserLoginNameList = this.userLoginNameList.filter(item => item),
        data = {
          id: this.id,
          userLoginNameList: newUserLoginNameList
        };
      if (this.userLoginNameDialog === "项目查看管理") {
        data.type = "PROJECT_QUERY";
      }
      if (this.userLoginNameDialog === "通知抑制管理") {
        data.type = "NOTIFICATION_SUPPRESS";
      }
      this.$service("/projectCategory/updateProjectCategoryUser", data).then(res => {
          if (res.result == 200) {
            this.$message({ message: "操作成功", type: "success" });
            this.inquire();
            this.userloginNameShow = false;
          } else {
            this.$message({ message: "操作失败", type: "warning" });
          }
        }
      );
    },
    // 关闭用户管理
    userloginNameClose() {
      this.userLoginNameValue = [];
      this.userLoginNameData = [];
    },
    // 点击删除
    deleteShow(val) {
      this.id = val.id;
      this.$confirm("删除队列, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
        .then(() => {
          let params = {
            id: this.id
          };
          this.$http("/sip/team/delete", null, "get", null, params).then(
            res => {
              if (res.result == 200) {
                this.$message({ message: "删除成功", type: "success" });
                this.inquire();
              } 
            }
          );
        })
        .catch(() => {});
    }
  }
};
</script>
<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>
<style scoped="scoped">
.workingTimeClass .working_day_class >>> .el-input{
  width: 243px;
}
.el-addCategory-m .el-select {
  width: 100%;
}
.workingTimeClass >>> .el-input {
  width: 120px;
}
.transferClass >>> .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover{
  color: #C0C4CC !important;
  background-color: #fff;
  border-color: #ebeef5;
}
.transferClass >>> .el-transfer-panel__item{
  display: block !important;
}
.transferClass >>> .el-transfer__button:first-child{
  margin-bottom: 10px !important;
}
.transferClass >>> .el-transfer__button{
  display: block !important;
  margin: 0 auto !important;
  padding: 10px !important;
  border-radius: 50% !important;
  font-size: 0 !important;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  width: 165px;
  margin: 0 auto;
}
</style>


