<template>
  <div class="wechatMark">
    <div class="operate">
      <el-button type="primary" @click="inquireTask(1)">查询</el-button>
      <el-button type="primary" @click="uploadNumber">上传客户编号</el-button>
    </div>
    <!-- 打标列表 -->
    <el-table
      :data="taskTableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="taskName"
        label="任务名称"
        min-width="120"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="statusLabel"
        label="状态"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag size="small" :type="scope.row.color">{{
            scope.row.statusLabel
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="totalCount"
        label="进度"
        width="130"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.processCountSub }}/{{ scope.row.totalCount }}
        </template>
      </el-table-column>
      <el-table-column
        prop="successRate"
        label="打标成功率"
        width="120"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>打标成功率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="接口成功数 / 处理个数"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          {{ scope.row.successRate }}
        </template>
      </el-table-column>
      <el-table-column
        prop="notMatchedCount"
        label="未匹配率"
        width="120"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>未匹配率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="未匹配数 / 处理个数"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          {{ scope.row.notMatchedRate }}
        </template>
      </el-table-column>
      <el-table-column
        prop="submitFailedCount"
        label="提交失败率"
        width="120"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>提交失败率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="提交失败数 / 处理个数"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          {{ scope.row.submitFailedRate }}
        </template>
      </el-table-column>
      <el-table-column
        prop="submitter"
        label="操作者"
        width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="submitTime"
        label="上传时间"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="150"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="clickSuspend(scope.row)"
            :disabled="scope.row.isSuspend"
            >暂停</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="clickContinue(scope.row)"
            :disabled="scope.row.isContinue"
            >继续</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="clickStrategy(scope.row)"
            :disabled="scope.row.isStrategy"
            >策略</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="taskPageSizeChange"
      @current-change="currentTaskPageNo"
      :current-page.sync="taskPageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="taskTotal"
    ></el-pagination>
    <!-- 上传客户编号 -->
    <el-dialog
      title="上传客户编号"
      :visible.sync="uploadNumberShow"
      width="500px"
      @close="uploadNumberClose"
    >
      <template slot="title">
        <i class="el-icon-warning dialog-title-my" style="color: #e6a23c"></i>
        <span class="dialog-title-my my-title">上传客户编号</span>
      </template>
      <el-form label-width="150px" label-position="left">
        <el-form-item label="任务名称：" required>
          <el-input
            v-model="taskName"
            placeholder="请输入任务名称"
            style="width: 95%"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="标签：" required>
          <el-input
            v-model="tagValue"
            placeholder="请输入标签"
            style="width: 95%"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="间隔时间(秒)：" required>
          <el-input
            v-model="timeInterval"
            placeholder="请输入时间间隔"
            style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="最多处理个数：" required>
          <template slot="label">
            最多处理个数
            <el-tooltip class="item" placement="top-start">
              <div slot="content">单个请求可以处理的最多手机号个数</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-input
            v-model="phoneCountPerRequest"
            placeholder="请输入最多处理个数"
            style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传号码：">
          <template slot="label">
            上传客户编号
            <el-tooltip class="item" placement="top-start">
              <div slot="content">暂时仅支持客户编号上传</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-upload
            class="upload-demo"
            drag
            :data="numberUploadData"
            :action="numberUrl"
            :on-success="successNumberUpload"
            :on-error="errorNumberUpload"
            ref="numberUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="uploadNumber_submit"
          :loading="isNumberSubmit"
          >确定</el-button
        >
        <el-button @click="uploadNumberShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 修改策略 -->
    <el-dialog
      title="修改策略"
      :visible.sync="strategyShow"
      width="500px"
      @close="strategyClose"
    >
      <template slot="title">
        <i class="el-icon-warning dialog-title-my" style="color: #e6a23c"></i>
        <span class="dialog-title-my my-title">修改策略</span>
      </template>
      <el-form label-width="150px">
        <el-form-item label="间隔时间(秒)：" required>
          <el-input
            v-model="timeInterval"
            placeholder="请输入时间间隔"
            style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="最多处理个数：" required>
          <template slot="label">
            最多处理个数
            <el-tooltip class="item" placement="top-start">
              <div slot="content">单个请求可以处理的最多手机号个数</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-input
            v-model="phoneCountPerRequest"
            placeholder="请输入最多处理个数"
            style="width: 95%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="strategy_submit"
          :loading="isNumberSubmit"
          >确定</el-button
        >
        <el-button @click="strategyShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "wechatMark",
  data() {
    return {
      taskTableData: [],
      loadingTable: false,
      taskPageNo: 1,
      taskPageSize: 10,
      taskTotal: 0,
      manualAsyn: null,
      numberUploadData: {},
      numberUrl: "",
      fileList: [],
      cookie: true,
      uploadNumberShow: false,
      isNumberSubmit: false,
      taskName: "",
      tagValue: "",
      timeInterval: "20",
      phoneCountPerRequest: "50",
      strategyShow: false,
      server: "",
      taskId: "",
    };
  },
  mounted() {
    this.getManualAsyn();
    this.inquireTask(1);
  },
  methods: {
    // 点击上传客户编号
    uploadNumber() {
      this.uploadNumberShow = true;
    },
    // 确定上传客户编号
    uploadNumber_submit() {
      this.numberUrl = this.$constants.baseURL + "/wechatTask/mark/submit";
      this.numberUploadData.taskName = this.taskName;
      this.numberUploadData.tagValue = this.tagValue;
      this.numberUploadData.phoneCountPerRequest = this.phoneCountPerRequest;
      this.numberUploadData.delay = this.timeInterval;
      if (!this.numberUploadData.taskName) {
        this.$message.warning("请输入任务名称");
        return;
      }
      if (!this.numberUploadData.tagValue) {
        this.$message.warning("请输入标签");
        return;
      }
      if (!this.numberUploadData.delay) {
        this.$message.warning("请输入时间间隔");
        return;
      }
      if (!this.numberUploadData.phoneCountPerRequest) {
        this.$message.warning("请输入最多处理个数");
        return;
      }
      // this.isNumberSubmit = true;
      this.$nextTick(() => {
        this.$refs.numberUpload.submit();
      });
    },
    // 关闭上传客户编号
    uploadNumberClose() {
      this.timeInterval = "20";
      this.taskName = "";
      this.tagValue = "";
      this.phoneCountPerRequest = "50";
      this.isNumberSubmit = false;
      this.$refs.numberUpload.clearFiles();
    },
    // 点击查询
    inquireTask(index) {
      if (index) {
        this.taskPageNo = index;
      }
      let data = {
        pageNo: this.taskPageNo,
        pageSize: this.taskPageSize,
      };
      this.getWechatMark(data);
    },
    // 查询打标任务数据
    getWechatMark(data) {
      this.$http("/wechatTask/mark/list", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            this.taskTotal = res.data.total;
            let list = res.data.records;
            list.forEach((item) => {
              item.submitTime = this.$common.transformTime(item.submitTime);
              item.successRate = "0%";
              item.notMatchedRate = "0%";
              item.submitFailedRate = "0%";
              item.processCountSub =
                item.successCount +
                item.notMatchedCount +
                item.submitFailedCount;
              if (item.processCountSub) {
                item.successRate =
                  (item.successCount / item.processCountSub) * 100;
                item.successRate = item.successRate.toFixed(0) + "%";
                item.notMatchedRate =
                  (item.notMatchedCount / item.processCountSub) * 100;
                item.notMatchedRate = item.notMatchedRate.toFixed(0) + "%";
                item.submitFailedRate =
                  (item.submitFailedCount / item.processCountSub) * 100;
                item.submitFailedRate = item.submitFailedRate.toFixed(0) + "%";
              }
              item.isSuspend = true;
              item.isContinue = true;
              item.isStrategy = false;
              switch (item.status) {
                case "INIT":
                  item.statusLabel = "创建中";
                  item.color = "warning";
                  break;
                case "SUBMITTING":
                  item.statusLabel = "执行中";
                  item.color = "warning";
                  item.isSuspend = false;
                  break;
                case "FINISH":
                  item.statusLabel = "完成";
                  item.color = "success";
                  item.isStrategy = true;
                  break;
                case "TERMINATE":
                  item.statusLabel = "服务器关闭";
                  item.color = "danger";
                  break;
                case "SUSPEND":
                  item.statusLabel = "已暂停";
                  item.color = "info";
                  item.isContinue = false;
                  break;
              }
            });
            this.taskTableData = list;
          }
        }
      );
    },
    // 翻页
    currentTaskPageNo(index) {
      this.inquireTask(index);
    },
    // 每页
    taskPageSizeChange(index) {
      this.taskPageSize = index;
      this.inquireTask(1);
    },
    // 点击暂停
    clickSuspend(val) {
      this.$confirm("暂停添加, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let path = "https://" + val.server + this.$constants.basePath;
          this.$service(
            "/wechatTask/suspend",
            { taskId: val.id },
            "post",
            path
          ).then((res) => {
            if (res.result === 200) {
              this.$message.success("暂停成功");
              this.inquireTask();
            }
          });
        })
        .catch(() => {});
    },
    // 点击继续
    clickContinue(val) {
      this.$confirm("继续添加, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let path = "https://" + val.server + this.$constants.basePath;
          this.$service(
            "/wechatTask/resume",
            { taskId: val.id },
            "post",
            path
          ).then((res) => {
            if (res.result === 200) {
              this.$message.success("继续成功");
              this.inquireTask();
            }
          });
        })
        .catch(() => {});
    },
    // 点击策略
    clickStrategy(val) {
      this.strategyShow = true;
      this.timeInterval = val.delay;
      this.server = val.server;
      this.taskId = val.id;
      this.phoneCountPerRequest = val.phoneCountPerRequest;
    },
    // 确定修改策略
    strategy_submit() {
      let data = {
        taskId: this.taskId,
        phoneCountPerRequest: this.phoneCountPerRequest,
        delay: Number(this.timeInterval),
      };
      if (!data.delay) {
        this.$message.warning("请输入时间间隔");
        return;
      }
      if (!data.phoneCountPerRequest) {
        this.$message.warning("请输入最多处理个数");
        return;
      }
      this.isNumberSubmit = true;
      let path = "https://" + this.server + this.$constants.basePath;
      this.$service("/wechatTask/mark/updateStrategy", data, "post", path)
        .then((res) => {
          this.isNumberSubmit = false;
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.strategyShow = false;
            this.inquireTask();
          }
        })
        .catch((err) => {
          this.isNumberSubmit = false;
        });
    },
    // 关闭策略
    strategyClose() {
      this.phoneCountPerRequest = "50";
      this.timeInterval = "20";
      this.server = "";
      this.isNumberSubmit = false;
    },
    handleExceed(files, fileList) {
      //限制上传文件数量提示
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    successNumberUpload(res, file, fileList) {
      //补充号码上传成功
      this.$refs.numberUpload.clearFiles();
      if (res.result == 200) {
        this.isNumberSubmit = false;
        this.$message({ message: "文件上传成功！", type: "success" });
        this.uploadNumberShow = false;
        this.inquireTask(1);
      }
    },
    errorNumberUpload(err, file, fileList) {
      //补充号码上传失败
      this.isNumberSubmit = false;
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    // 刷新手动上传任务进度
    getManualAsyn() {
      this.manualAsyn = setInterval(() => {
        this.inquireTask();
      }, 10 * 1000);
    },
  },
  beforeDestroy() {
    if (this.manualAsyn) {
      clearInterval(this.manualAsyn);
      this.manualAsyn = null;
    }
  },
};
</script>
<style scoped>
.data_style {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.nav .batchName .el-select {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.upload-demo >>> .el-upload-dragger {
  width: 304px;
}
.records_style >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
</style>