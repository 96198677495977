<template>
  <div class="knowledgeEntry">
    <div class="nav">
      <div class="batchName">
        词条：
        <el-input v-model="entryName" placeholder="请输入词条" clearable></el-input>
      </div>
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button @click="addEntry">添加新词条</el-button>
    </div>
    <el-table
      :data="tableListData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
      height="calc(100% - 190px)"
    >
      <el-table-column prop="id" label="编号" width="70" align="center"></el-table-column>
      <el-table-column prop="name" label="词条" width="150" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="type" label="类型" width="120" align="left">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 'COMMON'">普通问题</span>
          <span v-if="scope.row.type === 'CUSTOMER'">业务问题</span>
        </template>
      </el-table-column>
      <el-table-column prop="keyword" label="关键词" width="120" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="answerType" label="回答方式" width="120" align="left">
        <template slot-scope="scope">
          <span v-if="scope.row.answerType === 'SINGLE_ANSWER'">单次回答</span>
          <span v-if="scope.row.answerType === 'SECONDARY'">知识库流程</span>
        </template>
      </el-table-column>
      <el-table-column prop="contentList" label="机器人话术/知识库流程" align="left" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.answerType === 'SINGLE_ANSWER'">
            <div v-for="item in scope.row.contentLabelList" :key="item.id">
              <span>{{ item.content }}</span>
            </div>
          </div>
          <div v-if="scope.row.answerType === 'SECONDARY'">
            <span>{{ scope.row.answerValue }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop label="操作" width="130" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modifyClick(scope.row)">修改</el-button>
          <el-button type="text" size="small" @click="deleteClick(scope.row)" style="color: #F56C6C">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 增加词条 -->
    <el-dialog 
      :title="entryTitle" 
      :visible.sync="entryShow" 
      width="500px" 
      @close="entryClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="词条:" required>
          <el-input 
            v-model="entryForm.name" 
            placeholder="请输入词条" 
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="类型:" required>
          <el-radio-group v-model="entryForm.entryType">
            <el-radio label="CUSTOMER">业务问题
              <el-tooltip placement="top">
                <div slot="content" style="width: 300px">业务问题是与行业/场景强相关的问题，比如：询问购买产品需要支付的费用。</div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-radio>
            <el-radio label="COMMON">通用问题
              <el-tooltip placement="top">
                <div slot="content" style="width: 300px">通用问题与业务场景相关度不高，在任何场景都会有被用户提及。比如：正在开车。</div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            关键词
            <el-tooltip placement="top">
              <div slot="content" style="width: 300px">关键词：通过正则表达式规则匹配用户说出什么样子的话术会进入到该意图。</div>
              <i class="el-icon-question"></i>
            </el-tooltip>:
          </template>
          <el-input 
            v-model="entryForm.keyword" 
            placeholder="请输入关键词并回车（多个请用回车分割）" 
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
        <el-form-item label="回答方式:" required>
          <el-radio-group v-model="entryForm.answerType" @change="answerTypeChange">
            <el-radio label="SINGLE_ANSWER">单次回答</el-radio>
            <el-radio label="SECONDARY">知识库流程</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="机器人话术:" required v-if="entryForm.answerType === 'SINGLE_ANSWER'">
          <div v-for="(item,index) in entryForm.contentList" :key="item.id" class="ef-speech-cc-mm" style="margin-bottom: 10px; ">
            <el-input 
              v-model="item.content" 
              placeholder="请输入机器人话术" 
              maxlength="150"
              show-word-limit
              style="width: 90%;"
            ></el-input>
            <el-button
              v-if="index > 0" 
              type="text"
              @click="deleteSpeechClick(item)"
            >删除</el-button>
          </div>
          <div>
            <el-button
              type="text"
              @click="addSpeechClick"
            >+增加机器人话术</el-button>
            <span style="padding-left: 10px">
              为什么需要添加多个话术？
              <el-tooltip placement="top">
                <div slot="content" style="width: 300px">多个话术为机器人提供一问多答的能力，让机器人更加智能。通常用于用户反复询问一个问题时，机器人按照配置顺序，用不同的答案为用户澄清。</div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="执行动作:" required v-if="entryForm.answerType === 'SINGLE_ANSWER'">
          <el-select v-model="entryForm.action" placeholder="请选择执行动作" @change="actionChange">
            <el-option
              v-for="item in receiptActionList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转流程:" required v-if="entryForm.action === 'REDIRECT' && entryForm.answerType === 'SINGLE_ANSWER'">
          <el-select v-model="entryForm.flowId" placeholder="请选择跳转流程">
            <el-option
              v-for="item in flowList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择流程:" required v-if="entryForm.answerType === 'SECONDARY'">
          <el-select v-model="entryForm.flow" placeholder="请选择跳转流程">
            <el-option
              v-for="item in flowOptionsList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="entry_submit" :loading="loading">确定</el-button>
        <el-button @click="entryShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "knowledgeEntry",
  props: {
    activeSpeechId: [String, Number]
  },
  data() {
    return {
      loading: false,
      entryTitle: "",
      entryShow: false,
      entryName: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableListData: [],
      loadingTable: false,
      entryForm: {
        name: "",
        entryType: "CUSTOMER",
        keyword: "",
        answerType: "SINGLE_ANSWER",
        contentList: [{id: 1, content: ""}],
        action: "",
        flow: "",
        flowId: "",
      },
      receiptActionList: [
        {
          value: "WAIT",
          label: "等待用户回复"
        },
        {
          value: "HANGUP",
          label: "挂机"
        },
        {
          value: "REDIRECT",
          label: "跳转至主流程"
        },
      ],
      flowOptionsList: [],
      flowList: [],
      addSpeechId: 100,
      actvieDialogueNote: "",
    }
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        dialogueId: this.activeSpeechId
      }
      this.getEntryList(data);
    },
    // 查询词条列表
    getEntryList(data) {
      this.loadingTable = true;
      this.$http("/dialogueNote/dialogueNoteList",null,"get",null,data).then(res => {
        this.loadingTable = false;
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach(item => {
            item.contentLabelList = [];
            if (item.contentList) {
              item.contentList.forEach((menu,index) => {
                item.contentLabelList.push({
                  id: index + 1,
                  content: menu
                })
              })
            }
          })
          this.tableListData = list;
          this.total = res.data.total;
        }
      }).catch(err => {
        this.loadingTable = false;
      })
    },
    // 分页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 页数
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 选择执行动作
    actionChange(val) { 
      if (val === "REDIRECT") {
        this.getFlowList();
      }
    },
    // 选择回答方式
    answerTypeChange(val) { 
      if (val === "SECONDARY") {
        this.getFlowOptionsList();
      }
    },
    // 查询知识库流程列表
    getFlowOptionsList() {
      let data = {
        dialogueId: this.activeSpeechId,
        type: "SECONDARY"
      }
      this.$http("/dialogueScenario/dialogueScenarioList",null,"get",null,data).then(res => {
        this.loadingTable = false;
        if (res.result === 200) {
          let list = res.data;
          list.forEach(item => {
            item.id = item.id.toString();
          });
          this.flowOptionsList = list;
        }
      })
    },
    // 查询主流程列表
    getFlowList() {
      let data = {
        dialogueId: this.activeSpeechId,
        type: " MASTER"
      }
      this.$http("/dialogueScenario/dialogueScenarioList",null,"get",null,data).then(res => {
        this.loadingTable = false;
        if (res.result === 200) {
          let list = res.data;
          list.forEach(item => {
            item.id = item.id.toString();
          });
          this.flowList = list;
        }
      })
    },
    // 点击新增
    addEntry() {
      this.entryTitle = "添加新词条";
      this.entryShow = true;
    },
    // 点击修改
    modifyClick(val) {
      this.entryTitle = "修改词条";
      this.actvieDialogueNote = val.id;
      this.entryForm.name = val.name;
      this.entryForm.entryType = val.type;
      this.entryForm.keyword = val.keyword;
      this.entryForm.answerType = val.answerType;
      if (val.answerType === "SINGLE_ANSWER") {
        if (val.contentList) {
          this.entryForm.contentList = [];
          val.contentList.forEach((item,index) => {
            let obj = {
              id: index + 1,
              content: item
            }
            this.entryForm.contentList.push(obj);
          })
        }
        if (val.action === "REDIRECT") {
          this.entryForm.flowId = val.answerValue;
        }
        this.entryForm.action = val.action;
        this.actionChange(this.entryForm.action);
      }
      if (val.answerType === "SECONDARY") {
        this.entryForm.flow = val.answerValue;
        this.answerTypeChange(this.entryForm.answerType)
      }
      this.entryShow = true;
    },
    // 确定新增
    entry_submit() {
      let data = {
        dialogueId: this.activeSpeechId,
        name: this.entryForm.name,
        type: this.entryForm.entryType,
        keyword: this.entryForm.keyword,
        answerType: this.entryForm.answerType,
        contentList: []
      }
      if (!this.entryForm.name) {
        this.$message.warning("请输入词条");
        return
      }
      if (this.entryForm.answerType === "SINGLE_ANSWER") {
        if (this.entryForm.contentList.length < 1) {
          this.$message.warning("请输入机器人话术");
          return
        }
        if (!this.entryForm.action) {
          this.$message.warning("请选择回执动作");
          return
        }
        if (this.entryForm.action === "REDIRECT") {
          if (!this.entryForm.flowId) {
            this.$message.warning("请选择跳转流程");
            return
          }
          data.answerValue = this.entryForm.flowId;
        }
        this.entryForm.contentList.forEach(item => {
          if (item.content) {
            data.contentList.push(item.content);
          }
        });
        data.action = this.entryForm.action;
      }
      if (this.entryForm.answerType === "SECONDARY") {
        if (!this.entryForm.flow) {
          this.$message.warning("请选择流程");
          return
        }
        data.answerValue = this.entryForm.flow;
      }
      this.loading = true;
      if (this.entryTitle === "添加新词条") {
        this.$service("/dialogueNote/addDialogueNote",data).then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.inquire(1);
            this.entryShow = false;
          }
        }).catch(err => {
          this.loading = false;
        })
      }
      if (this.entryTitle === "修改词条") {
        data.id = this.actvieDialogueNote;
        this.$service("/dialogueNote/updateDialogueNote",data).then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.inquire();
            this.entryShow = false;
          }
        }).catch(err => {
          this.loading = false;
        })
      }
    },
    // 关闭新增
    entryClose() {
      this.addSpeechId = 100;
      this.entryForm = {
        name: "",
        entryType: "CUSTOMER",
        keyword: "",
        answerType: "SINGLE_ANSWER",
        contentList: [{id: 1, content: ""}],
        action: "",
        flow: "",
        flowId: "",
      }
    },
    // 点击新增机器人话术
    addSpeechClick() {
      if (this.entryForm.contentList.length >= 10) {
        this.$message.warning("已达到10个上限，请删除重试")
        return
      }
      this.addSpeechId++;
      let obj = {
        id: this.addSpeechId,
        content: ""
      }
      this.entryForm.contentList.push(obj);
    },
    // 点击删除机器人话术
    deleteSpeechClick(val) {
      let index = -1;
      index = this.entryForm.contentList.indexOf(val);
      if (index !== -1) {
        this.entryForm.contentList.splice(index, 1);
      }
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm('此操作将删除【'+val.name+'】词条, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: val.id
        }
        this.$service("/dialogueNote/deleteDialogueNote",data).then(res => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.inquire();
          }
        })
      }).catch(() => {
               
      });
    },
  },
}
</script>
<style scoped>
.knowledgeEntry {
  height: 100%;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  /* margin-bottom: 20px; */
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
</style>