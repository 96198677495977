<template>
  <div class="interFaceStatistics">
    <div class="nav">
      <div class="batchName">
        <el-checkbox v-model="filterRestore" style="margin-left: 20px"
          >过滤坐席分组</el-checkbox
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :span-method="objectSpanMethod"
      v-loading="loadingTable"
      border
    >
      <!-- <el-table-column prop="date" label="" width="150" align="center">
      </el-table-column> -->
      <el-table-column label="今日数据" align="center">
        <el-table-column prop="type" label="" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.type == 'robot'">机器人</span>
            <span v-if="row.type == 'flag'">盲加微信</span>
            <span v-if="row.type == 'hangupWechat'">挂机微信</span>
            <span v-if="row.type == 'unFlag'">YX加粉</span>
            <span v-if="row.type == 'all'">全部</span>
            <span v-if="row.type == 'clientTaking'">人工沟通加粉</span>
          </template>
        </el-table-column>
        <el-table-column prop="group" label="分组" align="center">
        </el-table-column>
        <el-table-column prop="dailyHelloCount" label="招呼数" align="center">
        </el-table-column>
        <el-table-column
          prop="dailyAutoPassedCount"
          label="自动通过数"
          align="center"
        >
          <template slot="header" slot-scope="scope">
            <span>自动通过数</span>
            <el-tooltip
              class="item"
              effect="light"
              content="微信未开启好友验证，加好友时自动通过"
              placement="top-start"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ scope.row.dailyAutoPassedCount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="dailyAutoPassedRate"
          label="自动通过率"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.dailyPassCount > 0"
              >{{
                Math.round(
                  (row.dailyAutoPassedCount / row.dailyPassCount) * 100
                )
              }}%</span
            >
            <span v-if="row.dailyPassCount == 0">0%</span>
          </template>
        </el-table-column>
        <el-table-column prop="dailyPassCount" label="通过数" align="center">
        </el-table-column>
        <el-table-column prop="dailyHelloRate" label="通过率" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.dailyHelloCount > 0"
              >{{
                Math.round((row.dailyPassCount / row.dailyHelloCount) * 100)
              }}%</span
            >
            <span v-if="row.dailyHelloCount == 0">0%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dailyNotFoundRate"
          label="未查询到好友比率"
          width="140"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.dailyAllCount > 0"
              >{{
                Math.round(
                  (row.dailyNotFoundCount / row.dailyAllCount) * 100
                )
              }}%</span
            >
            <span v-if="row.dailyAllCount == 0">0%</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="近一小时数据" align="center">
        <!-- <el-table-column prop="group" label="分组" align="center">
        </el-table-column> -->
        <el-table-column prop="recentHelloCount" label="招呼数" align="center">
        </el-table-column>
        <el-table-column
          prop="recentAutoPassedCount"
          label="自动通过数"
          align="center"
        >
          <template slot="header" slot-scope="scope">
            <span>自动通过数</span>
            <el-tooltip
              class="item"
              effect="light"
              content="微信未开启好友验证，加好友时自动通过"
              placement="top-start"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <span v-if="row.recentAutoPassedCount == null">0</span>
            <span v-else>{{ row.recentAutoPassedCount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="recentAutoPassedDate"
          label="自动通过率"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.recentPassCount > 0"
              >{{
                Math.round(
                  (row.recentAutoPassedCount / row.recentPassCount) * 100
                )
              }}%</span
            >
            <span v-if="row.recentPassCount == 0">0%</span>
          </template>
        </el-table-column>
        <el-table-column prop="recentPassCount" label="通过数" align="center">
        </el-table-column>
        <el-table-column prop="recentHelloRate" label="通过率" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.recentHelloCount > 0"
              >{{
                Math.round((row.recentPassCount / row.recentHelloCount) * 100)
              }}%</span
            >
            <span v-if="row.recentHelloCount == 0">0%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="recentNotFoundRate"
          label="未查询到好友比率"
          width="140"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.recentAllCount > 0"
              >{{
                Math.round(
                  (row.recentNotFoundCount / row.recentAllCount) * 100
                )
              }}%</span
            >
            <span v-if="row.recentAllCount == 0">0%</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "interFaceStatistics",
  data() {
    return {
      tableData: [],
      loadingTable: true,
      requestLogSummaryAsyn: null,
      group: "",
      filterRestore: false,
    };
  },
  mounted() {
    this.getList();
    this.requestLogSummaryAsyn = setInterval(() => {
      this.getList();
    }, 1000);
  },
  beforeDestroy() {
    if (this.requestLogSummaryAsyn) {
      //如果定时器还在运行 或者直接关闭，不用判断
      clearInterval(this.requestLogSummaryAsyn); //关闭
    }
  },
  methods: {
    //   获取数据
    getList() {
      let data = {};
      this.$http("/wechat/requestLogSummary", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            this.loadingTable = false;
            if (this.filterRestore === true) {
              this.group = "NORMAL";
              let list = res.data.filter((el) => el.groupType === this.group);
              this.tableData = list.reverse();
            } else {
              let list = res.data;
              this.tableData = list.reverse();
            }
            // this.tableData = [{"type":"all","group":"teamnew","dailyHelloCount":"752","recentHelloCount":"137","dailyPassCount":"230","recentPassCount":"39","dailyNotFoundCount":"138","recentNotFoundCount":"24"},{"type":"all","group":"team1","dailyHelloCount":"1609","recentHelloCount":"536","dailyPassCount":"362","recentPassCount":"91","dailyNotFoundCount":"310","recentNotFoundCount":"97"},{"type":"Robot","group":"team1","dailyHelloCount":"57","recentHelloCount":"49","dailyPassCount":"12","recentPassCount":"11","dailyNotFoundCount":"12","recentNotFoundCount":"12"},{"type":"Robot","group":"teamnew","dailyHelloCount":"568","recentHelloCount":"137","dailyPassCount":"202","recentPassCount":"39","dailyNotFoundCount":"93","recentNotFoundCount":"24"},{"type":"HangupWechat","group":"team1","dailyHelloCount":"1384","recentHelloCount":"455","dailyPassCount":"298","recentPassCount":"71","dailyNotFoundCount":"256","recentNotFoundCount":"83"},{"type":"HangupWechat","group":"teamnew","dailyHelloCount":"184","recentHelloCount":"0","dailyPassCount":"28","recentPassCount":"0","dailyNotFoundCount":"45","recentNotFoundCount":"0"},{"type":"Flag","group":"team1","dailyHelloCount":"168","recentHelloCount":"32","dailyPassCount":"52","recentPassCount":"9","dailyNotFoundCount":"42","recentNotFoundCount":"2"}]
            // this.groupType();
          }
        }
      );
    },
    getTableData() {
      let spanOneArr = [],
        spanTwoArr = [],
        concatOne = 0,
        concatTwo = 0;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
          spanTwoArr.push(1);
        } else {
          if (item.type === this.tableData[index - 1].type) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr,
        two: spanTwoArr,
      };
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (columnIndex === 0) {
        const _row = this.getTableData(this.JZPFData).one[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
};
</script>
<style scoped>
.data_style {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.nav .batchName .el-select {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.upload-demo >>> .el-upload-dragger {
  width: 304px;
}
.records_style >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
</style>