<template>
	<div class="machineCalling">
		<div class="groupName">
			群组名称：
			<el-select v-model="groupId" placeholder="请选择" @change = 'change(groupId)'>
				<el-option v-for="item in groupList" :key="item.groupName" :label="item.groupName" :value="item.id"></el-option>
			</el-select>
		</div>
		<ul class="info">
			<li class='iconfont icon-nv-line-customer '>
				<i>潜客数</i>
				<b>{{this.info.passengerNum}}</b>
			</li>
			<li class="iconfont icon-youxiao new ">
				<i>预计有效数</i>
				<b>{{this.info.userfulNum}}</b>
			</li>
			<li class="iconfont icon-qian ">
				<i>费用估计</i>
				<b>{{this.info.expense}}</b>
			</li>
			<li class="iconfont icon-feiyong ">
				<i>账户余额</i>
				<b>{{this.info.balance}}</b>
			</li>
			<li class="iconfont icon-waihurenwu">
				<i>线路余额</i>
				<b>{{this.info.lineBalance}}</b>
			</li>
			<li class="iconfont icon-duanxinyingxiao">
				<i>挂机短信余额</i>
				<b style="color:red;">{{this.info.SMSbalance}}</b>
			</li>
		</ul>
		<div class="box">
			<div class="right">
				<p>挂机短信</p>
				<span>
					<b>启用短信通知：</b>
					<el-radio-group v-model="usingSMS" size="mini">
						<el-radio-button label="true">启用</el-radio-button>
						<el-radio-button label="false">不启用</el-radio-button>
					</el-radio-group>
				</span> 
				<span>
					<b>短信意向等级：</b>
					<el-checkbox-group v-model="grade" size="mini" class='grade' :disabled='disabled'>
						<el-checkbox-button :label="1" :key="1">A</el-checkbox-button>
						<el-checkbox-button :label="2" :key="2">B</el-checkbox-button>
						<el-checkbox-button :label="3" :key="3">C</el-checkbox-button>
						<el-checkbox-button :label="4" :key="4">D</el-checkbox-button>
						<el-checkbox-button :label="5" :key="5">E</el-checkbox-button>
						<el-checkbox-button :label="6" :key="6">F</el-checkbox-button>
					</el-checkbox-group> 
				</span>			
			</div>
			<div class="left">
				<p>外呼任务设置</p>
				<span>
					<b>请选择话术：</b> 
					<el-select v-model="speakingId" placeholder="请选择">
						<el-option v-for="item in speakingList" :key="item.dialogResId" :label="item.dialogName" :value="item.dialogResId"></el-option>
					</el-select>
					<a href="javascript:void(0)" @click='speakingManage()'>话术管理</a>
				</span>
				<span>
					<b>请选择线路：</b>
					<el-select v-model="lineId" placeholder="请选择">
						<el-option v-for="item in lineList" :key="item.lineId" :label="item.phoneNumber" :value="item.lineId"></el-option>
					</el-select> 
				</span>
				<span>
					<b class='repair'>启动方式<i></i>：</b>
					<el-radio v-model="startType" label="1">立即发起</el-radio>
					<el-radio v-model="startType" label="2">定时发起</el-radio>
				</span>
				<span class="time" >
					<b class='repair'>启动时间<i></i>：</b>					
					<el-date-picker v-model="time" type="datetimerange" :disabled='bol' range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format='yyyy-MM-dd HH:mm:ss'></el-date-picker>
					<!-- 消息提示 -->
					<el-tooltip placement="right">
						<div slot="content">启动的开始时间和结束时间必须是同一天<br/></div>
						<i class="el-icon-question" style="color: #34BFA3;"></i>
					</el-tooltip>				
				</span>				
				<span>
					<b class='repair'>开启打断<i></i>：</b>
					<el-switch v-model="interrupt" active-text="开启" inactive-text=""></el-switch>
				</span>				
			</div>
		</div>
		<div class="operate">
			<el-button type="primary" @click='click_startCalling()'>开始拨打</el-button>
			<el-button type="primary" @click='reset()' class='reset'>重置</el-button>
		</div>
		<!-- 开始外呼 -->
		<el-dialog  :visible.sync="startCalling"    width='500px' >
				<div slot="title" class="dialog-title" >
					<h3>开始外呼</h3>
				</div>
				<el-form :model="form">
					<el-form-item label="" :label-width="formWidth">
							<b>任务名称：</b> 
							<el-input v-model="form.taskName" autocomplete="off" placeholder='请输入任务名称'></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer" align="center">
						<el-button type="primary"  @click="startCalling_submit()" >提 交</el-button>
						<el-button  @click="startCalling_cancel()" >取 消</el-button>
				</div>
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		name:'machineCalling',
		data(){
			return {
				msg:'机器外呼',
				info:{
					balance:'',
					passengerNum:'',
					userfulNum:'',
					expense:'',
					lineBalance:'',
					SMSbalance:''
				},
				groupList:'',
				groupId:'',
				callOut:'1',
				usingSMS:'false',
				grade:[],
				
				speakingList:[],
			  speakingId:'',
				lineList:'',
				lineId:'',
				
				startType:'1',
				time:[],
				
				interrupt:'false',
				bol:true,
				href:'',
				
				startCalling:false,
				formWidth: '30px',
				form:{
					taskName:''
				},
				disabled:true
				
			}
		},
		watch:{
			time(val){//时间
				if(val.length){
					let startTime = val[0].split(' ')[0],
							endTime = val[1].split(' ')[0];
					if(startTime !== endTime){
						this.time = [];
						this.$message({type:'warning',message:'启动的开始时间和结束时间必须是同一天!'});
					}
				}				
			},
			startType(val) {//启动方式			
					if(this.startType == '2'){
						this.bol = false;
					}else {
						this.time = [];
						this.bol = true;
					}
			},
			groupList(val) {//群组列表,
					if(val.length == 0){
						this.$confirm('群组列表为空，请先跳转至 潜客筛选 页面!', '提示', {
							confirmButtonText: '跳转',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {//跳转操作										
							this.$router.push('/passengerSelect');																	
						});
					}
			},
			speakingList(val) {//话术列表
					if(val.length == 0){
						this.$confirm('话术列表为空，请跳转至 话术管理!', '提示', {
							confirmButtonText: '跳转',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {//跳转操作													
							window.open(this.href);																	
						});
					}
			},
			usingSMS(val) {//启用短信通知
				if(val == 'false'){
					this.disabled = true;
				}else {
					this.disabled = false;
					this.grade = [1];
				}
			}
		},
		mounted(){
			this.getUserInfo();
			this.getSpeaking_lineList();
		},
		methods:{
			getSpeaking_lineList(){//话术列表、线路模板
				this.$http('/aida/dialogAndLineList').then(res=>{
					if(res.result == 200){
						let lineList = res.data.lineDataList,
								speakingList = res.data.dialogDataList;
						if(lineList.length){
							this.lineList = lineList;
							this.lineId = lineList[0].lineId; 
						}
						if(res.data.dialogDataList.length){
								this.speakingList = speakingList;														
								this.speakingId = speakingList[0].dialogResId;	
						}						
					}				
				});				
			},
			getUserInfo(){//用户信息
				this.$http('/aida/getSmsAndVoiceBalance').then(res=>{
					if(res.result == 200){
						this.info.SMSbalance = res.data.smsBalance + '条';// 挂机短信余额 
						this.info.lineBalance = res.data.voiceBalance + '分钟';//线路余额
					}
				});
				this.$http('/user/userInfo').then(res=>{
					if(res.result == 200){
						this.info.balance = (res.data.balance/100).toFixed(2) + '元';	//账户余额					
					}	
				});
				let data = {
						pageNo:1,
						pageSize:1000,
						queryType:'PHONE_CALL'
					};
				this.$http('/sms/macGroupList',data).then(res=>{														
					if(res.result == 200){
						let list = res.data.list,
								id = this.$route.query.groupId;					
						if(id){
							list.map(item=>{
								if(item.id == id){
									this.groupId = id; 			
									this.info.passengerNum = item.totalCount + '人'; 
									this.info.userfulNum = item.estimateCount + '条' ; 
									this.info.expense = (item.estimateFee /100).toFixed(2)+'元';
								}
							});									
						}else {
							this.groupId = list[0].id; 			
							this.info.passengerNum = list[0].totalCount + '人'; //潜客数		
							this.info.userfulNum = list[0].estimateCount + '条' ; //预计有效数		
							this.info.expense = (list[0].estimateFee /100).toFixed(2)+'元'; //费用估计
						}
						this.groupList = list;
					}
				});
			},
			click_startCalling(){//开始拨打
				if(this.groupList.length == 0){
					this.$confirm('群组列表为空，请先跳转至 潜客筛选 页面!', '提示', {
						confirmButtonText: '跳转',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {//跳转操作										
						this.$router.push('/passengerSelect');																	
					});
				}else {
					this.startCalling = true;		
				}
			},
			startCalling_cancel(){//取消拨打
				this.form.taskName = '';
				this.startCalling = false;				
			},
			startCalling_submit(){//拨打电话
				if(this.form.taskName != ''){
					let gradeStr = this.grade.join(),
							data = {
								macGroupId:this.groupId,
								dialogResourceId:this.speakingId,
								startupType:this.startType,
								startupDateStart:this.time[0],
								startupDateEnd:this.time[1],
								lineIds:this.lineId,
								smsNotify:this.usingSMS,
								smsFilters:gradeStr,
								interrupt:this.interrupt,
								taskName:this.form.taskName
						};
					this.$http('/aida/addPhoneCallTask',data).then(res=>{	
						if(res.result == 200){
							this.$message({message: '提交成功！',type: 'success'});
							this.startCalling_cancel();
							this.$router.push('/machineCallingRecord');
						}
					});
				}else {
					this.$message({message: '任务名称不能为空！',type: 'warning'});
				}
			},
			reset(){//重置
				let id = this.$route.query.groupId,
						list = this.groupList;
				if(id){
					list.map(item=>{
						if(item.id == id){
							this.groupId = id; 			
							this.info.passengerNum = item.totalCount + '人'; 
							this.info.userfulNum = item.estimateCount + '条' ; 
							this.info.expense = (item.estimateFee /100).toFixed(2)+'元';
						}
					});									
				}else {
					this.groupId = list[0].id; 			
					this.info.passengerNum = list[0].totalCount + '人'; //潜客数		
					this.info.userfulNum = list[0].estimateCount + '条' ; //预计有效数		
					this.info.expense = (list[0].estimateFee /100).toFixed(2)+'元'; //费用估计
				}				
				if(this.speakingList.length){
					this.speakingId = this.speakingList[0].dialogResId;//默认 id
				}
				if(this.lineList.length){
					this.lineId = this.lineList[0].lineId; //默认 id
				}								
				this.startType = '1';
				this.time = [];
				this.usingSMS = 'false';
				this.grade = '1';
				this.disabled = true;
				this.interrupt = 'false';
				
			},
			speakingManage(){//话术管理
				this.$http('/aida/dialogManage').then(res=>{		
					if(res.result == 200){
						if(res.data.route){
							this.href = res.data.route;
							window.open(this.href);
						}else {
							this.$message({message: '系统故障，请联系管理员！',type: 'warning'});
						}						
					}
				});				
			},
			change(id){//组名改变
				this.groupList.map(item=>{
					if(item.id == id){
						this.info.passengerNum = item.totalCount + '人';//潜客数
						this.info.userfulNum = item.estimateCount + '条'; //预计有效数
						this.info.expense = (item.estimateFee/100).toFixed(2)+'元';//费用估计	
					}
				});							
			}
			
		}
	}
</script>


<style scoped="scoped">
	.groupName{
		width: 1100px;
	}
	.groupName .el-select{
		width: 400px;
	}
	.groupName i{
		font-style: normal;
		font-size: 14px;
		margin: 0 15px;
	}
	.info{
		width: 1100px;
		height: 116px;
		margin: 30px 0;
		padding-top: 20px;
		display: flex;
		justify-content: space-between;
		box-shadow: 0px 0px 1px 1px lightgray;
	}
	.info li {
		width: 17%;
		text-align: center;
		line-height: 50px;
		font-size: 22px;
		color: #34bfa3;
	}
	.info li i{
		font-style: normal;
		font-size: 18px;
		color:#8F8F8F ;
		margin-left: 5px;
	}
	.info li b{
		display: block;
		font-size: 18px;
		color: #657180;
	}
	.box{
		width: 1080px;
		height: 300px;
		padding: 10px;
		box-shadow: 0px 0px 1px 1px lightgray;
	}
	.right{
		width: 420px;
		height: 100%;
		float: right;
		border-left:1px solid lightgray;
		padding-left: 25px;
	}
	.right p,.left p{
		font-size: 18px;
		margin-bottom: 15px;
	}
	.right span{
		display: block;
		width: 380px;
		margin-left: 35px;
		line-height: 50px;
	}
	.right .grade{
		display: inline-block;
		vertical-align: top;
		width: 260px;
	}
	.right b{
		color:#657180 ;
		font-size: 14px;
		margin: 0 15px 0px 0;
	}
	.box .left{
		width: 625px;
		height: 100%;
		box-sizing: border-box;
	}
	.left span {
		display: block;
		width: 600px;
		line-height: 50px;
		margin-left: 30px;
	}
	.left span b {
		font-size: 14px;
		color:#657180 ;
		margin-left: 15px ;
	}
	.left a{
		font-style: normal;
		font-size: 14px;
		color: #169BD5;
		margin-left: 15px;
	}
	.left span .el-select{
		width: 400px;
	}
	.left .repair i{
		margin-left: 13px;
	}
	.left .time .el-date-editor{
		width: 400px;
	}
	.operate{
		margin-top: 50px ;
  }
  .operate .el-button{
	  width: 120px;
  }
	.reset {
		margin-left: 30px;
	}
	.el-dialog b {
		color:#657180 ;
		font-size: 17px;
	}
	.el-dialog .el-input{
		width: 300px;
	}
	.dialog-footer .el-button {
	 	width: 120px;
	}
	
</style>