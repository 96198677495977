<template>
  <div id="app">
    <router-view name="first"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;*/
}
.el-message-box {
  width: 600px;
}
.mzindex {
  z-index: 9999 !important;
  position: relative !important;
}
.el-message-box__wrapper {
  z-index: 9999 !important;
}
</style>
