<template>
  <div class="telephoneTraffic">
    <div class="nav_menu_m">
      <div class="nav_item">
        用户名称：
        <el-select
          v-model="condition.loginName"
          placeholder="请选择用户"
          filterable
          remote
          :loading="loadingName"
          :remote-method="NameRemoteMethod"
          @change="loginNameChange"
          @focus="NameRemoteFocus"
          clearable
        >
          <el-option
            v-for="item in userNameList"
            :key="item.id"
            :value="item.loginName"
            :label="item.userName"
          ></el-option>
        </el-select>
      </div>
      <div class="nav_item">
        时间：
        <el-date-picker
          v-model="condition.gmtCreated"
          type="daterange"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="callPickerOptions"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="nav_menu_m">
      <div class="nav_item">
        客服名称：
        <el-input
          v-model="condition.number"
          placeholder="请输入坐席"
          clearable
        ></el-input>
        <!-- <el-select 
          v-model="condition.number" 
          @focus="numberRemoteFocus" 
          placeholder="请选择坐席" 
          remote
          filterable
          clearable
          :remote-method="numberRemoteRemoteMethod"
        >
          <el-option
            v-for="item in numberList"
            :key="item.id"
            :value="item.number"
            :label="item.name"
          ></el-option>
        </el-select> -->
      </div>
      <div class="nav_item">
        队列：
        <el-select
          v-model="condition.teamId"
          placeholder="请选择拨打队列"
          filterable
          clearable
          :disabled="!condition.loginName"
        >
          <el-option
            v-for="item in teamIdList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquireClick">查询</el-button>
      <!-- <el-button type="success" @click="exportReportList" :disabled="exportDisabled">导出</el-button> -->
    </div>
    <!-- 话务统计表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
      :summary-method="getExpensesSummaries"
      show-summary
      @sort-change="tableSortChange"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="clientNumber"
        label="坐席编号"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="clientName"
        label="客服"
        min-width="150"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="date"
        label="日期"
        :width="dateWidth"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="orderCount"
        label="成单量"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="submitCount"
        label="招呼量"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="fanCount"
        label="进粉量"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="chatCount"
        label="互动量"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="payCount"
        label="支付量"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="unsubscribeCount"
        label="删粉量"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="passRateLabel"
        label="通过率"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="chatRateLabel"
        label="互动率"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="payRateLabel"
        label="支付率"
        align="center"
      ></el-table-column>
      <!-- <el-table-column prop="efficiency" width="115" align="center">
        <template slot="header" slot-scope="scope">
          <span>工作利用率</span>
          <el-tooltip class="item" effect="light" content="通话总时长/登录总时长" placement="top-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      @size-change="sizeChange"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "mechanismStatistics",
  data() {
    return {
      sortField: "",
      sortType: "",
      dateWidth: "115",
      loading: false,
      loadingName: false,
      userNameList: [],
      weekPickerOptions: {
        firstDayOfWeek: 1,
      },
      callPickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      condition: {
        gmtCreated: [],
        callEndAtStart: "",
        callEndAtEnd: "",
        number: "",
        loginName: "",
        teamId: "",
      },
      teamIdList: [],
      numberList: [],
      currentPage: null,
      total: null,
      tableData: [],
      summaryTableData: [],
      exportDisabled: true,
      pageSize: 10,
      number: null,
      userloginName: "",
      userName: "",
    };
  },
  mounted() {
    let end = new Date();
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.callEndAtEnd = end;
    this.condition.gmtCreated = [start, end];
    this.theWeek();
    this.inquireUserList({ pageSize: 1000, pageNo: 1, enabled: true });
    this.inquireNumber({
      pageSize: 1000,
      pageNo: 1,
      loginName: this.userName,
    });
    this.inquire(1);
  },
  methods: {
    // 排序
    // 排序
    tableSortChange(column) {
      console.log(column)
      if (column.order === "descending") {
        this.sortField = column.prop;
        this.sortType = 'asc';
      } else if (column.order === "ascending") {
        this.sortField = column.prop;
        this.sortType = 'desc';
      } else {
        this.sortField = "";
        this.sortType = "";
      }
      this.inquire();
    },
    // --------排序end
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    inquireTeamList() {
      //查询拨打列队列表
      let params = {
        pageNo: 1,
        pageSize: 1000,
        loginName: this.condition.loginName,
      };
      this.loading = true;
      this.$http("/sip/team/list", null, "get", null, params).then((res) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.records;
          this.teamIdList = list;
        }
      });
    },
    inquire(index) {
      //查询
      this.dataProcessing(); //查询参数处理
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        userLoginName: this.userName,
        clientNumber: this.condition.number,
        teamId: this.condition.teamId,
        start: this.condition.callEndAtStart,
        end: this.condition.callEndAtEnd,
        sortField: this.sortField,
        sortType: this.sortType,
      };
      if (!params.start || !params.end) {
        this.$message({ message: "请选择时间", type: "warning" });
        return;
      }
      this.inquireReportList(params);
    },
    dataProcessing() {
      //查询参数处理
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreated && this.condition.gmtCreated.length > 0) {
        gmtCreatedStart = this.condition.gmtCreated[0];
        gmtCreatedEnd = this.condition.gmtCreated[1];
      }
      this.condition.callEndAtStart = gmtCreatedStart;
      this.condition.callEndAtEnd = gmtCreatedEnd;
    },
    inquireReportList(params) {
      //查询微信坐席业绩信息
      this.loading = true;
      this.$http(
        "/wechat/report/clientPerformanceInfo",
        null,
        "get",
        null,
        params
      ).then((res) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.records;
          let summary = res.data.summary;
          this.total = res.data.total;
          this.exportDisabled = true;
          list.forEach((item) => {
            item.date = this.$common.transformTime(item.date).substring(0, 10);
            item.passRateLabel = (item.passRate * 100).toFixed(2) + "%";
            item.chatRateLabel = (item.chatRate * 100).toFixed(2) + "%";
            item.payRateLabel = (item.payRate * 100).toFixed(2) + "%";
          });
          summary.passRateLabel = (summary.passRate * 100).toFixed(2) + "%";
          summary.chatRateLabel = (summary.chatRate * 100).toFixed(2) + "%";
          summary.payRateLabel = (summary.payRate * 100).toFixed(2) + "%";
          this.tableData = list;
          this.summaryTableData = summary;
          if (this.tableData && this.tableData.length > 0) {
            this.exportDisabled = false;
          }
        }
      });
    },
    currentPage_data(index) {
      //当前页
      this.inquire(index);
    },
    sizeChange(index) {
      //显示条数--分页
      this.pageSize = index;
      this.inquire(1);
    },
    // 合计行线路费用统计
    getExpensesSummaries(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        for (let i in this.summaryTableData) {
          if (i == column.property) {
            arr[index] = this.summaryTableData[i];
          }
        }
      });

      return arr;
    },
    inquireClick() {
      //点击查询
      this.inquire(1);
    },
    exportReportList() {
      //点击导出
      let params = {
        pageSize: 10,
        pageNo: this.currentPage,
        loginName: this.userName,
        clientNumber: this.condition.number,
        start: this.condition.callEndAtStart,
        end: this.condition.callEndAtEnd,
      };
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      let href =
        this.$constants.baseURL + "/sip/client/exportReportList?" + url;
      this.exportDisabled = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.exportDisabled = data;
        }, 4000);
      });
      // window.location.href = href;
    },
    inquireUserList(params) {
      //查询公司名称
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          this.userNameList = list;
          this.userNameList.forEach((item) => {
            if (item.loginName === this.userName) {
              this.condition.loginName = this.userName;
            }
          });
        }
      });
    },
    // 选择用户名称
    loginNameChange(val) {
      this.userName = val;
      this.condition.number = "";
      this.condition.teamId = "";
      this.inquireTeamList();
    },
    // 用户名称获取焦点
    NameRemoteFocus() {
      this.inquireUserList({ pageSize: 1000, pageNo: 1, enabled: true });
    },
    NameRemoteMethod(userName) {
      //搜索用户名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize: 1000,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireUserList(params);
      }, 200);
    },
    // 客服名称获取焦点
    numberRemoteFocus() {
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.condition.loginName,
      };
      this.inquireNumber(params);
    },
    // 客服名称搜索
    numberRemoteRemoteMethod(val) {
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.condition.loginName,
        number: val,
      };
      this.inquireNumber(params);
    },
    inquireNumber(params) {
      //查询坐席
      this.$http("/sip/client/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          this.numberList = res.data.list;
        }
      });
    },
  },
};
</script>
<style scoped>
.nav_menu_m {
  margin-bottom: 20px;
}
.nav_menu_m .nav_item {
  display: inline-block;
  margin-right: 20px;
}
.nav_menu_m .nav_item .el-input,
.nav_menu_m .nav_item .el-select {
  width: 250px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>