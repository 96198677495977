import { render, staticRenderFns } from "./wechatSubjectGroup.vue?vue&type=template&id=19e25e1b&scoped=true&"
import script from "./wechatSubjectGroup.vue?vue&type=script&lang=js&"
export * from "./wechatSubjectGroup.vue?vue&type=script&lang=js&"
import style0 from "./wechatSubjectGroup.vue?vue&type=style&index=0&id=19e25e1b&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e25e1b",
  null
  
)

export default component.exports