<template>
  <div class="tagtrack">
    <div class="nav">
      <div class="batchName">
        客户编号：
        <el-input
          v-model="customerCode"
          placeholder="请输入客户编号"
          clearable
        ></el-input>
      </div>
      <div class="batchName">
        创建时间：
        <el-date-picker
          v-model="gmtCreated"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          :clearable="false"
        ></el-date-picker>
      </div>
    </div>
    <div class="nav">
      <div class="batchName">
        批次编号：
        <el-input
          v-model="batchNumber"
          placeholder="请输入批次编号"
          clearable
        ></el-input>
      </div>
      <div class="batchName">
        数据标记：
        <el-input
          v-model="dataSign"
          placeholder="请输入数据标记"
          clearable
        ></el-input>
      </div>
    </div>
    <div class="operate_btn">
      <el-button type="primary" @click="inquire(1)">查询</el-button>
    </div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="batchNumber"
        label="批次编号"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="customerCode"
        label="客户编号"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="dataSign"
        label="数据标记"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag type="warning" v-if="scope.row.status == 'CREATING'"
            >请求发送中</el-tag
          >
          <el-tag type="warning" v-if="scope.row.status == 'SUBMITTED'"
            >已提交好友申请</el-tag
          >
          <el-tag type="danger" v-if="scope.row.status == 'NOT_FOUND'"
            >未查询到好友</el-tag
          >
          <el-tag type="danger" v-if="scope.row.status == 'SUBMIT_FAILURE'"
            >好友申请异常</el-tag
          >
          <el-tag type="danger" v-if="scope.row.status == 'REJECTED'"
            >拒绝好友申请</el-tag
          >
          <el-tag type="danger" v-if="scope.row.status == 'EXPIRED'"
            >好友申请已过期</el-tag
          >
          <el-tag type="danger" v-if="scope.row.status == 'RELOGIN'"
            >登录失效</el-tag
          >
          <el-tag type="success" v-if="scope.row.status == 'ADDEDFRIEND'"
            >已经是好友</el-tag
          >
          <el-tag type="success" v-if="scope.row.status == 'PASSED'"
            >通过好友申请</el-tag
          >
          <el-tag type="danger" v-if="scope.row.status == 'CLIENT_OFFLINE'"
            >手机离线</el-tag
          >
          <el-tag type="danger" v-if="scope.row.status == 'NOTLOGGEDIN'"
            >未登录</el-tag
          >
          <el-tag type="warning" v-if="scope.row.status == 'NO_REQUEST'"
            >未加微信</el-tag
          >
          <el-tag
            type="warning"
            v-if="scope.row.status == 'CUSTOMER_ACCOUNT_IS_ILLEGAL'"
            >对方账号异常</el-tag
          >
          <el-tag type="warning" v-if="scope.row.status == ''">未知</el-tag>
          <!-- <el-tag type="warning" v-else>未知</el-tag> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="180"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <!-- :width="roleMenu === 'SUPER_MANAGER' ? '270' : '150'" -->
      <el-table-column prop label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <!-- v-if="roleMenu === 'SUPER_MANAGER'" -->
          <el-button
            type="text"
            size="small"
            @click="dialogueTranslate(scope.row)"
            :disabled="scope.row.playVoiceDisabled"
            >对话翻译</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    >
    </el-pagination>
    <!-- 对话翻译 -->
    <dialogue-translation
      ref="dialogueTranslation"
      :title="dialogueTranslateTitle"
      type="translation"
      :dialogueRound="dialogueRound"
      :currentSrc="currentSrc"
      :dialogueData="dialogueList"
    >
    </dialogue-translation>
  </div>
</template>
<script>
import dialogueTranslation from "../pages/dialogueTranslation";
export default {
  name: "tagtrack",
  components: {
    dialogueTranslation,
  },
  data() {
    return {
      dialogueTranslateTitle: "",
      dialogueRound: false,
      currentSrc: null, //录音url
      dialogueList: [],
      callUuid: "",
      unifiedLoading: null,
      // ----------------
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      loadingTable: false,
      batchNumber: "",
      dataSign: "",
      customerCode: "",
      gmtCreated: [],
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "近三个月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let sm = start.getMonth() - 2;
              start.setMonth(sm);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-标签追踪";
    this.theWeek();
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.gmtCreated = [start, end];
    this.inquire(1);
  },
  methods: {
    // 点击对话翻译
    dialogueTranslate(val) {
      this.callUuid = val.callerUuid;
      let data = {
        userLoginName: val.userLoginName,
        uuid: val.callerUuid,
      };
      // let path = "https://backend.huixiao9.huiyu.org.cn";
      this.unifiedLoading = this.$loading();
      this.$http("/sip/call/translationInfo", null, "get", this.urlServer, data)
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            this.currentSrc = `${this.urlServer}/sip/playVoice?callId=${val.id}&loginName=${val.userLoginName}`;
            this.dialogueList = [];
            this.dialogueTranslateTitle = "对话翻译-" + val.customerCode;
            if (val.clientNumber) {
              this.dialogueTranslateTitle += "-" + val.clientNumber;
            }
            this.dialogueRound = res.data.dialogueRound;
            if (res.data.dialogueRound) {
              // if (!res.data || res.data.status !== "SUCCESS") {
              //   this.$message.warning("对话未翻译");
              //   return
              // }
              // this.dialogueTranslateShow = true;
              // this.$refs.dialogueTranslation.speechTestClick();
              // let list = JSON.parse(res.data.list.content);
              let list = res.data.list;
              // list.forEach((item) => {
              //   item.customerChannelId = res.data.list.customerChannelId;
              // });
              this.dialogueList = list;
              // if (res.data.list.labels) {
              //   this.dialogueTagList = res.data.labels.split(",");
              // }
            } else {
              let list = JSON.parse(res.data.list.content);
              console.log(list);
              list.forEach((item) => {
                item.customerChannelId = res.data.list.customerChannelId;
              });
              this.dialogueList = list;
              // this.dialogueList = res.data.list;
            }

            this.$refs.dialogueTranslation.speechTestClick();
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.gmtCreated) {
        gmtCreatedStart = this.gmtCreated[0];
        gmtCreatedEnd = this.gmtCreated[1];
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        batchNumber: this.batchNumber,
        dataSign: this.dataSign,
        customerCode: this.customerCode,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
      };
      this.getDialogueDataSignList(data);
    },
    // 查询列表
    getDialogueDataSignList(data) {
      this.loadingTable = true;
      this.$http("/dialogueDataSign/list", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableData = list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 页数
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
  },
};
</script>
<style scoped>
.nav,
.operate_btn {
  margin-bottom: 20px;
}
.nav .batchName {
  display: inline-block;
  width: 400px;
}
.nav .batchName .el-input {
  width: 250px;
}
.nav .batchName .el-date-editor {
  width: 250px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>