<template>
  <div
    ref="node"
    :style="nodeContainerStyle"
    @click="clickNode"
    @mouseup="changeNodeSite"
    @contextmenu="nodeRightMenu"
    @dblclick="onDblClick"
    :class="nodeContainerClass"
  >
    <!-- 节点名称 -->
    <!-- <div class="ef-node-text" :show-overflow-tooltip="true">
      {{ node.name }}
    </div> -->
    <!-- 节点内容 -->
    <div v-if="node.type === 'REDIRECT'">
      <div>
        <div
          class="ef-node-text-content"
          v-if="node.dialogueNodeContentList.length > 0"
        >
          <div v-for="(i, j) in node.dialogueNodeContentList" :key="j">
            {{ i.content }}
          </div>
        </div>
        <span v-else>-</span>
      </div>
      <div>
        <img
          src="@/assets/img/tiaoz.png"
          alt=""
          style="width: 14px; vertical-align: -3%"
        />
        <span v-for="item in flowNodeList" :key="item.id">
          <span v-if="node.nextScenarioId === item.id" style="font-size: 12px"
            >主线流程：{{ item.name }}</span
          >
        </span>
      </div>
    </div>
    <div v-if="node.type === 'NORMAL'" style="min-height: 70px">
      <div
        class="ef-node-text-content"
        v-if="node.dialogueNodeContentList.length > 0"
      >
        <div v-for="(i, j) in node.dialogueNodeContentList" :key="j">
          {{ i.content }}
        </div>
      </div>
      <div style="height: 20px; color: #409eff; font-size: 12px" v-else>
        请编辑节点内容
      </div>
    </div>
    <div v-if="node.type === 'HANGUP'">
      <div>
        <div
          class="ef-node-text-content"
          v-if="node.dialogueNodeContentList.length > 0"
        >
          <div v-for="(i, j) in node.dialogueNodeContentList" :key="j">
            {{ i.content }}
          </div>
        </div>
        <span v-else>-</span>
      </div>
      <div>
        <img
          src="@/assets/img/tiaoz.png"
          alt=""
          style="width: 14px; vertical-align: -3%"
        />
        <span style="font-size: 12px">挂机</span>
      </div>
    </div>
    <div v-if="node.type === 'ADD_WECHAT'">
      <div>
        <div
          class="ef-node-text-content"
          v-if="node.dialogueNodeContentList.length > 0"
        >
          <div v-for="(i, j) in node.dialogueNodeContentList" :key="j">
            {{ i.content }}
          </div>
        </div>
        <span v-else>-</span>
      </div>
      <div>
        <img
          src="@/assets/img/tiaoz.png"
          alt=""
          style="width: 14px; vertical-align: -3%"
        />
        <span style="font-size: 12px">加微信</span>
      </div>
    </div>
    <!-- 分支节点 -->
    <div v-if="node.type === 'NORMAL'">
      <div v-if="node.dialogueNodeBranchList.length < 1">
        <div class="flow-node-drag-btn-p">用户回复分支</div>
      </div>
      <div v-else>
        <div
          v-for="item in node.dialogueNodeBranchList"
          :key="item.id"
          class="flow-node-drag-btn"
        >
          <div :id="item.id" class="flow-node-drag">
            <span :class="nodeIcoClass">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 操作 -->
    <div class="node-flow-chart-item-cz">
      <i
        class="el-icon-edit icon-cc-mm"
        style="margin-right: 5px; color: #bfbfbf"
        @click="modifyNode($event, node)"
      >
      </i>
      <i
        class="el-icon-delete icon-cc-mm"
        style="margin-right: 5px; color: #bfbfbf"
        @click="deleteNode($event, node)"
        v-if="!node.starter"
      >
      </i>
    </div>
    <!-- 流程起点 -->
    <div class="ef-node-container-qi-dian" v-if="node.starter">
      <div class="ef-node-container-qi-dian-i"></div>
      <div class="ef-node-container-qi-dian-content">流程起点</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    node: Object,
    activeElement: Object,
    flowNodeList: Array,
  },
  data() {
    return {};
  },
  computed: {
    nodeContainerClass() {
      return {
        "ef-node-container": this.node.type == "NORMAL" ? true : false,
        "ef-node-container-small": this.node.type !== "NORMAL" ? true : false,
        "ef-node-active":
          this.activeElement.type == "node"
            ? this.activeElement.nodeId === this.node.id
            : false,
      };
    },
    // 节点容器样式
    nodeContainerStyle() {
      return {
        top: this.node.top,
        left: this.node.left,
      };
    },
    nodeIcoClass() {
      var nodeIcoClass = {};
      // 添加该class可以推拽连线出来，viewOnly 可以控制节点是否运行编辑
      nodeIcoClass["flow-node-drag"] = this.node.viewOnly ? false : true;
      return nodeIcoClass;
    },
  },
  methods: {
    // 点击节点
    clickNode(e) {
      // console.log(this.node)
      e.preventDefault();
      e.stopPropagation();
      this.$emit("clickNode", this.node);
    },
    // 鼠标移动后抬起
    changeNodeSite() {
      // 避免抖动
      if (
        this.node.left == this.$refs.node.style.left &&
        this.node.top == this.$refs.node.style.top
      ) {
        return;
      }
      this.$emit("changeNodeSite", {
        nodeId: this.node.id,
        left: this.$refs.node.style.left,
        top: this.$refs.node.style.top,
      });
    },
    // 鼠标右击
    nodeRightMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("nodeRightMenu", this.node, e);
    },
    // 鼠标双击
    onDblClick(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("onDblClick", this.node, e);
    },
    // 点击编辑节点
    modifyNode(e, node) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("modifyNode", node);
    },
    // 点击删除节点
    deleteNode(e, node) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("deleteNode", node);
    },
  },
};
</script>
