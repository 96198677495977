<template>
  <div class="recording-down">
    <div class="projectStatus">
      用户名称：
      <!-- <el-input v-model="condition.loginName" placeholder="请输入用户名称" clearable></el-input> -->
      <el-select v-model="condition.loginName" filterable remote :loading="loadingLoginName" :remote-method="remoteMethodLoginName" @focus="NameRemoteFocus" clearable placeholder="可以试试搜索">
        <el-option v-for="item in loginNameList" :key="item.id" :label="item.label" :value="item.loginName"></el-option>
      </el-select>
    </div>
    <el-button type="primary" @click="inquire()">查询</el-button>
    <!-- 录音列表 -->
    <el-table :data="tableData" stripe style="width: 100%" border v-loading="loadingList">
       <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
       <el-table-column prop="userName" label="用户名称" min-width="200" align="left" show-overflow-tooltip></el-table-column>
       <el-table-column prop="name" label="文件名称" min-width="200" align="left" show-overflow-tooltip></el-table-column>
       <el-table-column prop="statusLabel" label="状态" width="100" align="left"></el-table-column>
       <el-table-column prop="gmtCreated" label="创建时间" width="190" align="center"></el-table-column>
       <el-table-column prop label="操作" width="70" align="center" fixed="right">
         <template slot-scope="scope">
            <el-button type="text" size="small" @click="downRecord(scope.row,scope.$index)" :disabled="scope.row.downDis">下载</el-button>
         </template>
       </el-table-column>
    </el-table>
     <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData:[],
      currentPage: null,
      total:null,
      pageSize: 10,
      condition:{
        loginName:''
      },
      loginNameList:[],
      loadingLoginName:false,
      taskServer: '',
      loadingList: false
    }
  },
  mounted(){
    document.title = "惠销平台管理系统-文件下载";
    this.inquire();
    this.inquireLoginName({"pageNo":1,"pageSize":20,"enabled": true});
  },
  methods:{
    // 查询
    inquire(){
      let params = {
        pageNo:1,
        pageSize:this.pageSize,
        loginName:this.condition.loginName
      }
      this.recordInquire(params)
    },
    // 查询录音列表
    recordInquire(params){
      this.loadingList = true;
      this.$http('/sip/downloadFile/list',null,'get',null,params).then(res => {
        setTimeout( () => {
          this.loadingList = false
        }, 200)
        if(res.result == 200){
          let list = res.data.records
          this.total = res.data.total;
          list.forEach(item => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated)
            item.downDis = true
            switch (item.status) {
              case 'DOWNLOADABLE':
                item.statusLabel = '可下载'
                item.downDis = false
                break;
              case 'EXCEPTION':
                item.statusLabel = '异常'
                break;
              case 'WAITING':
                item.statusLabel = '等待打包'
                break;
              case 'PREPARING':
                item.statusLabel = '正在打包'
                break;
              case 'EXPIRED':
                item.statusLabel = '已过期'
                break;
            }
          });
          this.tableData = list
        }
      })
    },
    // 用户名称获取焦点 
    NameRemoteFocus() {
      this.inquireLoginName({"pageNo":1,"pageSize":20,"enabled": true});
    },
    // 搜索用户名称
    remoteMethodLoginName(userName){
      this.loadingLoginName = true;
      setTimeout(() => {
        this.loadingLoginName = false;
        let params = {
            pageSize:20,
            pageNo:1,
            userName:userName,
            enabled: true
          }
          this.inquireLoginName(params)
      }, 200);
    },
    // 查询用户名称
    inquireLoginName(params){
      this.loginNameList = [];
      this.$http('/user/list',null,'get',null,params).then(res=>{
        if(res.result == 200){
          let newuserNameList = res.data.list;
          newuserNameList.map(item=>{
            item.label = item.userName;
            this.loginNameList.push(item);
          })
        }
      })
    },
    // 分页显示条数
    sizeChange(index){
      this.pageSize = index;
      this.currentPage = 1;
      let params = {
        pageSize: this.pageSize,
        pageNo: 1,
        loginName:this.condition.loginName
      };
      this.recordInquire(params);
    },
    // 当前页
    currentPage_data(index){
      let params = {
        pageSize: this.pageSize,
        pageNo: index,
        loginName:this.condition.loginName
      };
      this.recordInquire(params);
    },
    // 点击下载录音
    downRecord(val,index){
      let params = {
        id: val.id
      }
      this.tableData[index].downDis = true;
      this.$set(this.tableData[index],'downDis',this.tableData[index].downDis);
      this.taskServer = val.server;
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$http('/sip/downloadFile/getRemoteDownloadUrl',null,'get',path,params).then(res => {
        this.tableData[index].downDis = false;
        this.$set(this.tableData[index],'downDis',this.tableData[index].downDis);
        if(res.result == 200){
          let href = res.data;
          window.location.href = href;
        }
      })
    }
  }
}
</script>

<style scoped>
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.projectStatus {
  margin-bottom: 20px;
}
.projectStatus .el-input {
  margin-left: 20px;
  width: 260px;
}
</style>

