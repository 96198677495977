<template>
  <div class="singleDeduction">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="扣除明细" name="details">
        <div class="nav">
          <div class="callTime">日期：
            <el-date-picker
              v-model="condition.callEndAtStart"
              value-format="yyyy-MM-dd"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              align="right"
            ></el-date-picker>
          </div>
          <div class="companyName">用户名称：
            <el-select
              v-model="condition.userLoginName"
              placeholder="请选择用户名称"
              filterable
              remote
              :loading="loadingLoginName"
              :remote-method="remoteMethodLoginName"
              clearable
              @change="userNameChange"
              @focus="userNameFocus"
            >
              <el-option
                v-for="item in loginNameList"
                :key="item.id"
                :value="item.loginName"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="batchName">坐席编号：
            <el-input v-model="condition.clientNumber" placeholder="请输入坐席编号" clearable></el-input>
          </div>
          <div class="taskName">项目名称：
            <el-select
              v-model="condition.projectName"
              placeholder="请选择项目名称"
              filterable
              remote
              :loading="loadingLoginName"
              :remote-method="remoteMethodProjectName"
              clearable
              @change="projectNameChange"
              @focus="projectNameFocus"
            >
              <el-option
                v-for="item in projectNameList"
                :key="item.id"
                :value="item.id"
                :label="item.projectName"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquireSingle(1)" :disabled="inquireSingleDisabled">查询</el-button>
            <el-button type="success" @click="exportExpenses" :disabled="exportExpensesDisabled">导出</el-button>
            <el-button type="primary" @click="warrantyModification(null, 'batch')" :disabled="isBatchModifly">批量保底修改</el-button>
            <el-button type="primary" @click="deduction" :disabled="isDeduction">扣除</el-button>
          </div>
        </div>
        <!-- 成单扣除表 -->
        <el-table
          :data="singleTableData"
          stripe
          style="width: 100%"
          border=""
          class="tableDataClass"
          v-loading="loadingList"
          :summary-method="getSummaries"
          @selection-change="handleSelectionChange"
          show-summary
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column
            prop="userName"
            label="用户名称"
            min-width="170"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="projectName"
            label="项目名称"
            min-width="170"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="clientNumber" label="坐席编号" width="110" align="center"></el-table-column>
          <el-table-column
            prop="successCountAtMorningLabel"
            label="上午成单(实际/保底)"
            width="170"
            align="center"
          >
            <template slot-scope="scope">
              <span
                :style="{color: (scope.row.miniSuccessCountAtMorning === 0 ? '#606266' : 
                (scope.row.successCountAtMorning >= Number(scope.row.miniSuccessCountAtMorningStr) ? '#67c23a' : '#bb5555'))}"
              >{{scope.row.successCountAtMorningLabel}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="successCountAtAfternoonLabel"
            label="下午成单(实际/保底)"
            width="170"
            align="center"
          >
            <template slot-scope="scope">
              <span
                :style="{color: (scope.row.miniSuccessCountAtAfternoon === 0 ? '#606266' : 
                (scope.row.successCountAtAfternoon >= Number(scope.row.miniSuccessCountAtAfternoonStr) ? '#67c23a' : '#bb5555'))}"
              >{{scope.row.successCountAtAfternoonLabel}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="totalSuccessCountLabel" label="全天成单(实际/保底)" width="170" align="center">
            <template slot-scope="scope">
              <span
                :style="{color: (scope.row.totalMiniSuccessCount === 0 ? '#606266' : 
              (scope.row.totalSuccessCount >= Number(scope.row.totalMiniSuccessCountStr) ? '#67c23a' : '#bb5555'))}"
              >{{scope.row.totalSuccessCountLabel}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="workUnitLabel" label="人/天" width="70" align="center"></el-table-column>
          <el-table-column prop="unitPriceStr" label="单价(元)" width="170" align="center"></el-table-column>
          <el-table-column prop="deductionStr" label="总计扣除(元)" width="170" align="center"></el-table-column>
          <el-table-column prop="date" label="日期" width="130" align="center"></el-table-column>
          <el-table-column label="操作" width="90" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="warrantyModification(scope.row,'single')">保底修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSingleChange"
          @current-change="currentPage_single"
          :current-page.sync="currentPageSingle"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="totalSingle"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="扣除汇总" name="summary">
        <div class="nav">
          <div class="callTime">日期：
            <el-date-picker
              v-model="conditionSummary.callEndAtStart"
              value-format="yyyy-MM-dd"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              align="right"
            ></el-date-picker>
          </div>
          <div class="companyName">用户名称：
            <el-select
              v-model="conditionSummary.userLoginName"
              placeholder="请选择用户名称"
              filterable
              remote
              :loading="loadingLoginName"
              :remote-method="remoteMethodLoginName"
              clearable
              @change="summaryUserLoginNameChange"
              @focus="userNameFocus"
            >
              <el-option
                v-for="item in loginNameList"
                :key="item.id"
                :value="item.loginName"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="taskName">项目名称：
            <el-select
              v-model="conditionSummary.projectName"
              placeholder="请选择项目名称"
              filterable
              remote
              :loading="loadingLoginName"
              :remote-method="remoteMethodProjectNameSummary"
              clearable
              @change="summaryProjectNameChange"
              @focus="projectNameFocusSummary"
            >
              <el-option
                v-for="item in projectNameList"
                :key="item.id"
                :value="item.id"
                :label="item.projectName"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquireSummary(1)">查询</el-button>
          </div>
        </div>
        <!-- 日汇总表 -->
        <el-table
          :data="summaryTableData"
          stripe
          style="width: 100%"
          border=""
          class="tableDataClass"
          v-loading="loadingList"
        >
          <el-table-column type="index" label="编号" width="60" align="center"></el-table-column>
          <el-table-column prop="userLoginName" label="用户名称" min-width="170" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="170" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="deductionCountStr" label="扣除条数" width="100" align="center"></el-table-column>
          <el-table-column prop="deductionStr" label="总计扣除(元)" width="150" align="center"></el-table-column>
          <!-- <el-table-column prop="date" label="日期" width="130" align="center"></el-table-column> -->
          <el-table-column prop="remark" label="备注" min-width="200" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="operator" label="操作者" width="130" align="center"></el-table-column>
          <el-table-column prop="gmtCreated" label="创建时间" width="200" align="center"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSummaryChange"
          @current-change="currentPage_summary"
          :current-page.sync="currentPageSummary"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="totalSummary"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- 保底修改 -->
    <el-dialog
      :visible.sync="warrantyShow"
      @close="warrantyShowCloseDialog"
      width="400px"
      class="warranty_class"
    >
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">保底修改</span>
      </template>
      <el-form :model="warrantyForm" label-position="left" label-width="115px">
        <el-form-item label="上午成单保底：">
          <el-input style="width: 100%" v-model="warrantyForm.miniSuccessCountAtMorning" placeholder="请输入上午成单数"></el-input>
        </el-form-item>
        <el-form-item label="下午成单保底：">
          <el-input style="width: 100%" v-model="warrantyForm.miniSuccessCountAtAfternoon" placeholder="请输入下午成单数"></el-input>
        </el-form-item>
        <el-form-item label="单价(元)：">
          <el-input style="width: 100%" v-model="warrantyForm.unitPrice" @input="unitPriceInput" placeholder="请输入单价"></el-input>
        </el-form-item>
        <div class="textPrompt" v-if="modifyType === 'batch'">*操作将更新 {{idListLength}} 条数据</div>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="warranty_submit" :disabled="isWarrantyDis">确定</el-button>
        <el-button @click="warrantyShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 扣除 -->
    <el-dialog
      :visible.sync="deductionShow"
      @close="deductionCloseDialog"
      width="400px"
      class="warranty_class"
    >
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">扣除</span>
      </template>
      <el-form label-position="left" label-width="88px">
        <el-form-item label="用户：">
          <span style="font-weight: bold;">{{userLoginNameLabel}}</span>
        </el-form-item>
        <el-form-item label="项目名称：">
          <span style="font-weight: bold;">{{projectNameLabel}}</span>
        </el-form-item>
        <el-form-item label="日期：">
          <span style="font-weight: bold;">{{condition.callEndAtStart[0]}}</span>
        </el-form-item>
        <el-form-item label="金额(元)：">
          <el-input style="width: 100%" v-model="deductionForm.money" @input="moneyInput" placeholder="请输入金额"></el-input>
        </el-form-item>
        <el-form-item label="数量：">
          <el-input style="width: 100%" v-model="deductionForm.count" @input="countInput" placeholder="请输入数量"></el-input>
        </el-form-item>
        <el-form-item label="单价：">
          <el-input style="width: 100%" v-model="unitPriceValue" placeholder="单价" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input style="width: 100%" v-model="deductionForm.remark" type="textarea" :rows="4" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="deduction_submit" :disabled="isDeductionDis">确定</el-button>
        <el-button @click="deductionShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "singleDeduction",
  data() {
    return {
      summaryPageNo: 1,
      summaryPageSize: 10,
      currentPageSummary: 1,
      totalSummary: null,
      summaryTableData: [],
      conditionSummary: {
        callEndAtStart: [],
        userLoginName: "",
        projectName: ""
      },
      activeName: "details",
      userLoginNameLabel: "",
      projectNameLabel: "",
      deductionShow: false,
      isDeductionDis: false,
      deductionForm: {
        count: "",
        money: "",
        remark: ""
      },
      modifyType: "batch",
      idListLength: 0,
      idList: [],
      isBatchModifly: true,
      isExportExpenses: true,
      isWarrantyDis: false,
      warrantyForm: {
        id: "",
        miniSuccessCountAtMorning: "",
        miniSuccessCountAtAfternoon: "",
        unitPrice: "",
      },
      warrantyShow: false,
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        onPick({ maxDate, minDate }) {
          if (minDate) {
            sessionStorage.setItem("theTime", minDate.getTime());
          }
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "本周记录",
            onClick(picker) {
              const end = new Date();
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              picker.$emit("pick", [new Date(start), end]);
            }
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "此刻",
            onClick(picker) {
              const end = new Date();
              let start = sessionStorage.getItem("theTime");
              if (start) {
                start = parseInt(start);
                picker.$emit("pick", [new Date(start), end]);
                sessionStorage.removeItem("theTime");
              }
            }
          }
        ]
      },
      defaultTime: ["00:00:00", "23:59:59"],
      condition: {
        callEndAtStart: [],
        userLoginName: "",
        clientNumber: "",
        projectName: ""
      },
      loadingLoginName: false,
      loginNameList: [],
      loadingList: false,
      currentPageSingle: 1,
      totalSingle: null,
      singleTableData: [],
      pageNo: 1,
      pageSize: 10,
      listTotal: [],
      projectNameList: []
    };
  },
  computed: {
    isDeduction() {
      // 选择用户、选择项目、无坐席编号并且在一天内可以点击扣除
      if (this.condition.userLoginName && this.condition.projectName && !this.condition.clientNumber && this.condition.callEndAtStart) {
        let start = new Date(this.condition.callEndAtStart[0]);
        let end = new Date(this.condition.callEndAtStart[1]);
        let diff = start - end;
        if (diff === 0) {
          return false
        }
      }
      return true
    },
    unitPriceValue() {
      // 扣除单价 = 金额 / 数量
      let money = Number(this.deductionForm.money);
      let count = Number(this.deductionForm.count);
      if (money && count) {
        let price = (money / count).toFixed(2);
        return price
      }
      return 
    },
    inquireSingleDisabled() {
      // 扣除明细查询按钮禁用
      if (this.condition.userLoginName && this.condition.projectName) {
        return false
      }
      return true
    },
    exportExpensesDisabled() {
      // 扣除明细导出按钮禁用
      if (this.condition.userLoginName && this.condition.projectName && !this.isExportExpenses) {
        return false
      }
      return true
    }
  },
  mounted() {
    document.title = "惠销平台管理系统-成单扣除";
    this.theWeek();
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf()).substr(0, 10);
    end = this.$common.transformTime(end.valueOf()).substr(0, 10);
    this.condition.callEndAtStart = [start, end];
    this.conditionSummary.callEndAtStart = [start, end];
    // this.inquireSingle(1);
  },
  methods: {
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
    // 用户名称选择---日汇总
    summaryUserLoginNameChange(val) {
      this.conditionSummary.projectName = "";
    },
    // 项目名称选择---明细页面
    projectNameChange(val) {
      this.projectNameLabel = "";
      this.loginNameList = [];
      this.projectNameList.forEach(item => {
        if (val === item.id) {
          this.projectNameLabel = item.projectName;
          this.loginNameList.push({
            id: 1,
            loginName: item.userLoginName,
            label: item.userName
          })
          this.$nextTick(() => {
            this.condition.userLoginName = item.userLoginName;
            this.userLoginNameLabel = item.userName;
          })
        }
      })
    },
    // 项目名称选择---汇总页面
    summaryProjectNameChange(val) {
      this.loginNameList = [];
      this.projectNameList.forEach(item => {
        if (val === item.id) {
          this.loginNameList.push({
            id: 1,
            loginName: item.userLoginName,
            label: item.userName
          })
          this.$nextTick(() =>{
            this.conditionSummary.userLoginName = item.userLoginName;
          })
        }
      })
    },
    // 查询项目名称
    getProjectName(data) {
      this.projectNameList = [];
      this.$http("/sip/project/list", null, "get", null, data).then(res => {
        if (res.result === 200) {
          let list = res.data.list;
          this.projectNameList = list;
        }
      })
    },
    // 项目名称搜索---日汇总
    remoteMethodProjectNameSummary(projectName) {
      let data = {
        pageNo: 1,
        pageSize: 20,
        loginName: this.conditionSummary.userLoginName,
        projectName: projectName
      }
      this.getProjectName(data);
    },
    // 项目名称获取焦点---日汇总
    projectNameFocusSummary() {
      let data = {
        pageNo: 1,
        pageSize: 20,
        loginName: this.conditionSummary.userLoginName,
      }
      this.getProjectName(data);
    },
    // 项目名称搜索
    remoteMethodProjectName(projectName) {
      let data = {
        pageNo: 1,
        pageSize: 20,
        loginName: this.condition.userLoginName,
        projectName: projectName
      }
      this.getProjectName(data);
    },
    // 项目名称获取焦点
    projectNameFocus() {
      let data = {
        pageNo: 1,
        pageSize: 20,
        loginName: this.condition.userLoginName,
      }
      this.getProjectName(data);
    },
    // 切换tab标签页
    handleClick(tab, event){
      this.isExportExpenses = true;
      if (this.activeName === "details") {
        // this.inquireSingle();
      } else if (this.activeName === "summary") {
        this.inquireSummary();
      }
    },
    // 查询日汇总
    inquireSummary(pageNo, pageSize) {
      let data = {
        pageNo: pageNo || this.summaryPageNo,
        pageSize: pageSize || this.summaryPageSize,
        userLoginName: this.conditionSummary.userLoginName,
        projectId: this.conditionSummary.projectName,
      };
      if (
        this.conditionSummary.callEndAtStart &&
        this.conditionSummary.callEndAtStart.length > 0
      ) {
        data.gmtCreatedStart = this.conditionSummary.callEndAtStart[0] + " 00:00:00";
        data.gmtCreatedEnd = this.conditionSummary.callEndAtStart[1] + " 23:59:59";
      }
      this.getDeductionSummaryList(data);
    },
    // 查询日汇总
    getDeductionSummaryList(data) {
      this.loadingList = true;
      this.$http("/orderDeduction/orderDeductionSummaryList",null,"get",null,data).then(res => {
        this.loadingList = false;
        if (res.result === 200) {
          let list = res.data.records;
          this.totalSummary = res.data.total;
          list.forEach(item => {
            item.deductionStr = item.deduction / 1000;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          })
          this.summaryTableData = list;
        }
      }).catch(err => {
        this.loadingList = false;
      })
    },
    // 日汇总表分页
    currentPage_summary(index) {
      this.summaryPageNo = index;
      this.inquireSummary();
    },
    // 日汇总表显示条数--分页
    handleSummaryChange(index) {
      this.summaryPageNo = 1;
      this.summaryPageSize = index;
      this.inquireSummary();
    },
    unitPriceInput(){//限制input输入
      let inputVal = this.warrantyForm.unitPrice;
      inputVal = this.$common.numberCheck(inputVal);
      this.warrantyForm.unitPrice = inputVal;
    },
    countInput(){//限制扣除数量input输入
      let inputVal = this.deductionForm.count;
      // inputVal = inputVal.replace(/[^\d]/g,'');
      inputVal = this.$common.numberCheck(inputVal);
      this.deductionForm.count = inputVal;
    },
    moneyInput(){//限制扣除金额input输入
      let inputVal = this.deductionForm.money;
      inputVal = this.$common.numberCheck(inputVal);
      this.deductionForm.money = inputVal;
    },
    // 点击扣除
    deduction() {
      this.deductionShow = true;
      let data = {
        userLoginName: this.condition.userLoginName,
        projectId: this.condition.projectName,
        gmtCreatedStart: this.condition.callEndAtStart[0] + " 00:00:00",
        gmtCreatedEnd: this.condition.callEndAtStart[1] + " 23:59:59"
      }
      this.getDeduction(data);
    },
    // 确定扣除
    deduction_submit() {
      let data = {
        userLoginName: this.condition.userLoginName,
        projectId: this.condition.projectName,
        deductionCount: Number(this.deductionForm.count)*10,
        deduction: (this.deductionForm.money*1000).toFixed(0),
        remark: this.deductionForm.remark
      }
      this.$service("/orderDeduction/updateDeductionSummary",data).then(res => {
        if (res.result === 200) {
          this.$message.success("扣除成功");
          this.deductionShow = false;
        }
      })
    },
    // 关闭扣除
    deductionCloseDialog() {
      this.deductionForm = {
        count: "",
        money: "",
        remark: ""
      }
    },
    // 查询扣除信息
    getDeduction(data) {
      this.$http("/orderDeduction/deductionInfo",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let deductionForm = res.data;
          let count = Number(deductionForm.totalDeductionCount)
          if(count%10 != 0) {
            count = (count / 10).toFixed(1);
          } else {
            count = (count / 10);
          }
          this.deductionForm = {
            count: count,
            money: Number(deductionForm.totalDeduction)/1000,
            remark: deductionForm.remark
          }
        }
      })
    },
    // 用户名称搜索选择改变
    userNameChange(val) {
      this.condition.projectName = "";
      this.userLoginNameLabel = "";
      this.loginNameList.forEach(item => {
        if (val === item.loginName) {
          this.userLoginNameLabel = item.label;
        }
      })
    },
    // 选择用户名称
    handleSelectionChange(val) {
      this.idList = [];
      val.forEach(item => {
        this.idList.push(item.id)
      })
      this.isBatchModifly = true;
      if (val.length > 0) {
        this.isBatchModifly = false;
      }
      this.idListLength = val.length;
    },
    // 点击保底修改
    warrantyModification(val, type) {
      this.warrantyShow = true;
      if (type === "single") {
        this.modifyType = "single";
        this.warrantyForm = {
          id: val.id,
          miniSuccessCountAtMorning: val.miniSuccessCountAtMorning,
          miniSuccessCountAtAfternoon: val.miniSuccessCountAtAfternoon,
          unitPrice: val.unitPriceStr,
        }
      }
      if (type === "batch") {
        this.modifyType = "batch";
      }
    },
    // 确定保底修改
    warranty_submit() {
      this.isWarrantyDis = true;
      let data = {};
      if (this.modifyType === "single") {
        let idList = [];
        idList.push(this.warrantyForm.id);
        data = {
          idList: idList,
          miniSuccessCountAtMorning: Number(this.warrantyForm.miniSuccessCountAtMorning),
          miniSuccessCountAtAfternoon: Number(this.warrantyForm.miniSuccessCountAtAfternoon),
          unitPrice: (Number(this.warrantyForm.unitPrice) * 1000).toFixed(0),
        };
      }
      if (this.modifyType === "batch") {
        let miniSuccessCountAtMorning,
            miniSuccessCountAtAfternoon,
            unitPrice;
        if (this.warrantyForm.miniSuccessCountAtMorning) {
          miniSuccessCountAtMorning = Number(this.warrantyForm.miniSuccessCountAtMorning)
        }
        if (this.warrantyForm.miniSuccessCountAtAfternoon) {
          miniSuccessCountAtAfternoon = Number(this.warrantyForm.miniSuccessCountAtAfternoon)
        }
        if (this.warrantyForm.unitPrice) {
          unitPrice = (Number(this.warrantyForm.unitPrice) * 1000).toFixed(0)
        }
        data = {
          idList: this.idList,
          miniSuccessCountAtMorning: miniSuccessCountAtMorning,
          miniSuccessCountAtAfternoon: miniSuccessCountAtAfternoon,
          unitPrice: unitPrice,
        };
      }
      this.$service("/orderDeduction/updateDeductionInfo",data).then(res => {
        this.isWarrantyDis = false;
        if (res.result === 200) {
          this.$message({message: "修改成功", type: "success"});
          this.warrantyShow = false;
          this.inquireSingle();
        }
      })
    },
    // 关闭保底修改
    warrantyShowCloseDialog() {
      this.warrantyForm = {
        id: "",
        miniSuccessCountAtMorning: "",
        miniSuccessCountAtAfternoon: "",
        unitPrice: "",
      }
      this.isWarrantyDis = false;
    },
    // 查询日明细
    inquireSingle(pageNo, pageSize) {
      let data = {
        pageNo: pageNo || this.pageNo,
        pageSize: pageSize || this.pageSize,
        clientNumber: this.condition.clientNumber,
        userLoginName: this.condition.userLoginName,
        projectId: this.condition.projectName
      };
      if (
        this.condition.callEndAtStart &&
        this.condition.callEndAtStart.length > 0
      ) {
        data.gmtCreatedStart = this.condition.callEndAtStart[0] + " 00:00:00";
        data.gmtCreatedEnd = this.condition.callEndAtStart[1] + " 23:59:59";
      }
      this.getOrderDeduction(data);
      this.deductionSummary(data);
    },
    // 查询成单扣除表
    getOrderDeduction(data) {
      this.loadingList = true;
      this.isExportExpenses = true;
      this.$http("/orderDeduction/list", null, "get", null, data).then(res => {
        setTimeout(() => {
          this.loadingList = false;
        }, 200);
        if (res.result === 200) {
          this.totalSingle = res.data.total;
          let list = res.data.records;
          list.forEach(item => {
            item.workUnitLabel = (item.workUnit / 10).toFixed(1);
            if (item.enableAverageDeduction) {
              item.deductionStr = "-";
            }
            if (item.successCountAtMorning === 0 && item.miniSuccessCountAtMorning === 0) {
              item.successCountAtMorningLabel = "-"
            } else {
              item.successCountAtMorningLabel =
                item.successCountAtMorning + "/" + item.miniSuccessCountAtMorningStr;
            }
            if (item.successCountAtAfternoon === 0 && item.miniSuccessCountAtAfternoon === 0) {
              item.successCountAtAfternoonLabel = "-"
            } else {
              item.successCountAtAfternoonLabel =
                item.successCountAtAfternoon + "/" + item.miniSuccessCountAtAfternoonStr;
            }
            if (item.totalSuccessCount === 0 && item.totalMiniSuccessCount === 0) {
              item.totalSuccessCountLabel = "-"
            } else {
              item.totalSuccessCountLabel =
              item.totalSuccessCount + "/" + item.totalMiniSuccessCountStr;
            }
          });
          if (list.length > 0) {
            this.isExportExpenses = false;
          }
          this.singleTableData = list;
        }
      });
    },
    // 分页
    currentPage_single(index) {
      this.pageNo = index;
      this.inquireSingle(index);
    },
    // 显示条数--分页
    handleSingleChange(index) {
      this.pageNo = 1;
      this.pageSize = index;
      this.inquireSingle(1, index);
    },
    // 查询合计
    deductionSummary(data) {
      this.$http(
        "/orderDeduction/deductionSummary",
        null,
        "get",
        null,
        data
      ).then(res => {
        if (res.result === 200) {
          let list = res.data;
          // list.deductionStr = (list.deduction / 1000).toFixed(2);
          list.offsetStr = (list.offset / 1000).toFixed(2);
          list.workUnitLabel = (list.totalWorkUnit / 10).toFixed(1);
          list.totalSuccessCountLabel =
            list.totalSuccessCount + "/" + list.totalMiniSuccessCount;
          if (list.totalSuccessCount === 0 && list.totalMiniSuccessCount === 0) {
            list.totalSuccessCountLabel = "-";
          }

          list.successCountAtMorningLabel =
            list.totalSuccessCountAtMorning + "/" + list.totalMiniSuccessCountAtMorning;
          if (list.totalSuccessCountAtMorning === 0 && list.totalMiniSuccessCountAtMorning === 0) {
            list.successCountAtMorningLabel = "-";
          }

          list.successCountAtAfternoonLabel =
            list.totalSuccessCountAtAfternoon + "/" + list.totalMiniSuccessCountAtAfternoon;
          if (list.totalSuccessCountAtAfternoon === 0 && list.totalMiniSuccessCountAtAfternoon === 0) {
            list.successCountAtAfternoonLabel = "-";
          }
          this.listTotal = list;
        }
      });
    },
    // 合计行
    getSummaries(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        for (let i in this.listTotal) {
          if (i == column.property) {
            arr[index] = this.listTotal[i];
          }
        }
      });
      return arr;
    },
    // 用户名称选择框获取焦点
    userNameFocus() {
      this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true});
    },
    //搜索用户名称
    remoteMethodLoginName(userName) {
      this.loadingLoginName = true;
      setTimeout(() => {
        this.loadingLoginName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true
        };
        this.inquireLoginName(params);
      }, 200);
    },
    // 查询用户名称
    inquireLoginName(params) {
      this.loginNameList = [];
      this.$http("/user/list", null, "get", null, params).then(res => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map(item => {
            item.label = item.userName;
            this.loginNameList.push(item);
          });
        }
      });
    },
    // 点击导出 
    exportExpenses() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        clientNumber: this.condition.clientNumber,
        userLoginName: this.condition.userLoginName,
        projectId: this.condition.projectName,
      }
      if (
        this.condition.callEndAtStart &&
        this.condition.callEndAtStart.length > 0
      ) {
        params.gmtCreatedStart = this.condition.callEndAtStart[0] + " 00:00:00";
        params.gmtCreatedEnd = this.condition.callEndAtStart[1] + " 23:59:59";
      }
      let url = "";
      for(let i in params){
        if(params[i]){
          url+=(i+'='+params[i]+'&')
        }
      }
      let href = this.$constants.baseURL+"/orderDeduction/export?"+url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then(data=>{
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      })
    }
  }
};
</script>
<style scoped>
.singleDeduction >>> .el-tabs__content {
  overflow: auto;
}
.textPrompt{
  font-size: 12px;
  color: #409eff;
  /* display: inline-block; */
  margin-bottom: 10px;
}
.el-table {
  margin-bottom: 20px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav {
  width: 1200px;
}
.nav .el-input,
.nav .el-select {
  width: 300px;
}
.companyName,
.callTime,
.taskName,
.batchName {
  width: 700px;
  margin-bottom: 20px;
}
.callTime >>> .el-input__inner {
  width: 430px;
}
.callTime,
.batchName {
  margin-right: 6px;
}
.callTime,
.batchName {
  float: right;
  text-align: left;
}
/* .batchName .el-input {
  width: 230px;
} */
.operate {
  margin-bottom: 20px;
}
.checkbox >>> .el-checkbox__label {
  font-size: 16px;
  color: #333;
}
</style>


