<template>
  <div class="latentGuest">
    <div  class="nav_menu nav">
      <div class="callTime">创建日期：
        <el-date-picker
          class="startTime"
          v-model="condition.callEndAtStart"
          type="datetime"
          placeholder="起始时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="callPickerOptions"
        ></el-date-picker> -
        <el-date-picker
          class="endTime"
          v-model="condition.callEndAtEnd"
          type="datetime"
          placeholder="终止时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="callPickerOptions"
        ></el-date-picker>
      </div>
      <div class="companyName">用户名称：
        <el-select v-model="condition.userId" placeholder="请选择公司名称" filterable remote @change="userIdChange" :remote-method="uerNameRemoteMethod" @focus="NameRemoteFocus" clearable>
          <el-option
            v-for="item in userNameList"
            :key="item.id"
            :value="item.loginName"
            :label="item.userName"
          ></el-option>
        </el-select>
      </div>
      <div class="batchName" v-if="roleMenu != 'SUPER_MANAGER'">状态：<i style="display: inline-block;margin-right:31px;"></i>
        <el-select v-model="condition.status" placeholder="请选择状态">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="batchName" v-if="roleMenu == 'SUPER_MANAGER'">状态：<i style="display: inline-block;margin-right:31px;"></i>
        <el-select v-model="condition.status" placeholder="请选择状态">
          <el-option
            v-for="item in statusList2"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="taskName">项目名称：
        <el-select v-model="condition.projectName" placeholder="可以试试搜索" filterable remote :loading="loadingName" :remote-method="nameRemoteMethod" @focus="projectIdRemoteFocus" clearable>
          <el-option
          v-for="item in projectNameList"
          :key="item.id"
          :value="item.projectName"
          :label="item.projectName"
          ></el-option>
        </el-select>
        <el-checkbox v-model="includeChildren">包含子账号</el-checkbox>
      </div>
      <div class="operate">
        <el-button type="primary" @click="inquire()">查询</el-button>
        <el-button type="primary" @click="addGuest" v-if="roleMenu!='SUPER_MANAGER'">新增</el-button>
      </div>
    </div>
    <!-- 需求表 -->
    <el-table :data="tableData" stripe style="width: 100%" border v-loading="loadingTab">
      <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
      <el-table-column prop="submitUserName" label="用户" min-width="120" align="left" v-if="roleMenu=='SUPER_MANAGER'||parentLoginName == loginName" show-overflow-tooltip></el-table-column>
      <el-table-column prop="projectName" label="项目名称" width="140" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="labelShow" label="标签" width="140" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="city" label="城市" min-width="150" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="finishDateLabel" label="需求日期" width="170" align="left"></el-table-column>
      <el-table-column prop="number" label="条数" width="70" align="left"></el-table-column>
      <el-table-column prop="requirement" label="要求" width="150" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="note" label="备注" width="150" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="statusLabel" label="状态" width="70" align="left"></el-table-column>
      <el-table-column prop="gmtCreated" label="创建日期" width="170" align="center"></el-table-column>
      <el-table-column prop label="操作" :width="roleMenu=='SUPER_MANAGER'?250:285" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="checkGuest(scope.row)">查看</el-button>
          <el-button type="text" size="small" @click="recreateGuest(scope.row)" v-if="roleMenu!='SUPER_MANAGER'">重新创建</el-button>
          <el-button type="text" size="small" :disabled="scope.row.modifyDisabled" @click="modifyGuest(scope.row)" v-if="roleMenu!='SUPER_MANAGER'">修改</el-button>
          <el-button type="text" size="small" @click="submitGuest(scope.row)" v-if="roleMenu!='SUPER_MANAGER'" :disabled="scope.row.submitDisabled">提交</el-button>
          <el-button type="text" size="small" @click="cancelGuest(scope.row)" v-if="scope.row.cancelShow" :disabled="scope.row.cancelDisabled">撤回</el-button>
          <el-button type="text" size="small" @click="cancelGuest(scope.row)" v-if="scope.row.rejectShow" :disabled="scope.row.cancelDisabled">驳回</el-button>
          <el-button type="text" size="small" @click="discardGuest(scope.row)" v-if="roleMenu!='SUPER_MANAGER'" :disabled="scope.row.discardDisabled">终止</el-button>
          <el-button type="text" size="small" @click="deployGuest(scope.row)" v-if="roleMenu=='SUPER_MANAGER'" :disabled="scope.row.deployDisabled">部署</el-button>
          <el-button type="text" size="small" @click="cancelDeployment(scope.row)" v-if="roleMenu=='SUPER_MANAGER'" :disabled="scope.row.cancelDeploymentDisabled">撤销部署</el-button>
          <el-button type="text" size="small" @click="noteGuest(scope.row)" v-if="roleMenu=='SUPER_MANAGER'" :disabled="scope.row.noteDisabled">备注</el-button>
          <el-button type="text" size="small" @click="completeGuest(scope.row)" v-if="roleMenu=='SUPER_MANAGER'" :disabled="scope.row.completeDisabled">完成</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增 -->
    <el-dialog title="新增" :visible.sync="addGuestShow" class="el-addGuestShow input-m" width="400px" @close="addGuestClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">新增</span>
      </template>
      <el-form :model="form" :label-position="labelLeft" class="addFormClass">
        <el-form-item label="项目名称：" :label-width="formWidth">
          <el-select v-model="form.projectName" placeholder="请选择项目名称">
            <el-option
              v-for="item in projectNameList"
              :key="item.id"
              :label="item.projectName"
              :value="item.projectName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签：" :label-width="formWidth" required>
          <!-- <el-select v-model="form.label" filterable placeholder="可以试试搜索">
            <el-option
              v-for="item in labelList"
              :key="item.id"
              :label="item.secondLevelLabel"
              :value="item.id">
            </el-option>
          </el-select> -->
          <el-input v-model="form.label" placeholder="请输入标签"></el-input>
        </el-form-item>
        <el-form-item label="城市：" :label-width="formWidth">
          <el-select v-model="form.city" filterable placeholder="可以试试搜索" multiple>
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.tagName"
              :value="item.tagName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求日期：" :label-width="formWidth">
          <el-date-picker
            v-model="form.dateTime"
            type="date"
            :picker-options="pickerOptions"
            value-format="timestamp"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="条数：" :label-width="formWidth">
          <el-input v-model="form.number" placeholder="请输入条数"></el-input>
        </el-form-item>
        <el-form-item label="其它要求：" :label-width="formWidth">
          <el-input v-model="form.otherRequirements" type="textarea" :rows="4" placeholder="请输入要求"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addGuest_submit()">提 交</el-button>
          <el-button type="warning" @click="addGuest_draft()">保存草稿</el-button>
          <el-button @click="addGuest_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改" :visible.sync="modifyGuestShow" class="el-modifyGuestShow input-m" width="400px" @close="modifyGuestClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">修改</span>
      </template>
      <el-form :model="modifyForm" :label-position="labelLeft" class="modifyFormClass">
        <el-form-item label="项目名称：" :label-width="formWidth">
          <el-select v-model="modifyForm.projectName" placeholder="请选择项目名称">
            <el-option
              v-for="item in projectNameList"
              :key="item.id"
              :label="item.projectName"
              :value="item.projectName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签：" label-width="90px">
          <!-- <el-select v-model="modifyForm.label" filterable placeholder="可以试试搜索">
            <el-option
              v-for="item in labelList"
              :key="item.id"
              :label="item.secondLevelLabel"
              :value="item.id">
            </el-option>
          </el-select> -->
          <el-input v-model="modifyForm.label" placeholder="请输入标签"></el-input>
        </el-form-item>
        <el-form-item label="城市：" label-width="90px">
          <el-select v-model="modifyForm.city" filterable placeholder="可以试试搜索" multiple>
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.tagName"
              :value="item.tagName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求日期：" label-width="90px">
          <el-date-picker
            v-model="modifyForm.finishDate"
            type="date"
            :picker-options="pickerOptions"
            value-format="timestamp"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="条数：" label-width="90px">
          <el-input v-model="modifyForm.number" placeholder="请输入条数"></el-input>
        </el-form-item>
        <el-form-item label="要求：" label-width="90px">
          <el-input v-model="modifyForm.requirement" type="textarea" :rows="4" placeholder="请输入要求"></el-input>
        </el-form-item>
      </el-form>
      <div class="button-center">
        <el-button type="primary" @click="modifyGuest_submit()">提 交</el-button>
        <el-button type="warning" @click="modifyGuest_draft()" v-if="saveDraftShow">保存草稿</el-button>
        <el-button @click="modifyGuest_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 备注 -->
    <el-dialog title="备注" :visible.sync="noteGuestShow" custom-class="el-noteGuestShow input-m" width="400px" @close="noteGuestClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">备注</span>
      </template>
      <el-form :model="noteForm" :label-position="labelLeft">
        <el-form-item label="备注：" label-width="60px">
          <el-input v-model="noteForm.note" type="textarea" :rows="4" placeholder="请输入备注"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="noteGuest_submit()">确认提交</el-button>
          <el-button @click="noteGuest_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog title="查看" :visible.sync="checkGuestShow" class="el-noteGuestShow input-m" width="400px" @close="checkGuestClose">
      <template slot="title">
        <i class="el-icon-data-analysis dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">查看</span>
      </template>
      <el-form :model="checkForm" :label-position="labelLeft">
        <el-form-item label="用户：" label-width="90px">
          <el-input v-model="checkForm.userName" disabled></el-input>
        </el-form-item>
        <el-form-item label="项目名称：" label-width="90px">
          <el-input v-model="checkForm.projectName" disabled></el-input>
        </el-form-item>
        <el-form-item label="标签：" label-width="90px">
          <el-input v-model="checkForm.label" disabled></el-input>
        </el-form-item>
        <el-form-item label="城市：" label-width="90px">
          <el-input v-model="checkForm.city" disabled></el-input>
        </el-form-item>
        <el-form-item label="需求日期：" label-width="90px">
          <el-input v-model="checkForm.finishDateLabel" disabled></el-input>
        </el-form-item>
        <el-form-item label="条数：" label-width="90px">
          <el-input v-model="checkForm.number" disabled></el-input>
        </el-form-item>
        <el-form-item label="要求：" label-width="90px">
          <el-input v-model="checkForm.requirement" type="textarea" :rows="4" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注：" label-width="90px">
          <el-input v-model="checkForm.note" type="textarea" :rows="4" disabled></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 完成--计费弹框 -->
    <el-dialog title="计费" :visible.sync="chargingShow" class="el-addBill input-m" width="410px" @close="chargingClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">计费</span>
      </template>
      <el-form :model="chargingForm" label-position="left" label-width="100px">
        <el-form-item label="用户名称：">
          <el-input v-model="chargingForm.userName" placeholder="请输入用户名称" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="批次：">
					<el-select v-model="chargingForm.batchName" placeholder="请选择批次" clearable>
            <el-option v-for="item in batchNameList" :key="item.id" :value="item.id" :label="item.projectName"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="数据量：" required>
          <el-input v-model="chargingForm.dataVolume" placeholder="请输入数据量"  @blur="pitchOn"></el-input>
        </el-form-item>
        <el-form-item label="单价：" required>
          <el-input v-model="chargingForm.price" placeholder="请输入单价" @blur="pitchOn"></el-input>
        </el-form-item>
        <el-form-item label="费用：">
          <el-input v-model="chargingForm.feeLabel" placeholder="请输入费用" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="chargingForm.note" type="textarea" :rows="4" placeholder="请输入备注" disabled></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="charging_submit()">提 交</el-button>
          <el-button @click="charging_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:'latentGuest',
  props:['roleMenu','parentLoginName','loginName'],
  data(){
    return {
      loadingTab: false,
      pickerOptions:{
        disabledDate(time) {
          const start = new Date();
            start.setHours(23);
            start.setMinutes(59);
            start.setSeconds(59);
            start.setMilliseconds(59);
          return time.getTime() < start;
        },
      },
      callPickerOptions:{
        disabledDate(time) {
            const start = new Date();
            start.setHours(23);
            start.setMinutes(59);
            start.setSeconds(59);
            start.setMilliseconds(59);
            return time.getTime() > start;
        },
      },
      addGuestShow:false,
      modifyGuestShow:false,
      noteGuestShow:false,
      checkGuestShow:false,
      checkForm:{
        userName:'',
        projectName:'',
        label:'',
        city:'',
        finishDateLabel:'',
        number:'',
        requirement:'',
        note:'',
      },
      currentPage:null,
      total:null,
      labelLeft:"left",
      formWidth:"90px",
      tableData:[],
      form:{
        projectName:'',
        label:'',
        city:[],
        dateTime:'',
        number:'',
        otherRequirements:''
      },
      modifyForm:{
        projectName:'',
        id:'',
        label:'',
        city:'',
        finishDate:'',
        number:'',
        requirement:'',
      },
      labelList:[],
      cityList:[],
      noteForm:{
        id:'',
        note:'' 
      },
      condition:{
        callEndAtStart:'',
        callEndAtEnd:'',
        userId:'',
        batchName:'',
        projectName:'',
      },
      chargingShow:false,
      chargingForm:{
        userName:'',
        batchName:'',
        dataVolume:'',
        price:'',
        fee:'',
        feeLabel:'',
        note:''
      },
      id:'',
      batchNameList:[],
      projectNameList:[],
      userNameList:[],
      statusList:[
        {
          label:'全部',
          value:''
        },
        {
          label:'草稿',
          value:'DRAFT'
        },
        {
          label:'提交',
          value:'ADDED'
        },
        {
          label:'部署中',
          value:'DEPLOYED'
        },
        {
          label:'撤回',
          value:'WITHDRAWN'
        },
        {
          label:'被驳回',
          value:'PRIMARY_REJECT'
        },
        {
          label:'待确认',
          value:'WAIT_PRIMARY_CONFIRM'
        },
        {
          label:'终止',
          value:'DISCARDED'
        },
        {
          label:'完成',
          value:'COMPLETED'
        },
      ],
      statusList2:[
        {
          label:'全部',
          value:''
        },
        {
          label:'提交',
          value:'ADDED'
        },
        {
          label:'部署中',
          value:'DEPLOYED'
        },
        {
          label:'完成',
          value:'COMPLETED'
        },
      ],
      loadingName:false,
      includeChildren:false,
      saveDraftShow:false,
      pageSize:10
    }
  },
  watch:{
    includeChildren(val){
      this.condition.projectName = "";
      let params = {};
        params.pageSize = 1000;
        params.pageNo = 1;
        params.loginName = this.condition.userId;
        params.includeChildren = this.includeChildren;
        this.inquireProject(params);
    }
  },
  mounted(){
    document.title = "惠销平台管理系统-需求提交";
    this.theWeek();
    this.inquireUserList({
        pageSize: 1000,
        pageNo: 1,
        enabled: true
      });
    this.inquireProject({pageNo:1,pageSize:1000});
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    start = this.$common.transformTime(start.valueOf());
    // start = start.substring(0,10);
    // end = end.substring(0,10);
    this.condition.callEndAtStart = start;
    let time = new Date();
    time = time.getTime()+24*3600*1000;
    // time = this.$common.transformTime(time);
    // time = time.substring(0,10);
    this.form.dateTime = time;
    sessionStorage.setItem('dateTime', time);
    this.inquirelabel();
    this.$nextTick(()=>{
      this.inquire();
    })
  },
  methods:{
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
     // 项目搜索获取焦点
    projectIdRemoteFocus() {
      let params = {
        pageSize:20,
        pageNo:1,
        loginName: this.condition.userId,
        includeChildren: this.includeChildren
      }
      this.inquireProject(params)
    },
    nameRemoteMethod(projectName){//项目名称搜索
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize:1000,
          pageNo:1,
          loginName: this.condition.userId,
          projectName:projectName,
          includeChildren: this.includeChildren
        }
        this.inquireProject(params)
      }, 200);
    },
    inquire(){//查询表格数据
      this.currentPage = 1;
      let params = {
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        projectName:this.condition.projectName,
        loginName:this.condition.userId,
        status:this.condition.status,
        gmtCreatedStart:this.condition.callEndAtStart,
        gmtCreatedEnd:this.condition.callEndAtEnd,
        includeChildren:this.includeChildren
      }
      this.inquireProspectiveCustomer(params);
    },
    inquireProspectiveCustomer(params){//查询需求列表
      this.loadingTab = true
      this.$http('/prospectiveCustomer/list',null,'get',null,params).then(res=>{
        setTimeout(() => {
          this.loadingTab = false
        }, 200);
        if(res.result == 200){
          let list = res.data.records;
          this.total = res.data.total;
          list.map(item=>{
            item.finishDateLabel = this.$common.transformTime(item.finishDate);
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.submitDisabled = true;
            item.cancelDisabled = true;
            item.discardDisabled = true;
            item.deployDisabled = true;
            item.completeDisabled = true;
            item.modifyDisabled = true;
            item.cancelDeploymentDisabled = true;
            item.cancelShow = false;
            item.rejectShow = false;
            item.labelShow = item.label;
            // item.projectName = item.projectName.split(",");
            // item.projectNameId = item.projectName[0];
            // item.projectNameLabel = item.projectName[1];
            if(this.parentLoginName == this.loginName && this.roleMenu!='SUPER_MANAGER'){
              item.cancelShow = true;
            }
            if(this.parentLoginName != this.loginName && this.roleMenu!='SUPER_MANAGER'){
              item.cancelShow = true;
            }
            switch(item.status){
              case "DRAFT":
                item.statusLabel = "草稿";
                item.discardDisabled = false;
                item.submitDisabled = false;
                item.modifyDisabled = false;
                break;
              case "ADDED":
                item.statusLabel = "提交";
                item.cancelDisabled = false;
                item.deployDisabled = false;
                break;
              case "DEPLOYED":
                item.statusLabel = "部署中";
                item.completeDisabled = false;
                item.cancelDeploymentDisabled = false;
                break;
              case "WITHDRAWN":
                item.statusLabel = "撤回";
                item.discardDisabled = false;
                item.submitDisabled = false;
                item.modifyDisabled = false;
                break;
              case "DISCARDED":
                item.statusLabel = "终止";
                break;
              case "COMPLETED":
                item.statusLabel = "完成";
                break;
              case "PRIMARY_REJECT":
                item.statusLabel = "被驳回";
                item.discardDisabled = false;
                if(this.parentLoginName != this.loginName){
                  item.modifyDisabled = false;
                  item.submitDisabled = false;
                }
                break;
              case "WAIT_PRIMARY_CONFIRM":
                item.statusLabel = "待确认";
                item.modifyDisabled = false;
                item.submitDisabled = false;
                if(this.parentLoginName != this.loginName){
                  item.submitDisabled = true;
                  item.modifyDisabled = true;
                }
                if(this.parentLoginName == this.loginName && this.roleMenu!='SUPER_MANAGER'){
                  item.rejectShow = true;
                  item.cancelShow = false;
                }
                item.cancelDisabled = false;
                item.discardDisabled = false;
                break;
            }
          })
          this.tableData = list;
        }
      })
    },
    inquirelabel(){//查询标签、城市
      let data = {
        pageNo: 1,
        pageSize: 1000
      }
      this.$http('/label/list',null,'get',null,data).then(res=>{
        if(res.result == 200){
          this.labelList = res.data.records;
        }
      });
      this.$http('/prospectiveCustomer/cityList',null).then(res=>{
        if(res.result == 200){
          this.cityList = res.data;
        }
      });
    },
    currentPage_data(index){//当前页
      this.currentPage = index;
      let params = {
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        projectName:this.condition.projectName,
        loginName:this.condition.userId,
        status:this.condition.status,
        gmtCreatedStart:this.condition.callEndAtStart,
        gmtCreatedEnd:this.condition.callEndAtEnd,
        includeChildren:this.includeChildren
      }
      this.inquireProspectiveCustomer(params);
    },
    sizeChange(index){//显示条数--分页
      this.currentPage = 1;
      this.pageSize = index;
      let params = {
        pageNo:this.currentPage,
        pageSize:this.pageSize,
        projectName:this.condition.projectName,
        loginName:this.condition.userId,
        status:this.condition.status,
        gmtCreatedStart:this.condition.callEndAtStart,
        gmtCreatedEnd:this.condition.callEndAtEnd,
        includeChildren:this.includeChildren
      }
      this.inquireProspectiveCustomer(params);
    },
    inquireUserList(params){//查询公司名称
      this.$http('/user/list', null, 'get', null, params).then(res => {
        if(res.result == 200){
          let list = res.data.list;
          this.userNameList = list;
        }
      })
    },
    inquireProject(params){//查询项目名称
      this.projectNameList = [];
      let arr = [];
      this.$http('/sip/project/list',null,'get',null,params).then(res => {
        let list = res.data.list;
        let obj = {}
        // let par = {
        //     projectName:"全部",
        //     id:""
        //   }
        list.map(item=>{
          obj = {
            id:item.id,
            projectName:item.projectName,
          }
          arr.push(obj);
        })
        for(let i=0;i<arr.length;i++){
          for(let j=i+1;j<arr.length;j++){
            if(arr[i].projectName == arr[j].projectName){
              arr.splice(j, 1);
              j--;
            }
          }
        }
        this.projectNameList = arr;
          // this.projectNameList.unshift(par);
      })
    },
    // 公司名称获取焦点
    NameRemoteFocus(){
      this.inquireUserList({
        pageSize: 1000,
        pageNo: 1,
        enabled: true
      });
    },
    // 搜索用户名称 
    uerNameRemoteMethod(userName){
      this.inquireUserList({
        pageSize: 1000,
        pageNo: 1,
        userName:userName,
        enabled: true
      });
    },
    userIdChange(userId){//选择公司
      this.condition.projectName = "";
    },
    addGuest(){//点击新增
      this.addGuestShow = true;
      let dateTime = sessionStorage.getItem('dateTime');
      if(dateTime){
        this.form.dateTime = Number(dateTime);
      }
    },
    checkGuest(val){//点击查看
      this.checkGuestShow = true;
      this.checkForm = {
        userName:val.submitUserName,
        projectName:val.projectName,
        label:val.labelShow,
        city:val.city,
        finishDateLabel:val.finishDateLabel,
        number:val.number,
        requirement:val.requirement,
        note:val.note,
      }
    },
    checkGuestClose(){//关闭查看表单
      this.checkForm = {
        projectName:'',
        label:'',
        city:'',
        finishDateLabel:'',
        number:'',
        requirement:'',
        note:'',
      }
    },
    recreateGuest(val){//点击重新修建
      let data = {
        projectName:val.projectName,
        label:val.label,
        city:val.city,
        finishDate:val.finishDateLabel,
        number:val.number,
        requirement:val.requirement
      }
      // data.finishDate = data.finishDate.substring(0,10);
      this.$http('/prospectiveCustomer/saveDraft',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'重新创建成功',type:'success'});
          this.inquire();
        }
      })
    },
    modifyGuest(val){//点击修改
      this.saveDraftShow = false;
      if(val.submitLoginName == this.loginName){
        this.saveDraftShow = true;
      }
      this.modifyGuestShow = true;
      let city="";
      if(val.city){
        city = val.city.split(",");
      }
      this.modifyForm = {
        id:val.id,
        state:val.statusLabel,
        label:val.label,
        city:city,
        finishDate:val.finishDate,
        number:val.number,
        requirement:val.requirement
      }
      if(val.projectName){
        this.modifyForm.projectName = val.projectName
      }else{
        this.modifyForm.projectName = '';
      }
    },
    submitGuest(val){//点击提交
      let data = {
        id:val.id
      }
      this.$http('/prospectiveCustomer/add',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'提交成功',type:'success'});
          this.inquire();
        }
      })
    },
    cancelGuest(val){//点击撤回
      let data = {
        id:val.id
      }
      this.$http('/prospectiveCustomer/withdraw',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'撤回成功',type:'success'});
          this.inquire();
        }
      })
    },
    discardGuest(val){//点击终止
      let data = {
        id:val.id
      }
      this.$http('/prospectiveCustomer/discard',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'终止成功',type:'success'});
          this.inquire();
        }
      })
    },
    deployGuest(val){//点击部署
      let data = {
        id:val.id
      }
      this.$http('/prospectiveCustomer/deploy',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'部署成功',type:'success'});
          this.inquire();
        }
      })
    },
    cancelDeployment(val){//点击撤销部署
      let data = {
        id:val.id
      }
      this.$http('/prospectiveCustomer/cancelDeployment',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'撤销部署成功',type:'success'});
          this.inquire();
        }
      })
    },
    noteGuest(val){//点击备注
      this.noteGuestShow = true;
      this.noteForm.note = val.note;
      this.noteForm.id = val.id;
    },
    noteGuest_submit(){//确认提交备注
      let data = this.noteForm;
      this.$http('/prospectiveCustomer/modifyNote',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'备注成功',type:'success'});
          this.inquire();
          this.noteGuest_cancel();
        }
      })
    },
    noteGuest_cancel(){//取消提交备注
      this.noteGuestShow = false;
    },
    noteGuestClose(){//关闭备注表单
      this.noteForm.note = "";
      this.noteForm.id = "";
    },
    completeGuest(val){//点击完成
      // console.log(val)
      this.chargingShow = true;
      let params = {
        loginName : val.submitLoginName,
        pageSize : 1000,
        pageNo : 1
      }
      this.batchNameList = [];
      this.$http('/sip/project/list',null,'get',null,params).then(res => {
        let list = res.data.list;
        let obj = {}
        list.map(item=>{
          obj = {
            id:item.id,
            projectName:item.projectName,
          }
          this.batchNameList.push(obj);
        })
      })
      this.chargingForm.batchName = parseInt(val.projectNameId);
      this.chargingForm.userName = val.submitUserName;
      this.chargingForm.note = val.note;
      this.chargingForm.dataVolume = val.number;
      this.id = val.id;
    },
    pitchOn(){//单价输入框失去焦点
      this.chargingForm.fee = '';
      this.chargingForm.feeLabel = '';
      if(this.chargingForm.price && this.chargingForm.dataVolume){
        this.chargingForm.fee = (this.chargingForm.price * this.chargingForm.dataVolume).toFixed(3);
        this.chargingForm.feeLabel = this.chargingForm.fee + ' 元';
      }
    },
    addGuest_submit(){//点击新增提交
      let city = this.form.city.join(",");
      let time = this.$common.transformTime(this.form.dateTime);
      let data = {
        projectName:this.form.projectName,
        label:this.form.label,
        city:city,
        finishDate:time,
        number:this.form.number,
        requirement:this.form.otherRequirements,
      }
      if(data.label){
        this.$http('/prospectiveCustomer/add',data).then(res=>{
          if(res.result == 200){
            this.$message({message:'新增成功',type:'success'});
            this.inquire();
            this.addGuest_cancel();
          }
        })
      }else{
        this.$message({message:'信息不完整',type:'warning'});
      }
    },
    addGuest_draft(){//点击保存草稿
      let city = this.form.city;
      city = city.join(",");
      let time = this.$common.transformTime(this.form.dateTime);
      let data = {
        projectName:this.form.projectName,
        label:this.form.label,
        city:city,
        finishDate:time,
        number:this.form.number,
        requirement:this.form.otherRequirements,
      }
      this.$http('/prospectiveCustomer/saveDraft',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'保存草稿成功',type:'success'});
          this.inquire();
          this.addGuest_cancel();
        }
      })
    },
    addGuest_cancel(){//点击新增取消
      this.addGuestShow = false;
    },
    addGuestClose(){//关闭新增表单
      this.form = {
        label:'',
        city:[],
        dateTime:'',
        number:'',
        otherRequirements:'',
        projectName:''
      }
    },
    modifyGuestClose(){//关闭修改表单
      this.modifyForm = {
        label:"",
        city:[],
        finishDate:"",
        number:"",
        requirement:"",
        projectName:""
      }
    },
    modifyGuest_submit(){//提交修改表单
      let data = {};
      for(let i in this.modifyForm){
        data[i] = this.modifyForm[i];
      }
      data.finishDate = this.$common.transformTime(data.finishDate);
      data.city = data.city.join(",");
      delete data.state;
      this.$http('/prospectiveCustomer/add',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'修改成功',type:'success'});
          this.inquire();
          this.modifyGuest_cancel();
        }
      })
    },
    modifyGuest_draft(){//点击修改表单保存草稿
      let data = {};
      for(let i in this.modifyForm){
        data[i] = this.modifyForm[i];
      }
      data.finishDate = this.$common.transformTime(data.finishDate);
      data.city = data.city.join(",");
      delete data.state;
      this.$http('/prospectiveCustomer/saveDraft',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'保存草稿成功',type:'success'});
          this.inquire();
          this.modifyGuest_cancel();
        }
      })
    },
    modifyGuest_cancel(){//取消修改表单
      this.modifyGuestShow = false;
    },
    charging_submit(){//提交计费表单
      let data = {
        id:this.id,
        count:this.chargingForm.dataVolume,
        money:(this.chargingForm.fee*1000).toFixed(0),
        remark:this.chargingForm.note,
      }
      if(!this.chargingForm.price || !this.chargingForm.dataVolume){
        this.$message({message:'信息不完整',type:'warning'});
        return;
      }
      this.$http('/prospectiveCustomer/complete',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'完成',type:'success'});
          this.inquire();
          this.charging_cancel();
        }
      })
    },
    charging_cancel(){//取消计费表单
      this.chargingShow = false;
    },
    chargingClose(){//关闭计费表单
      this.chargingForm = {
        userId:'',
        batchName:'',
        dataVolume:'',
        price:'',
        fee:'',
        note:'',
        feeLabel:''
      }
    }
  }
}
</script>
<style scoped>
.latentGuest .input-m >>> .el-input {
  width: 100%;
}
.el-addBill >>> .el-dialog__body{
  padding: 10px 20px 20px 20px;
}
.el-addBill .el-select,.el-addBill .el-input,.el-addBill .el-textarea{
  width: 250px;
}
.el-noteGuestShow >>> .el-dialog__body{
  padding: 7px 25px;
}
.el-addGuestShow >>> .el-dialog__body{
  padding: 7px 25px 30px 25px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
 text-align: center
}
.el-addGuestShow .el-input{
  width: 260px;
}
.el-addGuestShow .el-select{
  width: 260px;
}
.el-modifyGuestShow .el-select{
  width: 260px;
}
.el-modifyGuestShow .el-input{
  width: 260px;
}
.taskName .el-checkbox{
  margin-left: 15px;
}
.taskName >>> .el-checkbox__label{
  font-size:16px;
  color: #333;
} 
.callTime,.batchName {
  margin-right: 6px;
}
.callTime,.batchName{
  float: right;
  text-align: left;
}
.batchName .el-select{
  width: 230px;
}
.nav{
  width: 1060px;
}
.addFormClass >>> .el-textarea__inner{
  padding: 5px 5px;
  width: 260px;
}
.modifyFormClass >>> .el-textarea__inner{
  padding: 5px 5px;
  width: 260px;
}
</style>