<template>
  <div class="prologueList">
    <div style="margin-bottom: 20px">
      <div style="display: inline-block">
        开场白名称：
        <el-input
          v-model="prologueTitle"
          placeholder="请输入开场白名称"
          clearable
          style="width: 250px"
        ></el-input>
      </div>
    </div>
    <div class="add-sound-btn">
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button type="primary" @click="addSound">新增</el-button>
    </div>
    <el-table
      :data="prologueTableData"
      stripe
      style="width: 100%"
      border
      class="tableDataClass"
      v-loading="loadingList"
    >
      <el-table-column
        type="index"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        min-width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="title"
        label="开场白名称"
        min-width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="fileName"
        label="文件名称"
        min-width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="userLoginName"
        label="登录名称"
        width="150"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="server"
        label="服务器"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="300"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="170"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="140" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="playRecord(scope.row)"
            >播放</el-button
          >
          <el-button type="text" size="small" @click="modifyClick(scope.row)"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="downloadVoice(scope.row)"
            >下载</el-button
          >
          <!-- <el-button type="text" size="small" @click="deleteClick(scope.row)" style="color: #ff0000b0;">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handlePrologueChange"
      @current-change="currentPage_prologue"
      :current-page.sync="currentPagePrologue"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="totalPrologue"
    ></el-pagination>
    <!-- 新增 -->
    <el-dialog
      :visible.sync="addSoundShow"
      custom-class="el-addProject"
      @close="closeAddSound"
      width="500px"
      class="addProjectClass"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">新增开场白</span>
      </template>
      <el-form label-position="left" label-width="105px">
        <el-form-item label="产品名称：">
          <el-input
            v-model="productName"
            placeholder="请输入产品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="开场白名称：">
          <el-input
            v-model="soundRecordName"
            placeholder="请输入开场白名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="开场白说明：">
          <el-input
            type="textarea"
            :rows="4"
            v-model="soundRecordPrologue"
            placeholder="请输入开场白说明"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传文件：">
          <el-upload
            class="upload-demo"
            drag
            :data="prologueUploadData"
            :action="prologueUrl"
            :on-success="successPrologueUpload"
            :on-error="errorPrologueUpload"
            ref="prologueUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="addPrologue()"
          :disabled="isAddPrologue"
          >确定</el-button
        >
        <el-button @click="addSoundShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="修改开场白"
      :visible.sync="modifyPrologueShow"
      width="450px"
      @close="closeModifyPrologue"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改开场白</span>
      </template>
      <el-form label-position="left" label-width="105px">
        <el-form-item label="产品名称：" class="itemClass">
          <el-input v-model="prologurForm.productName"></el-input>
        </el-form-item>
        <el-form-item label="开场白名称：" class="itemClass">
          <el-input v-model="prologurForm.title"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="prologurForm.remark"
            type="textarea"
            :rows="5"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button
            type="primary"
            @click="modifyPrologue_submit()"
            :disabled="isModifyPrologue_submit"
            >确定</el-button
          >
          <el-button @click="modifyPrologueShow = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 播放录音 -->
    <el-dialog
      class="customDialog"
      :visible.sync="playRecordShow"
      width="300px"
      @close="closePlayRecordShow"
    >
      <audio
        ref="audio"
        :src="currentSrc"
        autoplay="autoplay"
        controlsList="nodownload"
        controls="controls"
      ></audio>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "prologueList",
  props: ["server"],
  data() {
    return {
      prologurForm: {
        productName: "",
        title: "",
        remark: "",
        id: "",
      },
      isModifyPrologue_submit: false,
      modifyPrologueShow: false,
      prologueTitle: "",
      loadingList: false,
      prologueTableData: [],
      currentPagePrologue: 1,
      totalPrologue: null,
      pageNo: 1,
      pageSize: 10,
      addSoundShow: false,
      isAddPrologue: false,
      soundRecordName: "",
      productName: "",
      soundRecordPrologue: "",
      playRecordShow: false,
      currentSrc: "",
      playFlag: true,
      fileList: [],
      prologueUrl: "",
      prologueUploadData: {},
      cookie: true,
        server: ""
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-开场白列表";
    this.inquire(1);
  },
  methods: {
    // 点击修改
    modifyClick(val) {
      console.log(val);
      this.modifyPrologueShow = true;
      this.prologurForm.title = val.title;
      this.prologurForm.productName = val.productName;
      this.prologurForm.remark = val.remark;
      this.prologurForm.id = val.id;
    },
    // 确定修改
    modifyPrologue_submit() {
      this.$service("/prologue/updatePrologue", this.prologurForm).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.modifyPrologueShow = false;
            this.inquire();
          }
        }
      );
    },
    // 关闭修改
    closeModifyPrologue() {
      this.prologurForm = {
        title: "",
        productName: "",
        remark: "",
        id: "",
      };
    },
    // 点击查询
    inquire(pageNo, pageSize) {
      let data = {
        pageNo: pageNo || this.pageNo,
        pageSize: pageSize || this.pageSize,
        title: this.prologueTitle,
      };
      this.getPrologueList(data);
    },
    // 点击新增
    addSound() {
      this.addSoundShow = true;
    },
    //限制上传文件数量提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    //开场白上传成功
    successPrologueUpload(res, file, fileList) {
      this.$refs.prologueUpload.clearFiles();
      if (res.result == 200) {
        this.isAddPrologue = false;
        this.$message({ message: "文件上传成功！", type: "success" });
        this.addSoundShow = false;
        this.inquire(1);
      }
    },
    //开场白上传失败
    errorPrologueUpload(err, file, fileList) {
      this.isAddPrologue = false;
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    // 确定新增
    addPrologue() {
      this.isAddPrologue = true;
      this.prologueUploadData.title = this.soundRecordName;
      this.prologueUploadData.productName = this.productName;
      this.prologueUploadData.remark = this.soundRecordPrologue;
      let path = "https://" + this.server + this.$constants.basePath;
      this.prologueUrl = path + "/prologue/addPrologue";
      this.$nextTick(() => {
        this.$refs.prologueUpload.submit();
      });
    },
    // 关闭新增
    closeAddSound() {
      this.soundRecordName = "";
      this.productName = "";
      this.soundRecordPrologue = "";
      this.$refs.prologueUpload.clearFiles();
    },
    // 点击播放
    playRecord(val) {
      this.playRecordShow = true;
      this.currentSrc = `https://${val.server}${this.$constants.basePath}/prologue/prePlayPrologue?id=${val.id}`;
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        // this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    // 关闭播放
    closePlayRecordShow() {
      //播放录音关闭
      this.$refs.audio.pause();
      this.playFlag = false;
    },
    //点击删除
    deleteClick(val) {
      this.$confirm("删除该开场白文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: val.id,
          };
          this.$service("/prologue/deletePrologue", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.getPrologueList();
            }
          });
        })
        .catch(() => {});
    },
    // 查询开场白列表
    getPrologueList(data) {
      this.loadingList = true;
      this.$http("/prologue/list", null, "get", null, data)
        .then((res) => {
          this.loadingList = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            });
            this.totalPrologue = res.data.total;
            this.prologueTableData = list;
          }
        })
        .catch((err) => {
          this.loadingList = false;
        });
    },
    downloadVoice(val) {
      //下载录音
      let params = {
        id: val.id,
      };
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i];
        }
      }
      let path = "https://" + this.server + this.$constants.basePath;
      let href = path + "/prologue/download?" + url;
      window.location.href = href;
    },
    // 分页条数
    handlePrologueChange(index) {
      this.pageNo = 1;
      this.pageSize = index;
      this.inquire();
    },
    // 分页
    currentPage_prologue(index) {
      this.pageNo = index;
      this.inquire();
    },
  },
};
</script>
<style scoped>
.button-center {
  text-align: center;
}
.add-sound-btn {
  margin-bottom: 20px;
}
.el-table {
  margin-bottom: 20px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
</style>

