<template>
  <div class="wechatSubjectGroup">
    <div class="searchConditionItem">
      分组名称：
      <el-input v-model="groupName" placeholder="请输入分组名称" clearable />
    </div>
    <el-button type="primary" @click="inquire()">查询</el-button>
    <el-button type="primary" @click="add">新增</el-button>
    <!-- 项目表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
    >
      <el-table-column
        prop="id"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="groupName"
        label="分组名称"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="submitter"
        label="提交方"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="routingType"
        label="路由类型"
        min-width="200"
        align="left"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.routingType == "RANDOM" ? "随机" : "轮循" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="260"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="edit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteGroup(scope.row)"
            style="color: #f56c6c"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 修改项目 -->
    <el-dialog :title="title" :visible.sync="editShow" width="1000px">
      <el-form label-width="90px">
        <el-form-item label="分组名称:">
          <el-input
            v-model="groupNameAdd"
            placeholder="请输入分组名称"
            clearable
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="提交方:">
          <el-select v-model="submitter" style="width: 240px">
            <el-option
              v-for="item in submitterList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="路由类型:">
          <el-radio-group v-model="routingTypeAdd">
            <el-radio label="RANDOM">随机</el-radio>
            <el-radio label="WITH_ORDER">轮循</el-radio>
            <el-radio label="WEIGHTED_RANDOM">加权随机</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="addWechatSubjectGroupItem"
          >新增</el-button
        >
      </div>
      <el-table
        :data="wechatSubjectGroupItemList"
        stripe
        style="width: 100%"
        border
      >
        <template v-if="prefix == 'wechat'">
          <el-table-column prop="appKey" label="加粉主体" min-width="200">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.appKey"
                @change="scope.row.ruleId = ''"
              >
                <el-option
                  v-for="item in appKeyList"
                  :key="item.appKey"
                  :label="item.subjectName"
                  :value="item.appKey"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="ruleId" label="规则id" width="200">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.ruleId"
                :disabled="!scope.row.appKey"
                @focus="getRuleList(scope.row.appKey)"
              >
                <el-option
                  v-for="item in ruleList"
                  :key="item.ruleId"
                  :label="item.name"
                  :value="item.ruleId"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column prop="taskId" label="任务id" min-width="200">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.taskId"
                placeholder="请输入任务id"
                clearable
              />
            </template>
          </el-table-column>
          <el-table-column prop="tagId" label="标签id" width="200">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.tagId"
                placeholder="请输入标签id"
                clearable
              />
            </template>
          </el-table-column>
          <el-table-column prop="subjectId" label="主体id" width="200">
            <template slot-scope="scope">
              <el-select v-model="scope.row.subjectId">
                <el-option
                  v-for="item in subjectList"
                  :key="item.id"
                  :label="item.subjectName"
                  :value="item.subjectId"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          prop="currentCount"
          label="当前数"
          width="100"
          v-if="routingTypeAdd == 'WEIGHTED_RANDOM'"
        >
        </el-table-column>
        <el-table-column
          prop="maxCount"
          label="最大数"
          width="100"
          v-if="routingTypeAdd == 'WEIGHTED_RANDOM'"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.maxCount"
              placeholder="请输入最大数"
              clearable
            />
          </template>
        </el-table-column>
        <el-table-column prop="enabled" label="启用状态" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.enabled" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="deleteWechatSubjectGroupItem(scope.$index)"
              style="color: #f56c6c"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="editSubmit()">提 交</el-button>
        <el-button @click="editShow = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "wechatSubjectGroup",
  props: ["prefix"],
  data() {
    return {
      groupName: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      title: "",
      editId: "",
      editShow: false,
      tableData: [],
      loading: false,
      groupNameAdd: "",
      routingTypeAdd: "",
      wechatSubjectGroupItemList: [],
      editWechatSubjectGroupItemShow: false,
      appKeyList: [],
      submitterList: [],
      submitter: "",
      ruleList: [],
      subjectList: [],
    };
  },
  mounted() {
    this.inquire();
    if (this.prefix == "wechat") {
      this.getAppKeyList();
    } else {
      this.getSubjectList();
    }
    this.getSubmitterList();
  },
  methods: {
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        groupName: this.groupName,
      };
      this.$http(
        `/${this.prefix}SubjectGroup/${this.prefix}SubjectGroupPage`,
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          this.total = res.data.count;
          this.tableData = res.data.records;
        }
      });
    },
    add() {
      this.title = "新增";
      this.groupNameAdd = "";
      this.submitter = "";
      this.routingTypeAdd = "RANDOM";
      this.wechatSubjectGroupItemList.splice(0);
      this.editShow = true;
    },
    edit(val) {
      this.title = "修改";
      this.editId = val.id;
      this.groupNameAdd = val.groupName;
      this.routingTypeAdd = val.routingType;
      this.submitter = val.submitter;
      let data = {
        groupId: val.id,
      };
      this.$http(
        `/${this.prefix}SubjectGroup/${this.prefix}SubjectGroupItemListByGroupId`,
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          this.wechatSubjectGroupItemList = res.data;
          this.editShow = true;
        }
      });
    },
    editSubmit() {
      let data = {
        groupName: this.groupNameAdd,
        routingType: this.routingTypeAdd,
        submitter: this.submitter,
      };
      if (this.prefix == "wechat") {
        data.wechatSubjectGroupItemList = this.wechatSubjectGroupItemList;
        data.wechatSubjectGroupItemList.forEach((item) => {
          delete item.currentCount;
          this.appKeyList.forEach((appKeyItem) => {
            if (appKeyItem.appKey == item.appKey) {
              item.subjectName = appKeyItem.subjectName;
            }
          });
        });
      } else {
        data.xylkSubjectGroupItemList = this.wechatSubjectGroupItemList;
        data.xylkSubjectGroupItemList.forEach((item) => {
          delete item.currentCount;
          this.appKeyList.forEach((appKeyItem) => {
            if (appKeyItem.appKey == item.appKey) {
              item.subjectName = appKeyItem.subjectName;
            }
          });
        });
      }
      if (this.title == "新增") {
        this.$service(
          `/${this.prefix}SubjectGroup/add${this.prefix.replace(/^./, (match) =>
            match.toUpperCase()
          )}SubjectGroup`,
          data
        ).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.editShow = false;
            this.inquire(1);
          }
        });
      } else {
        data.id = this.editId;
        this.$service(
          `/${this.prefix}SubjectGroup/edit${this.prefix.replace(
            /^./,
            (match) => match.toUpperCase()
          )}SubjectGroup`,
          data
        ).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.editShow = false;
            this.inquire();
          }
        });
      }
    },
    deleteGroup(val) {
      this.$confirm("<div>确定删除【" + val.groupName + "】?</div>", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      })
        .then(() => {
          let data = {
            id: val.id,
          };
          this.$service(
            `/${this.prefix}SubjectGroup/delete${this.prefix.replace(
              /^./,
              (match) => match.toUpperCase()
            )}SubjectGroup`,
            data
          ).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquire();
            }
          });
        })
        .catch(() => {});
    },
    sizeChange(val) {
      this.pageSize = val;
      this.inquire(1);
    },
    currentPage_data(index) {
      this.inquire(index);
    },
    addWechatSubjectGroupItem() {
      let obj = {
        maxCount: "",
        currentCount: "",
        enabled: true,
      };
      if (this.prefix == "wechat") {
        obj.appKey = "";
        obj.ruleId = "";
      } else {
        obj.taskId = "";
        obj.tagId = "";
        obj.subjectId = undefined;
      }
      this.wechatSubjectGroupItemList.push(obj);
    },
    deleteWechatSubjectGroupItem(index) {
      this.wechatSubjectGroupItemList.splice(index, 1);
    },
    getAppKeyList() {
      this.$http(
        "/wechatSubjectGroup/querySubjectNameAndAppKey",
        null,
        "get"
      ).then((res) => {
        if (res.result === 200) {
          this.appKeyList = res.data;
        }
      });
    },
    getSubmitterList() {
      this.$http(`/${this.prefix}SubjectGroup/submitterList`, null, "get").then(
        (res) => {
          if (res.result === 200) {
            this.submitterList = res.data;
          }
        }
      );
    },
    getRuleList(appKey) {
      if (!appKey) {
        this.ruleList.splice(0);
        return;
      }
      let name = "";
      this.appKeyList.forEach((item) => {
        if (item.appKey == appKey) {
          name = item.subjectName;
        }
      });
      this.$http(
        "/wechatSubjectGroup/getRuleListBySubjectName",
        null,
        "get",
        null,
        { name }
      ).then((res) => {
        if (res.result === 200) {
          this.ruleList = res.data;
        }
      });
    },
    getSubjectList() {
      this.$http("/xylkSubjectGroup/subjectList", null, "get").then((res) => {
        if (res.result === 200) {
          this.subjectList = res.data;
        }
      });
    },
  },
};
</script>
<style scoped="scoped">
.searchConditionItem {
  margin-bottom: 20px;
}
.searchConditionItem .el-input,
.searchConditionItem .el-select {
  margin-left: 20px;
  width: 260px;
}
.searchConditionItem .el-button {
  display: block;
  margin: 20px 0;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}

.el-form-item .el-select,
.el-form-item .el-input {
  width: 95%;
}
</style>
