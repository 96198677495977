<template>
  <div class="team">
    <div class="nav">
      <div class="projectStatus" style="margin-bottom:20px;">
        <div style="margin-right:20px;display: inline-block;">
          用户名称：
          <el-select 
            v-model="userLoginName" 
            filterable 
            remote 
            :loading="loadingLoginName" 
            :remote-method="remoteMethodLoginName" 
            @focus="userNameRemoteFocus" 
            placeholder="请选择用户名称" 
            clearable
          >
            <el-option v-for="item in loginNameList" :key="item.id" :label="item.label" :value="item.loginName"></el-option>
          </el-select>
        </div>
        <div style="margin-right:20px;display: inline-block;">
          队列名称：
          <el-input v-model="teamNameSearch" placeholder="请输入队列名称"></el-input>
        </div> 
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button type="primary" @click="addTeamClick">新增队列</el-button>
    <el-button @click="workingTimeClick(null,'batch')" v-if="roleMenu==='SUPER_MANAGER'" :disabled="dropdownDisabled">批量工作时间</el-button>
    <!-- 拨打队列表 -->
    <el-table 
      :data="tableData" 
      stripe 
      style="width: 100%" 
      border
      v-loading="loading" 
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="index" prop label="编号" width="60" align="center" v-if="roleMenu!=='SUPER_MANAGER'"></el-table-column>
      <el-table-column type="selection" width="55" v-if="roleMenu==='SUPER_MANAGER'"></el-table-column>
      <el-table-column prop="userLoginName" label="用户" width align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="队列名称" width align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="typeLabel" label="队列类型" width="100" align="center"></el-table-column>
      <el-table-column prop="autoStartEnabled" label="自启动" width="100" align="center">
        <template slot="header">
          <span>自启动</span>
          <el-tooltip class="item" effect="light" placement="top-start">
            <div slot="content">1. 在非工作时间开启，会自动排队<br/>2. 工作时间打到非工作时间，会自动暂停并排队，等第二天会自动启动</div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.autoStartEnabled">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column prop="clientCount" label="坐席数" width="100" align="center"></el-table-column>
      <el-table-column prop="workingTimeStr" label="工作时间" width="430" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="roleMenu!=='SUPER_MANAGER'">{{scope.row.workingTimeStr}}</span>
          <el-button v-if="roleMenu==='SUPER_MANAGER'" type="text" size="small" @click="workingTimeClick(scope.row, 'single')">{{scope.row.workingTimeStr}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="gmtCreated" label="创建时间" width="170" align="center"></el-table-column>
      <el-table-column prop label="操作" width="125" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="canClient(scope.row)" :disabled="scope.row.type=='VIRTUAL'">坐席管理</el-button>
          <el-button type="text" size="small" @click="modifyName(scope.row)">修改</el-button>
          <!-- <el-button type="text" size="small" @click="deleteShow(scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增 -->
    <el-dialog :visible.sync="addTeam" custom-class="el-addTeam-m" width="450px" @close="addTeamClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">新增队列</span>
      </template>
      <el-form :model="form" :label-position="labelLeft" label-width="120px">
        <el-form-item label="用户名称：">
          <span style="font-weight: bold;">{{userName}}</span>
        </el-form-item>
        <el-form-item label="队列名称：" required>
          <el-input v-model="form.name" placeholder="请输入队列名称"></el-input>
        </el-form-item>
        <el-form-item label="队列类型：">
          <el-select v-model="teamType" placeholder="请选择队列类型">
            <el-option
              v-for="item in teamTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否自启动：">
          <template slot="label">
            是否自启动
            <el-tooltip class="item" placement="top-start">
              <div slot="content">1. 在非工作时间开启，会自动排队<br/>2. 工作时间打到非工作时间，会自动暂停并排队，等第二天会自动启动</div>
              <i class="el-icon-question icon-color"></i>
            </el-tooltip>：
          </template>
          <el-switch
            v-model="autoStartEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addTeam_submit()">提 交</el-button>
          <el-button @click="addTeam_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 操作 -->
    <!-- 坐席管理 -->
    <el-dialog
      :visible.sync="clientShow"
      custom-class="el-addTeam"
      width="550px"
      title="坐席管理"
    >
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">坐席管理</span>
      </template>
      <el-transfer
        filterable
        filter-placeholder="请输入坐席编号"
        v-model="value"
        :data="data"
        :titles="['可选择','使用中']"
        @change="handleChangeClient"
        class="transferClass"
      ></el-transfer>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="chenageClient_submit()">提 交</el-button>
        <el-button @click="chenageClient_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改" :visible.sync="modifyNameShow" custom-class="el-addTeam" width="450px" @close="addTeamClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">修改</span>
      </template>
      <el-form :label-position="labelLeft" label-width="120px">
        <el-form-item label="名称：">
          <el-input v-model="TeamName" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="队列类型：">
          <el-select v-model="modifyTeamType" placeholder="请选择队列类型" style="width: 100%">
            <el-option
              v-for="item in teamTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否自启动：">
          <template slot="label">
            是否自启动
            <el-tooltip class="item" placement="top-start">
              <div slot="content">1. 在非工作时间开启，会自动排队<br/>2. 工作时间打到非工作时间，会自动暂停并排队，等第二天会自动启动</div>
              <i class="el-icon-question icon-color"></i>
            </el-tooltip>：
          </template>
          <el-switch
            v-model="autoStartEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="modifyName_submit()">提 交</el-button>
          <el-button @click="modifyName_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 修改工作时间 -->
    <el-dialog title="修改工作时间" :visible.sync="modifyWorkingTimeShow" custom-class="el-addTeam" width="425px" @close="modifyWorkingTimeClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">{{workingTimeTitle}}</span>
      </template>
      <el-form :label-position="labelLeft"  label-width="110px" class="workingTimeClass">
        <el-form-item label="工作日：" required>
          <el-select 
            v-model="startWorkingDay" 
            placeholder="请选择工作日" 
            clearable
            multiple
            collapse-tags
            class="working_day_class"
          >
            <el-option
              v-for="item in workingDayList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上午：" required>
          <el-time-select
            placeholder="起始时间"
            v-model="morningStartWorkingTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '22:00'
            }">
          </el-time-select>
          <el-time-select
            placeholder="结束时间"
            v-model="morningEndWorkingTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '22:00',
              minTime: morningStartWorkingTime
            }">
          </el-time-select>
        </el-form-item>
        <el-form-item label="下午：" required>
          <el-time-select
            placeholder="起始时间"
            v-model="afternoonStartWorkingTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '22:00'
            }">
          </el-time-select>
          <el-time-select
            placeholder="结束时间"
            v-model="afternoonEndWorkingTime"
            :picker-options="{
              start: '07:00',
              step: '00:05',
              end: '22:00',
              minTime: afternoonStartWorkingTime
            }">
          </el-time-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
						拨打控制
						<el-tooltip class="item" content="在规定工作时间之外无法开启拨打任务" placement="top-start">
							<i class="el-icon-question icon-color"></i>
						</el-tooltip>：
					</template>
          <el-switch v-model="minutesControlEnabled" :disabled="minutesControlDisabled" active-color="#13ce66" inactive-color="#C0CCDA"></el-switch>
        </el-form-item>
        <el-form-item>
          <template slot="label">
						自动暂停
						<el-tooltip class="item" content="在规定工作时间之外自动暂停任务" placement="top-start">
							<i class="el-icon-question icon-color"></i>
						</el-tooltip>：
					</template>
          <el-switch v-model="forceStopEnabled" active-color="#13ce66" inactive-color="#C0CCDA"></el-switch>
        </el-form-item>
        <div v-if="timeTipsShow" style="color: #E6A23C; margin: 0 0 20px 0">
          拨打时间重合，中午不会休息
        </div>
        <div class="button-center">
          <el-button type="primary" @click="modifyWorkingTime_submit()">确 定</el-button>
          <el-button @click="modifyWorkingTimeShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 批量修改工作时间 -->
    <el-dialog 
      :visible.sync="batchOperationShow" 
      width="400px" 
      @close="batchOperationShowClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    > 
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">批量修改工作时间</span>
      </template>
      <div style="max-height: 550px; overflow: auto;">
        <el-steps :active="batchTeamIndex" direction="vertical" :space="55">
          <el-step v-for="(item ,i) in batchTeamStepsList" :key="i" :status="item.finishStatus">
            <template slot="title">
              <div>用户：{{item.userLoginName}}</div>
            </template>
            <template slot="description">
              <div>队列名称：{{item.name}}</div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button v-if="batchOperationBtnShow" @click="batchOperationSubmit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "team",
  data() {
    return {
      batchOperationShow: false,
      batchOperationBtnShow: false,
      batchTeamIndex: 0,
      batchTeamStepsList: [],
      multipleSelection: [],
      workingTimeTitle: "",
      autoStartEnabled: false,
      workingDayList: [
        {
          value: "MONDAY",
          label: "周一"
        },
        {
          value: "TUESDAY",
          label: "周二"
        },
        {
          value: "WEDNESDAY",
          label: "周三"
        },
        {
          value: "THURSDAY",
          label: "周四"
        },
        {
          value: "FRIDAY",
          label: "周五"
        },
        {
          value: "SATURDAY",
          label: "周六"
        },
        {
          value: "SUNDAY",
          label: "周日"
        },
      ],
      startWorkingDay: [],
      modifyTeamType: "",
      teamTypeList: [
        {
          value: "NORMAL",
          label: "普通队列"
        },
        {
          value: "VIRTUAL",
          label: "虚拟队列"
        },
      ],
      teamType: "NORMAL",
      minutesControlDisabled: false,
      morningStartWorkingTime: '',
      morningEndWorkingTime: '',
      afternoonStartWorkingTime: '',
      afternoonEndWorkingTime: '',
      minutesControlEnabled: false,
      forceStopEnabled: false,
      modifyWorkingTimeShow: false,
      userLoginName: '',
      teamNameSearch: '',
      loadingLoginName: false,
      loginNameList:[],
      loading: false,
      TeamName:'',
      modifyNameShow:false,
      data: [],
      value: [],
      pinyin: [],
      tableData: null,
      currentPage: null,
      total: null,
      addTeam: false,
      labelLeft: "left",
      clientShow: false,
      deletedShow: false,
      id: "",
      clientNumbers: [],
      routeIds: [],
      form: {
        name: ""
      },
      formWidth: "60px",
      loginName:'',
      pageSize:10,
      teamId: '',
      userName: ''
    };
  },
  props:['roleMenu'],
  watch: {
    forceStopEnabled(val) {
      if (val) {
        this.minutesControlDisabled = true;
      } else {
        this.minutesControlDisabled = false;
      }
    }
  },
  computed: {
    dropdownDisabled() {
      // 是否禁用批量操作
      if (this.multipleSelection.length > 0) {
        return false
      }
      return true
    },
    timeTipsShow() {
      if (this.morningEndWorkingTime && this.afternoonStartWorkingTime) {
        let morningTmie = this.morningEndWorkingTime.split(":")
        let afternoonTime = this.afternoonStartWorkingTime.split(":")
        let t1 = new Date(2016, 9, 10, morningTmie[0], morningTmie[1]).getTime()
        let t2 = new Date(2016, 9, 10, afternoonTime[0], afternoonTime[1]).getTime()
        if (t1 >= t2) {
          return true
        }
      }
      return false
    }
  },
  mounted() {
    document.title = "惠销平台管理系统-拨打队列";
    let request = new Promise((resolve,rejects) => {
      let params = {"pageNo":1,"pageSize":20,"enabled": true};
      this.loginNameList = [];
      this.$http('/user/list',null,'get',null,params).then(res=>{
        if(res.result == 200){
          let newuserNameList = res.data.list;
          newuserNameList.map(item=>{
            item.label = item.userName;
            this.loginNameList.push(item);
          })
          // this.userLoginName = this.loginNameList[0].loginName;
          resolve();
        }
      })
    })
    request.then(res => {
      this.inquire(1);
    })
  },
  methods: {
    // 表格多选
    handleSelectionChange(val) {
      this.batchTeamStepsList = [];
      this.multipleSelection = this.$common.deepClone(val);
      this.multipleSelection.forEach(item => {
        item.finishStatus = "wait"
        this.batchTeamStepsList.push(item);
      });
    },
    // 确认批量操作
    batchOperationSubmit() {
      this.batchOperationShow = false;
      this.inquire(1);
    },
    // 关闭批量操作
    batchOperationShowClose() {
      this.batchTeamIndex = 0;
      this.batchTeamStepsList = [];
      this.batchOperationBtnShow = false;
      this.modifyWorkingTimeShow = false;
      this.morningStartWorkingTime = '';
      this.morningEndWorkingTime = '';
      this.afternoonStartWorkingTime = '';
      this.afternoonEndWorkingTime = '';
      this.startWorkingDay = [];
      this.minutesControlEnabled = false;
      this.forceStopEnabled = false;
    },
    // 点击新增
    addTeamClick() {
      if (!this.userLoginName) {
        this.$message.warning("请选择用户名称");
        return
      }
      this.addTeam = true;
      this.loginNameList.forEach(item => {
        if (item.loginName === this.userLoginName) {
          this.userName = item.userName;
        }
      })
    },
    // 点击工作时间
    workingTimeClick(val, key) {
      this.modifyWorkingTimeShow = true;
      if (key === "batch") {
        this.workingTimeTitle = "批量修改工作时间";
      }
      if (key === "single") {
        this.workingTimeTitle = "修改工作时间";
        this.morningStartWorkingTime = val.startMinutesAtMorningStr;
        this.morningEndWorkingTime = val.endMinutesAtMorningStr;
        this.afternoonStartWorkingTime = val.startMinutesAtAfternoonStr;
        this.afternoonEndWorkingTime = val.endMinutesAtAfternoonStr;
        this.minutesControlEnabled = val.minutesControlEnabled;
        this.forceStopEnabled = val.forceStopEnabled;
        this.startWorkingDay = val.workDayList;
        this.teamId = val.id;
      }
    },
    // 确定修改工作时间
    modifyWorkingTime_submit() {
      if (this.workingTimeTitle === "修改工作时间") {
        let data = {
          teamId: this.teamId,
          workDayList: this.startWorkingDay,
          morningStartWorkingTime: this.morningStartWorkingTime,
          morningEndWorkingTime: this.morningEndWorkingTime,
          afternoonStartWorkingTime: this.afternoonStartWorkingTime,
          afternoonEndWorkingTime: this.afternoonEndWorkingTime,
          minutesControlEnabled: this.minutesControlEnabled,
          forceStopEnabled: this.forceStopEnabled,
        }
        if (!data.workDayList.length > 0) {
          this.$message({message: "工作日时间不能为空", type: "warning"});
          return
        }
        if (!data.morningStartWorkingTime || !data.morningEndWorkingTime) {
          this.$message({message: "上午工作时间不能为空", type: "warning"});
          return
        }
        if (!data.afternoonStartWorkingTime || !data.afternoonEndWorkingTime) {
          this.$message({message: "下午工作时间不能为空", type: "warning"});
          return
        }
        this.$service("/sip/team/updateWorkingTime",data).then(res => {
          if (res.result === 200) {
            this.modifyWorkingTimeShow = false;
            this.$message({message: "修改成功", type: "success"});
            this.inquire();
          }
        })
      }
      if (this.workingTimeTitle === "批量修改工作时间") {
        this.batchTeamIndex = 0;
        this.batchOperationShow = true;
        this.batchTeamStepPause(this.batchTeamStepsList, this.batchTeamStepsList[0], 0);
      }
    },
    // 批量任务操作--取消排队
    batchTeamStepPause(stepsList, data, index) {
      if (index > this.batchTeamStepsList.length-1) {
        this.batchOperationBtnShow = true;
        return
      }
      data.finishStatus = "process";
      let params = {
        teamId: data.id,
        workDayList: this.startWorkingDay,
        morningStartWorkingTime: this.morningStartWorkingTime,
        morningEndWorkingTime: this.morningEndWorkingTime,
        afternoonStartWorkingTime: this.afternoonStartWorkingTime,
        afternoonEndWorkingTime: this.afternoonEndWorkingTime,
        minutesControlEnabled: this.minutesControlEnabled,
        forceStopEnabled: this.forceStopEnabled,
      };
      this.$service("/sip/team/updateWorkingTime", params).then(
        res => {
          if (res.result == 200) {
            this.batchTeamIndex++;
            data.finishStatus = "success";
            return this.batchTeamStepPause(this.batchTeamStepsList, this.batchTeamStepsList[this.batchTeamIndex], this.batchTeamIndex);
          } else {
            this.batchTeamIndex++;
            data.finishStatus = "error";
            return this.batchTeamStepPause(this.batchTeamStepsList, this.batchTeamStepsList[this.batchTeamIndex], this.batchTeamIndex);
          }
        }
      ).catch(err => {
        this.batchTeamIndex++;
        data.finishStatus = "error";
        return this.batchTeamStepPause(this.batchTeamStepsList, this.batchTeamStepsList[this.batchTeamIndex], this.batchTeamIndex);
      })
    },
    // 关闭修改工作时间
    modifyWorkingTimeClose() {
      this.batchOperationShowClose();
    },
    // 用户名称获取焦点
    userNameRemoteFocus() {
      let params = {
            pageSize:20,
            pageNo:1,
            enabled: true
          }
      this.inquireLoginName(params)
    },
    remoteMethodLoginName(userName){//搜索用户名称
      this.loadingLoginName = true;
      setTimeout(() => {
        this.loadingLoginName = false;
        let params = {
            pageSize:20,
            pageNo:1,
            userName:userName,
            enabled: true
          }
          this.inquireLoginName(params)
      }, 200);
    },
    inquireLoginName(params){//查询用户名称
      this.loginNameList = [];
      this.$http('/user/list',null,'get',null,params).then(res=>{
        if(res.result == 200){
          let newuserNameList = res.data.list;
          newuserNameList.map(item=>{
            item.label = item.userName;
            this.loginNameList.push(item);
          })
        }
      })
    },
    modifyName(val){//点击修改
      this.modifyNameShow = true;
      this.TeamName = val.name;
      this.id = val.id;
      this.modifyTeamType = val.type;
      this.autoStartEnabled = val.autoStartEnabled;
    },
    modifyName_submit(){//提交修改
      let data = {
        id: this.id,
        name: this.TeamName,
        type: this.modifyTeamType,
        autoStartEnabled: this.autoStartEnabled
      }
      this.$service('/sip/team/update',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'修改成功',type:'success'});
          this.modifyName_cancel();
          this.inquire();
        }
      })
    },
    modifyName_cancel(){//取消修改
      this.modifyNameShow = false;
      this.modifyTeamType = "";
    },
    currentPage_data(index) {//当前页
      this.inquire(index);
    },
    sizeChange(index) {//显示条数--分页
      this.pageSize = index;
      this.inquire(1);
    },
    inquire(index) {//查询列表
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        loginName: this.userLoginName,
        name: this.teamNameSearch
      };
      this.inquireTeamList(params);
    },
    inquireTeamList(params){//查询拨打列队列表
      this.loading = true
      this.$http("/sip/team/list", null, "get", null, params).then(res => {
        setTimeout(() => {
          this.loading = false
        }, 200);
        if (res.result == 200) {
          let list = res.data.records;
          this.total = res.data.total;
          list.map(item => {
            item.workingTimeStr = item.workingTimeStr.replace("#","");
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            if (item.type === "NORMAL") {
              item.typeLabel = "普通队列";
            }
            if (item.type === "VIRTUAL") {
              item.typeLabel = "虚拟队列";
            }
          });
          this.tableData = list;
        }
      });
    },
    inquire2() {//请求坐席数据
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.loginName,
        ignoreUsed: true
      };
      // 清空数据
      this.data = [];
      this.pinyin = [];
      this.$http("/sip/client/list", null, "get", null, params).then(res => {
        if (res.result == 200) {
          let list = res.data.list;
          list.forEach((client, index) => {
            this.pinyin.push(client.number);
            this.data.push({
              label: client.name,
              key: client.number,
              pinyin: this.pinyin[index],
              disabled: client.used
            });
          });
          this.value.forEach(i => {
            this.data.forEach((j,k) => {
              if (i === j.key) {
                this.data[k].disabled = false;
              }
            })
          })
        }
      });
    },
    addTeam_submit() {
      //新增提交
      let params = {
        userLoginName: this.userLoginName,
        name: this.form.name,
        type: this.teamType,
        autoStartEnabled: this.autoStartEnabled
      };
      if (!this.form.name) {
        this.$message({ message: "信息不完整", type: "warning" });
        return;
      }
      this.$service("/sip/team/add",params).then(res => {
        if (res.result == 200) {
          this.$message({ message: "新增队列成功", type: "success" });
          this.addTeam_cancel();
          this.inquire(1);
        }
      });
    },
    addTeam_cancel() {
      this.form.name = "";
      this.addTeam = false;
    },
    addTeamClose() {
      this.autoStartEnabled = false;
    },
    canClient(val) {//点击坐席管理
      this.loginName = val.userLoginName;
      this.id = val.id;
      this.clientNumbers = [];
      let clientNumbers = [];
      if(val.clientNumbers){
        clientNumbers = val.clientNumbers.split(",");
      }
      this.value = [];
      clientNumbers.forEach(c => {
        this.value.push(c);
        this.clientNumbers.push(c);
      });
      this.clientShow = true;
      this.inquire2();
    },
    handleChangeClient(value, direction, movedKeys) {//坐席管理穿梭框变动
      this.clientNumbers = value;
    },
    chenageClient_submit() {//确认坐席管理
      let newclientNumbers = this.clientNumbers.filter(item => item),
        params = {
          id: this.id,
          clientNumbers: newclientNumbers.join(",")
        };
      this.$http("/sip/team/updateClient", null, "get", null, params).then(
        res => {
          if (res.result == 200) {
            this.$message({ message: "坐席操作成功", type: "success" });
            this.inquire();
            this.clientShow = false;
          } else {
            this.$message({ message: "坐席操作失败", type: "warning" });
          }
        }
      );
    },
    chenageClient_cancel() {
      this.clientShow = false;
    },
    deleteShow(val) {
      this.id = val.id;
      this.$confirm("删除队列, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
        .then(() => {
          let params = {
            id: this.id
          };
          this.$http("/sip/team/delete", null, "get", null, params).then(
            res => {
              if (res.result == 200) {
                this.$message({ message: "删除成功", type: "success" });
                this.inquire();
              } 
            }
          );
        })
        .catch(() => {});
    }
  }
};
</script>
<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>
<style scoped="scoped">
.projectStatus .el-select, .projectStatus .el-input {
  width: 250px;
}
.workingTimeClass .working_day_class >>> .el-input{
  width: 243px;
}
.el-addTeam-m .el-select {
  width: 100%;
}
.workingTimeClass >>> .el-input {
  width: 120px;
}
.transferClass >>> .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover{
  color: #C0C4CC !important;
  background-color: #fff;
  border-color: #ebeef5;
}
.transferClass >>> .el-transfer-panel__item{
  display: block !important;
}
.transferClass >>> .el-transfer__button:first-child{
  margin-bottom: 10px !important;
}
.transferClass >>> .el-transfer__button{
  display: block !important;
  margin: 0 auto !important;
  padding: 10px !important;
  border-radius: 50% !important;
  font-size: 0 !important;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  width: 165px;
  margin: 0 auto;
}
</style>


