<template>
  <div class="longUrlGroupManage">
    <div class="nav"></div>
    <el-button type="primary" @click="inquire()">查询</el-button>
    <el-button @click="addShort">新增</el-button>
    <el-table
      :data="longUrlTableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="userLoginName"
        label="用户"
        width="150"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="groupName"
        label="分组名"
        width="150"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="userSmsAccountName"
        label="通道名称"
        width="120"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="smsAccessKey"
        label="accessKey"
        width="120"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="smsContent"
        label="短信内容"
        min-width="150"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        min-width="150"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="160"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="lookClinetNumber(scope.row)"
            >查看坐席链接</el-button
          >
          <el-button type="text" size="small" @click="modifyShort(scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增 -->
    <el-dialog
      title="新增"
      :visible.sync="addShortShow"
      custom-class="el-addBindshow"
      width="950px"
      @close="addShortClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ addShortTitle }}</span>
      </template>
      <el-form label-position="left" label-width="120px" :inline="true">
        <el-form-item label="用户名称：" required>
          <el-select
            v-model="loginName"
            filterable
            remote
            :loading="loadingName"
            :remote-method="NameRemoteMethod"
            placeholder="请选择用户名称"
            @focus="NameRemoteFocus"
            style="width: 250px"
            :disabled="addShortTitle !== '新增'"
          >
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :value="item.loginName"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分组名称：" required>
          <el-input
            v-model="groupName"
            placeholder="请输入分组名称"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="平台名称：" required>
          <el-select
            v-model="manPlatformLabel"
            placeholder="请选择平台名称"
            @change="manPlatformChange"
            style="width: 250px"
          >
            <el-option
              v-for="item in platformArr"
              :key="item.id"
              :label="item.alias"
              :value="
                item.id +
                '_' +
                item.accessKey +
                '_' +
                item.accessSecret +
                '_' +
                item.alias
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="短信签名：" required>
          <el-select
            v-model="smsSignValueInfo"
            placeholder="请选择短信签名"
            style="width: 250px"
            :disabled="!manPlatformLabel"
          >
            <el-option
              v-for="(item, index) in smsSignList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label required>
          <template slot="label">
            短信内容
            <el-tooltip
              content="短信内容不需要手动输入签名"
              placement="top-start"
              effect="light"
            >
              <i
                class="el-icon-question"
                style="color: #909399"
              ></i> </el-tooltip
            >：
          </template>
          <smsInputEnabled
            :textareaSMS="SMStextareaInfo"
            @change="textarea_smsInfo"
            @smsNum="getSmsNum"
            @callValueChange="callValueChange"
            @wechatServerChange="wechatServerChange"
            @wechatGroupIdChange="wechatGroupIdChange"
            @shortUrlDomainChange="shortUrlDomainChange"
            :callValue="callValue"
            :serverWechat="serverWechat"
            :clientGroupId="clientGroupId"
            :smsSign="smsSignValueInfo"
            :shortUrlDomainList="shortUrlDomainList"
            :shortUrlDomain="shortUrlDomain"
            style="width: 750px"
          ></smsInputEnabled>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="remark"
            type="textarea"
            :rows="4"
            placeholder="请输入备注"
            style="width: 750px"
          ></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addShort_submit()">确认</el-button>
          <el-button @click="addShortShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 查看坐席链接 -->
    <el-dialog
      :title="lookClinetNumberTitle"
      :visible.sync="lookClinetNumberShow"
      class="connectedTable"
      width="800px"
      @close="lookClinetNumberClose"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{
          lookClinetNumberTitle
        }}</span>
      </template>
      <el-button type="primary" @click="addClinetNumber">新增</el-button>
      <el-table
        :data="lookClinetNumberTableData"
        size="mini"
        stripe
        style="width: 100%"
        border
      >
        <el-table-column
          type="index"
          prop
          label="编号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientNumber"
          label="坐席编号"
          width="150"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="longUrl"
          label="专属长链"
          min-width="100"
          align="left"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="modifyLongUrl(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 新增坐席长链 -->
    <el-dialog
      :title="addClinetNumberTitle"
      :visible.sync="addClinetNumberShow"
      custom-class="el-addBindshow"
      width="500px"
      @close="addClinetNumberClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ addClinetNumberTitle }}</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="坐席编号：" required>
          <el-select
            v-model="clientNumber"
            placeholder="请选择坐席编号"
            filterable
            :disabled="!addClinetNumberForm"
          >
            <el-option
              v-for="item in clientNumberList"
              :key="item.id"
              :label="item.name + '(' + item.number + ')'"
              :value="item.number"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专属长链：" required>
          <el-input v-model="longUrl" placeholder="请输入专属长链"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addClinetNumber_submit()"
            >确认</el-button
          >
          <el-button @click="addClinetNumberShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import smsInputEnabled from "../pages/smsInputEnabled.vue";
export default {
  name: "longUrlGroupManage",
  components: {
    smsInputEnabled,
  },
  data() {
    return {
      loginName: "",
      loadingName: false,
      userNameList: [],
      addShortTitle: "",
      manPlatformLabel: "",
      platformArr: [],
      shortUrlDomainList: [],
      shortUrlDomain: undefined,
      smsSignValueInfo: "",
      smsSignList: "",
      smsNum: 0,
      SMStextareaInfo: "",
      callValue: "NONE",
      serverWechat: "t9.innerchic.cn",
      clientGroupId: "",
      smsSignValueInfo: "",
      lookClinetNumberTitle: "",
      addClinetNumberForm: false,
      addClinetNumberTitle: "",
      addClinetNumberShow: false,
      lookClinetNumberShow: false,
      lookClinetNumberTableData: [],
      groupName: "",
      remark: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loadingTable: false,
      longUrlTableData: [],
      addShortShow: false,
      longUrl: "",
      clientNumber: "",
      clientNumberList: [],
      userLoginName: "",
      groupId: "",
    };
  },
  mounted() {
    this.inquire(1);
  },
  methods: {
    // 用户名称获取焦点
    NameRemoteFocus() {
      this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    NameRemoteMethod(userName) {
      //搜索用户名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireLoginName(params);
      }, 200);
    },
    inquireLoginName(params) {
      //查询用户名称
      this.userNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            item.label = item.userName;
            this.userNameList.push(item);
          });
        }
      });
    },
    // 人工任务--平台选择
    manPlatformChange(val) {
      let platforms = val.split("_");
      this.smsSignValueInfo = "";
      let signData = {
        pageNo: 1,
        pageSize: 100,
        userSmsAccountId: platforms[0],
      };
      this.getSignList(signData);
    },
    // 查询平台
    getPlatformList() {
      this.$http("/smsCenterWeb/listUserSmsAccount", null, "get").then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            this.platformArr = list;
          }
        }
      );
    },
    getShortUrlDomainList(val) {
      this.$http("/smsCenterWeb/listDomain", null, "get").then((res) => {
        if (res.result === 200) {
          this.shortUrlDomainList = res.data;
          if (val) {
            this.shortUrlDomain = val.shortUrlDomain;
          } else {
            this.shortUrlDomain = this.shortUrlDomainList[0];
          }
        }
      });
    },
    shortUrlDomainChange(value) {
      this.shortUrlDomain = value;
    },
    // 获取短信签名
    getSignList(data) {
      this.$http("/smsCenterWeb/listSign", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            this.smsSignList = res.data;
          }
        }
      );
    },
    // 获取短信内容--坐席
    textarea_smsInfo(val) {
      this.SMStextareaInfo = val;
    },
    // 获取短信条数
    getSmsNum(num) {
      this.smsNum = num;
    },
    // 获取点击调用
    callValueChange(val) {
      this.callValue = val;
    },
    // 获取微信服务器
    wechatServerChange(val) {
      this.serverWechat = val;
    },
    // 获取微信机器
    wechatGroupIdChange(val) {
      this.clientGroupId = val;
    },
    // 点击查看坐席
    lookClinetNumber(val) {
      this.lookClinetNumberShow = true;
      this.userLoginName = val.userLoginName;
      this.lookClinetNumberTitle = "【" + val.userLoginName + "】查看坐席链接";
      this.groupId = val.id;
      this.getGroupItemList(val.id);
    },
    // 关闭查看坐席
    lookClinetNumberClose() {
      this.lookClinetNumberTableData = [];
    },
    // 点击新增---查看坐席链接
    addClinetNumber() {
      this.addClinetNumberTitle = "新增";
      this.addClinetNumberForm = true;
      this.addClinetNumberShow = true;
      this.getClinetNumberList();
    },
    // 确定新增---查看坐席链接
    addClinetNumber_submit() {
      let data = {
        longUrl: this.longUrl,
        groupId: this.groupId,
        clientNumber: this.clientNumber,
      };
      if (!data.clientNumber) {
        this.$message.warning("请选择坐席编号");
        return;
      }
      if (!data.longUrl) {
        this.$message.warning("请输入专属长链");
        return;
      }
      this.$service("/longUrlGroup/updateGroupItem", data).then((res) => {
        if (res.result === 200) {
          this.addClinetNumberShow = false;
          if (this.addClinetNumberForm) {
            this.$message.success("新增成功");
          } else {
            this.$message.success("修改成功");
          }
          this.getGroupItemList(this.groupId);
        }
      });
    },
    // 关闭新增---查看坐席链接
    addClinetNumberClose() {
      this.longUrl = "";
      this.clientNumber = "";
    },
    // 点击修改---查看坐席链接
    modifyLongUrl(val) {
      this.addClinetNumberTitle = "修改";
      this.addClinetNumberForm = false;
      this.clientNumber = val.clientNumber;
      this.longUrl = val.longUrl;
      this.addClinetNumberShow = true;
    },
    // 查询用户坐席
    getClinetNumberList() {
      let params = {
        pageNo: 1,
        pageSize: 1000,
        loginName: this.userLoginName,
        ignoreDisabled: true,
      };
      this.$http("/sip/client/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          this.clientNumberList = list;
        }
      });
    },
    // 查询坐席长链
    getGroupItemList(groupId) {
      let data = {
        groupId: groupId,
      };
      this.$http("/longUrlGroup/groupItemList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            this.lookClinetNumberTableData = list;
          }
        }
      );
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      this.getGroupList(data);
    },
    // 查询长链分组数据
    getGroupList(data) {
      this.loadingTable = true;
      this.$http("/longUrlGroup/groupList", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.total = res.data.total;
            this.longUrlTableData = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 每页
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 点击新增
    addShort() {
      this.addShortTitle = "新增";
      this.addShortShow = true;
      this.getPlatformList();
      this.getShortUrlDomainList();
    },
    // 点击修改
    modifyShort(val) {
      this.loginName = val.userLoginName;
      this.addShortTitle = "【" + val.groupName + "】修改";
      this.groupId = val.id;
      this.addShortShow = true;
      this.getPlatformList();
      this.getShortUrlDomainList(val);
      this.inquireLoginName({ pageNo: 1, pageSize: 200, enabled: true });
      this.groupName = val.groupName;
      this.remark = val.remark;
      this.manPlatformLabel =
        val.userSmsAccountId +
        "_" +
        val.smsAccessKey +
        "_" +
        val.smsAccessSecret +
        "_" +
        val.userSmsAccountName;
      this.manPlatformChange(this.manPlatformLabel);
      this.$nextTick(() => {
        this.callValue = val.addWechatTriggerAction;
        this.serverWechat = val.wechatServer;
        this.clientGroupId = val.wechatClientGroupId;
        this.smsSignValueInfo = "";
        this.smsSignValueInfo = val.smsSign;
        let SMStextareaInfo = val.smsContent;
        if (!SMStextareaInfo) {
          SMStextareaInfo = "";
        }
        let signFirstIndex = SMStextareaInfo.indexOf("【");
        let signLastIndex = SMStextareaInfo.indexOf("】");
        if (signFirstIndex !== -1 && signLastIndex !== -1) {
          let startText = SMStextareaInfo.substring(0, signFirstIndex);
          let endText = SMStextareaInfo.substring(signLastIndex + 1);
          SMStextareaInfo = startText + endText;
        }
        this.SMStextareaInfo = SMStextareaInfo;
      });
    },
    // 确定新增
    addShort_submit() {
      let data = {
        groupName: this.groupName,
        remark: this.remark,
        userLoginName: this.loginName,
        smsSign: this.smsSignValueInfo,
        smsContent: this.smsSignValueInfo + this.SMStextareaInfo,
        userSmsAccountId: "",
        smsAccessKey: "",
        smsAccessSecret: "",
        userSmsAccountName: "",
        addWechatTriggerAction: this.callValue,
        wechatServer: this.serverWechat,
        wechatClientGroupId: this.clientGroupId,
      };
      if (this.manPlatformLabel) {
        let platforms = this.manPlatformLabel.split("_");
        data.userSmsAccountId = platforms[0];
        data.smsAccessKey = platforms[1];
        data.smsAccessSecret = platforms[2];
        data.userSmsAccountName = platforms[3];
      }
      if (data.smsContent.indexOf("#坐席短链#") !== -1) {
        data.shortUrlDomain = this.shortUrlDomain;
        if (!data.shortUrlDomain) {
          this.$message.warning("请选择域名");
          return;
        }
      }
      if (!data.userLoginName) {
        this.$message.warning("请选择用户名称");
        return;
      }
      if (!data.groupName) {
        this.$message.warning("请输入分组名称");
        return;
      }
      if (!data.userSmsAccountId) {
        this.$message.warning("请选择平台");
        return;
      }
      if (!data.smsSign) {
        this.$message.warning("请输选择短信签名");
        return;
      }
      if (!this.SMStextareaInfo) {
        this.$message.warning("请输入短信内容");
        return;
      }
      if (data.addWechatTriggerAction !== "NONE") {
        if (!data.wechatClientGroupId) {
          this.$message.warning("请选择机器分组");
          return;
        }
      }
      if (this.addShortTitle === "新增") {
        this.$service("/longUrlGroup/addGroup", data).then((res) => {
          if (res.result === 200) {
            this.addShortShow = false;
            this.inquire(1);
            this.$message.success("新增成功");
          }
        });
      } else {
        data.id = this.groupId;
        this.$service("/longUrlGroup/updateGroup", data).then((res) => {
          if (res.result === 200) {
            this.addShortShow = false;
            this.inquire(1);
            this.$message.success("修改成功");
          }
        });
      }
    },
    // 关闭新增
    addShortClose() {
      this.loginName = "";
      this.groupName = "";
      this.remark = "";
      this.manPlatformLabel = "";
      this.platformArr = "";
      this.smsSignValueInfo = "";
      this.SMStextareaInfo = "";
      this.callValue = "NONE";
      this.serverWechat = "t9.innerchic.cn";
      this.clientGroupId = "";
      this.platformArr = [];
      this.smsSignList = [];
      this.shortUrlDomainList = [];
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.el-addBindshow .el-input,
.el-addBindshow .el-select {
  width: 80%;
}
.connectedTable >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 20px 0 20px 0;
}
</style>
