//对$axios的二次封装

import axios from "axios";
import qs from "qs";
import { Message, MessageBox } from "element-ui";
import router from "../../router";
import constants from "./constants.js";

// const path = 'http://mockjs.huiyu.org.cn';
// const path = 'https://backend.huixiao.huiyu.org.cn';
//http状态码
axios.defaults.validateStatus = function (status) {
  // console.log(status)
  return status >= 200 && status < 300; // 默认的
};

// 请求拦截器
axios.interceptors.request.use(
  function (config) {
    // console.log(config)

    return config;
  },
  function (error) {
    // console.log(error)
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  function (response) {
    // console.log(response)
    if (response.data.result === 201) {
      let href = response.data.data;
      window.location.href = href;
    }
    return response;
  },
  function (err) {
    // console.log(err.response)
    if (err && err.response) {
      let message = new Date().getTime();
      let oldMessage = "";
      let oldUrl = -1;
      switch (err.response.status) {
        case 400:
          oldMessage = JSON.parse(sessionStorage.getItem("time"));
          if (oldMessage + 500 < message) {
            MessageBox.alert(
              `<div>${err.response.data.message}</div><div>traceId: ${err.response.data.traceId}</div>`,
              "错误",
              {
                confirmButtonText: "确定",
                type: "error",
                dangerouslyUseHTMLString: true,
                callback: (action) => {},
                customClass: "mzindex",
              }
            );
          }
          break;
        case 401:
          break;
        case 404:
          oldMessage = JSON.parse(sessionStorage.getItem("time"));
          if (oldMessage + 500 < message) {
            Message({
              message: err.response.data.message,
              type: "warning",
              customClass: "mzindex",
            });
          }
          break;
        case 502:
          oldMessage = JSON.parse(sessionStorage.getItem("time"));
          if (oldMessage + 500 < message) {
            Message({
              message: "系统重启中，请稍后重试！",
              type: "warning",
              customClass: "mzindex",
            });
          }
          break;
      }
      sessionStorage.setItem("time", JSON.stringify(message));
    }
    // 对响应错误做点什么
    return Promise.resolve(err); //返回接口的错误信息
  }
);

const http = function http(url, data, type, baseURL, params) {
  return new Promise((resolve, reject) => {
    if (
      baseURL &&
      constants.baseURL == "http://localhost:8080/backendApiPrefix"
    ) {
      baseURL = constants.baseURL;
    }
    axios({
      method: type || "post",
      baseURL: baseURL || constants.baseURL, // 将自动加在 url 前面，除非 url 是一个绝对 URL
      url: url,
      data: data,
      params: params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
      transformRequest: [
        function (data) {
          // 向服务器发送数据前修改数据 (只用于 'PUT', 'POST' 和 'PATCH' 请求方法)
          return qs.stringify(data);
        },
      ],
      headers: { "Content-Type": "application/x-www-form-urlencoded" }, //即将被发送的自定义请求头
      withCredentials: true, //  表示跨域请求时允许携带cookie
    })
      .then((res) => {
        if (res.response) {
          if (res.response.status === 401) {
            let oldUrl = -1;
            Message({
              message: res.response.data.message,
              type: "warning",
              customClass: "mzindex",
            });
            oldUrl = window.location.href;
            router.push("/");
            sessionStorage.setItem("oldUrl", JSON.stringify(oldUrl));
          }
          resolve(res.response);
        }
        if (res.data) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default http;
