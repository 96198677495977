<template>
	<div class="importMac">
		<div class="mac">
			<b>mac地址：</b>
			<el-input type='textarea' placeholder="请输入mac地址" v-model="textarea" resize='none' :spellcheck='false'></el-input>
			<span class="explain">
				说明：
				1、手动输入多个mac(一行一个)，每个mac一律英文小写；<br>
				<i></i>2、mac文件只支持 txt、xls、xlsx文件格式。<br>
				<i></i>3、<a :href="href">点击此处</a> ，下载模板。
			</span>
			<el-button type="primary"  @click='importFile =true' class='btns'>文件导入</el-button>
			<el-button type="primary" @click='click_createGroup()' :disabled='disabled' class='btns'>创建群组</el-button>
		</div>
				
		<!-- 导入文件 -->
		<el-dialog title="" :visible.sync="importFile" custom-class='el-template' width = "480px" >
			<div slot="title" class="dialog-title" >
				<h3>导入文件</h3>
			</div>
			<!-- 上传文件 -->			
			<el-upload class="upload-demo" drag  :data="uploadData" :action="url"  :before-upload="beforeUpload" :on-success='uploadSuccess'  ref="upload" :auto-upload="false" :file-list='fileList' :on-change='handleChange'>
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			</el-upload>	
			<div slot="footer" class="file-footer" align="center">					
				<el-button type="primary" @click="importFile_submit()">上 传</el-button>
				<el-button @click="importFile_cancel()" >取 消</el-button>
			</div>
		</el-dialog>
		
		<!-- 创建群组 -->		
		<el-dialog  :visible.sync="createGroup" custom-class='el-createGroup' width='500px'  >
			<div slot="title" class="dialog-title" >
				<h3>营销任务</h3>
			</div>	
			<el-form :model="form">
				<el-form-item label="" :label-width="formWidth">
					<b>有效数据：</b> 
					<span style="color: lightseagreen;"> {{form.num}} </span> 条
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>群组名称：</b>
					<el-input v-model="form.name" autocomplete="off" placeholder='请输入客群名称'></el-input>
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>所属行业：</b>
					<el-select v-model="form.industry" placeholder="请选择所属行业">
						<el-option v-for="item in industry" :key="item" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>场景名称：</b>
					<el-input v-model="form.scene" autocomplete="off" placeholder='请输入场景名称'></el-input>
				</el-form-item> 
			</el-form>		  
			<div slot="footer" class="dialog-footer" align="center">
				<el-button type="primary" @click='createGroup_submit()'>提 交</el-button>
				<el-button @click='createGroup_cancel()' >取 消</el-button>				
			</div>
		</el-dialog>
		
		<!-- 开始营销 -->
		<el-dialog title="" :visible.sync="startMarketing" custom-class='el-startMarketing' width='600px' >
			<div slot="title" class="dialog-title" >
				<h3>开始营销</h3>
			</div>	
			<b class='word'>群组创建成功，群组名称：<i>{{form.name}}</i>，共计<i> {{form.num}} </i>条。</b>		
			<div slot="footer" class="dialog-footer" align="center">
				<el-button type="primary" @click="startMarketing_sms()" >导入短信系统</el-button>
				<el-button type="primary" @click="startMarketing_phone()">导入呼叫系统</el-button>
				<el-button @click="startMarketing_cancel()" >取 消</el-button>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		name:'importMac',
		data(){
			return {
				href:this.$constants.baseURL+"/sms/downloadMacUploadExcelTemplate",
				msg:'导入Mac',
				textarea:'',
				importFile:false,
				templateList:'',
				url:this.$constants.baseURL+'/sms/uploadMacGroupImportFile',
				fileList:[],
				
				createGroup:false,
				industry:['家居装修','医疗美容','房地产','信用卡','会展','教育','旅游','游戏','理财','个贷','网贷','婚恋','其他'],			
				form: {
					num:'',
					name: '',
					industry: '',
					scene:'',
					ids:''
				},
				
				startMarketing:false,
				groupId:'',
				formWidth: '50px',
				disabled:true,
				uploadData:{}			
			}
		},
		watch:{
			textarea(val){
				if(val){
					this.disabled = false;
				}else {
					this.disabled = true;
				}
			}
		},
		methods:{
			click_createGroup(){//点击按钮创建群组	
				this.form.num = this.textarea.replace(/\n/g,',').split(',').length;
				this.createGroup = true;
			},
			handleChange(file, fileList){//文件状态改变时触发
				this.fileList = fileList.slice(-1);
			},
			beforeUpload(file) {//文件上传前条件判断
				var fileName=new Array();
				fileName =file.name.split('.');
				const type1 = fileName[fileName.length-1] === 'txt';
				const type2 =  fileName[fileName.length-1]=== 'xlsx';
				const type3 =  fileName[fileName.length-1]=== 'xls';
				const isLt2M = file.size / 1024 / 1024 < 10 ;              
				if (type1 || type2 ||type3) {
					return true
				}else {
					this.$message({message: '上传模板只能是 .txt、.xlsx 或者 .xls格式!',type: 'warning'});
					return false
				}								
			},
			uploadSuccess(res,file, fileList){//文件上传成功
				if(res.result == 200){
					this.$message({ message: '导入文件成功！',type: 'success'});
					this.textarea = '';//清空
					this.form.num = res.data.length;
					res.data.map(item=>{
						this.textarea += item+'\n';
					});
					this.textarea = this.textarea.substr(0,this.textarea.length-2);
					this.$refs.upload.clearFiles();//清空已上传的文件列表
					this.importFile = false;					
				}else if(res.result == 401){
					this.$router.push('/');
				}else {
					this.$message({message: '导入文件失败！', type: 'warning'});
				}				
			},
			importFile_submit(){//文件上传			
				 this.$refs.upload.submit();
			},
			importFile_cancel(){//取消文件上传
				this.$refs.upload.clearFiles();
				this.importFile = false;
			},
			createGroup_cancel(){//创建群组取消
				this.form = {
					industry:'',
					scene:'',
					name:'',
					num:''
				}
				this.createGroup = false;
			},
			createGroup_submit(){//创建群组
				if(this.form.name!='' &&this.form.industry!='' &&this.form.scene!=''){
					let strArr = this.textarea.replace(/\n/g,','),
						len = strArr.substr(-1,1);
					if(len == ','){
						strArr = strArr.substr(0,strArr.length-1);
					}
					let data = {
						macs:strArr,
						groupName:this.form.name,
						industry:this.form.industry ,
						remark:this.form.scene																	
					};																						
					this.$http('/sms/addMacGroupManual',data).then(res=>{	
						if (res.result == 200) {
							this.$message({ message: '群组创建成功！', type: 'success'});
							this.groupId = res.data.id;
							this.createGroup = false;
							this.startMarketing = true;					
						}
					});	
				}else {
					this.$message({ message: '信息填写不完整！',type: 'warning'});		
				}
			},
			startMarketing_cancel(){//取消营销
				this.form = {
					industry:'',
					scene:'',
					name:'',
					num:''
				}
				this.startMarketing = false;		 
			},
			startMarketing_sms(){//导入短信系统
				this.$router.push({ path:'/SMSmarketing', query:{ groupId:this.groupId}});
				this.startMarketing_cancel();
			},
			startMarketing_phone(){//导入呼叫系统
				this.$router.push({path:'/machineCalling',query:{groupId:this.groupId}});
				this.startMarketing_cancel();
			}
		}
	}
</script>

<style scoped="scoped">
	
	.mac{
		width: 900px;
	}
	.mac .el-textarea{
		width: 400px;
		height: 300px;
		margin: 20px 10px 10px 38px;
	}
	.mac .el-textarea >>>.el-textarea__inner {
		height: 100% !important;
	}
	.mac b{
		color:#657180 ;
		font-size: 17px;
		display: block;
	}
	.mac .btns{
		width: 120px;
		margin-left: 38px;
	}
	.explain{
		display: block;
		font-style: normal;
		font-size: 12px;
		margin: 0px 0px 50px 38px;
	}
	.explain i{
		margin-left: 40px;
	}
	.el-upload{
		width: 500px;
		line-height: 180px;
		border: 1px dashed;
		border-radius:4px ;
		margin-left: 30px;
	}
	.el-dialog .el-button{
		width: 120px;
	}
	.el-upload i{
		width: 150px;
		line-height: 90px;		
		border: 1px solid lightgray;
		margin-right: 50px;
	}
	.el-upload >>> .el-upload-list li {
		text-align: left;
		padding-left: 130px;
	}
	.el-dialog .el-input{
		width: 300px;
	}
	.el-dialog b{
		color:#657180 ;
		font-size: 17px;
	}
	.el-dialog b i{
		font-style: normal;
		color: lightseagreen;
	}
	.createGroup{
		width: 150px;
		margin-top: 20px;
	}
	
	.el-createGroup .el-input,.el-createGroup .el-select{
		width: 250px;
	}
	.startMarketing .word{
		display: block;
		text-align: center;
	}
	.upload-demo{
		margin-left: 35px;
	}
</style>