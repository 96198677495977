<template>
  <div class="globalConfig">
    <!-- <el-button type="primary" @click="inquire">查询</el-button> -->
    <el-table
      :data="tableListData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
      :span-method="objectSpanMethod"
      height="calc(100% - 40px)"
    >
      <el-table-column prop="name" label="语境" width="200" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
          <el-tooltip placement="top">
            <div slot="content" style="width: 300px">{{ scope.row.remark }}</div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="condition" label="触发条件" width="200" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="contentLabel" label="机器人话术" min-width="100" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="actionLabel" label="执行动作" width="140" align="left"></el-table-column>
      <el-table-column prop="enabled" label="状态" width="100" align="left">
        <template slot-scope="scope">
          <div v-if="scope.row.enabled">
            <div class="node-speech-item-ss-rr" style="background: #67C23A"></div>已启用
          </div>
          <div v-else>
            <div class="node-speech-item-ss-rr" style="background: #F56C6C"></div>已禁用
          </div>
        </template>
      </el-table-column>
      <el-table-column prop label="操作" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button 
            type="text" 
            size="small"
            @click="modifyClick(scope.row)" 
          >修改</el-button>
          <el-button 
            type="text" 
            size="small"
            @click="enableClick(scope.row)" 
          >
            <span v-if="scope.row.enabled">禁用</span>
            <span v-else>启用</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 增加配置 -->
    <el-dialog 
      :title="addTitle" 
      :visible.sync="addShow" 
      width="500px" 
      @close="addClose"
    >
      <el-form label-width="100px">
        <el-form-item label="语境:">
          <span>{{ form.name }}</span>
        </el-form-item>
        <el-form-item label="机器人话术:" required>
          <div v-for="(item,index) in form.contentList" :key="item.id" class="ef-speech-cc-mm">
            <el-input
              v-model="item.content"
              placeholder="请输入此节点需要播放的话术"
              type="textarea"
              :rows="4"
              maxlength="150"
              show-word-limit
              style="margin-bottom: 10px; width: 90%;"
            ></el-input>
            <el-button 
              v-if="index > 0" 
              type="text" 
              @click="deleteSpeechClick(item)"
            >删除</el-button>
          </div>
          <div>
            <el-button
              type="text" 
              @click="addSpeechClick"
            >+添加机器人话术</el-button>
            <span style="padding-left: 10px">
              为什么需要添加多个话术？
              <el-tooltip placement="top">
                <div slot="content" style="width: 300px">如果遇到对用户回复进行多次确认的场景时，可以通过添加多个不同的话术，让机器人更加智能。通常用于个性化设置用户无回复或者未识别到用户意图的场景。</div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="执行动作:" required>
          <el-select v-model="form.action" placeholder="请选择执行动作">
            <el-option
              v-for="item in receiptActionList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用状态:" required>
          <el-switch
            v-model="form.enabled"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="dialog_submit" :loading="loading">确定</el-button>
        <el-button @click="addShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "globalConfig",
  props: {
    activeSpeechId: [String, Number]
  },
  data() {
    return {
      tableListData: [],
      loadingTable: false,
      receiptActionList: [
        {
          value: "HANGUP",
          label: "挂机"
        },
      ],
      addTitle: "",
      addShow: false,
      form: {
        name: "",
        contentList: [
          {
            id: 1,
            content: ""
          }
        ],
        action: "",
        enabled: false
      },
      activeId: "",
      addSpeechId: 100,
      loading: false,
      spanArr: [],
      pos: 0,
    }
  },
  mounted() {
    this.inquire();
  },
  methods: {
    // 点击查询
    inquire() {
      let data = {
        dialogueId: this.activeSpeechId
      }
      this.$http("/dialogueGlobalConfig/globalConfigList",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let list = res.data;
          this.getSpanArr(list);
        }
      })
    },
    // 点击修改
    modifyClick(val) {
      this.addTitle = "修改全局配置";
      this.form.name = val.name;
      if (val.contentList) {
        this.form.contentList = [];
        val.contentList.forEach((item,index) => {
          this.form.contentList.push({
            id: index + 1,
            content: item
          })
        })
      }
      this.form.action = val.action;
      this.form.enabled = val.enabled;
      this.activeId = val.id;
      this.addShow = true;
    },
    // 确定新增
    dialog_submit() {
      let data = {
        id: this.activeId,
        dialogueId: this.activeSpeechId,
        contentList: [],
        action: this.form.action,
        enabled: this.form.enabled,
      }
      this.form.contentList.forEach(item => {
        if (item.content) {
          data.contentList.push(item.content);
        }
      });
      if (data.contentList.length < 1) {
        this.$message.warning("请输入机器人话术");
        return 
      }
      if (!data.action) {
        this.$message.warning("请选择执行动作");
        return 
      }
      this.loading = true;
      this.$service("/dialogueGlobalConfig/updateGlobalConfig",data).then(res => {
        this.loading = false;
        if (res.result === 200) {
          this.$message.success('修改成功');
          this.addShow = false;
          this.inquire();
        }
      }).catch(err => {
        this.loading = false;
      })
    },
    // 关闭全局配置
    addClose() {
      this.form = {
        name: "",
        contentList: [
          {
            id: 1,
            content: ""
          }
        ],
        action: "",
        enabled: false
      }
    },
    // 点击新增话术
    addSpeechClick() {
      if (this.form.contentList.length >= 3) {
        this.$message.warning("已达到3个上限，请删除重试")
        return
      }
      this.addSpeechId++;
      let obj = {
        id: this.addSpeechId,
        content: ""
      }
      this.form.contentList.push(obj);
    },
    // 点击删除话术
    deleteSpeechClick(val) {
      let index = -1;
      index = this.form.contentList.indexOf(val);
      if (index !== -1) {
        this.form.contentList.splice(index, 1);
      }
    },
    // 点击启用or禁用
    enableClick(val) {
      let str = "启用";
      if (val.enabled) {
        str = "禁用";
      }
      this.$confirm("确定"+str+"【"+ val.name +"】?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: val.id,
            enabled: !val.enabled
          };
          this.$service("/dialogueGlobalConfig/enableGlobalConfig", data).then((res) => {
            if (res.result === 200) {
              this.$message.success(str+"成功");
              this.inquire();
            }
          });
        })
        .catch(() => {});
    },
    // 此函数是实现合并随意行数的功能
    getSpanArr(data) {
      let list = [];
      data.forEach(item => {
        item.contentLabel = "";
        item.actionLabel = "挂机";
        if (item.contentList) {
          item.contentList.forEach((menu,index) => {
            let obj = this.$common.deepClone(item);
            let length = item.contentList.length;
            (index+1) < length
              ? obj.actionLabel = "等待用户回复"
              : obj.actionLabel = "挂机";
            obj.contentLabel = menu;
            list.push(obj);
          })
        } else {
          list.push(item);
        }
      })
      this.tableListData = list;
      this.spanArr = []; // 合并行数组
      for (var i = 0; i < list.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (list[i].code === list[i - 1].code) {
            // 如果operationalUsersGroupName相等就累加，并且push 0
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // columnIndex === 0 找到第一列，实现合并随机出现的行数
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      // columnIndex === 1 找到第二列，实现合并随机出现的行数
      if (columnIndex === 1) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      // columnIndex === 4 找到第五列，实现合并随机出现的行数
      if (columnIndex === 4) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      // columnIndex === 5 找到第六列，实现合并随机出现的行数
      if (columnIndex === 5) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
}
</script>
<style scoped>
.globalConfig {
  padding: 15px;
  height: 100%;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
</style>