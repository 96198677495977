<template>
  <div class="recording-down">
    <div class="projectStatus">
      <div class="search">
        客户姓名：
        <el-input
          v-model="condition.name"
          placeholder="请输入客户姓名"
          clearable
        ></el-input>
      </div>
      <div class="search">
        手机号：
        <el-input
          v-model="condition.phone"
          placeholder="请输入手机号"
          clearable
        ></el-input>
      </div>
      <div class="search">
        招呼语：
        <el-input
          v-model="condition.addFansWord"
          placeholder="请输入招呼语"
          clearable
        ></el-input>
      </div>
    </div>
    <div class="projectStatus">
      <div class="search">
        微信昵称：
        <el-input
          v-model="condition.wechatNick"
          placeholder="请输入微信昵称"
          clearable
        ></el-input>
      </div>
      <div class="search">
        创建时间：
        <el-date-picker
          v-model="condition.gmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
        ></el-date-picker>
      </div>
    </div>
    <div class="projectStatus">
      <div class="search">
        标签：
        <el-select
          v-model="condition.mark"
          filterable
          placeholder="请选择标签"
          style="width: 260px"
          multiple
          clearable
        >
          <el-option
            v-for="item in markList"
            :key="item.value"
            :label="item.key"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="inquire()">查询</el-button>
      <el-button type="primary" @click="uploadFiles('file')"
        >招呼语上传</el-button
      >
      <el-button type="primary" @click="uploadFiles('file1')">打标</el-button>
    </div>

    <!-- 列表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingList"
    >
      <el-table-column
        prop="id"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerCode"
        label="手机号"
        width="120"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="name"
        label="客户名称"
        width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="douyinNick"
        label="抖音昵称"
        width="150"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="totalOrderCount"
        label="总单量"
        width="100"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="addFansWord"
        label="招呼语"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="mark"
        label="标签"
        width="100"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="wechatState"
        label="加粉状态"
        width="170"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.statusType" size="small">{{
            scope.row.statuslabel
          }}</el-tag>
          <!-- <el-button
            type="text"
            v-if="scope.row.status === 'SUBMITTED'"
            @click="refreshStatus(scope.row)"
            >刷新</el-button
          > -->
        </template>
      </el-table-column>
      <el-table-column
        prop="wechatNick"
        label="微信昵称"
        width="100"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="190"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        prop
        label="操作"
        width="70"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="downRecord(scope.row, scope.$index)"
            :disabled="scope.row.downDis"
            >下载</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 上传文件 -->
    <el-dialog
      :title="uploadDataTitle"
      :visible.sync="uploadFilesShow"
      custom-class="el-modifyLabelShow"
      width="450px"
      @close="uploadFilesClose"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ uploadDataTitle }}</span>
      </template>
      <div style="width: 360px; margin: 0 auto">
        <el-upload
          class="upload-demo"
          drag
          :data="addUploadData"
          :action="addUrl"
          :on-success="successNumberUpload"
          :on-error="errorNumberUpload"
          ref="addUpload"
          :auto-upload="false"
          :file-list="fileList"
          :with-credentials="cookie"
          :limit="1"
          :on-exceed="handleExceed"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <div style="margin-left: 20px">
        <a
          v-if="aDemo == 'file'"
          style="color: #73a8fc"
          href="https://file.huiyu.org.cn/file/downloadShareFile?uuid=ddb02209-543a-46c7-9210-f923dc74d826&checkUuid=7WVmM4ktjc
"
          >demo下载</a
        >
        <a
          v-if="aDemo == 'file1'"
          style="color: #73a8fc"
          href="https://file.huiyu.org.cn/file/downloadShareFile?uuid=a0047860-48b7-4248-b42f-948cf3e1ae54&checkUuid=KRj54B5z7H
"
          >demo下载</a
        >
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button
            type="primary"
            @click="addLabelFile_submit()"
            :loading="uploading"
            >提 交</el-button
          >
          <el-button @click="addLabelFile_cancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploading: false,
      tableData: [],
      currentPage: null,
      total: null,
      pageSize: 10,
      condition: {
        phone: "",
        name: "",
        wechatNick: "",
        addFansWord: "",
        gmtCreatedDate: [],
        mark: [],
      },
      loadingList: false,
      uploadDataTitle: "",
      uploadFilesShow: false,
      addUploadData: {},
      addUrl: this.$constants.baseURL + "/liquor/addFansWordUpload",
      fileList: [],
      cookie: true,
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      markList: [],
      aDemo: "",
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-白酒订单";
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreatedDate = [start, end];
    this.inquire(1);
    this.getMarkList();
  },
  methods: {
    // 查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let params = {
        pageNo: index || this.currentPage,
        pageSize: this.pageSize,
        phone: this.condition.phone,
        name: this.condition.name,
        wechatNick: this.condition.wechatNick,
        mark: this.condition.mark.join(","),
        addFansWord: this.condition.addFansWord,
        startTime: gmtCreatedStart,
        endTime: gmtCreatedEnd,
      };
      this.recordInquire(params);
    },
    // 查询白酒客户
    recordInquire(params) {
      this.loadingList = true;
      this.$http("/liquor/userPage", null, "get", null, params).then((res) => {
        setTimeout(() => {
          this.loadingList = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.records;
          this.total = res.data.total;
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            switch (item.wechatState) {
              case "CREATING":
                item.statusType = "warning";
                item.statuslabel = "请求发送中";
                break;
              case "SUBMITTED":
                item.statusType = "warning";
                item.statuslabel = "已提交好友申请";
                break;
              case "NOT_FOUND":
                item.statusType = "danger";
                item.statuslabel = "未查询到好友";
                break;
              case "SUBMIT_FAILURE":
                item.statusType = "danger";
                item.statuslabel = "好友申请异常";
                break;
              case "EXPIRED":
                item.statusType = "danger";
                item.statuslabel = "好友申请已过期";
                break;
              case "RELOGIN":
                item.statusType = "danger";
                item.statuslabel = "登录失效";
                break;
              case "ADDEDFRIEND":
                item.statusType = "success";
                item.statuslabel = "已经是好友";
                break;
              case "PASSED":
                item.statusType = "success";
                item.statuslabel = "通过好友申请";
                break;
              case "CLIENT_OFFLINE":
                item.statusType = "danger";
                item.statuslabel = "手机离线";
                break;
              case "NOTLOGGEDIN":
                item.statusType = "danger";
                item.statuslabel = "未登录";
                break;
              case "NO_REQUEST":
                item.statusType = "warning";
                item.statuslabel = "未加微信";
                break;
              // yc非法状态
              case "CUSTOMER_ACCOUNT_IS_ILLEGAL":
                item.statusType = "warning";
                item.statuslabel = "对方账号异常";
                break;
              case "IN_QUEUE":
                item.statusType = "warning";
                item.statuslabel = "排队中";
                break;
              case "DISCARDED":
                item.statusType = "warning";
                item.statuslabel = "已丢弃";
                break;
              default:
                item.statusType = "warning";
                item.statuslabel = "未知";
                break;
            }
          });
          this.tableData = list;
        }
      });
    },
    // 分页显示条数
    sizeChange(index) {
      this.pageSize = index;
      this.currentPage = 1;
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: 1,
        phone: this.condition.phone,
        name: this.condition.name,
        wechatNick: this.condition.wechatNick,
        mark: this.condition.mark.join(","),
        addFansWord: this.condition.addFansWord,
        startTime: gmtCreatedStart,
        endTime: gmtCreatedEnd,
      };
      this.recordInquire(params);
    },
    // 当前页
    currentPage_data(index) {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: index,
        phone: this.condition.phone,
        name: this.condition.name,
        wechatNick: this.condition.wechatNick,
        mark: this.condition.mark.join(","),
        addFansWord: this.condition.addFansWord,
        startTime: gmtCreatedStart,
        endTime: gmtCreatedEnd,
      };
      this.recordInquire(params);
    },
    // 点击上传文件
    uploadFiles(type) {
      this.uploadFilesShow = true;
      this.aDemo = type;
      if (type === "file") {
        this.uploadDataTitle = "上传文件";
        this.addUrl = this.$constants.baseURL + "/liquor/addFansWordUpload";
      }
      if (type === "file1") {
        this.uploadDataTitle = "上传文件";
        this.addUrl = this.$constants.baseURL + "/liquor/userMarkUpload";
      }
    },
    // 关闭上传文件
    uploadFilesClose() {
      this.$refs.addUpload.clearFiles();
      this.uploading = false;
    },
    //文件上传成功
    successNumberUpload(res, file, fileList) {
      this.$refs.addUpload.clearFiles();
      if (res.result == 200) {
        this.uploading = false;
        this.$message({ message: "文件上传成功！", type: "success" });
        this.addLabelFile_cancel();
        this.inquire(1);
      }
    },
    //文件上传失败
    errorNumberUpload(err, file, fileList) {
      let message = JSON.parse(err.message);
      this.uploading = false;
      this.$message.warning(message.message);
    },
    //文件上传提交
    addLabelFile_submit() {
      this.uploading = true;
      this.$refs.addUpload.submit();
    },
    //文件上传取消
    addLabelFile_cancel() {
      this.uploading = false;
      this.uploadFilesShow = false;
    },
    //限制上传文件数量提示
    handleExceed(files, fileList) {
      this.uploading = false;
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 查询标签数据
    getMarkList() {
      this.$http("/liquor/markList", null, "get").then((res) => {
        if (res.result === 200) {
          this.markList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.projectStatus {
  margin-bottom: 20px;
}
.projectStatus .search {
  display: inline-block;
  margin-right: 30px;
}
.projectStatus .el-input {
  /* margin-left: 20px; */
  width: 260px;
}
</style>
