import { render, staticRenderFns } from "./strategyManage.vue?vue&type=template&id=217d76c1&scoped=true&"
import script from "./strategyManage.vue?vue&type=script&lang=js&"
export * from "./strategyManage.vue?vue&type=script&lang=js&"
import style0 from "./strategyManage.vue?vue&type=style&index=0&id=217d76c1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "217d76c1",
  null
  
)

export default component.exports