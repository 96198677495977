<template>
  <div class="userManagement">
    <el-table :data="tableData" stripe style="width: 100%" border>
      <el-table-column type='index' prop="" label="编号" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="服务器地址" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="修改信息" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="企业名称" width="" align="center"></el-table-column>
      <el-table-column prop="" label="联系电话" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="400号码" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="呼入目的号码" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="呼出主叫号码" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="呼出主叫限制前缀" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="外呼队列目的码" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="外呼地区限制码" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="终端数" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="权限" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="状态" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="录音" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="短信" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="呼入优先接听" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="外呼队列" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="外呼队列主叫透传" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="坐席呼入直通" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="通话记录" width="60" align="center"></el-table-column>
      <el-table-column prop="" label="项目统计" width="60" align="center"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination @current-change="currentPage_data" :current-page.sync="currentPage" :page-size='10'
      layout="prev, pager, next,jumper" :total="total" :pager-count='5'></el-pagination>
  </div>
</template>

<script>
  export default {
    name: 'UserManagement',
    data() {
      return {
        msg: '用户管理',
        tableData: null,
        currentPage: null,
        total: null
      }
    },
    mounted() {
      this.inquire(1);
    },
    methods: {
      currentPage_data(index) {//当前页
        this.inquire(index);
      },
      inquire(index) {//查询列表				
        let url = null,
          params = {
            pageSize: 10,
            pageNo: this.currentPage
          }
        this.$http('/sip/client', null, 'get', url, params).then(res => {
          if (res.result == 200) {
            let list = res.data.list;
            this.total = res.data.count;
            this.tableData = list;
          }

        })
      },
    }
  }
</script>

<style scoped="scoped">
  .el-table {
    margin: 30px 0px;
  }

  .el-pagination {
    float: right;
    text-align: right;
  }
</style>