<template>
  <div class="requestLogList">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="接口调用" name="first">
        <div class="nav">
          <div class="batchName">
            微信账号：
            <el-input
              v-model="condition.wechatAccount"
              placeholder="请输入微信账号"
              clearable
            ></el-input>
          </div>
          <div class="batchName">
            来源：
            <i style="margin-left: 17px"></i>
            <el-select
              v-model="condition.source"
              clearable
              placeholder="请选择来源"
            >
              <el-option
                v-for="item in sourceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="callTime">
            调用时间：
            <i style="margin-left: 16px"></i>
            <el-date-picker
              v-model="condition.gmtCreatedDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              align="right"
            ></el-date-picker>
          </div>
        </div>
        <div class="nav">
          <div class="batchName">
            机器编号：
            <el-select
              v-model="condition.clientCode"
              clearable
              @focus="clientNickNameFocus"
              placeholder="请选择机器编号"
              filterable
            >
              <el-option
                v-for="item in wechatClientList"
                :key="item.id"
                :label="item.codeOrName"
                :value="item.clientCode"
              ></el-option>
            </el-select>
          </div>
          <div class="batchName">
            服务器：
            <i style="margin-left: 2px"></i>
            <el-select
              v-model="wechatServerLabel"
              placeholder="请选择服务器"
              @change="wechatClientGroupFocus(wechatServerLabel, 'query')"
              clearable
            >
              <el-option
                v-for="item in wechatServerList"
                :key="item.server"
                :label="item.server"
                :value="item.server"
              >
              </el-option>
            </el-select>
          </div>
          <div class="batchName">
            机器分组：
            <el-select
              v-model="wechatGroupName"
              placeholder="请选择机器分组"
              clearable
            >
              <el-option
                v-for="item in wechatClientGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="nav">
          <div class="batchName">
            错误信息：
            <el-select
              v-model="condition.errorMessage"
              clearable
              placeholder="请选择错误信息"
            >
              <el-option
                v-for="item in errorMessageList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="batchName">
            状态<el-tooltip class="item" effect="light" placement="top-start">
              <div slot="content">
                <div>
                  登录失效:
                  实际上已经登录失效了(在其他手机登录，封号，登录过期)，但是页面还没有刷新(点击任何地方都会立即退出登录)
                </div>
                <div>未登陆: 开机后，直接显示微信未登陆</div>
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
            <i style="margin-left: 2px"></i>
            <el-select
              v-model="condition.status"
              clearable
              placeholder="请选择状态"
              @change="statusChange"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in wechatStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="batchName">
            好友验证：
            <el-select
              v-model="autoPassed"
              placeholder="请选择好友验证类型"
              clearable
            >
              <el-option
                v-for="item in autoPassedList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="operate">
          <el-button type="primary" @click="inquire(1)">查询</el-button>
          <el-button type="primary" @click="reAdd">重新添加</el-button>
        </div>
        <!-- 数据统计 -->
        <!-- <div class="data_style">
          <div class="data_item">
            (全部)今日招呼数: {{ dailyHelloCount }} (近1小时:
            {{ recentHelloCount }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            今日通过数: {{ dailyPassCount }} (近1小时: {{ recentPassCount }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            今日通过率: {{ dailyHelloRate }} (近1小时: {{ recentHelloRate }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            未查询到好友比率: {{ dailyNotFoundRate }} (近1小时:
            {{ recentNotFoundRate }})
          </div>
        </div>
        <div class="data_style" style="margin-top: 5px">
          <div class="data_item">
            (挂机)今日招呼数: {{ dailyHelloCountHangupWechat }} (近1小时:
            {{ recentHelloCountHangupWechat }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            今日通过数: {{ dailyPassCountHangupWechat }} (近1小时:
            {{ recentPassCountHangupWechat }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            今日通过率: {{ dailyHelloRateHangupWechat }} (近1小时:
            {{ recentHelloRateHangupWechat }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            未查询到好友比率: {{ dailyNotFoundRateHangupWechat }} (近1小时:
            {{ recentNotFoundRateHangupWechat }})
          </div>
        </div>
        <div class="data_style" style="margin-top: 5px">
          <div class="data_item">
            (人工)今日招呼数: {{ dailyHelloCountClientTaking }} (近1小时:
            {{ recentHelloCountClientTaking }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            今日通过数: {{ dailyPassCountClientTaking }} (近1小时:
            {{ recentPassCountClientTaking }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            今日通过率: {{ dailyHelloRateClientTaking }} (近1小时:
            {{ recentHelloRateClientTaking }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            未查询到好友比率: {{ dailyNotFoundRateClientTaking }} (近1小时:
            {{ recentNotFoundRateClientTaking }})
          </div>
        </div>
        <div class="data_style" style="margin-top: 5px">
          <div class="data_item">
            (机器人)今日招呼数: {{ dailyHelloCountRobot }} (近1小时:
            {{ recentHelloCountRobot }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            今日通过数: {{ dailyPassCountRobot }} (近1小时:
            {{ recentPassCountRobot }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            今日通过率: {{ dailyHelloRateRobot }} (近1小时:
            {{ recentHelloRateRobot }})
            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="data_item">
            未查询到好友比率: {{ dailyNotFoundRateRobot }} (近1小时:
            {{ recentNotFoundRateRobot }})
          </div>
        </div> -->
        <!-- 接口调用列表 -->
        <el-table
          :data="requestLogTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="loadingTable"
        >
          <el-table-column
            prop="id"
            label="编号"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="clientCode"
            label="机器编号"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="clientNumber"
            label="坐席编号"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="customerCode"
            label="客户编号"
            width="165"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.customerCode }}</span>
              <el-button
                type="text"
                size="small"
                @click="associatedRecords(scope.row)"
                >关联记录</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="wechatAccount"
            label="微信账号"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="wechatServer"
            label="服务器"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="wechatGroupName"
            label="机器分组"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="source"
            label="来源"
            min-width="120"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.source === 'HANGUP_WECHAT'">挂机微信</span>
              <span v-if="scope.row.source === 'CLIENT_TALKING_WECHAT'"
                >坐席沟通</span
              >
              <span v-if="scope.row.source === 'HANGUP_SMS_CLICK_WECHAT'"
                >挂机短信点击</span
              >
              <span v-if="scope.row.source === 'MANUEL_WECHAT'">手动加粉</span>
              <span v-if="scope.row.source === 'FLAG_WECHAT'">盲加家长粉</span>
              <span v-if="scope.row.source === 'ROBOT_WECHAT'">AI机器人</span>
              <span v-if="scope.row.source === 'UN_FLAG_WECHAT'">YX加粉</span>
              <span v-if="scope.row.source === 'NOTIFY_WECHAT'">二次加粉</span>
              <span v-if="scope.row.source === 'SMS_CLICK_WECHAT'"
                >短信回调加粉</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag :type="scope.row.statusType" size="small">{{
                scope.row.statuslabel
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="autoPassed"
            label="好友验证"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.autoPassed == 0" type="info" size="small"
                >未知</el-tag
              >
              <el-tag v-if="scope.row.autoPassed == 2" size="small"
                >未开启好友验证</el-tag
              >
              <el-tag
                v-if="scope.row.autoPassed == 1"
                type="success"
                size="small"
                >已开启好友验证</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="errorMessage"
            label="错误信息"
            width="150"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="调用时间"
            width="170"
            align="center"
          ></el-table-column>
          <!-- <el-table-column prop label="操作" width="325" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" v-if="scope.row.talking" disabled>查看问卷</el-button>
              <el-button type="text" size="small" v-if="scope.row.talking" disabled>播放录音</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <my-pagination
          totalShow
          onePageNoShow
          pageSizeShow
          :tableList="requestLogTableData"
          @inquireTotal="inquire(null, 'total')"
          @inquireOne="inquire(1)"
          @currentPageNo="currentPageNo"
          @currentPageSize="pageSizeChange"
          class="el-pagination"
        ></my-pagination>
      </el-tab-pane>
      <el-tab-pane label="接口调用统计" name="firstAdd">
        <interFaceStatistics
          v-if="interFaceStatisticsShow"
        ></interFaceStatistics>
      </el-tab-pane>
      <el-tab-pane label="手动加粉" name="second">
        <div class="operate">
          <el-button type="primary" @click="inquireTask(1)">查询</el-button>
          <el-button type="primary" @click="uploadNumber"
            >上传客户编号</el-button
          >
          <el-button type="primary" @click="greetingClick"
            >招呼语加粉</el-button
          >
        </div>
        <!-- 手动上传列表 -->
        <el-table
          :data="taskTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="loadingTable"
        >
          <el-table-column
            prop="id"
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="taskName"
            label="任务名称"
            min-width="120"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="wechatServer"
            label="服务器"
            min-width="120"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="wechatGroupName"
            label="机器分组"
            min-width="120"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="statusLabel"
            label="状态"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag size="small" :type="scope.row.color">{{
                scope.row.statusLabel
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="totalCount"
            label="进度"
            width="130"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="planClick(scope.row)">
                {{ scope.row.processCount }}/{{ scope.row.totalCount }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="processCount"
            label="成功率"
            width="100"
            align="center"
          >
            <template slot="header">
              <span>成功率</span>
              <el-tooltip
                class="item"
                effect="light"
                content="接口成功数 / 处理个数"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.successPercentage }}
            </template>
          </el-table-column>
          <el-table-column
            prop="successCount"
            label="招呼率"
            width="100"
            align="center"
          >
            <template slot="header">
              <span>招呼率</span>
              <el-tooltip
                class="item"
                effect="light"
                content="招呼数 / 接口成功数"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ scope.row.chatCountPercentage }}
            </template>
          </el-table-column>
          <el-table-column
            prop="submitter"
            label="操作者"
            width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="submitTime"
            label="上传时间"
            width="180"
            align="center"
          ></el-table-column>
          <el-table-column
            prop
            label="操作"
            width="200"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="clickSuspend(scope.row)"
                :disabled="scope.row.isSuspend"
                >暂停</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="clickContinue(scope.row)"
                :disabled="scope.row.isContinue"
                >继续</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="clickStrategy(scope.row)"
                :disabled="
                  scope.row.isStrategy || scope.row.taskType == 'GREETING_TYPE'
                "
                >策略</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="clickDelete(scope.row)"
                style="color: red"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="taskPageSizeChange"
          @current-change="currentTaskPageNo"
          :current-page.sync="taskPageNo"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="taskTotal"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="机器人加粉" name="three">
        <robotAddPowder v-if="robotAddPowderShow"></robotAddPowder>
      </el-tab-pane>
      <el-tab-pane
        label="机器人加粉统计"
        name="threeAdd"
        v-if="taskOtherOperationShow('/robot/addFansReportList')"
      >
        <robotStatustucs v-if="robotStatustucsShow"></robotStatustucs>
      </el-tab-pane>
      <el-tab-pane label="批量打标" name="four">
        <wechatMark v-if="wechatMarkShow"></wechatMark>
      </el-tab-pane>

      <el-tab-pane label="打标任务记录" name="five">
        <wechatMarkRecord v-if="wechatMarkRecordShow"></wechatMarkRecord>
      </el-tab-pane>
    </el-tabs>
    <!-- 重新添加 -->
    <el-dialog
      title="重新添加"
      :visible.sync="reAddShow"
      width="400px"
      @close="reAddClose"
    >
      <template slot="title">
        <i class="el-icon-warning dialog-title-my" style="color: #e6a23c"></i>
        <span class="dialog-title-my my-title">重新添加</span>
      </template>
      <el-form label-width="100px" label-position="left">
        <el-form-item label="服务器：">
          <el-select
            v-model="wechatClientGroupIdServer"
            placeholder="请选择服务器"
            style="width: 95%"
            @change="wechatClientGroupFocus(wechatClientGroupIdServer, 'query')"
          >
            <el-option
              v-for="item in wechatServerList"
              :key="item.server"
              :label="item.server"
              :value="item.server"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机器分组：">
          <el-select
            v-model="addWechatClientGroupId"
            placeholder="请选择机器分组"
            style="width: 95%"
            clearable
          >
            <el-option
              v-for="item in wechatClientGroupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.groupName"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="color: #e6a23c">1. 将会重新添加 {{ total }} 个好友</div>
      <div style="color: #e6a23c">
        2. 只有申请异常和申请过期的状态可以重新添加
      </div>
      <!-- <div style="color:#E6A23C;">3. 添加使用原记录的参数(服务器、招呼语、分组id)</div> -->
      <div style="color: #e6a23c">3. 两次添加会间隔10秒</div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="reAdd_submit"
          :disabled="reAddDisabled"
          >确定</el-button
        >
        <el-button @click="reAddShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 上传客户编号 -->
    <el-dialog
      title="上传客户编号"
      :visible.sync="uploadNumberShow"
      width="500px"
      @close="uploadNumberClose"
    >
      <template slot="title">
        <i class="el-icon-warning dialog-title-my" style="color: #e6a23c"></i>
        <span class="dialog-title-my my-title">{{ clientTitle }}</span>
      </template>
      <el-form label-width="140px" label-position="left">
        <el-form-item label="任务名称：" required v-if="clientType">
          <el-input
            v-model="taskName"
            placeholder="请输入任务名称"
            style="width: 95%"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="服务器：" required v-if="clientType">
          <el-select
            v-model="wechatClientGroupIdServer"
            placeholder="请选择服务器"
            style="width: 95%"
            @change="wechatClientGroupFocus(wechatClientGroupIdServer, 'query')"
          >
            <el-option
              v-for="item in wechatServerList"
              :key="item.server"
              :label="item.server"
              :value="item.server"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机器分组：" required v-if="clientType">
          <el-select
            v-model="addWechatClientGroupId"
            placeholder="请选择机器分组"
            style="width: 95%"
            clearable
          >
            <el-option
              v-for="item in wechatClientGroupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="间隔时间(秒)：" required v-if="clientType">
          <el-input
            v-model="timeInterval"
            placeholder="请输入时间间隔"
            style="width: 95%"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传号码：">
          <template slot="label">
            上传表格文件
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                数据格式：表格第二行开始， <br />
                第一列：客户编号；
                <br />
                第二列：机器分组；
                <br />
                第三列：招呼语
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-upload
            class="upload-demo"
            drag
            :data="numberUploadData"
            :action="numberUrl"
            :on-success="successNumberUpload"
            :on-error="errorNumberUpload"
            ref="numberUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>

        <div>
          <a
            href="https://file.huiyu.org.cn/file/downloadShareFile?uuid=bb6345c8-cf6e-45c2-9991-ea0b58551cb9&checkUuid=66tdaCpHXE"
          >
            样例下载</a
          >
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="uploadNumber_submit"
          :loading="isNumberSubmit"
          >确定</el-button
        >
        <el-button @click="uploadNumberShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 修改策略 -->
    <el-dialog
      title="修改策略"
      :visible.sync="strategyShow"
      width="500px"
      @close="strategyClose"
    >
      <template slot="title">
        <i class="el-icon-warning dialog-title-my" style="color: #e6a23c"></i>
        <span class="dialog-title-my my-title">修改策略</span>
      </template>
      <el-form label-width="140px">
        <el-form-item label="机器分组：" required>
          <el-select
            v-model="addWechatClientGroupId"
            placeholder="请选择机器分组"
            style="width: 95%"
            clearable
          >
            <el-option
              v-for="item in wechatClientGroupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="间隔时间(秒)：" required>
          <el-input
            v-model="timeInterval"
            placeholder="请输入时间间隔"
            style="width: 95%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="strategy_submit"
          :loading="isNumberSubmit"
          >确定</el-button
        >
        <el-button @click="strategyShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 关联记录表格 -->
    <el-dialog
      title="关联记录"
      :visible.sync="associatedRecordsShow"
      width="1200px"
      @close="associatedRecordsClose"
      class="records_style"
    >
      <el-table
        :data="associatedRecordsData"
        stripe
        border=""
        size="small"
        v-loading="loadingRecordsTable"
      >
        <el-table-column
          type="index"
          prop
          label="编号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientCode"
          label="机器编号"
          width="90"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientNumber"
          label="坐席编号"
          width="90"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerCode"
          label="客户编号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="wechatAccount"
          label="微信账号"
          min-width="100"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="wechatServer"
          label="服务器"
          width="100"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="wechatGroupName"
          label="机器分组"
          width="100"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="source"
          label="来源"
          width="100"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.source === 'HANGUP_WECHAT'">挂机微信</span>
            <span v-if="scope.row.source === 'CLIENT_TALKING_WECHAT'"
              >坐席沟通</span
            >
            <span v-if="scope.row.source === 'HANGUP_SMS_CLICK_WECHAT'"
              >挂机短信点击</span
            >
            <span v-if="scope.row.source === 'MANUEL_WECHAT'">手动加粉</span>
            <span v-if="scope.row.source === 'FLAG_WECHAT'">盲加家长粉</span>
            <span v-if="scope.row.source === 'ROBOT_WECHAT'">AI机器人</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="120" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.statusType" size="small">{{
              scope.row.statuslabel
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="errorMessage"
          label="错误信息"
          width="150"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="gmtCreated"
          label="调用时间"
          width="145"
          align="center"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="currentRecordersPageNo"
        :current-page.sync="pageNoRecorders"
        :page-size="10"
        :pager-count="5"
        layout="total, prev, pager, next, jumper"
        :total="recordsTotal"
      ></el-pagination>
    </el-dialog>
    <!-- 手动加粉进度列 -->
    <el-dialog
      title="进度"
      :visible.sync="progressShow"
      width="800px"
      @close="progressClose"
      class="records_style"
    >
      <el-table
        :data="progressData"
        stripe
        border=""
        size="small"
        v-loading="progressLoading"
      >
        <el-table-column
          type="index"
          prop
          label="编号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerCode"
          label="客户编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="groupName"
          label="机器分组"
          width="90"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="addFansCall"
          label="招呼语"
          align="center"
        ></el-table-column>
        <el-table-column prop="status" label="状态" width="120" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.statusType" size="small">{{
              scope.row.statusLabel
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="executeTime"
          label="执行时间"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="requestStatus"
          label="加粉状态"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag :type="scope.row.requestStatusType" size="small">{{
              scope.row.requestStatusLabel
            }}</el-tag>
          </template></el-table-column
        > -->
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="currentProgressPageNo"
        :current-page.sync="pageNoProgress"
        :page-size="10"
        :pager-count="5"
        layout="total, prev, pager, next, jumper"
        :total="progressTotal"
      ></el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import myPagination from "../pages/myPagination.vue";
import interFaceStatistics from "./interFaceStatistics.vue";
import robotStatustucs from "./robotStatustucs.vue";
import robotAddPowder from "./robotAddPowder.vue";
import wechatMark from "./wechatMark.vue";
import wechatMarkRecord from "./wechatMarkRecord.vue";
export default {
  name: "requestLogList",
  components: {
    interFaceStatistics,
    robotStatustucs,
    robotAddPowder,
    wechatMark,
    wechatMarkRecord,
    myPagination,
  },
  data() {
    return {
      autoPassed: "",
      autoPassedList: [
        {
          label: "未知",
          value: "0",
        },
        {
          label: "已开启好友验证",
          value: "1",
        },
        {
          label: "未开启好友验证",
          value: "2",
        },
      ],
      wechatMarkShow: false,
      wechatMarkRecordShow: false,
      robotStatustucsShow: false,
      interFaceStatisticsShow: false,
      robotAddPowderShow: false,
      manualAsyn: null,
      taskName: "",
      taskId: "",
      strategyShow: false,
      numberUploadData: {},
      numberUrl: "",
      fileList: [],
      cookie: true,
      isNumberSubmit: false,
      uploadNumberShow: false,
      clientTitle: "",
      clientType: false,
      timeInterval: "",
      taskTableData: [],
      taskTotal: 0,
      taskPageNo: 1,
      taskPageSize: 10,
      activeName: "first",
      errorMessageList: [
        {
          label: "客服忙碌，稍后再试",
          value: "客服忙碌，稍后再试",
        },
        {
          label: "User do not exist",
          value: "User do not exist",
        },
        {
          label: "被搜帐号状态异常，无法显示",
          value: "被搜帐号状态异常，无法显示",
        },
        {
          label: "找不到相关帐号或内容",
          value: "找不到相关帐号或内容",
        },
        {
          label: "对方被加好友过于频繁，请稍后再试。",
          value: "对方被加好友过于频繁，请稍后再试。",
        },
        {
          label: "登录过期，请重新登录。",
          value: "登录过期，请重新登录。",
        },
        {
          label: "由于对方的隐私设置，你无法通过搜索微信号将其添加至通讯录。",
          value: "由于对方的隐私设置，你无法通过搜索微信号将其添加至通讯录。",
        },
        {
          label:
            "帐号状态异常，本次登录已失效。请尝试重新登录，并根据弹窗提示操作。",
          value:
            "帐号状态异常，本次登录已失效。请尝试重新登录，并根据弹窗提示操作。",
        },
        {
          label: "手动变更状态: SUBMIT_FAILURE",
          value: "手动变更状态: SUBMIT_FAILURE",
        },
        {
          label: "你的帐号违反了《微信个人帐号使用规范》，无法添加朋友。",
          value: "你的帐号违反了《微信个人帐号使用规范》，无法添加朋友。",
        },
        {
          label: "对方帐号违反了《微信个人帐号使用规范》，无法添加朋友。",
          value: "对方帐号违反了《微信个人帐号使用规范》，无法添加朋友。",
        },
        {
          label: "system error",
          value: "system error",
        },
      ],
      wechatServerLabel: "",
      wechatGroupName: "",
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
        {
          server: "gateway.bilinl.com",
        },
        {
          server: "secretno.jingtunwang.com",
        },
      ],
      wechatClientGroupIdServer: "t9.innerchic.cn",
      addWechatClientGroupId: "",
      wechatClientGroupList: [],
      reAddShow: false,
      sourceList: [
        {
          label: "挂机微信",
          value: "HANGUP_WECHAT",
        },
        {
          label: "坐席沟通",
          value: "CLIENT_TALKING_WECHAT",
        },
        {
          label: "挂机短信点击",
          value: "HANGUP_SMS_CLICK_WECHAT",
        },
        {
          label: "手动加粉",
          value: "MANUEL_WECHAT",
        },
        {
          label: "盲加家长粉",
          value: "FLAG_WECHAT",
        },
        {
          label: "YX加粉",
          value: "UN_FLAG_WECHAT",
        },
        {
          label: "AI机器人",
          value: "ROBOT_WECHAT",
        },
        {
          label: "二次加粉",
          value: "NOTIFY_WECHAT",
        },
        {
          label: "短信回调加粉",
          value: "SMS_CLICK_WECHAT",
        },
      ],
      server: "",
      condition: {
        errorMessage: "",
        wechatAccount: "",
        source: "",
        gmtCreatedDate: [],
        clientCode: "",
        status: [],
      },
      wechatClientList: [],
      wechatStatusList: [
        {
          value: "NOT_FOUND",
          label: "未查询到好友",
        },
        {
          value: "SUBMITTED",
          label: "已提交好友申请",
        },
        {
          value: "SUBMIT_FAILURE",
          label: "好友申请异常",
        },
        {
          value: "ADDEDFRIEND",
          label: "已经是好友",
        },
        {
          value: "PASSED",
          label: "通过好友申请",
        },
        {
          value: "EXPIRED",
          label: "好友申请已过期",
        },
        {
          value: "RELOGIN",
          label: "登录失效",
        },
        {
          value: "CLIENT_OFFLINE",
          label: "手机离线",
        },
        {
          value: "NOTLOGGEDIN",
          label: "未登录",
        },
        {
          value: "NO_REQUEST",
          label: "未加微信",
        },
        {
          value: "CUSTOMER_ACCOUNT_IS_ILLEGAL",
          label: "对方账号非法",
        },
        {
          value: "IN_QUEUE",
          label: "排队中",
        },
        {
          value: "DISCARDED",
          label: "已丢弃",
        },
        {
          value: "LIMIT",
          label: "添加好友过于频繁",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loadingTable: false,
      requestLogTableData: [],
      // 全部
      dailyHelloCount: "",
      recentHelloCount: "",
      dailyPassCount: "",
      recentPassCount: "",
      dailyHelloRate: "",
      recentHelloRate: "",
      dailyNotFoundRate: "",
      recentNotFoundRate: "",
      // 挂机
      dailyHelloCountHangupWechat: "",
      recentHelloCountHangupWechat: "",
      dailyPassCountHangupWechat: "",
      recentPassCountHangupWechat: "",
      dailyHelloRateHangupWechat: "",
      recentHelloRateHangupWechat: "",
      dailyNotFoundRateHangupWechat: "",
      recentNotFoundRateHangupWechat: "",
      // 人工
      dailyHelloCountClientTaking: "",
      recentHelloCountClientTaking: "",
      dailyPassCountClientTaking: "",
      recentPassCountClientTaking: "",
      dailyHelloRateClientTaking: "",
      recentHelloRateClientTaking: "",
      dailyNotFoundRateClientTaking: "",
      recentNotFoundRateClientTaking: "",
      // 机器人
      dailyHelloCountRobot: "",
      recentHelloCountRobot: "",
      dailyPassCountRobot: "",
      recentPassCountRobot: "",
      dailyHelloRateRobot: "",
      recentHelloRateRobot: "",
      dailyNotFoundRateRobot: "",
      recentNotFoundRateRobot: "",

      requestLogSummaryAsyn: null,
      callerUuid: "",
      associatedRecordsShow: false,
      associatedRecordsData: [],
      loadingRecordsTable: false,
      pageNoRecorders: 1,
      recordsTotal: 0,
      progressShow: false,
      progressData: [],
      progressLoading: false,
      pageNoProgress: 1,
      progressTotal: 0,
    };
  },
  computed: {
    reAddDisabled() {
      let abnormalStatusList = [
        "NOT_FOUND",
        "SUBMITTED",
        "ADDEDFRIEND",
        "PASSED",
      ];
      let statusList = this.condition.status;
      let res = false;
      for (let i in statusList) {
        let index = abnormalStatusList.indexOf(statusList[i]);
        if (index !== -1) {
          return true;
        } else {
          res = true;
        }
      }
      if (res && this.total > 0) {
        return false;
      }
      return true;
    },
  },
  props: ["roleMenu", "powers"],
  mounted() {
    document.title = "惠销平台管理系统-接口调用记录";
    this.theWeek();
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreatedDate = [start, end];
    this.inquire(1);
    this.getRequestLogSummary();
    this.requestLogSummaryAsyn = setInterval(() => {
      this.getRequestLogSummary();
    }, 10000);
  },
  methods: {
    // 其它操作显示
    taskOtherOperationShow(key) {
      let powers = this.powers;
      for (let i in powers) {
        let item = powers[i];
        if (item.subMenus) {
          for (let j in item.subMenus) {
            let menu = item.subMenus[j];
            let index = JSON.parse(menu.extraMsg).index;
            if (index === key) {
              return true;
            }
          }
        }
      }
      return false;
    },
    // -----------------------
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 点击关联记录
    associatedRecords(val) {
      this.callerUuid = val.callerUuid;
      this.associatedRecordsShow = true;
      this.inquireRecords(1);
    },
    // 查询关联记录
    inquireRecords(index) {
      let data = {
        pageNo: index,
        pageSize: 10,
        callerUuid: this.callerUuid,
      };
      this.loadingRecordsTable = true;
      this.$http("/wechat/relationRequestLogList", null, "get", null, data)
        .then((res) => {
          this.loadingRecordsTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.recordsTotal = res.data.total;
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              switch (item.status) {
                case "CREATING":
                  item.statusType = "warning";
                  item.statuslabel = "请求发送中";
                  break;
                case "SUBMITTED":
                  item.statusType = "warning";
                  item.statuslabel = "已提交好友申请";
                  break;
                case "NOT_FOUND":
                  item.statusType = "danger";
                  item.statuslabel = "未查询到好友";
                  break;
                case "SUBMIT_FAILURE":
                  item.statusType = "danger";
                  item.statuslabel = "好友申请异常";
                  break;
                case "EXPIRED":
                  item.statusType = "danger";
                  item.statuslabel = "好友申请已过期";
                  break;
                case "RELOGIN":
                  item.statusType = "danger";
                  item.statuslabel = "登录失效";
                  break;
                case "CLIENT_OFFLINE":
                  item.statusType = "danger";
                  item.statuslabel = "手机离线";
                  break;
                case "NOTLOGGEDIN":
                  item.statusType = "danger";
                  item.statuslabel = "未登录";
                  break;
                case "ADDEDFRIEND":
                  item.statusType = "success";
                  item.statuslabel = "已经是好友";
                  break;
                case "PASSED":
                  item.statusType = "success";
                  item.statuslabel = "通过好友申请";
                  break;
                case "NO_REQUEST":
                  item.statusType = "warning";
                  item.statuslabel = "未加微信";
                  break;
                case "CUSTOMER_ACCOUNT_IS_ILLEGAL":
                  item.statusType = "warning";
                  item.statuslabel = "对方账号非法";
                  break;
                case "IN_QUEUE":
                  item.statusType = "warning";
                  item.statuslabel = "排队中";
                  break;
                case "DISCARDED":
                  item.statusType = "warning";
                  item.statuslabel = "已丢弃";
                  break;
                default:
                  item.statusType = "warning";
                  item.statuslabel = "未知";
                  break;
              }
            });
            this.associatedRecordsData = list;
          }
        })
        .catch((err) => {
          this.loadingRecordsTable = false;
        });
    },
    // 关联记录分页
    currentRecordersPageNo(index) {
      this.inquireRecords(index);
    },
    // 关闭关联记录
    associatedRecordsClose() {
      this.associatedRecordsData = [];
    },
    // 进度弹框分页
    currentProgressPageNo(index) {
      this.getProgress(index);
    },
    // 关闭进度弹框
    progressClose() {
      this.progressData = [];
    },
    handleExceed(files, fileList) {
      //限制上传文件数量提示
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    successNumberUpload(res, file, fileList) {
      //补充号码上传成功
      this.$refs.numberUpload.clearFiles();
      if (res.result == 200) {
        this.isNumberSubmit = false;
        this.$message({ message: "文件上传成功！", type: "success" });
        this.uploadNumberShow = false;
        this.inquireTask(1);
      }
    },
    errorNumberUpload(err, file, fileList) {
      //补充号码上传失败
      this.isNumberSubmit = false;
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    // 切换tab
    handleClick() {
      if (this.activeName === "first") {
        this.interFaceStatisticsShow = false;
        this.robotStatustucsShow = false;
        this.robotAddPowderShow = false;
        this.wechatMarkShow = false;
        this.wechatMarkRecordShow = false;
        if (this.requestLogSummaryAsyn) {
          clearInterval(this.requestLogSummaryAsyn);
          this.requestLogSummaryAsyn = null;
        }
        if (this.manualAsyn) {
          clearInterval(this.manualAsyn);
          this.manualAsyn = null;
        }
        this.requestLogSummaryAsyn = setInterval(() => {
          this.getRequestLogSummary();
        }, 10000);
        this.inquire(1);
        this.getRequestLogSummary();
      }
      if (this.activeName === "second") {
        this.interFaceStatisticsShow = false;
        this.robotStatustucsShow = false;
        this.robotAddPowderShow = false;
        this.wechatMarkShow = false;
        this.wechatMarkRecordShow = false;
        if (this.requestLogSummaryAsyn) {
          clearInterval(this.requestLogSummaryAsyn);
          this.requestLogSummaryAsyn = null;
        }
        if (this.manualAsyn) {
          clearInterval(this.manualAsyn);
          this.manualAsyn = null;
        }
        this.getManualAsyn();
        this.inquireTask(1);
      }
      if (this.activeName === "firstAdd") {
        this.interFaceStatisticsShow = true;
        this.robotStatustucsShow = false;
        this.robotAddPowderShow = false;
        this.wechatMarkShow = false;
        this.wechatMarkRecordShow = false;
        if (this.requestLogSummaryAsyn) {
          clearInterval(this.requestLogSummaryAsyn);
          this.requestLogSummaryAsyn = null;
        }
        if (this.manualAsyn) {
          clearInterval(this.manualAsyn);
          this.manualAsyn = null;
        }
      }
      if (this.activeName === "threeAdd") {
        this.robotStatustucsShow = true;
        this.interFaceStatisticsShow = false;
        this.robotAddPowderShow = false;
        this.wechatMarkShow = false;
        this.wechatMarkRecordShow = false;
        if (this.requestLogSummaryAsyn) {
          clearInterval(this.requestLogSummaryAsyn);
          this.requestLogSummaryAsyn = null;
        }
        if (this.manualAsyn) {
          clearInterval(this.manualAsyn);
          this.manualAsyn = null;
        }
      }
      if (this.activeName === "three") {
        this.robotAddPowderShow = true;
        this.robotStatustucsShow = false;
        this.interFaceStatisticsShow = false;
        this.wechatMarkShow = false;
        this.wechatMarkRecordShow = false;
        if (this.requestLogSummaryAsyn) {
          clearInterval(this.requestLogSummaryAsyn);
          this.requestLogSummaryAsyn = null;
        }
        if (this.manualAsyn) {
          clearInterval(this.manualAsyn);
          this.manualAsyn = null;
        }
      }
      if (this.activeName === "four") {
        this.wechatMarkShow = true;
        this.wechatMarkRecordShow = false;
        this.robotAddPowderShow = false;
        this.robotStatustucsShow = false;
        this.interFaceStatisticsShow = false;
        if (this.requestLogSummaryAsyn) {
          clearInterval(this.requestLogSummaryAsyn);
          this.requestLogSummaryAsyn = null;
        }
        if (this.manualAsyn) {
          clearInterval(this.manualAsyn);
          this.manualAsyn = null;
        }
      }
      if (this.activeName === "five") {
        this.wechatMarkShow = false;
        this.wechatMarkRecordShow = true;
        this.robotAddPowderShow = false;
        this.robotStatustucsShow = false;
        this.interFaceStatisticsShow = false;
        if (this.requestLogSummaryAsyn) {
          clearInterval(this.requestLogSummaryAsyn);
          this.requestLogSummaryAsyn = null;
        }
        if (this.manualAsyn) {
          clearInterval(this.manualAsyn);
          this.manualAsyn = null;
        }
      }
      if (this.activeName === "six") {
        this.wechatMarkShow = false;
        this.wechatMarkRecordShow = false;
        this.robotAddPowderShow = false;
        this.robotStatustucsShow = false;
        this.interFaceStatisticsShow = false;
        if (this.requestLogSummaryAsyn) {
          clearInterval(this.requestLogSummaryAsyn);
          this.requestLogSummaryAsyn = null;
        }
        if (this.manualAsyn) {
          clearInterval(this.manualAsyn);
          this.manualAsyn = null;
        }
      }
    },
    // 招呼语加粉
    greetingClick() {
      this.uploadNumberShow = true;
      this.clientType = false;
      this.clientTitle = "招呼语加粉";
      this.wechatClientGroupFocus(this.wechatClientGroupIdServer);
    },
    // 点击上传客户编号
    uploadNumber() {
      this.uploadNumberShow = true;
      this.clientType = true;
      this.clientTitle = "上传客户编号";
      this.wechatClientGroupFocus(this.wechatClientGroupIdServer);
    },
    // 确定上传客户编号
    uploadNumber_submit() {
      if (this.clientType) {
        this.numberUrl = this.$constants.baseURL + "/wechatTask/manual/submit";
        this.numberUploadData.taskName = this.taskName;
        this.numberUploadData.wechatClientGroupId = this.addWechatClientGroupId;
        this.numberUploadData.delay = this.timeInterval;
        this.numberUploadData.type = "MANUAL_TYPE";
        if (!this.numberUploadData.taskName) {
          this.$message.warning("请输入任务名称");
          return;
        }
        if (!this.numberUploadData.wechatClientGroupId) {
          this.$message.warning("请选择机器分组");
          return;
        }
        if (!this.numberUploadData.delay) {
          this.$message.warning("请输入时间间隔");
          return;
        }
        this.isNumberSubmit = true;
        this.$nextTick(() => {
          this.$refs.numberUpload.submit();
        });
      } else {
        this.numberUrl = this.$constants.baseURL + "/wechatTask/manual/submit";
        this.numberUploadData.type = "GREETING_TYPE";
        this.isNumberSubmit = true;
        this.$nextTick(() => {
          this.$refs.numberUpload.submit();
        });
      }
    },
    // 关闭上传客户编号
    uploadNumberClose() {
      this.wechatClientGroupIdServer = "t9.innerchic.cn";
      this.addWechatClientGroupId = "";
      this.timeInterval = "";
      this.taskName = "";
      this.isNumberSubmit = false;
      this.$refs.numberUpload.clearFiles();
    },
    // 点击查询手动上传任务
    inquireTask(index) {
      if (index) {
        this.taskPageNo = index;
      }
      let data = {
        pageNo: this.taskPageNo,
        pageSize: this.taskPageSize,
      };
      this.getTaskList(data);
    },
    getTaskList(data) {
      this.loadingTable = true;
      this.$http("/wechatTask/manual/list", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.submitTime = this.$common.transformTime(item.submitTime);
              item.chatCountPercentage = "0%";
              item.successPercentage = "0%";
              if (item.successCount > 0) {
                item.chatCountPercentage =
                  (item.chatCount / item.successCount) * 100;
                item.chatCountPercentage =
                  item.chatCountPercentage.toFixed(0) + "%";
              }
              if (item.processCount) {
                item.successPercentage =
                  (item.successCount / item.processCount) * 100;
                item.successPercentage =
                  item.successPercentage.toFixed(0) + "%";
              }
              item.isSuspend = true;
              item.isContinue = true;
              item.isStrategy = false;
              switch (item.status) {
                case "INIT":
                  item.statusLabel = "创建中";
                  item.color = "warning";
                  break;
                case "SUBMITTING":
                  item.statusLabel = "执行中";
                  item.color = "warning";
                  item.isSuspend = false;
                  break;
                case "FINISH":
                  item.statusLabel = "完成";
                  item.color = "success";
                  item.isStrategy = true;
                  break;
                case "TERMINATE":
                  item.statusLabel = "服务器关闭";
                  item.color = "danger";
                  break;
                case "SUSPEND":
                  item.statusLabel = "已暂停";
                  item.color = "info";
                  item.isContinue = false;
                  break;
              }
            });
            this.taskTableData = list;
            this.taskTotal = res.data.total;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 手动上传任务--翻页
    currentTaskPageNo(index) {
      this.inquireTask(index);
    },
    // 手动上传任务--条数
    taskPageSizeChange(index) {
      this.taskPageSize = index;
      this.inquireTask(1);
    },
    // 刷新手动上传任务进度
    getManualAsyn() {
      this.manualAsyn = setInterval(() => {
        this.inquireTask();
      }, 10 * 1000);
    },
    // 点击进度
    planClick(val) {
      this.progressShow = true;
      this.taskId = val.id;
      this.getProgress(1);
    },
    // 查询进度
    getProgress(index) {
      let data = {
        pageNo: index,
        pageSize: 10,
        taskId: this.taskId,
      };
      this.$http("/wechatTask/manual/itemList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.progressTotal = res.data.total;
            list.forEach((item) => {
              switch (item.status) {
                case "INIT":
                  item.statusType = "info";
                  item.statusLabel = "初始化";
                  break;
                case "SUBMITTED":
                  item.statusType = "warning";
                  item.statusLabel = "已提交未返回";
                  break;
                case "SUBMIT_FAILURE":
                  item.statusType = "danger";
                  item.statusLabel = "提交失败";
                  break;
                case "FINISH":
                  item.statusType = "success";
                  item.statusLabel = "任务执行完成";
                  break;
              }
              switch (item.requestStatus) {
                case "CREATING":
                  item.requestStatusType = "warning";
                  item.requestStatusLabel = "添加好友中";
                  break;
                case "NOT_FOUND":
                  item.requestStatusType = "danger";
                  item.requestStatusLabel = "未查询到好友";
                  break;
                case "CUSTOMER_ACCOUNT_IS_ILLEGAL":
                  item.requestStatusType = "danger";
                  item.requestStatusLabel = "非法的微信状态";
                  break;
                case "SUBMITTED":
                  item.requestStatusType = "warning";
                  item.requestStatusLabel = "已提交好友申请";
                  break;
                case "SUBMIT_FAILURE":
                  item.requestStatusType = "warning";
                  item.requestStatusLabel = "好友申请异常";
                  break;
                case "ADDEDFRIEND":
                  item.requestStatusType = "success";
                  item.requestStatusLabel = "已经是好友";
                  break;
                case "PASSED":
                  item.requestStatusType = "success";
                  item.requestStatusLabel = "通过好友申请";
                  break;
                case "REJECTED":
                  item.requestStatusType = "danger";
                  item.requestStatusLabel = "拒绝好友申请";
                  break;
                case "EXPIRED":
                  item.requestStatusType = "danger";
                  item.requestStatusLabel = "好友申请已过期";
                  break;
                case "RELOGIN":
                  item.requestStatusType = "danger";
                  item.requestStatusLabel = "登陆失效";
                  break;
                case "NO_REQUEST":
                  item.requestStatusType = "danger";
                  item.requestStatusLabel = "未加微信";
                  break;
                case "CLIENT_OFFLINE":
                  item.requestStatusType = "danger";
                  item.requestStatusLabel = "手机离线";
                  break;
                case "NOTLOGGEDIN":
                  item.requestStatusType = "danger";
                  item.requestStatusLabel = "未登录";
                  break;
                case "DISCARDED":
                  item.requestStatusType = "warning";
                  item.requestStatusLabel = "丢弃的";
                  break;
                case "IN_QUEUE":
                  item.requestStatusType = "warning";
                  item.requestStatusLabel = "队列中";
                  break;
              }
            });
            this.progressData = list;
          }
        }
      );
    },
    // 进度状态查询

    // 点击暂停
    clickSuspend(val) {
      this.$confirm("暂停添加, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let path = "https://" + val.server + this.$constants.basePath;
          this.$service(
            "/wechatTask/suspend",
            { taskId: val.id },
            "post",
            path
          ).then((res) => {
            if (res.result === 200) {
              this.$message.success("暂停成功");
              this.inquireTask();
            }
          });
        })
        .catch(() => {});
    },
    // 点击继续
    clickContinue(val) {
      this.$confirm("继续添加, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let path = "https://" + val.server + this.$constants.basePath;
          this.$service(
            "/wechatTask/resume",
            { taskId: val.id },
            "post",
            path
          ).then((res) => {
            if (res.result === 200) {
              this.$message.success("继续成功");
              this.inquireTask();
            }
          });
        })
        .catch(() => {});
    },
    // 点击策略
    clickStrategy(val) {
      this.strategyShow = true;
      this.addWechatClientGroupId = val.wechatGroupId;
      this.timeInterval = val.delay;
      this.taskId = val.id;
      this.server = val.server;
      this.wechatClientGroupFocus(val.wechatServer);
    },
    clickDelete(val) {
      this.$confirm("确定删除" + "【" + val.taskName + "】?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let path = "https://" + val.server + this.$constants.basePath;
          this.$service(
            "/wechatTask/delete",
            { taskId: val.id },
            "post",
            path
          ).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.inquireTask();
            }
          });
        })
        .catch(() => {});
    },
    // 确定修改策略
    strategy_submit() {
      let data = {
        taskId: this.taskId,
        groupId: this.addWechatClientGroupId,
        delay: Number(this.timeInterval),
      };
      if (!data.groupId) {
        this.$message.warning("请选择机器分组");
        return;
      }
      if (!data.delay) {
        this.$message.warning("请输入时间间隔");
        return;
      }
      this.isNumberSubmit = true;
      let path = "https://" + this.server + this.$constants.basePath;
      this.$service("/wechatTask/manual/updateStrategy", data, "post", path)
        .then((res) => {
          this.isNumberSubmit = false;
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.strategyShow = false;
            this.inquireTask();
          }
        })
        .catch((err) => {
          this.isNumberSubmit = false;
        });
    },
    // 关闭策略
    strategyClose() {
      this.addWechatClientGroupId = "";
      this.wechatClientGroupList = [];
      this.timeInterval = "";
      this.server = "";
    },
    // 机器分组获取焦点
    wechatClientGroupFocus(val, sign) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      if (sign === "query") {
        this.addWechatClientGroupId = "";
        this.wechatGroupName = "";
      }
      this.getWechatClientGroupList(data);
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatClientGroupList = list;
        }
      });
    },
    // 点击重新添加
    reAdd() {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        clientCode: this.condition.clientCode,
        status: this.condition.status.join(","),
        source: this.condition.source,
        wechatAccount: this.condition.wechatAccount,
        errorMessage: this.condition.errorMessage,
        wechatServer: this.wechatServerLabel,
        wechatGroupName: this.wechatGroupName,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
        queryTotalCount: true,
      };
      this.$http("/wechat/requestLogList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            this.total = res.data.total;
            if (this.total > 0) {
              this.reAddShow = true;
              this.wechatClientGroupFocus(this.wechatClientGroupIdServer);
            } else {
              this.$message.warning("数据为空");
            }
          }
        }
      );
    },
    // 确定重新添加
    reAdd_submit() {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        clientCode: this.condition.clientCode,
        status: this.condition.status.join(","),
        source: this.condition.source,
        errorMessage: this.condition.errorMessage,
        wechatAccount: this.condition.wechatAccount,
        wechatServer: this.wechatServerLabel,
        wechatGroupName: this.wechatGroupName,
        readdWechatServer: this.wechatClientGroupIdServer,
        readdWechatGroupName: this.addWechatClientGroupId,
        totalCount: this.total,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
      };
      this.$service("/wechat/reAddCustomer", data).then((res) => {
        if (res.result === 200) {
          this.reAddShow = false;
          this.$message.success("重新添加成功");
        }
      });
    },
    // 关闭重新添加
    reAddClose() {
      this.wechatClientGroupIdServer = "t9.innerchic.cn";
      this.addWechatClientGroupId = "";
    },
    // 状态搜索选项change
    statusChange(val) {},
    // 查询接口调用统计
    getRequestLogSummary() {
      this.$http("/wechat/requestLogSummary", null, "get").then((res) => {
        if (res.result === 200) {
          this.dailyHelloCount = res.data.dailyHelloCount;
          this.recentHelloCount = res.data.recentHelloCount;
          this.dailyPassCount = res.data.dailyPassCount;
          this.recentPassCount = res.data.recentPassCount;

          this.dailyHelloCountHangupWechat =
            res.data.dailyHelloCountHangupWechat;
          this.recentHelloCountHangupWechat =
            res.data.recentHelloCountHangupWechat;
          this.dailyPassCountHangupWechat = res.data.dailyPassCountHangupWechat;
          this.recentPassCountHangupWechat =
            res.data.recentPassCountHangupWechat;

          this.dailyHelloCountClientTaking =
            res.data.dailyHelloCountClientTaking;
          this.recentHelloCountClientTaking =
            res.data.recentHelloCountClientTaking;
          this.dailyPassCountClientTaking = res.data.dailyPassCountClientTaking;
          this.recentPassCountClientTaking =
            res.data.recentPassCountClientTaking;

          this.dailyHelloCountRobot = res.data.dailyHelloCountRobot;
          this.recentHelloCountRobot = res.data.recentHelloCountRobot;
          this.dailyPassCountRobot = res.data.dailyPassCountRobot;
          this.recentPassCountRobot = res.data.recentPassCountRobot;

          if (res.data.dailyHelloCount > 0) {
            this.dailyHelloRate =
              (res.data.dailyPassCount / res.data.dailyHelloCount) * 100;
            this.dailyHelloRate = Math.round(this.dailyHelloRate) + "%";
            this.dailyNotFoundRate =
              (res.data.dailyNotFoundCount / res.data.dailyHelloCount) * 100;
            this.dailyNotFoundRate = Math.round(this.dailyNotFoundRate) + "%";
          } else {
            this.dailyHelloRate = "0%";
            this.dailyNotFoundRate = "0%";
          }
          if (res.data.recentHelloCount > 0) {
            this.recentHelloRate =
              (res.data.recentPassCount / res.data.recentHelloCount) * 100;
            this.recentHelloRate = Math.round(this.recentHelloRate) + "%";
            this.recentNotFoundRate =
              (res.data.recentNotFoundCount / res.data.recentHelloCount) * 100;
            this.recentNotFoundRate = Math.round(this.recentNotFoundRate) + "%";
          } else {
            this.recentHelloRate = "0%";
            this.recentNotFoundRate = "0%";
          }

          if (res.data.dailyHelloCountHangupWechat > 0) {
            this.dailyHelloRateHangupWechat =
              (res.data.dailyPassCountHangupWechat /
                res.data.dailyHelloCountHangupWechat) *
              100;
            this.dailyHelloRateHangupWechat =
              Math.round(this.dailyHelloRateHangupWechat) + "%";
            this.dailyNotFoundRateHangupWechat =
              (res.data.dailyNotFoundCountHangupWechat /
                res.data.dailyHelloCountHangupWechat) *
              100;
            this.dailyNotFoundRateHangupWechat =
              Math.round(this.dailyNotFoundRateHangupWechat) + "%";
          } else {
            this.dailyHelloRateHangupWechat = "0%";
            this.dailyNotFoundRateHangupWechat = "0%";
          }
          if (res.data.recentHelloCountHangupWechat > 0) {
            this.recentHelloRateHangupWechat =
              (res.data.recentPassCountHangupWechat /
                res.data.recentHelloCountHangupWechat) *
              100;
            this.recentHelloRateHangupWechat =
              Math.round(this.recentHelloRateHangupWechat) + "%";
            this.recentNotFoundRateHangupWechat =
              (res.data.recentNotFoundCountHangupWechat /
                res.data.recentHelloCountHangupWechat) *
              100;
            this.recentNotFoundRateHangupWechat =
              Math.round(this.recentNotFoundRateHangupWechat) + "%";
          } else {
            this.recentHelloRateHangupWechat = "0%";
            this.recentNotFoundRateHangupWechat = "0%";
          }

          if (res.data.dailyHelloCountClientTaking > 0) {
            this.dailyHelloRateClientTaking =
              (res.data.dailyPassCountClientTaking /
                res.data.dailyHelloCountClientTaking) *
              100;
            this.dailyHelloRateClientTaking =
              Math.round(this.dailyHelloRateClientTaking) + "%";
            this.dailyNotFoundRateClientTaking =
              (res.data.dailyNotFoundCountClientTaking /
                res.data.dailyHelloCountClientTaking) *
              100;
            this.dailyNotFoundRateClientTaking =
              Math.round(this.dailyNotFoundRateClientTaking) + "%";
          } else {
            this.dailyHelloRateClientTaking = "0%";
            this.dailyNotFoundRateClientTaking = "0%";
          }
          if (res.data.recentHelloCountClientTaking > 0) {
            this.recentHelloRateClientTaking =
              (res.data.recentPassCountClientTaking /
                res.data.recentHelloCountClientTaking) *
              100;
            this.recentHelloRateClientTaking =
              Math.round(this.recentHelloRateClientTaking) + "%";
            this.recentNotFoundRateClientTaking =
              (res.data.recentNotFoundCountClientTaking /
                res.data.recentHelloCountClientTaking) *
              100;
            this.recentNotFoundRateClientTaking =
              Math.round(this.recentNotFoundRateClientTaking) + "%";
          } else {
            this.recentHelloRateClientTaking = "0%";
            this.recentNotFoundRateClientTaking = "0%";
          }

          if (res.data.dailyHelloCountRobot > 0) {
            this.dailyHelloRateRobot =
              (res.data.dailyPassCountRobot / res.data.dailyHelloCountRobot) *
              100;
            this.dailyHelloRateRobot =
              Math.round(this.dailyHelloRateRobot) + "%";
            this.dailyNotFoundRateRobot =
              (res.data.dailyNotFoundCountRobot /
                res.data.dailyHelloCountRobot) *
              100;
            this.dailyNotFoundRateRobot =
              Math.round(this.dailyNotFoundRateRobot) + "%";
          } else {
            this.dailyHelloRateRobot = "0%";
            this.dailyNotFoundRateRobot = "0%";
          }
          if (res.data.recentHelloCountRobot > 0) {
            this.recentHelloRateRobot =
              (res.data.recentPassCountRobot / res.data.recentHelloCountRobot) *
              100;
            this.recentHelloRateRobot =
              Math.round(this.recentHelloRateRobot) + "%";
            this.recentNotFoundRateRobot =
              (res.data.recentNotFoundCountRobot /
                res.data.recentHelloCountRobot) *
              100;
            this.recentNotFoundRateRobot =
              Math.round(this.recentNotFoundRateRobot) + "%";
          } else {
            this.recentHelloRateRobot = "0%";
            this.recentNotFoundRateRobot = "0%";
          }
        }
      });
    },
    // 点击查询
    inquire(index, isTotal) {
      if (index) {
        this.pageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let data = {
        pageNo: index || this.pageNo,
        pageSize: this.pageSize,
        clientCode: this.condition.clientCode,
        status: this.condition.status.join(","),
        source: this.condition.source,
        wechatAccount: this.condition.wechatAccount,
        errorMessage: this.condition.errorMessage,
        wechatServer: this.wechatServerLabel,
        wechatGroupName: this.wechatGroupName,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
        autoPassed: this.autoPassed,
      };
      if (isTotal) {
        data.queryTotalCount = true;
      }
      this.getRequestLogList(data, isTotal);
    },
    // 查询接口调用列表
    getRequestLogList(data, isTotal) {
      if (!isTotal) {
        this.loadingTable = true;
      }
      this.$http("/wechat/requestLogList", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.total = res.data.total;
            if (isTotal) {
              this.$message.success("数据总共 " + res.data.total + " 条");
              return;
            }
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              switch (item.status) {
                case "CREATING":
                  item.statusType = "warning";
                  item.statuslabel = "请求发送中";
                  break;
                case "SUBMITTED":
                  item.statusType = "warning";
                  item.statuslabel = "已提交好友申请";
                  break;
                case "NOT_FOUND":
                  item.statusType = "danger";
                  item.statuslabel = "未查询到好友";
                  break;
                case "SUBMIT_FAILURE":
                  item.statusType = "danger";
                  item.statuslabel = "好友申请异常";
                  break;
                case "EXPIRED":
                  item.statusType = "danger";
                  item.statuslabel = "好友申请已过期";
                  break;
                case "RELOGIN":
                  item.statusType = "danger";
                  item.statuslabel = "登录失效";
                  break;
                case "CLIENT_OFFLINE":
                  item.statusType = "danger";
                  item.statuslabel = "手机离线";
                  break;
                case "NOTLOGGEDIN":
                  item.statusType = "danger";
                  item.statuslabel = "未登录";
                  break;
                case "ADDEDFRIEND":
                  item.statusType = "success";
                  item.statuslabel = "已经是好友";
                  break;
                case "PASSED":
                  item.statusType = "success";
                  item.statuslabel = "通过好友申请";
                  break;
                case "NO_REQUEST":
                  item.statusType = "warning";
                  item.statuslabel = "未加微信";
                  break;
                case "CUSTOMER_ACCOUNT_IS_ILLEGAL":
                  item.statusType = "warning";
                  item.statuslabel = "对方账号非法";
                  break;
                case "IN_QUEUE":
                  item.statusType = "warning";
                  item.statuslabel = "排队中";
                  break;
                case "DISCARDED":
                  item.statusType = "warning";
                  item.statuslabel = "已丢弃";
                  break;
                case "LIMIT":
                  item.statusType = "warning";
                  item.statuslabel = "添加好友过于频繁";
                  break;
                default:
                  item.statusType = "warning";
                  item.statuslabel = "未知";
                  break;
              }
            });
            this.requestLogTableData = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPageNo(index) {
      this.pageNo = index;
      this.inquire();
    },
    // 数量分页
    pageSizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 选择机器获取焦点
    clientNickNameFocus() {
      this.getWechatClientList();
    },
    // 查询机器列表
    getWechatClientList() {
      let data = {
        codeOrName: "",
      };
      this.wechatClientList = [];
      this.$http("/wechat/wechatClientList", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item) => {
              item.codeOrName = item.clientCode;
              if (item.clientName) {
                item.codeOrName =
                  item.clientCode + "( " + item.clientName + " )";
              }
            });
            this.wechatClientList = list;
          }
        }
      );
    },
    demoClick() {
      window.localtion.href =
        "https://file.huiyu.org.cn/file/downloadShareFile?uuid=bb6345c8-cf6e-45c2-9991-ea0b58551cb9&checkUuid=66tdaCpHXE";
    },
  },
  beforeDestroy() {
    if (this.requestLogSummaryAsyn) {
      clearInterval(this.requestLogSummaryAsyn);
      this.requestLogSummaryAsyn = null;
    }
    if (this.manualAsyn) {
      clearInterval(this.manualAsyn);
      this.manualAsyn = null;
    }
  },
};
</script>
<style scoped>
.data_style {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.nav .batchName .el-select {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.upload-demo >>> .el-upload-dragger {
  width: 304px;
}
.records_style >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
</style>
