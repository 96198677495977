<template>
  <div class="charts">
    <!-- 用户数据 -->
    <ul class="showInfo">
      <li class="iconfont icon-nv-line-customer">
        <i
          >顾客总量 <br />
          {{ customerNum }}</i
        >
      </li>
      <li class="iconfont icon-zaixianfengzhirenshu">
        <i
          >顾客峰值 <br />
          {{ top }}</i
        >
      </li>
      <li class="iconfont icon-xinguke new">
        <i
          >新增顾客 <br />
          {{ newCustomer }}</i
        >
      </li>
      <li class="iconfont icon-tingliushijian">
        <i
          >停留时间 <br />
          {{ stay }}</i
        >
      </li>
    </ul>
    <!-- 绘图-->
    <div class="passengerAnalysis" style="width: 100%">
      <b>客流分析 </b>
      <span id="trend"></span>
    </div>

    <ul class="pie">
      <li class="resideRate">
        <b>
          驻留率
          <el-tooltip class="item" effect="dark" content="" placement="right">
            <div slot="content">
              驻留率：停留30s以上的潜客数量/当天潜客总量；
            </div>
            <i class="el-icon-question editor"></i>
          </el-tooltip>
        </b>
        <span id="resideRate"></span>
      </li>
      <li class="lookBack">
        <b>
          回头率
          <el-tooltip class="item" effect="dark" content="" placement="right">
            <div slot="content">
              回头率：老客数量（老客指在今日前被探测到过的潜客在今日又被探测到了）/当天潜客总量；
            </div>
            <i class="el-icon-question editor"></i>
          </el-tooltip>
        </b>
        <span id="lookBack"></span>
      </li>
      <li class="highFrequency">
        <b>
          高频探测率
          <el-tooltip class="item" effect="dark" content="" placement="right">
            <div slot="content">
              高频探测率：探测次数大于3次潜客数量/当天总采集到的潜客数量；
            </div>
            <i class="el-icon-question editor"></i>
          </el-tooltip>
        </b>
        <span id="highFrequency"></span>
      </li>
    </ul>

    <div class="phoneBrand">
      <b>手机品牌</b>
      <span id="phoneBrand"></span>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
require("echarts/lib/chart/bar");

export default {
  name: "Charts",
  data() {
    return {
      msg: "图表展示",
      startTime: "",
      equipList: [],
      boxNumber: "",
      customerNum: "",
      top: "",
      newCustomer: "",
      stay: "",
      trendNum: [],
      trendTime: [],
      brand: [],
      lookBack: [],
      resideRate: [],
      highFrequency: [],
      show: true,
      mark: false,
    };
  },
  mounted() {
    let data = sessionStorage.getItem("res");
    data = JSON.parse(data);
    this.getEcharts_data(data);
  },
  methods: {
    getEcharts_data(val) {
      // if(val){
      let arr = val,
        customerNum = arr.TOTAL_CUSTOMER_COUNT.value.totalCount;
      this.customerNum = customerNum + "人";
      this.newCustomer =
        customerNum - arr.REBUY_RATE.value.oldCustomerCount + "人";
      this.stay =
        Math.ceil(arr.AVERAGE_STOP_SECONDS.value.averageSeconds / 60) + "分钟";
      //数据处理
      this.trendNum = [];
      this.trendTime = [];
      let trend = arr.CUSTOMER_TRADE.value.list;
      if (trend) {
        // 客流分析
        trend.map((item) => {
          this.trendNum.push(item.count); // 人数
          this.trendTime.push(item.time + "点"); // 时间
        });
      }
      let num = Math.max.apply(Math, this.trendNum),
        time;
      this.trendNum.map((item) => {
        if (item == num) {
          time = item;
        }
      });
      this.top = num + "人";

      //手机品牌
      this.brand = arr.BRAND_RATE.value.list;
      let brandName = [],
        brandData = [],
        count = 0,
        arrData = [],
        arrName = [],
        otherCount = null;
      this.brand.map((item) => {
        count += item.value;
        brandName.push(item.name);
        brandData.push(item.value);
      });
      brandData.map((item) => {
        item = (item / count).toFixed(4);
        if (eval(item) > eval(0.01) && item != "其他") {
          arrData.push(item);
          arrName.push(item);
        }
      });
      (count = 0), (brandData = arrData);
      arrData.map((item) => {
        item = item - 0;
        count += item;
      });
      count = count.toFixed(4);
      otherCount = eval(1) - eval(count);
      if (otherCount > 0) {
        //其他 概率非空
        brandData.push(otherCount);
        brandName.push("其他");
      }

      // 排序
      let i = brandData.length - 1;

      while (i > 0) {
        let pos = 0;
        for (let j = 0; j < i; j++) {
          if (brandData[j] > brandData[j + 1]) {
            pos = j;
            let tmp = brandData[j],
              other = brandName[j];
            brandData[j] = brandData[j + 1];
            brandData[j + 1] = tmp;
            brandName[j] = brandName[j + 1];
            brandName[j + 1] = other;
          }
        }

        i = pos;
      }

      //回头率
      this.lookBack = [];
      let newObj = {},
        oldObj = {};
      oldObj.value = arr.REBUY_RATE.value.rebuyRate;
      oldObj.name = "老顾客";
      newObj.value = 1 - oldObj.value;
      newObj.name = "新顾客";
      this.lookBack.push(newObj);
      this.lookBack.push(oldObj);
      //驻留率
      this.resideRate = [];
      let stayObj = {},
        noStay = {};
      stayObj.value = arr.STOP_RATE.value.stopRate;
      noStay.value = 1 - stayObj.value;
      stayObj.name = "驻留";
      noStay.name = "未驻留";
      this.resideRate.push(stayObj);
      this.resideRate.push(noStay);
      // 高频探测率
      if (arr.HIGH_RATE) {
        this.highFrequency = [];
        let Obj01 = {},
          Obj02 = {};
        Obj01.value = arr.HIGH_RATE.value.highRate;
        Obj02.value = 1 - Obj01.value;
        Obj01.name = "高频";
        Obj02.name = "非高频";
        this.highFrequency.push(Obj01);
        this.highFrequency.push(Obj02);
      } else {
        this.highFrequency = [];
        let Obj01 = {},
          Obj02 = {};
        Obj01.value = 0;
        Obj02.value = 1 - Obj01.value;
        Obj01.name = "高频";
        Obj02.name = "非高频";
        this.highFrequency.push(Obj01);
        this.highFrequency.push(Obj02);
      }

      //绘制图形
      this.drawLine();
      this.drawPie(
        "resideRate",
        this.resideRate,
        ["驻留", "未驻留"],
        "resideRate"
      );
      this.drawPie("lookBack", this.lookBack, ["新顾客", "老顾客"], "lookBack");
      this.drawPie(
        "highFrequency",
        this.highFrequency,
        ["高频", "非高频"],
        "highFrequency"
      );
      this.drawBar("phoneBrand", brandName, brandData, "phoneBrand");
      // }
    },
    drawLine() {
      // 折线图
      let dom = document.getElementById("trend"),
        parent = document.getElementsByClassName("passengerAnalysis")[0], // 父级菜单
        changeSize = function () {
          var width = getComputedStyle(parent, null)["width"];
          dom.style.width = width;
        };
      changeSize();
      let myChart = echarts.init(dom),
        option = {
          title: {},
          grid: {
            x: 60,
            y: 30,
            x2: 50,
            y2: 30,
          },
          tooltip: {
            // 提示框
            formatter: function (val) {
              return (
                val.seriesName +
                "：" +
                "<br>" +
                val.name +
                "，" +
                val.value +
                "人"
              );
            },
          },
          xAxis: {
            // x轴坐标
            type: "category",
            boundaryGap: false,
            data: this.trendTime,
            splitLine: { show: true }, //网格线
            splitArea: { show: false }, //网格区域
            name: "时间",
            nameTextStyle: {
              padding: [0, 0, -25, 0],
            },
            nameGap: 15,
            nameRotate: 0,
            axisLine: {
              show: true,
              symbol: ["none", "arrow"],
              symbolSize: [8, 8],
              symbolOffset: [0, 14],
              lineStyle: {
                color: "black",
                width: 1,
                type: "solid",
                shadowOffsetX: 8,
              },
            },
          },
          yAxis: {
            // y轴坐标
            type: "value",
            splitLine: { show: true },
            splitArea: { show: true },
            name: "人数",
            nameTextStyle: {
              padding: [0, 0, 0, -40],
            },

            nameGap: 15,
            nameRotate: 0,
            axisLine: {
              show: true,
              symbol: ["none", "arrow"],
              symbolSize: [8, 8],
              symbolOffset: [0, 14],
              lineStyle: {
                color: "black",
                width: 1,
                type: "solid",
                shadowOffsetY: -14,
              },
            },
          },
          series: [
            {
              type: "line",
              smooth: true,
              name: "客流量",
              itemStyle: { normal: { areaStyle: { type: "default" } } },
              data: this.trendNum,
            },
          ],
        };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      //图表自适应
      window.addEventListener("resize", function () {
        changeSize();
        myChart.resize();
      });
    },
    drawPie(id, data, arr, father) {
      //饼状图
      let dom = document.getElementById(id),
        parent = document.getElementsByClassName(father)[0], // 父级菜单
        changeSize = function () {
          let width = getComputedStyle(parent, null)["width"];
          dom.style.width = width;
        };
      changeSize();
      let myChart = echarts.init(dom),
        option = {
          tooltip: {
            trigger: "item",
            formatter: function (val) {
              return val.name + "：" + val.percent + "%";
            },
          },
          color: ["#6AB0B8", "#E98F6F", "#F09252", "#D53A35"],
          legend: {
            orient: "vertical",
            x: "left",
            data: arr,
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: {
                show: true,
                type: ["pie", "funnel"],
                option: {
                  funnel: {
                    x: "25%",
                    width: "50%",
                    funnelAlign: "center",
                    max: 1548,
                  },
                },
              },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          calculable: true,
          series: [
            {
              name: "百分比",
              type: "pie",
              radius: "65%",
              center: "50%",
              itemStyle: {
                normal: {
                  // 									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{//颜色渐变
                  // 										offset: 0,
                  // 										color: '#ba7ef3'
                  // 									}, {
                  // 										offset: 1,
                  // 										color: '#965df2'
                  // 									}]),
                  //
                  color: function (params) {
                    //设置颜色
                    let colorList = [
                      "#ba7ef3",
                      "#F0805A",
                      "#26C0C0",
                      "#27727B",
                    ];
                    return colorList[params.dataIndex];
                  },

                  label: {
                    show: true,
                    formatter: function (val) {
                      return val.percent + "%";
                    },
                  },
                  labelLine: { show: false },
                },
                emphasis: {
                  label: {
                    show: true,
                    position: "center",
                    textStyle: { fontSize: "25", fontWeight: "bold" },
                  },
                },
              },
              data: data,
            },
          ],
        };
      myChart.setOption(option);
      //图表自适应
      window.addEventListener("resize", function () {
        changeSize();
        myChart.resize();
      });
    },
    drawBar(selector, brandName, brandData, father) {
      // 柱状图
      let dom = document.getElementById(selector),
        parent = document.getElementsByClassName(father)[0], // 父级菜单
        changeSize = function () {
          let width = getComputedStyle(parent, null)["width"];
          dom.style.width = width;
        };
      changeSize();

      let myChart = echarts.init(dom),
        option = {
          grid: {
            x: 60,
            y: 30,
            x2: 80,
            y2: 30,
          },
          color: ["#E4B5B8"],
          tooltip: {
            trigger: "axis",
            formatter: function (val) {
              return (
                val[0].seriesName +
                "：" +
                val[0].name +
                "<br>" +
                "百分比：" +
                (val[0].data * 100).toFixed(2) +
                "%"
              );
            },
          },
          legend: {
            data: brandName,
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ["line", "bar"] },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          calculable: true,
          xAxis: [
            {
              type: "category",
              data: brandName,
              name: "手机品牌", //轴名称
              nameTextStyle: {
                padding: [0, 0, -25, 0],
              },
              axisLine: {
                //设置箭头
                show: true,
                symbol: ["none", "arrow"],
                symbolSize: [8, 8],
                symbolOffset: [0, 14],
                lineStyle: {
                  color: "black",
                  width: 1,
                  type: "solid",
                  shadowOffsetX: 8,
                },
              },
              axisTick: {
                //坐标轴 刻度
                show: true,
                inside: false,
                lengt: 3,
                lineStyle: {
                  width: 1,
                  type: "solid",
                },
              },
              axisLabel: {
                //坐标轴 标签
                show: true,
                inside: false,
                rotate: 0,
                margin: 5,
                interval: 0,
              },
              splitLine: {
                //网格线
                show: true,
              },
              splitArea: {
                show: false,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "百分比",
              nameTextStyle: {
                padding: [0, 0, 2, -40],
              },
              nameGap: 15,
              nameRotate: 0,
              axisLine: {
                show: true,
                symbol: ["none", "arrow"],
                symbolSize: [8, 8],
                symbolOffset: [0, 14],
                lineStyle: {
                  color: "black",
                  width: 1,
                  type: "solid",
                  shadowOffsetY: -8,
                },
              },
              splitLine: { show: true }, //网格线
              splitArea: { show: true }, //网格区域
            },
          ],
          series: [
            {
              name: "手机品牌",
              type: "bar",
              data: brandData,
              label: {
                normal: {
                  show: true,
                  position: "top",
                  formatter: function (val) {
                    return (val.value * 100).toFixed(2) + "%";
                  },
                  textStyle: {
                    //数值样式
                    color: "blue",
                    fontSize: 16,
                  },
                },
              },
              itemStyle: {
                normal: {
                  // 									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{//颜色渐变
                  // 										offset: 0,
                  // 										color: '#ba7ef3'
                  // 									}, {
                  // 										offset: 1,
                  // 										color: '#965df2'
                  // 									}]),
                  //
                  color: function (params) {
                    //设置颜色
                    let colorList = [
                      "#C1232B",
                      "#B5C334",
                      "#FCCE10",
                      "#E87C25",
                      "#27727B",
                      "#FE8463",
                      "#9BCA63",
                      "#FAD860",
                      "#F3A43B",
                      "#60C0DD",
                      "#D7504B",
                      "#C6E579",
                      "#F4E001",
                      "#F0805A",
                      "#26C0C0",
                    ];
                    return colorList[params.dataIndex];
                  },
                },
              },
            },
          ],
        };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        changeSize();
        myChart.resize();
      });
    },
  },
};
</script>

<style scoped="scoped">
.showInfo {
  display: flex;
  justify-content: space-between;
}
.showInfo li {
  width: 15%;
  min-width: 200px;
  line-height: 50px;
  border-radius: 3px;
  color: #34bfa3;
  box-shadow: 0px 0px 1px 1px lightgray;
}
.showInfo li:before {
  display: inline-block;
  font-size: 50px;
  margin: 10% 0 0 10%;
}
.showInfo li i {
  display: block;
  width: 45%;
  float: right;
  font-style: normal;
  color: #8f8f8f;
}

/* 绘制图形 */

.charts div,
.pie li {
  height: 380px;
  min-width: 300px;
  border-radius: 3px;
  box-shadow: 0px 0px 1px 1px lightgray;
}
.charts b {
  display: block;
  font-style: normal;
  line-height: 50px;
  color: #66b1ff;
  font-size: 18px;
  padding-left: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid lightgray;
}
.charts span {
  width: 100%;
  display: inline-block;
  height: 300px;
}
.passengerAnalysis {
  margin: 90px 0 50px 0;
}
.pie {
  display: flex;
  justify-content: space-between;
}
.pie li {
  width: 30%;
}
.phoneBrand {
  width: 100%;
  margin: 30px 0px;
}
.editor {
  color: #34bfa3;
  font-size: 14px;
}
</style>
