<template>
  <div class="recording-down">
    <div class="projectStatus">
      <div class="search">
        产品编号：
        <el-input
          v-model="condition.productCode"
          placeholder="请输入产品编号"
          clearable
        ></el-input>
      </div>
      <div class="search">
        手机号：
        <el-input
          v-model="condition.phone"
          placeholder="请输入手机号"
          clearable
        ></el-input>
      </div>
      <div class="search">
        微信昵称：
        <el-input
          v-model="condition.wechatNick"
          placeholder="请输入微信昵称"
          clearable
        ></el-input>
      </div>
    </div>
    <div class="projectStatus">
      <div class="search">
        下单时间：
        <el-date-picker
          v-model="condition.gmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          style="margin-right: 20px"
        ></el-date-picker>
      </div>
      <div class="search">
        新老客户：
        <el-select
          v-model="condition.oldFan"
          clearable
          placeholder="请选择新老客户"
          style="width: 260px"
        >
          <el-option
            v-for="item in userTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="projectStatus">
      <div class="search">
        创建时间：
        <el-date-picker
          v-model="condition.gmtCreatedDate1"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          unlink-panels
          :picker-options="pickerOptions1"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          style="margin-right: 20px"
        ></el-date-picker>
      </div>
    </div>
    <div class="projectStatus">
      <el-button type="primary" @click="inquire()">查询</el-button>
      <el-button type="primary" @click="uploadFiles('file')"
        >订单上传</el-button
      >
    </div>

    <!-- 录音列表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingList"
    >
      <!-- @cell-mouse-enter="mouseEnter" -->
      <el-table-column
        prop="id"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="shopName"
        label="店铺"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="wechatNick"
        label="微信昵称"
        min-width="150"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="nickName"
        label="粉丝昵称"
        min-width="150"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="addFansWord"
        label="招呼语"
        min-width="150"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        width="70"
        align="left"
      ></el-table-column>
      <!-- <el-table-column
        prop="statusLabel"
        label="客户信息"
        width="100"
        align="left"
      ></el-table-column> -->
      <el-table-column
        prop="productName"
        label="下单商品"
        min-width="300"
        align="left"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div class="cust-table-item">
            <span>
              {{ scope.row.productName }}
            </span>
            <a
              href="javascript:;"
              class="copy-class show-copy"
              v-clipboard:copy="scope.row.productName"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >复制</a
            >
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="statusLabel"
        label="物流单号"
        width="100"
        align="left"
      ></el-table-column> -->
      <el-table-column
        prop="customerCode"
        label="客户手机号"
        width="130"
        align="left"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.customerCode }}</span>
          <span v-if="scope.row.oldFan" style="color: red">*</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="customerName"
        label="客户名称"
        width="100"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="customerAddress"
        label="客户收货地址"
        width="300"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="productCode"
        label="产品编号"
        width="100"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="orderId"
        label="订单ID"
        width="130"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="orderTime"
        label="下单时间"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="190"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        prop
        label="操作"
        width="70"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="downRecord(scope.row, scope.$index)"
            :disabled="scope.row.downDis"
            >下载</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 上传文件 -->
    <el-dialog
      :title="uploadDataTitle"
      :visible.sync="uploadFilesShow"
      custom-class="el-modifyLabelShow"
      width="450px"
      @close="uploadFilesClose"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ uploadDataTitle }}</span>
      </template>
      <div style="width: 360px; margin: 0 auto">
        <el-upload
          class="upload-demo"
          drag
          :data="addUploadData"
          :action="addUrl"
          :on-success="successNumberUpload"
          :on-error="errorNumberUpload"
          ref="addUpload"
          :auto-upload="false"
          :file-list="fileList"
          :with-credentials="cookie"
          :limit="1"
          :on-exceed="handleExceed"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <div style="margin-left: 20px">
        <a
          style="color: #73a8fc"
          href="https://file.huiyu.org.cn/file/downloadShareFile?uuid=3e08a2dd-d59a-4006-b745-5f1f12216ad1&checkUuid=mzcLtmUqT0
"
          >demo下载</a
        >
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button
            type="primary"
            @click="addLabelFile_submit()"
            :loading="uploading"
            >提 交</el-button
          >
          <el-button @click="addLabelFile_cancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploading: false,
      tableData: [],
      currentPage: null,
      total: null,
      pageSize: 10,
      condition: {
        phone: "",
        // name: "",
        wechatNick: "",
        // addFansWord: "",
        // orderId: "",
        productCode: "",
        gmtCreatedDate: [],
        gmtCreatedDate1: [],
        oldFan: "",
      },
      loadingList: false,
      uploadDataTitle: "",
      uploadFilesShow: false,
      addUploadData: {},
      addUrl: this.$constants.baseURL + "/liquor/excelDataUpload",
      fileList: [],
      cookie: true,
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pickerOptions1: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      userTypeList: [
        {
          label: "新用户",
          value: false,
        },
        {
          label: "老用户",
          value: true,
        },
      ],
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-白酒订单";
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreatedDate1 = [start, end];
    this.inquire();
  },
  methods: {
    // 复制成功
    onCopy(e) {
      this.$message({ message: "复制成功", type: "success" });
    },
    // 复制失败
    onError(e) {
      this.$message({ message: "复制失败", type: "error" });
    },
    // 查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      let startTime = "";
      let endTime = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      if (this.condition.gmtCreatedDate1) {
        startTime = this.condition.gmtCreatedDate1[0];
        endTime = this.condition.gmtCreatedDate1[1];
      }
      let params = {
        pageNo: index || this.currentPage,
        pageSize: this.pageSize,
        phone: this.condition.phone,
        // name: this.condition.name,
        wechatNick: this.condition.wechatNick,
        // addFansWord: this.condition.addFansWord,
        // orderId: this.condition.orderId,
        productCode: this.condition.productCode,
        orderTimeStart: gmtCreatedStart,
        orderTimeEnd: gmtCreatedEnd,
        startTime: startTime,
        endTime: endTime,
        oldFan: this.condition.oldFan,
      };
      this.recordInquire(params);
    },
    // 查询白酒订单
    recordInquire(params) {
      this.loadingList = true;
      this.$http("/liquor/orderPage", null, "get", null, params).then((res) => {
        setTimeout(() => {
          this.loadingList = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.records;
          this.total = res.data.total;
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            //   item.downDis = true;
          });
          this.tableData = list;
        }
      });
    },
    // 分页显示条数
    sizeChange(index) {
      this.pageSize = index;
      this.currentPage = 1;
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      let startTime = "";
      let endTime = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      if (this.condition.gmtCreatedDate1) {
        startTime = this.condition.gmtCreatedDate1[0];
        endTime = this.condition.gmtCreatedDate1[1];
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: 1,
        phone: this.condition.phone,
        // name: this.condition.name,
        wechatNick: this.condition.wechatNick,
        // addFansWord: this.condition.addFansWord,
        // orderId: this.condition.orderId,
        productCode: this.condition.productCode,
        orderTimeStart: gmtCreatedStart,
        orderTimeEnd: gmtCreatedEnd,
        startTime: startTime,
        endTime: endTime,
        oldFan: this.condition.oldFan,
      };
      this.recordInquire(params);
    },
    // 当前页
    currentPage_data(index) {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      let startTime = "";
      let endTime = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      if (this.condition.gmtCreatedDate1) {
        startTime = this.condition.gmtCreatedDate1[0];
        endTime = this.condition.gmtCreatedDate1[1];
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: index,
        phone: this.condition.phone,
        // name: this.condition.name,
        wechatNick: this.condition.wechatNick,
        // addFansWord: this.condition.addFansWord,
        // orderId: this.condition.orderId,
        productCode: this.condition.productCode,
        orderTimeStart: gmtCreatedStart,
        orderTimeEnd: gmtCreatedEnd,
        startTime: startTime,
        endTime: endTime,
        oldFan: this.condition.oldFan,
      };
      this.recordInquire(params);
    },
    // 点击上传文件
    uploadFiles(type) {
      this.uploadFilesShow = true;
      if (type === "file") {
        this.uploadDataTitle = "上传文件";
        this.addUrl = this.$constants.baseURL + "/liquor/excelDataUpload";
      }
      // if (type === "data") {
      //   this.uploadDataTitle = "数据更新";
      //   this.addUrl =
      //     this.$constants.baseURL + "/wechat/uploadCustomerPassInfo";
      // }
    },
    // 关闭上传文件
    uploadFilesClose() {
      this.$refs.addUpload.clearFiles();
      this.uploading = false;
    },
    //文件上传成功
    successNumberUpload(res, file, fileList) {
      this.$refs.addUpload.clearFiles();
      if (res.result == 200) {
        this.$message({ message: "文件上传成功！", type: "success" });
        this.uploading = false;
        this.addLabelFile_cancel();
        this.inquire(1);
      } else {
        this.uploading = false;
      }
    },
    //文件上传失败
    errorNumberUpload(err, file, fileList) {
      let message = JSON.parse(err.message);
      this.uploading = false;
      this.$message.warning(message.message);
    },
    //文件上传提交
    addLabelFile_submit() {
      this.uploading = true;
      this.$refs.addUpload.submit();
    },
    //文件上传取消
    addLabelFile_cancel() {
      this.uploadFilesShow = false;
      this.uploading = false;
    },
    //限制上传文件数量提示
    handleExceed(files, fileList) {
      this.uploading = false;
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 鼠标移入
    // mouseEnter(row, column, cell, event) {
    //   console.log(row, column, cell, event);
    //   this.tableData.forEach((item)=>{
    //     if(row.id===item.id){

    //     }
    //   })
    // },
  },
};
</script>

<style scoped>
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.projectStatus {
  margin-bottom: 20px;
}
.projectStatus .search {
  display: inline-block;
  margin-right: 30px;
}
.projectStatus .el-input {
  /* margin-left: 20px; */
  width: 260px;
}
.projectStatus .search .el-date-editor {
  width: 360px;
}
.copy-class {
  position: absolute;
  right: 6px;
  color: #52c41a;
  display: none;
}
.cust-table-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cust-table-item:hover {
  padding-right: 30px;
}
.cust-table-item:hover .show-copy {
  display: inline-block;
}
</style>
