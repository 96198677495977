<template>
  <div class="secondTouchTemplate">
    <div class="search-m">
      <!-- <div class="search-m-1">
        名称:
        <el-input
          v-model="smsName"
          placeholder="请输入名称"
          clearable
        ></el-input>
      </div> -->
    </div>
    <div class="search-botton">
      <el-button type="primary" @click="inquire(1)">查 询</el-button>
      <el-button type="primary" @click="modifyClick('add')">新增</el-button>
    </div>
    <!-- 短信模板列表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loading"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="名称"
        width=""
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="subName"
        label="子名称"
        width=""
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="prefix"
        label="前缀"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="smsClickCallbackType"
        label="点击回调"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.smsClickCallbackType === 'ADD_WECHAT'"
            >添加微信</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="longUrl"
        label="长链"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="90"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="modifyClick('modify', scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="changePageNo"
      :current-page.sync="pageNo"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "secondTouchTemplate",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      loading: false,
      modalTltle: ""
    };
  },
  mounted() {},
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
    },
    // 翻页
    changePageNo(index) {
      this.inquire(index);
    },
    // 每页条数
    pageSizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 点击新增or编辑
    modifyClick(key, val) {
      if (key === "add") {
        this.modalTltle = "新增模板";
      }
      if (key === "modify") {
        this.modalTltle = "编辑模板";
      }
    }
  },
};
</script>
<style scoped>
.search-m {
  margin-bottom: 20px;
}
.search-m .search-m-1 {
  display: inline-block;
  width: 350px;
}
.search-m .search-m-1 .el-input {
  width: 250px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
</style>