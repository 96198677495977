<template>
  <div class="achievementList">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="业绩统计" name="ach">
        <achievementStatistics
          :roleMenu="roleMenu"
          v-if="achShow"
        >
        </achievementStatistics>
      </el-tab-pane>
      <el-tab-pane label="业绩统计汇总" name="achTotal">
        <achievementStatisticsTotal
          :roleMenu="roleMenu"
          v-if="achTotalShow"
        >
        </achievementStatisticsTotal>
      </el-tab-pane>
      <el-tab-pane label="支付统计" name="pay">
        <paymentStatistics
          :roleMenu="roleMenu"
          :loginName="loginName"
          v-if="payShow"
        ></paymentStatistics>
      </el-tab-pane>
      <el-tab-pane label="绑定关系" name="bind">
        <bindRelation
          :roleMenu="roleMenu"
          :loginName="loginName"
          v-if="bindShow"
        ></bindRelation>
      </el-tab-pane>
      <el-tab-pane label="成单量统计" name="payCount">
        <payCountStatistics
          :roleMenu="roleMenu"
          :loginName="loginName"
          v-if="payCountShow"
        ></payCountStatistics>
      </el-tab-pane>
      <el-tab-pane label="月累计目标达成" name="monthly">
        <monthCumulative
          :roleMenu="roleMenu"
          :loginName="loginName"
          v-if="monthlyShow"
        ></monthCumulative>
      </el-tab-pane>
      <el-tab-pane label="进粉量管理" name="addFans">
        <addFansMange
          :roleMenu="roleMenu"
          :loginName="loginName"
          v-if="addFansShow"
        ></addFansMange>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import achievementStatistics from "./achievementStatistics"
import achievementStatisticsTotal from "./achievementStatisticsTotal"
import paymentStatistics from "./paymentStatistics"
import bindRelation from "./bindRelation"
import payCountStatistics from "./payCountStatistics"
import monthCumulative from "./monthCumulative"
import addFansMange from "./addFansMange"
export default {
  name: "achievementList",
  props: ["roleMenu","loginName"],
  components: {
    achievementStatistics,
    achievementStatisticsTotal,
    paymentStatistics,
    bindRelation,
    payCountStatistics,
    monthCumulative,
    addFansMange
  },
  data() {
    return {
      activeName: "ach",
      achShow: false,
      achTotalShow: false,
      payShow: false,
      bindShow: false,
      payCountShow: false,
      monthlyShow: false,
      addFansShow: false,
    };
  },
  mounted() {
    this.achShow = true;
  },
  methods: {
    // 切换tab
    handleClick(val) {
      if (this.activeName === "ach") {
        this.achShow = true;
        this.payShow = false;
        this.achTotalShow = false;
        this.bindShow = false;
        this.payCountShow = false;
        this.monthlyShow = false;
        this.addFansShow = false;
      }
      if (this.activeName === "achTotal") {
        this.achShow = false;
        this.achTotalShow = true;
        this.payShow = false;
        this.bindShow = false;
        this.payCountShow = false;
        this.monthlyShow = false;
        this.addFansShow = false;
      }
      if (this.activeName === "pay") {
        this.achShow = false;
        this.achTotalShow = false;
        this.payShow = true;
        this.bindShow = false;
        this.payCountShow = false;
        this.monthlyShow = false;
        this.addFansShow = false;
      }
      if (this.activeName === "bind") {
        this.achShow = false;
        this.achTotalShow = false;
        this.payShow = false;
        this.payCountShow = false;
        this.monthlyShow = false;
        this.addFansShow = false;
        this.bindShow = true;
      }
      if (this.activeName === "payCount") {
        this.achShow = false;
        this.payShow = false;
        this.achTotalShow = false;
        this.bindShow = false;
        this.monthlyShow = false;
        this.addFansShow = false;
        this.payCountShow = true;
      }
      if (this.activeName === "monthly") {
        this.achShow = false;
        this.achTotalShow = false;
        this.payShow = false;
        this.bindShow = false;
        this.payCountShow = false;
        this.addFansShow = false;
        this.monthlyShow = true;
      }
      if (this.activeName === "addFans") {
        this.achShow = false;
        this.achTotalShow = false;
        this.payShow = false;
        this.bindShow = false;
        this.payCountShow = false;
        this.addFansShow = true;
        this.monthlyShow = false;
      }
    }
  }
};
</script>
<style scoped>
.a_nav {
  margin: 0 0px 20px 0px;
}
.a_nav .callTime {
  display: inline-block;
  margin-right: 20px;
}
.achievementList .el-pagination {
  float: right;
  margin-top: 20px;
}
.achievementList .el-table {
  margin-top: 20px;
}
.connectedTable >>> .el-dialog__body::after {
  display:block;
  content:'';
  clear:both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 0 0 20px 0;
}
</style>

