<template>
  <div class="wechatLabelItem">
    <div class="nav"></div>
    <div class="btn_nav">
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button @click="addClick('add')">新增</el-button>
      <el-checkbox v-model="enabled" class="checkbox_class"
        >过滤已禁用</el-checkbox
      >
    </div>
    <!-- 微信粉丝列表 -->
    <el-table
      :data="labelTableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="label"
        label="标签内容"
        width
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="labelRemark"
        label="标签备注"
        width
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="order"
        label="排序"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="lableType === 'LESSON'"
        prop="rateLabel"
        label="业绩系数"
        width
        align="center"
      ></el-table-column>
      <el-table-column prop="enabled" label="启用" width align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.enabled">启用</el-tag>
          <el-tag type="danger" v-if="!scope.row.enabled">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="reCalculateTime"
        label="生效时间"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="90"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="addClick('modify', scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="currentPageNo"
      :current-page.sync="pageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <!-- 新增or修改 -->
    <el-dialog
      title="新增"
      :visible.sync="addShow"
      width="500px"
      @close="addClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ addTitle }}</span>
      </template>
      <el-form label-width="140px">
        <el-form-item label="标签内容：" v-if="addFormShow" required>
          <el-input
            v-model="labelName"
            placeholder="请输入标签"
            clearable
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序：" required>
          <el-input
            v-model="order"
            placeholder="请输入排序序号"
            clearable
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="业绩系数：" required v-if="lableType === 'LESSON'">
          <el-input
            v-model="rate"
            placeholder="请输入业绩系数"
            clearable
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="生效时间：" required v-if="lableType === 'LESSON'">
          <el-date-picker
            v-model="reCalculateTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开课时间:" v-if="lableType === 'LESSON'">
          <template slot="label">
            需要开课时间
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                开启后， 在微信粉丝列表打标时, 会显示开课时间输入框
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-switch
            v-model="needOpenCourseTime"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="自动同步:" v-if="lableType === 'LESSON'">
          <template slot="label">
            订单自动同步
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                开启后, 标记此课程会自动进行业绩同步，<br />
                销售在微信粉丝列表打标时会看不到这门课程,
                也就无法手动去打标。<br />
                微信商城直接同步过来的订单都应该开启
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-switch
            v-model="autoSync"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="autoSyncChange"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="自动上报业绩:" v-if="lableType === 'LESSON'">
          <template slot="label">
            业绩自动上报
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                开启后, 如果订单自动同步开启， 业绩自动上也应该开启<br />
                如果订单自动同步关闭， 业绩自动上报开启，<br />
                表示销售在微信粉丝列表打标后， 业绩看板不会+1
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-switch
            v-model="autoUploadOrder"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled="autoUploadShow"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="标签备注：">
          <el-input
            v-model="labelRemark"
            type="textarea"
            :rows="4"
            placeholder="请输入备注"
            clearable
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="启用：" v-if="!addFormShow">
          <el-switch
            v-model="labelEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="add_submit">确定</el-button>
        <el-button @click="addShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "wechatLabelItem",
  props: ["roleMenu", "loginName", "lableType"],
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      labelTableData: [],
      loadingTable: false,
      enabled: true,
      addTitle: "",
      addShow: false,
      labelName: "",
      reCalculateTime: null,
      reCalculateTime1: null,
      labelRemark: "",
      order: "",
      rate: "",
      labelId: "",
      needOpenCourseTime: false,
      autoSync: false,
      autoUploadOrder: false,
      labelEnabled: false,
      addFormShow: false,
      autoUploadShow: false,
    };
  },
  mounted() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    start = this.$common.transformTime(start.valueOf()).substring(0, 10);
    this.reCalculateTime1 = start;
    this.inquire(1);
    // this.reCalculateTime = new Date();
  },
  methods: {
    // 点击新增or修改
    addClick(val, row) {
      this.reCalculateTime = this.reCalculateTime1;
      if (val === "add") {
        this.addTitle = "新增";
        this.addFormShow = true;
      }
      if (val === "modify") {
        this.addFormShow = false;
        this.addTitle = "修改";
        this.labelId = row.id;
        this.rate = row.rateLabel;
        this.labelName = row.label;
        this.reCalculateTime = row.reCalculateTime;
        this.order = row.order;
        this.labelRemark = row.labelRemark;
        this.labelEnabled = row.enabled;
        this.needOpenCourseTime = row.needOpenCourseTime;
        this.autoSync = row.autoSync;
        this.autoUploadOrder = row.autoUploadOrder;
        if (row.autoSync) {
          this.autoUploadShow = this.autoSync;
        }
      }
      this.addShow = true;
    },
    // 确定新增or修改
    add_submit() {
      if (this.addTitle === "新增") {
        if (!this.labelName) {
          this.$message.warning("请输入标签内容");
          return;
        }
        if (!this.order) {
          this.$message.warning("请输入排序");
          return;
        }
        if (!this.reCalculateTime) {
          this.$message.warning("请选择时间");
          return;
        }
        let data = {
          label: this.labelName,
          // reCalculateTime: this.reCalculateTime,
          order: this.order,
          labelRemark: this.labelRemark,
          category: this.lableType,
        };
        let rate = null;
        if (this.lableType === "LESSON") {
          if (this.rate !== null) {
            rate = this.rate * 100;
          }
          data.rate = rate;
          if (data.rate === null) {
            this.$message.warning("请输入业绩系数");
            return;
          }
          data.needOpenCourseTime = this.needOpenCourseTime;
          data.autoSync = this.autoSync;
          data.autoUploadOrder = this.autoUploadOrder;
          data.reCalculateTime = this.reCalculateTime;
        }
        this.$service("/wechatLabel/insertWechatLabel", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.addShow = false;
            this.inquire(1);
          }
        });
      }
      if (this.addTitle === "修改") {
        if (!this.order) {
          this.$message.warning("请输入排序");
          return;
        }
        if (!this.reCalculateTime) {
          this.$message.warning("请选择时间");
          return;
        }
        let data = {
          id: this.labelId,
          order: this.order,
          labelRemark: this.labelRemark,
          enabled: this.labelEnabled,
          needOpenCourseTime: this.needOpenCourseTime,
          reCalculateTime: this.reCalculateTime,
          autoSync: this.autoSync,
          autoUploadOrder: this.autoUploadOrder,
        };
        let rate = null;
        if (this.lableType === "LESSON") {
          if (this.rate !== null) {
            rate = this.rate * 100;
          }
          data.rate = rate;
          if (data.rate === null) {
            this.$message.warning("请输入业绩系数");
            return;
          }
          data.needOpenCourseTime = this.needOpenCourseTime;
          data.reCalculateTime = this.reCalculateTime;
          data.autoSync = this.autoSync;
          data.autoUploadOrder = this.autoUploadOrder;
        }
        this.$service("/wechatLabel/modifyWechatLabel", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.addShow = false;
            this.inquire();
          }
        });
      }
    },
    // 关闭新增or修改
    addClose() {
      this.labelName = "";
      this.reCalculateTime = "";
      this.order = "";
      this.labelRemark = "";
      this.rate = "";
      this.needOpenCourseTime = false;
      this.autoSync = false;
      this.autoUploadOrder = false;
    },
    autoSyncChange(val) {
      if (val) {
        this.autoUploadShow = true;
        this.autoUploadOrder = true;
      } else {
        this.autoUploadShow = false;
        this.autoUploadOrder = false;
      }
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        category: this.lableType,
      };
      if (this.enabled) {
        data.enabled = this.enabled;
      }
      this.getLabelList(data);
    },
    // 查询标签数据
    getLabelList(data) {
      this.loadingTable = true;
      this.$http("/wechatLabel/findWechatLabelList", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.data;
            list.forEach((item) => {
              item.rateLabel = "";
              if (item.rate !== null) {
                item.rateLabel = item.rate / 100;
              }
            });
            this.total = res.data.total;
            this.labelTableData = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 每页
    pageSizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 翻页
    currentPageNo(index) {
      this.inquire(index);
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.checkbox_class {
  margin-left: 60px;
}
</style>