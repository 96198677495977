<template>
	<div class='nodata'>
		<h1>{{msg}}</h1>
		
	</div>
</template>

<script>
	export default {
		name:'Nodata',
		data() {
			return {
				msg:'暂无数据'
			};
		},
		mounted() {
			
		},
		methods:{
			
		}
	}
</script>

<style scoped="scoped">
	.nodata {
		width: 100%;
		height: 100%;
	}
	.nodata h1 {
		font-size: 30px;
		text-align: center;
		margin-top: 100px;
	}
</style>
