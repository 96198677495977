import Vue from "vue";
import App from "./App";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import constants from "./components/js/constants.js";
import "../node_modules/ant-design-vue/lib/pagination/style/index.css";
import { Pagination,Statistic } from "ant-design-vue";
import VueClipboard from "vue-clipboard2";
import loading from "./components/js/loading.js"; // 引入loading
import "@/components/ef/index.css";
import common from "./components/js/common.js";
import http from "./components/js/http.js";
import service from "./components/js/service.js";
import md5 from "js-md5";
import promise from "es6-promise";
import Directives from "./directives";
import VDistpicker from "v-distpicker";

Vue.component("v-distpicker", VDistpicker); //地址选择器
promise.polyfill();
Vue.use(Directives);
Vue.use(Pagination);
Vue.use(Statistic);
Vue.use(VueClipboard);
Vue.use(loading); // 全局使用loading
Vue.prototype.$common = common;
Vue.prototype.$http = http;
Vue.prototype.$service = service;
Vue.prototype.$constants = constants;
Vue.prototype.$md5 = md5;
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
