<template>
  <div class="record51talk">
    <div class="nav">
      <div class="batchName" v-if="roleMenu === 'SUPER_MANAGER'">
        运营：
        <el-select
          v-model="userName"
          placeholder="请选择运营"
          filterable
          remote
          :loading="loadingUserName"
          :remote-method="remoteMethoduserName"
          @focus="userLoginNameFocus"
          clearable
        >
          <el-option
            v-for="item in userLoginNameList"
            :key="item.loginName"
            :label="item.userName"
            :value="item.loginName"
          >
          </el-option>
        </el-select>
      </div>
      <div class="batchName">
        推广链接：
        <el-input
          v-model="lpUrlSearch"
          placeholder="请输入推广链接"
          clearable
        ></el-input>
      </div>
      <div class="callTime">
        时间范围：
        <el-date-picker
          v-model="gmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          style="margin-right: 20px;"
          :clearable="false"
        ></el-date-picker>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquire51talk(1)">查询</el-button>
      <el-button @click="newBindingInformation" v-if="(roleMenu=== 'WECHAT_PRODUCT_MANAGER' || roleMenu === 'SUPER_MANAGER') ? true : false">更新绑定信息</el-button>
      <el-button type="success" @click="export51talk" :disabled="derivedDis">导出</el-button>
    </div>
    <!-- 51talk表 -->
    <el-table
      :data="record51talkList"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
      :summary-method="getSummariesFans"
      show-summary
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="运营"
        width="150"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="registerCount"
        label="注册数"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="orderCount"
        label="支付数"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalRegisterCountForMonth"
        label="当月总注册数"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalOrderCountForMonth"
        label="当月总支付数"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="lpUrl"
        label="推广链接"
        min-width="150"
        show-overflow-tooltip
        align="left"
      ></el-table-column>
      <el-table-column
        prop="operator"
        label="操作"
        width="100"
        align="center"
        v-if="roleMenu === 'SUPER_MANAGER'"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="bindingOperation(scope.row)"
            >绑定运营</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="currentPageNo"
      :current-page.sync="pageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <!-- 绑定运营 -->
    <el-dialog
      title="绑定运营"
      :visible.sync="bindingOperationShow"
      width="550px"
      class="connectedTable"
      @close="bindingOperationClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">绑定运营</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="选择运营：">
          <el-select
            v-model="userLoginName"
            placeholder="请选择运营"
            filterable
            remote
            :loading="loadingUserName"
            :remote-method="remoteMethoduserName"
            @focus="userLoginNameFocus"
            clearable
          >
            <el-option
              v-for="item in userLoginNameList"
              :key="item.loginName"
              :label="item.userName"
              :value="item.loginName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推广链接：">
          {{ lpUrl }}
        </el-form-item>
      </el-form>
      <div class="button-center">
        <el-button type="primary" @click="bindingOperation_submit()"
          >确 定</el-button
        >
        <el-button @click="bindingOperationShow = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 更新绑定信息 -->
    <el-dialog
      title="更新绑定信息"
      :visible.sync="bindingInformationShow"
      width="500px"
      class="el-changeLine"
      @close="bindingInformationClose"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">更新绑定信息</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="上传文件：">
          <el-upload
            class="upload-demo"
            drag
            :data="bindingInformationUploadData"
            :action="bindingInformationUrl"
            :on-change="bindingInformationUploadChange"
            :on-success="successBindingInformationUpload"
            :on-error="errorBindingInformationUpload"
            ref="bindingInformationUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="true"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
          <div class="titlt-show">请上传excel格式的文件(带表头, 表头名称一定要一致)</div>
          <div class="titlt-show">只需要两列数据: 链接, 对应关系(运营名称)</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="bindingInformation_submit()"
          >提 交</el-button
        >
        <el-button @click="bindingInformationShow = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "record51talk",
  props: ["roleMenu"],
  data() {
    return {
      bindingInformationShow: false,
      bindingInformationUploadData: {},
      fileList: [],
      bindingInformationUrl: this.$constants.baseURL + "/wechat/bindOperatorUrlFor51talk",
      userName: "",
      lpUrlSearch: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      record51talkList: [],
      tableTotal: {},
      loadingTable: false,
      gmtCreatedDate: [],
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      bindingOperationShow: false,
      userLoginName: "",
      loadingUserName: false,
      userLoginNameList: [],
      lpUrl: "",
      derivedDis: true
    };
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.gmtCreatedDate = [start, end];
    this.theWeek();
    this.inquire51talk(1);
  },
  methods: {
    // 点击导出
    export51talk() {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.gmtCreatedDate) {
        gmtCreatedStart = this.gmtCreatedDate[0];
        gmtCreatedEnd = this.gmtCreatedDate[1];
      }
      let params = {
        userLoginName: this.userName,
        lpUrl: this.lpUrlSearch,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
      };
      let url = "";
      for(let i in params){
        if(params[i]){
          url+=(i+'='+params[i]+'&')
        }
      }
      let href = this.$constants.baseURL+"/wechat/callback51talkExport?"+url;
      // window.location.href = href;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then(data=>{
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      })
    },
    // 点击更新绑定信息
    newBindingInformation() {
      this.bindingInformationShow = true;
    },
    // 提交更新绑定信息
    bindingInformation_submit() {
      // this.bindingInformationUploadData
      this.$refs.bindingInformationUpload.submit();
    },
    // 关闭更新绑定信息
    bindingInformationClose() {
      this.$refs.bindingInformationUpload.clearFiles();
    },
    // 更新绑定信息改变
    bindingInformationUploadChange(file, fileList) {
      let files = {0:file.raw}
      // this.$common.readExcel(files);
    },
    // 更新绑定信息上传成功
    successBindingInformationUpload(res, file, fileList){
      this.$refs.bindingInformationUpload.clearFiles();
      if(res.result == 200){
        this.$message({ message: "文件上传成功！", type: "success" });
        this.bindingInformationShow = false;
        this.inquire51talk(1);
      }
    },
    // 更新绑定信息上传失败
    errorBindingInformationUpload(err, file, fileList){
      let message = JSON.parse(err.message)
      this.$message.warning(message.message)
    },
    handleExceed(files, fileList) {//限制上传文件数量提示
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 点击绑定运营
    bindingOperation(val) {
      this.bindingOperationShow = true;
      this.lpUrl = val.lpUrl;
      this.userLoginName = val.userName;
    },
    // 确定绑定运营
    bindingOperation_submit() {
      let data = {
        userLoginName: this.userLoginName,
        url: this.lpUrl,
      };
      this.$service("/wechat/bindUserUrlFor51talk", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("绑定成功");
          this.bindingOperationShow = false;
          this.inquire51talk();
        }
      });
    },
    // 关闭绑定运营
    bindingOperationClose() {
      this.userLoginName = "";
      this.lpUrl = "";
    },
    // 绑定运营获取焦点
    userLoginNameFocus() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        roleCode: "WECHAT",
        enabled: true,
      };
      this.getUserNameList(data);
    },
    // 绑定运营搜索
    remoteMethoduserName(val) {
      let data = {
        pageNo: 1,
        pageSize: 20,
        enabled: true,
        roleCode: "WECHAT",
        userName: val,
      };
      this.getUserNameList(data);
    },
    // 查询运营列表
    getUserNameList(data) {
      this.loadingUserName = true;
      this.$http("/user/list", null, "get", null, data)
        .then((res) => {
          this.loadingUserName = false;
          if (res.result === 200) {
            let list = res.data.list;
            this.userLoginNameList = list;
          }
        })
        .catch((err) => {
          this.loadingUserName = false;
        });
    },
    // 查询51talk数据
    inquire51talk(index) {
      if (index) {
        this.pageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.gmtCreatedDate) {
        gmtCreatedStart = this.gmtCreatedDate[0];
        gmtCreatedEnd = this.gmtCreatedDate[1];
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        userLoginName: this.userName,
        lpUrl: this.lpUrlSearch,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
      };
      this.get51talkList(data);
    },
    // 请求51talk数据
    get51talkList(data) {
      this.loadingTable = true;
      this.derivedDis = true;
      this.$http(
        "/wechat/callbackSummaryFor51talk",
        null,
        "get",
        null,
        data
      ).then((res) => {
        this.loadingTable = false;
        if (res.result === 200) {
          let list = res.data.page.records;
          this.tableTotal = {
            orderCount: res.data.totalOrderCount,
            registerCount: res.data.totalRegisterCount,
          };
          this.record51talkList = list;
          this.total = res.data.page.total;
          if (list.length > 0) {
            this.derivedDis = false;
          }
        }
      }).catch(err => {
        this.loadingTable = false;
      })
    },
    // 翻页
    currentPageNo(index) {
      this.inquire51talk(index);
    },
    // 分页页码
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.inquire51talk(1);
    },
    // 转粉表---合计行
    getSummariesFans(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        for (let i in this.tableTotal) {
          if (i == column.property) {
            arr[index] = this.tableTotal[i];
          }
        }
      });
      return arr;
    },
    // 每周
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
  },
};
</script>
<style scoped>
.titlt-show {
  font-size: 14px;
  color: #E6A23C;
  line-height: 18px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav {
  margin-bottom: 20px;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.button-center {
  text-align: center;
}
</style>