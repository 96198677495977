<template>
  <div class="tripartiteData">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="51talk" name="51talk">
        <record51talk v-if="record51talkShow" :roleMenu="roleMenu"></record51talk>
      </el-tab-pane>
      <el-tab-pane label="三方订单" name="zuoyebang">
        <zuoyebangOrder v-if="zuoyebangOrderShow" :roleMenu="roleMenu"></zuoyebangOrder>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import record51talk from "./record51talk.vue";
import zuoyebangOrder from "./zuoyebangOrder.vue";
export default {
  name: "tripartiteData",
  components: {
    record51talk,
    zuoyebangOrder
  },
  props: ["roleMenu"],
  data() {
    return {
      activeName: "51talk",
      record51talkShow: false,
      zuoyebangOrderShow: false,
    };
  },
  mounted() {
    this.record51talkShow = true;
  },
  methods: {
    // 选择tab
    handleClick(tab) {
      if (this.activeName === "51talk") {
        this.record51talkShow = true;
        this.zuoyebangOrderShow = false;
      }
      if (this.activeName === "zuoyebang") {
        this.record51talkShow = false;
        this.zuoyebangOrderShow = true;
      }
    },
  },
};
</script>
<style scoped>
</style>