<template>
  <div class="labelManage">
    <el-button type="primary" @click="addLabel">新增</el-button>
    <el-button type="primary" @click="addLabelFile">批量新增</el-button>
    <el-table :data="tableData" stripe style="width: 100%" border v-loading="loading">
      <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
      <el-table-column prop="app" label="app" width align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="firstLevelLabel" label="一级标签" width align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="secondLevelLabel" label="二级标签" width align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop label="操作" width="90" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modifyLabel(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
     <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 修改 -->
    <el-dialog title="修改" :visible.sync="modifyLabelShow" custom-class="el-modifyLabelShow" width="400px" @close="modifyLabelClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">修改</span>
      </template>
      <el-form :model="modifyForm" :label-position="labelLeft">
        <el-form-item label="app：" label-width="90px">
          <el-input v-model="modifyForm.app" placeholder="请输入条数"></el-input>
        </el-form-item>
        <el-form-item label="一级标签：" label-width="90px">
          <el-input v-model="modifyForm.firstLevelLabel" placeholder="请输入条数"></el-input>
        </el-form-item>
        <el-form-item label="二级标签：" label-width="90px">
          <el-input v-model="modifyForm.secondLevelLabel" placeholder="请输入条数"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="modifyLabel_submit()">提 交</el-button>
          <el-button @click="modifyLabel_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog title="新增" :visible.sync="addLabelShow" custom-class="el-modifyLabelShow" width="400px" @close="addLabelClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">新增</span>
      </template>
      <el-form :model="addForm" :label-position="labelLeft">
        <el-form-item label="app：" label-width="90px">
          <el-input v-model="addForm.app" placeholder="请输入条数"></el-input>
        </el-form-item>
        <el-form-item label="一级标签：" label-width="90px">
          <el-input v-model="addForm.firstLevelLabel" placeholder="请输入条数"></el-input>
        </el-form-item>
        <el-form-item label="二级标签：" label-width="90px">
          <el-input v-model="addForm.secondLevelLabel" placeholder="请输入条数"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addLabel_submit()">提 交</el-button>
          <el-button @click="addLabel_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 批量新增 -->
    <el-dialog title="批量新增" :visible.sync="addLabelFileShow" custom-class="el-modifyLabelShow" width="500px" @close="addLabelFileClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">批量新增</span>
      </template>
      <el-form :model="addFileForm" :label-position="labelLeft">
        <el-form-item label="上传文件：" label-width="100px">
          <el-upload
            class="upload-demo"
            drag
            :data="addUploadData"
            :action="addUrl"
            :on-success="successNumberUpload"
            :on-error="errorNumberUpload"
            ref="addUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addLabelFile_submit()">提 交</el-button>
          <el-button @click="addLabelFile_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:"LabelManage",
  data(){
    return {
      loading: false,
      tableData:[],
      currentPage:null,
      total:null,
      modifyLabelShow:false,
      addLabelShow:false,
      addLabelFileShow:false,
      labelLeft:"left",
      modifyForm:{
        id:'',
        app:'',
        firstLevelLabel:'',
        secondLevelLabel:''
      },
      addForm:{
        app:'',
        firstLevelLabel:'',
        secondLevelLabel:''
      },
      addFileForm:{},
      addUploadData:{},
      addUrl:this.$constants.baseURL + "/label/addLabelFile",
      fileList: [],
      cookie: true,
      pageSize:10
    }
  },
  mounted(){
    document.title = "惠销平台管理系统-标签管理";
    this.inquire();
  },
  methods:{
    handleExceed(files, fileList) {//限制上传文件数量提示
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    inquire(){//查询
      this.currentPage = 1;
      let params = {
        pageNo:this.currentPage,
        pageSize:this.pageSize
      }
      this.inquirelabelList(params);
    },
    inquirelabelList(params){//查询标签list
      this.loading = true
      this.$http('/label/list',null,'get',null,params).then(res=>{
        setTimeout(() => {
          this.loading = false
        }, 200);
        if(res.result == 200){
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      })
    },
    currentPage_data(index){//当前页
      this.currentPage = index;
      let params = {
        pageNo:this.currentPage,
        pageSize:this.pageSize
      }
      this.inquirelabelList(params);
    },
    sizeChange(index){//当前页
      this.currentPage = 1;
      this.pageSize = index;
      let params = {
        pageNo:this.currentPage,
        pageSize:this.pageSize
      }
      this.inquirelabelList(params);
    },
    modifyLabel(val){//点击修改
      this.modifyLabelShow = true;
      this.modifyForm = {
        id:val.id,
        app:val.app,
        firstLevelLabel:val.firstLevelLabel,
        secondLevelLabel:val.secondLevelLabel
      }
    },
    modifyLabel_submit(){//点击修改提交
      let data = this.modifyForm;
      this.$http('/label/addLabel',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'修改成功',type:'success'});
          this.inquire();
          this.modifyLabel_cancel();
        }
      })
    },
    modifyLabel_cancel(){//点击修改取消
      this.modifyLabelShow = false;
    },
    modifyLabelClose(){//关闭修改表单
      this.modifyForm = {
        app:'',
        firstLevelLabel:'',
        secondLevelLabel:''
      }
    },
    addLabel(){//新增标签
      this.addLabelShow = true;
    },
    addLabel_submit(){//点击新增提交
      let data = {
        app:this.addForm.app,
        firstLevelLabel:this.addForm.firstLevelLabel,
        secondLevelLabel:this.addForm.secondLevelLabel,
      }
      this.$http('/label/addLabel',data).then(res=>{
        if(res.result == 200){
          this.$message({message:'新增成功',type:'success'});
          this.inquire();
          this.addLabel_cancel();
        }
      })
    },
    addLabel_cancel(){//点击新增取消
      this.addLabelShow = false;
    },
    addLabelClose(){//关闭新增表单
      this.addForm = {
        app:'',
        firstLevelLabel:'',
        secondLevelLabel:''
      }
    },
    addLabelFile(){//点击批量新增
      this.addLabelFileShow = true;
    },
    successNumberUpload(res, file, fileList){//批量新增上传成功
      this.$refs.addUpload.clearFiles();
      if(res.result == 200){
        this.$message({ message: "文件上传成功！", type: "success" });
        this.addLabelFile_cancel();
        this.inquire();
      }
    },
    errorNumberUpload(err, file, fileList){//批量新增上传失败
      let message = JSON.parse(err.message)
      this.$message.warning(message.message)
    },
    addLabelFile_submit(){//批量新增提交
      // this.addUploadData.taskId = this.addFileForm.taskId;
      this.$refs.addUpload.submit();
    },
    addLabelFile_cancel(){//批量新增取消
      this.addLabelFileShow = false;
    },
    addLabelFileClose(){//关闭批量新增
      this.$refs.addUpload.clearFiles();
    }
  }
}
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center
}
.el-modifyLabelShow .el-input{
  width: 250px;
}
.upload-demo >>> .el-upload-dragger {
  width: 320px !important;
}
</style>