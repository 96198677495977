<template>
  <div class="voiceOver">
    <!-- 语音合成设置 -->
    <div class="nav_voice">
      <div class="nav_voice_title">
        <div class="nav_voice_title_text">
          <span>语音合成设置</span>
          <el-tooltip placement="top">
            <div slot="content" style="width: 300px">
              语音合成也称作文本转语音（TTS），支持配置声音风格、语速、音量。
            </div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
      </div>
      <div class="nav_voice_content">
        <div class="nav_voice_content_item">
          <div class="nav_voice_content_img">
            <img
              v-if="voiceRecordForm.type === 'FEMALE'"
              src="@/assets/img/kefunv.png"
              alt=""
              style="width: 40px"
            />
            <img
              v-if="voiceRecordForm.type === 'MALE'"
              src="@/assets/img/kefunan.png"
              alt=""
              style="width: 40px"
            />
          </div>
          <div class="nav_voice_content_warp">
            <div style="font-weight: 700">{{ voiceType }}</div>
            <div>
              <span class="mr16">风格：{{ voiceStyle }}</span>
              <span class="mr16">语速：{{ voiceSpeed }}</span>
              <span class="mr16">音量：{{ voiceRecordForm.volume }}</span>
              <div style="display: inline-block">
                <audio
                  id="myAudio"
                  ref="audio"
                  :src="voiceSrc"
                  controlsList="nodownload"
                  controls="controls"
                  style="display: none"
                ></audio>
                <el-button
                  size="small"
                  type="text"
                  @click="voicePlay"
                  v-if="playFlag === -1"
                  >快速试听</el-button
                >
                <span v-if="playFlag === 1">
                  <i class="el-icon-loading"></i>
                  <el-button size="small" type="text">正在加载</el-button>
                </span>
                <span v-if="playFlag === 2">
                  <i class="el-icon-loading"></i>
                  <el-button size="small" type="text" @click="voicePause"
                    >停止</el-button
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="nav_voice_content_btn">
          <el-button type="primary" size="mini" @click="setVoice"
            >修改设置</el-button
          >
        </div>
      </div>
      <hr class="nav_voice_content_bottom" />
    </div>
    <!-- <el-button type="primary" @click="inquire(1)">查询</el-button> -->
    <el-table
      :data="tableListData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
      height="calc(100% - 195px)"
    >
      <el-table-column
        prop="id"
        label="编号"
        width="70"
        align="center"
      ></el-table-column>
      <el-table-column prop="source" label="话术来源" width="100" align="left">
        <template slot-scope="scope">
          <span v-for="item in sourceList" :key="item.value">
            <span v-if="item.value === scope.row.source">{{ item.label }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="subSource"
        label="话术子来源"
        width="110"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="content"
        label="机器人话术"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="voiceType"
        label="放音类型"
        width="170"
        align="left"
      >
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.voiceType"
            @change="dialogueVoiceTypeChange(scope.row)"
          >
            <el-radio label="TTS">TTS</el-radio>
            <el-radio label="RECORD">录音</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="上传状态" width="120" align="left">
        <template slot-scope="scope">
          <span v-if="scope.row.voiceType === 'TTS'" style="font-size: 12px">
            <div class="node-speech-item-ss-rr-hua"></div>
            无需上传
          </span>
          <span v-if="scope.row.voiceType === 'RECORD'" style="font-size: 12px">
            <span
              v-if="
                scope.row.ossFileIdForRecord && scope.row.ossFileIdForRecord > 0
              "
            >
              <div
                class="node-speech-item-ss-rr node-speech-item-ss-rr-success"
              ></div>
              已上传
            </span>
            <span v-else>
              <div
                class="node-speech-item-ss-rr node-speech-item-ss-rr-err"
              ></div>
              未上传
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop
        label="操作"
        width="200"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="uploadVoiceForClick(scope.row)"
            :disabled="scope.row.voiceType !== 'RECORD'"
          >
            <span v-if="scope.row.voicePathForRecord">更换录音</span>
            <span v-else>上传录音</span>
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="uploadVoiceClick(scope.row)"
            >更新话术</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="auditionClick(scope.row)"
            v-if="scope.row.playFlag === -1"
            :disabled="
              scope.row.ossFileIdForRecord <= 0 &&
              scope.row.voiceType === 'RECORD'
            "
          >
            试听</el-button
          >
          <span v-if="scope.row.playFlag === 1">
            <i class="el-icon-loading"></i>
            <el-button size="small" type="text">正在加载</el-button>
          </span>
          <span v-if="scope.row.playFlag === 2">
            <i class="el-icon-loading"></i>
            <el-button
              size="small"
              type="text"
              @click="auditionPause(scope.row)"
              >停止</el-button
            >
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 语音设置 -->
    <el-dialog
      title="修改设置"
      :visible.sync="voiceShow"
      width="500px"
      @close="voiceClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="声音类型:" required>
          <el-radio-group v-model="voiceForm.type" @change="typeChange">
            <el-radio label="FEMALE">女声</el-radio>
            <el-radio label="MALE">男声</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="声音风格:" required>
          <el-select
            v-model="voiceForm.style"
            placeholder="请选择声音风格"
            v-if="voiceForm.type === 'FEMALE'"
          >
            <el-option
              v-for="item in womanVoiceStyleList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="voiceForm.style"
            placeholder="请选择声音风格"
            v-if="voiceForm.type === 'MALE'"
          >
            <el-option
              v-for="item in manVoiceStyleList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="语速控制:" required>
          <div class="speed_style">
            <div class="t1">很慢</div>
            <div class="t2">慢</div>
            <div class="t3">正常</div>
            <div class="t4">快</div>
            <div class="t5">很快</div>
          </div>
          <el-slider
            v-model="voiceForm.speed"
            :step="100"
            :min="-200"
            :max="200"
            style="width: 90%"
            :show-tooltip="false"
            show-stops
          >
          </el-slider>
        </el-form-item>
        <el-form-item label="音量控制:" required>
          <div class="volume_style">
            <div>0</div>
            <el-slider
              v-model="voiceForm.volume"
              style="width: 80%; margin: 0 8px"
            ></el-slider>
            <div>100</div>
          </div>
        </el-form-item>
        <el-form-item label="试听内容:">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            maxlength="100"
            show-word-limit
            v-model="voiceForm.content"
          >
          </el-input>
          <div>
            • 点击
            <el-button
              type="text"
              @click="voiceTestPlay"
              v-if="playTestFlag === -1"
              >快速试听</el-button
            >
            <span v-if="playTestFlag === 1">
              <i class="el-icon-loading"></i>
              <el-button type="text">正在加载</el-button>
            </span>
            <span v-if="playTestFlag === 2">
              <i class="el-icon-loading"></i>
              <el-button type="text" @click="voiceTestPause">停止</el-button>
            </span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="voice_submit" :loading="loading"
          >确定</el-button
        >
        <el-button @click="voiceShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 跟新话术 -->
    <el-dialog
      title="跟新话术"
      :visible.sync="uploadVoiceShow"
      width="500px"
      @close="uploadVoiceClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="机器人话术:" required>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            maxlength="100"
            show-word-limit
            v-model="uploadVoiceForm.content"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="uploadVoice_submit()"
          :loading="loading"
          >确认</el-button
        >
        <el-button @click="uploadVoiceShow = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 上传录音 -->
    <el-dialog
      title="上传录音"
      :visible.sync="uploadVoiceForShow"
      width="500px"
      @close="uploadVoiceForClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="上传录音:" required>
          <el-upload
            class="upload-demo"
            drag
            :data="addUploadData"
            :action="addUrl"
            :on-success="successNumberUpload"
            :on-error="errorNumberUpload"
            ref="addUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="uploadVoiceFor_submit()"
          :loading="loading"
          >确认</el-button
        >
        <el-button @click="uploadVoiceForShow = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "voiceOver",
  props: {
    activeSpeechId: [String, Number],
  },
  data() {
    return {
      addUploadData: {},
      addUrl: this.$constants.baseURL + "/dialogueVoice/uploadVoiceForDialogue",
      fileList: [],
      cookie: true,
      uploadVoiceForShow: false,
      uploadVoiceForm: {
        content: "",
      },
      uploadVoiceShow: false,
      activeNodeId: "",
      voiceSrc: "",
      playFlag: -1, //播放状态
      playTestFlag: -1, //播放状态
      voiceRecordForm: {
        type: "FEMALE",
        style: "",
        speed: 0,
        volume: 50,
        content: "",
      },
      voiceForm: {
        type: "FEMALE",
        style: "",
        speed: 0,
        volume: 50,
        content: "",
      },
      womanVoiceStyleList: [],
      manVoiceStyleList: [],
      voiceStyleList: [],
      loading: false,
      voiceShow: false,
      intentionLibraryName: "",
      loadingTable: false,
      tableListData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      lastRunTime: null,
      sourceList: [
        {
          value: "GLOBAL_CONFIG",
          label: "全局配置",
        },
        {
          value: "MASTER",
          label: "主线流程",
        },
        {
          value: "SECONDARY",
          label: "知识库流程",
        },
        {
          value: "SECONDARY_NOTE",
          label: "知识库词条",
        },
      ],
    };
  },
  computed: {
    voiceType() {
      let str = "";
      if (this.voiceRecordForm.type === "FEMALE") {
        str = "女声";
        return str;
      }
      if (this.voiceRecordForm.type === "MALE") {
        str = "男声";
        return str;
      }
    },
    voiceStyle() {
      let str = "";
      this.voiceStyleList.forEach((item) => {
        if (item.code === this.voiceRecordForm.style) {
          str = item.name;
        }
      });
      return str;
    },
    voiceSpeed() {
      let str = "";
      switch (this.voiceRecordForm.speed) {
        case -200:
          str = "很慢";
          break;
        case -100:
          str = "慢";
          break;
        case 0:
          str = "正常";
          break;
        case 100:
          str = "快";
          break;
        case 200:
          str = "很快";
          break;
      }
      return str;
    },
  },
  mounted() {
    this.inquire(1);
    this.getVoiceStyleList();
    this.getDialogueDetail();
    let audio = document.getElementById("myAudio");
    audio.loop = false;
    audio.addEventListener(
      "ended",
      () => {
        this.playFlag = -1;
        this.playTestFlag = -1;
        if (this.activeNodeId) {
          let list = this.$common.deepClone(this.tableListData);
          list.forEach((item) => {
            if (item.id === this.activeNodeId) {
              item.playFlag = -1;
              this.activeNodeId = "";
            }
          });
          this.tableListData = list;
        }
      },
      false
    );
  },
  methods: {
    // 点击上传录音
    uploadVoiceForClick(val) {
      this.activeNodeId = val.id;
      this.uploadVoiceForShow = true;
    },
    // 确定上传录音
    uploadVoiceFor_submit() {
      this.addUploadData.dialogueContentId = this.activeNodeId;
      this.$refs.addUpload.submit();
    },
    // 关闭上传录音
    uploadVoiceForClose() {
      this.loading = false;
      this.activeNodeId = "";
      this.$refs.addUpload.clearFiles();
    },
    //上报成功
    successNumberUpload(res, file, fileList) {
      this.$refs.addUpload.clearFiles();
      this.loading = true;
      if (res.result == 200) {
        this.loading = false;
        this.$message({ message: "录音上传成功！", type: "success" });
        this.uploadVoiceForShow = false;
        this.inquire(1);
      }
    },
    //上报失败
    errorNumberUpload(err, file, fileList) {
      this.loading = false;
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    handleExceed(files, fileList) {
      //限制上传文件数量提示
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 点击更新话术
    uploadVoiceClick(val) {
      this.activeNodeId = val.id;
      this.uploadVoiceForm.content = val.content;
      this.uploadVoiceShow = true;
    },
    // 确定更新话术
    uploadVoice_submit() {
      let data = {
        id: this.activeNodeId,
        content: this.uploadVoiceForm.content,
      };
      this.loading = true;
      this.$service("/dialogueVoice/updateVoiceContent", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("修改完成");
            this.uploadVoiceShow = false;
            this.inquire(1);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭更新话术
    uploadVoiceClose() {
      this.activeNodeId = "";
      this.uploadVoiceForm = {
        content: "",
      };
    },
    // 选择声音类型
    typeChange(val) {
      this.voiceForm.style = "";
      if (val === "FEMALE") {
        if (this.womanVoiceStyleList.length > 0) {
          this.voiceForm.style = this.womanVoiceStyleList[0].code;
        }
      }
      if (val === "MALE") {
        if (this.manVoiceStyleList.length > 0) {
          this.voiceForm.style = this.manVoiceStyleList[0].code;
        }
      }
    },
    // 获取话术详情
    getDialogueDetail() {
      let data = {
        id: this.activeSpeechId,
      };
      this.$http("/dialogue/dialogueDetail", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let resData = res.data;
            this.voiceRecordForm = {
              type: resData.aiVoiceGender,
              style: resData.aiVoiceStyle,
              speed: resData.aiVoiceSpeechRate,
              volume: resData.aiVoiceVolume,
              content:
                "尊敬的王大志先生，我是智能数码专营店的售后客服，请问有什么可以帮您？",
            };
          }
        }
      );
    },
    // 获取声音风格
    getVoiceStyleList() {
      this.$http("/dialogueVoice/dialogueVoiceActorList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            if (item.gender === "FEMALE") {
              this.womanVoiceStyleList.push(item);
            }
            if (item.gender === "MALE") {
              this.manVoiceStyleList.push(item);
            }
          });
          this.voiceStyleList = list;
        }
      });
    },
    // 生产录音
    generateNlsTtsVoice(data, key) {
      if (key === "default") {
        this.playFlag = 1;
      }
      if (key === "test") {
        this.playTestFlag = 1;
      }
      this.$service("/dialogueVoice/generateNlsTtsVoice", data)
        .then((res) => {
          if (res.result === 200) {
            let param = {
              randomId: res.data.randomId,
            };
            this.getTtsVoice(param, key);
          }
        })
        .catch((err) => {
          if (key === "default") {
            this.playFlag = -1;
          }
          if (key === "test") {
            this.playTestFlag = -1;
          }
        });
    },
    // 获取录音
    getTtsVoice(data, key) {
      if (key === "default") {
        this.playFlag = 2;
      }
      if (key === "test") {
        this.playTestFlag = 2;
      }
      if (data.dialogueNodeContentId) {
        this.voiceSrc = `${this.$constants.baseURL}/dialogueVoice/playTtsVoice?dialogueNodeContentId=${data.dialogueNodeContentId}&voiceType=${data.voiceType}`;
      }
      if (data.randomId) {
        this.voiceSrc = `${this.$constants.baseURL}/dialogueVoice/playTtsVoice?randomId=${data.randomId}&voiceType=TTS`;
      }
      this.$refs.audio.load();
      fetch(this.voiceSrc)
        .then((response) => response.blob())
        .then((blob) => {
          return this.$refs.audio.play();
        })
        .then((_) => {
          // Video playback started ;)
        })
        .catch((e) => {
          // Video playback failed ;(
        });
    },
    // 点击播放语音
    voicePlay() {
      let currentTime = Date.now(),
        protectTime = 1000;
      if (this.lastRunTime) {
        if (currentTime - this.lastRunTime < protectTime) {
          this.lastRunTime = currentTime;
          return; //两次执行太过频繁，直接退出
        }
      }
      this.lastRunTime = currentTime;
      let data = {
        dialogueId: this.activeSpeechId,
        aiVoiceGender: this.voiceRecordForm.type,
        aiVoiceStyle: this.voiceRecordForm.style,
        aiVoiceVolume: this.voiceRecordForm.volume,
        aiVoiceSpeechRate: this.voiceRecordForm.speed,
        content: this.voiceRecordForm.content,
      };
      this.generateNlsTtsVoice(data, "default");
    },
    // 点击关闭语音
    voicePause() {
      let currentTime = Date.now(),
        protectTime = 1000;
      if (this.lastRunTime) {
        if (currentTime - this.lastRunTime < protectTime) {
          this.lastRunTime = currentTime;
          return; //两次执行太过频繁，直接退出
        }
      }
      this.lastRunTime = currentTime;
      this.$refs.audio.pause();
      this.playFlag = -1;
    },
    // 点击播放语音--测试
    voiceTestPlay() {
      let currentTime = Date.now(),
        protectTime = 1000;
      if (this.lastRunTime) {
        if (currentTime - this.lastRunTime < protectTime) {
          this.lastRunTime = currentTime;
          return; //两次执行太过频繁，直接退出
        }
      }
      let data = {
        dialogueId: this.activeSpeechId,
        aiVoiceGender: this.voiceForm.type,
        aiVoiceStyle: this.voiceForm.style,
        aiVoiceVolume: this.voiceForm.volume,
        aiVoiceSpeechRate: this.voiceForm.speed,
        content: this.voiceForm.content,
      };
      this.generateNlsTtsVoice(data, "test");
    },
    // 点击关闭语音--测试
    voiceTestPause() {
      let currentTime = Date.now(),
        protectTime = 1000;
      if (this.lastRunTime) {
        if (currentTime - this.lastRunTime < protectTime) {
          this.lastRunTime = currentTime;
          return; //两次执行太过频繁，直接退出
        }
      }
      this.lastRunTime = currentTime;
      this.$refs.audio.pause();
      this.playTestFlag = -1;
    },
    // 点击试听
    auditionClick(val) {
      let currentTime = Date.now(),
        protectTime = 1000;
      if (this.lastRunTime) {
        if (currentTime - this.lastRunTime < protectTime) {
          this.lastRunTime = currentTime;
          return; //两次执行太过频繁，直接退出
        }
      }
      this.activeNodeId = val.id;
      let data = {
        dialogueNodeContentId: val.id,
        voiceType: val.voiceType,
      };
      this.getTtsVoice(data);
      val.playFlag = 2;
    },
    // 关闭试听
    auditionPause(val) {
      let currentTime = Date.now(),
        protectTime = 1000;
      if (this.lastRunTime) {
        if (currentTime - this.lastRunTime < protectTime) {
          this.lastRunTime = currentTime;
          return; //两次执行太过频繁，直接退出
        }
      }
      this.lastRunTime = currentTime;
      this.$refs.audio.pause();
      val.playFlag = -1;
    },
    // 点击设置语音
    setVoice() {
      this.voiceForm = this.$common.deepClone(this.voiceRecordForm);
      this.voiceShow = true;
    },
    // 确定语音设置
    voice_submit() {
      let name = "";
      this.voiceStyleList.forEach((item) => {
        if (item.code === this.voiceForm.style) {
          name = item.name;
        }
      });
      let data = {
        dialogueId: this.activeSpeechId,
        aiVoiceGender: this.voiceForm.type,
        aiVoiceStyle: this.voiceForm.style,
        aiVoiceVolume: this.voiceForm.volume,
        aiVoiceSpeechRate: this.voiceForm.speed,
        aiVoiceName: name,
      };
      this.loading = true;
      this.$service("/dialogueVoice/updateDialogueVoiceActor", data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.voiceShow = false;
            this.getDialogueDetail();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭语音设置
    voiceClose() {
      this.playTestFlag = -1;
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        dialogueId: this.activeSpeechId,
      };
      this.getListDialogueVoice(data);
    },
    // 查询话术配音
    getListDialogueVoice(data) {
      this.loadingTable = true;
      this.$http("/dialogueVoice/listDialogueVoice", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.playFlag = -1;
            });
            this.tableListData = list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 分页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 页码
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 选择录音类型
    dialogueVoiceTypeChange(val) {
      let data = {
        id: val.id,
        dialogueVoiceType: val.voiceType,
      };
      this.loadingTable = true;
      this.$service("/dialogueVoice/updateDialogueVoiceType", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("切换成功");
            this.inquire();
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
<style scoped>
.volume_style {
  display: flex;
}
.speed_style {
  position: relative;
  height: 25px;
}
.speed_style .t1 {
  position: absolute;
  left: -10px;
}
.speed_style .t2 {
  position: absolute;
  left: 70px;
}
.speed_style .t3 {
  position: absolute;
  left: 147px;
}
.speed_style .t4 {
  position: absolute;
  left: 233px;
}
.speed_style .t5 {
  position: absolute;
  left: 305px;
}
.voiceOver {
  padding: 15px;
  height: 100%;
}
.nav_voice {
  margin-bottom: 16px;
}
.nav_voice_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 25px;
}
.nav_voice_title_text {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
}
.nav_voice_content {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.nav_voice_content_item {
  display: flex;
  align-items: center;
}
.nav_voice_content_img {
  display: inline-block;
  margin-right: 8px;
}
.nav_voice_content_warp {
  display: inline-block;
  font-size: 14px;
}
.nav_voice_content_warp .mt8 {
  margin-top: 8px;
}
.nav_voice_content_warp .mr16 {
  margin-right: 16px;
}
.nav_voice_content_bottom {
  margin-left: 48px;
  border-width: 1px 0px 0px;
  border-top-style: dashed;
  border-right-style: initial;
  border-bottom-style: initial;
  border-left-style: initial;
  border-top-color: rgb(221, 221, 221);
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image: initial;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
</style>
