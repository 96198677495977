<template>
  <div class="subjectGroup">
    <el-tabs v-model="activeName">
      <el-tab-pane label="圈量" name="1">
        <router-view
          name="third"
          prefix="wechat"
          v-if="activeName == '1'"
        ></router-view>
      </el-tab-pane>
      <el-tab-pane label="星辰来客" name="2">
        <router-view
          name="third"
          prefix="xylk"
          v-if="activeName == '2'"
        ></router-view>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "subjectGroup",
  data() {
    return {
      activeName: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/subjectGroup/wechatSubjectGroup") {
          this.activeName = "1";
        } else if (newVal == "/subjectGroup/xylkSubjectGroup") {
          this.activeName = "2";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/subjectGroup/wechatSubjectGroup") return;
          this.$router.replace("/subjectGroup/wechatSubjectGroup");
        }
        if (newVal === "2") {
          if (this.$route.path == "/subjectGroup/xylkSubjectGroup") return;
          this.$router.replace("/subjectGroup/xylkSubjectGroup");
        }
      },
    },
  },
};
</script>

<style></style>
