<template>
  <div class="menuTree">
    <div class="tableCalss">
      <div style="margin-bottom: 15px;">
        <el-button type="primary" @click="addRole">新增角色</el-button>
        <el-button type="primary" @click="menuManagement('MENU')">菜单管理</el-button>
        <el-button type="primary" @click="menuManagement('POWER')">权限管理</el-button>
      </div>
      <!-- 角色表 -->
      <el-table :data="roleTableData" stripe style="width: 100%" border v-loading="loading">
        <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
        <el-table-column prop="roleName" label="名称" min-width="130" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="roleCode" label="角色编码" min-width="130" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="indexUrl" label="跳转地址" min-width="130" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="gmtModified" label="更新时间" width="180" align="center"></el-table-column>
        <el-table-column prop label="操作" width="250" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="modifyRole(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="resources(scope.row)">菜单设置</el-button>
            <el-button type="text" size="small" @click="powerSetClick(scope.row)">权限设置</el-button>
            <el-button type="text" size="small" @click="deleteRole(scope.row)" :disabled="scope.row.deleteDisabled" style="color: #ff0000b0">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentPage_data"
        :current-page.sync="currentPage"
        :page-size="10"
        :page-sizes="[10, 20, 30, 40]"
        layout="sizes, total, prev, pager, next,jumper"
        :total="total"
        :pager-count="5"
      ></el-pagination>
    </div>
    <!-- 菜单设置or权限设置 -->
    <el-dialog 
      :title="setDialogTitle" 
      :visible.sync="treeShow" 
      class="el-management" 
      width="450px" 
      @closed="treeClose"
      top="8vh"
    >
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">{{setDialogTitle}}</span>
      </template>
      <el-tree
        show-checkbox
        :data="treeList"
        :props="defaultProps"
        node-key="id"
        :default-expanded-keys="expandedKeys"
        ref="tree">
      </el-tree>
      <div class="button-tree">
        <el-button type="primary" @click="resources_submit()">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog :visible.sync="editShow" custom-class="el-addTeam" width="400px" @close="editClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">编辑</span>
      </template>
      <el-form :model="form" label-position="left">
        <el-form-item label="名称：" label-width="60px">
          <el-input v-model="form.cn" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="图标：" label-width="60px">
          <el-input v-model="form.class" placeholder="请输入图标"></el-input>
        </el-form-item>
        <el-form-item label="url：" label-width="60px">
          <el-input v-model="form.url" placeholder="请输入url"></el-input>
        </el-form-item>
        <el-form-item label="启用：" label-width="60px">
          <el-switch v-model="form.enabled" active-color="#13ce66" inactive-color="#C0CCDA"></el-switch>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="edit_submit()">提 交</el-button>
          <el-button @click="edit_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改" :visible.sync="modifyRoleShow" custom-class="el-addTeam" width="400px" @close="modifyRoleClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">修改</span>
      </template>
      <el-form :model="modifyRoleForm" label-position="left">
        <el-form-item label="角色名称：" label-width="90px">
          <el-input v-model="modifyRoleForm.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="跳转地址：" label-width="90px">
          <el-input v-model="modifyRoleForm.indexUrl" placeholder="请输入跳转地址"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="modifyRole_submit">提 交</el-button>
          <el-button @click="modifyRoleShow = false">取 消</el-button>
        </div>
        </el-form>
    </el-dialog>
    <!-- 新增角色 -->
    <el-dialog title="新增角色" :visible.sync="addShow" custom-class="el-addTeam" width="400px" @close="addClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">新增角色</span>
      </template>
      <el-form :model="addForm" label-position="left">
        <el-form-item label="角色名称：" label-width="90px">
          <el-input v-model="addForm.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色编码：" label-width="90px">
          <el-input v-model="addForm.roleCode" placeholder="请输入角色编码"></el-input>
        </el-form-item>
        <el-form-item label="跳转地址：" label-width="90px">
          <el-input v-model="addForm.indexUrl" placeholder="请输入跳转地址"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="add_submit()">提 交</el-button>
          <el-button @click="add_cancel">取 消</el-button>
        </div>
        </el-form>
    </el-dialog>
    <!-- 菜单管理 -->
    <el-dialog :title="menuManagementTitle" :visible.sync="menuManagementShow" class="el-management" width="400px" @close="menuClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">{{menuManagementTitle}}</span>
      </template>
      <el-tree
        :data="menuTreeList"
        :props="defaultProps"
        node-key="id"
        ref="menuTree"
        :expand-on-click-node="false"
        :default-expanded-keys="menuExpandedKeys"
        draggable
        :allow-drop="allowDrop"
        @node-drop="handleDrop">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button style="font-size: 12px;" type="text" @click="() => append(data)" v-if="data.appendShow">追加</el-button>
            <el-button style="font-size: 12px;" type="text" @click="() => edit(data)">编辑</el-button>
            <el-button style="font-size: 12px;color: #ff0000b0" type="text" @click="() => remove(node, data)">删除</el-button>
          </span>
        </span>
      </el-tree>
    </el-dialog>
    <!-- 新增菜单 -->
    <el-dialog title="新增菜单" :visible.sync="addMenuShow" custom-class="el-addTeam" width="400px" @close="addMenuClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">{{addMenuTitle}}</span>
      </template>
      <el-form :model="addMenuForm" label-position="left" label-width="70px">
        <el-form-item label="名称：" required>
          <el-input v-model="addMenuForm.cn" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="图标：">
          <el-input v-model="addMenuForm.class" placeholder="请输入图标"></el-input>
        </el-form-item>
        <el-form-item label="url：">
          <el-input v-model="addMenuForm.url" placeholder="请输入url"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addMenu_submit()">提 交</el-button>
          <el-button @click="addMenu_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'roleList',
    data(){
      return {
        addMenuTitle: "",
        setDialogTitle: "",
        setDialogType: "",
        modifyRoleForm: {
          id: "",
          roleName: "",
          indexUrl: "",
        },
        modifyRoleShow: false,
        loading: false,
        treeShow:false,
        form:{
          cn:'',
          class:'',
          url:'',
          pid:'',
          order:'',
          resourceId:'',
          enabled: false
        },
        editShow:false,
        roleTableData:null,
        treeList: [],
        expandedKeys:[],
        defaultProps: {
          children: 'subMenus',
          label: 'cn'
        },
        roleId:'',
        currentPage:null,
        total:null,
        addForm:{
          roleName:'',
          roleCode:'',
          indexUrl: ""
        },
        addShow:false,
        menuManagementShow:false,
        menuManagementTitle: "",
        menuTreeList:[],
        addId:null,
        addMenuShow:false,
        addMenuForm:{
          cn:'',
          class:'',
          url:'',
          pid:'',
          order:''
        },
        menuExpandedKeys:[],
        pageSize:10
      }
    },
    mounted(){
      document.title = "惠销平台管理系统-角色管理";
      this.inquire(1);
    },
    methods:{
      modifyRole(val){//点击修改
        this.modifyRoleShow = true;
        this.modifyRoleForm = {
          id: val.id,
          roleName: val.roleName,
          indexUrl: val.indexUrl,
        }
      },
      // 确定修改
      modifyRole_submit() {
        if (this.modifyRoleForm.indexUrl) {
          let index = this.modifyRoleForm.indexUrl.indexOf("/");
          if (index !== 0) {
            this.$message.warning("跳转地址格式必须以 / 开头");
            return
          }
        }
        this.$service("/user/updateRole",this.modifyRoleForm).then(res => {
          if (res.result === 200) {
            this.modifyRoleShow = false;
            this.$message.success("修改成功");
            this.inquire();
          }
        })
      },
      // 关闭修改
      modifyRoleClose() {
        this.modifyRoleForm = {
          id: "",
          roleName: "",
          indexUrl: "",
        }
      },
      currentPage_data(index){//当前页
        this.inquire(index);
      },
      sizeChange(index){//显示条数--分页
        this.currentPage = 1;
        this.pageSize = index;
        let params = {
          pageNo:this.currentPage,
          pageSize:this.pageSize
        }
        this.inquireRoleList(params);
      },
      inquire(index){//查询
        if (index) {
          this.currentPage = index;
        }
        let params = {
          pageNo: index || this.currentPage,
          pageSize: this.pageSize
        }
        this.inquireRoleList(params);
      },
      inquireRoleList(params){//查询角色列表
        this.loading = true
        this.$http('/user/roleList',null,'get',null,params).then(res=>{
          setTimeout(() => {
            this.loading = false
          }, 200);
          if(res.result == 200){
            this.total = res.data.total;
            let list = res.data.records;
            list.map(item=>{
              item.deleteDisabled = false;
              item.gmtModified = this.$common.transformTime(item.gmtModified);
              switch (item.status) {
                case 'ENABLE':
                  item.statusLable = '启用';
                  break;
              }
              switch (item.deletable) {
                case false:
                  item.deleteDisabled = true;
                  break;
              }
            })
            this.roleTableData = list;
          }
        })
      },
      // 点击权限设置
      powerSetClick(val) {
        this.treeShow = true;
        this.roleId = val.id;
        this.setDialogTitle = "【"+val.roleName+"】权限设置";
        this.setDialogType = "power";
        this.inquirePowerList();
      },
      // 查询所有资源
      inquirePowerList() {
        this.$http("/user/powerList").then(res => {
          if (res.result === 200) {
            this.expandedKeys = [];
            this.treeList = res.data;
            this.inquireRolePowers(this.roleId);
          }
        })
      },
      inquireRolePowers(roleId){//查询对应角色权限
        let params = {
          roleId:roleId
        }
        this.$http('/user/rolePowers',null,'get',null,params).then(res=>{
          if(res.result == 200){
            let menuId = [];
            let list = res.data;
            list.map(i=>{
              if(i.subMenus){
                i.subMenus.map(j=>{
                  menuId.push(j.id)
                })
              }else{
                menuId.push(i.id);
              }
            })
            this.expandedKeys = menuId;
            this.$refs.tree.setCheckedKeys(menuId);
          }
        })
      },
      resources(val){//点击菜单设置
        this.treeShow = true;
        this.roleId = val.id;
        this.setDialogTitle = "【"+val.roleName+"】菜单设置";
        this.setDialogType = "resource";
        this.menuType = "MENU";
        this.inquireResourceList(this.menuType, true);
      },
      resources_submit(){//点击菜单设置提交
        let resouceIdsList = this.$refs.tree.getCheckedKeys();
        let resouceIds = resouceIdsList.join(",");
        let data = {
          roleId:this.roleId,
        }
        if(!resouceIds){
          data.deleteAll = true;
        }
        if (this.setDialogType === "resource") {
          data.resourceIds = resouceIds;
          this.$http('/user/updateRoleResource',data).then(res=>{
            if(res.result == 200){
              this.$message({message:'菜单设置成功',type:'success'});
              this.inquireResourceList(this.menuType, true);
              this.treeShow = false;
            }
          })
        }
        if (this.setDialogType === "power") {
          data.menuIds = resouceIds;
          this.$http('/user/updateRolePower',data).then(res=>{
            if(res.result == 200){
              this.$message({message:'权限设置成功',type:'success'});
              this.inquirePowerList();
              this.treeShow = false;
            }
          })
        }
      },
      treeClose(){//关闭菜单设置
        this.treeList = [];
        this.setDialogTitle = "";
        this.setDialogType = "";
        this.expandedKeys = [];
      },
      inquireResourceList(type, isNext){//查询所有资源
        this.$http('/user/resourceList',null,"get",null,{type: type}).then(res=>{
          if(res.result == 200){
            if(isNext){
              this.expandedKeys = [];
              this.treeList = res.data;
              this.inquireRoleMenus(this.roleId);
            }else{
              this.menuTreeList = res.data;
              let addId = [];
              this.menuTreeList.map(item=>{
                addId.push(item.id);
                item.appendShow = false;
                if(item.pid == 0){
                  item.appendShow = true;
                }
                if(item.subMenus){
                  item.subMenus.map(menu=>{
                    addId.push(menu.id)
                  })
                }
              })
              let maxId = addId.sort((a,b)=>b-a)[0];
              this.addId = maxId;
            }
          }
        })
      },
      inquireRoleMenus(roleId){//查询对应角色资源
        let params = {
          roleId:roleId
        }
        this.$http('/user/roleMenus',null,'get',null,params).then(res=>{
          if(res.result == 200){
            let menuId = [];
            let list = res.data;
            list.map(i=>{
              if(i.subMenus){
                i.subMenus.map(j=>{
                  menuId.push(j.id)
                })
              }else{
                menuId.push(i.id);
              }
            })
            this.expandedKeys = menuId;
            this.$refs.tree.setCheckedKeys(menuId);
          }
        })
      },
      addRole(){//点击新增
        this.addShow = true;
      },
      add_submit(){//新增提交
        let data = {
          roleName:this.addForm.roleName,
          roleCode:this.addForm.roleCode,
          indexUrl: this.addForm.indexUrl
        }
        if (this.addForm.indexUrl) {
          let index = this.addForm.indexUrl.indexOf("/");
          if (index !== 0) {
            this.$message.warning("跳转地址格式必须以 / 开头");
            return
          }
        }
        this.$service('/user/addRole',data).then(res=>{
          if(res.result == 200){
            this.$message({message:'修改成功',type:'success'});
            this.inquire(1);
            this.add_cancel();
          }
        })
      },
      add_cancel(){//新增取消
        this.addShow = false;
      },
      addClose(){//新增关闭
        this.addForm.roleName = '';
        this.addForm.roleCode = '';
        this.addForm.indexUrl = '';
      },
      deleteRole(val){//删除角色
        this.$confirm("【"+val.roleName+"】删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          let data = {
            roleId:val.id
          }
          this.$http('/user/deleteRole',data).then(res=>{
            if(res.result == 200){
              this.$message({message:'删除成功',type:'success'});
              this.inquire();
              this.treeShow = false;
            }
          })
        })
        .catch(() => {});
      },
      menuManagement(type){//点击菜单管理
        this.menuManagementShow = true;
        this.menuType = type;
        if (type === 'MENU') {
          this.menuManagementTitle = "菜单管理";
        } else {
          this.menuManagementTitle = "权限管理";
        }
        this.inquireResourceList(type);
      },
      menuClose(){//关闭菜单管理
        this.menuExpandedKeys = [];
        this.menuTreeList = [];
      },
      allowDrop(draggingNode, dropNode, type){//菜单拖拽时判定目标节点能否被放置
        return true;
      },
      handleDrop(draggingNode, dropNode, dropType, ev){//菜单拖拽成功时触发的事件
        // console.log(draggingNode.data);
        let data = {
          resourceId:draggingNode.data.id,
          cn:draggingNode.data.cn,
          pid:draggingNode.data.pid,
          extraMsg:draggingNode.data.extraMsg,
          enabled: true,
          type: this.menuType
        }
        let node = draggingNode.data;
        let isDrop = true;
        data.order = '';
        this.menuTreeList.forEach((c,i) => {
          if(node.id == c.id){
            data.order = i+1;
            data.pid = 0;
            c.appendShow = true;
          }else{
            if(c.subMenus){
              c.subMenus.forEach((k,j) => {
                if(node.id == k.id){
                  this.menuExpandedKeys.push(data.resourceId);
                  data.order = j+1;
                  if(dropType == 'inner'){
                    data.pid = dropNode.data.id;
                  }else{
                    data.pid = dropNode.data.pid;
                  }
                  k.appendShow = false;
                }
                if(k.subMenus){
                  this.$message({message:'编辑失败',type:'warning'});
                  this.menuExpandedKeys.push(data.resourceId);
                  this.inquireResourceList(this.menuType);
                  isDrop = false;
                }
              });
            }
          }
        });
        if(isDrop){
          this.$service('/user/updateResource',data).then(res=>{
            if(res.result == 200){
              this.$message({message:'编辑成功',type:'success'});
              this.inquireResourceList(this.menuType);
            }
          })
        }
      },
      append(data,parentNode) {//点击增加菜单
        this.addMenuShow = true;
        if(data.subMenus){
          this.addMenuForm.order = data.subMenus.length +1;
        }else{
          this.addMenuForm.order = 1;
        }
        if (this.menuType === 'MENU') {
          this.addMenuTitle = "【"+ data.cn +"】新增菜单";
        } else {
          this.addMenuTitle = "【"+ data.cn +"】新增权限";
        }
        this.addMenuForm.pid = data.id
      },
      addMenu_submit(){//提交新增菜单
        let extraMsg = {};
        let url = "";
        let icon = "";
        if(this.addMenuForm.url){
          url = this.addMenuForm.url;
          extraMsg.index = url;
        }
        if(this.addMenuForm.class){
          icon = this.addMenuForm.class;
          extraMsg.class = icon;
        }
        let data = {
          cn:this.addMenuForm.cn,
          pid:this.addMenuForm.pid,
          order:this.addMenuForm.order,
          extraMsg:JSON.stringify(extraMsg),
          type: this.menuType
        }
        if(!this.addMenuForm.cn){
          this.$message({message:'信息不完整',type:'warning'});
          return;
        }
        this.$http('/user/addResource',data).then(res=>{
          if(res.result == 200){
            this.menuExpandedKeys.push(data.pid);
            this.$message({message:'追加成功',type:'success'});
            this.inquireResourceList(this.menuType);
            this.addMenu_cancel();
          }
        })
      },
      addMenu_cancel(){//取消新增菜单
        this.addMenuShow = false;
      },
      addMenuClose(){//关闭新增菜单
        this.addMenuForm={
          cn:'',
          class:'',
          url:'',
          pid:'',
          order:''
        }
      },
      remove(node, data) {//删除菜单
        this.$confirm("删除【"+data.cn+"】, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          let param = {
            resourceId:data.id
          }
          this.$http('/user/deleteResource',param).then(res=>{
            if(res.result == 200){
              this.menuExpandedKeys.push(data.pid);
              this.$message({message:'删除成功',type:'success'});
              this.inquireResourceList(this.menuType);
            }
          })
        })
        .catch(() => {});
      },
      edit(data){//点击编辑菜单
        this.editShow = true;
        this.form.cn = data.cn;
        this.form.order = data.order;
        this.form.pid = data.pid;
        this.form.resourceId = data.id;
        this.form.enabled = data.enabled;
        let extraMsg = JSON.parse(data.extraMsg);
        if(extraMsg){
          for(let i in extraMsg){
            if(i=='index'){
              this.form.url = extraMsg[i];
            }
            if(i=='class'){
              this.form.class = extraMsg[i];
            }
          }
        }
      },
      edit_submit(){//提交编辑菜单
        let extraMsg = {};
        let url = "";
        let icon = "";
        if(this.form.url){
          url = this.form.url;
          extraMsg.index = url;
        }
        if(this.form.class){
          icon = this.form.class;
          extraMsg.class = icon;
        }
        let data = {
          resourceId:this.form.resourceId,
          cn:this.form.cn,
          pid:this.form.pid,
          order:this.form.order,
          enabled: this.form.enabled,
          extraMsg:JSON.stringify(extraMsg),
          type: this.menuType
        }
        this.$service('/user/updateResource',data).then(res=>{
          if(res.result == 200){
            this.menuExpandedKeys.push(data.resourceId);
            this.$message({message:'编辑成功',type:'success'});
            this.inquireResourceList(this.menuType);
            this.edit_cancel();
          }
        })
      },
      edit_cancel(){//取消编辑菜单
        this.editShow = false;
      },
      editClose(){//关闭编辑菜单
        this.form.cn = '';
        this.form.url = '';
        this.form.class = '';
        this.form.order = '';
        this.form.pid = '';
        this.form.resourceId = '';
        this.form.enabled = false;
      },
      
    }
  }
</script>
<style scoped='scoped'>
.el-management >>> .el-dialog__body{
  padding:0px 20px 20px 20px;
}
.el-addTeam .el-input{
  width: 92%;
}
.menuTree{
  position: relative;
}
.treeClass{
  position: absolute;
  min-width: 300px;
  top: 56px;
  left: 750px;
  border:1px solid #EBEEF5;
  padding: 10px 10px;
}
.button-center {
  width: 165px;
  margin: 0 auto;
}
.button-tree{
  margin-top: 15px;
  width: 100%;
  text-align: center;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-top: 15px;
  margin-bottom: 20px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>