<template>
  <div class="project">
    <el-tabs @tab-click="tabHandleClick">
      <el-tab-pane label="问题列表">
        <el-button type="primary" @click="addquestion=true">新增问题</el-button>
        <el-table :data="tableData" stripe style="width: 100%" border class="table-class" v-loading="loading">
          <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
          <el-table-column prop="question" label="问题" min-width="200" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="option" label="选项" min-width="200" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="questionTypeDisplayName" label="类型" width="140" align="center"></el-table-column>
          <el-table-column prop="tag" label="分类" width="100" align="center"></el-table-column>
          <el-table-column prop="gmtCreated" label="创建时间" width="190" align="center"></el-table-column>
          <el-table-column prop label="操作" width="140" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="reviseQuestion(scope.row)">修改</el-button>
              <el-button type="text" size="small" @click="deleteQuestion(scope.row)" style="color: #ff0000b0">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页--问题列表 -->
        <el-pagination
          @current-change="currentPage_data"
          :current-page.sync="currentPage"
          :page-size="10"
          @size-change="sizeChange"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="total"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="问卷列表">
        <el-button type="primary" @click="addGroupingClick">新增问卷</el-button>
        <el-table :data="tableGroupingData" stripe style="width: 100%" border class="table-class" v-loading="loading">
          <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
          <el-table-column prop="groupName" label="问卷名" min-width="200" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createdTime" label="创建时间" width="190" align="center"></el-table-column>
          <el-table-column prop label="操作" width="140" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="previewAddGrouping(scope.row)">预览</el-button>
              <el-button type="text" size="small" @click="reviseGrouping(scope.row)">修改</el-button>
              <el-button type="text" size="small" @click="deleteGrouping(scope.row)" style="color: #ff0000b0">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页--问卷列表 -->
        <el-pagination
          @current-change="groupingPage_data"
          :current-page.sync="groupingPage"
          :page-size="10"
          @size-change="groupSizeChange"
          :page-sizes="[10, 20, 30, 40]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="groupingTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- 新增问题 -->
    <el-dialog title="新增问题" :visible.sync="addquestion" class="el-addquestion" width="400px" @close="closeDialog">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">新增问题</span>
      </template>
      <el-form :model="form" label-position="left" label-width="70px" class="el-form">
        <el-form-item label="问题：">
          <el-input v-model="form.question" placeholder="请输入问题"></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select v-model="form.questionType" placeholder="请选择类型" @change="optionTypeChange">
            <el-option
              v-for="item in questionTypeList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类：">
          <el-select v-model="form.tag" placeholder="请选择分类">
            <el-option
              v-for="item in tagList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-for="(item,index) in form.optionList" :label="'选项' + (index+1) + '：'" :key="index" v-show="revisedFormShow">
          <el-input v-model="item.option" placeholder="请输入选项"></el-input>
          <el-button @click.prevent="removeOption(item)" style="color: #ff0000b0">删除</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="addOption" v-show="revisedFormShow">新增选项</el-button>
        <el-button type="primary" @click="submitForm()">提交</el-button>
      </div>
    </el-dialog>
    <!-- 修改问题 -->
    <el-dialog title="修改问题" :visible.sync="revisequestion" class="el-addquestion" width="550px"  @close="closeRevise">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">修改问题</span>
      </template>
      <el-form :model="revisedForm" label-position="left" label-width="100px" class="el-form">
        <el-form-item label="问题：">
          <el-input v-model="revisedForm.question" placeholder="请输入问题"></el-input>
        </el-form-item>
        <el-form-item label="类型：">
          <el-select v-model="revisedForm.questionType" placeholder="请选择类型" @change="optionTypeChange">
            <el-option
              v-for="item in questionTypeList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-for="(item,index) in revisedForm.optionList" :label="'选项' + (index+1) + '：'" :key="index" v-show="revisedFormShow">
          <el-input v-model="item.optionLable" placeholder="请输入选项" @input="refresh(item.id)"></el-input>
          <el-button @click.prevent="removeReviseOption(item)" style="color: #ff0000b0">删除</el-button>
          <el-button @click.prevent="holdReviseOption(item)" v-show="holdReviseShow['show_'+item.id]">保存</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="addReviseOption" v-show="revisedFormShow">新增选项</el-button>
        <el-button type="primary" @click="revised()">修改</el-button>
      </div>
    </el-dialog>
    <!-- 新增问卷 -->
    <el-dialog title="新增问卷" :visible.sync="addGrouping" class="el-addGrouping" width="550px"  @close="addGroupingClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">新增问卷</span>
      </template>
      <span>问卷名：</span>
      <el-input v-model="addGroupingName" placeholder="请输入问卷名"></el-input>
      <el-transfer
        filterable
        filter-placeholder="请输入问题"
        v-model="questionManageValue"
        :data="questionManageData"
        :titles="['可选择','使用中']"
        target-order="push"
        class="transferClass"
      ></el-transfer>
      <!-- 预览 -->
      <el-dialog width="550px" title="预览" :visible.sync="previewVisible" append-to-body class="projectQuestion-form">
        <template slot="title">
          <i class="el-icon-view dialog-title-my" style="color:#1296db;"></i>
          <span class="dialog-title-my my-title">预览</span>
        </template>
        <el-form label-position="right">
          <el-form-item  v-for="item in GroupQuestionList" :key="item.id" :label="item.newQuestion" label-width="160px" >
            <el-radio-group v-if="item.questionType=='RADIO'">
              <el-radio v-for="muen in item.optionList" :key="muen.id" :label="muen.id" disabled>{{muen.option}}</el-radio>
            </el-radio-group>

            <el-input v-if="item.questionType=='INPUT'" :placeholder="'请输入'+item.question" class="question_input" disabled></el-input>

            <el-input v-if="item.questionType=='DISTRICT_SELECTOR'" :placeholder="'请选择'+item.question" class="question_input" disabled></el-input>

            <el-input v-if="item.questionType=='CAR_BRAND_SELECTOR'" :placeholder="'请选择'+item.question" class="question_input" disabled></el-input>

            <el-input v-if="item.questionType=='TEXT'" :rows="8" type="textarea" resize="none" class="question_textarea" disabled></el-input>

            <el-checkbox-group v-if="item.questionType=='CHECKBOX'">
              <el-checkbox  v-for="muen in item.optionList" :key="muen.id" :label="muen.id" disabled>{{muen.option}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-dialog>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="reviseGrouping_submit()">提 交</el-button>
        <el-button @click="previewClick">预 览</el-button>
      </div>
    </el-dialog>
    <!-- 修改问卷 -->
    <el-dialog title="修改问卷" :visible.sync="reviseGroupingShow" class="el-addGrouping" width="550px"  @close="reviseGroupingClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">修改问卷</span>
      </template>
      <span>问卷名：</span>
      <el-input v-model="groupingText" placeholder="请输入问卷名"></el-input>
      <el-transfer
        filterable
        filter-placeholder="请输入问题"
        v-model="groupingManageValue"
        :data="groupingManageData"
        :titles="['可选择','使用中']"
        target-order="push"
        class="transferClass"
      ></el-transfer>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="reviseGroupingName_submit">确 定</el-button>
        <el-button @click="previewGrouping">预 览</el-button>
      </div>
    </el-dialog>
      <!-- 预览 -->
    <el-dialog width="550px" title="预览" :visible.sync="previewGroupingVisible" class="projectQuestion-form">
      <template slot="title">
        <i class="el-icon-view dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">预览</span>
      </template>
      <el-form label-position="right" @close="previewGroupVisibleClose">
        <el-form-item  v-for="item in GroupQuestionList" :key="item.id" :label="item.newQuestion" label-width="160px" >
          <el-radio-group v-if="item.questionType=='RADIO'">
            <el-radio v-for="muen in item.optionList" :key="muen.id" :label="muen.id" disabled>{{muen.option}}</el-radio>
          </el-radio-group>

          <el-input v-if="item.questionType=='INPUT'" :placeholder="'请输入'+item.question" class="question_input" disabled></el-input>

          <el-input v-if="item.questionType=='DISTRICT_SELECTOR'" :placeholder="'请选择'+item.question" class="question_input" disabled></el-input>

          <el-input v-if="item.questionType=='CAR_BRAND_SELECTOR'" :placeholder="'请选择'+item.question" class="question_input" disabled></el-input>

          <el-input v-if="item.questionType=='TEXT'" :rows="8" type="textarea" resize="none" class="question_textarea" disabled></el-input>

          <el-checkbox-group v-if="item.questionType=='CHECKBOX'">
            <el-checkbox  v-for="muen in item.optionList" :key="muen.id" :label="muen.id" disabled>{{muen.option}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
  <script>
  export default {
    data() {
      return {
        loading: false,
        revisedFormShow:true,
        questionId:'',
        holdReviseShow:{},
        revisequestion:false,
        addquestion:false,
        tableData: null,
        currentPage: null,
        total: null,
        form:{
          questionType:"",
          question:"",
          optionList:[{option:""}],
          tag:''
        },
        tagList:[
          {value:'教育',label:'教育'},
          {value:'金融',label:'金融'},
          {value:'股票',label:'股票'},
          {value:'通用',label:'通用'}
        ],
        revisedForm:{
          questionType:"",
          question:"",
          optionList:[{option:""}]
        },
        questionTypeList:[
          {value:"RADIO",label:"单选"},
          {value:"CHECKBOX",label:"多选"},
          {value:"INPUT",label:"输入文本"},
          {value:"TEXT",label:"文本框"},
          {value:"DISTRICT_SELECTOR",label:"地址选择器"},
          {value:"CAR_BRAND_SELECTOR",label:"汽车品牌选择器"},
        ],
        tableGroupingData:[],
        addGrouping:false,
        previewVisible:false,
        projectQuestionList:[],
        questionManageValue:[],
        questionManageData:[],
        pinyin:[],
        leftCheckId:[],
        addGroupingName:'',
        groupingList:[],
        reviseGroupingShow:false,
        groupingText:'',
        groupingId:'',
        groupingManageValue:[],
        groupingManageData:[],
        groupingPinyin:[],
        groupingPage:1,
        groupingTotal:null,
        allSipQuestion:[],
        previewGroupingVisible:false,
        GroupQuestionList:[],
        userLoginName:'',
        pageSize:10,
        groupSize:10,
      };
    },
    mounted() {
      document.title = "惠销平台管理系统-问卷模板";
      this.inquire();
    },
    methods: {
      tabHandleClick(tab, event){//选择tab
        if(tab.label == '问卷列表'){
          this.inquireQuestionGroupList();
        }
        if(tab.label == '问题列表'){
          this.inquire();
        }
      },
      previewAddGrouping(val){//点击预览--问卷列表
        let params = {
          groupId:val.id
        }
        this.$http('/sip/question/questionGroupDetail',null,'get',null,params).then(res=>{
          if(res.result == 200){
            let list = res.data.allSipQuestion;
            let sipList = res.data.sipQuestionGroup.sipQuestionList;
            this.allSipQuestion = list;
            this.groupingManageValue = [];
            this.groupingManageData = [];
            this.groupingPinyin = [];
            list.forEach((item,index) => {
              this.groupingPinyin.push(item.question);
              this.groupingManageData.push({
                label:item.question,
                key:item.id,
                groupingPinyin:this.groupingPinyin[index]
              });
            });
            sipList.map(menu=>{
              this.groupingManageValue.push(menu.id);
            })
            this.previewGrouping();
          }
        })
      },
      previewGroupVisibleClose(){//关闭预览--问卷列表
        this.groupingManageValue = [];
        this.groupingManageData = [];
        this.allSipQuestion = [];
        this.GroupQuestionList = [];
      },
      currentPage_data(index) {//当前页--问题列表
        this.currentPage = index;
        let params = {
            pageSize: this.pageSize,
            pageNo: this.currentPage,
            queryTemplate:true
          };
        this.questionList(params);
      },
      sizeChange(index) {//显示条数--问题列表
        this.pageSize = index;
        this.currentPage = 1;
        let params = {
            pageSize: this.pageSize,
            pageNo: this.currentPage,
            queryTemplate:true
          };
        this.questionList(params);
      },
      inquire() {//查询列表--问题列表
        this.currentPage = 1;
        let params = {
            pageSize: this.pageSize,
            pageNo: this.currentPage,
            queryTemplate:true
          };
        this.questionList(params);
      },
      questionList(params){//查询列表--问题列表list
        this.loading = true
        this.$http("/sip/question/list", null, "get", null, params).then(res => {
          setTimeout(() => {
            this.loading = false
          }, 200);
          if (res.result == 200) {
            let list = res.data.list;
            this.total = res.data.count;
            list.map(item => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              var option = '';
              if(item.optionList && item.optionList.length>0){
                if(item.questionType == 'INPUT' || item.questionType == 'TEXT'){
                  item.option = ''
                }else{
                  for(let i=0;i<item.optionList.length;i++){
                      option += (item.optionList[i].option+"; ");
                      item.optionList[i].optionLable = (item.optionList[i].option+"|"+item.optionList[i].value);
                  }
                }
              }
              item.option = option;
              switch(item.questionType){
                case 'RADIO':
                item.questionTypeDisplayName = "单选";
                break;
                case 'CHECKBOX':
                item.questionTypeDisplayName = "多选";
                break;
                case 'INPUT':
                item.questionTypeDisplayName = "输入文本";
                break;
                case 'TEXT':
                item.questionTypeDisplayName = "文本框";
                break;
                case 'DISTRICT_SELECTOR':
                item.questionTypeDisplayName = "地址选择器";
                break;
                case 'CAR_BRAND_SELECTOR':
                item.questionTypeDisplayName = "汽车品牌选择器";
                break;
              }
            });
            this.tableData = list;

            this.questionManageData = [];
            this.questionManageValue = [];
            this.groupingList = [];
            this.pinyin = [];
            list.forEach((item,index) => {
              this.pinyin.push(item.question);
              let label = '';
              if(item.optionList){
                item.optionList.forEach((menu,i)=>{
                  label += menu.option+",";
                })
              }
              label = this.$common.answerSplicing(label);// 新增问卷穿梭框显示内容处理(问题+答案)
              let obj = {
                label:item.question+label,
                key:item.id,
                pinyin:this.pinyin[index]
              }
              this.groupingList.push(obj);
            });
            this.questionManageData = this.groupingList;
          }
        });
      },
      inquireQuestionGroupList(){//查询问卷列表
        this.groupingPage = 1;
        let  params = {
            pageSize: this.groupSize,
            pageNo: this.groupingPage,
            // loginName:'nobody',
            groupName:'',
            queryTemplate:true
          };
        this.questionGroupList(params);
      },
      questionGroupList(params){//查询问卷列表list
        this.loading = true
        this.$http('/sip/question/questionGroupList',null,'get',null,params).then(res=>{
          setTimeout(() => {
            this.loading = false
          }, 200);
          if(res.result == 200){
            this.groupingTotal = res.data.count;
            let list = res.data.records;
            list.map(item=>{
              item.createdTime = this.$common.transformTime(item.gmtCreated); 
            })
            this.tableGroupingData = list;
          }
        })
      },
      groupingPage_data(index){//当前页--问卷列表
        this.groupingPage = index;
        let  params = {
            pageSize: this.groupSize,
            pageNo: this.groupingPage,
            // loginName:'nobody',
            groupName:'',
            queryTemplate:true
          };
        this.questionGroupList(params);
      },
      groupSizeChange(index){//显示条数--问卷列表
        this.groupSize = index;
        this.groupingPage = 1;
        let  params = {
            pageSize: this.groupSize,
            pageNo: this.groupingPage,
            // loginName:'nobody',
            groupName:'',
            queryTemplate:true
          };
        this.questionGroupList(params);
      },
      removeOption(item){//新增删除选项--问题列表
        let index = this.form.optionList.indexOf(item)
          if (index !== -1) {
            this.form.optionList.splice(index, 1)
          }
      },
      addOption(){//新增增加选项
        this.form.optionList.push({
            option: '',
          });
      },
      removeReviseOption(item){//删除修改选项
        let data = {
          questionId:this.questionId,
          optionId:item.id,
          loginName:this.userLoginName,
          delete:true
        }
        let index = this.revisedForm.optionList.indexOf(item)
        if(!item.id){ // 判断是否有id来确认删除是否执行接口
          if (index !== -1) {
              this.revisedForm.optionList.splice(index, 1)
            }
          this.$message({message:'删除成功',type:'success'});
          return;
        }
        this.$http('/sip/question/modifyOption',data).then(res=>{
          if(res.result == 200){
            if (index !== -1) {
              this.revisedForm.optionList.splice(index, 1)
            }
            this.$message({message:'删除成功',type:'success'});
          }
        })
        
      },
      holdReviseOption(item){//保存修改选项--问题列表
        if(!item.id){
          let data = {
            questionId:this.questionId,
            option:item.optionLable
          }
          this.$http('/sip/question/addOption',data).then(res=>{// 判断是否有id来确认是否是新增操作
            if(res.result == 200){
              item.id = res.data.id;
              this.$message({message:'保存成功',type:'success'});
              this.holdReviseShow['show_'+item.id] = false;
            }
          })
          return;
        }
        let newData = {
          questionId:this.questionId,
          optionId:item.id,
          option:item.optionLable,
          delete:false
        }
        this.$http('/sip/question/modifyOption',newData).then(res=>{// 判断是否有id来确认是否是修改操作
          if(res.result == 200){
            this.$message({message:'保存成功',type:'success'});
            this.holdReviseShow['show_'+item.id] = false;
          }
        })
      },
      addReviseOption(){//修改增加选项
        if(this.revisedForm.optionList && this.revisedForm.optionList.length>0){
          this.revisedForm.optionList.push({
            optionLable: '',
          });
        }else{
          this.revisedForm.optionList = [];
          this.revisedForm.optionList.push({
            optionLable: '',
          });
        }
      },
      refresh(id){//修改选项内容变化
        this.holdReviseShow['show_'+id] = true
      },
      submitForm(){//提交新增--问题列表
        let options = [];
        let option = ''
        this.form.optionList.map(item=>{
          options.push(item.option)
        })
        
        if(options && options.length>0){
          option = options.join(";")
        }
  
        let data={
          question:this.form.question,
          questionType:this.form.questionType,
          options:option,
          addTemplate:true,
          tag:this.form.tag
        }
        this.$http('/sip/question/add',data).then(res=>{
          if(res.result == 200){
            this.$message({message:'新增成功',type:'success'});
            this.addquestion = false;
            this.inquire();
          }
        })
      },
      closeDialog(){//关闭新增弹窗
        this.form.questionType="";
        this.form.question="";
        this.form.optionList=[{option:""}];
      },
      closeRevise(){//关闭修弹窗--问题列表
        this.holdReviseShow = {}
        let params = {
            pageSize: this.pageSize,
            pageNo: this.currentPage,
            queryTemplate:true
          };
        this.questionList(params);
      },
      reviseQuestion(val){//点击修改--问题列表
        this.revisequestion = true;
        this.revisedForm.question = val.question;
        this.revisedForm.questionType = val.questionType;
        this.revisedForm.optionList = val.optionList;
        this.questionId = val.id;
        this.userLoginName = val.userLoginName
        this.optionTypeChange(val.questionType)
      },
      deleteQuestion(val){//点击删除--问题列表
        let data = {
          questionId:val.id,
          delete:true,
          isSystem:true,
        }
        this.$confirm("【"+val.question+"】删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.$http('/sip/question/modifyQuestion',data).then(res=>{
            if(res.result == 200){
              this.$message({message:'删除成功',type:'success'});
              let params = {
                  pageSize: this.pageSize,
                  pageNo: this.currentPage,
                  queryTemplate:true
                };
              this.questionList(params);
            }
          })
        })
        .catch(() => {});
      },
      revised(){//提交修改--问题列表
        let newRevisedForm = this.revisedForm;
        
        let data = {
          questionId:this.questionId,
          question:newRevisedForm.question,
          questionType:newRevisedForm.questionType,
          isSystem:true
        }
        
        this.$http('/sip/question/modifyQuestion',data).then(res=>{
          if(res.result == 200){
            this.$message({message:'修改成功',type:'success'});
            this.revisequestion = false;
          }
        })
      },
      optionTypeChange(val){//显示选项
        if(val=='CHECKBOX' || val=='RADIO'){
          this.revisedFormShow = true;
        }else{
          this.revisedFormShow = false;
        }
      },
      reviseGrouping(val){//点击修改--问卷列表
        this.reviseGroupingShow = true;
        this.groupingText = val.groupName;
        this.groupingId = val.id;
        let params = {
          groupId:val.id
        }
        this.inquirequestionGroupDetail(params);
      },
      reviseGroupingName_submit(){//点击确定修改--修改问卷列表
        let data = {
          groupId:this.groupingId,
          groupName:this.groupingText,
          questionIds:''
        }
        data.questionIds = this.groupingManageValue.join(",");
        this.$http("/sip/question/modifyQuestionGroup",data).then(res=>{
          if(res.result == 200){
            this.$message({message:'修改成功',type:'success'});
            this.reviseGroupingShow = false;
            let  params = {
              pageSize: this.groupSize,
              pageNo: this.groupingPage,
              // loginName:'nobody',
              groupName:'',
              queryTemplate:true
            };
            this.questionGroupList(params);
          }
        })
      },
      previewGrouping(){//点击预览--修改问卷列表
        this.previewGroupingVisible = true;
        this.GroupQuestionList = [];
        let list = this.allSipQuestion,
            question = this.groupingManageValue;
        question.map(j=>{
          list.map(i=>{
            if(j == i.id){
              i.newQuestion = i.question + "：";
              this.GroupQuestionList.push(i)
            }
          })
        })
      },
      reviseGroupingClose(){//关闭修改问卷列表
        this.groupingManageValue = [];
        this.groupingManageData = [];
        this.groupingText = '';
        this.allSipQuestion = [];
        this.GroupQuestionList = [];
      },
      inquirequestionGroupDetail(params){//查询问卷列表详情
        this.$http('/sip/question/questionGroupDetail',null,'get',null,params).then(res=>{
          if(res.result == 200){
            let list = res.data.allSipQuestion;
            let sipList = res.data.sipQuestionGroup.sipQuestionList;
            this.allSipQuestion = list;
            this.groupingManageValue = [];
            this.groupingManageData = [];
            this.groupingPinyin = [];
            list.forEach((item,index) => {
              this.groupingPinyin.push(item.question);
              let label = '';
              if(item.optionList){
                item.optionList.forEach((menu,i)=>{
                  label += menu.option+",";
                })
              }
              label = this.$common.answerSplicing(label);// 修改问卷穿梭框显示内容处理(问题+答案)
              let obj = {
                label:item.question+label,
                key:item.id,
                groupingPinyin:this.groupingPinyin[index]
              }
              this.groupingManageData.push(obj);
            });
            sipList.map(menu=>{
              this.groupingManageValue.push(menu.id);
            })
          }
        })
      },
      deleteGrouping(val){//点击删除--问卷列表
        let data = {
          questionGroupId:val.id,
          loginName:val.userLoginName
        }
        this.$confirm("【"+val.groupName+"】删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.$http('/sip/question/deleteQuestionGroup',data).then(res=>{
            if(res.result == 200){
              this.$message({message:'删除成功',type:'success'});
              let  params = {
                pageSize: this.groupSize,
                pageNo: this.groupingPage,
                // loginName:'nobody',
                groupName:'',
                queryTemplate:true
              };
              this.questionGroupList(params);
            }
          })
        })
        .catch(() => {});
      },
      addGroupingClick(){//点击新增问卷列表
        this.addGrouping = true;
        this.inquire(1000);
        // this.questionManageData = this.groupingList;
      },
      reviseGrouping_submit(){//点击提交--问卷列表
        let data = {
          groupName:this.addGroupingName,
          questionIds:'',
          addTemplate:true
        }
        data.questionIds = this.questionManageValue.join(",");
        this.$http('/sip/question/addQuestionGroup',data).then(res=>{
          if(res.result == 200){
            this.$message({message:'新增成功',type:'success'});
            this.addGrouping = false;
            this.inquireQuestionGroupList();
          }
        })
      },
      addGroupingClose(){//关闭新增问卷列表
        this.questionManageData = [];
        this.questionManageValue = [];
        this.addGroupingName = '';
        this.GroupQuestionList = [];
      },
      previewClick(){//点击预览--问卷列表
        this.previewVisible = true;
        this.GroupQuestionList = [];
        let list = this.tableData,
            question = this.questionManageValue;
        question.map(j=>{
          list.map(i=>{
            if(j == i.id){
              i.newQuestion = i.question + "：";
              this.GroupQuestionList.push(i)
            }
          })
        })
      },
    },
  };
  </script>
  <style scoped>
  .el-tabs {
    margin-bottom: 20px;
  }
  .table-class >>> .el-table__fixed-right{
    height: 100% !important;
  }
  .transferClass >>> .el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover{
    color: #C0C4CC !important;
    background-color: #fff;
    border-color: #ebeef5;
  }
  .transferClass >>> .el-transfer-panel__item{
    display: block !important;
  }
  .transferClass >>> .el-transfer__button:first-child{
    margin-bottom: 10px !important;
  }
  .transferClass >>> .el-transfer__button{
    display: block !important;
    margin: 0 auto !important;
    padding: 10px !important;
    border-radius: 50% !important;
    font-size: 0 !important;
  }
  .el-addGrouping >>> .el-dialog__body{
    padding: 10px 20px 0px 20px;
  }
  .el-addGrouping .el-input,.el-addGrouping .el-select{
    margin-bottom: 10px;
  }
  .el-table {
    margin: 20px 0px;
  }
  .el-pagination {
    float: right;
    text-align: right;
  }
  .el-form >>> .el-input__inner{
    width: 217px;
  }
  .el-input{
    width: 215px;
  }
  .el-addquestion >>> .el-dialog__body{
    padding-bottom: 0;
  }
  .question_textarea{
    width: 80%;
  }
  .question_input{
    width: 60%;
  }
  </style>
  <style>
  .btn-custom-cancel {
    float: right;
    margin-left: 10px !important;
  }
  </style>
  