<template>
  <div class="achievementStatistics">
    <div class="a_nav">
      <div class="callTime">
        运营主管：
        <el-select v-model="managerId" placeholder="请选择运营主管" clearable>
          <el-option
            v-for="item in managerList"
            :key="item.managerId"
            :value="item.managerId"
            :label="item.userName"
          >
          </el-option>
        </el-select>
      </div>
      <div class="callTime">
        时间范围：
        <el-date-picker
          v-model="condition.gmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
        ></el-date-picker>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="achievementTnquire(1)">查询</el-button>
      <el-button v-if="roleMenu == 'SUPER_MANAGER'" @click="setInversionRate"
        >转化率设置</el-button
      >
      <el-button v-if="roleMenu == 'SUPER_MANAGER'" @click="conversionRateClick"
        >转化率记录</el-button
      >
      <el-button
        type="success"
        v-if="roleMenu == 'SUPER_MANAGER' || 'WECHAT_MANAGER'"
        @click="exportClick"
        :disabled="derivedDis"
        >导出</el-button
      >
      <el-checkbox v-model="filterQuit" style="margin-left: 20px"
        >过滤离职</el-checkbox
      >
    </div>
    <!-- 业绩列表 -->
    <el-table
      :data="achievementTableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
    >
      <el-table-column
        prop="operator"
        label="跟进运营"
        min-width="150"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.operatorLabel }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="label"
        label="标签"
        min-width="150"
        align="center"
        v-if="dateRangeShow"
      ></el-table-column>
      <el-table-column
        prop="fanCount"
        label="来源进粉量"
        min-width="150"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>来源进粉量</span>
          <el-tooltip
            class="item"
            effect="light"
            content="通过好友申请数量"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-popover
            :disabled="
              roleMenu !== 'SUPER_MANAGER' &&
              roleMenu !== 'WECHAT_SUPER_MANAGER' &&
              roleMenu !== 'LIULIANG_OPERATOR'
            "
            placement="right"
            width="450"
            @show="transferClick(scope.row.performanceEntryMap)"
            trigger="click"
            class="purple1-count-transfer"
          >
            <!-- 来源数据 -->
            <el-table :data="transferData" size="mini" stripe border>
              <el-table-column
                property="source"
                label="来源"
                align="center"
              ></el-table-column>
              <el-table-column
                property="fanCount"
                label="进粉量"
                align="center"
              ></el-table-column>
            </el-table>
            <a href="javascript:;" slot="reference" style="color: #3a8ee6">{{
              scope.row.fanCount
            }}</a>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="expectPayCount"
        label="业绩目标"
        min-width="150"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>业绩目标</span>
          <el-tooltip
            class="item"
            effect="light"
            content="通过来源进粉量乘以转化率计算得出 并将结果向上取整"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="payCount"
        label="来源支付量"
        min-width="150"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>来源支付量</span>
          <el-tooltip
            class="item"
            effect="light"
            content="报名科目数量"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="450"
            @show="transferClick(scope.row.performanceEntryMap)"
            trigger="click"
            class="purple1-count-transfer"
          >
            <!-- 来源数据 -->
            <el-table :data="transferData" size="mini" stripe border>
              <el-table-column
                property="source"
                label="来源"
                align="center"
              ></el-table-column>
              <el-table-column
                property="payCount"
                label="支付量"
                align="center"
              ></el-table-column>
            </el-table>
            <a href="javascript:;" slot="reference" style="color: #3a8ee6">{{
              scope.row.payCount
            }}</a>
          </el-popover>
        </template>
      </el-table-column>
      <!-- </el-table-column> -->
      <el-table-column
        prop="achieveRate"
        label="目标达成率"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span :style="{ color: scope.row.achieveRateColor }">{{
            scope.row.achieveRateItem
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="actualPayCount"
        label="实际支付量"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.actualPayCount }}</span>
          <el-button
            type="text"
            @click="setActualPayCount(scope.row)"
            v-if="scope.row.allowReport"
            >上报</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="actualAchieveRate"
        label="实际目标达成率"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span :style="{ color: scope.row.actualAchieveRateColor }">{{
            scope.row.actualAchieveRateItem
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="performanceCount"
        label="业绩绩效统计"
        min-width="150"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>业绩绩效统计</span>
          <el-tooltip
            class="item"
            effect="light"
            content="报名科目数量"
            placement="left-end"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-popover
            placement="left-end"
            width="450"
            @show="performabceClick(scope.row.performanceList)"
            trigger="click"
            class="purple1-count-transfer"
          >
            <!-- 业绩绩效统计 -->
            <el-table :data="transferData1" size="mini" stripe border>
              <el-table-column
                property="productType"
                label="产品类型"
                align="center"
              ></el-table-column>
              <el-table-column
                property="performanceCount"
                label="业绩绩效统计"
                align="center"
              ></el-table-column>
            </el-table>
            <a href="javascript:;" slot="reference" style="color: #3a8ee6">{{
              scope.row.performanceCount
            }}</a>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="attentionRate"
        label="关注率"
        min-width="150"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>关注率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="关注量/进粉量"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="markingRateLabel"
        label="打标率"
        min-width="150"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>打标率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="今日三方订单量(支付时间为今天) / 实际支付量"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="userAddClientCount"
        label="新增转粉"
        min-width="150"
        align="center"
      ></el-table-column> -->
      <!-- <el-table-column
        prop="user2ClientRateLabel"
        label="新增转粉率"
        min-width="150"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>新增转粉率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="新增转粉 / 实际支付量"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="explainAchieveRate-style">
      {{ explainAchieveRate }}
    </div>
    <!-- 分页 -->
    <el-pagination
      @size-change="achievementPageSizeChange"
      @current-change="achievementCurrentPageNo"
      :current-page.sync="achievementPageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="achievementTotal"
    ></el-pagination>
    <!-- 转化率设置 -->
    <el-dialog
      title="转化率设置"
      :visible.sync="setInversionRateShow"
      width="800px"
      class="el-changeLine"
      @close="setInversionRateClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">转化率设置</span>
      </template>
      <el-form label-position="left" label-width="170px" class="el-form">
        <el-form-item label="挂机微信：">
          <div
            v-for="(item, index) in hungUpData"
            :key="index"
            class="rate-div"
          >
            <el-select
              v-model="item.machineGroup"
              multiple
              collapse-tags
              style="width: 200px"
              placeholder="请选择机器分组"
            >
              <el-option
                v-for="item in wechatGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.conversionRate"
              placeholder="请输入挂机微信转化率 例如10"
              style="width: 270px"
            ></el-input>
            <i
              class="el-icon-remove-outline m-delete-button"
              v-if="hungUpData.length > 1"
              @click="removeRate(item, 'hungUp')"
            ></i>
          </div>
          <div>
            <el-button
              size="mini"
              icon="el-icon-plus"
              style="width: 475px"
              @click="addRate('hungUp')"
              >添加</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="座席电话沟通：">
          <div
            v-for="(item, index) in clientTalkingData"
            :key="index"
            class="rate-div"
          >
            <el-select
              v-model="item.machineGroup"
              multiple
              collapse-tags
              style="width: 200px"
              placeholder="请选择机器分组"
            >
              <el-option
                v-for="item in wechatGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.conversionRate"
              placeholder="请输入坐席沟通转化率 例如10"
              style="width: 270px"
            ></el-input>
            <i
              class="el-icon-remove-outline m-delete-button"
              v-if="clientTalkingData.length > 1"
              @click="removeRate(item, 'clientTalking')"
            ></i>
          </div>
          <div>
            <el-button
              size="mini"
              icon="el-icon-plus"
              style="width: 475px"
              @click="addRate('clientTalking')"
              >添加</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="手动加粉：">
          <div
            v-for="(item, index) in manualPayData"
            :key="index"
            class="rate-div"
          >
            <el-select
              v-model="item.machineGroup"
              multiple
              collapse-tags
              style="width: 200px"
              placeholder="请选择机器分组"
            >
              <el-option
                v-for="item in wechatGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.conversionRate"
              placeholder="请输入手动加粉转化率 例如10"
              style="width: 270px"
            ></el-input>
            <i
              class="el-icon-remove-outline m-delete-button"
              v-if="manualPayData.length > 1"
              @click="removeRate(item, 'manualPay')"
            ></i>
          </div>
          <div>
            <el-button
              size="mini"
              icon="el-icon-plus"
              style="width: 475px"
              @click="addRate('manualPay')"
              >添加</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="AI机器人外呼加粉丝：">
          <div
            v-for="(item, index) in robotAddFansData"
            :key="index"
            class="rate-div"
          >
            <el-select
              v-model="item.machineGroup"
              multiple
              collapse-tags
              style="width: 200px"
              placeholder="请选择机器分组"
            >
              <el-option
                v-for="item in wechatGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.conversionRate"
              placeholder="请输入AI机器人外呼加粉丝转化率 例如10"
              style="width: 270px"
            ></el-input>
            <i
              class="el-icon-remove-outline m-delete-button"
              v-if="robotAddFansData.length > 1"
              @click="removeRate(item, 'robotAddFans')"
            ></i>
          </div>
          <div>
            <el-button
              size="mini"
              icon="el-icon-plus"
              style="width: 475px"
              @click="addRate('robotAddFans')"
              >添加</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="YX加粉">
          <div
            v-for="(item, index) in yxAddFansData"
            :key="index"
            class="rate-div"
          >
            <el-select
              v-model="item.machineGroup"
              multiple
              collapse-tags
              style="width: 200px"
              placeholder="请选择机器分组"
            >
              <el-option
                v-for="item in wechatGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.conversionRate"
              placeholder="请输入AI机器人外呼加粉丝转化率 例如10"
              style="width: 270px"
            ></el-input>
            <i
              class="el-icon-remove-outline m-delete-button"
              v-if="yxAddFansData.length > 1"
              @click="removeRate(item, 'yxAddFans')"
            ></i>
          </div>
          <div>
            <el-button
              size="mini"
              icon="el-icon-plus"
              style="width: 475px"
              @click="addRate('yxAddFans')"
              >添加</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="挂机短信点击：">
          <div
            v-for="(item, index) in hangupSMSClickData"
            :key="index"
            class="rate-div"
          >
            <el-select
              v-model="item.machineGroup"
              multiple
              collapse-tags
              style="width: 200px"
              placeholder="请选择机器分组"
            >
              <el-option
                v-for="item in wechatGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.conversionRate"
              placeholder="请输入挂机短信点击转化率 例如10"
              style="width: 270px"
            ></el-input>
            <i
              class="el-icon-remove-outline m-delete-button"
              v-if="hangupSMSClickData.length > 1"
              @click="removeRate(item, 'hangupSMSClick')"
            ></i>
          </div>
          <div>
            <el-button
              size="mini"
              icon="el-icon-plus"
              style="width: 475px"
              @click="addRate('hangupSMSClick')"
              >添加</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="盲加家长粉：">
          <div
            v-for="(item, index) in flagWechatData"
            :key="index"
            class="rate-div"
          >
            <el-select
              v-model="item.machineGroup"
              multiple
              collapse-tags
              style="width: 200px"
              placeholder="请选择机器分组"
            >
              <el-option
                v-for="item in wechatGroupList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.conversionRate"
              placeholder="请输入盲加家长粉转化率 例如10"
              style="width: 270px"
            ></el-input>
            <i
              class="el-icon-remove-outline m-delete-button"
              v-if="flagWechatData.length > 1"
              @click="removeRate(item, 'flagWechat')"
            ></i>
          </div>
          <div>
            <el-button
              size="mini"
              icon="el-icon-plus"
              style="width: 475px"
              @click="addRate('flagWechat')"
              >添加</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="setInversionRate_submit"
          >确定</el-button
        >
        <el-button @click="setInversionRateShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 转化率记录 -->
    <el-dialog
      title="转化率记录"
      :visible.sync="conversionRateShow"
      width="1050px"
      class="connectedTable"
      @close="conversionRateClose"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">转化率记录</span>
        <el-tooltip
          content="转化率取的是小于当天零点时间的最新一条数据。
                5月13号设置的转化率不会影响到当天的计算结果，5月14号才会生效。"
          placement="top-start"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i>
        </el-tooltip>
      </template>
      <el-table
        :data="conversionRateTableData"
        size="mini"
        stripe
        style="width: 100%"
        border
        v-loading="loadingTab"
      >
        <el-table-column
          prop="gmtCreated"
          label="日期"
          min-width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="hangupPayRate"
          label="挂机微信"
          min-width="100"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="manualPayRate"
          label="手动加粉"
          width
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="clientPayRate"
          label="座席电话沟通"
          width
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="robotWechat"
          label="AI机器人外呼加粉丝"
          width
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="unFlagWechat"
          label="YX加粉"
          width
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="hangupSmsClickWechat"
          label="挂机短信点击"
          width
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="flagWechat"
          label="盲加家长粉"
          width
          align="center"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <el-pagination 
        @current-change="conversionRatePageNoChange" 
        :current-page.sync="conversionRatePage" 
        :page-size="10" 
        layout="total,prev, pager, next,jumper" 
        :total="conversionRateTotal" 
        :pager-count="5"
      ></el-pagination> -->
    </el-dialog>
    <!-- 设置实际支付量 -->
    <el-dialog
      title="设置实际支付量"
      :visible.sync="actualPayCountShow"
      width="500px"
      @close="actualPayCountClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">设置实际支付量</span>
      </template>
      <el-form label-position="left" label-width="160px" class="el-form">
        <el-form-item label="日期：">
          <el-date-picker
            v-model="actualPayCountDate"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="actualPayCountDateChange"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in orderCountInfoData"
          :key="index"
          :label="item.productType + '：'"
        >
          <el-input
            v-model="item.orderCount"
            placeholder="请输入实际支付量"
            :disabled="!item.allowUpdate"
            clearable
            style="width: 220px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="setActualPayCount_submit"
          >确定</el-button
        >
        <el-button @click="actualPayCountShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "achievementStatistics",
  props: ["roleMenu"],
  data() {
    return {
      filterQuit: true,
      explainAchieveRate: "",
      // "目标达标率：<60% 六分之一进粉，60%-80% 三分之一进粉， 80%-100% 三分之二进粉， >100% 正常进粉",
      transferData: [],
      transferData1: [],
      orderCountInfoData: {},
      actualPayCountShow: false,
      managerId: "",
      managerList: [],
      condition: {
        gmtCreatedDate: [],
      },
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      loadingTable: false,
      achievementTableData: [],
      achievementTotal: 0,
      achievementPageNo: 1,
      achievementPageSize: 10,
      mergeCount: 3,
      conversionRateShow: false,
      loadingTab: false,
      conversionRateTableData: [],
      conversionRatePage: 1,
      conversionRateTotal: 0,
      setInversionRateShow: false,
      wechatGroupList: [],
      hungUpData: [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ],
      clientTalkingData: [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ],
      manualPayData: [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ],
      robotAddFansData: [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ],
      yxAddFansData: [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ],
      hangupSMSClickData: [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ],
      flagWechatData: [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ],
      clientTalkingRate: "",
      manualPayRate: "",
      robotAddFans: "",
      flagWechat: "",
      hangupSMSClick: "",
      derivedDis: true,
      actualPayCountDate: "",
      operator: "",
      dateRangeShow: false,
    };
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreatedDate = [start, end];
    this.theWeek();
    this.achievementTnquire(1);
    this.getManagerList();
    this.getWechatClientGroupList();
    this.getEditLabel();
    console.log(this.roleMenu);
    // SUPER_MANAGER
  },
  methods: {
    getDateRangeShow() {
      if (this.condition.gmtCreatedDate) {
        let end = new Date(this.condition.gmtCreatedDate[1]),
          start = new Date(this.condition.gmtCreatedDate[0]);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        start = start.valueOf();
        end = end.valueOf();
        let diff = start - end;
        if (diff === 0) {
          this.dateRangeShow = true;
          return;
        }
      }
      this.dateRangeShow = false;
    },
    // 每周
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 获取业绩看板标签
    getEditLabel() {
      this.$http("/wechat/tab/getLabel", null, "get").then((res) => {
        if (res.result === 200) {
          this.explainAchieveRate = res.data;
        }
      });
    },
    // 查询机器分组
    getWechatClientGroupList() {
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: "t9.innerchic.cn",
      };
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatGroupList = list;
        }
      });
    },
    // 点击设置实际支付量
    setActualPayCount(val) {
      let date = null;
      if (!this.condition.gmtCreatedDate) {
        date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        // date.setTime(date.getTime() - 3600 * 1000 * 24);
      } else {
        let end = this.condition.gmtCreatedDate[1];
        date = new Date(end);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        // date.setTime(date.getTime() - 3600 * 1000 * 24);
      }
      this.actualPayCountDate = this.$common.transformTime(date);
      this.operator = val.operator;
      this.actualPayCountShow = true;
      this.getOrderCountInfo();
    },
    // 确定设置实际支付量
    setActualPayCount_submit() {
      let data = {
        date: this.actualPayCountDate,
        operator: this.operator,
        orderCountMap: {},
      };
      for (let key in this.orderCountInfoData) {
        data.orderCountMap[this.orderCountInfoData[key].productType] = Number(
          this.orderCountInfoData[key].orderCount
        );
      }
      this.$service("/wechat/orderCountInfo/set", data).then((res) => {
        if (res.result === 200) {
          this.actualPayCountShow = false;
          this.$message.success("设置成功");
          this.achievementTnquire();
        }
      });
    },
    // 关闭设置实际支付量
    actualPayCountClose() {
      this.orderCountInfoData = {};
    },
    // 设置实际支付量时间选择
    actualPayCountDateChange(val) {
      this.getOrderCountInfo();
    },
    // 获取指定运营每个产品的成单量
    getOrderCountInfo() {
      let data = {
        date: this.actualPayCountDate,
        operator: this.operator,
      };
      this.$http("/wechat/orderCountInfo", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            this.orderCountInfoData = list;
          }
        }
      );
    },
    // 查询主管列表
    getManagerList() {
      this.$http("/wechat/managerList", null, "get").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.managerList = list;
        }
      });
    },
    // 点击导出
    exportClick() {
      let start = this.condition.gmtCreatedDate[0];
      let end = this.condition.gmtCreatedDate[1];
      let params = {
        managerId: this.managerId,
        start: start,
        end: end,
      };
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      url = url + "&filterQuit=" + this.filterQuit;
      let href =
        this.$constants.baseURL +
        "/wechat/report/expectPerformance/export?" +
        url;
      // let href = 'http://192.168.1.129:9997'+"/call400Stats/stats/export?"+url;
      // window.location.href = href;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      });
    },
    // 点击转化率设置
    setInversionRate() {
      this.setInversionRateShow = true;
      let data = {
        gmtCreated: this.$common.transformTime(new Date()),
      };
      this.getConversionRate(data, "one");
    },
    // 点击添加转化率
    addRate(type) {
      console.log(type);
      let data = {
        machineGroup: [],
        conversionRate: "",
      };
      if (type === "hungUp") {
        this.hungUpData.push(data);
      }
      if (type === "clientTalking") {
        this.clientTalkingData.push(data);
      }
      if (type === "manualPay") {
        this.manualPayData.push(data);
      }
      if (type === "robotAddFans") {
        this.robotAddFansData.push(data);
      }
      if (type === "yxAddFans") {
        this.yxAddFansData.push(data);
      }
      if (type === "hangupSMSClick") {
        this.hangupSMSClickData.push(data);
      }
      if (type === "flagWechat") {
        this.flagWechatData.push(data);
      }
    },
    // 点击删除转化率
    removeRate(val, type) {
      if (type === "hungUp") {
        let index = this.hungUpData.indexOf(val);
        this.hungUpData.splice(index, 1);
      }
      if (type === "clientTalking") {
        let index = this.clientTalkingData.indexOf(val);
        this.clientTalkingData.splice(index, 1);
      }
      if (type === "manualPay") {
        let index = this.manualPayData.indexOf(val);
        this.manualPayData.splice(index, 1);
      }
      if (type === "robotAddFans") {
        let index = this.robotAddFansData.indexOf(val);
        this.robotAddFansData.splice(index, 1);
      }
      if (type === "yxAddFans") {
        let index = this.yxAddFansData.indexOf(val);
        this.yxAddFansData.splice(index, 1);
      }
      if (type === "hangupSMSClick") {
        let index = this.hangupSMSClickData.indexOf(val);
        this.hangupSMSClickData.splice(index, 1);
      }
      if (type === "flagWechat") {
        let index = this.flagWechatData.indexOf(val);
        this.flagWechatData.splice(index, 1);
      }
    },
    // 转化率数据处理
    setInversionRate_handle(data) {
      let newData = this.$common.deepClone(data);
      for (let i in newData) {
        for (let j in newData[i]) {
          if (j === "machineGroup") {
            newData[i][j] = newData[i][j].join(",");
            if (!newData[i][j]) {
              return;
            }
          } else {
            if (newData[i][j]) {
              newData[i][j] = Number(newData[i][j]);
            } else {
              newData[i][j] = 0;
            }
          }
        }
      }
      return newData;
    },
    // 确定转化率设置
    setInversionRate_submit() {
      let hungUpData = this.setInversionRate_handle(this.hungUpData);
      let clientTalkingData = this.setInversionRate_handle(
        this.clientTalkingData
      );
      let manualPayData = this.setInversionRate_handle(this.manualPayData);
      let robotAddFansData = this.setInversionRate_handle(
        this.robotAddFansData
      );
      let yxAddFansData = this.setInversionRate_handle(this.yxAddFansData);
      let hangupSMSClickData = this.setInversionRate_handle(
        this.hangupSMSClickData
      );
      let flagWechatData = this.setInversionRate_handle(this.flagWechatData);
      let data = {
        hangupPayRate: hungUpData,
        clientPayRate: clientTalkingData,
        manualPayRate: manualPayData,
        robotWechat: robotAddFansData,
        unFlagWechat: yxAddFansData,
        hangupSmsClickWechat: hangupSMSClickData,
        flagWechat: flagWechatData,
      };
      if (
        hungUpData &&
        clientTalkingData &&
        manualPayData &&
        robotAddFansData &&
        yxAddFansData &&
        hangupSMSClickData &&
        flagWechatData
      ) {
        this.$service("/wechat/report/performance/setConfig", data).then(
          (res) => {
            if (res.result === 200) {
              this.$message.success("设置成功");
              this.setInversionRateShow = false;
            }
          }
        );
      } else {
        this.$message.warning("请选择机器分组");
      }
    },
    // 关闭转化率设置
    setInversionRateClose() {
      this.hungUpData = [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ];
      this.clientTalkingData = [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ];
      this.manualPayData = [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ];
      this.robotAddFansData = [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ];
      this.yxAddFansData = [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ];
      this.hangupSMSClickData = [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ];
      this.flagWechatData = [
        {
          conversionRate: "",
          machineGroup: [],
        },
      ];
    },
    // 点击转化率记录
    conversionRateClick() {
      this.conversionRateShow = true;
      this.conversionRateInquire(1);
    },
    // 关闭转化率记录
    conversionRateClose() {
      this.conversionRateTableData = [];
    },
    // 点击查询转化率记录
    conversionRateInquire(index) {
      if (index) {
        this.conversionRatePage = index;
      }
      let data = {
        pageNo: this.conversionRatePage,
        pageSize: 10,
      };
      this.getConversionRate();
    },
    // 查询转化率记录
    getConversionRate(data, key) {
      this.$http(
        "/wechat/report/performance/getNewConfig",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          if (key === "one") {
            this.hungUpData = [];
            this.clientTalkingData = [];
            this.manualPayData = [];
            this.robotAddFansData = [];
            this.yxAddFansData = [];
            this.hangupSMSClickData = [];
            this.flagWechatData = [];
            for (let i in list) {
              if (i === "hangupPayRate") {
                for (let j in list[i]) {
                  let obj = {
                    machineGroup: list[i][j].machineGroup.split(","),
                    conversionRate: list[i][j].conversionRate,
                  };
                  this.hungUpData.push(obj);
                }
              }
              if (i === "clientPayRate") {
                for (let j in list[i]) {
                  let obj = {
                    machineGroup: list[i][j].machineGroup.split(","),
                    conversionRate: list[i][j].conversionRate,
                  };
                  this.clientTalkingData.push(obj);
                }
              }
              if (i === "manualPayRate") {
                for (let j in list[i]) {
                  let obj = {
                    machineGroup: list[i][j].machineGroup.split(","),
                    conversionRate: list[i][j].conversionRate,
                  };
                  this.manualPayData.push(obj);
                }
              }
              if (i === "robotWechat") {
                for (let j in list[i]) {
                  let obj = {
                    machineGroup: list[i][j].machineGroup.split(","),
                    conversionRate: list[i][j].conversionRate,
                  };
                  this.robotAddFansData.push(obj);
                }
              }
              if (i === "unFlagWechat") {
                for (let j in list[i]) {
                  let obj = {
                    machineGroup: list[i][j].machineGroup.split(","),
                    conversionRate: list[i][j].conversionRate,
                  };
                  this.yxAddFansData.push(obj);
                }
              }
              if (i === "hangupSmsClickWechat") {
                for (let j in list[i]) {
                  let obj = {
                    machineGroup: list[i][j].machineGroup.split(","),
                    conversionRate: list[i][j].conversionRate,
                  };
                  this.hangupSMSClickData.push(obj);
                }
              }
              if (i === "flagWechat") {
                for (let j in list[i]) {
                  let obj = {
                    machineGroup: list[i][j].machineGroup.split(","),
                    conversionRate: list[i][j].conversionRate,
                  };
                  this.flagWechatData.push(obj);
                }
              }
            }
          } else {
            let newList = [];
            list.forEach((item) => {
              let obj = {};
              for (let i in item) {
                obj[i] = [];
                for (let j in item[i]) {
                  obj.gmtCreated = item[i][j].gmtCreated;
                  obj[i].push(
                    item[i][j].machineGroup +
                      " : " +
                      item[i][j].conversionRate +
                      "%"
                  );
                }
                obj[i] = obj[i].join(" | ");
              }
              newList.push(obj);
            });
            this.conversionRateTableData = newList;
          }
        }
      });
      // this.$http("/wechat/report/performance/getConfig",null,"get").then(res => {
      //   if (res.result === 200) {
      //     let list = res.data;
      //     list.forEach(item => {
      //       item.createTime = this.$common.transformTime(item.createTime).substring(0,10);
      //       item.clientPayRate = item.clientPayRate + '%';
      //       item.hangupPayRate = item.hangupPayRate + '%';
      //       item.manualPayRate = item.manualPayRate + '%';
      //       item.robotWechat = item.robotWechat + '%';
      //       item.flagWechat = item.flagWechat + '%';
      //       item.hangupSmsClickWechat = item.hangupSmsClickWechat + '%';
      //     })
      //     this.conversionRateTableData = list;
      //   }
      // })
    },
    // 转化率记录--翻页
    conversionRatePageNoChange(index) {
      this.conversionRateInquire(index);
    },
    // 点击查询
    achievementTnquire(index) {
      if (index) {
        this.achievementPageNo = index;
      }
      if (!this.condition.gmtCreatedDate) {
        this.$message.warning("请选择时间范围");
        return;
      }
      let start = this.condition.gmtCreatedDate[0];
      let end = this.condition.gmtCreatedDate[1];
      let data = {
        pageNo: this.achievementPageNo,
        pageSize: this.achievementPageSize,
        managerId: this.managerId,
        start: start,
        end: end,
        filterQuit: this.filterQuit,
      };
      this.getAchievementList(data);
    },
    // 查询业绩列表
    getAchievementList(data) {
      this.loadingTable = true;
      this.derivedDis = true;
      this.achievementTableData = [];
      this.getDateRangeShow();
      this.$http(
        "/wechatStatistics/findWechatPerformanceStatistics",
        null,
        "get",
        null,
        data
      )
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            let summary = res.data.summary;
            summary.operator = "合计";
            this.achievementTotal = res.data.total;
            if (summary) {
              list.push(summary);
            }
            list.forEach((item) => {
              item.achieveRateItem = (item.achieveRate * 100).toFixed(1) + "%";
              item.actualAchieveRateItem =
                (item.actualAchieveRate * 100).toFixed(1) + "%";
              item.markingRateLabel = (item.markingRate * 100).toFixed(1) + "%";
              item.user2ClientRateLabel =
                (item.user2ClientRate * 100).toFixed(1) + "%";
              // item.expectPayCountItem = Math.ceil(item.expectPayCount);
              item.expectPayCountItem = item.expectPayCount;
              item.achieveRateColor = "#606266";
              item.actualAchieveRateColor = "#606266";
              if (item.achieveRate < 0.6) {
                item.achieveRateColor = "#F56C6C";
              }
              if (item.achieveRate >= 0.6 && item.achieveRate <= 0.8) {
                item.achieveRateColor = "#E6A23C";
              }
              if (item.achieveRate > 1) {
                item.achieveRateColor = "#67C23A";
              }
              if (item.actualAchieveRate < 0.6) {
                item.actualAchieveRateColor = "#F56C6C";
              }
              if (
                item.actualAchieveRat >= 0.6 &&
                item.actualAchieveRate <= 0.8
              ) {
                item.actualAchieveRateColor = "#E6A23C";
              }
              if (item.actualAchieveRate > 1) {
                item.actualAchieveRateColor = "#67C23A";
              }
              item.fanCount = 0;
              item.payCount = 0;
              for (let i in item.performanceEntryMap) {
                item.fanCount += item.performanceEntryMap[i].fanCount;
                item.payCount += item.performanceEntryMap[i].payCount;
              }
              if (item.fanCount !== 0) {
                item.attentionRate =
                  ((item.freshFansCount / item.fanCount) * 100).toFixed(1) +
                  "%";
              } else {
                item.attentionRate = "0.0%";
              }

              item.operatorLabel = item.operator;
              if (item.managerUserName) {
                item.operatorLabel =
                  item.operator + " ( " + item.managerUserName + " )";
              }
            });
            this.achievementTableData = list;
            if (this.achievementTableData.length > 0) {
              this.derivedDis = false;
            }
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 业绩列表---分页
    achievementCurrentPageNo(index) {
      this.achievementTnquire(index);
    },
    // 业绩列表---每页
    achievementPageSizeChange(index) {
      this.achievementPageSize = index;
      this.achievementTnquire(1);
    },
    // 显示来源数据
    transferClick(val) {
      this.transferData = [];
      for (let i in val) {
        let title = "";
        let data = {};
        switch (i) {
          case "ROBOT_WECHAT":
            title = "AI机器人外呼加粉丝";
            break;
          case "HANGUP_WECHAT":
            title = "挂机微信";
            break;
          case "CLIENT_TALKING_WECHAT":
            title = "座席电话沟通";
            break;
          case "MANUEL_WECHAT":
            title = "手动加粉";
            break;
          case "HANGUP_SMS_CLICK_WECHAT":
            title = "挂机短信点击";
            break;
          case "FLAG_WECHAT":
            title = "盲加家长粉";
            break;
          case "UN_FLAG_WECHAT":
            title = "YX加粉";
            break;
          case "NOTIFY_WECHAT":
            title = "二次加粉";
            break;
          case "SMS_CLICK_WECHAT":
            title = "短信回调加粉";
            break;
        }
        data = {
          source: title,
          fanCount: val[i].fanCount,
          payCount: val[i].payCount,
        };
        this.transferData.push(data);
      }
    },
    performabceClick(val) {
      // console.log(val)
      this.transferData1=val
    },
  },
};
</script>
<style scoped>
.a_nav {
  margin: 0 0px 20px 0px;
}
.a_nav .callTime {
  display: inline-block;
  margin-right: 20px;
}
.achievementStatistics .el-pagination {
  float: right;
  margin-top: 20px;
}
.achievementStatistics .el-table {
  margin-top: 20px;
}
.connectedTable >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 0 0 20px 0;
}
.explainAchieveRate-style {
  color: #e6a23c;
  font-size: 15px;
  margin-top: 10px;
}
.m-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.m-delete-button:hover {
  color: #777;
}
.m-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.rate-div {
  margin-bottom: 5px;
}
</style>

