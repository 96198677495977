<template>
	<div class="userList">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="用户列表" name="first">
				<div class="userNameClass">用户名称：
					<el-input v-model="userName" placeholder="请输入用户名称" clearable></el-input>
				</div>
				<div class="statusClass">状态：
					<el-select v-model="projectStatus" placeholder="请选择状态" clearable>
						<el-option
							v-for="item in projectStatusList"
							:key="item.status"
							:value="item.status"
							:label="item.label"
						></el-option>
					</el-select>
				</div>
				<div>
					<el-button type="primary" @click="inquire(1)" style="margin-bottom:10px;">查询</el-button>
					<el-button type="primary" @click="addUser" style="margin-bottom:10px;" v-if="roleMenu=='SUPER_MANAGER' || roleMenu== 'WECHAT_MANAGER'">新增</el-button>
				</div>
				<!-- 用户表 -->
				<el-table v-loading="loading" ref="table" :data="tableData" stripe style="width: 100%" border row-key="key" lazy :load="load" @expand-change="expandChange" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" v-if="isTableShow">
					<!-- <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column> -->
					<el-table-column prop="userName" label="用户名称" min-width="200" align="left" show-overflow-tooltip>
						<template slot-scope="scope">
							<span>{{scope.row.userName}}</span>
							<el-tag type="warning" size="mini" v-if="scope.row.accountType === 'clone'">克隆</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="loginName" label="登录名称" min-width="200" align="left" show-overflow-tooltip></el-table-column>
					<el-table-column label="剩余话费(元)" width="120" align="left">
						<template slot-scope="scope">
							<span v-if="!scope.row.routeSetUpDis">{{scope.row.phoneBalance}}</span>
						</template>
					</el-table-column>
					<el-table-column label="透支额度(元)" width="130" align="left">
						<template slot="header">
							<span>透支额度(元)</span>
							<el-tooltip class="item" effect="light" placement="top-start">
								<div slot="content">
									<div>开启透支可以让用户在余额为负(小于透支额度)的情况下继续使用</div>
								</div>
								<i class="el-icon-question icon-color"></i>
							</el-tooltip>
						</template>
						<template slot-scope="scope">
							<span v-if="!scope.row.routeSetUpDis">{{scope.row.overdraft}}</span>
						</template>
					</el-table-column>
					<el-table-column label="今日成单扣除(元)" width="160" align="left">
						<template slot="header">
							<span>今日成单扣除(元)</span>
							<el-tooltip class="item" effect="light" placement="top-start">
								<div slot="content">
									<div>开启后，用户每个成单都会立即冻结部分金额以保证当日成单结算能够顺利进行</div>
								</div>
								<i class="el-icon-question icon-color"></i>
							</el-tooltip>
						</template>
						<template slot-scope="scope">
							<span v-if="!scope.row.routeSetUpDis">{{scope.row.orderDeduction}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="miniAvailableBalance" label="冻结余额(元)" width="130" align="left">
						<template slot="header">
							<span>冻结余额(元)</span>
							<el-tooltip class="item" effect="light" placement="top-start">
								<div slot="content">
									<div>直接冻结部分金额，当用户剩余金额小于冻结金额,服务会立即停止</div>
								</div>
								<i class="el-icon-question icon-color"></i>
							</el-tooltip>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="routeCount" label="线路条数" width="120" align="center"></el-table-column> -->
					<el-table-column label="服务器" align="left" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-if="roleMenu!=='SUPER_MANAGER'">{{scope.row.server}}</span>
							<el-button v-if="roleMenu=='SUPER_MANAGER'" type="text" size="small" @click="trans2Server(scope.row)">{{scope.row.server}}</el-button>
						</template>
					</el-table-column>
					<el-table-column prop="lastLoginDate" label="上次登录时间" width="200" align="center"></el-table-column>
					<el-table-column prop label="操作" width="230" align="center" fixed="right" v-if="roleMenu=='SUPER_MANAGER'">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="getInto(scope.row)">进入</el-button>
							<el-button type="text" size="small" @click="recharge(scope.row)" :disabled="scope.row.rechargeDis">充值</el-button>
							<el-button type="text" size="small" @click="routeSetUp(scope.row,scope.$index)" :disabled="scope.row.routeSetUpDis">线路设置</el-button>
							<!-- <el-button type="text" size="small" @click="overdraftChange(scope.row)">透支额度</el-button> -->
							<el-dropdown trigger="click">
								<span class="el-dropdown-link">
									其他<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-button type="text" size="small" @click="permissionSet(scope.row)">权限设置</el-button>
									<el-button type="text" size="small" @click="remindSet(scope.row)">提醒设置</el-button>
									<el-button type="text" size="small" @click="rechargeRecord(scope.row)" :disabled="scope.row.rechargeRecordDis">充值记录</el-button>
									<el-button type="text" size="small" @click="frozenBalanceClick(scope.row)">冻结余额设置</el-button>
									<el-button type="text" size="small" @click="modifyChargeClick(scope.row)" v-if="scope.row.parentId > -1">变更主账号</el-button>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination @size-change="sizeChange" @current-change="currentPage_data" :current-page.sync="currentPage" :page-size="20" :page-sizes="[10, 20, 30, 40]"	layout="sizes, total, prev, pager, next,jumper" :total="total" :pager-count="5"></el-pagination>
			</el-tab-pane>

			<el-tab-pane label="用户分组" name="second" disabled>
				<!-- <div class="userNameClass">用户名称：
					<el-input v-model="userName" placeholder="请输入用户名称" clearable></el-input>
				</div>
				<div class="statusClass">状态：
					<el-select v-model="projectStatus" placeholder="请选择状态" clearable>
						<el-option
							v-for="item in projectStatusList"
							:key="item.status"
							:value="item.status"
							:label="item.label"
						></el-option>
					</el-select>
				</div> -->
				<div>
					<el-button type="primary" @click="inquireGroup(1)" style="margin-bottom:10px;">查询</el-button>
					<el-button type="primary" @click="addUser" style="margin-bottom:10px;" v-if="roleMenu=='SUPER_MANAGER' || roleMenu== 'WECHAT_MANAGER'">新增</el-button>
				</div>
				<!-- 用户分组 -->
				<el-table v-loading="loading" ref="table" :data="groupTableData" stripe style="width: 100%" border>
					<el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
					<el-table-column prop="userName" label="用户名称" min-width="200" align="left" show-overflow-tooltip></el-table-column>
					<el-table-column prop="loginName" label="登录名称" min-width="200" align="left" show-overflow-tooltip></el-table-column>
					<el-table-column prop="lastLoginDate" label="上次登录时间" width="200" align="center"></el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-pagination @size-change="groupSizeChange" @current-change="groupCurrentPage_data" :current-page.sync="groupCurrentPage" :page-size="20" :page-sizes="[10, 20, 30, 40]"	layout="sizes, total, prev, pager, next,jumper" :total="groupTotal" :pager-count="5"></el-pagination>
			</el-tab-pane>
		</el-tabs>
		<!-- 线路设置 -->
		<el-dialog title="线路设置" :visible.sync="routeSetUpShow" class="el-routeSetUp" width="880px" @close="closerouteSetUp">
			<template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">线路设置</span>
      </template>
			<el-table :data="routeData" border row-key="relationId" :tree-props="{children: 'children'}">
				<el-table-column prop="name" label="线路名称" min-width="150" align="left"></el-table-column>
				<el-table-column prop="ip" label="ip地址" width="140" align="left"></el-table-column>
				<el-table-column prop="provider" label="线路供应商" width="150" align="left"></el-table-column>
				<el-table-column prop="registType" label="线路类型" width="90" align="left"></el-table-column>
				<el-table-column prop="feeDisplay" label="费用" width align="left"></el-table-column>
				<el-table-column prop label="操作" width="120" align="center" fixed="right">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="routeRevise(scope.row)">修改</el-button>
						<el-button type="text" size="small" @click="routeDelete(scope.row)" :disabled="scope.row.deleteDis" style="color: #ff0000b0">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 修改费用 -->
			<el-dialog width="400px" title="修改费用" :visible.sync="reviseRouteShow" append-to-body @close="feeClose">
				<template slot="title">
					<i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
					<span class="dialog-title-my my-title">修改费用</span>
				</template>
				<el-form :model="reviseRouteForm" label-position="left" label-width="140px" class="el-form">
					<el-form-item label="费用：" required>
						<el-input v-model="reviseRouteForm.fee" placeholder="请输入费用" class="feewidth"></el-input>
					</el-form-item>
					<el-form-item label="是否覆盖子线路：" required v-if="overrideShow">
						<el-radio-group v-model="reviseRouteForm.override">
							<el-radio :label="true">覆盖</el-radio>
							<el-radio :label="false">不覆盖</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer" align="center">
					<el-button @click="reviseRouteFee" type="primary">确定</el-button>
				</div>
			</el-dialog>
			<!-- 新增设置 -->
			<el-dialog width="410px" title="新增设置" :visible.sync="addRouteShow" append-to-body @close="addRouteClose">
				<template slot="title">
					<i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
					<span class="dialog-title-my my-title">新增设置</span>
				</template>
				<el-form :model="addRouteForm" label-position="left" label-width="100px" class="el-form">
					<el-form-item label="选择线路：" required>
						<!-- <el-select v-model="addRouteForm.routeId" placeholder="请选择线路" @change="changeRouteId">
							<el-option v-for="item in routeIdList" :key="item.id" :value="item.id" :label="item.name"></el-option>
						</el-select> -->
						<el-cascader 
							v-model="addRouteForm.routeTagId" 
							placeholder="请选择线路" 
							:options="virtualRouteList" 
							:show-all-levels="false" 
							:props="routeProps" 
							@change="routeTagChange"
						></el-cascader>
					</el-form-item>
					<el-form-item label="费用：">
						<el-input v-model="addRouteForm.fee" placeholder="请输入费用" class="addRouteFeewidth"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer" align="center">
					<el-button @click="addLine" type="primary">确定</el-button>
					<el-button @click="addLine_cencel">取消</el-button>
				</div>
			</el-dialog>
			<div slot="footer" class="dialog-footer" align="center">
				<el-button type="primary" @click="addRoute">新增设置</el-button>
			</div>
		</el-dialog>
		<!-- 充值 -->
		<el-dialog title="充值" :visible.sync="rechargeShow" width="500px" @close="closeRecharge">
			<template slot="title">
				<i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">充值</span>
			</template>
			 <el-tabs v-model="rechargeActive">
				<el-tab-pane label="话费充值" name="first">
					<el-form :model="rechargeForm" label-position="left" label-width="100px" class="el-form">
						<el-form-item label="用户：" class="itemClass">
							<el-input v-model="rechargeForm.userName" disabled></el-input>
						</el-form-item>
						<el-form-item label="金额：" required>
							<el-input v-model="rechargeForm.money" @input="handleInput" placeholder="请输入金额"></el-input>
						</el-form-item>
						<el-form-item label="备注：" required>
							<el-input v-model="rechargeForm.remark" type="textarea" :rows="5" placeholder="请输入备注"></el-input>
						</el-form-item>
						<div class="button-center">
							<el-button type="primary" @click="recharge_submit()" :disabled="isRecharge_submit">提 交</el-button>
							<el-button @click="recharge_cancel">取 消</el-button>
						</div>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="短信充值" name="second">
					<el-form :model="rechargeForm" label-position="left" label-width="100px" class="el-form">
						<el-form-item label="用户：" class="itemClass">
							<el-input v-model="rechargeForm.userName" disabled></el-input>
						</el-form-item>
						<el-form-item label="短信条数：" required>
							<el-input v-model="rechargeForm.balance" @input="handleInput" placeholder="请输入短信条数"></el-input>
						</el-form-item>
						<el-form-item label="备注：" required>
							<el-input v-model="rechargeForm.remark" type="textarea" :rows="5" placeholder="请输入备注"></el-input>
						</el-form-item>
						<div class="button-center">
							<el-button type="primary" @click="rechargeCount_submit()" :disabled="isRecharge_submit">提 交</el-button>
							<el-button @click="recharge_cancel">取 消</el-button>
						</div>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<!-- 充值记录 -->
		<el-dialog title="充值记录" :visible.sync="rechargeRecordShow" class="el-rechargeRecord" width="880px" @close="closeRechargeRecord">
			<template slot="title">
				<i class="el-icon-data-analysis dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">充值记录</span>
			</template>
			<el-table :data="rechargeRecordData" stripe style="width: 100%" border>
				<el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
				<el-table-column prop="money" label="金额" min-width="100" align="left"></el-table-column>
				<el-table-column prop="remark" label="备注" min-width="100" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column prop="operator" label="操作人" min-width="100" align="left"></el-table-column>
				<el-table-column prop="gmtCreated" label="时间" min-width="100" align="center"></el-table-column>
			</el-table>
			 <!-- 分页 -->
			 <el-pagination @current-change="rechargeCurrentPage_data" :current-page.sync="rechargeCurrentPage" :page-size="10" layout="total,prev, pager, next,jumper" :total="rechargeTotal" :pager-count="5"></el-pagination>
		</el-dialog>
		<!-- 新增账号 -->
		<el-dialog title="新增账号" :visible.sync="accountShow" class="el-recharge" width="450px" @close="closeAccountShow">
			<template slot="title">
				<i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">新增账号</span>
			</template>
			<el-form label-position="left" label-width="100px" class="el-form">
				<el-form-item label="账号类型：">
					<el-select v-model="addParentId" placeholder="请选择账号类型" @change="addParentIdChange" style="width:250px">
						<el-option
							v-for="item in addParentIdList"
							:key="item.value"
							:value="item.value"
							:label="item.label"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="服务器：" v-if="!isParent">
					<el-select v-model="server" placeholder="请选择服务器" style="width:250px" filterable>
						<el-option
							v-for="item in serverList"
							:key="item.value"
							:value="item.value"
							:label="item.label"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="主账号：" v-if="isParent">
					<el-select v-model="addParentName" placeholder="请选择主账号" filterable remote :loading="loading" :remote-method="addParentNameMethod" @focus="userNameRemoteFocus" style="width:250px">
						<el-option
							v-for="item in addParentNameList"
							:key="item.id"
							:value="item.id"
							:label="item.userName"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户名称：">
					<el-input v-model="addUserName" placeholder="请输入用户名称"></el-input>
				</el-form-item>
				<el-form-item label="登录名称：">
					<el-input v-model="addLoginName" placeholder="请输入登录名称"></el-input>
				</el-form-item>
				<el-form-item label="密码：">
					<el-input v-model="addPassword" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item label="权限：">
					<el-select v-model="addRole" placeholder="请选择角色权限" style="width:250px">
						<el-option
							v-for="item in addRoleList"
							:key="item.id"
							:value="item.id"
							:label="item.roleName"
						></el-option>
					</el-select>
				</el-form-item>
				<div class="button-center">
          <el-button type="primary" @click="account_submit()" :disabled="isAccount_submit">确 定</el-button>
          <el-button @click="accountShow = false">取 消</el-button>
        </div>
			</el-form>
		</el-dialog>
		<!-- 透支额度 -->
		<!-- <el-dialog title="透支额度" :visible.sync="overdraftShow" class="el-recharge" width="400px" @close="closeOverdraft">
			<el-form label-position="left" label-width="100px" class="el-form">
				<el-form-item label="透支额度：">
					<el-input v-model="overdraft"></el-input>
				</el-form-item>
				<div class="button-center">
          <el-button type="primary" @click="overdraft_submit()" :disabled="isOverdraft_submit">修 改</el-button>
          <el-button @click="overdraft_cancel">取 消</el-button>
        </div>
			</el-form>
		</el-dialog> -->
		<!-- 服务器迁移 -->
    <el-dialog title="服务器迁移" :visible.sync="serverMigrationShow" class="el-recharge" width="400px" @close="serverMigrationClose">
			<template slot="title">
				<i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">服务器迁移</span>
			</template>
      <el-form label-position="left">
        <el-form-item label="服务器：" label-width="95px" required>
          <el-select v-model="newServer" placeholder="请选择服务器">
            <el-option
              v-for="item in newServerList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
							:disabled="item.disabled"
            ></el-option>
          </el-select>
				</el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="serverMigration_submit()">确 定</el-button>
          <el-button @click="serverMigrationShow = false">取 消</el-button>
        </div>
			</el-form>
    </el-dialog>
		<!-- 权限设置 -->
    <el-dialog title="权限设置" :visible.sync="permissionSetShow" class="el-recharge" width="400px" @close="permissionSetClose">
			<template slot="title">
				<i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">权限设置</span>
			</template>
      <el-form :model="permissionSetForm" label-position="left">
        <el-form-item label="权限：" label-width="145px">
          <el-select v-model="permissionSetForm.roleId" placeholder="请选择角色权限" clearable :disabled="!isEnabled">
            <el-option
              v-for="item in permissionSetList"
              :key="item.roleId"
              :value="item.roleId"
              :label="item.loginName"
            ></el-option>
          </el-select>
				</el-form-item>
				<el-form-item label="透支额度：" label-width="145px">
					<el-input v-model="overdraft" placeholder="请输入透支额度" style="width:217px" clearable :disabled="!isEnabled"></el-input>
				</el-form-item>
				<el-form-item label="最大并发率：" label-width="145px">
					<el-input v-model="maxRouteMultiple" placeholder="请输入最大并发率" style="width:217px" clearable :disabled="!isEnabled"></el-input>
				</el-form-item>
				<el-form-item label="启用状态：" label-width="145px" class="qi-yong">
					<template slot="label">
						启用状态
						<el-tooltip class="item" content="禁用立即生效, 线上用户以及子账号会被踢下线" placement="top-start">
							<i class="el-icon-question icon-color"></i>
						</el-tooltip>：
					</template>
					<el-tooltip :content="enabledType" placement="top">
						<el-switch
							v-model="isEnabled"
							active-color="#13ce66"
            	inactive-color="#C0CCDA"
							@change="enabledChange"
						>
						</el-switch>
					</el-tooltip>
				</el-form-item>
				<el-form-item label-width="145px" class="qi-yong">
					<template slot="label">
						用户分类调整
						<el-tooltip class="item" content="坐席是否能调整成单旗帜为其它旗帜" placement="top-start">
							<i class="el-icon-question icon-color"></i>
						</el-tooltip>：
					</template>
					<el-tooltip :content="successFlagChangeableType" placement="top">
						<el-switch
							v-model="successFlagChangeable"
							active-color="#13ce66"
            	inactive-color="#C0CCDA"
							@change="successFlagChangeableChange"
						>
						</el-switch>
					</el-tooltip>
				</el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="permissionSet_submit()" :disabled="isPermissionSet_submit">确 定</el-button>
          <el-button @click="permissionSet_cancel">取 消</el-button>
        </div>
			</el-form>
    </el-dialog>
		<!-- 提醒设置 -->
		<el-dialog title="提醒设置" :visible.sync="remindSetShow" class="el-recharge" width="400px" @close="remindSetClose">
			<template slot="title">
				<i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">提醒设置</span>
			</template>
			 <el-form :model="remindSetForm" label-position="left" label-width="120px">
				 <el-form-item label="告警阈值(元)：" required>
					<el-input v-model="remindSetForm.threshold" placeholder="请输入告警阈值" style="width:217px" clearable></el-input>
				</el-form-item>
				<el-form-item label="启用：" class="qi-yong">
					<el-switch
						v-model="remindSetForm.enabled"
						active-color="#13ce66"
						inactive-color="#C0CCDA"
					>
					</el-switch>
				</el-form-item>
				<el-form-item class="qi-yong">
					<template slot="label">
						自动调整：
					</template>
					<el-switch
						v-model="remindSetForm.autdoAdjust"
						active-color="#13ce66"
						inactive-color="#C0CCDA"
					>
					</el-switch>
				</el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="remindSet_submit()" :disabled="isRemindSet_submit">确 定</el-button>
          <el-button @click="remindSetShow = false">取 消</el-button>
        </div>
			 </el-form>
		</el-dialog>
		<!-- 冻结余额设置 -->
		<el-dialog title="冻结余额设置" :visible.sync="frozenBalanceShow" class="el-recharge" width="400px" @close="frozenBalanceClose">
			<template slot="title">
				<i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">冻结余额设置</span>
			</template>
			 <el-form label-position="left" label-width="120px">
				 <el-form-item label="冻结余额(元)：">
					<el-input v-model="frozenBalance" placeholder="请输入冻结余额" style="width:217px" clearable></el-input>
				</el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="frozenBalanceSet_submit()" :disabled="isFrozenBalanceSet_submit">确 定</el-button>
          <el-button @click="frozenBalanceShow = false">取 消</el-button>
        </div>
			 </el-form>
		</el-dialog>
		<!-- 变更主账号 -->
		<el-dialog 
			title="变更主账号" 
			:visible.sync="modifyChargeShow" 
			class="el-recharge" 
			width="400px" 
			@close="modifyChargeClose"
		>
			<template slot="title">
				<i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
				<span class="dialog-title-my my-title">变更主账号</span>
			</template>
			 <el-form label-position="left" label-width="120px">
				 <el-form-item label="选择主账号：">
					<el-select 
						v-model="chargeName" 
						placeholder="请选择主账号"
						filterable 
						remote 
						:loading="loadingUserName" 
						:remote-method="remoteMethoduserName" 
						@focus="chargeNameFocus"
						@change="chargeNameChagne"
					>
						<el-option
							v-for="item in chargeList"
							:key="item.loginName"
							:label="item.userName"
							:disabled="item.chargeNameDisabled"
							:value="item.loginName">
						</el-option>
					</el-select>
				</el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="modifyCharge_submit()">确 定</el-button>
          <el-button @click="modifyChargeShow = false">取 消</el-button>
        </div>
			 </el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'userList',
		inject:['reload'],
		props:['roleMenu'],
		data() {
			return {
				loadingUserName: false,
				modifyChargeShow: false,
				groupTableData: [],
				groupCurrentPage: 1,
				groupPageSize: 10,
				groupTotal: 0,
				activeName: "first",
				routeProps: {
					lazy: true,
					lazyLoad: (node, resolve) => {
						const { level } = node;
						if (level > 0) {
							let  params = {
								pageSize: 1000,
								pageNo: 1,
								// virtual: false,
								enabled: true,
								// reverseType: 'COMBINATION_ROUTE',
								tag: node.data.name
							};
							this.$http("/route/list", null, "get", null, params).then(res=>{
								if (res.result == 200){
									let list = res.data.list;
									list.map(item => {
										item.value = item.id;
										item.label = item.name;
										item.leaf = level >= 1;
									})
									this.virtualRouteListCopy.map(item => {
										if (item.label === node.label) {
											item.children = list;
										}
									})
									resolve(list);
								}
							})
						}
					}
				},
				virtualRouteList: [],
				virtualRouteListCopy: [],
				frozenBalanceShow: false,
				isFrozenBalanceSet_submit: false,
				frozenBalance: "",
				remindSetShow: false,
				isRemindSet_submit: false,
				remindSetForm: {
					userLoginName: "",
					threshold: "",
					enabled: false,
					autdoAdjust: false
				},
				serverMigrationShow: false,
				newServer: "",
				currentServer: "",
				newServerList: [],
				successFlagChangeableType: '',
				successFlagChangeable: false,
				serverList: [],
				server: 'huixiao',
				isParent: false,
				addParentId: -1,
				addUserName: '',
				addLoginName: '',
				addRole: '',
				addParentName: -1,
				addPassword: '',
				addParentIdList: [
					{
						value: -1,
						label: '主账号'
					},
					{
						value: 1,
						label: '子账号'
					},
					{
						value: 2,
						label: '克隆账号'
					},
				],
				addRoleList: [],
				addParentNameList: [],
				accountShow: false,
				isAccount_submit: false,
				projectStatus: 'true',
				projectStatusList: [
					{
						status: 'true',
						label: '可用'
					},
					{
						status: 'false',
						label: '不可用'
					},
				],
				loading:false,
				enabledType: '',
				isEnabled: false,
				tableData: [],
				total: null,
				currentPage: null,
				routeSetUpShow: false,
				routeData: [],
				addRouteShow: false,
				reviseRouteShow: false,
				reviseRouteForm: {
					fee: '',
					override: true
				},
				overrideShow: false,
				relationId: '',
				userLoginName: '',
				addRouteForm: {
					routeId: '',
					fee:'',
					routeTagId: []
				},
				routeIdList: [],
				indexId:'',
				rechargeShow:false,
				rechargeActive: 'first',
				rechargeForm:{
					userName:'',
					loginName:'',
					money:'',
					balance: '',
					remark:'',
				},
				rechargeRecordShow:false,
				rechargeRecordData:[],
				rechargeCurrentPage:null,
				rechargeTotal:null,
				loginName:'',
				overdraft:'',
				overdraftShow:false,
				userName:'',
				permissionSetForm:{
          roleId:'',
					loginName:''
        },
				permissionSetShow:false,
				permissionSetList:[],
				isRecharge_submit:false,
				isOverdraft_submit:false,
				isPermissionSet_submit:false,
				tableDataIndex:'',
				pageSize:20,
				maxRouteMultiple:'',
				parentId: '',
				maps:new Map(),
				isTableShow: true,
				inServer: "",
				chargeList: [],
				chargeName: "",
				beforeChangeNameId: "",
				beforeChangeName: "",
				afterChangeName: "",
				childrenLoginName: ""
			}
		},
		mounted() {
			document.title = "惠销平台管理系统-用户列表";
			this.inquire(1);
			if (this.roleMenu === 'SUPER_MANAGER') {
				this.inquireRoleList();
			}
		},
		methods: {
			// tab切换
			handleClick() {
				if (this.activeName === "first") {
					this.inquire(1);
				}
				if (this.activeName === "second") {
					this.inquireGroup(1);
				}
			},
			// 查询主张号列表
			getUserNameList(data) {
				this.loadingUserName = true;
				this.$http('/user/list', null, 'get', null, data).then(res => {
					this.loadingUserName = false;
					if (res.result === 200) {
						let list = res.data.list;
						list.forEach(item => {
							item.chargeNameDisabled = false;
							if (this.beforeChangeNameId === item.id) {
								item.chargeNameDisabled = true;
							}
						})
						this.chargeList = list;
					}
				}).catch(err =>{
					this.loadingUserName = false;
				})
			},
			// 点击修改主账号
			modifyChargeClick(val) {
				this.modifyChargeShow = true;
				this.beforeChangeName = val.parentUserName;
				this.beforeChangeNameId = val.parentId;
				this.inServer = val.server;
				this.childrenLoginName = val.loginName;
			},
			// 修改主账号获取焦点
			chargeNameFocus() {
				let data = {
					pageNo: 1,
					pageSize: 20,
					primaryAccount: true,
					enabled: true,
				}
				this.getUserNameList(data);
			},
			// 修改主账号搜索
			remoteMethoduserName(val) {
				let data = {
					pageNo: 1,
					pageSize: 20,
					primaryAccount: true,
					enabled: true,
					userName: val
				}
				this.getUserNameList(data);
			},
			// 选择主帐号
			chargeNameChagne(val) {
				if (val) {
					this.chargeList.forEach(item => {
						if (item.loginName === val) {
							this.afterChangeName = item.userName;
						}
					})
				}
			},
			// 确定修改主账号
			modifyCharge_submit() {
				if (!this.chargeName) {
					this.$message.success("请选择主账号");
					return
				}
				this.$confirm("确认从主账号【"+this.beforeChangeName+"】变更至【"+this.afterChangeName+"】, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					cancelButtonClass: "btn-custom-cancel",
					type: "warning"
				})
				.then(() => {
					let path = "https://" + this.inServer + this.$constants.basePath;
					let data = {
						userLoginName: this.childrenLoginName,
						newParentUserLoginName: this.chargeName
					}
					this.$service("/user/changeParent", data, "post", path).then(res => {
						if (res.result === 200) {
							this.inquire();
							this.$message.success("变更成功");
							this.modifyChargeShow = false;
						}
					})
				})
				.catch(() => {});
			},
			// 关闭修改主账号
			modifyChargeClose() {
				this.chargeName = "";
				this.beforeChangeName = "";
				this.afterChangeName = "";
				this.beforeChangeNameId = "";
				this.childrenLoginName = "";
			},
			// 点击查询用户分组
			inquireGroup(index) {
				if (index) {
					this.groupCurrentPage = index;
				}
				let data = {
					pageNo: this.groupCurrentPage,
					pageSize: this.groupPageSize
				}
				this.getGroupList(data);
			},
			// 查询用户分组
			getGroupList(data) {
				console.log(data)
			},
			// 用户分组--翻页
			groupCurrentPage_data(index) {
				this.inquireGroup(index);
			},
			// 用户分组--每页
			groupSizeChange(index) {
				this.groupPageSize = index;
				this.inquireGroup(1);
			},
			// 线路标签级联选择改变
			routeTagChange(val) {
				let routeTag = this.addRouteForm.routeTagId;
				if (routeTag.length > 1) {
					this.addRouteForm.routeId = routeTag[1];
				} else {
					this.addRouteForm.routeId = routeTag[0]
				}
				for (let i = 0; i < this.routeIdList.length; i++) {
					if (this.addRouteForm.routeId == this.routeIdList[i].id) {
						this.addRouteForm.fee = this.routeIdList[i].fee;
					}
				}
			},
			// 查询线路标签
    	getRouteTagList() {
				this.virtualRouteList = [];// 可用的线路
				this.virtualRouteListCopy = [];// 可用的线路copy
				this.$http("/route/routeTagList", null, "get").then(res => {
					if (res.result === 200) {
						let list = res.data;
						list.map(item => {
							item.value = item.tag;
							item.label = item.tag;
							item.name = item.tag;
							item.children = [];
						})
						this.virtualRouteList = list;
						this.virtualRouteList.unshift({
							name: "",
							value: "全部",
							label: "全部",
							children: []
						});
						this.virtualRouteListCopy = this.$common.deepClone(this.virtualRouteList);
					}
				})
			},
			// 点击冻结余额设置
			frozenBalanceClick(val) {
				this.frozenBalanceShow = true;
				this.frozenBalance = val.miniAvailableBalance;
				this.userLoginName = val.loginName;
				this.inServer = val.server;
			},
			// 确定冻结余额设置
			frozenBalanceSet_submit() {
				let data = {
					userLoginName: this.userLoginName,
					miniAvailableBalance: (this.frozenBalance*1000).toFixed(0)
				}
				this.isFrozenBalanceSet_submit = true;
				let path = "https://" + this.inServer + this.$constants.basePath;
				this.$service("/user/updateMiniAvailableBalance", data, "post", path).then(res => {
					this.isFrozenBalanceSet_submit = false;
					if (res.result === 200) {
						this.$message.success("修改成功");
						this.frozenBalanceShow = false;
						this.inquire();
					}
				})
			},
			// 关闭冻结余额设置
			frozenBalanceClose() {
				this.frozenBalance = "";
				this.userLoginName = "";
				this.inServer = "";
			},
			// 点击提醒设置
			remindSet(val) {
				this.remindSetShow = true;
				this.inServer = val.server;
				this.$http("/user/notifyMessageInfo",null,"get",null,{userLoginName: val.loginName}).then(res => {
					if (res.result === 200) {
						this.remindSetForm = {
							userLoginName: res.data.userLoginName,
							threshold: res.data.threshold / 1000,
							enabled: res.data.enabled,
							autdoAdjust: res.data.autdoAdjust
						}
					}
				})
			},
			// 确定提醒设置
			remindSet_submit() {
				this.isRemindSet_submit = true;
				let data = {
					userLoginName: this.remindSetForm.userLoginName,
					threshold: (this.remindSetForm.threshold * 1000).toFixed(0),
					enabled: this.remindSetForm.enabled,
					autdoAdjust: this.remindSetForm.autdoAdjust,
				}
				if (!data.threshold) {
					this.isRemindSet_submit = false;
					this.$message.warning("请输入告警阈值");
					return
				}
				let path = "https://" + this.inServer + this.$constants.basePath;
				this.$service("/user/updateNotifyMessage", data, "post", path).then(res => {
					this.isRemindSet_submit = false;
					if (res.result === 200) {
						this.$message.success("修改成功");
						this.remindSetShow = false;
					}
				})
			},
			// 关闭提醒设置
			remindSetClose() {
				this.isRemindSet_submit = false;
				this.inServer = "";
				this.remindSetForm = {
					userLoginName: "",
					threshold: "",
					enabled: false,
					autdoAdjust: false
				}
			},
			// 点击服务器
			trans2Server(val) {
				this.serverMigrationShow = true;
				this.userLoginName = val.loginName;
				this.currentServer = val.server;
				this.getServerList();
			},
			// 确认服务器迁移
			serverMigration_submit() {
				let data = {
					userLoginName: this.userLoginName,
					server: this.newServer
				}
				if (!data.server) {
					this.$message.warning("请选择服务器");
					return
				}
				this.$service("/user/trans2Server",data).then(res => {
					if (res.result === 200) {
						this.$message.success("修改成功");
						this.serverMigrationShow = false;
						this.inquire();
					}
				})
			},
			// 关闭服务器迁移
			serverMigrationClose() {
				this.userLoginName = "";
				this.newServer = "";
				this.currentServer = "";
			},
			// 获取服务器列表
			getServerList() {
				this.serverList = [];
				this.newServerList = [];
				this.$http("/serverInfo/list",null,"get",null,{pageNo: 1, pageSize: 1000, enabled: true}).then(res => {
					if (res.result === 200) {
						let list = res.data.records;
						list.forEach(item => {
							let obj = {
								label: item.name,
								value: item.name,
								disabled: false
							}
							this.serverList.push(obj);
							if (this.currentServer === item.name) {
								obj.disabled = true
							}
							this.newServerList.push(obj);
						})
					}
				})
			},
			// 点击新增账号
			addUser(){
				this.accountShow = true;
				let params = {
            pageSize:10,
            pageNo:1,
						userName:'',
						primaryAccount:true,
						enabled: true
          }
				this.getUserList(params);
				this.getRoleList();
				this.getServerList();
			},
			// 确定新增账号
			account_submit(){
				let data = {
					loginName: this.addLoginName,
					userName: this.addUserName,
					password: this.addPassword,
					parentId: this.addParentName,
					roleId: this.addRole,
				}
				if (this.addParentId === -1) {
					data.accountType = "master";
				}
				if (this.addParentId === 1) {
					data.accountType = "child";
				}
				if (this.addParentId === 2) {
					data.accountType = "clone";
				}
				if (this.addParentId !== -1) {
					if (!this.addParentName) {
						this.$message({message:'请选择主账号',type:'warning'});
						return 
					}
				}
				if (!this.addUserName) {
					this.$message({message:'请输入用户名称',type:'warning'});
					return 
				}
				if (!this.addLoginName) {
					this.$message({message:'请输入登录名称',type:'warning'});
					return 
				}
				if (!this.addPassword) {
					this.$message({message:'请输入密码',type:'warning'});
					return 
				}
				if (!this.addRole) {
					this.$message({message:'请选择角色权限',type:'warning'});
					return 
				}
				if (this.addParentId === -1) {
					if (!this.server) {
						this.$message({message:'请选择服务器',type:'warning'});
						return 
					}
					data.server = this.server;
				}
				this.$service('/user/add',data).then(res => {
					if (res.result === 200) {
						this.accountShow = false;
						this.$message({message:'新增成功',type:'success'});
						this.inquire(1);
					}
				})
			},
			// 选择新增账号类型
			addParentIdChange(val){
				if (val === -1) {
					this.isParent = false;
					this.addParentName = -1;
				} else {
					this.isParent = true;
					this.addParentName = '';
				}
			},
			// 关闭新增账号
			closeAccountShow(){
				this.server = 'huixiao';
				this.addParentId = -1;
				this.addParentName = -1;
				this.addUserName = '';
				this.addLoginName = '';
				this.addPassword = '';
				this.addRole = '';
				this.isParent = false;
				this.addParentNameList = [];
				this.addRoleList = [];
			},
			// 新增主账号获取焦点
			userNameRemoteFocus() {
				let params = {
            pageSize:10,
            pageNo:1,
						userName:'',
						primaryAccount:true,
						enabled: true
          }
				this.getUserList(params);
			},
			addParentNameMethod(userName) {//搜索主账号
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          let params = {
            pageSize:10,
            pageNo:1,
						userName:userName,
						primaryAccount:true,
						enabled: true
          }
          this.getUserList(params);
        }, 200);
			},
			// 获取主账号列表
			getUserList(params){
				this.$http('/user/list', null, 'get', null, params).then(res => {
					if (res.result === 200) {
						this.addParentNameList = res.data.list;
					}
				})
			},
			// 获取角色列表
			getRoleList(){
				this.$http('/user/roleList', null, 'get', null, {pageNo: 1,pageSize: 100}).then(res => {
					if (res.result === 200) {
						this.addRoleList = res.data.records;
						this.addRole = this.addRoleList[0].id;
					}
				})
			},
			//权限设置启用状态
			enabledChange(val){
				if(val){
					this.enabledType = '已启用'
				}else{
					this.enabledType = '已禁用'
				}
			},
			//用户分类调整
			successFlagChangeableChange(val){
				if(val){
					this.successFlagChangeableType = '已启用'
				}else{
					this.successFlagChangeableType = '已禁用'
				}
			},
			expandChange(rows,val){//展开行刷新子账号数据
				// if (!val) {//关闭行执行
				// 	const {tree,treeNode,resolve} = this.maps.get(rows.id)
				// 	this.$set(this.$refs.table.store.states.lazyTreeNodeMap,rows.id,[])
				// 	this.load(tree,treeNode,resolve);
				// }
			},
			//查询子账号
			load(tree, treeNode, resolve) {
				let pid = tree.id
				let params = {
					pageSize: 100,
					pageNo: 1,
					parentAccountId: pid,
					enabled: this.projectStatus
				}
				this.maps.set(pid,{tree,treeNode,resolve})
				this.$http('/user/list', null, 'get', null, params).then(res => {
					if(res.result == 200){
						let list = res.data.list
						let index = 1000
						list.forEach(item => {
							index++
							item.key = tree.key + index.toString()
							item.lastLoginDate = this.$common.transformTime(item.lastLoginDate);
							item.routeSetUpDis = true
							item.rechargeDis = true
							item.rechargeRecordDis = true
						});
						resolve(list)
					}
				})
      },
			inquireRoleList(){//查询roleList
				let params = {
          pageNo:1,
          pageSize:1000
        }
        this.$http('/user/roleList',null,'get',null,params).then(res=>{
					if(res.result == 200){
						let list = res.data.records;
						this.permissionSetList = [];
						list.map(item=>{
							let obj = {
								loginName:item.roleName,
								roleId:item.id
							}
							this.permissionSetList.push(obj);
						})
					}
				})
			},
			permissionSet(val){//点击权限设置
				if(val.parentId == -1){

				}else{
					this.parentId = val.parentId;
				}
        this.permissionSetShow = true;
				this.permissionSetForm.loginName = val.loginName;
				this.overdraft = val.overdraft;
				this.isEnabled = val.enabled;
				this.successFlagChangeable = val.successFlagChangeable;
				this.maxRouteMultiple = val.maxRouteMultiple;
				if(val.enabled){
					this.enabledType = '已启用'
				}else{
					this.enabledType = '已禁用'
				}
				if(val.successFlagChangeable){
					this.successFlagChangeableType = '已启用'
				}else{
					this.successFlagChangeableType = '已禁用'
				}
				for(let i of this.permissionSetList){
					if(i.roleId == val.roleId){
						this.$nextTick(()=>{
							this.permissionSetForm.roleId = val.roleId;
						})
					}else{
						this.permissionSetForm.roleId = '';
					}
				}
      },
			permissionSet_submit(){//权限设置提交
				let data = {}
				if(this.isEnabled){
					data = {
						enabled: this.isEnabled,
						loginName:this.permissionSetForm.loginName,
						roleId:this.permissionSetForm.roleId,
						overdraft:(this.overdraft*1000).toFixed(0),
						maxRouteMultiple:parseInt(this.maxRouteMultiple*100),
						successFlagChangeable: this.successFlagChangeable
					}
					if(!this.permissionSetForm.roleId){
						this.$message({message:'请选择角色权限',type:'warning'});
						return;
					}
					if(!this.overdraft){
						this.$message({message:'请输入透支额度',type:'warning'});
						return;
					}
					if(!this.maxRouteMultiple){
						this.$message({message:'请输入最大并发数',type:'warning'});
						return;
					}
				}else{
					data = {
						enabled: this.isEnabled,
						loginName:this.permissionSetForm.loginName,
					}
				}
				this.isPermissionSet_submit = true;
				this.$http('/user/updateUserSetting',data).then(res=>{
					if(res.result == 200){
						this.$message({message:'权限设置成功',type:'success'});
						this.permissionSet_cancel();
						this.inquire();
						this.isPermissionSet_submit = false;
						if(this.parentId){
							const {tree,treeNode,resolve} = this.maps.get(this.parentId)
							this.$set(this.$refs.table.store.states.lazyTreeNodeMap,this.parentId,[])
							this.load(tree,treeNode,resolve);
						}
					}
				})
      },
      permissionSet_cancel(){//权限设置取消
        this.permissionSetShow = false;
      },
      permissionSetClose(){//权限设置关闭
        this.permissionSetForm.roleId = '';
				this.permissionSetForm.loginName = '';
				this.overdraft = '';
				this.maxRouteMultiple = '';
				this.isPermissionSet_submit = false;
				this.isEnabled = false;
				this.enabledType = '';
				this.successFlagChangeableType = '';
				this.parentId = '';
      },
			handleInput(){//限制input输入
				let money = this.rechargeForm.money;
				// money = money.replace(/[^\d]/g, ''); // 清除“数字”以外的字符
				// if (money.indexOf('.') < 0 && money != '') {
				// 		// 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
				// 		money = parseInt(money);
				// }
				money = this.$common.numberCheck(money);
				this.rechargeForm.money = money;
			},
			recharge(val){//点击充值
				this.rechargeShow = true;
				this.rechargeForm.userName = val.userName;
				this.rechargeForm.loginName = val.loginName;
				this.inServer = val.server;
			},
			recharge_submit(){//充值提交
				let data = {
					userLoginName:this.rechargeForm.loginName,
					money:(this.rechargeForm.money*1000).toFixed(0),
					remark:this.rechargeForm.remark,
				}
				this.isRecharge_submit = true;
				if(this.rechargeForm.money && this.rechargeForm.remark){
					let path = "https://" + this.inServer + this.$constants.basePath;
					this.$service('/user/recharge', data, "post", path).then(res=>{
						if(res.result == 200){
							this.$message({message:'充值成功',type:'success'});
							this.inquire();
							this.recharge_cancel();
							this.isRecharge_submit = false;
						}
					})
				}else{
					this.isRecharge_submit = false;
					this.$message({message:'信息不完整',type:'warning'});
				}
			},
			rechargeCount_submit(){//充值提交
				let data = {
					userLoginName:this.rechargeForm.loginName,
					balance:this.rechargeForm.balance,
					remark:this.rechargeForm.remark,
				}
				this.isRecharge_submit = true;
				if(this.rechargeForm.balance && this.rechargeForm.remark){
					let path = "https://" + this.inServer + this.$constants.basePath;
					this.$service('/user/rechargeCount', data, "post", path).then(res=>{
						if(res.result == 200){
							this.$message({message:'充值成功',type:'success'});
							this.inquire();
							this.recharge_cancel();
							this.isRecharge_submit = false;
						}
					})
				}else{
					this.isRecharge_submit = false;
					this.$message({message:'信息不完整',type:'warning'});
				}
			},
			recharge_cancel(){//充值取消
				this.rechargeShow = false;
			},
			closeRecharge(){//关闭充值表单
				this.rechargeForm = {
					userName:'',
					loginName:'',
					money:'',
					balance: '',
					remark:'',
				}
				this.rechargeActive = 'first';
				this.inServer = "";
				this.isRecharge_submit = false;
			},
			rechargeRecord(val){//点击充值记录
				this.rechargeRecordShow = true;
				this.loginName = val.loginName;
				let params = {
					pageNo:1,
					pageSize:10,
					loginName:val.loginName,
					// needSummary:false
				}
				this.inquireRechargeRecord(params);
			},
			closeRechargeRecord(){//关闭充值记录弹框
				this.rechargeRecordData = [];
			},
			rechargeCurrentPage_data(index){//当前页充值记录
				let params = {
					pageNo:index,
					pageSize:10,
					loginName:this.loginName,
					// needSummary:false
				}
				this.inquireRechargeRecord(params);
			},
			inquireRechargeRecord(params){//查询充值记录
				this.$http('/user/rechargeList',null,'get',null,params).then(res=>{
					if(res.result == 200){
						this.rechargeTotal = res.data.count;
						let list = res.data.list;
						list.map(item=>{
							item.money = (item.money).toFixed(2)+' 元';
							item.gmtCreated = this.$common.transformTime(item.gmtCreated);
						})
						this.rechargeRecordData = list;
					}
				})
			},
			// overdraftChange(val){//点击透支额度
			// 	this.overdraftShow = true;
			// 	this.loginName = val.loginName;
			// 	this.overdraft = val.overdraft;
			// },
			// overdraft_submit(){//透支额度修改
			// 	let data = {
			// 		loginName:this.loginName,
			// 		overdraft:this.overdraft*1000
			// 	}
			// 	this.isOverdraft_submit = true;
			// 	if(this.overdraft){
			// 		this.$http('/user/modifyOverdraft',data).then(res=>{
			// 			this.$message({message:'修改成功',type:'success'});
			// 			this.inquire();
			// 			this.overdraft_cancel();
			// 			this.isOverdraft_submit = false;
			// 		})
			// 	}else{
			// 		this.isOverdraft_submit = false;
			// 		this.$message({message:'信息不完整',type:'warning'});
			// 	}
			// },
			// overdraft_cancel(){//透支额度修改取消
			// 	this.overdraftShow = false;
			// },
			// closeOverdraft(index) {//关闭透支额度表单
			// 	this.overdraft = '';
			// 	this.isOverdraft_submit = false;
			// },
			currentPage_data(index) {//当前页
				this.currentPage = index;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.currentPage,
					userName:this.userName,
					primaryAccount:true,
					enabled: this.projectStatus
				}
				this.inquireUserList(params);
			},
			sizeChange(index) {//显示条数--分页
				this.pageSize = index;
				this.currentPage = 1;
				let params = {
					pageSize: this.pageSize,
					pageNo: this.currentPage,
					userName:this.userName,
					primaryAccount:true,
					enabled: this.projectStatus
				}
				this.inquireUserList(params);
			},
			getInto(val){//点击进入
				let params = {
					loginName:val.loginName
				}
				this.$http('/user/redirect',null,'get',null,params).then(res=>{
					if(res.result == 200){
						this.$http("/user/userInfo").then(res => {
							if (res.result === 200) {
								let route = "";
								let newArr = this.$common.deepClone(res.data.menus);
								if(newArr && newArr.length>0){
									let menu = newArr[0].subMenus;
									if(menu){
										route = JSON.parse(menu[0].extraMsg).index;
									}else{
										route = JSON.parse(newArr[0].extraMsg).index;
									}
								}
								if (res.data.indexUrl) {
									route = res.data.indexUrl;
								}
								this.$router.push(route);
								this.$router.go(0);
							}
						})
					}
				})
			},
			inquire(index) {//查询列表
				if (index) {
					this.currentPage = 1;
				}
				let params = {
					pageSize: this.pageSize,
					pageNo: index || this.currentPage,
					userName:this.userName,
					primaryAccount:true,
					enabled: this.projectStatus
				}
				let isTableShow = sessionStorage.getItem('projectStatus')
				if (isTableShow !== this.projectStatus) {
					this.isTableShow = false
				}
				sessionStorage.setItem('projectStatus', this.projectStatus)
				this.inquireUserList(params);
			},
			inquireUserList(params){//查询用户列表
				this.loading = true;
				this.tableData = [];
				this.$http('/user/list', null, 'get', null, params).then(res => {
					this.isTableShow = true
					setTimeout(() => {
						this.loading = false
					}, 200);
					if (res.result == 200) {
						let list = res.data.list;
						this.total = res.data.count;
						list.map(item => {
							// item.hasChildren = true;
							item.key = item.id;
							item.lastLoginDate = this.$common.transformTime(item.lastLoginDate);
							item.routeSetUpDis = false
							item.rechargeDis = false
							item.rechargeRecordDis = false
							if(item.sipRouteList && item.sipRouteList.length>0){
								item.routeCount = item.sipRouteList.length;
							}else{
								item.routeCount = 0;
							}
						});
						this.tableData = list;
					}
				})
			},
			routeSetUp(val,index) {//点击线路设置
				this.tableDataIndex = index;
				this.routeSetUpShow = true;
				let routeList = [];
				let params = {
					pageNo:1,
					pageSize:1000,
					loginName:val.loginName
				}
				this.$http('/route/userRouteRelationList',null,'get',null,params).then(res=>{
					if(res.result == 200){
						let list = res.data.records;
						list.map(c=>{
							if(c.sipRoute){
								c.sipRoute.relationId = c.id;
								routeList.push(c.sipRoute)
							}
							if(c.subSipUserRouteFeeRelationList){
								c.sipRoute.children = [];
								c.subSipUserRouteFeeRelationList.map(i=>{
									if(i.sipRoute){
										i.sipRoute.deleteDis = true;
										i.sipRoute.relationId = i.id;
										c.sipRoute.children.push(i.sipRoute)
									}
								})
							}
						})
						this.routeData = routeList;
					}
				})
				this.userLoginName = val.loginName;
				this.indexId = val.id;
			},
			closerouteSetUp() {//关闭线路设置弹窗
				this.routeData = [];
			},
			routeRevise(val) {//线路修改
				this.reviseRouteShow = true;
				this.overrideShow = false;
				if(val.feeDisplay){
					this.reviseRouteForm.fee = val.feeDisplay;
				}
				if (val.type === "COMBINATION_ROUTE") {
					this.overrideShow = true;
				}
				this.relationId = val.relationId;
			},
			routeDelete(val) {//线路删除
				let data = {
					relationId: val.relationId,
					delete: true,
					userLoginName:this.userLoginName
				}
				this.$confirm("删除, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					cancelButtonClass: "btn-custom-cancel",
					type: "warning"
				})
				.then(() => {
					this.$http('/route/updateUserRelation', data).then(res => {
						if (res.result == 200) {
							this.$message({ message: '删除成功', type: 'success' });
							this.routeSetUp(this.tableData[this.tableDataIndex],this.tableDataIndex)
						}
					})
				})
				.catch(() => {});
				
			},
			addRoute() {//点击新增设置
				this.addRouteShow = true;
				this.inquireRoute();
				this.getRouteTagList();
			},
			addRouteClose(){//新增设置弹窗关闭
				this.addRouteForm = {
					routeId:'',
					fee:'',
					routeTagId: []
				}
			},
			addLine() {//确定新增设置
				let data = {
					routeId: this.addRouteForm.routeId,
					userLoginName: this.userLoginName,
					fee: this.addRouteForm.fee,
				}
				this.routeIdList.forEach(item => {
					if (item.id === data.routeId) {
						if (item.type === "COMBINATION_ROUTE") {
							data.override = this.reviseRouteForm.override;
						}
					}
				})
				if(data.routeId){
					this.$http('/route/addUserRelation',data).then(res=>{
						if(res.result == 200){
							this.$message({ message: '新增设置成功', type: 'success' });
							this.addRouteShow = false;
							this.routeSetUp(this.tableData[this.tableDataIndex],this.tableDataIndex)
						}
					})
				}else{
					this.$message({ message: '信息不完整', type: 'warning' });
				}
			},
			addLine_cencel(){//取消新增设置
				this.addRouteShow = false;
			},
			reviseRouteFee() {//修改线路费用
				let data = {
					relationId: this.relationId,
					userLoginName: this.userLoginName,
					fee: this.reviseRouteForm.fee,
					delete: false,
					override: this.reviseRouteForm.override
				}
				if(!data.fee){
					this.$message({message:'请输入费用',type:'warning'});
					return
				}
				this.$http('/route/updateUserRelation', data).then(res => {
					if (res.result == 200) {
						this.$message({ message: '修改成功', type: 'success' });
						this.routeSetUp(this.tableData[this.tableDataIndex],this.tableDataIndex)
						this.reviseRouteShow = false;
					}
				})
			},
			feeClose(){//费用关闭
				this.reviseRouteForm.fee = "";
				this.reviseRouteForm.override = true;
			},
			inquireRoute() {//查询线路
				let params = {
					pageSize: 1000,
					pageNo: 1,
					enabled: true
				}
				this.$http('/route/list', null, 'get', null, params).then(res => {
					if (res.result == 200) {
						this.routeIdList = res.data.list;
					}
				})
			},
			changeRouteId(routeId) {//新增设置routeId值改变
				for (let i = 0; i < this.routeIdList.length; i++) {
					if (routeId == this.routeIdList[i].id) {
						this.addRouteForm.fee = this.routeIdList[i].fee;
					}
				}
			}
		},
		beforeDestroy(){
			sessionStorage.removeItem('projectStatus')
		}
	}
</script>
<style>
	.btn-custom-cancel {
		float: right;
		margin-left: 10px !important;
	}
</style>
<style scoped="scoped">
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-dropdown{
  font-size: 12px;
  margin-left: 10px;
}
.el-dropdown-menu .el-button {
  display: block;
  width: 90px;
  border-radius: 0px;
}
.el-dropdown-menu .el-button+.el-button {
  margin: 0;
}
.el-dropdown-menu .el-button:hover {
  background: #ecf5ff;
}
	.el-routeSetUp >>> .el-table__fixed-right{
		height: 100% !important;
	}
	.el-table {
		margin-bottom: 20px;
	}
	.el-addTeam .el-select{
		width: 260px;
	}
	.statusClass, .userNameClass{
		display: inline-block;
		margin-right: 10px;
	}
	.userNameClass .el-input{
		width: 230px;
		margin-bottom: 10px;
	}
	.el-pagination {
		float: right;
		text-align: right;
		margin-bottom: 20px;
	}
	.feewidth {
		width: 60%;
		margin-right: 10px;
	}
	.addRouteFeewidth{
		width: 217px;
	}
	.el-recharge .el-input,.el-recharge .el-textarea{
		width: 250px;
	}
	.el-recharge >>> .el-textarea__inner{
		padding: 5px 10px;
	}
	.el-recharge >>> .el-form-item__label:before{
		content: "*";
		color: #ff4949;
		margin-right: 4px;
	}
	.el-recharge .itemClass >>> .el-form-item__label:before{
		content: "";
		color: #ff4949;
		margin-right: 12px;
	}
	.button-center {
		text-align: center
	}
	.el-rechargeRecord >>> .el-dialog__body{
		padding:10px 20px;
	}
	.el-rechargeRecord >>> .el-dialog__body::after{
		display: block;
    content: '';
    clear: both;
	}
	.el-rechargeRecord .el-table{
		margin-bottom: 10px;
	}
	.el-recharge .qi-yong >>> .el-form-item__label::before{
		content: '';
		margin-right: 10px;
	}
</style>