<template>
  <div class="achievementStatisticsTotal">
    <div class="a_nav">
      <div class="callTime">运营主管：
        <!-- <el-select v-model="managerId" placeholder="请选择运营主管" clearable>
          <el-option 
            v-for="item in managerList" 
            :key="item.managerId" 
            :value="item.managerId" 
            :label="item.userName"
          >
          </el-option>
        </el-select> -->
        <el-input v-model="supervisorName" placeholder="请输入运营" clearable></el-input>
      </div>
      <div class="callTime">时间范围：
        <el-date-picker
          v-model="condition.gmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
        ></el-date-picker>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="getManagerPerformanceList">查询</el-button>
      <el-checkbox v-model="filterQuit" style="margin-left: 20px">过滤离职</el-checkbox>
    </div>
    <el-table
      :data="totalTableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
      :span-method="objectSpanMethod"
    >
      <el-table-column prop="managerName" label="经理" min-width="100" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="supervisorName" label="主管" min-width="100" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="normalFansCount" label="正常进粉人天" min-width="120" align="left"></el-table-column>
      <el-table-column prop="onlineDay" label="上线人数" min-width="120" align="left">
        <template slot="header" slot-scope="scope">
          <span>上线人数</span>
          <el-tooltip class="item" effect="light" content="销售当天进粉数>10算上线" placement="top-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="fansCount" label="进粉量" min-width="100" align="left"></el-table-column>
      <el-table-column prop="interactiveCount" label="互动量" min-width="100" align="left"></el-table-column>
      <el-table-column prop="expectPerformance" label="业绩目标" min-width="100" align="left"></el-table-column>
      <el-table-column prop="orderCount" label="实际支付量" min-width="100" align="left"></el-table-column>
      <el-table-column prop="performanceCount" label="业绩绩效" min-width="100" align="left"></el-table-column>
      <el-table-column prop="interactiveRateItem" label="互动率" min-width="100" align="left">
        <template slot="header" slot-scope="scope">
          <span>互动率</span>
          <el-tooltip class="item" effect="light" content="互动量 / 进粉量" placement="top-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="achievementRate" label="业绩目标达成率" min-width="120" align="left">
        <template slot="header" slot-scope="scope">
          <span>业绩目标达成率</span>
          <el-tooltip class="item" effect="light" content="业绩绩效 / 业绩目标" placement="top-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <span :style="{color: scope.row.achieveRateColor}">{{scope.row.achieveRateItem}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="fansRateItem" label="正常进粉率" min-width="100" align="left">
        <template slot="header" slot-scope="scope">
          <span>正常进粉率</span>
          <el-tooltip class="item" effect="light" content="正常进粉人天 / 总进粉人天" placement="top-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="explainAchieveRate-style">
      {{explainAchieveRate}}
    </div>
  </div>
</template>
<script>
export default {
  name: "achievementStatisticsTotal",
  data() {
    return {
      filterQuit: true,
      loadingTable: false,
      totalTableData: [],
      spanArr: [],
      explainAchieveRate:'',
      // explainAchieveRate: "目标达标率：<60% 六分之一进粉，60%-80% 三分之一进粉， 80%-100% 三分之二进粉， >100% 正常进粉",
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            }
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            }
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      condition: {
        gmtCreatedDate: []
      },
      managerId: "",
      supervisorName: "",
      managerList: []
    }
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreatedDate = [start, end];
    this.theWeek();
    this.getManagerList();
    this.getManagerPerformanceList();
    this.getEditLabel();
  },
  methods: {
    // 每周
    theWeek() {
      this.$http("/user/thisWeek", null).then(res => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 获取业绩看板标签
    getEditLabel() {
      this.$http("/wechat/tab/getLabel", null, "get").then(
        (res) => {
          if (res.result === 200) {
            this.explainAchieveRate = res.data;
          }
        }
      );
    },
    // 查询主管列表
    getManagerList() {
      this.$http("/wechat/managerList",null,'get').then(res => {
        if (res.result === 200) {
          let list = res.data;
          this.managerList = list;
        }
      })
    },
    // 查询列表
    getManagerPerformanceList() {
      if (!this.condition.gmtCreatedDate) {
        this.$message.warning("请选择时间范围");
        return
      }
      let start = this.condition.gmtCreatedDate[0];
      let end	= this.condition.gmtCreatedDate[1];
      let data = {
        supervisorName: this.supervisorName,
        start: start,
        end: end,
        filterQuit: this.filterQuit

      }
      this.$http("/wechat/tab/managerPerformanceList",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item, index) => {
            item.fansRateItem = "0%";
            if (item.totalFansCount) {
              item.fansRateItem = (item.fansRate * 100).toFixed(1) + "%";
            }
            item.interactiveRateItem = "0%";
            if (item.fansCount) {
              item.interactiveRateItem = (item.interactiveRate * 100).toFixed(1) + "%";
            }
            item.achieveRateItem = "0%";
            if (item.expectPerformance) {
              item.achieveRateItem = (item.achievementRate * 100).toFixed(1) + "%";
            }
            item.achieveRateColor = "#606266";
            if (item.achievementRate < 0.6) {
              item.achieveRateColor = "#F56C6C";
            }
            if (item.achievementRate >= 0.6 && item.achievementRate <= 0.8) {
              item.achieveRateColor = "#E6A23C";
            }
            if (item.achievementRate > 1) {
              item.achieveRateColor = "#67C23A";
            }
          });
          this.getSpanArr(list);
        }
      })
    },
    // 此函数是实现合并随意行数的功能
    getSpanArr(data) {
      let summary = {};
      let monthSummary = {
        supervisorName: "",
        normalFansCount: 0,
        onlineDay:0,
        performanceCount: 0,
        fansCount: 0,
        interactiveCount: 0,
        orderCount: 0,
        expectPerformance: 0,
        achievementRate: 0,
        achieveRateItem: "0%",
        interactiveRate: 0,
        interactiveRateItem: "0%",
        fansRate: 0,
        fansRateItem: "0%",
        totalFansCount: 0,
        achieveRateColor: "#606266",
        managerName: "总合计",
      };
      data.forEach(item => {
        summary[item.managerName] = {
          supervisorName: item.managerName + "组合计",
          performanceCount: 0,
          normalFansCount: 0,
          onlineDay:0,
          fansCount: 0,
          interactiveCount: 0,
          orderCount: 0,
          expectPerformance: 0,
          achievementRate: 0,
          achieveRateItem: "0%",
          interactiveRate: 0,
          interactiveRateItem: "0%",
          fansRate: 0,
          fansRateItem: "0%",
          totalFansCount: 0,
          achieveRateColor: "#606266",
          managerName: item.managerName,
        }
      })
      data.forEach(item => {
        summary[item.managerName].performanceCount += item.performanceCount;
        summary[item.managerName].expectPerformance += item.expectPerformance;
        summary[item.managerName].interactiveCount += item.interactiveCount;
        summary[item.managerName].fansCount += item.fansCount;
        summary[item.managerName].normalFansCount += item.normalFansCount;
        summary[item.managerName].onlineDay += item.onlineDay;
        summary[item.managerName].orderCount += item.orderCount;
        summary[item.managerName].totalFansCount += item.totalFansCount;
      })
      for (let i in summary) {
        if (summary[i].totalFansCount) {
          summary[i].fansRate = summary[i].normalFansCount / summary[i].totalFansCount;
          summary[i].fansRateItem = (summary[i].fansRate * 100).toFixed(1) + "%";
        }
        //  if (summary[i].totalFansCount) {
        //   summary[i].fansRate = summary[i].normalFansCount / summary[i].totalFansCount;
        //   summary[i].fansRateItem = (summary[i].fansRate * 100).toFixed(1) + "%";
        // }
        if (summary[i].fansCount) {
          summary[i].interactiveRate = summary[i].interactiveCount / summary[i].fansCount;
          summary[i].interactiveRateItem = (summary[i].interactiveRate * 100).toFixed(1) + "%";
        }
        if (summary[i].expectPerformance) {
          summary[i].achievementRate = summary[i].performanceCount / summary[i].expectPerformance;
          summary[i].achieveRateItem = (summary[i].achievementRate * 100).toFixed(1) + "%";
        }
        if (summary[i].achievementRate < 0.6) {
          summary[i].achieveRateColor = "#F56C6C";
        }
        if (summary[i].achievementRate >= 0.6 && summary[i].achievementRate <= 0.7) {
          summary[i].achieveRateColor = "#E6A23C";
        }
        if (summary[i].achievementRate > 0.8) {
          summary[i].achieveRateColor = "#67C23A";
        }
        monthSummary.performanceCount += summary[i].performanceCount;
        monthSummary.expectPerformance += summary[i].expectPerformance;
        monthSummary.interactiveCount += summary[i].interactiveCount;
        monthSummary.fansCount += summary[i].fansCount;
        monthSummary.normalFansCount += summary[i].normalFansCount;
        monthSummary.onlineDay += summary[i].onlineDay;
        monthSummary.orderCount += summary[i].orderCount;
        monthSummary.totalFansCount += summary[i].totalFansCount;
      }
      if (monthSummary.totalFansCount) {
        monthSummary.fansRate = monthSummary.normalFansCount / monthSummary.totalFansCount;
        monthSummary.fansRateItem = (monthSummary.fansRate * 100).toFixed(1) + "%";
      }
      if (monthSummary.fansCount) {
        monthSummary.interactiveRate = monthSummary.interactiveCount / monthSummary.fansCount;
        monthSummary.interactiveRateItem = (monthSummary.interactiveRate * 100).toFixed(1) + "%";
      }
      if (monthSummary.expectPerformance) {
        monthSummary.achievementRate = monthSummary.performanceCount / monthSummary.expectPerformance;
        monthSummary.achieveRateItem = (monthSummary.achievementRate * 100).toFixed(1) + "%";
      }
      if (monthSummary.achievementRate < 0.6) {
        monthSummary.achieveRateColor = "#F56C6C";
      }
      if (monthSummary.achievementRate >= 0.6 && monthSummary.achievementRate <= 0.7) {
        monthSummary.achieveRateColor = "#E6A23C";
      }
      if (monthSummary.achievementRate > 0.8) {
        monthSummary.achieveRateColor = "#67C23A";
      }
      data.reverse();
      for (let i in summary) {
        let push = false;
        data.forEach((item, index) => {
          if (i === item.managerName && !push) {
            push = true;
            data.splice(index, 0, summary[i]);
          }
        })
      }
      data.reverse();
      data.push(monthSummary);
      this.totalTableData = data;
      this.spanArr = []; // 合并行数组
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].managerName === data[i - 1].managerName) {
            // 如果operationalUsersGroupName相等就累加，并且push 0
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // columnIndex === 0 找到第一列，实现合并随机出现的行数
      if (columnIndex === 0) {
        if (rowIndex === (this.totalTableData.length -1)) {
          // columnIndex === this.totalTableData.length -1 找到第最后一行，实现合并第1到2列
          if (columnIndex === 0) {
            return {
              rowspan: 1,
              colspan: 2,
            };
          } else if (columnIndex === 1) {
            return [0, 0];
          }
        }
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      } else {
        if (rowIndex === (this.totalTableData.length -1)) {
          // columnIndex === this.totalTableData.length -1 找到第最后一行，实现第2列为0
          if (columnIndex === 1) {
            return [0, 0];
          }
        }
      }
    },
  },
}
</script>
<style scoped>
.a_nav {
  margin: 0 0px 20px 0px;
}
.a_nav .callTime {
  display: inline-block;
  margin-right: 20px;
}
.a_nav .callTime .el-input {
  width: 68%;
}
.el-table {
  margin: 20px 0px;
}
.achievementStatisticsTotal .el-pagination {
  float: right;
  margin-top: 20px;
}
.achievementStatisticsTotal .el-table {
  margin-top: 20px;
}
.explainAchieveRate-style {
  color: #E6A23C;
  font-size: 15px;
  margin-top: 10px;
}
</style>