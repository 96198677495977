<template>
  <div class="intentionLibrary">
    <div class="nav">
      <div class="batchName">
        意图名称：
        <el-input v-model="intentionLibraryName" placeholder="请输入意图名称" clearable></el-input>
      </div>
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button @click="addIntentionLibrary">创建默认意图</el-button>
    </div>
    <el-table
      :data="tableListData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
      height="calc(100% - 100px)"
    >
      <el-table-column prop="id" label="编号" width="70" align="center"></el-table-column>
      <el-table-column prop="name" label="意图名称" min-width="100" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="keyword" label="关键词" min-width="100" align="left" show-overflow-tooltip></el-table-column>
      <!-- <el-table-column prop="branchType" label="类型" width="100" align="left"></el-table-column> -->
      <el-table-column prop="gmtCreated" label="创建时间" width="180" align="left"></el-table-column>
      <el-table-column prop label="操作" width="120" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button 
            type="text" 
            @click="modifyClick(scope.row)" 
            size="small"
          >修改</el-button>
          <el-button 
            type="text" 
            @click="deleteBranch(scope.row)" 
            style="color: #F56C6C" 
            size="small"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 增加意图 -->
    <el-dialog 
      :title="branchTitle" 
      :visible.sync="branchShow" 
      width="500px" 
      @close="branchClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="意图名称:" required>
          <el-input 
            v-model="branchForm.name" 
            placeholder="请输入意图名称" 
            clearable
            :readonly="branchTitle === '编辑默认意图'"
          ></el-input>
        </el-form-item>
        <el-form-item label="关键词:">
          <el-input 
            v-model="branchForm.keyWord" 
            placeholder="请输入关键词并回车（多个关联词中间用｜分隔）" 
            @keyup.enter.native="addKeyWord(branchForm.keyWord)"
            clearable
          ></el-input>
          <!-- 提示 -->
          <div class="tag-cc-mm">
            <span class="text-color-ff">匹配到该意图的关键词</span>
            <span class="text-color-mm">(优先推荐使用关键词)</span>
          </div>
          <!-- 关键词 -->
          <div class="tag-cc-mm">
            <el-tag 
              v-for="(item,index) in branchForm.keyWordList" 
              :key="index"
              size="mini" 
              closable 
              @close="deleteKeyword(item)"
            >{{ item }}</el-tag>
          </div>
          <!-- 操作 -->
          <div class="tag-cc-mm">
            <div v-if="branchForm.keyWordList.length > 0">
              <el-button type="text" class="text-color-mm" v-copy="copyKeyWord">复制全部关键此</el-button>
              <el-button type="text" class="text-color-mm" @click="deleteAllKeyword">删除全部关键词</el-button>
            </div>
            <span v-else class="text-color-ff">暂无关键词</span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="branch_submit" :loading="loading" :disabled="branchDisabled">确定</el-button>
        <el-button @click="branchShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "intentionLibrary",
  props: {
    activeSpeechId: [String, Number]
  },
  data() {
    return {
      loading: false,
      intentionLibraryName: "",
      loadingTable: false,
      tableListData: [],
      branchTitle: "",
      branchShow: false,
      branchForm: {
        name: "",
        keyWord: "",
        keyWordList: []
      },
      modifyBranchId: ""
    }
  },
  computed: {
    copyKeyWord() {
      let keyWord = this.branchForm.keyWordList.join("|");
      return keyWord;
    },
    branchDisabled() {
      if (!this.branchForm.keyWord) {
        return false
      }
      return true
    }
  },
  mounted() {
    this.getListDialogueBranch();
  },
  methods: {
    // 点击查询
    inquire() {
      this.getListDialogueBranch();
    },
    // 列举话术所有回答意图
    getListDialogueBranch() {
      let data = {
        dialogueId: this.activeSpeechId,
        name: this.intentionLibraryName
      }
      this.tableListData = [];
      this.loadingTable = true;
      this.$http("/dialogueDefaultBranch/listDialogueDefaultBranch",null,"get",null,data).then(res => {
        this.loadingTable = false;
        if (res.result === 200) {
          let branchData = res.data;
          this.tableListData = branchData;
          // for (let i in branchData) {
          //   // if (i === 'anyAnswerBranch') {
          //   //   if (branchData[i]) {
          //   //     branchData[i].forEach(item => {
          //   //       item.branchType = "任何回复";
          //   //       this.tableListData.push(item);
          //   //     });
          //   //   }
          //   // }
          //   if (i === 'defaultBranch') {
          //     if (branchData[i]) {
          //       branchData[i].forEach(item => {
          //         item.branchType = "默认回答";
          //         this.tableListData.push(item);
          //       });
          //     }
          //   }
          //   if (i === 'customerBranch') {
          //     if (branchData[i]) {
          //       branchData[i].forEach(item => {
          //         item.branchType = "自定义回答";
          //         this.tableListData.push(item);
          //       });
          //     }
          //   }
          // }
        }
      }).catch(err => {
        this.loadingTable = false;
      })
    },
    // 点击创建意图
    addIntentionLibrary() {
      this.branchTitle = "新建默认意图";
      this.branchShow = true;
    },
    // 点击删除意图
    deleteBranch(val) {
      this.$confirm("<div>确定删除【"+ val.name +"】默认意图?</div><div style='color: #E6A23C'>该操作将同时删除所有节点上的此意图</div>", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      })
        .then(() => {
          let data = {
            id: val.id,
          };
          this.$service("/dialogueDefaultBranch/deleteDialogueDefaultBranch", data).then((res) => {
            if (res.result === 200) {
              this.$message.success("删除成功");
              this.getListDialogueBranch();
            }
          });
        })
        .catch(() => {});
    },
    // 点击修改
    modifyClick(val) {
      this.branchTitle = "编辑默认意图";
      this.branchForm.name = val.name;
      this.modifyBranchId = val.id;
      if (val.keyword) {
        this.branchForm.keyWordList = val.keyword.split("|");
      }
      this.branchShow = true;
    },
    // 添加关键词
    addKeyWord(tag) {
      if (tag) {
        this.branchForm.keyWord = "";
        let tagList = tag.split("|");
        let keyWordList = [...this.branchForm.keyWordList, ...tagList];
        this.branchForm.keyWordList = this.$common.unique(keyWordList);
      }
    },
     // 点击删除关键词
    deleteKeyword(tag) {
      this.branchForm.keyWordList.splice(
        this.branchForm.keyWordList.indexOf(tag),
        1
      );
    },
    // 点击删除全部关键词
    deleteAllKeyword() {
      this.branchForm.keyWordList = [];
    },
    // 确定增加意图
    branch_submit() {
      let data = {
        dialogueId: this.activeSpeechId,
        name: this.branchForm.name,
        keyword: this.copyKeyWord,
      };
      if (!this.branchForm.name) {
        this.$message.warning("请输入意图名称");
        return
      }
      this.loading = true;
      if (this.branchTitle === "新建默认意图") {
        let dialogueNodeBranchList = this.tableListData;
        for (let i in dialogueNodeBranchList) {
          let item = dialogueNodeBranchList[i];
          if (item.name === this.branchForm.name) {
            this.$message.warning("意图已存在");
            this.loading = false;
            return;
          }
        }
        this.$service("/dialogueDefaultBranch/addDialogueDefaultBranch", data).then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.getListDialogueBranch();
            this.branchShow = false;
          }
        }).catch(err => {
          this.loading = false;
        })
      }
      if (this.branchTitle === "编辑默认意图") {
        data.id = this.modifyBranchId;
        console.log(this.modifyBranchId);
        this.$service("/dialogueDefaultBranch/updateDialogueBranch", data).then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("编辑成功");
            this.getListDialogueBranch();
            this.branchShow = false;
          }
        }).catch(err => {
          this.loading = false;
        })
      }
    },
    // 关闭增加意图
    branchClose() {
      this.branchForm = {
        name: "",
        keyWord: "",
        keyWordList: []
      }
    },
  },
}
</script>
<style scoped>
.intentionLibrary {
  padding: 15px;
  height: 100%;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  /* margin-bottom: 20px; */
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
</style>