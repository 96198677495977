<template>
  <div class="machineList">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        label="机器列表"
        name="clientList"
        v-if="abnormalShow11 == true"
      >
        <div class="nav">
          <div class="batchName">
            选择机器：
            <el-input
              v-model="condition.codeOrName"
              placeholder="请输入机器编号，多个以逗号分隔"
              clearable
            ></el-input>
          </div>
          选择机器分组：
          <div class="batchName">
            <el-input
              v-model="condition.clientGroup"
              placeholder="请输入机器分组"
              clearable
            ></el-input>
          </div>
          设备状态：
          <div class="batchName">
            <el-select
              v-model="condition.shebeiIsOnline"
              placeholder="请选择客服状态"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in shebeiIsOnlineList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="nav">
          <div class="batchName">
            跟进运营：
            <el-input
              v-model="condition.operator"
              placeholder="请输入跟进运营"
              clearable
            ></el-input>
          </div>
          选择分组：
          <div class="batchName">
            <el-select
              v-model="condition.wechatClientGroupType"
              placeholder="请选择分组"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in wechatClientGroupTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 客服状态：
          <div class="batchName">
            <el-select
              v-model="condition.kefuIsOnline"
              placeholder="请选择客服状态"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in kefuIsOnlineList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="operate">
          <el-button type="primary" @click="inquire(1)">查询</el-button>
          <el-button type="primary" @click="machineInformationSync"
            >机器信息同步</el-button
          >
          <el-button
            type="primary"
            @click="batchAddGroup"
            :disabled="this.multipleSelection.length <= 0"
            >批量添加分组</el-button
          >
          <el-button
            type="primary"
            @click="exportClick"
            :disabled="downloadFansDis"
            >导出</el-button
          >
        </div>
        <!-- 机器列表 -->
        <el-table
          :data="wechatClientListTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="loadingTable"
          @selection-change="handleSelectionChange"
        >
          >
          <el-table-column type="selection" width="40" align="center">
          </el-table-column>
          <!-- <el-table-column
            prop="id"
            label="编号"
            width="60"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="clientCode"
            label="机器编号"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="clientGroup"
            label="机器分组"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="clientName"
            label="机器名称"
            min-width="150"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="operator"
            label="跟进运营"
            min-width="150"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="status"
            label="是否是二开机器"
            width="125"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.resaleClient">是</span>
              <span v-else>否</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.enabled" type="success" size="small"
                >启用</el-tag
              >
              <el-tag v-else type="info" size="small">禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="客服状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.kefuIsOnline" type="success" size="small"
                >在线</el-tag
              >
              <el-tag v-else type="info" size="small">离线</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="设备状态"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.shebeiIsOnline"
                type="success"
                size="small"
                >在线</el-tag
              >
              <el-tag v-else type="info" size="small">离线</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="添加时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column prop label="操作" width="90" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="updateClientInfoClick(scope.row)"
                >更新</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="clientInfoDelete(scope.row)"
                style="color: #ff0000b0"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->

        <el-pagination
          @size-change="pageSizeChange"
          @current-change="currentPageNo"
          :current-page.sync="pageNo"
          :page-sizes="[10, 30, 100, 200, 500, 1000, 3000]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane
        label="机器分组"
        name="clientGroup"
        v-if="abnormalShow22 == true"
      >
        <div class="nav">
          <div class="batchName">
            分组名称：
            <el-input
              v-model="conditionGroup.groupName"
              placeholder="请输入分组名称"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="operate">
          <el-button type="primary" @click="inquireGroup(1)">查询</el-button>
          <el-button type="primary" @click="wechatClientGroupClick(null, 'add')"
            >新增</el-button
          >
          <el-button type="primary" @click="syncRemainCount"
            >同步剩余加粉数</el-button
          >
        </div>
        <!-- 机器列表 -->
        <el-table
          :data="wechatClientGroupTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="loadingTable"
        >
          <!-- type="index" -->
          <!-- <el-table-column
            prop="id"
            label="编号"
            width="60"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="groupName"
            label="分组名称"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="wechatClientGroupType"
            label="分组类型"
            min-width="150"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.wechatClientGroupType == 'NORMAL'"
                >默认分组</span
              >
              <span v-if="scope.row.wechatClientGroupType == 'CLIENT'"
                >坐席分组</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="server"
            label="服务器"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remainCountForHour"
            label="小时剩余加粉数"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remainCountForDay"
            label="天剩余加粉数"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            min-width="150"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="添加时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column prop label="操作" width="90" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="wechatClientGroupClick(scope.row, 'update')"
                >更新</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="wechatClientGroupDelete(scope.row)"
                style="color: #ff0000b0"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="pageSizeGroupChange"
          @current-change="currentGroupPageNo"
          :current-page.sync="pageNoGroup"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="totalGroup"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane
        label="异常机器"
        name="abnormal"
        v-if="abnormalShow33 == true"
      >
        <div class="nav">
          <!-- <div class="batchName">
            服务器：
            <el-select
              v-model="wechatServer"
              placeholder="请选择服务器"
              clearable
            >
              <el-option
                v-for="item in wechatServerList"
                :key="item.server"
                :label="item.server"
                :value="item.server"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="batchName">
            异常时间：
            <el-date-picker
              v-model="abnormalGmtCreatedDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              align="right"
            ></el-date-picker>
          </div>
        </div>
        <div class="nav">
          <div class="batchName">
            状态：
            <i style="margin-left: 17px"></i>
            <el-select
              v-model="selectStatus"
              placeholder="请选择状态"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="batchName">
            机器分组：
            <el-input
              v-model="wechatGroupName"
              placeholder="请输入机器分组"
              clearable
            ></el-input>
            <!-- <el-select
              v-model="wechatGroupName"
              placeholder="请选择机器分组"
              clearable
            >
              <el-option
                v-for="item in wechatGroupNameList"
                :key="item.id"
                :label="item.groupName"
                :value="item.groupName"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>
        <div class="operate">
          <el-button type="primary" @click="inquireAbnormal(1)">查询</el-button>
          <el-checkbox v-model="filterRestore" style="margin-left: 20px"
            >过滤已处理异常机器</el-checkbox
          >
        </div>
        <!-- 机器列表 -->
        <el-table
          :data="abnormalTableData"
          stripe
          style="width: 100%"
          border=""
          v-loading="loadingTable"
          @sort-change="tableSortChange"
        >
          <!-- <el-table-column
            prop="id"
            label="编号"
            width="60"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="clientCode"
            label="机器编号"
            min-width="150"
            sortable="custom"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="operator"
            label="跟进运营"
            min-width="150"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            prop="wechatServer"
            label="服务器"
            min-width="150"
            align="center"
          ></el-table-column> -->
          <el-table-column
            prop="clientGroup"
            label="当前分组"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="sourceGroup"
            label="来源分组"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="exceptionCount"
            label="异常次数"
            min-width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag :type="scope.row.statusType" size="small">{{
                scope.row.statuslabel
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="result"
            label="错误信息"
            width="150"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="异常时间"
            width="170"
            sortable="custom"
            align="center"
          ></el-table-column>
          <el-table-column prop label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-popconfirm
                title="确定要处理吗"
                @confirm="callBack(scope.row)"
              >
                <el-button
                  type="text"
                  size="small"
                  :disabled="scope.row.auditStatus !== false"
                  slot="reference"
                  >已处理</el-button
                >
              </el-popconfirm>
              <el-button
                type="text"
                size="small"
                @click="unusualUpdate(scope.row)"
                style="color: #ff0000b0"
                :disabled="
                  scope.row.restore !== true || scope.row.auditStatus === false
                "
                >异常处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <my-pagination
          totalShow
          onePageNoShow
          pageSizeShow
          :tableList="abnormalTableData"
          @inquireTotal="inquireAbnormal(null, 'total')"
          @inquireOne="inquireAbnormal(1)"
          @currentPageNo="abnormalCurrentPageNo"
          @currentPageSize="abnormalPageSizeChange"
          class="el-pagination"
        ></my-pagination>
      </el-tab-pane>
      <el-tab-pane label="封号记录" name="banned">
        <banned v-if="bannedShow"></banned>
      </el-tab-pane>
    </el-tabs>
    <!-- 批量添加分组 -->
    <el-dialog
      title="批量添加分组"
      :visible.sync="batchUpdateClientInfoShow"
      @close="batchUpdateClientInfoClose"
      width="450px"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">批量添加分组</span>
      </template>
      <el-form label-position="left" label-width="70px" class="el-form">
        <el-form-item label="机器分组">
          <el-select
            v-model="batchClientGroup"
            placeholder="请选择机器分组"
            style="width: 100%"
            clearable
            filterable
          >
            <el-option
              v-for="item in clientGroupList"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="updateClientBatch_submit"
          >确定</el-button
        >
        <el-button @click="batchUpdateClientInfoShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 更新机器列表 -->
    <el-dialog
      title="更新"
      :visible.sync="updateClientInfoShow"
      @close="updateClientInfoClose"
      width="450px"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">更新</span>
      </template>
      <el-form
        :model="updateClientInfoForm"
        label-position="left"
        label-width="130px"
        class="el-form"
      >
        <el-form-item label="机器编号：">
          <el-input
            v-model="updateClientInfoForm.clientCode"
            placeholder="请输入机器编号"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="机器分组">
          <el-select
            v-model="updateClientInfoForm.clientGroup"
            placeholder="请选择机器分组"
            style="width: 100%"
            clearable
            filterable
          >
            <el-option
              v-for="item in clientGroupList"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机器名称：">
          <el-input
            v-model="updateClientInfoForm.clientName"
            placeholder="请输入机器名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="updateClientInfoForm.enabled"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          ></el-switch>
        </el-form-item>
        <el-form-item label="是否是二开机器：">
          <el-switch
            v-model="updateClientInfoForm.resaleClient"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="updateClientInfo_submit"
          >确定</el-button
        >
        <el-button @click="updateClientInfoShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 更新or新增机器分组 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="groupClientInfoShow"
      @close="groupClientInfoClose"
      width="450px"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ dialogTitle }}</span>
      </template>
      <el-form
        :model="groupClientInfoForm"
        label-position="left"
        label-width="90px"
        class="el-form"
      >
        <el-form-item label="服务器：">
          <el-select
            v-model="groupClientInfoForm.wechatClientGroupIdServer"
            placeholder="请选择服务器"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in serverList"
              :key="item.server"
              :label="item.server"
              :value="item.server"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分组类型">
          <el-select
            v-model="groupClientInfoForm.groupType"
            placeholder="请选择分组类型"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in groupTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分组名称：">
          <el-input
            v-model="groupClientInfoForm.groupName"
            placeholder="请输入分组名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="groupClientInfoForm.remark"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="updateClientGroup_submit"
          >确定</el-button
        >
        <el-button @click="groupClientInfoShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 异常处理 -->
    <el-dialog title="异常处理" :visible.sync="dialogFormVisible" width="450px">
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">异常处理</span>
        <el-tooltip
          content="异常机器处理完成后，需要手动加回到线上分组，继续进粉。"
          placement="top-start"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i>
        </el-tooltip>
      </template>
      <el-form :model="unusualForm" label-width="90px">
        <el-form-item label="机器分组">
          <el-select
            v-model="unusualForm.wechatGroupName"
            placeholder="请选择机器分组"
            clearable
          >
            <!-- :disabled="
              roleMenu !== 'SUPER_MANAGER' && roleMenu !== 'LIULIANG_OPERATOR'&& roleMenu !== 'WECHAT'
            " -->
            <el-option
              v-for="item in wechatGroupNameList"
              :key="item.id"
              :label="item.groupName"
              :value="item.groupName"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="unusualUpdate_submit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import myPagination from "../pages/myPagination.vue";
import banned from "./banned.vue";

export default {
  name: "machineList",
  props: ["roleMenu", "powers"],
  components: {
    myPagination,
    banned,
  },
  data() {
    return {
      bannedShow: false,
      //
      abnormalShow11: false,
      abnormalShow22: false,
      abnormalShow33: false,
      sort: "", //排序
      downloadFansDis: true,
      batchUpdateClientInfoShow: false,
      batchClientGroup: "",
      filterRestore: true,
      dialogFormVisible: false,
      unusualForm: {
        id: "",
        wechatGroupName: "",
      },
      wechatGroupNameList: [],
      wechatGroupName: "",
      statusList: [
        {
          label: "登录失效",
          value: "RELOGIN",
        },
        {
          label: "手机离线",
          value: "CLIENT_OFFLINE",
        },
        {
          label: "未登录",
          value: "NOTLOGGEDIN",
        },
        {
          label: "好友申请异常",
          value: "SUBMIT_FAILURE",
        },
      ],
      selectStatus: [],
      // wechatServer: "",
      // wechatServerList: [
      //   {
      //     server: "t1.innerchic.cn",
      //   },
      //   {
      //     server: "t9.innerchic.cn",
      //   },
      // ],
      abnormalGmtCreatedDate: [],
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      abnormalTableData: [],
      abnormalPageNo: 1,
      abnormalPageSize: 10,
      abnormalTotal: 0,
      serverList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
      ],
      groupTypeList: [
        {
          value: "NORMAL",
          label: "默认分组",
        },
        {
          value: "CLIENT",
          label: "坐席分组",
        },
      ],
      condition: {
        codeOrName: "",
        clientGroup: "",
        operator: "",
        wechatClientGroupType: "",
        kefuIsOnline: "",
        shebeiIsOnline: "",
      },
      conditionGroup: {
        groupName: "",
      },
      groupClientInfoShow: false,
      groupClientInfoForm: {
        groupName: "",
        groupType: "",
        remark: "",
        id: "",
        wechatClientGroupIdServer: "t9.innerchic.cn",
      },
      pageNoGroup: 1,
      pageSizeGroup: 10,
      totalGroup: 0,
      wechatClientGroupTableData: [],
      dialogTitle: "",
      wechatClientListTableData: [],
      loadingTable: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      updateClientInfoShow: false,
      clientGroupList: [], //查询所有机器分组
      updateClientInfoForm: {
        clientCode: "",
        clientGroup: "",
        clientName: "",
        enabled: false,
        resaleClient: false,
      },
      activeName: "clientList",
      unifiedLoading: null,
      multipleSelection: [], //多选数组
      clientCodes: [],
      clientCodesList: [],
      wechatClientGroupTypeList: [
        {
          value: "CLIENT",
          label: "坐席分组",
        },
        {
          value: "NORMAL",
          label: "普通分组",
        },
      ],
      kefuIsOnlineList: [
        {
          value: "true",
          label: "在线",
        },
        {
          value: "false",
          label: "离线",
        },
      ],
      shebeiIsOnlineList: [
        {
          value: "true",
          label: "在线",
        },
        {
          value: "false",
          label: "离线",
        },
      ],
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-机器管理";
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.abnormalGmtCreatedDate = [start, end];
    this.theWeek();
    this.inquire(1);
    this.getclientGroup();
    this.abnormalClick();
  },
  methods: {
    abnormalClick() {
      let powers = this.powers;
      for (let i in powers) {
        let item = powers[i];
        if (item.subMenus) {
          for (let j in item.subMenus) {
            let menu = item.subMenus[j];
            let index = JSON.parse(menu.extraMsg).index;
            if (index == "/exceptionClient/list11") {
              this.abnormalShow11 = true;
            }
            if (index == "/exceptionClient/list22") {
              this.abnormalShow22 = true;
            }
            if (index == "/exceptionClient/list33") {
              this.abnormalShow33 = true;
            }
          }
        }
      }
      if (this.abnormalShow11 !== true && this.abnormalShow33 === true) {
        this.activeName = "abnormal";
      } else if (this.abnormalShow11 !== true && this.abnormalShow22 === true) {
        this.activeName = "clientGroup";
      }
    },
    // 排序
    tableSortChange(column) {
      // console.log(column);
      if (column.order === "descending") {
        this.sort = column.prop + "&DESC";
      } else if (column.order === "ascending") {
        this.sort = column.prop + "&ASC";
      } else {
        this.sort = "";
      }
      this.inquireAbnormal();
    },
    // 多选框事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.clientCodesList = [];
    },
    // 批量添加分组
    batchAddGroup() {
      for (var i = 0; i < this.multipleSelection.length; i++) {
        this.clientCodesList.push(this.multipleSelection[i].clientCode);
      }
      // this.clientCodes = this.clientCodesList.join(",").split(',');
      this.clientCodes = Array.from(this.clientCodesList);
      this.batchUpdateClientInfoShow = true;
    },
    // 确定批量添加
    updateClientBatch_submit() {
      // console.log(this.clientCodes, this.batchClientGroup);
      let data = {
        clientCodes: this.clientCodes,
        clientGroup: this.batchClientGroup,
      };
      this.$service("/wechat/batchUpdateClientInfo", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("批量添加成功");
          this.batchUpdateClientInfoShow = false;
          this.inquire();
        }
      });
    },
    // 异常审核
    callBack(val) {
      let data = {
        id: val.id,
      };
      this.$http("/exceptionClient/submitAudit", null, "post", null, data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("审核请求发送成功");
            this.inquireAbnormal(1);
          }
        })
        .catch((err) => {});
    },
    // 异常处理
    unusualUpdate(val) {
      // console.log(val);
      // console.log(this.roleMenu);
      this.dialogFormVisible = true;
      this.unusualForm.wechatGroupName = val.sourceGroup;
      this.unusualForm.id = val.id;
    },
    // 异常处理确定
    unusualUpdate_submit() {
      // console.log(this.unusualForm.wechatGroupName)
      // console.log(this.unusualForm.id)
      // this.dialogFormVisible = false;
      let data = {
        id: this.unusualForm.id,
        wechatClientGroup: this.unusualForm.wechatGroupName,
      };
      this.$service("/exceptionClient/update", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("处理成功");
            this.dialogFormVisible = false;
            this.inquireAbnormal(1);
          }
        })
        .catch((err) => {
          this.dialogFormVisible = false;
        });
    },
    // 每周
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 点击同步剩余加粉数
    syncRemainCount() {
      this.$http("/wechat/syncRemainCount", null, "get").then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
        }
      });
    },
    // 点击机器信息同步
    machineInformationSync() {
      this.unifiedLoading = this.$loading({ message: "数据同步中..." });
      this.$http("/wechat/syncWechatClientInfo", null, "get")
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            this.$message.success("数据同步成功");
            this.inquire(1);
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    // tab切换
    handleClick(tab) {
      this.loadingTable = false;
      if (tab.name === "clientList") {
        this.inquire(1);
        this.bannedShow = false;
      }
      if (tab.name === "clientGroup") {
        this.inquireGroup(1);
        this.bannedShow = false;
      }
      if (tab.name === "abnormal") {
        let data = {
          pageNo: 1,
          pageSize: 1000,
        };
        this.$http(
          "/wechat/wechatClientGroupList",
          null,
          "get",
          null,
          data
        ).then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.wechatGroupNameList = list;
          }
        });
        this.inquireAbnormal(1);
        this.bannedShow = false;
      }
      if (tab.name === "banned") {
        this.bannedShow = true;
      }
    },
    // 点击查询--异常机器
    inquireAbnormal(index, isTotal) {
      if (index) {
        this.abnormalPageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.abnormalGmtCreatedDate) {
        gmtCreatedStart = this.abnormalGmtCreatedDate[0];
        gmtCreatedEnd = this.abnormalGmtCreatedDate[1];
      }
      let data = {
        pageNo: index || this.abnormalPageNo,
        pageSize: this.abnormalPageSize,
        status: this.selectStatus.join(","),
        // wechatServer: this.wechatServer,
        wechatGroupName: this.wechatGroupName,
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
        // restore: this.filterRestore,
        sort: this.sort,
      };
      if (this.filterRestore === true) {
        data.restore = true;
      } else {
        data.restore = "";
      }
      if (isTotal) {
        data.queryTotalCount = true;
      }
      this.getAbnormalList(data, isTotal);
    },
    // 查询机器列表
    getAbnormalList(data, isTotal) {
      if (!isTotal) {
        this.loadingTable = true;
      }
      // this.$http("/wechat/requestLogList", null, "get", null, data)
      this.$http("/exceptionClient/list", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.abnormalTotal = res.data.total;
            if (isTotal) {
              this.$message.success("数据总共 " + res.data.total + " 条");
              return;
            }
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              switch (item.status) {
                case "CREATING":
                  item.statusType = "warning";
                  item.statuslabel = "请求发送中";
                  break;
                case "SUBMITTED":
                  item.statusType = "warning";
                  item.statuslabel = "已提交好友申请";
                  break;
                case "NO_REQUEST":
                  item.statusType = "warning";
                  item.statuslabel = "未加微信";
                  break;
                case "NOT_FOUND":
                  item.statusType = "danger";
                  item.statuslabel = "未查询到好友";
                  break;
                case "SUBMIT_FAILURE":
                  item.statusType = "danger";
                  item.statuslabel = "好友申请异常";
                  break;
                // case "REJECTED":
                //   item.statusType = "danger";
                //   item.statuslabel = "拒绝好友申请";
                //   break;
                case "EXPIRED":
                  item.statusType = "danger";
                  item.statuslabel = "好友申请已过期";
                  break;
                case "RELOGIN":
                  item.statusType = "danger";
                  item.statuslabel = "登录失效";
                  break;
                case "CLIENT_OFFLINE":
                  item.statusType = "danger";
                  item.statuslabel = "手机离线";
                  break;
                case "NOTLOGGEDIN":
                  item.statusType = "danger";
                  item.statuslabel = "未登录";
                  break;
                case "ADDEDFRIEND":
                  item.statusType = "success";
                  item.statuslabel = "已经是好友";
                  break;
                case "PASSED":
                  item.statusType = "success";
                  item.statuslabel = "通过好友申请";
                  break;
                default:
                  item.statusType = "warning";
                  item.statuslabel = "未知";
                  break;
              }
            });
            this.abnormalTableData = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    abnormalCurrentPageNo(index) {
      this.abnormalPageNo = index;
      this.inquireAbnormal();
    },
    // 数量分页
    abnormalPageSizeChange(index) {
      this.abnormalPageSize = index;
      this.inquireAbnormal(1);
    },
    // 点击更新or点击新增--机器分组
    wechatClientGroupClick(val, key) {
      this.groupClientInfoShow = true;
      if (val) {
        this.groupClientInfoForm = {
          groupName: val.groupName,
          groupType: val.wechatClientGroupType,
          remark: val.remark,
          id: val.id,
          wechatClientGroupIdServer: val.server,
        };
      }
      if (key === "add") {
        this.dialogTitle = "新增";
      }
      if (key === "update") {
        this.dialogTitle = "更新";
      }
    },
    // 确定更新--机器分组
    updateClientGroup_submit() {
      let data = {
        groupName: this.groupClientInfoForm.groupName,
        wechatClientGroupType: this.groupClientInfoForm.groupType,
        server: this.groupClientInfoForm.wechatClientGroupIdServer,
        remark: this.groupClientInfoForm.remark,
      };
      if (this.dialogTitle === "新增") {
        this.$service("/wechat/addWechatClientGroup", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.groupClientInfoShow = false;
            this.inquireGroup();
          }
        });
      }
      if (this.dialogTitle === "更新") {
        data.id = this.groupClientInfoForm.id;
        this.$service("/wechat/updateWechatClientGroup", data).then((res) => {
          if (res.result === 200) {
            this.$message.success("更新成功");
            this.groupClientInfoShow = false;
            this.inquireGroup();
          }
        });
      }
    },
    // yc--start-----------------------------------------------
    // 机器列表删除
    clientInfoDelete(val) {
      this.$confirm("【" + val.clientCode + "】删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let data = {
            clientCode: val.clientCode,
          };
          this.$service("/wechat/wechatClient/delete", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.inquire();
            }
          });
        })
        .catch(() => {});
    },
    //  机器分组删除
    wechatClientGroupDelete(val) {
      // console.log(val.groupName)
      this.$confirm("【" + val.groupName + "】删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let data = {
            clientGroup: val.groupName,
          };
          this.$service("/wechat/wechatClientGroup/delete", data).then(
            (res) => {
              if (res.result == 200) {
                this.$message({ message: "删除成功", type: "success" });
                this.inquireGroup();
              }
            }
          );
        })
        .catch(() => {});
    },
    // yc--end-------------------------------------------------
    // 点击关闭更新--机器分组
    groupClientInfoClose() {
      this.groupClientInfoForm = {
        groupName: "",
        groupType: "",
        remark: "",
        id: "",
        wechatClientGroupIdServer: "t9.innerchic.cn",
      };
    },
    // 点击查询--机器分组
    inquireGroup(index) {
      if (index) {
        this.pageNoGroup = index;
      }
      let data = {
        pageNo: index || this.pageNoGroup,
        pageSize: this.pageSizeGroup,
        groupName: this.conditionGroup.groupName,
      };
      this.getWechatClientGroupList(data);
    },
    // 查询机器列表
    getWechatClientGroupList(data) {
      this.loadingTable = true;
      this.$http("/wechat/wechatClientGroupList", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            });
            this.wechatClientGroupTableData = list;
            this.totalGroup = res.data.total;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentGroupPageNo(index) {
      this.pageNoGroup = index;
      this.inquireGroup();
    },
    // 数量分页
    pageSizeGroupChange(index) {
      this.pageSizeGroup = index;
      this.inquireGroup(1);
    },
    //yc-------start------------------------------------------------------------
    // 查询所有机器分组
    getclientGroup() {
      this.$http("/wechat/clientGroup/name/list", null, "get", null, null).then(
        (res) => {
          if (res.result === 200) {
            let list = [];
            for (let i in res.data) {
              let obj = {
                value: res.data[i],
              };
              list.push(obj);
            }
            this.clientGroupList = list;
            // console.log(this.clientGroupList, res.data);
          }
        }
      );
    },
    //yc-------end--------------------------------------------------------------
    // 点击更新
    updateClientInfoClick(val) {
      this.getclientGroup();
      this.updateClientInfoShow = true;
      this.updateClientInfoForm = {
        clientCode: val.clientCode,
        clientGroup: val.clientGroup,
        clientName: val.clientName,
        enabled: val.enabled,
        resaleClient: val.resaleClient,
      };
    },
    // 确定更新
    updateClientInfo_submit() {
      let data = {
        clientCode: this.updateClientInfoForm.clientCode,
        clientGroup: this.updateClientInfoForm.clientGroup,
        clientName: this.updateClientInfoForm.clientName,
        enabled: this.updateClientInfoForm.enabled,
        resaleClient: this.updateClientInfoForm.resaleClient,
      };
      this.$service("/wechat/updateClientInfo", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("更新成功");
          this.updateClientInfoShow = false;
          this.inquire();
        }
      });
    },
    // 点击关闭更新
    updateClientInfoClose() {
      this.updateClientInfoForm = {
        clientCode: "",
        clientGroup: "",
        clientName: "",
        enabled: false,
        resaleClient: false,
      };
    },
    // 关闭批量添加分组
    batchUpdateClientInfoClose() {
      this.batchClientGroup = "";
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: index || this.pageNo,
        pageSize: this.pageSize,
        code: this.condition.codeOrName,
        clientGroup: this.condition.clientGroup,
        operator: this.condition.operator,
        wechatClientGroupType: this.condition.wechatClientGroupType,
        // kefuIsOnline: this.condition.kefuIsOnline,
        shebeiIsOnline: this.condition.shebeiIsOnline,
        sort: "clientCode&ASC",
      };
      this.getClientList(data);
    },
    // 查询机器列表
    getClientList(data) {
      this.loadingTable = true;
      this.downloadFansDis = true;
      this.$http("/wechat/clientList", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            });
            if (list.length > 0) {
              this.downloadFansDis = false;
            }
            this.wechatClientListTableData = list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 点击导出
    exportClick() {
      // let gmtCreatedStart = "";
      // let gmtCreatedEnd = "";
      // if (this.abnormalGmtCreatedDate) {
      //   gmtCreatedStart = this.abnormalGmtCreatedDate[0].substring(0, 10);
      //   gmtCreatedEnd = this.abnormalGmtCreatedDate[1].substring(0, 10);
      // }
      let data = {
        code: this.condition.codeOrName,
        clientGroup: this.condition.clientGroup,
        operator: this.condition.operator,
        wechatClientGroupType: this.condition.wechatClientGroupType,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href = this.$constants.baseURL + "/wechat/clientList/export?" + url;
      window.location.href = href;
    },
    // 翻页
    currentPageNo(index) {
      this.pageNo = index;
      this.inquire();
    },
    // 数量分页
    pageSizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
  },
};
</script>
<style scoped>
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>

