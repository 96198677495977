<template>
  <div class="analysisReport">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="普通批次" name="ordinary">
        <div class="nav_menu">
          <div class="callTime">导入日期：
            <el-date-picker
              v-model="condition.callEndAtStart"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              align="right">
            </el-date-picker>
          </div>
          <div class="companyName">用户名称：
            <el-select v-model="condition.userId" placeholder="请选择用户名称" @change="userIdChange" filterable remote :loading="loadingName" :remote-method="NameRemoteMethod" @focus="NameRemoteFocus" clearable>
              <el-option
                v-for="item in userNameList"
                :key="item.id"
                :value="item.id"
                :label="item.userName"
              ></el-option>
            </el-select>
          </div>
          <div class="batchName">批次名称：
            <el-input v-model="condition.batchName" placeholder="请输入批次名称" clearable></el-input>
          </div>
          <div class="taskName">项目名称：
            <el-select v-model="condition.projectName" placeholder="请选择项目名称" filterable remote :loading="loadingProject" :remote-method="projectRemoteMethod" @focus="projectIdRemoteFocus" clearable>
              <el-option
                v-for="item in projectNameList"
                :key="item.id"
                :value="item.projectName"
                :label="item.projectNameLabel"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquire('ordinary')">查询</el-button>
            <el-button type="success" @click="derivedData('ordinary')" :disabled="derivedDis">导出数据</el-button>
          </div>
        </div>
        <!-- 批次表 -->
        <el-table :data="tableData" stripe style="width: 100%" border class='tableDataClass' :summary-method="getSummaries" show-summary @cell-click='handleCurrentChange' highlight-current-row @sort-change="batchOrder" v-loading="loadingList1">
          <el-table-column type="index" label="编号" width="60" align="center"></el-table-column>
          <el-table-column prop="batchName" label="批次名称" sortable='custom' min-width="230" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="100" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="callCount" label sortable='custom' min-width="120" align="center">
            <template slot="header" slot-scope="scope">
              <span>拨打次数</span>
              <el-tooltip class="item" effect="light" :content="callCountText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="total" label="数据量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="callNumber" label min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <span>已拨打量</span>
              <el-tooltip class="item" effect="light" :content="callNumberText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="answerCount" label min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <span>接通量</span>
              <el-tooltip class="item" effect="light" :content="answerCountText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="fee" label="话费" min-width="100" align="center"></el-table-column> -->
          <el-table-column prop="costFee" label="话费" min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <span>话费</span>
              <el-tooltip class="item" effect="light" content="按照成本统计" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="greaterThan60s" label min-width="175" align="center">
            <template slot="header" slot-scope="scope">
              <span>一分钟以上通话次数</span>
              <el-tooltip class="item" effect="light" :content="greaterThan60sText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="orderNumber" label="成单量" min-width="80" align="center"></el-table-column>
          <el-table-column prop="answerRate" label min-width="110" sortable='custom' align="center">
            <template slot="header" slot-scope="scope">
              <span>接通率</span>
              <el-tooltip class="item" effect="light" :content="answerRateText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="conversionRate" label min-width="135" sortable='custom' align="center">
            <template slot="header" slot-scope="scope">
              <span>接通转化率</span>
              <el-tooltip class="item" effect="light" :content="conversionRateText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="greaterThan60sRate" label min-width="190" sortable='custom' align="center">
            <template slot="header" slot-scope="scope">
              <span>一分钟以上通话占比</span>
              <el-tooltip class="item" effect="light" :content="greaterThan60sRateText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="orderRate" label min-width="110" sortable='custom' align="center">
            <template slot="header" slot-scope="scope">
              <span>成单率</span>
              <el-tooltip class="item" effect="light" :content="orderRateText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页1 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="currentPage_data"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <div class="displayBlock"></div>
        <!-- 项目表 -->
        <el-table :data="projectTableData" stripe style="width: 100%" border class='tableDataClass' @sort-change="projectNameOrder" v-loading="loadingList2">
          <el-table-column type="index" label="编号" width="60" align="center"></el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="100" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="label" label="标签" min-width="135" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="mobileCity" label="号码归属地" min-width="100" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="total" label="数据量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="callNumber" label min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <span>已拨打量</span>
              <el-tooltip class="item" effect="light" :content="callNumberText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="answerCount" label min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <span>接通量</span>
              <el-tooltip class="item" effect="light" :content="answerCountText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="fee" label="话费" min-width="100" align="center"></el-table-column>
          <el-table-column prop="greaterThan60s" label min-width="175" align="center">
            <template slot="header" slot-scope="scope">
              <span>一分钟以上通话次数</span>
              <el-tooltip class="item" effect="light" :content="greaterThan60sText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="orderNumber" label="成单量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="answerRate" label min-width="110" sortable='custom' align="center">
            <template slot="header" slot-scope="scope">
              <span>接通率</span>
              <el-tooltip class="item" effect="light" :content="answerRateText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="conversionRate" label min-width="135" sortable='custom' align="center">
            <template slot="header" slot-scope="scope">
              <span>接通转化率</span>
              <el-tooltip class="item" effect="light" :content="conversionRateText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="greaterThan60sRate" label min-width="190" sortable='custom' align="center">
            <template slot="header" slot-scope="scope">
              <span>一分钟以上通话占比</span>
              <el-tooltip class="item" effect="light" :content="greaterThan60sRateText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="orderRate" label min-width="110" sortable='custom' align="center">
            <template slot="header" slot-scope="scope">
              <span>成单率</span>
              <el-tooltip class="item" effect="light" :content="orderRateText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页2 -->
        <el-pagination
          @size-change="projectSizeChange"
          @current-change="currentPage_project"
          :current-page.sync="projectCurrentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="projectTotal">
        </el-pagination>
        <div class="displayBlock"></div>
      </el-tab-pane>

      <el-tab-pane label="微信批次" name="wechat">
        <div class="nav_menu">
          <div class="callTime">导入日期：
            <el-date-picker
              v-model="wecahtCondition.callEndAtStart"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              align="right">
            </el-date-picker>
          </div>
          <div class="companyName">用户名称：
            <el-select v-model="wecahtCondition.userId" placeholder="请选择用户名称" @change="userIdChange" filterable remote :loading="loadingName" :remote-method="NameRemoteMethod" @focus="NameRemoteFocus" clearable>
              <el-option
                v-for="item in userNameList"
                :key="item.id"
                :value="item.id"
                :label="item.userName"
              ></el-option>
            </el-select>
          </div>
          <div class="batchName">批次名称：
            <el-input v-model="wecahtCondition.batchName" placeholder="请输入批次名称" clearable></el-input>
          </div>
          <div class="taskName">项目名称：
            <el-select v-model="wecahtCondition.projectName" placeholder="请选择项目名称" filterable remote :loading="loadingProject" :remote-method="projectRemoteMethod" @focus="projectIdRemoteFocus" clearable>
              <el-option
                v-for="item in projectNameList"
                :key="item.id"
                :value="item.projectName"
                :label="item.projectNameLabel"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquire('wechat')">查询</el-button>
            <el-button type="success" @click="derivedData('wechat')" :disabled="wechatDerivedDis">导出数据</el-button>
          </div>
        </div>
        <!-- 微信批次表 -->
        <el-table :data="wecahtTableData" stripe style="width: 100%" border class='tableDataClass' :summary-method="getSummariesWecaht" show-summary @sort-change="batchOrder" v-loading="loadingList1">
          <el-table-column type="index" label="编号" width="60" align="center"></el-table-column>
          <el-table-column prop="batchName" label="批次名称" sortable='custom' min-width="230" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectName" label="项目名称" min-width="100" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="callCount" label sortable='custom' min-width="120" align="center">
            <template slot="header" slot-scope="scope">
              <span>拨打次数</span>
              <el-tooltip class="item" effect="light" :content="callCountText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="total" label="数据量" min-width="100" align="center"></el-table-column>
          <el-table-column prop="callNumber" label min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <span>已拨打量</span>
              <el-tooltip class="item" effect="light" :content="callNumberText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="answerCount" label min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <span>接通量</span>
              <el-tooltip class="item" effect="light" :content="answerCountText" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="costFee" label="话费" min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <span>话费</span>
              <el-tooltip class="item" effect="light" content="按照成本统计" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="wechatHelloCount" label="招呼量" min-width="80" align="center"></el-table-column>
          <el-table-column prop="wechatFansCount" label="加粉量" min-width="80" align="center"></el-table-column>
          <el-table-column prop="wechatHelloPassRate" label min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <span>招呼通过率</span>
              <el-tooltip class="item" effect="light" content="加粉量/招呼量" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="answerRate" label="接通率" min-width="110" sortable='custom' align="center">
          </el-table-column>
          <el-table-column prop="wechatFansHelloRate" label="接通招呼率" min-width="135" align="center">
            <template slot="header" slot-scope="scope">
              <span>接通招呼率</span>
              <el-tooltip class="item" effect="light" content="招呼量/接通量" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="wechatPassRate" label="接通加粉率" min-width="135" align="center">
            <template slot="header" slot-scope="scope">
              <span>接通加粉率</span>
              <el-tooltip class="item" effect="light" content="加粉量/接通量" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="wechatNotFoundRate" label min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <span>无微信率</span>
              <el-tooltip class="item" effect="light" content="无微信人数/接口调用次数" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="markingPayAmount" label="打标量" min-width="130" align="center"></el-table-column>
          <el-table-column prop="markingPayRate" label="打标率" min-width="130" align="center"></el-table-column>
        </el-table>
        <!-- 分页1 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="currentPage_data"
          :current-page.sync="wechatPageNo"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="wechatTotal">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name:'analysisReport',
  data(){
    return {
      activeName: "ordinary",
      wecahtTableData: [],
      wechatPageSize: 10,
      wechatTotal: 0,
      wechatPageNo: 1,
      wechatDerivedDis: true,
      wechatOrder: {},
      loadingList1: false,
      loadingList2: false,
      pickerOptions: {
        disabledDate(time) {
            const start = new Date();
            start.setHours(23);
            start.setMinutes(59);
            start.setSeconds(59);
            start.setMilliseconds(59);
            return time.getTime() > start;
        },
        onPick({ maxDate, minDate }){
          if(minDate){
            sessionStorage.setItem('theTime', minDate.getTime());
          }
        },
        shortcuts: [{
          text: '本月记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本周记录',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('thisWeek');
            start = parseInt(start);
            picker.$emit('pick', [new Date(start), end]);
          }
        }, {
          text: '本日记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '此刻',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('theTime');
            if(start){
              start = parseInt(start);
              picker.$emit('pick', [new Date(start), end]);
              sessionStorage.removeItem('theTime');
            }
          }
        }]
      },
      condition:{
        userId:'',
        projectName:'',
        callEndAtStart:'',
        batchName:'',
        loginName: ''
      },
      wecahtCondition:{
        userId:'',
        projectName:'',
        callEndAtStart:'',
        batchName:'',
        loginName: ''
      },
      userNameList:[],
      projectNameList:[],
      tableData:[],
      projectTableData:[],
      currentPage:1,
      pageSize:10,
      pageSizePro:10,
      total:0,
      projectCurrentPage:1,
      projectTotal:0,
      listTotal:{},
      listTotalWecaht:{},
      callCountText:'状态为“完成”的次数',
      answerCountText:'所有遍数接通的数据量',
      greaterThan60sText:'通话时长大于60s的通话数量',
      answerRateText:'接通量/已拨打量',
      conversionRateText:'成单量/接通量',
      greaterThan60sRateText:'一分钟以上通话次数/接通量',
      orderRateText:'成单量/已拨打量',
      callNumberText:'接通量+未接通量',
      order:{},
      projectOrder:{},
      batchName:'',
      defaultTime:['00:00:00','23:59:59'],
      loadingName:false,
      loadingProject:false,
      derivedDis:true,
    }
  },
  mounted(){
    document.title = "惠销平台管理系统-报表分析";
    this.theWeek();
    this.inquireUserList({pageSize: 1000,pageNo: 1,enabled: true});
    this.inquireProject({pageSize: 20,pageNo: 1});
    let end = new Date(),
        start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.callEndAtStart = [start, end];
    this.wecahtCondition.callEndAtStart = [start, end];
  },
  methods:{
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
    // 切换tab页
    handleClick(val) {
      if (this.activeName === "ordinary") {

      }
      if (this.activeName === "wechat") {

      }
    },
    // 查询微信批次
    wechatInquire() {

    },
    // 公司名称获取焦点
    NameRemoteFocus(){
      this.inquireUserList({pageSize: 1000,pageNo: 1,enabled: true});
    },
    NameRemoteMethod(userName) {//搜索公司名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize:1000,
          pageNo:1,
          userName:userName,
          enabled: true
        }
        this.inquireUserList(params)
      }, 200);
    },
    // 项目搜索获取焦点
    projectIdRemoteFocus() {
      let params = {
        pageSize:20,
        pageNo:1,
        loginName: this.condition.loginName,
      }
      this.inquireProject(params)
    },
    projectRemoteMethod(projectName) {//搜索项目名称
      this.loadingProject = true;
      setTimeout(() => {
        this.loadingProject = false;
        let params = {
          pageSize:20,
          pageNo:1,
          loginName: this.condition.loginName,
          projectName:projectName
        }
        this.inquireProject(params)
      }, 200);
    },
    batchOrder(val){//批次表排序
      this.order = {};
      if(val.order == 'descending'){
        val.order = 'desc'
      }else{
        val.order = 'asc'
      }
      let params = {}
      if (this.activeName === "ordinary") {
        switch(val.prop){
          case "batchName":
            this.order['batchName'] = val.order;
            break;
          case "callCount":
            this.order['callCount'] = val.order;
            break;
          case "greaterThan60sRate":
            this.order['greaterThan60sRate'] = val.order;
            break;
          case "answerRate":
            this.order['answerRate'] = val.order;
            break;
          case "conversionRate":
            this.order['conversionRate'] = val.order;
            break;
          case "orderRate":
            this.order['orderRate'] = val.order;
            break;
        }
        params = {
          pageSize:this.pageSize,
          pageNo:this.currentPage,
          fuzzyBatchName:this.condition.batchName,
          projectName:this.condition.projectName,
          userId:this.condition.userId,
        }
        params.batchNameOrder = this.order["batchName"];
        params.callCountOrder = this.order["callCount"];
        params.greaterThan60sRateOrder = this.order["greaterThan60sRate"];
        params.answerRateOrder = this.order["answerRate"];
        params.conversionRateOrder = this.order["conversionRate"];
        params.orderRateOrder = this.order["orderRate"];
        if(this.condition.callEndAtStart){
          params.importDateStart=this.condition.callEndAtStart[0];
          params.importDateEnd=this.condition.callEndAtStart[1];
        }else{
          this.$message({message:'请选择导入时间',type:'warning'});
          return;
        }
      }
      if (this.activeName === "wechat") {
        switch(val.prop){
          case "batchName":
            this.wechatOrder['batchName'] = val.order;
            break;
          case "callCount":
            this.wechatOrder['callCount'] = val.order;
            break;
          case "greaterThan60sRate":
            this.wechatOrder['greaterThan60sRate'] = val.order;
            break;
          case "answerRate":
            this.wechatOrder['answerRate'] = val.order;
            break;
          case "conversionRate":
            this.wechatOrder['conversionRate'] = val.order;
            break;
          case "orderRate":
            this.wechatOrder['orderRate'] = val.order;
            break;
        }
        params = {
          pageSize:this.wechatPageSize,
          pageNo:this.wechatPageNo,
          fuzzyBatchName:this.wecahtCondition.batchName,
          projectName:this.wecahtCondition.projectName,
          userId:this.wecahtCondition.userId,
        }
        params.batchNameOrder = this.wechatOrder["batchName"];
        params.callCountOrder = this.wechatOrder["callCount"];
        params.greaterThan60sRateOrder = this.wechatOrder["greaterThan60sRate"];
        params.answerRateOrder = this.wechatOrder["answerRate"];
        params.conversionRateOrder = this.wechatOrder["conversionRate"];
        params.orderRateOrder = this.wechatOrder["orderRate"];
        if(this.wecahtCondition.callEndAtStart){
          params.importDateStart=this.wecahtCondition.callEndAtStart[0];
          params.importDateEnd=this.wecahtCondition.callEndAtStart[1];
        }else{
          this.$message({message:'请选择导入时间',type:'warning'});
          return;
        }
      }
      this.loadingList1 = true;
      this.inquireList1(params);
    },
    projectNameOrder(val){//项目表排序
      this.projectOrder = {};
      if(val.order == 'descending'){
        val.order = 'desc'
      }else{
        val.order = 'asc'
      }
      switch(val.prop){
        case "callCount":
          this.projectOrder['callCount'] = val.order;
          break;
        case "greaterThan60sRate":
          this.projectOrder['greaterThan60sRate'] = val.order;
          break;
        case "answerRate":
          this.projectOrder['answerRate'] = val.order;
          break;
        case "conversionRate":
          this.projectOrder['conversionRate'] = val.order;
          break;
        case "orderRate":
          this.projectOrder['orderRate'] = val.order;
          break;
      }
      let params = {
        pageSize:this.pageSizePro,
        pageNo:this.projectCurrentPage,
        // fuzzyBatchName:this.batchName,
        projectName:this.condition.projectName,
        userId:this.condition.userId,
        importDateStart:this.condition.callEndAtStart[0],
        importDateEnd:this.condition.callEndAtStart[1],
        callCountOrder:this.projectOrder["callCount"],
        greaterThan60sRateOrder:this.projectOrder["greaterThan60sRate"],
        answerRateOrder:this.projectOrder["answerRate"],
        conversionRateOrder:this.projectOrder["conversionRate"],
        orderRateOrder:this.projectOrder["orderRate"],
      }
      if(this.batchName){
        params.batchName = this.batchName;
      }else{
        params.fuzzyBatchName = this.condition.batchName;
      }
      this.loadingList2 = true
      this.inquireList2(params);
    },
    handleCurrentChange(row, column, cell, event){//选批次查list2
      this.batchName = row.batchName;
      let params = {
        batchName:row.batchName,
        pageSize:this.pageSizePro,
        pageNo:1,
        projectName:this.condition.projectName,
        userId:this.condition.userId,
      }
      this.projectCurrentPage = 1;
      if(this.condition.callEndAtStart){
        params.importDateStart=this.condition.callEndAtStart[0];
        params.importDateEnd=this.condition.callEndAtStart[1];
      }else{
        this.$message({message:'请选择导入时间',type:'warning'});
        return;
      }
      this.loadingList2 = true
      this.inquireList2(params);
    },
    inquire(key){//查询数据
      if (key === "ordinary") {
        let params = {
          pageSize:this.pageSize,
          pageNo:1,
          fuzzyBatchName:this.condition.batchName,
          projectName:this.condition.projectName,
          userId:this.condition.userId,
        }
        if(this.condition.callEndAtStart){
          params.importDateStart=this.condition.callEndAtStart[0];
          params.importDateEnd=this.condition.callEndAtStart[1];
        }else{
          this.$message({message:'请选择导入时间',type:'warning'});
          return;
        }
        this.currentPage = 1;
        this.projectCurrentPage = 1;
        this.batchName = '';
        this.loadingList1 = true;
        this.loadingList2 = true;
        this.inquireList1(params);
        this.$nextTick(()=>{
          let data = this.$common.deepClone(params);
          data.pageSize = this.pageSizePro;
          this.inquireList2(data);
        })
      }
      if (key === "wechat") {
        let params = {
          pageSize: this.wechatPageSize,
          pageNo: 1,
          fuzzyBatchName: this.wecahtCondition.batchName,
          projectName: this.wecahtCondition.projectName,
          userId: this.wecahtCondition.userId,
        }
        if(this.wecahtCondition.callEndAtStart){
          params.importDateStart=this.wecahtCondition.callEndAtStart[0];
          params.importDateEnd=this.wecahtCondition.callEndAtStart[1];
        }else{
          this.$message({message:'请选择导入时间',type:'warning'});
          return;
        }
        this.wechatPageNo = 1;
        this.loadingList1 = true;
        this.inquireList1(params);
      }
    },
    inquireList1(params){//查询list1
      if (this.activeName === "ordinary") {
        this.tableData = [];
        this.listTotal = {};
        // params.addWechat = false;
      }
      if (this.activeName === "wechat") {
        this.wecahtTableData = [];
        this.listTotalWecaht = {};
        params.addWechat = true;
      }
      this.$http('/call400Stats/label/list',null,'get',null,params).then(res=>{
        if(res.result == 200){
          if (res.data) {
            let list = res.data.data;
            list.forEach(item => {
              if (item.costFee) {
                item.costFee = (item.costFee / 1000.).toFixed(2);
              }
            })
            if(list && list.length>0){
              if (this.activeName === "ordinary") {
                this.total = res.data.total;
                this.derivedDis = false;
                this.tableData = list;
              }
              if (this.activeName === "wechat") {
                this.wechatTotal = res.data.total;
                this.wechatDerivedDis = false;
                this.wecahtTableData = list;
              }
            }
          }
        }
      })
      this.$http('/call400Stats/label/list1/total',null,'get',null,params).then(res=>{
        setTimeout(() => {
          this.loadingList1 = false
        }, 200);
        if(res.result == 200){
          if (res.data) {
            let obj = res.data;
            if (this.activeName === "ordinary") {
              this.listTotal = obj;
            }
            if (this.activeName === "wechat") {
              obj.wechatFansHelloRate = "0%";
              obj.wechatPassRate = "0%";
              obj.wechatNotFoundRate = "0%";
              if (obj.answerCount) {
                obj.wechatFansHelloRate = (obj.wechatHelloCount / obj.answerCount * 100).toFixed(2) + "%";
                obj.wechatPassRate = (obj.wechatFansCount / obj.answerCount * 100).toFixed(2) + "%";
              }
              if (obj.totalWechatRequestCount) {
                obj.wechatNotFoundRate = (obj.totalWechatNotFoundCount / obj.totalWechatRequestCount * 100).toFixed(2) + "%";
              }
              this.listTotalWecaht = obj;
            }
          }
        }
      })
    },
    inquireList2(params){//查询list2
      this.$http('/call400Stats/label/list2',null,'get',null,params).then(res=>{
        setTimeout(() => {
          this.loadingList2 = false
        }, 200);
        if(res.result == 200){
          this.projectTotal = res.data.count;
          let list = res.data.list;
          this.projectTableData = list;
        }
      })
    },
    getSummaries(param){//合计行普通批次
      const { columns, data } = param;
      let arr=[]
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = '合计';
          return;
        }
        for(let i in  this.listTotal){
          if(i == column.property){
            arr[index] = this.listTotal[i]
          }
        }
      })
      return arr;
    },
    getSummariesWecaht(param){//合计行微信批次
      const { columns, data } = param;
      let arr=[]
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = '合计';
          return;
        }
        for(let i in  this.listTotalWecaht){
          if(i == column.property){
            arr[index] = this.listTotalWecaht[i]
          }
        }
      })
      return arr;
    },
    derivedData(key){//导出数据
      let params = {}
      if (key === "ordinary") {
        params.fuzzyBatchName = this.condition.batchName;
        params.projectName = this.condition.projectName;
        params.userId = this.condition.userId;
        if(this.condition.callEndAtStart){
          params.importDateStart=this.condition.callEndAtStart[0];
          params.importDateEnd=this.condition.callEndAtStart[1];
        }else{
          this.$message({message:'请选择导入时间',type:'warning'});
          return;
        }
      }
      if (key === "wechat") {
        params.fuzzyBatchName = this.wecahtCondition.batchName;
        params.projectName = this.wecahtCondition.projectName;
        params.userId = this.wecahtCondition.userId;
        params.addWechat = true;
        if(this.condition.callEndAtStart){
          params.importDateStart=this.wecahtCondition.callEndAtStart[0];
          params.importDateEnd=this.wecahtCondition.callEndAtStart[1];
        }else{
          this.$message({message:'请选择导入时间',type:'warning'});
          return;
        }
      }
      let url = "";
        for(let i in params){
          if(params[i]){
            url+=(i+'='+params[i]+'&')
          }
        }
        let href = this.$constants.baseURL+"/call400Stats/label/export?"+url;
        // let href = 'http://192.168.1.129:9997'+"/call400Stats/label/export?"+url;
        // window.location.href = href;
        this.derivedDis = true;
        this.$common.promiseDownload(href).then(data=>{
          setTimeout(() => {
            this.derivedDis = data;
          }, 4000);
        })
    },
    userIdChange(userId){//选择用户
      this.condition.projectName = "";
      this.condition.loginName = "";
      this.userNameList.forEach(item => {
        if (userId === item.id) {
          this.condition.loginName = item.loginName;
        }
      })
    },
    currentPage_data(index) {//当前页1
      if (this.activeName === "ordinary") {
        this.currentPage = index;
        let params = {
          pageSize:this.pageSize,
          pageNo:this.currentPage,
          fuzzyBatchName:this.condition.batchName,
          projectName:this.condition.projectName,
          userId:this.condition.userId,
          batchNameOrder:this.order["batchName"],
          callCountOrder:this.order["callCount"],
          greaterThan60sRateOrder:this.order["greaterThan60sRate"],
          answerRateOrder:this.order["answerRate"],
          conversionRateOrder:this.order["conversionRate"],
          orderRateOrder:this.order["orderRate"],
        }
        if(this.condition.callEndAtStart){
          params.importDateStart=this.condition.callEndAtStart[0];
          params.importDateEnd=this.condition.callEndAtStart[1];
          this.loadingList1 = true;
          this.inquireList1(params);
        }
      }
      if (this.activeName === "wechat") {
        this.wechatPageNo = index;
        let params = {
          pageSize:this.wechatPageSize,
          pageNo:this.wechatPageNo,
          fuzzyBatchName:this.wecahtCondition.batchName,
          projectName:this.wecahtCondition.projectName,
          userId:this.wecahtCondition.userId,
          batchNameOrder:this.order["batchName"],
          callCountOrder:this.order["callCount"],
          greaterThan60sRateOrder:this.order["greaterThan60sRate"],
          answerRateOrder:this.order["answerRate"],
          conversionRateOrder:this.order["conversionRate"],
          orderRateOrder:this.order["orderRate"],
        }
        if(this.wecahtCondition.callEndAtStart){
          params.importDateStart=this.wecahtCondition.callEndAtStart[0];
          params.importDateEnd=this.wecahtCondition.callEndAtStart[1];
          this.loadingList1 = true
          this.inquireList1(params);
        }
      }
    },
    currentPage_project(index) {//当前页2
      this.projectCurrentPage = index;
      let batchName = '';
      let params = {
        pageSize:this.pageSizePro,
        pageNo:index,
        // fuzzyBatchName:batchName,
        projectName:this.condition.projectName,
        userId:this.condition.userId,
        callCountOrder:this.projectOrder["callCount"],
        greaterThan60sRateOrder:this.projectOrder["greaterThan60sRate"],
        answerRateOrder:this.projectOrder["answerRate"],
        conversionRateOrder:this.projectOrder["conversionRate"],
        orderRateOrder:this.projectOrder["orderRate"],
      }
      if(this.batchName){
        params.batchName = this.batchName
      }else{
        params.fuzzyBatchName = this.condition.batchName
      }
      if(this.condition.callEndAtStart){
        params.importDateStart=this.condition.callEndAtStart[0];
        params.importDateEnd=this.condition.callEndAtStart[1];
        this.loadingList2 = true
        this.inquireList2(params);
      }
    },
    handleSizeChange(val) {//显示条数1
      if (this.activeName === "ordinary") {
        this.pageSize = val;
        let params = {
          pageSize:val,
          pageNo:1,
          fuzzyBatchName:this.condition.batchName,
          projectName:this.condition.projectName,
          userId:this.condition.userId,
        }
        this.currentPage = 1;
        if(this.condition.callEndAtStart){
          params.importDateStart=this.condition.callEndAtStart[0];
          params.importDateEnd=this.condition.callEndAtStart[1];
          this.loadingList1 = true
          this.inquireList1(params);
        }
      }
      if (this.activeName === "wechat") {
        this.wechatPageSize = val;
        let params = {
          pageSize: val,
          pageNo: 1,
          fuzzyBatchName: this.wecahtCondition.batchName,
          projectName: this.wecahtCondition.projectName,
          userId: this.wecahtCondition.userId,
        }
        this.wechatPageNo = 1;
        if(this.wecahtCondition.callEndAtStart){
          params.importDateStart=this.wecahtCondition.callEndAtStart[0];
          params.importDateEnd=this.wecahtCondition.callEndAtStart[1];
          this.loadingList1 = true
          this.inquireList1(params);
        }
      }
    },
    projectSizeChange(val) {//显示条数2
      this.pageSizePro = val;
      let params = {
        pageSize:val,
        pageNo:1,
        // fuzzyBatchName:this.condition.batchName,
        projectName:this.condition.projectName,
        userId:this.condition.userId,
      }
      this.projectCurrentPage = 1;
      if(this.batchName){
        params.batchName = this.batchName;
      }else{
        params.fuzzyBatchName = this.condition.batchName;
      }
      if(this.condition.callEndAtStart){
        params.importDateStart=this.condition.callEndAtStart[0];
        params.importDateEnd=this.condition.callEndAtStart[1];
        this.loadingList2 = true
        this.inquireList2(params);
      }
    },
    inquireUserList(params){//查询公司名称
      this.$http('/user/list', null, 'get', null, params).then(res => {
        if(res.result == 200){
          let list = res.data.list;
          this.userNameList = list;
        }
      })
    },
    inquireProject(params){//查询项目名称
      this.projectNameList = [];
      this.$http('/sip/project/list',null,'get',null,params).then(res => {
        let list = res.data.list;
        let obj = {}
        list.map(item=>{
          obj = {
            id: item.id,
            projectName: item.projectName,
            projectNameLabel: item.projectName
          }
          this.projectNameList.push(obj);
        })
      })
    },
  }
}
</script>
<style scoped="scoped">
.analysisReport >>> .el-tabs__content {
  overflow: auto;
}
.el-table{
  margin-bottom:20px;
}
.displayBlock{
  clear: both;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.callTime >>> .el-input__inner{
  width: 430px;
}
.callTime,.batchName {
  margin-right: 6px;
}
.callTime,.batchName{
  float: right;
  text-align: left;
}
.batchName .el-input{
  width: 230px;
}
.operate{
  margin-bottom: 20px;
}
</style>
<style>
.tableDataClass .current-row > td {
   background: rgba(0, 158, 250, 0.219) !important;
}
</style>