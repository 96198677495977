<template>
  <div class="speechcraft">
    <div class="nav">
      <div class="batchName">
        话术名称：
        <el-input v-model="speechName" placeholder="请输入话术名称" clearable></el-input>
      </div>
    </div>
    <div>
      <el-button type="primary" @click="query(1)">查询</el-button>
      <el-button @click="flowClick($event, null, 'add')">新建话术</el-button>
      <el-button @click="defaultClick">默认设置</el-button>
    </div>
    <div class="speechCard-cc-mm">
      <el-row :gutter="12">
        <el-col :span="8" v-for="item in speechList" :key="item.id">
          <div 
            class="node-speech-item"  
            @click="itemClick(item)"
            @mouseover="mouseoverFlow(item)"
            @mouseout="activeFlow = ''"
          >
            <div>
              <div class="node-speech-item-title">{{ item.name }}</div>
              <div class="node-speech-item-ss"> 
                <div>
                  最后更新时间：{{ item.gmtModified }}
                </div>
                <div>
                  声优名称：{{ item.aiVoiceName }}
                </div>
                <div>
                  话术ID：{{ item.id }}
                </div>
              </div>
            </div>
            <div class="node-speech-item-ss">
              <div class="node-speech-item-ss-rr" :class="{'node-speech-item-ss-rr-success': item.status === 'PASSED'}"></div>
              <span v-for="i in statusList" :key="i.value">
                <span v-if="item.status === i.value">{{ i.label }}</span>
              </span>
            </div>
            <div v-show="activeFlow === item.id" class="node-flow-chart-item-cz">
              <i class="el-icon-delete icon-cc-mm" style="margin-right: 5px;" @click="deleteFlow($event, item)"></i>
              <i class="el-icon-document-copy icon-cc-mm" style="margin-right: 5px;" @click="copyFlow($event, item)"></i>
              <i class="el-icon-edit icon-cc-mm" style="margin-right: 5px;" @click="flowClick($event, item, 'modify')"></i>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="node-speech-pagination">
      <div>共{{total}}套</div>
      <a-pagination simple :defaultPageSize="6" v-model="pageNo" :total="total" @change="pageNoChange"/>
    </div>
    <!-- 新增话术 -->
    <el-dialog 
      :title="flowTitle" 
      :visible.sync="flowShow" 
      width="500px" 
      @close="flowClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="话术名称:" required>
          <el-input 
            v-model="flowName" 
            placeholder="请输入话术名称" 
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="flow_submit" :loading="loading">确定</el-button>
        <el-button @click="flowShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "speechcraft",
  data() {
    return {
      loading: false,
      statusList: [
        {
          value: "EDITING",
          label: "编辑中"
        },
        {
          value: "PASSED",
          label: "已通过审核"
        },
      ],
      speechList: [],
      speechName: "",
      pageNo: 1,
      total: 0,
      activeFlow: "",
      flowTitle: "",
      flowName: "",
      flowShow: false,
      activeId: ""
    }
  },
  mounted() {
    document.title = "惠销平台管理系统-我的话术";
    this.query(1);
  },
  methods: {
    // 点击查询话术列表
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 6,
        name: this.speechName
      }
      this.getDialogueList(data);
    },
    // 查询话术列表
    getDialogueList(data) {
      this.$http("/dialogue/list",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let list = res.data.records;
          this.speechList = list;
          this.total = res.data.total;
        }
      })
    },
    // 点击话术
    itemClick(item) {
      this.activeId = item.id;
      this.$router.push({ path:'/speechFlow', query:{ dialogueId: item.id, name: item.name } });
    },
    // 点击默认设置
    defaultClick() {
      this.$router.push({ path:'/speechFlow/knowledgeBase', query:{ dialogueId: -1, name: "默认设置" } });
    },
    // 翻页
    pageNoChange(index) {
      this.query(index);
    },
    // 鼠标移入
    mouseoverFlow(item) {
      this.activeFlow = item.id;
    },
    // 点击删除
    deleteFlow(e, item) {
      e.stopPropagation();
      this.$confirm('此操作将删除【'+item.name+'】流程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: item.id
        }
        this.$service("/dialogue/deleteDialogue",data).then(res => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        })
      }).catch(() => {
               
      });
    },
    // 点击复制
    copyFlow(e, item) {
      e.stopPropagation();
      this.activeId = item.id;
      this.$confirm("确定复制【"+item.name+"】话术吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: this.activeId
          }
          this.$service("/dialogue/copyDialogue",data).then(res => {
            if (res.result === 200) {
              this.$message.success("复制成功");
              this.query(1);
            }
          })
        })
        .catch(() => {});
    },
    // 点击编辑
    flowClick(e, item, key) {
      e.stopPropagation();
      this.flowShow = true;
      if (key === "add") {
        this.flowTitle = "新建话术";
      }
      if (key === "modify") {
        this.flowTitle = "编辑话术";
        this.activeId = item.id;
        this.flowName = item.name;
      }
    },
    // 确定编辑
    flow_submit() {
      let data = {
        name: this.flowName
      }
      if (!this.flowName) {
        this.$message.warning("请输入话术名称");
        return
      }
      this.loading = true;
      if (this.flowTitle === "新建话术") {
        this.$service("/dialogue/addDialogue",data).then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.query(1);
            this.flowShow = false;
          }
        }).catch(err => {
          this.loading = false;
        })
      }
      if (this.flowTitle === "编辑话术") {
        data.id = this.activeId;
        this.$service("/dialogue/updateDialogue",data).then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("编辑成功");
            this.query();
            this.flowShow = false;
          }
        }).catch(err => {
          this.loading = false;
        })
      }
    },
    // 关闭编辑
    flowClose() {
      this.flowName = "";
    },
  }
}
</script>
<style scoped>
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
</style>