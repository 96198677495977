<template>
  <div class="userBlackList">
    <div class="nav">
      <div class="customerNumber">
        客户编号：
        <el-input
          v-model="customerNumber"
          placeholder="请输入客户编号"
          clearable
        ></el-input>
      </div>
      <div class="customerNumber">
        表名
        <el-tooltip class="item" effect="light" placement="top-start">
          <div slot="content">
            <div>
              黑名单数据按照customerCode最后一位分成了16张表，分页查询需要指定表名
            </div>
          </div>
          <i class="el-icon-question icon-color"></i> </el-tooltip
        >：
        <el-select
          v-model="tableNum"
          placeholder="请选择项表名"
          :disabled="tableNumDisabled"
        >
          <el-option
            v-for="item in tableNumList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="customerNumber">
        类型
        <el-select
          v-model="sourceList"
          placeholder="请选择黑名单类型"
          multiple
          clearable
        >
          <el-option
            v-for="item in blackListSourceList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button type="primary" @click="addCustomerNumber">单个新增</el-button>
    <el-button type="primary" @click="uploadFiles">批量导入</el-button>
    <!-- 黑名单表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerCode"
        label="客户编号"
        width="170"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="province"
        label="客户省份"
        width="170"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="city"
        label="客户城市"
        width="170"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="operator"
        label="运营商"
        width="170"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="170"
        align="center"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="70"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="deleteCustomer(scope.row)"
            style="color: #ff0000b0"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 单个新增 -->
    <el-dialog
      title="单个新增"
      :visible.sync="addCustomerShow"
      custom-class="el-addCustomer"
      width="400px"
      @close="addCustomerClose"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">单个新增</span>
      </template>
      <el-form :model="form" :label-position="labelLeft">
        <el-form-item label="客户编号：" label-width="100px" required>
          <el-input
            v-model="form.customerNumber"
            placeholder="请输入客户编号或手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型：" label-width="100px" required>
          <el-select
            v-model="sourceListAdd"
            placeholder="请选择黑名单类型"
            multiple
            clearable
          >
            <el-option
              v-for="item in blackListSourceList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="button-center">
        <el-button type="primary" @click="addCustomer_submit()"
          >提 交</el-button
        >
        <el-button @click="addCustomer_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 上传文件 -->
    <el-dialog
      title="批量导入"
      :visible.sync="uploadFilesShow"
      custom-class="el-uploadFiles"
      width="550px"
      @close="uploadFilesClose"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">批量导入</span>
      </template>
      <el-form :label-position="labelLeft">
        <el-form-item label="类型：" label-width="90px" required>
          <el-select
            v-model="sourceListAdd"
            placeholder="请选择黑名单类型"
            multiple
            clearable
          >
            <el-option
              v-for="item in blackListSourceList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传文件：" label-width="90px">
          <el-upload
            class="upload-demo"
            drag
            :data="uploadData"
            :action="url"
            :on-success="successUpload"
            :on-error="errorUpload"
            ref="upload"
            :headers="sourceUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
          <!-- <div class="textPrompt">*只能上传手机号码</div> -->
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="upload_submit()">提 交</el-button>
          <el-button @click="upload_cancel">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "userBlackList",
  data() {
    return {
      blackListSourceList: [],
      sourceList: [],
      sourceListAdd: [],
      tableNumList: [
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "a",
          value: "a",
        },
        {
          label: "b",
          value: "b",
        },
        {
          label: "c",
          value: "c",
        },
        {
          label: "d",
          value: "d",
        },
        {
          label: "e",
          value: "e",
        },
        {
          label: "f",
          value: "f",
        },
      ],
      tableNum: "0",
      loading: false,
      labelLeft: "left",
      customerNumber: "",
      tableData: [],
      currentPage: 1,
      total: 0,
      addCustomerShow: false,
      uploadFilesShow: false,
      form: {
        customerNumber: "",
      },
      uploadData: {},
      url: this.$constants.baseURL + "/blacklist/addBatch",
      sourceUpload: {},
      fileList: [],
      cookie: true,
      pageSize: 10,
    };
  },
  computed: {
    tableNumDisabled() {
      if (this.customerNumber) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    document.title = "惠销平台管理系统-黑名单管理";
    this.inquireBlacklistFindList();
  },
  methods: {
    inquireBlacklistFindList() {
      //查询黑名单列表
      this.blackListSourceList = [];
      this.$http("/blacklist/findList", null, "get", null).then((res) => {
        if (res.result == 200) {
          let list = res.data;
          list.forEach((item) => {
            this.blackListSourceList.push({
              value: item,
              label: item,
            });
            if (item == "教育") {
              this.sourceList.push(item);
            }
          });
          this.inquire(1);
        }
      });
    },
    inquire(index) {
      //查询表格数据
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        customerCode: this.customerNumber,
        tableNum: this.tableNum,
        source: this.sourceList.join(","),
      };
      if (!params.source) {
        this.$message.warning("请选择类型");
        return;
      }
      this.inquireList(params);
    },
    inquireList(params) {
      //查询黑名单列表
      this.loading = true;
      this.$http("/blacklist/list", null, "get", null, params).then((res) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.records;
          this.total = res.data.total;
          list.map((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          });
          this.tableData = list;
        }
      });
    },
    currentPage_data(index) {
      //当前页
      this.inquire(index);
    },
    sizeChange(index) {
      //显示条数--分页
      this.pageSize = index;
      this.inquire();
    },
    addCustomerNumber() {
      //点击新增
      this.addCustomerShow = true;
    },
    uploadFiles() {
      //点击上传文件
      this.uploadFilesShow = true;
    },
    deleteCustomer(val) {
      //点击删除
      this.$confirm("删除【" + val.customerCode + "】客户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let data = {
            customerCode: val.customerCode,
          };
          this.$http("/blacklist/delete", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.inquire();
            }
          });
        })
        .catch(() => {});
    },
    addCustomerClose() {
      //关闭新增表单
      this.form.customerNumber = "";
      this.sourceListAdd = [];
    },
    addCustomer_submit() {
      if (this.sourceListAdd.length == 0) {
        this.$message({ message: "信息不完整", type: "warning" });
        return;
      }
      //新增表单提交
      let data = {
        customerCode: this.form.customerNumber,
        sources: this.sourceListAdd.join(","),
      };
      if (!data.customerCode) {
        this.$message({ message: "信息不完整", type: "warning" });
        return;
      }
      this.$http("/blacklist/add", data).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "新增成功", type: "success" });
          this.addCustomer_cancel();
          this.inquire(1);
        }
      });
    },
    addCustomer_cancel() {
      //新增表单取消
      this.addCustomerShow = false;
    },
    errorUpload(res) {
      this.$message({
        message: JSON.parse(res.message).message,
        type: "error",
      });
    },
    successUpload(res, file, fileList) {
      //文件上传成功
      this.$refs.upload.clearFiles();
      if (res.result == 200) {
        this.inquire(1);
        this.upload_cancel();
        this.$message({
          message: "有效导入数 " + res.data.importCount + " 个",
          type: "success",
        });
      }
    },
    handleExceed(files, fileList) {
      //限制上传文件数量提示
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    upload_submit() {
      //点击提交上传文件
      if (this.sourceListAdd.length == 0) {
        this.$message({ message: "信息不完整", type: "warning" });
        return;
      }
      this.uploadData.sources = this.sourceListAdd.join(",");
      this.$nextTick(() => {
        this.$refs.upload.submit();
      });
    },
    upload_cancel() {
      //点击取消上传文件
      this.uploadFilesShow = false;
      this.sourceListAdd = [];
    },
    uploadFilesClose() {
      //关闭上传文件表单
      this.$refs.upload.clearFiles();
    },
  },
};
</script>
<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>
<style scoped>
.textPrompt {
  /* font-size: 12px; */
  color: #f00;
  /* display: inline-block; */
}
.nav {
  margin-bottom: 20px;
}
.nav .customerNumber {
  width: 350px;
  display: inline-block;
}
.customerNumber .el-input,
.customerNumber .el-select {
  width: 250px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.el-addCustomer .el-input {
  width: 220px;
}
</style>
