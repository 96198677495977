<template>
  <div class="longUrlBind">
    <el-button type="primary" @click="inquire()">查询</el-button>
    <el-button @click="addLongUrl()">新增</el-button>
    <el-table
      :data="longUrlTableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="groupName"
        label="分组名"
        width="200"
        show-overflow-tooltip
        align="left"
      ></el-table-column>
      <el-table-column
        prop="longUrl"
        label="专属长链"
        min-width="200"
        show-overflow-tooltip
        align="left"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="100"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modifyLongUrl(scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增坐席长链 -->
    <el-dialog
      :title="addClinetNumberTitle"
      :visible.sync="addClinetNumberShow"
      custom-class="el-addBindshow"
      width="500px"
      @close="addClinetNumberClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{addClinetNumberTitle}}</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="选择分组：" v-if="addClinetNumberTitle === '新增'" required>
          <el-select 
            v-model="groupId" 
            placeholder="请选择队列名称" 
            clearable
          >
            <el-option
            v-for="item in groupIdList"
            :key="item.id"
            :value="item.id"
            :label="item.groupName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专属长链：" required>
          <el-input v-model="longUrl" placeholder="请输入专属长链"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addClinetNumber_submit()"
            >确认</el-button
          >
          <el-button @click="addClinetNumberShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "longUrlBind",
  data() {
    return {
      longUrlTableData: [],
      loadingTable: false,
      addClinetNumberShow: false,
      longUrl: "",
      clientNumber: "",
      groupId: "",
      groupIdList: [],
      addClinetNumberTitle: ""
    };
  },
  mounted() {
    this.inquire();
  },
  methods: {
    // 点击新增
    addLongUrl() {
      this.addClinetNumberTitle = "新增";
      this.addClinetNumberShow = true;
      let data = {
        pageNo: 1,
        pageSize: 100,
      };
      this.$http("/longUrlGroup/groupList", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.groupIdList = list;
        }
      })
    },
    // 点击修改
    modifyLongUrl(val) {
      this.addClinetNumberTitle = "【"+val.groupName+"】修改"
      this.groupId = val.groupId;
      this.longUrl = val.longUrl;
      this.clientNumber = val.clientNumber;
      this.addClinetNumberShow = true;
    },
    // 关闭修改
    addClinetNumberClose() {
      this.longUrl = "";
      this.groupId = "";
    },
    // 点击确定
    addClinetNumber_submit(val) {
      let data = {
        longUrl: this.longUrl,
        groupId: this.groupId,
        clientNumber: this.clientNumber,
      };
      if (!data.groupId) {
        this.$message.warning("请选择分组");
        return;
      }
      if (!data.longUrl) {
        this.$message.warning("请输入专属长链");
        return;
      }
      this.$service("/longUrlGroup/updateGroupItem", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.addClinetNumberShow = false;
          this.inquire();
        }
      });
    },
    // 点击查询
    inquire() {
      this.getGroupItemList();
    },
    // 查询坐席长链
    getGroupItemList() {
      this.loadingTable = true;
      this.$http("/longUrlGroup/groupItemList", null, "get")
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data;
            this.longUrlTableData = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.el-addBindshow .el-input,
.el-addBindshow .el-select {
  width: 80%;
}
</style>