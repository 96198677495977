<template>
  <div class="liquor">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="订单列表" name="order">
        <liquorOrder v-if="liquorOrderShow" :roleMenu="roleMenu"></liquorOrder>
      </el-tab-pane>
      <el-tab-pane label="用户列表" name="user">
        <liquorUser v-if="liquorUserShow" :roleMenu="roleMenu"></liquorUser>
      </el-tab-pane>
      <el-tab-pane label="产品列表" name="product">
        <liquorProduct
          v-if="liquorProductShow"
          :roleMenu="roleMenu"
        ></liquorProduct>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import liquorOrder from "./liquorOrder.vue";
import liquorUser from "./liquorUser.vue";
import liquorProduct from "./liquorProduct.vue";
export default {
  name: "liquor",
  components: {
    liquorOrder,
    liquorUser,
    liquorProduct,
  },
  props: ["roleMenu"],
  data() {
    return {
      activeName: "order",
      liquorOrderShow: false,
      liquorUserShow: false,
      liquorProductShow: false,
    };
  },
  mounted() {
    this.liquorOrderShow = true;
  },
  methods: {
    // 选择tab
    handleClick(tab) {
      if (this.activeName === "order") {
        this.liquorOrderShow = true;
        this.liquorUserShow = false;
        this.liquorProductShow = false;
      }
      if (this.activeName === "user") {
        this.liquorOrderShow = false;
        this.liquorUserShow = true;
        this.liquorProductShow = false;
      }
      if (this.activeName === "product") {
        this.liquorOrderShow = false;
        this.liquorUserShow = false;
        this.liquorProductShow = true;
      }
    },
  },
};
</script>
<style scoped>
</style>
