<template>
  <div class="nodeFlowChart">
    <div v-for="item in flowChartList" :key="item.id">
      <div 
        class="node-flow-chart-item"  
        :class="{'ef-node-active': activeElement.id === item.id}"
        @click="itemClick(item)"
        @mouseover="mouseoverFlow(item)"
        @mouseout="activeFlow = ''"
      >
        <div>{{ item.name }}</div>
        <div v-show="activeFlow === item.id" class="node-flow-chart-item-cz">
          <i 
            class="el-icon-edit icon-cc-mm" 
            style="margin-right: 5px;" 
            @click="flowClick($event, item, 'modify')">
          </i>
          <i 
            class="el-icon-delete icon-cc-mm" 
            style="margin-right: 5px;" 
            @click="deleteFlow($event, item)" 
            v-if="!item.starter">
          </i>
        </div>
      </div>
    </div>
    <div class="node-flow-chart-btn" @click="flowClick($event, null, 'add')">
      <div>
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <!-- 新增流程 -->
    <el-dialog 
      :title="flowTitle" 
      :visible.sync="flowShow" 
      width="500px" 
      @close="flowClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="流程名称:" required>
          <el-input 
            v-model="flowNmae" 
            placeholder="请输入流程名称" 
            maxlength="12"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="flow_submit" :loading="loading">确定</el-button>
        <el-button @click="flowShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "nodeFlowChart",
  props: {
    activeElement: Object,
    activeSpeechId: [String, Number],
  },
  data() {
    return {
      loading: false,
      flowChartList: [],
      activeFlow: "",
      flowTitle: "",
      flowShow: false,
      flowNmae: "",
      activeId: ""
    }
  },
  mounted() {
    this.getDialogueScenarioList();
  },
  methods: {
    // 查询流程列表
    getDialogueScenarioList(sign) {
      let data = {
        dialogueId: this.activeSpeechId,
        type: "MASTER"
      }
      this.$http("/dialogueScenario/dialogueScenarioList",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let list = res.data;
          this.flowChartList = list;
          if (this.flowChartList.length > 0) {
            if (sign === "add") {
              this.itemClick(this.flowChartList[this.flowChartList.length - 1]);
            }
            if (!sign || sign === "delete") {
              this.itemClick(this.flowChartList[0]);
            }
            this.$emit("submit", this.flowChartList);
          }
        }
      })
    },
    // 点击流程
    itemClick(item) {
      this.activeId = item.id;
      this.$emit("flowChartClick", item);
    },
    // 鼠标移入
    mouseoverFlow(item) {
      this.activeFlow = item.id;
    },
    // 点击删除
    deleteFlow(e, item) {
      e.stopPropagation();
      this.$confirm('此操作将删除【'+item.name+'】流程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: item.id
        }
        this.$service("/dialogueScenario/deleteDialogueScenario",data).then(res => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.getDialogueScenarioList("delete");
          }
        })
      }).catch(() => {
               
      });
    },
    // 点击编辑
    flowClick(e, item, key) {
      e.stopPropagation();
      this.flowShow = true;
      if (key === "add") {
        this.flowTitle = "增加节点流程";
      }
      if (key === "modify") {
        this.flowTitle = "编辑节点流程";
        this.activeId = item.id;
        this.flowNmae = item.name;
      }
    },
    // 确定编辑
    flow_submit() {
      let data = {
        dialogueId: this.activeSpeechId,
        name: this.flowNmae
      }
      if (!this.flowNmae) {
        this.$message.warning("请输入流程名称");
        return
      }
      this.loading = true;
      if (this.flowTitle === "增加节点流程") {
        data.type = "MASTER";
        this.$service("/dialogueScenario/addDialogueScenario",data).then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.getDialogueScenarioList("add");
            this.flowShow = false;
          }
        }).catch(err => {
          this.loading = false;
        })
      }
      if (this.flowTitle === "编辑节点流程") {
        data.id = this.activeId;
        this.$service("/dialogueScenario/updateDialogueScenarioName",data).then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("编辑成功");
            this.getDialogueScenarioList("modify");
            this.flowShow = false;
          }
        }).catch(err => {
          this.loading = false;
        })
      }
    },
    // 关闭编辑
    flowClose() {
      this.flowNmae = "";
    },
  },
}
</script>