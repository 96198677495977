<template>
	<div class="machineCallingRecord">
		<div class="nav">
			<div class="task">
				任务名称：
				<el-input v-model="taskName" placeholder="请输入内容"></el-input>			
			</div>
			<div class="status">
				执行状态：
				<el-select v-model="statusId" placeholder="请选择">
					<el-option v-for="item in statusList" :key="item.statusCode" :label="item.status" :value="item.statusCode"></el-option>
				</el-select>
			</div>
			<el-button type="primary" class='inquire' @click='inquire()'>查询</el-button> 			
		</div>
		
		<!-- 数据展示 -->
		<el-table stripe ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border align='center'  >
			<el-table-column type='index' prop="" label="序号" width="60" align='center'></el-table-column>
			<el-table-column prop="taskName" label="任务名称" width="" align=''></el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="170" align='center'> </el-table-column>
			<el-table-column prop="costBalance" label="费用" width="100" align='center'> </el-table-column>
			<el-table-column prop="callCount" label="外呼数" width="120" align='center'></el-table-column>
			<el-table-column prop='connectCount' label="接通数" width="120" align='center'></el-table-column>
			<el-table-column prop="progress" label="完成率" width="120" align='center'></el-table-column>
			<el-table-column prop="taskStatus.status" label="执行状态" width="140" align='center'></el-table-column>
			<el-table-column prop="" label="操作" width="270" align='center'>
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="pause(scope.row,scope.$index)" :disabled='scope.row.judgeCode' >暂停</el-button>
					<el-button type="text" size="small" @click="pause(scope.row,scope.$index)"  :disabled='scope.row.judgeCode'>停止</el-button>
					<el-button type="text" size="small" @click="start(scope.row,scope.$index)" :disabled='scope.row.judgeCode22'>启动</el-button>
					<el-button type="text" size="small" @click="detail(scope.row,scope.$index)" >明细</el-button>
					<el-button type="text" size="small" @click="export_file(scope.row)" :disabled='scope.row.judgeCode33'>导出</el-button>					
			</template>
			</el-table-column>		   
		</el-table>
		
		<!-- 分页 -->
		<el-pagination @current-change="currentPage_data" :current-page.sync="currentPage" :page-size= '10' layout="prev, pager, next,jumper" :total="total" :pager-count='5'></el-pagination>
		
		<!-- 明细 -->
		<el-dialog  :visible.sync="showDetail" custom-class='el-detail' width = "600px"   >
			<div slot="title" class="dialog-title" >
				<h3>查看详情</h3>
			</div>
			<el-table :data ='detailList'  stripe style="width:100%"  border max-height=400 size='mini' :show-header='true' :fit='false'>	    			
				<el-table-column  label="客户意向等级" align='center'>
					<el-table-column prop="type" label="类别" width="" align='center'></el-table-column>
					<el-table-column prop="percent" label="百分比" width="" align='center'></el-table-column>
				</el-table-column>
				<el-table-column  label="通话时长" width="" align='center'>
					<el-table-column prop="time" label="时长" width="119" align='center'></el-table-column>
					<el-table-column prop="percent2" label="百分比" width="" align='center'></el-table-column>
				</el-table-column>	
				<el-table-column  label="对话轮次" width="" align='center'> 
					<el-table-column prop="num" label="次数" width="120" align='center'></el-table-column>
					<el-table-column prop="percent3" label="百分比" width="" align='center'></el-table-column>
				</el-table-column>			
			</el-table>		
		</el-dialog>
		
		
		
	</div>	
</template>

<script>
	export default{
		name:'MachineCallingRecord',
		data(){
			return{
				msg:'机器外呼记录',
				taskName:'',
				statusList:[{statusCode:0 ,status:'无号码'},{statusCode:1 ,status:'未营销'},{statusCode:2 ,status:'营销中'},{statusCode:3 ,status:'已完成'},{statusCode:4 ,status:'手动暂停'},{statusCode:5 ,status:'自动暂停'},{statusCode:6 ,status:'已终止'},{statusCode:7 ,status:'AI到期'},{statusCode:8 ,status:'未分析'},{statusCode:9 ,status:'分析中'}],
				statusId:'',
				tableData:[],
				currentPage:1,
				total:null,
				showDetail:false,			
				detailList: []		
			}
		},
		mounted(){
			this.inquire(1);
		},
		methods:{
			pause(val,index){//暂停、暂停操作
				let taskId = val.id,
					status = val.taskStatus.statusCode;
				if(status == 2){
					this.operate(taskId,status,index);
				}				
			},
			start(val,index){//启动
				let taskId = val.id,
					status = val.taskStatus.statusCode;
				if(status == 1|| status == 4|| status == 5){
					this.operate(taskId,status,index);
				}	
			},
			detail(val){//明细
				let data = {
					taskId:	val.id						
				};
				this.$http('/aida/phoneCallTaskDetail',data).then(res=>{														
					if(res.result == 200){
						let list = res.data;
							list.willingARate += '%';
							list.willingBRate += '%';
							list.willingCRate += '%';
							list.willingDRate += '%';
							list.willingERate += '%';
							list.willingFRate += '%';						
							list.callLengthLt10Rate += '%';
							list.callLength10to60Rate += '%';
							list.callLength60to120Rate += '%';
							list.callLengthGt120Rate += '%';
							list.round0to9Rate += '%';
							list.round10to19Rate += '%';
							list.round20to29Rate += '%';
							list.round30to39Rate += '%';
							list.roundGt40Rate += '%';
						this.showDetail = true;	
						this.detailList = [{type:'A类',percent:list.willingARate,time:'<10s',percent2:list.callLengthLt10Rate,num:'0-9次',percent3:list.round0to9Rate},{type:'B类',percent:list.willingBRate,time:'10s-1min',percent2:list.callLength10to60Rate,num:'10-19次',percent3:list.round10to19Rate},{type:'C类',percent:list.willingCRate,time:'1min-2min',percent2:list.callLength60to120Rate,num:'20-29次',percent3:list.round20to29Rate},{type:'D类',percent:list.willingDRate,time:'>2min',percent2:list.callLengthGt120Rate,num:'30-39次',percent3:list.round30to39Rate},{type:'E类',percent:list.willingERate,time:'',percent2:'',num:'40次以上',percent3:list.roundGt40Rate},{type:'F类',percent:list.willingFRate,time:'',percent2:'',num:'',percent3:''}];
					}
				});				
			},
			export_file(val){//导出文件			
				let href = this.$constants.baseURL+'/aida/exportPhoneCallTaskDetail?taskId='+ val.id;
				window.location.href = href;
			},
			currentPage_data(index){//当前页
				this.inquire(index);
			},
			inquire(index){//查询数据
				let data = {
					pageNo:index||this.currentPage,
					pageSize:10,						
					taskName:this.taskName,
					taskStatus:this.statusId				
				};
				this.$http('/aida/phoneCallTaskList',data).then(res=>{					
					if(res.result == 200){
						let list = res.data.list;
						this.total = res.data.count;
						list.map(item=>{
							if(item.callCount == -1){
								item.callCount = '未知';
							}
							if(item.connectCount == -1){
								item.connectCount = '未知';
							}
							item.costBalance = item.costBalance /100+ '元';
							item.createTime = this.$common.transformTime(item.createTime);
							if(item.taskStatus == 2){//暂停、停止
								item.judgeCode = false;
							}else {
								item.judgeCode = true;
							}
							if(item.taskStatus == 1|| item.taskStatus == 4|| item.taskStatus ==5){//启动
								item.judgeCode22 = false;
							}else {
								item.judgeCode22 = true;
							}
							if(item.taskStatus == -1){//禁用
								item.judgeCode22 = true;
								item.judgeCode = true;
							}else {
								item.judgeCode22 = true;
							}											
							if(item.progress == 100){//导出
								item.judgeCode33 = false;
							}else {
								item.judgeCode33 = true;
							}
							item.progress += '%' ;
							switch (item.taskStatus){
								case -1: item.taskStatus = {statusCode:-1 ,status:'未知'};
									break;
								case 0: item.taskStatus = {statusCode:0 ,status:'无号码'};
									break;
								case 1: item.taskStatus = {statusCode:1 ,status:'未营销'};
									break;
								case 2: item.taskStatus = {statusCode:2 ,status:'营销中'};
									break;
								case 3: item.taskStatus = {statusCode:3 ,status:'已完成'};
									break;
								case 4: item.taskStatus = {statusCode:4 ,status:'手动暂停'};
									break;
								case 5:item.taskStatus = {statusCode:5 ,status:'自动暂停'};
									break;
								case 6: item.taskStatus = {statusCode:6 ,status:'已终止'};
									break;
								case 7: item.taskStatus = {statusCode:7 ,status:'AI到期'};
									break;
								case 8: item.taskStatus = {statusCode:8 ,status:'未分析'};
									break;
								case 9: item.taskStatus = {statusCode:9 ,status:'分析中'};
									break;
							}					
						});
																						
						this.tableData = list;		  
					}		
				});
			},
			operate(taskId,status,index){//操作
				let data = {
						pageNo:this.currentPage,
						pageSize:10,						
						taskId:taskId,
						modifiedTaskStatus:status				
				};
				this.$http('/aida/modifyPhoneCallTaskStatus',data).then(res=>{														
					if(res.result == 200){
						if(status == 2){
							this.tableData[index].taskStatus = {statusCode:4 ,status:'手动暂停'};
						}else if(status == 1 || status == 4 || status == 5){
							this.tableData[index].taskStatus = {statusCode:2 ,status:'营销中'};
						}										
					}	
				});
			}
			
		}
	}
</script>


<style scoped="scoped">
	.nav {
		width: 1100px;
	}
	.task{
		display:inline-block ;
		width: 500px;
	}
	.task .el-input{
		width: 360px; 
	}
	.status {
		display:inline-block ;
		width: 360px;
	}
	.inquire {
		width: 120px;
	}
	.el-table{
		margin: 30px 0;
	}
	.el-pagination {
		float: right;
		text-align: right;
	}
	.el-detail .el-table {
		margin:-20px 0  0px 0;
	}
	
</style>

