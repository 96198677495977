<template>
  <div class="bannedLine">
    <div id="myChart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
require("echarts/lib/chart/bar");

export default {
  data() {
    return {};
  },
  mounted() {
    var firstDate = new Date();
    var startDate =
      firstDate.getFullYear() +
      "-" +
      (firstDate.getMonth() + 1 < 10 ? "0" : "") +
      firstDate.getMonth() +
      "-" +
      (firstDate.getDate() < 10 ? "0" : "") +
      firstDate.getDate() +
      " " +
      "00:00:00";

    var date = new Date();
    var currentMonth = date.getMonth();
    var nextMonth = ++currentMonth;
    var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    var oneDay = 1000 * 60 * 60 * 24;
    // var lastDate = new Date(nextMonthFirstDay - oneDay);
    var lastDate = new Date();
    var endDate =
      lastDate.getFullYear() +
      "-" +
      (lastDate.getMonth() + 1 < 10 ? "0" : "") +
      (lastDate.getMonth() + 1) +
      "-" +
      (lastDate.getDate() < 10 ? "0" : "") +
      lastDate.getDate() +
      " " +
      "23:59:59";
    // console.log(startDate, endDate);
    this.gmtCreatedStart = startDate;
    this.gmtCreatedEnd = endDate;
    this.inquire();
  },
  methods: {
    // 查询
    inquire() {
      let params = {
        start: this.gmtCreatedStart,
        end: this.gmtCreatedEnd,
      };

      this.recordInquire(params);
    },
    // 当天本月折线图
    recordInquire(params) {
      // this.loadingList = true;
      this.$http(
        "/exceptionWechatClientReport/monthCount",
        null,
        "get",
        null,
        params
      ).then((res) => {
        // setTimeout(() => {
        //   this.loadingList = false;
        // }, 200);
        if (res.result == 200) {
          // console.log(res.data);
          // 基于准备好的dom，初始化echarts实例
          let myChart = echarts.init(document.getElementById("myChart"));
          // 绘制图表
          myChart.setOption({
            title: {
              text: "月封号率",
            },
            tooltip: {
              trigger: "axis",
              formatter: function (data) {
                let res = data[0].name + "<br/>",
                  val;
                data.map((item) => {
                  val = item.value + "%";
                  res += item.seriesName + ": " + val + "<br/>";
                });
                return res;
              },
            },
            legend: {
              data: ["封号率"],
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            toolbox: {
              feature: {
                saveAsImage: {},
              },
            },
            xAxis: {
              name: "日期",
              type: "category",
              // boundaryGap: false,
              axisLabel: {
                interval: 0, //横轴信息全部显示
                rotate: 30, //30度角倾斜显示
              },
              axisTick: {
                alignWithLabel: true,
              },
              data: res.data.date.map(function (item, idnex) {
                return item.substring(5);
              }),
            },
            yAxis: {
              name: "百分比",
              type: "value",
              axisLabel: {
                show: true,
                interval: "auto",
                formatter: "{value} %",
              },
              show: true,
            },
            series: [
              {
                name: "封号率",
                type: "line",
                stack: "总量",
                data: res.data.count,
                // data: res.data.count.map(function (item, idnex) {
                //   return item * 100;
                // }),
              },
            ],
          });
        }
      });
    },
  },
};
</script>

<style  scoped>
.bannedLine {
  width: 100%;
  height: 70vh;
}
#myChart {
  width: 100%;
  height: 100%;
}
</style>

