<template>
  <div class="wechatMarkRecord">
    <div class="nav">
      <div class="batchName">
        任务名称：
        <el-input
          v-model="condition.taskName"
          placeholder="请输入任务名称"
        ></el-input>
      </div>
      <div class="batchName">
        提交人：
        <i style="margin-left: 17px"></i>
        <el-input
          v-model="condition.loginName"
          placeholder="请输入提交人"
        ></el-input>
      </div>
    </div>
    <div class="nav">
      <div class="batchName">
        状态：
        <i style="margin-left: 33px"></i>
        <el-select
          v-model="condition.status"
          clearable
          placeholder="请选择状态"
          @change="statusChange"
        >
          <el-option
            v-for="item in wechatStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="callTime">
        调用时间：
        <i style="margin-left: 16px"></i>
        <el-date-picker
          v-model="condition.gmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
        ></el-date-picker>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquireTask(1)">查询</el-button>
    </div>
    <!-- 打标任务记录列表 -->
    <el-table
      :data="wechatMarkTableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="taskName"
        label="任务名称"
        min-width="120"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerCode"
        label="客户编号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="tagValue"
        label="标签"
        min-width="120"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        min-width="120"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <el-tag size="small" :type="scope.row.color">{{
            scope.row.statusLabel
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="loginName"
        label="提交人"
        min-width="120"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="submitTime"
        label="调用时间"
        width="180"
        align="center"
      ></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="currentPageNo"
      :current-page.sync="pageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "wechatMarkRecord",
  data() {
    return {
      wechatMarkTableData: [],
      loadingTable: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      condition: {
        status: "",
        loginName: "",
        taskName: "",
        start: "",
        end: "",
        gmtCreatedDate: [],
      },
      wechatStatusList: [
        {
          label: "等待打标",
          value: "INIT"
        },
        {
          label: "打标成功",
          value: "SUCCESS"
        },
        {
          label: "号码未匹配",
          value: "MATCH_FAILURE"
        },
        {
          label: "提交失败",
          value: "SUBMIT_FAILURE"
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.theWeek();
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreatedDate = [start, end];
    this.inquireTask(1);
  },
  methods: {
    // 选择状态
    statusChange(val) {},
    // 点击查询
    inquireTask(index) {
      if (index) {
        this.pageNo = index
      }
      this.timeTransformation();
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        taskName: this.condition.taskName,
        loginName: this.condition.loginName,
        status: this.condition.status,
        start: this.condition.start,
        end: this.condition.end,
      }
      this.getRecordList(data);
    },
    // 查询打标任务记录
    getRecordList(data) {
      this.$http("/wechatTask/mark/recordList",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach(item => {
            item.submitTime = this.$common.transformTime(item.submitTime);
            item.statusLabel = this.statusTransformation(item.status).label;
            item.color = this.statusTransformation(item.status).color;
          })
          this.wechatMarkTableData = list;
          this.total = res.data.total;
        }
      })
    },
    // 分页
    currentPageNo(index) {
      this.inquireTask(index);
    },
    // 每页
    pageSizeChange(index) {
      this.pageSize = index;
      this.inquireTask(1);
    },
    // 查询时间转换
    timeTransformation() {
      let start = "";
      let end = "";
      if (
        this.condition.gmtCreatedDate &&
        this.condition.gmtCreatedDate.length > 0
      ) {
        start = this.condition.gmtCreatedDate[0];
        end = this.condition.gmtCreatedDate[1];
      }
      this.condition.start = start;
      this.condition.end = end;
    },
    // 状态转换
    statusTransformation(val) {
      let res = {
        label: "",
        color: "",
      };
      switch (val) {
        case "INIT":
          res = {
            label: "等待打标",
            color: "warning",
          };
          break;
        case "SUBMITTING":
          res = {
            label: "提交中",
            color: "warning",
          };
          break;
        case "SUCCESS":
          res = {
            label: "打标成功",
            color: "success",
          };
          break;
        case "MATCH_FAILURE":
          res = {
            label: "号码未匹配",
            color: "danger",
          };
          break;
        case "SUBMIT_FAILURE":
          res = {
            label: "提交失败",
            color: "danger",
          };
          break;
      }
      return res;
    },
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
  },
};
</script>
<style scoped>
.data_style {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.nav .batchName .el-select {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.upload-demo >>> .el-upload-dragger {
  width: 304px;
}
.records_style >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
</style>