<template>
  <div class="serverManage">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="服务器列表" name="first">
        <div class="nav_server">
          <div class="nav_server_item">
            服务器名称：
            <el-input v-model="serverName" placeholder="请输入服务器名称" clearable></el-input>
          </div>
        </div>
        <div class="btn_server">
          <el-button type="primary" @click="inquire(1)">查询</el-button>
          <el-button type="primary" @click="serverClick(null, 'add')">新增</el-button>
          <el-button @click="batchOperation" :disabled="dropdownDisabled">批量操作</el-button>
          <el-button type="primary" @click="inquire()" class="refresh_btn">刷新</el-button>
        </div>
        <!-- 服务器表格 -->
        <el-table 
          :data="serverTableData" 
          stripe 
          style="width: 100%" 
          border 
          v-loading="loadingTab" 
          @selection-change="handleSelectionChange" 
          show-summary
          :summary-method="getServerSummaries"
        >
          <!-- <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column> -->
          <el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
          <el-table-column prop="name" label="服务器名称" min-width="120" align="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="externalIp" label="外网ip" width="140" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="innerIp" label="内网ip" width="140" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="serverMaxMultiple" label="服务器并发" width="150" align="center">
            <template slot="header">
              <span>服务器并发</span>
              <el-tooltip class="item" effect="light" content="实时并发 | 最大并发" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.serverCurrentMultiple}}</span>
              <!-- <el-button type="text" @click="concurrencyDetails(scope.row)">{{scope.row.serverCurrentMultiple}}</el-button> -->
              <el-divider direction="vertical"></el-divider>
              {{scope.row.serverMaxMultiple}}
            </template>
          </el-table-column>
          <el-table-column prop="serverMaxMultiplePerSecond" label="每秒并发" width="125" align="center">
            <template slot="header">
              <span>每秒并发</span>
              <el-tooltip class="item" effect="light" content="小于等于0表示无限制" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span>{{scope.row.serverMaxMultiplePerSecond}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="enabled" label="是否可用" width="110" align="center">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.enabled"
                active-color="#13ce66"
                inactive-color="#C0CCDA"
                @change="enabledChagne(scope.row,scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="canStartTask" label="开启任务" width="110" align="center">
            <template slot="header">
              <span>开启任务</span>
              <el-tooltip class="item" effect="light" content="关闭后，所有用户将不能开启任务" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.canStartTask"
                active-color="#13ce66"
                inactive-color="#C0CCDA"
                :disabled="!scope.row.enabled"
                @change="canStartTaskChagne(scope.row,scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="canLogin" label="页面登录" width="110" align="center">
            <template slot="header">
              <span>页面登录</span>
              <el-tooltip class="item" effect="light" content="关闭后，所有用户无法登陆系统" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.canLogin"
                active-color="#13ce66"
                inactive-color="#C0CCDA"
                :disabled="!scope.row.enabled"
                @change="canLoginChagne(scope.row,scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="canDownloadVoice" label="下载录音" width="110" align="center">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.canDownloadVoice"
                active-color="#13ce66"
                inactive-color="#C0CCDA"
                :disabled="!scope.row.enabled"
                @change="canDownloadVoiceChagne(scope.row,scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="recordVoice" label="通话录音" width="110" align="center">
            <template slot="header">
              <span>通话录音</span>
              <el-tooltip class="item" effect="light" content="关闭后，所有通话将不会录音" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.recordVoice"
                active-color="#13ce66"
                inactive-color="#C0CCDA"
                :disabled="!scope.row.enabled"
                @change="soundRecordChagne(scope.row,scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="compressVoice" label="录音压缩" width="110" align="center">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.compressVoice"
                active-color="#13ce66"
                inactive-color="#C0CCDA"
                :disabled="!scope.row.enabled"
                @change="recordCompressChagne(scope.row,scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="allowAutoRecall" label="自启动" width="110" align="center">
            <!-- <template slot="header" slot-scope="scope">
              <span>自启动</span>
              <el-tooltip class="item" effect="light" content="自启动" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template> -->
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.allowAutoRecall"
                active-color="#13ce66"
                inactive-color="#C0CCDA"
                :disabled="!scope.row.enabled"
                @change="allowAutoRecallChagne(scope.row,scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="needNotifyLabel" label=" 需要变动通知" width="140" align="center"></el-table-column>
            <el-table-column prop="innerNetTransferLabel" label=" 内网传输" width="140" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" width="200" align="center"></el-table-column>
            <el-table-column prop label="操作" width="120" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="serverClick(scope.row, 'modify')">修改</el-button>
                <el-button type="text" size="small" @click="currentResetClick(scope.row)">重置并发</el-button>
              </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="currentPage_data"
          :current-page.sync="pageNo"
          :page-size="10"
          @size-change="sizeChange"
          :page-sizes="[10, 20, 30, 40, 100]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="total"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="服务器分组" name="second">
        <server-group v-if="serverGroupShow"></server-group>
      </el-tab-pane>
    </el-tabs>
    <!-- 修改or新增 -->
    <el-dialog :visible.sync="modifyServerInfoShow" class="input-m" width="800px" @close="modifyServerInfoClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">{{serverInfotitle}}</span>
      </template>
      <el-form :model="modifyServerForm" label-position="left" :inline="true" label-width="115px" class="modifyFormClass">
        <el-form-item label="服务器名称：" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">
          <el-input v-model="modifyServerForm.name" placeholder="请输入服务器名称"></el-input>
        </el-form-item>
        <el-form-item label="外网ip：" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">
          <el-input v-model="modifyServerForm.externalIp" placeholder="请输入外网ip"></el-input>
        </el-form-item>
        <el-form-item label="内网ip：" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">
          <el-input v-model="modifyServerForm.innerIp" placeholder="请输入内网ip"></el-input>
        </el-form-item>
        <el-form-item label="服务器并发：" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">
          <el-input v-model="modifyServerForm.serverMaxMultiple" placeholder="请输入服务器并发"></el-input>
        </el-form-item>
        <el-form-item label="每秒并发：">
          <template slot="label">
            <span>每秒并发</span>
            <el-tooltip class="item" effect="light" content="小于等于0表示无限制" placement="top-start">
              <i class="el-icon-question"></i>
            </el-tooltip>：
          </template>
          <el-input v-model="modifyServerForm.serverMaxMultiplePerSecond" placeholder="请输入每秒并发" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'"></el-input>
          <el-input v-model="modifyServerForm.serverMaxMultiplePerSecond" placeholder="请输入每秒并发" v-if="serverInfotitle === '批量操作'" style="width: 150px"></el-input>
          <el-button type="text" @click="batchModifySwitch(modifyServerForm.serverMaxMultiplePerSecond, '每秒并发', 'serverMaxMultiplePerSecond')" v-if="serverInfotitle === '批量操作'" >更新</el-button>
        </el-form-item>
        <el-form-item label="开启任务：">
          <el-switch
            v-if="serverInfotitle === '新增'"
            v-model="modifyServerForm.canStartTask"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
          <el-radio-group 
            v-if="serverInfotitle === '批量操作'"
            v-model="modifyServerForm.canStartTask"
            style="margin: 0 2px 0 0; width: 150px"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-button type="text" @click="batchModifySwitch(modifyServerForm.canStartTask, '开启任务', 'canStartTask')" v-if="serverInfotitle === '批量操作'" >更新</el-button>
        </el-form-item>
        <el-form-item label="页面登录：">
          <el-switch
            v-if="serverInfotitle === '新增'"
            v-model="modifyServerForm.canLogin"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
          <el-radio-group 
            v-if="serverInfotitle === '批量操作'"
            v-model="modifyServerForm.canLogin"
            style="margin: 0 2px 0 0; width: 150px"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-button type="text" @click="batchModifySwitch(modifyServerForm.canLogin, '页面登录', 'canLogin')" v-if="serverInfotitle === '批量操作'" >更新</el-button>
        </el-form-item>
        <el-form-item label="下载录音：">
          <el-switch
            v-if="serverInfotitle === '新增'"
            v-model="modifyServerForm.canDownloadVoice"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
          <el-radio-group 
            v-if="serverInfotitle === '批量操作'"
            v-model="modifyServerForm.canDownloadVoice"
            style="margin: 0 2px 0 0; width: 150px"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-button type="text" @click="batchModifySwitch(modifyServerForm.canDownloadVoice, '下载录音', 'canDownloadVoice')" v-if="serverInfotitle === '批量操作'" >更新</el-button>
        </el-form-item>
        <el-form-item label="是否可用：">
          <el-switch
            v-if="serverInfotitle === '新增'"
            v-model="modifyServerForm.enabled"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
          <el-radio-group 
            v-if="serverInfotitle === '批量操作'"
            v-model="modifyServerForm.enabled"
            style="margin: 0 2px 0 0; width: 150px"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-button type="text" @click="batchModifySwitch(modifyServerForm.enabled, '是否可用', 'enabled')" v-if="serverInfotitle === '批量操作'" >更新</el-button>
        </el-form-item>
        <el-form-item label="通话录音：">
          <el-switch
            v-if="serverInfotitle === '新增'"
            v-model="modifyServerForm.recordVoice"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
          <el-radio-group 
            v-if="serverInfotitle === '批量操作'"
            v-model="modifyServerForm.recordVoice"
            style="margin: 0 2px 0 0; width: 150px"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-button type="text" @click="batchModifySwitch(modifyServerForm.recordVoice, '通话录音', 'recordVoice')" v-if="serverInfotitle === '批量操作'" >更新</el-button>
        </el-form-item>
        <el-form-item label="自启动：">
          <el-switch
            v-if="serverInfotitle === '新增'"
            v-model="modifyServerForm.allowAutoRecall"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
          <el-radio-group 
            v-if="serverInfotitle === '批量操作'"
            v-model="modifyServerForm.allowAutoRecall"
            style="margin: 0 2px 0 0; width: 150px"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-button type="text" @click="batchModifySwitch(modifyServerForm.allowAutoRecall, '自启动', 'allowAutoRecall')" v-if="serverInfotitle === '批量操作'" >更新</el-button>
        </el-form-item>
        <el-form-item label="录音压缩：">
          <el-switch
            v-if="serverInfotitle === '新增'"
            v-model="modifyServerForm.compressVoice"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
          <el-radio-group 
            v-if="serverInfotitle === '批量操作'"
            v-model="modifyServerForm.compressVoice"
            style="margin: 0 2px 0 0; width: 150px"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-button type="text" @click="batchModifySwitch(modifyServerForm.compressVoice, '录音压缩', 'compressVoice')" v-if="serverInfotitle === '批量操作'" >更新</el-button>
        </el-form-item>
        <el-form-item label="需要变动通知：" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">
          <el-switch
            v-model="modifyServerForm.needNotify"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="内网传输：" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">
          <el-radio-group v-model="modifyServerForm.innerNetTransfer">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：" style="width: 700px" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">
          <el-input v-model="modifyServerForm.remark" type="textarea" :rows="6" placeholder="请输入备注" style="width: 560px"></el-input>
        </el-form-item>
      </el-form>
      <div class="button-center">
        <el-button type="primary" @click="modifyServer_submit()" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">确定</el-button>
        <el-button @click="modifyServerInfoShow = false" v-if="serverInfotitle === '新增' || serverInfotitle === '修改'">取消</el-button>
        <el-button @click="modifyServerInfoShow = false" v-if="serverInfotitle === '批量操作'">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 批量操作 -->
    <el-dialog 
      :visible.sync="batchOperationShow" 
      width="400px" 
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    > 
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">批量操作</span>
      </template>
      <div style="max-height: 550px; overflow: auto;">
        <el-steps :active="batchIndex" direction="vertical" :space="55">
          <el-step v-for="(item ,i) in batchStepsList" :key="i" :status="item.finishStatus">
            <template slot="title">
              <div>服务器名称：{{item.name}}</div>
            </template>
          </el-step>
        </el-steps>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button v-if="batchOperationBtnShow" @click="batchOperationSubmit">确认</el-button>
      </div>
    </el-dialog>
    <!-- 重置并发 -->
    <el-dialog
      title="重置并发"
      :visible.sync="currentResetShow"
      width="450px"
      @close="currentResetClose">
      <template slot="title">
        <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">重置并发</span>
      </template>
      <el-form label-position="left" label-width="100px" class="el-form">
        <el-form-item label="实时并发：">
          <el-input v-model="currentValue" placeholder="请输入实时并发" clearable style="width: 80%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="currentReset">确定</el-button>
        <el-button @click="currentResetShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 服务器并发图 -->
    <el-dialog
      title="服务器并发占比"
      :visible.sync="currentEchartsShow"
      width="500px"
      @close="currentEchartsClose">
      <template slot="title">
        <i class="el-icon-data-analysis dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">服务器并发占比</span>
      </template>
      <div id="main1" style="width:100%;height: 300px"></div>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
require("echarts/lib/chart/bar");
import serverGroup from "./serverGroup.vue";
export default {
  name: "serverManage",
  components : {
    serverGroup
  },
  data () {
    return {
      currentEchartsData: [],
      currentEchartsShow: false,
      unifiedLoading: null,
      serverName: "",
      serverGroupShow: false,
      activeName: "first",
      server: "",
      serverInfotitle: "",
      serverTableData: [],
      pageNo: 1,
      pageSize: 10,
      total: null,
      loadingTab: false,
      modifyServerInfoShow: false,
      serverId: "",
      modifyServerForm: {
        canStartTask: true,
        canLogin: true,
        canDownloadVoice: true,
        enabled: true,
        needNotify: true,
        name: "",
        innerIp: "",
        externalIp: "",
        innerNetTransfer: true,
        recordVoice: true,
        allowAutoRecall: true,
        compressVoice: true,
        remark: "",
        serverMaxMultiple: "",
        serverMaxMultiplePerSecond: "",
      },
      multipleSelection: [],
      batchStepsList: [],
      batchOperationShow: false,
      batchIndex: 0,
      batchOperationBtnShow: false,
      currentValue: "",
      currentResetShow: false,
      serverMultipleSum: 0
    }
  },
  computed: {
    dropdownDisabled() {
      // 是否禁用批量操作
      if (this.multipleSelection.length > 0) {
        return false
      }
      return true
    }
  },
  mounted() {
    document.title = "惠销平台管理系统-服务器管理";
    this.inquire(1)
  },
  methods: {
    // 表格selection禁用判断
    selectable(row, index) {
      if (row.enabled) {
        return true 
      } else {
        return false
      }
    },
    // 点击批量修改开关
    batchModifySwitch(val, title, key) {
      let tips = title;
      switch (title) {
        case "每秒并发":
          tips = "更新每秒并发";
          break;
        case "开启任务":
          if (val) {
            tips = "开启任务";
          } else {
            tips = "关闭任务";
          }
          break;
        case "页面登录":
          if (val) {
            tips = "可以页面登录";
            } else {
            tips = "不可以页面登录";
          }
          break;
        case "下载录音":
          if (val) {
            tips = "开启下载录音";
          } else {
            tips = "关闭下载录音";
          }
          break;
        case "是否可用":
          if (val) {
            tips = "启用";
          } else {
            tips = "禁用";
          }
          break;
        case "通话录音":
          if (val) {
            tips = "开启通话录音";
          } else {
            tips = "关闭通话录音";
          }
          break;
        case "自启动":
          if (val) {
            tips = "开启自启动";
          } else {
            tips = "关闭自启动";
          }
          break;
        case "录音压缩":
          if (val) {
            tips = "开启录音压缩";
          } else {
            tips = "关闭录音压缩";
          }
          break;
      }
      this.$confirm(tips + ", 是否继续?", "批量提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
      .then(() => {
        if (this.serverInfotitle === '批量操作') {
          this.batchIndex = 0;
          this.batchOperationShow = true;
          this.batchStepPause(this.batchStepsList, this.batchStepsList[0], 0, key);
        }
      })
      .catch(() => {
        // this.modifyServerForm[key] = !val;
      });
    },
    // 点击服务器并发
    concurrencyDetails(val) {
      this.currentEchartsShow = true;
      this.currentEchartsData = [
        {value:335, name:'huixiao'},
        {value:310, name:'huixiao2'},
        {value:234, name:'huixiao10'},
        {value:135, name:'huixiao12'},
        {value:1548, name:'huixiao14'}
      ]
      this.$nextTick(() => {
        this.initData();
      })
    },
    // 关闭服务器并发
    currentEchartsClose() {
      this.currentEchartsData = []
    },
    //初始化服务器并发数据
    initData() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('main1'));
      // 绘制图表
      myChart.setOption({
        title : {
          text: '',//主标题
          subtext: '',//副标题
          x:'center',//x轴方向对齐方式
        },
        tooltip : {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        series : [
          {
            name: '服务器',
            type: 'pie',
            radius : '75%',
            center: ['50%', '50%'],
            data: this.currentEchartsData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      });
    },
    // 合计行服务器
    getServerSummaries(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        if (column.property == "serverMaxMultiple") {
          arr[index] = "当前并发：" + this.serverMultipleSum
        }
      });

      return arr;
    },
    // 点击重置并发
    currentResetClick(val) {
      this.currentValue = val.serverCurrentMultiple;
      this.currentResetShow = true;
      this.server = val.name;
    },
    // 确定重置并发
    currentReset() {
       let data = {
        value: this.currentValue,
      }
      let path = "https://" + this.server + this.$constants.basePath;
      this.$service("/serverInfo/resetServerMultipleRedisCache",data,"post",path).then(res => {
        if (res.result === 200) {
          this.$message.success("重置成功");
          this.currentResetShow = false;
          this.inquire();
        }
      });
    },
    // 关闭重制并发
    currentResetClose() {
      this.currentValue = "";
    },
    // 切换tab
    handleClick() {
      if (this.activeName === "first") {
        this.serverGroupShow = false;
        this.inquire(1);
      }
      if (this.activeName === "second") {
        this.serverGroupShow = true;
      }
    },
    // 表格多选
    handleSelectionChange(val) {
      this.batchStepsList = [];
      this.multipleSelection = this.$common.deepClone(val);
      this.multipleSelection.forEach(item => {
        item.finishStatus = "wait"
        this.batchStepsList.push(item);
      });
    },
    // 点击批量操作
    batchOperation() {
      this.serverInfotitle = "批量操作";
      this.modifyServerInfoShow = true;
    },
    // 确认批量操作
    batchOperationSubmit() {
      this.batchOperationShow = false;
    },
    // 批量操作
    batchStepPause(stepsList, data, index, key) {
      if (index > this.batchStepsList.length-1) {
        this.batchOperationBtnShow = true;
        return
      }
      data.finishStatus = "process";
      let params = {
        id: data.id,
        name: data.name
      };
      params[key] = this.modifyServerForm[key];
      if (key === "serverMaxMultiplePerSecond") {
        params[key] = Number(this.modifyServerForm[key]);
      }
      let path = "https://" + data.name + this.$constants.basePath;
      this.$service("/serverInfo/updateServerInfo", params, 'post', path).then(
        res => {
          if (res.result == 200) {
            this.batchIndex++;
            data.finishStatus = "success";
            return this.batchStepPause(this.batchStepsList, this.batchStepsList[this.batchIndex], this.batchIndex, key);
          } else {
            this.batchIndex++;
            data.finishStatus = "error";
            return this.batchStepPause(this.batchStepsList, this.batchStepsList[this.batchIndex], this.batchIndex, key);
          }
        }
      ).catch(err => {
        this.batchIndex++;
        data.finishStatus = "error";
        return this.batchStepPause(this.batchStepsList, this.batchStepsList[this.batchIndex], this.batchIndex, key);
      })
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: index || this.pageNo,
        pageSize: this.pageSize,
        serverName: this.serverName
      }
      this.getServerInfo(data);
    },
    // 查询服务器信息
    getServerInfo(data) {
      this.serverMultipleSum = 0;
      this.loadingTab = true;
      this.$http("/serverInfo/list",null,"get",null,data).then(res => {
        this.loadingTab = false;
        if (res.result === 200) {
          let list = res.data.records;
          this.total = res.data.total;
          list.forEach(item => {
            if (item.innerNetTransfer) {
              item.innerNetTransferLabel = "是";
            } else {
              item.innerNetTransferLabel = "否";
            }
            if (item.needNotify) {
              item.needNotifyLabel = '开启';
            } else {
              item.needNotifyLabel = '关闭';
            }
            this.serverMultipleSum += item.serverCurrentMultiple;
          });
          this.serverTableData = list;
        }
      }).catch(err => {
        this.loadingTab = false;
      })
    },
    // 分页
    currentPage_data(index) {
      this.pageNo = index;
      this.inquire();
    },
    // 每页数据
    sizeChange(index) {
      this.pageNo = 1;
      this.pageSize = index;
      this.inquire();
    },
    // 开启or关闭任务
    canStartTaskChagne(val,index) {
      let data = {
        id: val.id,
        name: val.name,
        canStartTask: val.canStartTask
      }
      this.server = val.name;
      let title = "开启任务";
      if (val.canStartTask) {
        title = "开启任务"
      } else {
        title = "关闭任务"
      }
      this.$confirm(title + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
      .then(() => {
        this.setServerInfo(data);
      })
      .catch(() => {
        this.$set(this.serverTableData[index], "canStartTask", !val.canStartTask);
      });
    },
    // 开启or关闭页面登录
    canLoginChagne(val,index) {
      let data = {
        id: val.id,
        name: val.name,
        canLogin: val.canLogin
      }
      this.server = val.name;
      let title = "可以登录页面";
      if (val.canLogin) {
        title = "可以登录页面"
      } else {
        title = "不可以登录页面"
      }
      this.$confirm(title + ", 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.setServerInfo(data);
        })
        .catch(() => {
          this.$set(this.serverTableData[index], "canLogin", !val.canLogin);
        });
    },
    // 开启or关闭下载录音
    canDownloadVoiceChagne(val,index) {
      let data = {
        id: val.id,
        name: val.name,
        canDownloadVoice: val.canDownloadVoice
      }
      this.server = val.name;
      let title = "开启下载录音";
      if (val.canDownloadVoice) {
        title = "开启下载录音"
      } else {
        title = "关闭下载录音"
      }
      this.$confirm(title + ", 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.setServerInfo(data);
        })
        .catch(() => {
          this.$set(this.serverTableData[index], "canDownloadVoice", !val.canDownloadVoice);
        });
    },
    // 开启or关闭启用
    enabledChagne(val,index) {
      let data = {
        id: val.id,
        name: val.name,
        enabled: val.enabled
      }
      this.server = val.name;
      let title = "启用";
      if (val.enabled) {
        title = "启用"
      } else {
        title = "禁用"
      }
      this.$confirm(title + ", 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.setServerInfo(data);
        })
        .catch(() => {
          this.$set(this.serverTableData[index], "enabled", !val.enabled);
        });
    },
    // 开启or关闭通话录音
    soundRecordChagne(val,index) {
      let data = {
        id: val.id,
        name: val.name,
        recordVoice: val.recordVoice
      }
      this.server = val.name;
      let title = "开启通话录音";
      if (val.recordVoice) {
        title = "开启通话录音"
      } else {
        title = "关闭通话录音"
      }
      this.$confirm(title + ", 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.setServerInfo(data);
        })
        .catch(() => {
          this.$set(this.serverTableData[index], "recordVoice", !val.recordVoice);
        });
    },
    // 开启or关闭录音压缩
    recordCompressChagne(val,index) {
      let data = {
        id: val.id,
        name: val.name,
        compressVoice: val.compressVoice
      }
      this.server = val.name;
      let title = "开启录音压缩";
      if (val.compressVoice) {
        title = "开启录音压缩"
      } else {
        title = "关闭录音压缩"
      }
      this.$confirm(title + ", 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.setServerInfo(data);
        })
        .catch(() => {
          this.$set(this.serverTableData[index], "compressVoice", !val.compressVoice);
        });
    },
    // 开启or关闭自启动
    allowAutoRecallChagne(val,index) {
      let data = {
        id: val.id,
        name: val.name,
        allowAutoRecall: val.allowAutoRecall
      }
      this.server = val.name;
      let title = "开启自启动";
      if (val.allowAutoRecall) {
        title = "开启自启动"
      } else {
        title = "关闭自启动"
      }
      this.$confirm(title + ", 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning"
        })
        .then(() => {
          this.setServerInfo(data);
        })
        .catch(() => {
          this.$set(this.serverTableData[index], "allowAutoRecall", !val.allowAutoRecall);
        });
    },
    // 点击修改
    serverClick(val, key) {
      this.modifyServerInfoShow = true;
      if (key === 'add') {
        this.serverInfotitle = "新增";
      }
      if (key === 'modify') {
        this.serverInfotitle = "修改";
      }
      if (val) {
        this.server = val.name;
        this.serverId = val.id;
        this.modifyServerForm = {
          name: val.name,
          innerIp: val.innerIp,
          externalIp: val.externalIp,
          innerNetTransfer: val.innerNetTransfer,
          needNotify: val.needNotify,
          remark: val.remark,
          serverMaxMultiple: val.serverMaxMultiple,
          serverMaxMultiplePerSecond: val.serverMaxMultiplePerSecond,
        }
      }
    },
    // 确定修改
    modifyServer_submit() {
      let data = {
        name: this.modifyServerForm.name,
        innerIp: this.modifyServerForm.innerIp,
        externalIp: this.modifyServerForm.externalIp,
        innerNetTransfer: this.modifyServerForm.innerNetTransfer,
        needNotify: this.modifyServerForm.needNotify,
        remark: this.modifyServerForm.remark,
        serverMaxMultiple: this.modifyServerForm.serverMaxMultiple,
        serverMaxMultiplePerSecond: Number(this.modifyServerForm.serverMaxMultiplePerSecond),
      }
      if (this.serverInfotitle === '新增') {
        data.canStartTask = this.modifyServerForm.canStartTask;
        data.canLogin = this.modifyServerForm.canLogin;
        data.canDownloadVoice = this.modifyServerForm.canDownloadVoice;
        data.enabled = this.modifyServerForm.enabled;
        data.recordVoice = this.modifyServerForm.recordVoice;
        data.allowAutoRecall = this.modifyServerForm.allowAutoRecall;
        data.compressVoice = this.modifyServerForm.compressVoice;
        this.addServerInfo(data);
      }
      if (this.serverInfotitle === '修改') {
        data.id = this.serverId;
        this.setServerInfo(data);
      }
    },
    // 关闭修改弹窗
    modifyServerInfoClose() {
      this.serverId = "";
      this.modifyServerForm = {
        canStartTask: true,
        canLogin: true,
        canDownloadVoice: true,
        enabled: true,
        needNotify: true,
        name: "",
        innerIp: "",
        externalIp: "",
        innerNetTransfer: true,
        recordVoice: true,
        allowAutoRecall: true,
        compressVoice: true,
        remark: "",
        serverMaxMultiple: "",
        serverMaxMultiplePerSecond: "",
      }
      if (this.serverInfotitle === '批量操作') {
        this.inquire();
      }
    },
    // 新增服务器信息
    addServerInfo(data){
      this.$service("/serverInfo/addServerInfo",data).then(res => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.modifyServerInfoShow = false;
          this.inquire();
        }
      })
    },
    // 修改服务器信息
    setServerInfo(data){
      let path = "https://" + this.server + this.$constants.basePath;
      this.modifyServerInfoShow = false;
      this.unifiedLoading = this.$loading({message: "修改中..."});
      this.$service("/serverInfo/updateServerInfo",data,'post',path).then(res => {
        this.unifiedLoading.close();
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.inquire();
        }
      }).catch(err => {
        this.unifiedLoading.close();
        this.inquire();
      })
    }
  }
}
</script>
<style scoped>
.input-m >>> .el-input {
  width: 100%;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
 text-align: center
}
.modifyFormClass >>> .el-form-item {
  width: 350px;
}
.nav_server {
  margin-bottom: 20px;
}
.nav_server .nav_server_item {
  display: inline-block;
}
.nav_server .nav_server_item .el-input {
  width: 250px;
}
.btn_server {
  position: relative;
}
.btn_server .refresh_btn{
  float: right;
}
</style>

