//对$axios的二次封装

import axios from "axios";
import qs from "qs";
import constants from "./constants.js";

const loginHttp = function http(url, data, type, baseURL, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: type || "post",
      baseURL: baseURL || constants.baseURL, // 将自动加在 url 前面，除非 url 是一个绝对 URL
      url: url,
      data: data,
      params: params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
      transformRequest: [
        function (data) {
          // 向服务器发送数据前修改数据 (只用于 'PUT', 'POST' 和 'PATCH' 请求方法)
          return qs.stringify(data);
        },
      ],
      headers: { "Content-Type": "application/x-www-form-urlencoded" }, //即将被发送的自定义请求头
      withCredentials: true, //  表示跨域请求时允许携带cookie
    })
      .then((res) => {
        if (res.response) {
          resolve(res);
        }
        if (res.data) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default loginHttp;
