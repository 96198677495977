<template>
  <div class="taksTypeTemplate">
    <el-dialog title="模板选择" :visible="templateSelectionShow" width="1200px" class="connectedTable smsTemplate" @close="closed">
      <template slot="title">
        <i class="el-icon-data-analysis dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">模板选择</span>
      </template>
      <el-tabs v-model="activeTemplateName" @tab-click="templateHandleClick">
        <el-tab-pane label="人工坐席" name="normal" :disabled="normalDisabled">
          <!-- 人工坐席列表 -->
          <el-table :data="callTemplateTableData" stripe style="width: 100%" size="mini" border="" v-loading="seatsLoading">
            <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
            <el-table-column prop="name" label="名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="subName" label="子名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="originateTimeout" label="呼叫超时(秒)" width="100" align="left">
              <template slot-scope="scope">
                {{scope.row.strategyTemplateClientTalking.originateTimeout}}
              </template>
            </el-table-column>
            <el-table-column prop="answerInterval" label="坐席整理时间(秒)" width="130" align="left">
              <template slot-scope="scope">
                {{scope.row.strategyTemplateClientTalking.answerInterval}}
              </template>
            </el-table-column>
            <el-table-column prop="stopCallOnFull" label="坐席全满暂停外呼" width="130" align="left">
              <template slot-scope="scope">
                <span v-if="scope.row.strategyTemplateClientTalking.stopCallOnFull">是</span>
                <span v-if="!scope.row.strategyTemplateClientTalking.stopCallOnFull">否</span>
              </template>
            </el-table-column>
            <el-table-column prop="pauseSecondsOnFull" label="坐席全满停止外呼(秒)" width="140" align="left">
              <template slot-scope="scope">
                {{scope.row.strategyTemplateClientTalking.pauseSecondsOnFull}}
              </template>
            </el-table-column>
            <el-table-column prop label="操作" width="90" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="selectTemplate(scope.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="callTemplateChangePage"
            :current-page.sync="callTemplateTablePageNo"
            :page-size="10"
            :page-sizes="[10, 20, 30, 40]"
            layout="sizes, total, prev, pager, next,jumper"
            :total="callTemplateTableTotal"
            :pager-count="5"
          ></el-pagination>
        </el-tab-pane>

        <el-tab-pane label="人工开场白" name="prologue" :disabled="prologueDisabled">
          <!-- 人工开场白列表 -->
          <el-table :data="prologueTemplateTableData" stripe style="width: 100%" size="mini" border="" v-loading="prologueLoading">
            <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
            <el-table-column prop="name" label="名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="subName" label="子名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="prologueTitle" label="开场白名称" width="120" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="playPrologue" label="开场白启用" width="100" align="left">
              <template slot-scope="scope">
                <span v-if="scope.row.playPrologue">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column prop="originateTimeout" label="呼叫超时(秒)" width="100" align="left">
              <template slot-scope="scope">
                {{scope.row.strategyTemplateClientTalking.originateTimeout}}
              </template>
            </el-table-column>
            <el-table-column prop="answerInterval" label="坐席整理时间(秒)" width="130" align="left">
              <template slot-scope="scope">
                {{scope.row.strategyTemplateClientTalking.answerInterval}}
              </template>
            </el-table-column>
            <el-table-column prop="stopCallOnFull" label="坐席全满暂停外呼" width="130" align="left">
              <template slot-scope="scope">
                <span v-if="scope.row.strategyTemplateClientTalking.stopCallOnFull">是</span>
                <span v-if="!scope.row.strategyTemplateClientTalking.stopCallOnFull">否</span>
              </template>
            </el-table-column>
            <el-table-column prop="pauseSecondsOnFull" label="坐席全满停止外呼(秒)" width="140" align="left">
              <template slot-scope="scope">
                {{scope.row.strategyTemplateClientTalking.pauseSecondsOnFull}}
              </template>
            </el-table-column>
            <el-table-column prop label="操作" width="90" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="selectTemplate(scope.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="prologueTemplateChangePage"
            :current-page.sync="prologueTemplateTablePageNo"
            :page-size="10"
            :page-sizes="[10, 20, 30, 40]"
            layout="sizes, total, prev, pager, next,jumper"
            :total="prologueTemplateTableTotal"
            :pager-count="5"
          ></el-pagination>
        </el-tab-pane>

        <el-tab-pane label="挂机短信" name="short" :disabled="shortDisabled">
          <!-- 短信模板列表 -->
          <el-table :data="smsTemplateTableData" stripe style="width: 100%" size="mini" border="" v-loading="smsLoading">
            <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
            <el-table-column prop="name" label="名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="subName" label="子名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="prologueTitle" label="开场白名称" width="120" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="playPrologue" label="开场白启用" width="100" align="left">
              <template slot-scope="scope">
                <span v-if="scope.row.playPrologue">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column prop="content" label="内容" min-width="200" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="prefix" label="前缀" width="100" align="center"></el-table-column>
            <el-table-column prop="smsClickCallbackType" label="点击回调" width="100" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.smsClickCallbackType === 'ADD_WECHAT'">添加微信</span>
              </template>
            </el-table-column>
            <el-table-column prop="longUrl" label="长链" min-width="200" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop label="操作" width="90" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="selectTemplate(scope.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="smsTemplateChangePage"
            :current-page.sync="smsTemplateTablePageNo"
            :page-size="10"
            :page-sizes="[10, 20, 30, 40]"
            layout="sizes, total, prev, pager, next,jumper"
            :total="smsTemplateTableTotal"
            :pager-count="5"
          ></el-pagination>
        </el-tab-pane>
      
        <el-tab-pane label="挂机微信" name="wechat" :disabled="wechatDisabled">
          <!-- 微信模板列表 -->
          <el-table :data="wechatTemplateTableData" stripe style="width: 100%" size="mini" border="" v-loading="wechatLoading" ref="wechatTemplateTable">
            <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
            <el-table-column prop="name" label="名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="subName" label="子名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="prologueTitle" label="开场白名称" width="120" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="playPrologue" label="开场白启用" width="100" align="left">
              <template slot-scope="scope">
                <span v-if="scope.row.playPrologue">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column prop="triggerWechatServer" label="服务器" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="triggerWechatClientGroupName" label="分组" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop label="操作" width="90" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="selectTemplate(scope.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="wechatTemplateChangePage"
            :current-page.sync="wechatTemplateTablePageNo"
            :page-size="10"
            :page-sizes="[10, 20, 30, 40]"
            layout="sizes, total, prev, pager, next,jumper"
            :total="wechatTemplateTableTotal"
            :pager-count="5"
          ></el-pagination>
        </el-tab-pane>

        <el-tab-pane label="AI机器人" name="robot" :disabled="robotDisabled">
           <!-- AI机器人模板列表 -->
          <el-table :data="robotTemplateTableData" stripe style="width: 100%" size="mini" border="" v-loading="robotLoading" ref="wechatTemplateTable">
            <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
            <el-table-column prop="name" label="名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="subName" label="子名称" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="dialogueName" label="机器人话术" width="" align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop label="操作" width="90" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="selectTemplate(scope.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="robotTemplateChangePage"
            :current-page.sync="robotTemplateTablePageNo"
            :page-size="10"
            :page-sizes="[10, 20, 30, 40]"
            layout="sizes, total, prev, pager, next,jumper"
            :total="robotTemplateTableTotal"
            :pager-count="5"
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "taksTypeTemplate",
  props: {
    taskType:  {
      // 任务类型
      type: String,
      default: ""
    }
  },
  data() {
    return {
      robotTemplateTableData: [],
      robotLoading: false,
      robotTemplateTablePageNo: 1,
      robotTemplateTableTotal: 0,
      robotDisabled: false,
      normalDisabled: false,
      prologueDisabled: false,
      shortDisabled: false,
      wechatDisabled: false,
      templateSelectionShow: false,
      callTemplateTableData: [],
      activeTemplateName: "",
      seatsLoading: false,
      callTemplateTablePageNo: 1,
      callTemplateTableTotal: 0,
      smsTemplateTableData: [],
      smsLoading: false,
      smsTemplateTablePageNo: 1,
      smsTemplateTableTotal: 0,
      wechatTemplateTableData: [],
      wechatLoading: false,
      wechatTemplateTablePageNo: 1,
      wechatTemplateTableTotal: 0,
      prologueLoading: false,
      prologueTemplateTablePageNo: 1,
      prologueTemplateTableTotal: 0,
      prologueTemplateTableData: []
    }
  },
  computed: {
    activeName() {
      let active = "normal";
      if (this.taskType === "PROLOGUE") {
        active = "prologue";
        this.normalDisabled = true;
        this.prologueDisabled = false;
        this.shortDisabled = true;
        this.wechatDisabled = true;
        this.robotDisabled = true;
        return active
      }
      if (this.taskType === "PROLOGUE_SMS") {
        active = "short";
        this.normalDisabled = true;
        this.prologueDisabled = true;
        this.shortDisabled = false;
        this.wechatDisabled = true;
        this.robotDisabled = true;
        return active
      }
      if (this.taskType === "PROLOGUE_WECHAT") {
        active = "wechat";
        this.normalDisabled = true;
        this.prologueDisabled = true;
        this.shortDisabled = true;
        this.robotDisabled = true;
        this.wechatDisabled = false;
        return active
      }
      if (this.taskType === "AI_ROBOT") {
        active = "robot";
        this.normalDisabled = true;
        this.prologueDisabled = true;
        this.shortDisabled = true;
        this.wechatDisabled = true;
        this.robotDisabled = false;
        return active
      }
      this.normalDisabled = false;
      this.prologueDisabled = true;
      this.shortDisabled = true;
      this.wechatDisabled = true;
      this.robotDisabled = true;
      return active
    }
  },
  mounted() {

  },
  methods: {
    // 初始化
    init() {
      this.templateSelectionShow = true;
      this.activeTemplateName = this.activeName;
      this.templateHandleClick();
    },
    // 关闭
    closed() {
      this.templateSelectionShow = false;
    },
    // 选择模板tab
    templateHandleClick() {
      if (this.activeTemplateName === "short") {
        this.smsInquire(1);
      }
      if (this.activeTemplateName === "wechat") {
        this.wechatInquire(1);
      }
      if (this.activeTemplateName === "normal") {
        this.seatsInquire(1);
      }
      if (this.activeTemplateName === "prologue") {
        this.prologueInquire(1);
      }
      if (this.activeTemplateName === "robot") {
        this.robotInquire(1);
      }
    },
    // 点击短信模板查询
    robotInquire(index) {
      if (index) {
        this.robotTemplateTablePageNo = index;
      }
      let data = {
        pageNo: this.robotTemplateTablePageNo,
        pageSize: 10,
        enabled: true,
        templateType: "AI_ROBOT"
      };
      this.getMsgTemplateList(data, "robot");
    },
    // 翻页--机器人
    robotTemplateChangePage(index) {
      this.robotInquire(indedx);
    },
    // 点击短信模板查询
    smsInquire(index) {
      if (index) {
        this.smsTemplateTablePageNo = index;
      }
      let data = {
        pageNo: this.smsTemplateTablePageNo,
        pageSize: 10,
        enabled: true,
        templateType: "PROLOGUE_SMS"
      };
      this.getMsgTemplateList(data, "short");
    },
    // 翻页--短信
    smsTemplateChangePage(index) {
      this.smsInquire(index);
    },
    // 点击查询微信模板列表
    wechatInquire(index) {
      if (index) {
        this.wechatTemplateTablePageNo = index
      }
      let data = {
        pageNo: this.wechatTemplateTablePageNo,
        pageSize: 10,
        enabled: true,
        templateType: "PROLOGUE_WECHAT"
      };
      this.getMsgTemplateList(data, "wechat");
    },
    // 翻页--微信
    wechatTemplateChangePage(index) {
      this.wechatInquire(index);
    },
    // 点击查询人工坐席列表
    seatsInquire(index) {
      if (index) {
        this.callTemplateTablePageNo = index
      }
      let data = {
        pageNo: this.callTemplateTablePageNo,
        pageSize: 10,
        enabled: true,
        templateType: "NORMAL"
      };
      this.getMsgTemplateList(data, "normal");
    },
    // 翻页--人工坐席
    callTemplateChangePage(index) {
      this.seatsInquire(index);
    },
    // 点击查询人工开场白列表
    prologueInquire(index) {
      if (index) {
        this.prologueTemplateTablePageNo = index
      }
      let data = {
        pageNo: this.prologueTemplateTablePageNo,
        pageSize: 10,
        enabled: true,
        templateType: "PROLOGUE"
      };
      this.getMsgTemplateList(data, "prologue");
    },
    // 翻页--人工开场白
    prologueTemplateChangePage(index) {
      this.prologueInquire(index);
    },
    // 查询模板列表
    getMsgTemplateList(data, key) {
      if (key === "short") {
        this.smsLoading = true;
      }
      if (key === "wechat") {
        this.wechatLoading = true;
      }
      if (key === "normal") {
        this.seatsLoading = true;
      }
      if (key === "prologue") {
        this.prologueLoading = true;
      }
      if (key === "robot") {
        this.robotLoading = true;
      }
      this.$http("/msgTemplate/list", null, "get", null, data).then(res => {
        if (key === "short") {
          this.smsLoading = false;
        }
        if (key === "wechat") {
          this.wechatLoading = false;
        }
        if (key === "normal") {
          this.seatsLoading = false;
        }
        if (key === "prologue") {
          this.prologueLoading = false;
        }
        if (key === "robot") {
          this.robotLoading = false;
        }
        if (res.result === 200) {
          let list = res.data.records;
          let hangupSmsRouteInfoList = list.hangupSmsRouteInfoList;
          if (hangupSmsRouteInfoList) {
            hangupSmsRouteInfoList.forEach(item => {
              if (item.customerPhoneOperator) {
                item.customerPhoneOperator = item.customerPhoneOperator.split(",");
              } else {
                item.customerPhoneOperator = [];
              }
              if (item.forbiddenArea) {
                item.forbiddenArea = item.forbiddenArea.split(",");
              } else {
                item.forbiddenArea = [];
              }
              item.platformLabel = item.platform + '_' + item.accessKey;
            });
          }
          if (key === "short") {
            this.smsTemplateTableData = list;
            this.smsTemplateTableTotal = res.data.total;
          }
          if (key === "wechat") {
            this.wechatTemplateTableData = list;
            this.wechatTemplateTableTotal = res.data.total;
          }
          if (key === "normal") {
            list.forEach(item => {
              if (!item.strategyTemplateClientTalking) {
                item.strategyTemplateClientTalking = {
                  originateTimeout: "",
                  answerInterval: "",
                  stopCallOnFull: "",
                  pauseSecondsOnFull: "",
                }
              }
            })
            this.callTemplateTableData = list;
            this.callTemplateTableTotal = res.data.total;
          }
          if (key === "prologue") {
            list.forEach(item => {
              if (!item.strategyTemplateClientTalking) {
                item.strategyTemplateClientTalking = {
                  originateTimeout: "",
                  answerInterval: "",
                  stopCallOnFull: "",
                  pauseSecondsOnFull: "",
                }
              }
            })
            this.prologueTemplateTableData = list;
            this.prologueTemplateTableTotal = res.data.total;
          }
          if (key === "robot") {
            this.robotTemplateTableData = list;
            this.robotTemplateTableTotal = res.data.total;
          }
        }
      }).catch(err => {
        if (key === "short") {
          this.smsLoading = false;
        }
        if (key === "wechat") {
          this.wechatLoading = false;
        }
        if (key === "normal") {
          this.seatsLoading = false;
        }
        if (key === "prologue") {
          this.prologueLoading = false;
        }
        if (key === "robot") {
          this.robotLoading = false;
        }
      })
    },
    // 点击选择模板选择
    selectTemplate(val) {
      let data = {
        template_name_label: val.name,
        hangupSmsStrategyTemplateId: val.id
      }
      this.$emit("selectTemplate", data);
      this.templateSelectionShow = false;
    },
  }
}
</script>
<style scoped>
.smsTemplate >>> .el-table__fixed-right {
  height: 100% !important;
}
.connectedTable >>> .el-dialog__body::after {
  display:block;
  content:'';
  clear:both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 0 0 20px 0;
}
.connectedTable >>> .el-table__body .cell {
  font-size: 14px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>