<template>
  <div class="knowledgeBase">
    <el-radio-group 
      v-model="activeName" 
      style="margin-bottom: 20px"
      @change="activeNameChange"
      size="small"
    >
      <el-radio-button label="entry">知识库词条</el-radio-button>
      <el-radio-button label="flow">知识库流程</el-radio-button>
    </el-radio-group>
    <router-view 
      name="four" 
      :activeSpeechName="activeSpeechName"
      :activeSpeechId="activeSpeechId">
    </router-view>
  </div>
</template>
<script>
export default {
  name: "knowledgeBase",
  props: {
    activeSpeechId: [String, Number],
    activeSpeechName: String
  },
  data() {
    return {
      activeName: "",
      locationActiveName: "",
    }
  },
  mounted() {
    this.showTab();
  },
  methods: {
    activeNameChange(val) {
      if (val === this.locationActiveName) {
        return
      }
      this.locationActiveName = val;
      if (this.activeName === "entry") {
        this.$router.push({
          path: "/speechFlow/knowledgeBase/knowledgeEntry",
          query: {
            dialogueId: this.activeSpeechId, 
            name: this.activeSpeechName
          }
        })
      }
      if (this.activeName === "flow") {
        this.$router.push({
          path: "/speechFlow/knowledgeBase/knowledgeFlow",
          query: {
            dialogueId: this.activeSpeechId, 
            name: this.activeSpeechName
          }
        })
      }
    },
    // 显示tab页面
    showTab() {
      let path = window.location.href;
      let index = path.indexOf("?");
      let url = path.substr(0,index);
      url = url.substr(url.lastIndexOf("/")+1);
      if (url === "knowledgeEntry" || url === "knowledgeBase") {
        this.activeName = "entry";
      }
      if (url === "knowledgeFlow") {
        this.activeName = "flow";
      }
      this.activeNameChange(this.activeName);
    }
  },
}
</script>
<style scoped>
.knowledgeBase {
  padding: 15px;
  height: 100%;
}
</style>