<template>
  <div class="SMSmarketing">
    <!-- <div class="groupList">
			群组名称：
			<el-select v-model="groupId" placeholder="请选择" @change = 'selected(groupId)' >
				<el-option v-for="item in groupList" :key='item.id'  :value="item.id" :label='item.groupName'></el-option>
			</el-select>
		</div>						
		<ul class="showInfo">
			<li class='iconfont icon-nv-line-customer guestNum'>
				<em>潜客数</em> <i class='num'>{{info.passengerNum}}</i>
			</li>
			<li class="iconfont icon-qian " >
				<em>费用估计</em><i class='num'>{{info.expense}}</i>
			</li>
			<li class="iconfont icon-feiyong">
				<em>账户余额</em><i class='num'>{{info.balance}}</i>
			</li>
		</ul>
		<div class="filter">
			过滤已发送：
			<el-radio v-model="filter" label="true">过滤</el-radio> 
			<el-radio v-model="filter" label="false">不过滤</el-radio>
		</div> -->
    <div class="operation-btn">
      <el-upload
        class="upload-demo"
        :data="uploadData"
        :action="url"
        :on-success="successUpload"
        :on-error="errorUpload"
        ref="upload"
        :auto-upload="false"
        :file-list="fileList"
        :with-credentials="cookie"
        :limit="1"
        :on-exceed="handleExceed"
        :on-change="handleChange"
        :on-remove="handleRemove"
      >
        <el-button slot="trigger" type="primary">上传号码</el-button>
        <div slot="tip" class="el-upload__tip">只能上传1个文件</div>
      </el-upload>
    </div>
    <div class="sign-input">
      <h4>活动名称：</h4>
      <el-input v-model="msgName" placeholder="请输入名称" clearable></el-input>
    </div>
    <div class="sign-input">
      <h4>活动批次：</h4>
      <el-input
        v-model="activitySubCode"
        placeholder="请输入发送批次"
        clearable
      ></el-input>
    </div>
    <div class="sign">
      <h4>选择平台：</h4>
      <el-select
        v-model="platform"
        placeholder="请选择平台"
        @change="platformChange"
        clearable
      >
        <el-option
          v-for="item in platformList"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        >
        </el-option>
      </el-select>
    </div>
    <div class="sign">
      <h4>请选择签名：</h4>
      <el-select
        v-model="sign"
        placeholder="请选择签名"
        @change="sighChange"
        clearable
      >
        <el-option
          v-for="item in signList"
          :key="item.sign"
          :value="item.sign"
          :label="item.label"
        >
        </el-option>
      </el-select>
      <!-- <a href='javascript:void(0)' @click="signManager = true">签名管理</a> -->
    </div>
    <div class="smsContent">
      <h4>短信内容：</h4>
      <el-tooltip class="item" effect="light" content="" placement="right">
        <div slot="content">
          内容规范： <br />
          <i style="margin-left: 20px"></i>*
          必须带有【签名】，签名内容为：公司或品牌名称，字数要求2-16个字符，运营商必填。<br />
          <i style="margin-left: 20px"></i>*
          签名只能选择审核过的签名，内容首尾不能添加【】。<br />
          <i style="margin-left: 20px"></i>*
          内容合法，不能发送房产、发票、移民等国家法律法规严格禁止的内容。<br />
          <i style="margin-left: 20px"></i>*
          超链接地址请写在短信内容中，便于核实，部分安卓系统存在超链接识别问题，需在超链接后添加空格。<br />
          计费规则：<br />
          <i style="margin-left: 20px"></i>* 短信字数 =
          70个字，按照70个字一条短信计算。中文英文符号统一计算为一个字符。<br />
          <i style="margin-left: 20px"></i>*
          短信字数>70个字，占用3个字符作为分条字符，按照67个字记为一条短信计算，多条短信可能在部分手机系统上呈现为一条短信的形态，测试时请注意。<br />
          审核规则：<br />
          <i style="margin-left: 20px"></i>*
          签名跟模板报备的情况下，短信发送将直接提交给网关。<br />
          <i style="margin-left: 20px"></i>*
          如果出现批量短信发送驳回的情况，可能为“敏感词拦截”进入人工审核，请联系客服。<br />
          时段要求：<br />
          <i style="margin-left: 20px"></i>*
          会员营销短信因运营商规定，发送时段为8:00-22:00，超过时间范围会导致发送失败，请联系客服。<br />
        </div>
        <i class="el-icon-question editor">编辑须知</i>
      </el-tooltip>
      <a href="javascript:void(0)" @click="shortChain" class="template"
        >插入短链</a
      >
      <!-- <a href='javascript:void(0)' @click="UseTemplate()" class="template" style="margin-right: 10px;">使用模板</a> -->
      <el-input
        type="textarea"
        :rows="10"
        placeholder=""
        v-model="textarea"
        resize="none"
        class="textarea"
        ref="count"
      ></el-input>
      <span class="word">
        已输入<i>{{ SMS.totalNum }}</i
        >个字： 签名<i>({{ SMS.signLength }})</i> + 内容(<i>{{
          SMS.contentNum
        }}</i
        >) ，共消耗<i>{{ SMS.num }}</i
        >条短信
      </span>
      <!-- <el-checkbox v-model="hide" class='el-checkbox'>定时发送</el-checkbox>
			<el-date-picker class="time" v-show="hide" v-model="time" type="datetime" placeholder="选择日期时间"  value-format='yyyy-MM-dd HH:mm:ss'></el-date-picker>	<br> -->
      <div
        style="margin: 0px 0px 20px 0px; font-weight: bold"
        v-if="showMockMenu"
      >
        <span>模拟发送：</span>
        <el-switch v-model="mock" @change="mockChange"></el-switch>
      </div>
      <div style="margin: 0px 0px 20px 0px">
        <el-button type="primary" @click="sendNow()" :disabled="isUpload"
          >立即发送</el-button
        >
        <el-button type="primary" @click="sendTiming()">定时发送</el-button>
      </div>
    </div>

    <!-- 插入短链 -->
    <el-dialog
      title="插入短链"
      :visible.sync="shortChainShow"
      custom-class="el-sendTiming"
      width="400px"
    >
      <el-form ref="form" label-width="70px">
        <el-form-item label="长链：" required>
          <el-input
            v-model="longChain"
            placeholder="请输入长链"
            @input="longChainChange"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="shortChain_submit()"
          :disabled="isUpload"
          >确定</el-button
        >
        <el-button @click="shortChainShow = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 定时发送 -->
    <el-dialog
      title="设置定时发送时间"
      :visible.sync="sendTimingShow"
      custom-class="el-sendTiming"
      width="400px"
      @close="sendTimingClose"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="选择时间：">
          <el-date-picker
            class="sendTiming-time"
            v-model="time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="sendTiming_submit()"
          :disabled="isUpload"
          >确定</el-button
        >
        <el-button @click="sendTimingShow = false">取消</el-button>
      </div>
    </el-dialog>

    <!--签名管理-->
    <el-dialog
      :visible.sync="signManager"
      custom-class="el-signManager"
      width="510px"
    >
      <div slot="title" class="dialog-title">
        <h3>添加签名</h3>
      </div>
      <el-tooltip class="item" effect="light" content="" placement="right-end">
        <div slot="content">
          1、平台最多支持10个签名，如需更多签名，请联系客服处理。<br />
          2、人工审核会在10分钟内完成，请耐心等待！<br />
          3、工作时间：8:30 - 21:00，如对审核状态有异议，请联系客服。
        </div>
        <i class="el-icon-question editor">编辑须知</i>
      </el-tooltip>
      <el-form :model="addSign" class="form">
        <el-form-item label="" :label-width="formWidth" class="signName">
          <b>签名名称：</b>
          <el-input
            v-model="addSign.signName"
            placeholder="长度限制为2-16个字符，建议填写产品名 /公司名"
            maxlength="16"
            minlength="2"
          ></el-input>
          <i>实例：天猫商城 、招商银行 、顺丰快递</i>
        </el-form-item>
        <el-form-item label="" :label-width="formWidth" class="id">
          <b>应用场景：</b>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请描述业务使用的场景"
            resize="none"
            v-model="addSign.scene"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="signManager_submit()">提交</el-button>
        <el-button @click="signManager_cancel()">取消</el-button>
      </div>
    </el-dialog>

    <!-- 使用模板 -->
    <el-dialog
      :visible.sync="template"
      custom-class="el-template"
      width="800px"
    >
      <div slot="title" class="dialog-title">
        <h3>使用模板</h3>
      </div>
      <el-table
        :data="templateList"
        stripe
        style="width: 100%"
        border
        class="table"
        max-height="400"
        size="mini"
        :show-header="false"
        empty-text="暂无模板，请在短信内容框输入"
      >
        <el-table-column prop="template" label="模板" width="" align="left">
        </el-table-column>
        <el-table-column prop="" label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="template_checked(scope.row)"
              >使用此模板</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SMSmarketing",
  props: [],
  data() {
    return {
      showMockMenu: false,
      mock: false,
      platform: "YUNFENG",
      platformList: [
        {
          value: "YUNFENG",
          label: "云锋",
        },
      ],
      activitySubCode: "",
      msgName: "",
      isUpload: false,
      sendTimingShow: false,
      uploadData: {},
      url: this.$constants.baseURL + "/sms/short/url/uploadPhoneFile",
      fileList: [],
      cookie: true,
      msg: "短信营销",
      groupList: "",
      groupId: "",
      info: {
        passengerNum: "",
        expense: "",
        balance: "",
      },
      filter: "true",
      signList: [],
      sign: "",

      signManager: false,
      addSign: {
        signName: "",
        scene: "",
      },
      formWidth: "35px",

      textarea: "",
      SMS: {
        totalNum: 0,
        signLength: 0,
        contentNum: 0,
        TD: 4,
        num: 1,
      },
      hide: false,
      time: "",
      templateList: [],
      template: false,

      shortChainShow: false,
      longChain: "",
    };
  },
  watch: {
    textarea(val) {
      //文本域
      let index = this.textarea.indexOf(" #短链# ");
      if (index !== -1) {
        //有短信内容 #短链#
        this.SMS.contentNum = this.textarea.length + 18; // 18 长链 + 空格长度 - ' #短链# '
        this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
      } else {
        this.textarea = this.textarea.trim();
        this.SMS.contentNum = this.textarea.length;
        this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
      }
      if (this.SMS.totalNum < 71) {
        this.SMS.num = 1;
      } else {
        this.SMS.num = Math.ceil((this.SMS.totalNum - 70) / 67) + 1;
      }
    },
    groupList(val) {
      //群组列表
      if (val.length == 0) {
        this.$confirm("群组列表为空，请先跳转至 潜客筛选 页面!", "提示", {
          confirmButtonText: "跳转",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          //跳转操作
          this.$router.push("/passengerSelect");
        });
      }
    },
    hide(val) {
      //定时隐藏
      if (!val) {
        this.time = "";
      }
    },
  },
  mounted() {
    // this.getUserInfo();
    // this.getUserBalance()
    this.getSignList(
      //签名列表
      {
        platform: this.platform,
      }
    );
    this.getShowMockMenu(); //模拟发送是否显示
  },
  methods: {
    // 模拟发送是否显示
    getShowMockMenu() {
      this.$http("/showMockMenu", null, "get", null, null).then((res) => {
        if (res.result === 200) {
          this.showMockMenu = res.data.showMockMenu;
        }
      });
    },
    // 选择平台
    platformChange(val) {
      this.platform = val;
      this.getSignList({
        platform: val,
      });
    },
    // 是否模拟发送
    mockChange(val) {
      this.mock = val;
    },
    // 点击插入短链
    shortChain() {
      this.shortChainShow = true;
    },
    // 长链输入变化
    longChainChange(longChain) {
      let index = this.textarea.indexOf(" #短链# ");
      if (index !== -1) {
        //有短信内容 #短链#
        let textFront = this.textarea.substr(0, index);
        let textAfter = this.textarea.substr(index + 6);
        this.textarea = textFront + textAfter;
      }
    },
    // 确定插入短链
    shortChain_submit() {
      if (this.longChain) {
        let textarea = this.textarea;
        this.textarea = textarea + " #短链# ";
        this.shortChainShow = false;
      } else {
        this.$message({ message: "请输入长链！", type: "warning" });
      }
    },
    // 点击立即发送
    sendNow() {
      this.uploadData.content = this.sign + this.textarea;
      this.uploadData.msgName = this.msgName;
      this.uploadData.longUrl = this.longChain;
      this.uploadData.activitySubCode = this.activitySubCode;
      this.uploadData.platform = this.platform;
      this.uploadData.mock = this.mock;
      if (this.fileList.length < 1) {
        this.$message({ message: "号码不能为空！", type: "warning" });
        return;
      }
      if (!this.msgName) {
        this.$message({ message: "活动名称不能为空！", type: "warning" });
        return;
      }
      if (!this.activitySubCode) {
        this.$message({ message: "活动批次不能为空！", type: "warning" });
        return;
      }
      if (!this.platform) {
        this.$message({ message: "平台不能为空！", type: "warning" });
        return;
      }
      if (!this.sign) {
        this.$message({ message: "签名不能为空！", type: "warning" });
        return;
      }
      if (!this.textarea) {
        this.$message({ message: "短信内容不能为空！", type: "warning" });
        return;
      }
      this.isUpload = true;
      let title = "立即发送短信, 是否继续?";
      if (this.mock) {
        title = "此次发送为模拟发送, 不会真实发送短信。是否继续?";
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          this.$refs.upload.submit();
        })
        .catch(() => {
          this.isUpload = false;
        });
    },
    // 点击定时发送
    sendTiming() {
      this.sendTimingShow = true;
    },
    // 确定定时发送
    sendTiming_submit() {
      this.uploadData.content = this.sign + this.textarea;
      this.uploadData.sendTime = this.time;
      this.uploadData.msgName = this.msgName;
      this.uploadData.longUrl = this.longChain;
      this.uploadData.activitySubCode = this.activitySubCode;
      this.uploadData.platform = this.platform;
      this.uploadData.mock = this.mock;
      if (this.fileList.length < 1) {
        this.$message({ message: "号码不能为空！", type: "warning" });
        return;
      }
      if (!this.msgName) {
        this.$message({ message: "活动名称不能为空！", type: "warning" });
        return;
      }
      if (!this.activitySubCode) {
        this.$message({ message: "活动批次不能为空！", type: "warning" });
        return;
      }
      if (!this.platform) {
        this.$message({ message: "平台不能为空！", type: "warning" });
        return;
      }
      if (!this.sign) {
        this.$message({ message: "签名不能为空！", type: "warning" });
        return;
      }
      if (!this.textarea) {
        this.$message({ message: "短信内容不能为空！", type: "warning" });
        return;
      }
      if (!this.time) {
        this.$message({ message: "时间不能为空！", type: "warning" });
        return;
      }
      this.sendTimingShow = false;
      this.isUpload = true;
      let title = "定时发送短信, 是否继续?";
      if (this.mock) {
        title = "此次发送为模拟发送, 不会真实发送短信。是否继续?";
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          this.$refs.upload.submit();
        })
        .catch(() => {
          this.isUpload = false;
        });
    },
    // 关闭定时发送
    sendTimingClose() {
      this.time = "";
    },
    // 上传号码change时
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    // 上传号码remove时
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 号码上传成功
    successUpload(res, file, fileList) {
      this.$refs.upload.clearFiles();
      if (res.result == 200) {
        this.isUpload = false;
        this.textarea = "";
        this.msgName = "";
        this.longChain = "";
        this.platform = "YUNFENG";
        this.mock = false;
        this.$message({ message: "短信发送成功！", type: "success" });
        this.$router.push({ path: "/smmSmarketingRecords" });
      }
    },
    // 号码上传失败
    errorUpload(err, file, fileList) {
      this.isUpload = false;
      this.$message({ message: "短信发送失败！", type: "error" });
    },
    //限制上传文件数量提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },

    getUserInfo() {
      //用户信息
      let data = {
        pageNo: 1,
        pageSize: 1000,
        queryType: "SMS_SEND",
      };
      this.$http("/sms/macGroupList", data).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          if (list.length) {
            let id = this.$route.query.groupId;
            if (id) {
              list.map((item) => {
                if (item.id == id) {
                  this.groupId = item.id; // id
                  this.info.passengerNum = item.totalCount + "人"; // 潜客数
                  this.info.expense =
                    (item.estimateFee / 100).toFixed(2) + "元"; // 费用估计
                }
              });
            } else {
              this.groupId = list[0].id; // id
              this.info.passengerNum = list[0].totalCount + "人"; // 潜客数
              this.info.expense = (list[0].estimateFee / 100).toFixed(2) + "元"; // 费用估计
            }
            this.groupList = list;
          }
        }
      });
    },
    getUserBalance() {
      //用户余额
      this.$http("/user/userInfo", {}).then((res) => {
        if (res.result == 200) {
          this.info.balance = (res.data.balance / 100).toFixed(2) + "元"; //账户余额
        }
      });
    },
    UseTemplate() {
      //使用模板
      let data = {
        pageNo: 1,
        pageSize: 15,
      };
      this.$http("/sms/recentUsedTemplateList", data).then((res) => {
        if (res.result == 200) {
          let list = res.data,
            obj = {},
            arr = [];
          list.map((item) => {
            obj = {};
            obj.template = item;
            arr.push(obj);
          });
          this.templateList = arr;
          this.template = true;
        }
      });
    },
    template_checked(val) {
      //模板选中
      this.textarea = val.template;
      this.template = false;
    },
    getSignList(data) {
      //获取签名列表
      this.$http("/sms/short/url/sign/list", null, "get", null, data).then(
        (res) => {
          if (res.result == 200) {
            let list = res.data;
            let signList = [];
            list.forEach((item) => {
              signList.push({
                sign: "【" + item.sign.trim() + "】",
                label: item.sign.trim(),
              });
            });
            this.signList = signList;
          }
        }
      );
    },
    sighChange(sign) {
      //获取签名长度
      this.SMS.signLength = sign.length;
      this.SMS.totalNum = this.SMS.signLength + this.SMS.contentNum;
    },
    selected(id) {
      //群组列表选中
      this.groupList.map((item) => {
        if (item.id == id) {
          this.info.passengerNum = item.totalCount + "人"; //潜客数
          this.info.expense = (item.estimateFee / 100).toFixed(2) + "元"; //费用估计
        }
      });
    },
    signManager_cancel() {
      //取消签名
      this.addSign = {
        signName: "",
        scene: "",
      };
      this.signManager = false;
    },
    signManager_submit() {
      //添加签名
      if (this.addSign.scene != "" && this.addSign.signName != "") {
        let data = {
          sign: this.addSign.signName,
          remark: this.addSign.scene,
        };
        this.$http("/sms/addSign", data).then((res) => {
          if (res.result == 200) {
            this.$message({
              message:
                "提交成功，请尽快联系客服进行后台审核，审核通过后才能使用!",
              type: "success",
            });
            this.signManager_cancel();
            this.getSignList();
          }
        });
      } else {
        this.$message({ message: "信息填写不完整！", type: "warning" });
      }
    },
    sendMessage() {
      //发送短信
      this.signList.map((item) => {
        if (item.id == this.sign) {
          let sign = item.sign.split("-")[0];
          sign = sign.trim();
        }
      });
      let data = {
        macGroupId: this.groupId,
        excludeSmsSended: this.filter,
        smsSignId: this.sign,
        content: sign + this.textarea,
        sendTime: this.time,
      };
      this.$http("/sms/addMsgPlan", data).then((res) => {
        if (res.result == 200) {
          //发送成功
          this.$message({ message: "短信发送成功！", type: "success" });
          this.$router.push("/SMSmarketingRecord");
        }
      });
    },
    // sendNow(){	//发送短信
    // 	if(this.textarea!=''){
    // 		if(this.SMS.num>1){//短信 >1
    // 			this.$confirm('短信字数超限，将收取'+this.SMS.num+'倍费用，是否继续？', '提示', {
    // 				confirmButtonText: '确认',
    // 				cancelButtonText: '取消',
    // 				type: 'warning'
    // 			}).then(() => {//确认
    // 				let expense = this.info.expense.substr(0,this.info.expense.length-1)- 0,
    // 					balance = this.info.balance.substr(0,this.info.balance.length-1) - 0;
    // 				if(expense*this.SMS.num > balance){
    // 					this.$message({message:'账户余额不足，请联系客服充值！',type:'warning'});
    // 				}else {
    // 					this.sendMessage();
    // 				}
    // 			}).catch(()=>{//取消

    // 			});
    // 		}else {//1条短信
    // 			this.sendMessage();
    // 		}

    // 	}else {
    // 		this.$message({message: '短信内容不能为空！',type: 'warning'});
    // 	}
    // }
  },
};
</script>
<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>

<style scoped="scoped">
.upload-demo {
  width: 360px;
}
.operation-btn {
  position: relative;
}
.SMSmarketing {
  position: relative;
}
.el-select {
  width: 400px;
}
.showInfo {
  width: 800px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  margin: 25px 0;
}
.showInfo li {
  width: 12%;
  min-width: 200px;
  min-height: 80px;
  border-radius: 3px;
  padding: 20px 0px 0px 20px;
  font-size: 40px;
  color: #34bfa3;
  box-shadow: 0px 0px 1px 1px lightgray;
  position: relative;
}
.showInfo li em {
  display: block;
  width: 48%;
  font-size: 16px;
  font-style: normal;
  color: #8f8f8f;
  float: right;
}
.showInfo .new em {
  width: 50%;
}
/* .num{
		display:block;
		width: 45%;
		vertical-align: 8%;
		font-style: normal;
		font-size: 18px;
		font-weight: 300;
		color:#666666;
		position: absolute;
		left: 56%;
		top:50%;
	} */
.new i {
  left: 55%;
}
.el-radio {
  margin: 20px 0;
}
.sign-input .el-input {
  width: 400px;
}
.sign {
  width: 600px;
}
.sign a {
  color: #3a8ee6;
  font-size: 14px;
  margin-left: 15px;
}
.smsContent {
  width: 650px;
  margin-top: 20px;
  position: relative;
}
.smsContent .editor {
  position: absolute;
  top: 5px;
  left: 90px;
  color: #3a8ee6;
  font-size: 14px;
}

.smsContent h4 {
  margin: 5px 0px 5px 0px;
}
.template {
  float: right;
  font-size: 14px;
  margin-bottom: 5px;
  color: #3a8ee6;
}

.word {
  display: block;
  width: 100%;
  line-height: 40px;
  text-align: right;
  font-style: normal;
  font-size: 12px;
}
.word i {
  color: red;
  font-style: normal;
}
.el-checkbox {
  margin: 20px 0px 30px;
  font-size: 18px;
}
/* .smsContent	.el-button{
		width: 120px;
	} */

.time {
  width: 200px;
  margin-left: 20px;
}
.el-signManager {
  position: relative;
}

.el-signManager .form .el-form-item {
  margin-bottom: 10px;
}
.el-signManager .editor {
  position: absolute;
  top: 58px;
  left: 50px;
  color: #3a8ee6;
}
.el-signManager b {
  display: block;
  color: #657180;
  font-size: 17px;
}
.el-signManager .el-input,
.el-signManager .el-textarea {
  width: 400px;
}
.el-signManager .signName i {
  display: block;
  font-style: normal;
  font-size: 12px;
}
/* .el-signManager .el-button{
		width: 120px;
	} */
.el-template {
  font-size: 12px;
}

.el-template .table >>> td {
  padding: 0;
}
</style>
