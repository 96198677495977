<template>
  <div class="dblclick_menu">
    <el-dialog
      :title="title"
      :visible.sync="visibleShow"
      width="600px"
      @close="dialogClose"
    >
      <el-form label-position="left" label-width="100px">
        <!-- <el-form-item label="节点名称:" required>
          <el-input
            v-model="form.nodeName"
            placeholder="请输入节点名称"
            clearable
            style="width: 90%;"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="机器人话术:" v-if="node.type === 'NORMAL'">
          <div
            v-for="(item, index) in form.speech"
            :key="item.id"
            class="ef-speech-cc-mm"
          >
            <span class="fzhs-cc-mm" v-if="index > 0"
              >辅助话术{{ index }}:</span
            >
            <el-input
              v-model="item.content"
              :placeholder="'请输入此节点需要播放的话术' + (index + 1)"
              type="textarea"
              :rows="4"
              maxlength="150"
              show-word-limit
              style="margin-bottom: 10px; width: 90%"
            ></el-input>
            <i
              v-if="index > 0"
              class="el-icon-remove-outline remove-cc-mm"
              @click="deleteSpeechClick(item)"
            >
            </i>
          </div>
          <div v-if="form.speech.length > 1" style="color: #e6a23c">
            辅助话术{{ form.speech.length - 1 }}播放完后将直接挂机
          </div>
          <div>
            <el-button
              icon="el-icon-plus"
              size="mini"
              type="warning"
              @click="addSpeechClick"
              >增加辅助话术</el-button
            >
            <span style="padding-left: 10px">
              为什么需要添加多个话术？
              <el-tooltip placement="top">
                <div slot="content" style="width: 300px">
                  辅助话术是主话术播放完，再次返回当前节点时机器人播放的话术，最后一次辅助话术播放完后会直接挂机。注：再次返回当前节点是通过“知识库中跳转至主线流程”或者“流程中分支流转方式”触发。
                </div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="用户回答:" v-if="node.type === 'NORMAL'">
          <div>
            <span v-for="item in anyAnswerBranchList" :key="item.id">
              <el-checkbox-group v-model="form.anyAnswerCheckList">
                <el-checkbox :label="item.name">{{ item.name }}</el-checkbox>
              </el-checkbox-group>
            </span>
          </div>
          <div>默认回答意图</div>
          <div>
            <el-checkbox-group
              v-model="form.defaultCheckList"
              :disabled="oneBranchForAll"
            >
              <div
                v-for="item in defaultList"
                :key="item.id"
                class="checkbox-cc-mm"
                @mouseover="mouseoverDefaultCheck(item)"
                @mouseout="defaultCheck = ''"
              >
                <el-checkbox :label="item.name">{{ item.name }}</el-checkbox>
                <span v-show="item.id === defaultCheck">
                  <i style="display: inline-block; margin: 0 10px"></i>
                  <i
                    class="el-icon-edit icon-cc-mm"
                    @click="branchClick(item, 'modify')"
                  ></i>
                </span>
              </div>
            </el-checkbox-group>
          </div>
          <div>自定义回答意图</div>
          <div>
            <el-checkbox-group
              v-model="form.customCheckList"
              :disabled="oneBranchForAll"
            >
              <div
                v-for="item in customList"
                :key="item.id"
                class="checkbox-cc-mm"
                @mouseover="mouseoverCustomCheck(item)"
                @mouseout="customCheck = ''"
              >
                <el-checkbox :label="item.name">{{ item.name }}</el-checkbox>
                <span v-show="item.id === customCheck">
                  <i style="display: inline-block; margin: 0 10px"></i>
                  <el-popconfirm
                    title="确定删除吗？"
                    @confirm="deleteBranch(item)"
                  >
                    <i
                      slot="reference"
                      class="el-icon-delete icon-cc-mm"
                      style="margin-right: 10px"
                    ></i>
                  </el-popconfirm>
                  <i
                    class="el-icon-edit icon-cc-mm"
                    @click="branchClick(item, 'modify')"
                  ></i>
                </span>
              </div>
            </el-checkbox-group>
          </div>
          <div>
            <el-button
              icon="el-icon-plus"
              size="mini"
              type="warning"
              @click="branchClick(null, 'add')"
              >增加意图</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="机器人话术:" v-if="node.type === 'REDIRECT'">
          <div
            v-for="(item, index) in form.speech"
            :key="item.id"
            class="ef-speech-cc-mm"
          >
            <el-input
              v-model="item.content"
              placeholder="进入此节点后播放的话术"
              type="textarea"
              :rows="4"
              maxlength="150"
              show-word-limit
              style="margin-bottom: 10px; width: 90%"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          label="机器人话术:"
          v-if="node.type === 'HANGUP' || node.type === 'ADD_WECHAT'"
        >
          <div
            v-for="(item, index) in form.speech"
            :key="item.id"
            class="ef-speech-cc-mm"
          >
            <el-input
              v-model="item.content"
              placeholder="进入此节点后播放的话术"
              type="textarea"
              maxlength="150"
              show-word-limit
              :rows="4"
              style="margin-bottom: 10px; width: 90%"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="下一步:" required v-if="node.type === 'REDIRECT'">
          <el-select
            v-model="form.implementId"
            placeholder="请选择要执行的动作"
            @change="implementIdChange"
            style="width: 90%"
          >
            <el-option
              v-for="item in implementList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form.nextScenarioId"
            placeholder="请选择要跳转的流程节点"
            style="width: 90%; margin-top: 10px"
          >
            <el-option
              v-for="item in flowNodeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="分组设置:"
          required
          v-if="node.type === 'ADD_WECHAT'"
        >
          <el-select
            v-model="form.flagWechatServer"
            placeholder="请选择服务器"
            @change="flagWechatServerChange(form.flagWechatServer)"
            style="width: 90%"
          >
            <el-option
              v-for="item in wechatServerList"
              :key="item.server"
              :label="item.server"
              :value="item.server"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form.flagWechatClientGroupId"
            placeholder="请选择机器分组"
            style="width: 90%; margin-top: 10px"
          >
            <el-option
              v-for="item in wechatClientGroupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            标签追踪
            <el-tooltip placement="top">
              <div slot="content" style="width: 300px">
                添加标签后，通话结果详情中会自动记录命中到的标签，更好地支持个性化的数据统计与分析。
              </div>
              <i class="el-icon-question"></i> </el-tooltip
            >:
          </template>
          <el-input
            v-model="form.data_sign"
            placeholder="可增加标签方便后续数据统计"
            clearable
            style="width: 90%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="dialog_submit">确定</el-button>
        <el-button @click="dialogClose">取消</el-button>
      </div>
    </el-dialog>
    <!-- 增加意图 -->
    <el-dialog
      :title="branchTitle"
      :visible.sync="branchShow"
      width="500px"
      @close="branchClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="意图名称:" required>
          <el-input
            v-model="branchForm.name"
            placeholder="请输入意图名称"
            clearable
            :readonly="branchTitle === '编辑意图'"
          ></el-input>
        </el-form-item>
        <el-form-item label="关键词:" v-if="!unknownAnswer">
          <el-input
            v-model="branchForm.keyWord"
            placeholder="请输入关键词并回车（多个关联词中间用｜分隔）"
            @keyup.enter.native="addKeyWord(branchForm.keyWord)"
            clearable
          ></el-input>
          <!-- 提示 -->
          <div class="tag-cc-mm">
            <span class="text-color-ff">匹配到该意图的关键词</span>
            <span class="text-color-mm">(优先推荐使用关键词)</span>
          </div>
          <!-- 关键词 -->
          <div class="tag-cc-mm">
            <el-tag
              v-for="(item, index) in branchForm.keyWordList"
              :key="index"
              size="mini"
              closable
              @close="deleteKeyword(item)"
              >{{ item }}</el-tag
            >
          </div>
          <!-- 操作 -->
          <div class="tag-cc-mm">
            <div v-if="branchForm.keyWordList.length > 0">
              <el-button type="text" class="text-color-mm" v-copy="copyKeyWord"
                >复制全部关键此</el-button
              >
              <el-button
                type="text"
                class="text-color-mm"
                @click="deleteAllKeyword"
                >删除全部关键词</el-button
              >
            </div>
            <span v-else class="text-color-ff">暂无关键词</span>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="branch_submit"
          :loading="loading"
          :disabled="branchDisabled"
          >确定</el-button
        >
        <el-button @click="branchShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
export default {
  name: "dblclick_menu",
  props: {
    activeSpeechId: [String, Number],
    flowNodeList: Array,
  },
  data() {
    return {
      loading: false,
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
      ],
      wechatClientGroupList: [],
      implementList: [
        {
          id: 1,
          name: "指定主流程",
        },
      ],
      defaultCheck: "",
      customCheck: "",
      defaultList: [],
      branchForm: {
        name: "",
        keyWord: "",
        keyWordList: [],
      },
      modifyBranchId: "",
      branchTitle: "",
      branchShow: false,
      visibleShow: false,
      title: "",
      form: {
        nodeName: "",
        data_sign: "",
        speech: [],
        defaultCheckList: [],
        customCheckList: [],
        anyAnswerCheckList: [],
        keyWordData: {},
        nextScenarioId: "",
        implementId: 1,
        flagWechatServer: "",
        flagWechatClientGroupId: "",
      },
      customList: [],
      anyAnswerBranchList: [],
      node: {},
      addSpeechId: 5,
      unknownAnswer: false,
    };
  },
  computed: {
    copyKeyWord() {
      let keyWord = this.branchForm.keyWordList.join("|");
      return keyWord;
    },
    oneBranchForAll() {
      if (this.form.anyAnswerCheckList.length > 0) {
        this.form.defaultCheckList = [];
        this.form.customCheckList = [];
        return true;
      }
      return false;
    },
    branchDisabled() {
      if (!this.branchForm.keyWord) {
        return false;
      }
      return true;
    },
  },
  mounted() {},
  methods: {
    // 选择服务器
    flagWechatServerChange(val) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      this.form.flagWechatClientGroupId = "";
      this.getWechatClientGroupList(data);
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatClientGroupList = list;
          if (this.node.wechatClientGroupId) {
            this.form.flagWechatClientGroupId = this.node.wechatClientGroupId;
          }
        }
      });
    },
    // 点击新增话术
    addSpeechClick() {
      if (this.form.speech.length >= 4) {
        this.$message.warning("已达到4个上限，请删除重试");
        return;
      }
      this.addSpeechId++;
      let obj = {
        id: this.addSpeechId,
        content: "",
      };
      this.form.speech.push(obj);
    },
    // 点击删除话术
    deleteSpeechClick(val) {
      let index = -1;
      index = this.form.speech.indexOf(val);
      if (index !== -1) {
        this.form.speech.splice(index, 1);
      }
    },
    // 选择下一步
    implementIdChange(id) {
      this.form.nextScenarioId = "";
    },
    // 点击删除意图
    deleteBranch(val) {
      let data = {
        id: val.id,
      };
      this.$service("/dialogueBranch/deleteDialogueBranch", data).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.getListDialogueBranch();
          }
        }
      );
    },
    // 点击增加意图
    branchClick(val, key) {
      if (key === "add") {
        this.branchTitle = "新建意图";
      }
      if (key === "modify") {
        this.branchTitle = "编辑意图";
        this.unknownAnswer = val.unknownAnswer;
        this.branchForm.name = val.name;
        this.modifyBranchId = val.id;
        let keyWordData = cloneDeep(this.form.keyWordData);
        for (let i in keyWordData) {
          if (val.name == i) {
            keyWordData[i]
              ? (this.branchForm.keyWordList = keyWordData[i].split("|"))
              : (this.branchForm.keyWordList = []);
          }
        }
      }
      this.branchShow = true;
    },
    // 确定增加意图
    branch_submit() {
      let data = {
        dialogueId: this.activeSpeechId,
        name: this.branchForm.name,
        keyword: this.copyKeyWord,
        dialogueNodeId: this.node.id,
      };
      if (!this.branchForm.name) {
        this.$message.warning("请输入意图名称");
        return;
      }
      if (this.branchTitle === "新建意图") {
        let dialogueNodeBranchList = [
          ...this.customList,
          ...this.defaultList,
          ...this.anyAnswerBranchList,
        ];
        for (let i in dialogueNodeBranchList) {
          let item = dialogueNodeBranchList[i];
          if (item.name === this.branchForm.name) {
            this.$message.warning("意图已存在");
            return;
          }
        }
        this.loading = true;
        this.$service("/dialogueBranch/addDialogueBranch", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.getListDialogueBranch();
              this.branchShow = false;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.branchTitle === "编辑意图") {
        data.id = this.modifyBranchId;
        this.loading = true;
        this.$service("/dialogueBranch/updateDialogueBranch", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("编辑成功");
              this.getListDialogueBranch();
              this.branchShow = false;
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭增加意图
    branchClose() {
      this.branchForm = {
        name: "",
        keyWord: "",
        keyWordList: [],
      };
      this.unknownAnswer = false;
    },
    // 添加关键词
    addKeyWord(tag) {
      if (tag) {
        this.branchForm.keyWord = "";
        let tagList = tag.split("|");
        let keyWordList = [...this.branchForm.keyWordList, ...tagList];
        this.branchForm.keyWordList = this.$common.unique(keyWordList);
      }
    },
    // 点击删除关键词
    deleteKeyword(tag) {
      this.branchForm.keyWordList.splice(
        this.branchForm.keyWordList.indexOf(tag),
        1
      );
    },
    // 点击删除全部关键词
    deleteAllKeyword() {
      this.branchForm.keyWordList = [];
    },
    // 鼠标移入
    mouseoverDefaultCheck(val) {
      this.defaultCheck = val.id;
    },
    // 鼠标移入
    mouseoverCustomCheck(val) {
      this.customCheck = val.id;
    },
    //初始化
    init(nodeId) {
      this.getDialogueNodeDetail(nodeId);
    },
    // 查询节点信息
    getDialogueNodeDetail(nodeId) {
      let data = {
        dialogueId: this.activeSpeechId,
        dialogueNodeId: nodeId,
      };
      this.$http(
        "/dialogueNode/dialogueNodeDetail",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let node = res.data;
          this.node = cloneDeep(node);
          this.form.nodeName = node.name;
          this.form.data_sign = node.dataSign;
          if (
            node.dialogueNodeContentList &&
            node.dialogueNodeContentList.length > 0
          ) {
            node.dialogueNodeContentList.forEach((item, index) => {
              this.form.speech.push({
                id: index + 1,
                content: item.content,
              });
            });
          } else {
            this.form.speech = [
              {
                id: 1,
                content: "",
              },
            ];
          }
          if (node.type === "NORMAL") {
            this.title = "普通节点";
            this.getListDialogueBranch();
          }
          if (node.type === "REDIRECT") {
            this.title = "跳转节点";
            if (node.nextScenarioId) {
              this.form.nextScenarioId = node.nextScenarioId;
            }
          }
          if (node.type === "HANGUP") {
            this.title = "挂机节点";
          }
          if (node.type === "ADD_WECHAT") {
            this.title = "加微信";
            this.form.flagWechatServer = node.wechatServer;
            this.flagWechatServerChange(this.form.flagWechatServer);
          }
          this.visibleShow = true;
        }
      });
    },
    // 列举话术所有回答意图
    getListDialogueBranch() {
      let data = {
        dialogueId: this.activeSpeechId,
        dialogueNodeId: this.node.id,
      };
      this.$http(
        "/dialogueBranch/listDialogueBranch",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let branchData = res.data;
          // this.form.data_sign = branchData.data_sign;
          // this.form.data_sign = branchData.dataSign;
          this.form.keyWordData = {};
          this.defaultList = [];
          this.customList = [];
          this.anyAnswerBranchList = [];
          for (let i in branchData) {
            if (i === "defaultBranch") {
              if (branchData[i]) {
                this.defaultList = branchData[i];
              }
            }
            if (i === "customerBranch") {
              if (branchData[i]) {
                this.customList = branchData[i];
              }
            }
            if (i === "anyAnswerBranch") {
              if (branchData[i]) {
                this.anyAnswerBranchList = branchData[i];
              }
            }
            for (let j in branchData[i]) {
              let item = branchData[i][j];
              this.form.keyWordData[item.name] = item.keyword;
            }
          }
          this.form.defaultCheckList = [];
          this.form.customCheckList = [];
          this.form.anyAnswerCheckList = [];
          for (let i in this.node.dialogueNodeBranchList) {
            for (let j in this.defaultList) {
              if (
                this.node.dialogueNodeBranchList[i].name ===
                this.defaultList[j].name
              ) {
                this.form.defaultCheckList.push(
                  this.node.dialogueNodeBranchList[i].name
                );
              }
            }
            for (let j in this.customList) {
              if (
                this.node.dialogueNodeBranchList[i].name ===
                this.customList[j].name
              ) {
                this.form.customCheckList.push(
                  this.node.dialogueNodeBranchList[i].name
                );
              }
            }
            for (let j in this.anyAnswerBranchList) {
              if (
                this.node.dialogueNodeBranchList[i].name ===
                this.anyAnswerBranchList[j].name
              ) {
                this.form.anyAnswerCheckList.push(
                  this.node.dialogueNodeBranchList[i].name
                );
              }
            }
          }
        }
      });
    },
    // 确定
    dialog_submit() {
      // if (!this.form.nodeName) {
      //   this.$message.warning("请输入节点名称");
      //   return
      // }
      this.node.name = this.form.nodeName;
      this.node.data_sign = this.form.data_sign;
      this.node.dialogueNodeContentList = [];
      this.form.speech.forEach((item) => {
        if (item.content) {
          this.node.dialogueNodeContentList.push({
            content: item.content,
          });
        }
      });
      if (this.node.type === "NORMAL") {
        if (this.node.dialogueNodeContentList.length < 1) {
          this.$message.warning("请输入机器人话术");
          return;
        }
        let dialogueNodeBranchList = [],
          allBranchList = [
            ...this.customList,
            ...this.defaultList,
            ...this.anyAnswerBranchList,
          ];
        let itemIds = [
          ...this.form.anyAnswerCheckList,
          ...this.form.defaultCheckList,
          ...this.form.customCheckList,
        ];
        for (let j in allBranchList) {
          let menu = allBranchList[j];
          for (let i in itemIds) {
            let item = itemIds[i];
            if (menu.name === item) {
              dialogueNodeBranchList.push({
                id: this.node.id + "_" + menu.id,
                name: item,
              });
            }
          }
        }
        this.node.dialogueNodeBranchList = dialogueNodeBranchList;
      }
      if (this.node.type === "REDIRECT") {
        if (!this.form.nextScenarioId) {
          this.$message.warning("请选择下一步");
          return;
        }
        this.node.nextScenarioId = this.form.nextScenarioId;
      }
      if (this.node.type === "ADD_WECHAT") {
        if (!this.form.flagWechatClientGroupId) {
          this.$message.warning("请选择分组设置");
          return;
        }
        this.node.wechatServer = this.form.flagWechatServer;
        this.node.wechatClientGroupId = this.form.flagWechatClientGroupId;
      }
      this.$emit("submit", this.node);
      this.dialogClose();
    },
    // 关闭
    dialogClose() {
      this.form = {
        nodeName: "",
        data_sign: "",
        defaultCheckList: [],
        customCheckList: [],
        anyAnswerCheckList: [],
        keyWordData: {},
        nextScenarioId: "",
        implementId: 1,
        flagWechatServer: "",
        flagWechatClientGroupId: "",
        speech: [],
      };
      this.visibleShow = false;
      this.addSpeechId = 5;
    },
  },
};
</script>