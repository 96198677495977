<template>
  <div class="commonConfig">
    <div class="nav">
      <div class="batchName">
        配置名称：<el-input
          v-model="entryName"
          placeholder="请输入配置名称"
          clearable
        ></el-input>
      </div>
      <div class="batchName">
        配置值：<el-input
          v-model="entryValue"
          placeholder="请输入配置值"
          clearable
        ></el-input>
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button type="primary" @click="addConfig">新增</el-button>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
    >
      <el-table-column
        prop="id"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="entryName"
        label="配置名称"
        width
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="entryValue"
        label="配置值"
        width
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column type prop label="状态" width="70" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.enabled === true" type="success" size="small"
            >启用</el-tag
          >
          <el-tag v-if="scope.row.enabled === false" type="danger" size="small"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="180"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="90"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="deleteCommon(scope.row)"
            style="color: #ff0000b0"
            >删除</el-button
          >
          <el-button type="text" size="small" @click="modifyClick(scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增 -->
    <el-dialog
      :visible.sync="addConfigShow"
      custom-class="el-addProject"
      @close="addConfigClose"
      width="500px"
      class="addProjectClass"
    >
      <template slot="title">
        <div v-if="addTitle === '新增'">
          <i
            class="el-icon-circle-plus-outline dialog-title-my"
            style="color: #1296db"
          ></i>
          <span class="dialog-title-my my-title">{{ addTitle }}</span>
        </div>
        <div v-if="addTitle === '修改'">
          <i
            class="el-icon-edit-outline dialog-title-my"
            style="color: #1296db"
          ></i>
          <span class="dialog-title-my my-title">{{ addTitle }}</span>
        </div>
      </template>
      <el-form label-position="left" label-width="105px">
        <el-form-item label="配置名称	：" required>
          <el-input
            v-model="addForm.entryName"
            placeholder="请输入配置名称	"
          ></el-input>
        </el-form-item>
        <el-form-item label="配置值	：" required>
          <el-input
            v-model="addForm.entryValue"
            placeholder="请输入配置值	"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="addForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="addForm.enabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="addConfig_submit()" :loading="loading"
          >确定</el-button
        >
        <el-button @click="addConfigShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "commonConfig",
  data() {
    return {
      addConfigShow: false,
      addTitle: "",
      entryName: "",
      entryValue: "",
      addForm: {
        id: "",
        entryName: "",
        entryValue: "",
        remark: "",
        enabled: true,
      },
      loading: false,
      tableData: [],
      currentPage: null,
      total: null,
      pageSize: 10,
    };
  },
  mounted() {
    // document.title = "惠销平台管理系统-公共配置";
    this.inquire();
  },
  methods: {
    inquire() {
      //查询
      this.currentPage = 1;
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        entryName: this.entryName,
        entryValue: this.entryValue,
      };
      this.inquirelabelList(params);
    },
    inquirelabelList(params) {
      this.loading = true;
      this.$http("/commonConfig/list", null, "get", null, params).then(
        (res) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (res.result == 200) {
            this.total = res.data.total;
            this.tableData = res.data.records;
          }
        }
      );
    },
    currentPage_data(index) {
      //当前页
      this.currentPage = index;
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };
      this.inquirelabelList(params);
    },
    sizeChange(index) {
      //当前页
      this.currentPage = 1;
      this.pageSize = index;
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
      };
      this.inquirelabelList(params);
    },
    // 新增
    addConfig() {
      this.addTitle = "新增";
      this.addConfigShow = true;
    },
    // 修改
    modifyClick(val) {
      this.addTitle = "修改";
      this.addForm = {
        id: val.id,
        entryName: val.entryName,
        entryValue: val.entryValue,
        remark: val.remark,
        enabled: val.enabled,
      };
      this.addConfigShow = true;
    },
    // 确定新增
    addConfig_submit() {
      let data = {
        entryName: this.addForm.entryName,
        entryValue: this.addForm.entryValue,
        remark: this.addForm.remark,
        enabled: this.addForm.enabled,
      };
      if (!data.entryName) {
        this.$message.warning("请输入配置名称");
        return;
      }
      if (!data.entryValue) {
        this.$message.warning("请输入配置值");
        return;
      }
      this.loading = true;
      if (this.addTitle === "新增") {
        this.$service("/commonConfig/add", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.addConfigShow = false;
              this.inquire(1);
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
      if (this.addTitle === "修改") {
        data.id = this.addForm.id;
        this.$service("/commonConfig/update", data)
          .then((res) => {
            this.loading = false;
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.addConfigShow = false;
              this.inquire();
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 关闭新增
    addConfigClose() {
      this.addForm = {
        id: "",
        entryName: "",
        entryValue: "",
        remark: "",
        enabled: true,
      };
    },
    deleteCommon(val) {
      //删除
      this.$confirm("【" + val.entryName + "】删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: val.id,
          };
          this.$http("/commonConfig/delete", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.inquire();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.el-modifyLabelShow .el-input {
  width: 250px;
}
.upload-demo >>> .el-upload-dragger {
  width: 320px !important;
}
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-date-picker {
  width: 260px;
  margin-right: 20px;
}
</style>