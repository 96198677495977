<template>
  <div 
    style="height: calc(100vh - 140px)"
    @click="panelClick"
    @contextmenu="panelContextmenu"
  >
    <div>
      <i class="el-icon-back ef-icon-back-cc-mm" @click="goBackClick"></i>
      <span class="ef-icon-back-text-mm">{{ activeSpeechElementName }}</span>
    </div>
    <div style="height: calc(100% - 35px)">
      <el-row>
        <!--顶部工具菜单-->
        <el-col :span="24">
          <div class="ef-tooltar">
            <!-- <el-link type="primary" :underline="false">主线流程</el-link> -->
            <!-- <el-divider direction="vertical"></el-divider> -->
            <el-button
              type="text"
              icon="el-icon-delete"
              size="large"
              @click="deleteElement"
              :disabled="!this.activeElement.type"
            ></el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button
              type="text"
              icon="el-icon-plus"
              size="large"
              @click="zoomAdd"
            ></el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button
              type="text"
              icon="el-icon-minus"
              size="large"
              @click="zoomSub"
            ></el-button>
            <div style="float: right; margin-right: 5px">
              <!-- <el-button
                type="info"
                plain
                round
                icon="el-icon-document"
                @click="dataInfo"
                size="mini"
                >流程信息</el-button
              > -->
              <el-button
                type="success"
                plain
                round
                icon="el-icon-document-checked"
                @click="conserveClick"
                size="mini"
                >保存</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="display: flex; height: calc(100% - 45px); background-color: rgb(248, 248, 249);">
        <!-- 节点栏 -->
        <div class="flow-cc-mm-ii">
          <node-menu @addNode="addNode" ref="nodeMenu"></node-menu>
        </div>
        <!-- 绘图栏 -->
        <div 
          id="efContainer" 
          ref="efContainer" 
          class="container" 
          style="background-color: #f8f8f9;" 
          v-flowDrag
          v-if="easyFlowVisible"
        >
          <template v-for="node in data.dialogueNodeList">
            <flow-node
              :id="node.id"
              :key="node.id"
              :node="node"
              :activeElement="activeElement"
              :flowNodeList="flowNodeList"
              @changeNodeSite="changeNodeSite"
              @nodeRightMenu="nodeRightMenu"
              @onDblClick="onDblClick"
              @modifyNode="modifyNodeClick"
              @deleteNode="deleteNode_click"
              @clickNode="clickNode"
            >
            </flow-node>
          </template>
          <!-- 给画布一个默认的宽度和高度 -->
          <div style="position: absolute; top: 2000px; left: 2000px">&nbsp;</div>
        </div>
      </div>
    </div>
    <!-- 流程数据详情 -->
    <flow-info v-if="flowInfoVisible" ref="flowInfo" :data="data"></flow-info>
    <!-- 右击框 -->
    <right-menu 
      v-if="rightMenu.show" 
      :menuData="rightMenu" 
      @testSound="testSound"
      @deleteNodeClick="deleteNodeClick"
      @deleteLineClick="deleteLineClick"
    ></right-menu>
    <!-- 节点编辑 -->
    <dblclick-menu
      ref="dblclickNode"
      :activeSpeechId="activeSpeechElementId"
      :flowNodeList="flowNodeList"
      @submit="dblclickSubmit"
    ></dblclick-menu>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import "./jsplumb";
import { easyFlowMixin } from "@/components/ef/mixins";
import lodash from "lodash";
import nodeMenu from "@/components/ef/node_menu";
import flowNode from "@/components/ef/node";
import rightMenu from "@/components/ef/right_menu";
import dblclickMenu from "@/components/ef/dblclick_menu";
import FlowInfo from "@/components/ef/info";
export default {
  name: "knowledgePanle",
  components: {
    nodeMenu,
    flowNode,
    draggable,
    rightMenu,
    dblclickMenu,
    FlowInfo
  },
  // 一些基础配置移动该文件中
  mixins: [easyFlowMixin],
  directives: {
    flowDrag: {
      bind(el, binding, vnode, oldNode) {
        if (!binding) {
          return;
        }
        el.onmousedown = (e) => {
          if (e.button == 2) {
            // 右键不管
            return;
          }
          //  鼠标按下，计算当前原始距离可视区的高度
          let disX = e.clientX;
          let disY = e.clientY;
          el.style.cursor = "move";

          document.onmousemove = function (e) {
            // 移动时禁止默认事件
            e.preventDefault();
            const left = e.clientX - disX;
            disX = e.clientX;
            el.scrollLeft += -left;

            const top = e.clientY - disY;
            disY = e.clientY;
            el.scrollTop += -top;
          };

          document.onmouseup = function (e) {
            el.style.cursor = "auto";
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  data() {
    return {
      zoom: 1,
      // 控制流程数据显示与隐藏
      flowInfoVisible: false,
      // 流程列表
      flowNodeList: [],
      // 选中的话术
      activeSpeechElementId: "",
      activeScenarioId: "",
			activeSpeechElementName: "",
      // 控制画布销毁
      easyFlowVisible: false,
      // jsPlumb 实例
      jsPlumb: null,
      // 是否加载完毕标志位
      loadEasyFlowFinish: false,
      // 数据
      data: {},
      // 激活的元素、可能是节点、可能是连线
      activeElement: {
        // 可选值 node 、line
        type: undefined,
        // 节点ID
        nodeId: undefined,
        // 连线ID
        sourceId: undefined,
        targetId: undefined,
        // 节点名称
        name: undefined
      },
      // 右击数据
      rightMenu: {
        // 右击显示
        show: false,
        // 节点ID
        nodeId: undefined,
        // 节点类型
        type: undefined,
        // 节点名称
        name: undefined,
        // 是否为起点
        starter: false,
        // 位置
        left: 0,
        top: 0,
        // 点击类型
        clickType: undefined,
        // 源ID
        sourceId: undefined,
        // 目标ID
        targetId: undefined
      },
    }
  },
  mounted() {
    this.activeSpeechElementId = this.$route.query.dialogueId;
    this.activeSpeechElementName = this.$route.query.name;
    this.activeScenarioId = this.$route.query.scenarioId;
    this.jsPlumb = jsPlumb.getInstance();
    this.$nextTick(() => {
      // 默认加载流程A的数据、在这里可以根据具体的业务返回符合流程数据格式的数据即可
      this.getFlowChart();
    });
    this.getDialogueScenarioList();
  },
  methods: {
    // 查询流程列表
    getDialogueScenarioList() {
      let data = {
        dialogueId: this.activeSpeechElementId,
        type: "MASTER"
      }
      this.$http("/dialogueScenario/dialogueScenarioList",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let list = res.data;
          this.flowNodeList = list;
        }
      })
    },
    // 点击返回
    goBackClick() {
      this.$router.go(-1);
    },
    // 查询流程
		getFlowChart() {
      this.$http("/dialogueScenario/dialogueScenarioDetail",null,"get",null,{id: this.activeScenarioId}).then(res => {
        if (res.result === 200) {
          if (res.data.content) {
            let content = JSON.parse(res.data.content);
            for (let j in content.dialogueNodeList) {
              let menu = content.dialogueNodeList[j];
              menu.id = menu.id.toString();
              for (let i in menu.dialogueNodeBranchList) {
                let item = menu.dialogueNodeBranchList[i];
                item.id = item.uniqueId;
              }
            }
            for (let j in content.lineList) {
              let menu = content.lineList[j];
              menu.from = menu.from;
              menu.to = menu.to.toString();
            }
            this.dataReload(content);
          } else {
            this.data = {};
            this.dataReload(this.data);
          }
        }
      })
    },
    // 加载流程图
    dataReload(data) {
      this.easyFlowVisible = false;
      this.data.dialogueNodeList = [];
			this.data.lineList = [];
      this.$nextTick(() => {
				data = lodash.cloneDeep(data);
        this.easyFlowVisible = true;
        this.data = data;
        this.$nextTick(() => {
          this.jsPlumb = jsPlumb.getInstance();
          this.$nextTick(() => {
            this.jsPlumbInit();
          });
        });
      });
    },
    jsPlumbInit() {
      this.jsPlumb.ready(() => {
        // 导入默认配置
        this.jsPlumb.importDefaults(this.jsplumbSetting);
        // 会使整个jsPlumb立即重绘。
        this.jsPlumb.setSuspendDrawing(false, true);
        // 初始化节点
        this.loadEasyFlow();
        // 单点击了连接线, https://www.cnblogs.com/ysx215/p/7615677.html
        this.jsPlumb.bind("click", (conn, originalEvent) => {
          originalEvent.preventDefault();
          originalEvent.stopPropagation();
          this.activeElement.type = "line";
          this.activeElement.sourceId = conn.sourceId;
          this.activeElement.targetId = conn.targetId;
        });
        // 连线
        this.jsPlumb.bind("connection", (evt) => {
          let from = evt.source.id;
          let to = evt.target.id;
          if (this.loadEasyFlowFinish) {
            this.data.lineList.push({ from: from, to: to, anchors: ['Bottom', 'Top'] });
          }
        });

        // 删除连线回调
        this.jsPlumb.bind("connectionDetached", (evt) => {
          this.$service("/dialogueNode/updateLineInfo",{ id: evt.sourceId, nextNodeId: 0, fromAnchor: "Bottom", toAnchor: "Top" }).then(res => {
            if (res.result === 200) {
              this.deleteLine(evt.sourceId, evt.targetId);
              this.$message.success("删除成功");
            }
          })
        });

        // 改变线的连接节点
        this.jsPlumb.bind("connectionMoved", (evt) => {
          this.changeLine(evt.originalSourceId, evt.originalTargetId);
        });

        // 连线右击
        this.jsPlumb.bind("contextmenu", (evt, originalEvent) => {
          // console.log("contextmenu", originalEvent);
          originalEvent.preventDefault();
          originalEvent.stopPropagation();
          if (this.rightMenu.show) {
            this.rightMenu.show = false;
            return
          }
          this.rightMenu.clickType = "line";
          this.rightMenu.sourceId = evt.sourceId;
          this.rightMenu.targetId = evt.targetId;
          this.rightMenu.left = originalEvent.pageX + "px";
          this.rightMenu.top = originalEvent.pageY + "px";
          this.rightMenu.show = true;
        });

        // 连线
        this.jsPlumb.bind("beforeDrop", (evt) => {
          let from = evt.sourceId;
          let to = evt.targetId;
          let supFrom = from.split("_")[0];
          if (supFrom === to) {
            // this.$message.error("节点不支持连接自己");
            return false;
          }
          if (from === to) {
            // this.$message.error("节点不支持连接自己");
            return false;
          }
          if (this.hasLine(from, to)) {
            this.$message.error("该关系已存在,不允许重复创建");
            return false;
          }
          if (this.hashOppositeLine(from, to)) {
            this.$message.error("不支持两个节点之间连线回环");
            return false;
          }
          this.$service("/dialogueNode/updateLineInfo",{ id: from, nextNodeId: to, fromAnchor: "Bottom", toAnchor: "Top" }).then(res => {
            if (res.result === 200) {
              this.$message.success("连接成功");
            }
          })
          return true;
        });

        // beforeDetach
        this.jsPlumb.bind("beforeDetach", (evt) => {
          // console.log("beforeDetach", evt);
        });
        this.jsPlumb.setContainer(this.$refs.efContainer);
      });
    },
    // 加载流程图
    loadEasyFlow() {
      // 初始化节点
      for (var i = 0; i < this.data.dialogueNodeList.length; i++) {
        let node = this.data.dialogueNodeList[i];
        this.makeNode(node);
      }
      // 初始化连线
      for (var i = 0; i < this.data.lineList.length; i++) {
        let line = this.data.lineList[i];
        var connParam = {
          source: line.from,
          target: line.to,
          label: line.label ? line.label : "",
          connector: line.connector ? line.connector : "",
          anchors: line.anchors ? line.anchors : undefined,
          paintStyle: line.paintStyle ? line.paintStyle : undefined,
        };
        this.jsPlumb.connect(connParam, this.jsplumbConnectOptions);
      }
      this.$nextTick(function () {
        this.loadEasyFlowFinish = true;
      });
    },
    // 节点绘制
    makeNode(node) {
      this.$nextTick(function () {
        this.jsPlumb.makeSource(node.id, this.jsplumbSourceOptions);
        this.jsPlumb.makeTarget(node.id, this.jsplumbTargetOptions);
        this.jsPlumb.draggable(node.id, {
          containment: "parent",
          stop: function (el) {
            // 拖拽节点结束后的对调
            // console.log("拖拽结束: ", el);
          },
        });
        if (node.dialogueNodeBranchList && node.dialogueNodeBranchList.length > 0) {
          node.dialogueNodeBranchList.forEach(item => {
            this.jsPlumb.makeSource(item.id, this.jsplumbSourceOptions);
            this.jsPlumb.makeTarget(item.id, this.jsplumbTargetOptions);
            this.jsPlumb.draggable(item.id, {
              containment: "parent",
              stop: function (el) {
                // 拖拽节点结束后的对调
                // console.log("拖拽结束: ", el);
              },
            });
          })
        }
      });
    },
    // 删除线
    deleteLine(from, to) {
      this.data.lineList = this.data.lineList.filter(function (line) {
        if (line.from == from && line.to == to) {
          return false;
        }
        return true;
      });
    },
    // 改变连线
    changeLine(oldFrom, oldTo) {
      this.deleteLine(oldFrom, oldTo);
    },
    // 是否具有该线
    hasLine(from, to) {
      for (var i = 0; i < this.data.lineList.length; i++) {
        var line = this.data.lineList[i];
        if (line.from === from && line.to === to) {
          return true;
        }
      }
      return false;
    },
    // 是否含有相反的线
    hashOppositeLine(from, to) {
      return this.hasLine(to, from);
    },
    // 流程数据信息
    dataInfo() {
      this.flowInfoVisible = true;
      this.$nextTick(function () {
        this.$refs.flowInfo.init();
      });
    },
    // 删除激活的元素
    deleteElement() {
      if (this.activeElement.type === "node") {
        this.deleteNode(this.activeElement);
      } else if (this.activeElement.type === "line") {
        this.$confirm("确定删除所点击的线吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var conn = this.jsPlumb.getConnections({
              source: this.activeElement.sourceId,
              target: this.activeElement.targetId,
            })[0];
            this.jsPlumb.deleteConnection(conn);
          })
          .catch(() => {});
      }
    },
    zoomAdd() {
      if (this.zoom >= 1) {
        return;
      }
      this.zoom = this.zoom + 0.1;
      this.$refs.efContainer.style.transform = `scale(${this.zoom})`;
      this.jsPlumb.setZoom(this.zoom);
    },
    zoomSub() {
      if (this.zoom <= 0.35) {
        return;
      }
      this.zoom = this.zoom - 0.1;
      this.$refs.efContainer.style.transform = `scale(${this.zoom})`;
      this.jsPlumb.setZoom(this.zoom);
    },
    /**
     * 删除节点
     * @param nodes 被删除节点
     */
    deleteNode(nodes) {
      this.$confirm("确定要删除此节点?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.data.dialogueNodeList.filter((node) => {
            if (node.id === nodes.nodeId) {
              /**
               * 这里需要进行业务判断，是否可以删除
               */
              this.data.dialogueNodeList = this.data.dialogueNodeList.filter(function (node) {
                if (node.id === nodes.nodeId) {
                  // 伪删除，将节点隐藏，否则会导致位置错位
                  // node.show = false
                  return false;
                }
                return true;
              });
              // 取消的节点连线、节点删除
              for (let i = 0; i < node.dialogueNodeBranchList.length; i++) {
                let obj = node.dialogueNodeBranchList[i]
                for (let i in this.data.lineList) {
                  let menu = this.data.lineList[i];
                  if (obj.id === menu.from) {
                    this.data.lineList.splice(i, 1);
                    break
                  }
                  if (obj.id === menu.to) {
                    this.data.lineList.splice(i, 1);
                    break
                  }
                }
              }
              let data = {
                dialogueId: this.activeSpeechElementId,
                dialogueNodeId: nodes.nodeId
              }
              this.$service("/dialogueNode/deleteDialogueNode",data).then(res => {
                if (res.result === 200) {
                  this.$nextTick(() => {
                    this.jsPlumb.removeAllEndpoints(nodes.nodeId);
                    let data = lodash.cloneDeep(this.data);
                    this.dataReload(data);
                  });
                }
              })
            }
          })
        })
        .catch(() => {});
      return true;
    },
    // 返回唯一标识
    getUUID() {
      return Math.random().toString(36).substr(3, 10);
    },
    /**
     * 拖拽结束后添加新的节点
     * @param evt
     * @param nodeMenu 被添加的节点对象
     * @param mousePosition 鼠标拖拽结束的坐标
     */
    addNode(evt, nodeMenu, mousePosition) {
      var screenX = evt.originalEvent.clientX,
        screenY = evt.originalEvent.clientY;
      let efContainer = this.$refs.efContainer;
      var containerRect = efContainer.getBoundingClientRect();
      var left = screenX,
        top = screenY;
      // 计算是否拖入到容器中
      if (
        left < containerRect.x ||
        left > containerRect.width + containerRect.x ||
        top < containerRect.y ||
        containerRect.y > containerRect.y + containerRect.height
      ) {
        // this.$message.error("请把节点拖入到画布中");
        return;
      }
      left = left - containerRect.x + efContainer.scrollLeft;
      top = top - containerRect.y + efContainer.scrollTop;
      // 居中
      left -= 85;
      top -= 16;
      var nodeId = this.getUUID();
      // 动态生成名字
      var origName = nodeMenu.name;
      var nodeName = origName;
      var index = 1;
      while (index < 10000) {
        var repeat = false;
        for (var i = 0; i < this.data.dialogueNodeList.length; i++) {
          let node = this.data.dialogueNodeList[i];
          if (node.name === nodeName) {
            nodeName = origName + index;
            repeat = true;
          }
        }
        if (repeat) {
          index++;
          continue;
        }
        break;
      }
      var node = {
        id: nodeId,
        name: nodeName,
        type: nodeMenu.type,
        left: left + "px",
        top: top + "px",
        ico: nodeMenu.ico,
        dialogueNodeContentList: nodeMenu.dialogueNodeContentList,
        dialogueNodeBranchList: nodeMenu.dialogueNodeBranchList,
        wechatServer: nodeMenu.wechatServer,
        wechatClientGroupId: nodeMenu.wechatClientGroupId,
        nextScenarioId: nodeMenu.nextScenarioId,
			};
      let params = {
        dialogueId: this.activeSpeechElementId,
        dialogueScenarioId: this.activeScenarioId,
        name: node.name,
        type: node.type,
        top: node.top,
        left: node.left,
        dialogueNodeContentList: node.dialogueNodeContentList
      }
      this.$service("/dialogueNode/addDialogueNode",params).then(res => {
        if (res.result === 200) {
          /**
           * 这里可以进行业务判断、是否能够添加该节点
           */
          node.id = res.data.id.toString();
          this.data.dialogueNodeList.push(node);
          this.makeNode(node);
        }
      })
    },
    // 点击保存
    conserveClick() {
      let dialoguePositionItemList = [];
      this.data.dialogueNodeList.forEach(item => {
        let obj = {
          dialogueNodeId: item.id,
          left: item.left,
          top: item.top
        }
        dialoguePositionItemList.push(obj);
      })
      let data = {
        dialogueId: this.activeSpeechElementId,
        dialoguePositionItemList: dialoguePositionItemList
      }
      this.$service("/dialogueNode/updateDialoguePositionBatch",data).then(res => {
        if (res.result === 200) {
          this.$message.success("保存成功");
        }
      })
    },
    // 改变节点的位置
    changeNodeSite(data) {
      for (var i = 0; i < this.data.dialogueNodeList.length; i++) {
        let node = this.data.dialogueNodeList[i];
        if (node.id === data.nodeId) {
          node.left = data.left;
          node.top = data.top;
        }
      }
    },
    // 节点左键单击
    clickNode(node) {
      this.activeElement.type = "node";
      this.activeElement.nodeId = node.id;
      this.activeElement.name = node.name;
    },
    // 节点左键双击
    onDblClick(node) {
      this.$refs.dblclickNode.init(node.id);
    },
     // 点击编辑节点
    modifyNodeClick(node) {
      this.$refs.dblclickNode.init(node.id);
    },
    // 点击节点删除按钮
    deleteNode_click(node) {
      this.activeElement.type = "node";
      this.activeElement.nodeId = node.id;
      this.activeElement.name = node.name;
      this.deleteNode(this.activeElement);
    },
    // 节点右键单击
    nodeRightMenu(node, evt) {
      if (this.rightMenu.show === true) {
        this.rightMenu.show = false;
        return
      }
      this.$nextTick(() => {
        this.rightMenu.show = true;
        this.rightMenu.nodeId = node.id;
        this.rightMenu.starter = node.starter;
        this.rightMenu.type = node.type;
        this.rightMenu.clickType = "node";
        this.rightMenu.name = node.name;
        this.rightMenu.left = evt.pageX + "px";
        this.rightMenu.top = evt.pageY + "px";
      })
    },
    // 确认节点编辑
    dblclickSubmit(item) {
      this.data.dialogueNodeList.filter((node) => {
        if (node.id === item.id.toString()) {
          let checkedBranchNodeList = [];
          if (node.type === "NORMAL") {
            // 取消的节点连线、节点删除
            for (let i = 0; i < node.dialogueNodeBranchList.length; i++) {
              let obj = node.dialogueNodeBranchList[i]
              let isExist = false
              for (let j = 0; j < item.dialogueNodeBranchList.length; j++) {
                let aj = item.dialogueNodeBranchList[j]
                if (aj.name === obj.name) {
                  isExist = true
                  break
                }
              }
              if (!isExist) {
                /**
                 * 这里需要进行业务判断，是否可以删除
                 */
                this.data.dialogueNodeList = this.data.dialogueNodeList.filter(function (node) {
                  if (node.id === obj.id) {
                    // 伪删除，将节点隐藏，否则会导致位置错位
                    // node.show = false
                    return false;
                  }
                  return true;
                });
                for (let i in this.data.lineList) {
                  let menu = this.data.lineList[i];
                  if (obj.id === menu.from) {
                    this.data.lineList.splice(i, 1);
                    break
                  }
                  if (obj.id === menu.to) {
                    this.data.lineList.splice(i, 1);
                    break
                  }
                }
                this.$nextTick(function () {
                  this.jsPlumb.removeAllEndpoints(obj.id);
                });
              }
            }
            node.dialogueNodeBranchList = item.dialogueNodeBranchList;
            node.dialogueNodeBranchList.forEach(items => {
              checkedBranchNodeList.push(items.name);
            })
          }
          if (node.type === "REDIRECT") {
            node.nextScenarioId = item.nextScenarioId;
          }
          if (node.type === "ADD_WECHAT") {
            node.wechatServer = item.wechatServer;
            node.wechatClientGroupId = item.wechatClientGroupId;
          }
          node.name = item.name;
          node.dialogueNodeContentList = item.dialogueNodeContentList;
          let dialogueNodeContentList = [];
          item.dialogueNodeContentList.forEach(item => {
            dialogueNodeContentList.push(item.content);
          })
          let params = {
            id: item.id,
            dialogueId: this.activeSpeechElementId,
            dialogueScenarioId: this.activeScenarioId,
            // name: item.name,
            // data_sign: item.data_sign,
            dataSign: item.data_sign,
            type: item.type,
            nextScenarioId: item.nextScenarioId,
            wechatServer: item.wechatServer,
            wechatClientGroupId: item.wechatClientGroupId,
            dialogueNodeContentList: dialogueNodeContentList,
            checkedBranchNodeList: checkedBranchNodeList,
            top: item.top,
            left: item.left
          }
          this.$service("/dialogueNode/updateDialogueNode",params).then(res => {
            if (res.result === 200) {
              let obj = res.data;
              this.data.dialogueNodeList.filter((node) => {
                if (node.id === item.id.toString()) {
                  node.dialogueNodeBranchList = [];
                  obj.dialogueNodeBranchList.forEach(item => {
                    let e = {
                      id: item.uniqueId,
                      name: item.name
                    }
                    node.dialogueNodeBranchList.push(e);
                  })
                }
              })
              this.$nextTick(() => {
                let data = lodash.cloneDeep(this.data);
                this.dataReload(data);
              })
            }
          })
        }
      });
    },
    // 点击录音测试
    testSound(nodeId, type, label) {

    },
    // 点击删除节点
    deleteNodeClick(nodeId) {
      this.deleteNode(this.rightMenu);
    },
    // 点击删除连线
    deleteLineClick(sourceId, targetId) {
      this.$confirm("确定删除所点击的线吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var conn = this.jsPlumb.getConnections({
            source: sourceId,
            target: targetId,
          })[0];
          this.jsPlumb.deleteConnection(conn);
        })
        .catch(() => {});
    },
    // 左击页面
    panelClick(e) {
      this.rightMenu = {
        // 右击显示
        show: false,
        // 节点ID
        nodeId: undefined,
        // 节点类型
        type: undefined,
        // 节点名称
        name: undefined,
        // 是否为起点
        starter: false,
        // 位置
        left: 0,
        top: 0,
        // 点击类型
        clickType: undefined,
        // 源ID
        sourceId: undefined,
        // 目标ID
        targetId: undefined
      }
      this.activeElement = {
        // 可选值 node 、line
        type: undefined,
        // 节点ID
        nodeId: undefined,
        // 连线ID
        sourceId: undefined,
        targetId: undefined,
        // 节点名称
        name: undefined
      }
    },
    // 右击页面
    panelContextmenu() {
      if (this.rightMenu.show === true) {
        this.rightMenu = {
          // 右击显示
          show: false,
          // 节点ID
          nodeId: undefined,
          // 节点类型
          type: undefined,
          // 节点名称
          name: undefined,
          // 是否为起点
          starter: false,
          // 位置
          left: 0,
          top: 0,
          // 点击类型
          clickType: undefined,
          // 源ID
          sourceId: undefined,
          // 目标ID
          targetId: undefined
        }
      }
    },
  },
}
</script>