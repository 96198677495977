<template>
  <div class="monthCumulative">
    <div class="nav">
      <div class="batchName">
        运营：
        <el-input
          v-model="operator"
          placeholder="请输入运营"
          clearable
        ></el-input>
      </div>
      <div class="batchName">
        目标达成率：
        <el-input
          v-model="objectRateStart"
          placeholder="最小数"
          clearable
          style="width: 120px"
        ></el-input>
        -
        <el-input
          v-model="objectRateEnd"
          placeholder="最大数"
          clearable
          style="width: 120px"
        ></el-input>
      </div>
      <div class="batchName">
        日期：
        <el-date-picker
          v-model="abnormalGmtCreatedDate"
          type="month"
          value-format="yyyy-MM-dd"
          placeholder="选择月"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="nav">
      <div class="batchName">
        分组：
        <el-input
          v-model="operationalUsersGroupNames"
          placeholder="请输入分组"
          clearable
        ></el-input>
      </div>
    </div>
    <el-button type="primary" @click="inquire">查询</el-button>
    <el-button @click="batchNotes">批量添加标签</el-button>
    <el-button @click="syncHistoricalDataClick">一键同步</el-button>
    <el-checkbox v-model="filterQuit" style="margin-left: 20px"
      >过滤离职</el-checkbox
    >
    <el-table
      :data="monthTableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
      :span-method="objectSpanMethod"
    >
      <el-table-column
        prop="operationalUsersGroupName"
        label="分组"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="operationalUsers"
        label="跟进运营"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="singleObject"
        label="业绩目标"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="singleVolume"
        label="成单量"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="achieveRateItem"
        label="目标达成率"
        min-width="100"
        align="left"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span :style="{ color: scope.row.achieveRateColor }">{{
            scope.row.achieveRateItem
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="normalFansCount"
        label="正常进粉率"
        min-width="150"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          <span>正常进粉率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="正常进粉标签/(总标签-请假标签-控粉标签-离职标签)"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="performanceCount"
        label="业绩绩效统计"
        min-width="150"
        align="center"
      ></el-table-column>
    </el-table>
    <div class="explainAchieveRate-style">
      {{ explainAchieveRate }}
    </div>
    <!-- 批量添加标签 -->
    <el-dialog
      title="批量添加标签"
      :visible.sync="batchNotesShow"
      width="450px"
      @close="batchNotesClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">批量添加标签</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="日期：" required>
          <el-date-picker
            v-model="bacthDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="标签：" required>
          <el-select v-model="notesValue" placeholder="请选择标签">
            <el-option
              v-for="item in notesValueList"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="batchNotes_submit()"
            >确认</el-button
          >
          <el-button @click="batchNotesShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "monthCumulative",
  props: ["roleMenu", "loginName"],
  data() {
    return {
      filterQuit: true,
      notesValue: "",
      notesValueList: [
        {
          label: "正常进粉",
        },
        {
          label: "三分之一进粉",
        },
        {
          label: "三分之二进粉",
        },
        {
          label: "二分之一进粉",
        },
        {
          label: "六分之一进粉",
        },
        {
          label: "主管进粉",
        },
        {
          label: "请假一天",
        },
        {
          label: "请假半天",
        },
        {
          label: "新人进粉",
        },
        {
          label: "停粉",
        },
        {
          label: "控粉",
        },
        {
          label: "离职",
        },
      ],
      batchNotesShow: false,
      explainAchieveRate: "",
      // explainAchieveRate:
      //   "目标达标率：<60% 六分之一进粉，60%-80% 三分之一进粉， 80%-100% 三分之二进粉， >100% 正常进粉",
      objectRateStart: "",
      objectRateEnd: "",
      operator: "",
      operationalUsersGroupNames: "",
      abnormalGmtCreatedDate: "",
      monthTableData: [],
      loadingTable: false,
      spanArr: [],
      pos: 0,
      count: 0,
      bacthDate: "",
    };
  },
  mounted() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    start = this.$common.transformTime(start.valueOf()).substring(0, 10);
    this.abnormalGmtCreatedDate = start;
    this.inquire();
    this.getEditLabel();
  },
  methods: {
    // 获取业绩看板标签
    getEditLabel() {
      this.$http("/wechat/tab/getLabel", null, "get").then((res) => {
        if (res.result === 200) {
          this.explainAchieveRate = res.data;
        }
      });
    },
    // 点击一键同步
    syncHistoricalDataClick() {
      this.$http("/wechat/tab/syncHistoricalData").then((res) => {
        if (res.result === 200) {
          this.$message.success("同步成功");
        }
      });
    },
    // 点击批量添加标签
    batchNotes() {
      this.batchNotesShow = true;
      let start = new Date();
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);
      start = this.$common.transformTime(start.valueOf()).substring(0, 10);
      this.bacthDate = start;
    },
    // 确定批量添加标签
    batchNotes_submit() {
      let data = {
        label: this.notesValue,
        date: this.bacthDate,
        operationalUsers: this.operator,
        count: this.count,
      };
      if (this.objectRateStart) {
        data.objectRateStart = Number(this.objectRateStart) / 100;
      }
      if (this.objectRateEnd) {
        data.objectRateEnd = Number(this.objectRateEnd) / 100;
      }
      this.$service("/wechatUser/addUserLabel", data).then((res) => {
        if (res.result === 200) {
          this.batchNotesShow = false;
          this.$message.success("操作成功");
          this.inquire();
        }
      });
    },
    // 关闭批量添加标签
    batchNotesClose() {
      this.notesValue = "";
    },
    // 点击查询
    inquire() {
      let data = {
        operationalUsers: this.operator,
        date: this.abnormalGmtCreatedDate,
        operationalUsersGroupName: this.operationalUsersGroupNames,
        filterQuit: this.filterQuit,
      };
      if (this.objectRateStart) {
        data.objectRateStart = Number(this.objectRateStart) / 100;
      }
      if (this.objectRateEnd) {
        data.objectRateEnd = Number(this.objectRateEnd) / 100;
      }
      this.getMonthCumulative(data);
    },
    // 请求月累计目标转化数据
    getMonthCumulative(data) {
      this.loadingTable = true;
      this.monthTableData = [];
      this.$service("/wechat/tab/findWeChatProductPaysummary", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.data;
            list.forEach((item, index) => {
              item.achieveRateItem = "0%";
              if (item.singleObject) {
                item.achieveRateItem = (item.objectRate * 100).toFixed(1) + "%";
              }
              if (item.sourceFansCount) {
                item.normalFansCount =
                  ((item.normalFans / item.sourceFansCount) * 100).toFixed(2) +
                  "%";
              } else {
                item.normalFansCount = "0.00%";
              }
              item.achieveRateColor = "#606266";
              if (item.objectRate < 0.6) {
                item.achieveRateColor = "#F56C6C";
              }
              if (item.objectRate >= 0.6 && item.objectRate <= 0.8) {
                item.achieveRateColor = "#E6A23C";
              }
              if (item.objectRate > 1) {
                item.achieveRateColor = "#67C23A";
              }
            });
            this.count = list.length;
            this.getSpanArr(list);
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 此函数是实现合并随意行数的功能
    getSpanArr(data) {
      let summary = {};
      let monthSummary = {
        operationalUsers: "",
        singleObject: 0,
        sourceFansCount: 0,
        singleVolume: 0,
        performanceCount: 0,
        normalFans: 0,
        objectRate: 0,
        achieveRateItem: "0%",
        normalFansCount: "0.00%",
        achieveRateColor: "#606266",
        operationalUsersGroupName: "总合计",
      };
      data.forEach((item) => {
        summary[item.operationalUsersGroupName] = {
          operationalUsers: item.operationalUsersGroupName + "组合计",
          singleObject: 0,
          sourceFansCount: 0,
          singleVolume: 0,
          performanceCount: 0,
          normalFans: 0,
          objectRate: 0,
          achieveRateItem: "0%",
          normalFansCount: "0.00%",
          achieveRateColor: "#606266",
          operationalUsersGroupName: item.operationalUsersGroupName,
        };
      });
      data.forEach((item) => {
        summary[item.operationalUsersGroupName].singleObject +=
          item.singleObject;
        summary[item.operationalUsersGroupName].singleVolume +=
          item.singleVolume;
        summary[item.operationalUsersGroupName].performanceCount +=
          item.performanceCount;
        summary[item.operationalUsersGroupName].normalFans += item.normalFans;
        summary[item.operationalUsersGroupName].sourceFansCount +=
          item.sourceFansCount;
      });
      for (let i in summary) {
        if (summary[i].singleObject) {
          summary[i].objectRate =
            summary[i].performanceCount / summary[i].singleObject;
          summary[i].achieveRateItem =
            (summary[i].objectRate * 100).toFixed(1) + "%";
        }
        if (summary[i].sourceFansCount) {
          summary[i].normalFansCount =
            (
              (summary[i].normalFans / summary[i].sourceFansCount) *
              100
            ).toFixed(2) + "%";
        }
        if (summary[i].objectRate < 0.6) {
          summary[i].achieveRateColor = "#F56C6C";
        }
        if (summary[i].objectRate >= 0.6 && summary[i].objectRate <= 0.7) {
          summary[i].achieveRateColor = "#E6A23C";
        }
        if (summary[i].objectRate > 0.8) {
          summary[i].achieveRateColor = "#67C23A";
        }
        monthSummary.singleObject += summary[i].singleObject;
        monthSummary.singleVolume += summary[i].singleVolume;
        monthSummary.performanceCount += summary[i].performanceCount;
        monthSummary.normalFans += summary[i].normalFans;
        monthSummary.sourceFansCount += summary[i].sourceFansCount;
      }
      if (monthSummary.singleObject) {
        monthSummary.objectRate =
          monthSummary.performanceCount / monthSummary.singleObject;
        monthSummary.achieveRateItem =
          (monthSummary.objectRate * 100).toFixed(1) + "%";
      }
      if (monthSummary.sourceFansCount) {
        monthSummary.normalFansCount =
          (
            (monthSummary.normalFans / monthSummary.sourceFansCount) *
            100
          ).toFixed(2) + "%";
      }
      if (monthSummary.objectRate < 0.6) {
        monthSummary.achieveRateColor = "#F56C6C";
      }
      if (monthSummary.objectRate >= 0.6 && monthSummary.objectRate <= 0.7) {
        monthSummary.achieveRateColor = "#E6A23C";
      }
      if (monthSummary.objectRate > 0.8) {
        monthSummary.achieveRateColor = "#67C23A";
      }
      data.reverse();
      for (let i in summary) {
        let push = false;
        data.forEach((item, index) => {
          if (i === item.operationalUsersGroupName && !push) {
            push = true;
            data.splice(index, 0, summary[i]);
          }
        });
      }
      data.reverse();
      data.push(monthSummary);
      this.monthTableData = data;
      this.spanArr = []; // 合并行数组
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (
            data[i].operationalUsersGroupName ===
            data[i - 1].operationalUsersGroupName
          ) {
            // 如果operationalUsersGroupName相等就累加，并且push 0
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // columnIndex === 0 找到第一列，实现合并随机出现的行数
      if (columnIndex === 0) {
        if (rowIndex === this.monthTableData.length - 1) {
          // columnIndex === this.monthTableData.length -1 找到第最后一行，实现合并第1到2列
          if (columnIndex === 0) {
            return {
              rowspan: 1,
              colspan: 2,
            };
          } else if (columnIndex === 1) {
            return [0, 0];
          }
        }
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      } else {
        if (rowIndex === this.monthTableData.length - 1) {
          // columnIndex === this.monthTableData.length -1 找到第最后一行，实现第2列为0
          if (columnIndex === 1) {
            return [0, 0];
          }
        }
      }
      // columnIndex === 1 找到第二列，实现合并第2到4列
      // else if (columnIndex === 1) {
      // for (var i = 0; i < this.spanArr.length; i++) {
      //   // 筛选数组中数值不是0的数
      //   if (this.spanArr[i] > 1) {
      //     // console.log(i, this.spanArr[i])
      //     // 发现的规律：当前索引 + 索引所对应的数值 - 1 = 所要合并的第几行
      //     if (rowIndex === i + this.spanArr[i] - 1) {
      //       return {
      //         rowspan: 1,
      //         colspan: 4,
      //       };
      //     }
      //   }
      // }
      // }
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-addBindshow .el-input,
.el-addBindshow .el-select {
  width: 80%;
}
.titlt-show {
  font-size: 14px;
  color: #e6a23c;
  line-height: 18px;
}
.explainAchieveRate-style {
  color: #e6a23c;
  font-size: 15px;
  margin-top: 10px;
}
</style>