<template>
  <div class="bill">
    <div class="nav_menu">
      <div class="callTime">时间：
        <el-date-picker
          v-model="condition.callEndAtStart"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :default-time="['00:00:00', '23:59:59']"
          align="right">
        </el-date-picker>
      </div>
      <div class="companyName">用户名称：
        <el-select v-model="condition.userId" placeholder="请选择用户名称" filterable remote :loading="loadingName" :remote-method="NameRemoteMethod" @focus="NameRemoteFocus" clearable>
          <el-option
            v-for="item in userNameList"
            :key="item.id"
            :value="item.loginName"
            :label="item.userName"
          ></el-option>
        </el-select>
      </div>
      <div class="taskName">费用科目：
        <el-select v-model="condition.feeName" placeholder="请选择费用科目" clearable>
          <el-option
            v-for="item in subjectList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="operate">
        <el-button type="primary" @click="query()">查询</el-button>
        <el-button type="primary" @click="addBill()" v-if="roleMenu=='SUPER_MANAGER'">新增</el-button>
        <el-button type="success" @click="derivedData()" :disabled="derivedDis">账单导出</el-button>
      </div>
    </div>
    <!-- 汇总栏 -->
    <el-table :data="tableData" stripe style="width: 100%" border class="billTableClass" v-loading="loadingTab">
      <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
      <el-table-column prop="userName" label="账户名称" width align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="money" label="充值总额" width align="center"></el-table-column>
      <el-table-column label="消费总额" width align="center">
        <el-table-column prop="systemUse" label="系统服务费" width align="center"></el-table-column>
        <el-table-column prop="bill" label="话费" width align="center"></el-table-column>
        <el-table-column prop="other" label="其他费用" width align="center"></el-table-column>
        <el-table-column prop="total" label="合计" width align="center"></el-table-column>
      </el-table-column>
    </el-table>
    <!-- 明细栏 -->
    <div style="position: relative;">
      <el-table :data="bomTableData" stripe style="width: 100%" border class="billTableClass" v-loading="loadingBom">
        <el-table-column type="index" prop label="编号" width="60" align="center"></el-table-column>
        <el-table-column prop="gmtCreated" label="账单产生时间" width="140" align="center"></el-table-column>
        <el-table-column prop="userName" label="账户名称" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="submitUserName" label="账单产生用户" min-width="120" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="科目" width="130" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.category}}</span>
            <!-- <span v-if="scope.row.categoryShow">{{scope.row.category}}</span>
            <el-button type="text" @click="checkGuest(scope.row)" v-if="!scope.row.categoryShow">{{scope.row.category}}</el-button> -->
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量" width="130" align="center"></el-table-column>
        <el-table-column prop="money" label="金额 (元)" width="130" align="center"></el-table-column>
        <el-table-column prop="currentBalance" label="账户余额 (元)" width="150" align="center"></el-table-column>
        <el-table-column prop="unitPrice" label="单价 (元)" width="130" align="center" v-if="roleMenu==='SUPER_MANAGER'">
          <template slot="header" slot-scope="scope">
            <span>单价 (元)</span>
            <el-tooltip class="item" effect="light" content="金额/数量" placement="top-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="currentOverdraft" label="透支额度 (元)" width="150" align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" width="390" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
      <!-- 分页2 -->
      <el-pagination
        @size-change="bomSizeChange"
        @current-change="currentPage_bom"
        :current-page.sync="bomPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        :pager-count="5"
        layout="sizes, total, prev, pager, next, jumper"
        :total="bomTotal">
      </el-pagination>
      <div class="displayBlock"></div>
    </div>
    <!-- 新增 -->
    <el-dialog title="新增" :visible.sync="addBillShow" class="el-addBill" width="400px" @close="addBillClose">
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">新增</span>
      </template>
      <el-form :model="addForm" label-position="left" label-width="100px">
        <el-form-item label="用户名称：" required>
					<el-select v-model="addForm.userId" placeholder="请选择用户名称" filterable remote :loading="loadingName" :remote-method="NameRemoteMethod" @focus="NameRemoteFocus" clearable>
            <el-option v-for="item in userIdList" :key="item.id" :value="item.loginName" :label="item.userName"></el-option>
          </el-select>
				</el-form-item>
        <el-form-item label="科目：" required>
					<el-select v-model="addForm.subject" placeholder="请选择科目" clearable>
            <el-option v-for="item in subjectList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量：" required>
          <el-input v-model="addForm.number" placeholder="请输入数量" @input="countInput" @blur="pitchOn"></el-input>
        </el-form-item>
        <el-form-item label="正/负：">
          <el-select v-model="addForm.just" placeholder="请选择正/负">
            <el-option v-for="item in justList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额：" required>
          <el-input v-model="addForm.money" placeholder="请输入金额" @input="moneyInput" @blur="pitchOn"></el-input>
        </el-form-item>
        <el-form-item label="单价：">
          <el-input v-model="addForm.price" placeholder="请输入单价" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="addForm.note" type="textarea" :rows="4" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <div class="button-center">
        <el-button type="primary" @click="innerVisibleClick">提 交</el-button>
        <el-button @click="addBill_cancel">取 消</el-button>
      </div>
      <!-- 确认明细 -->
      <el-dialog class="el-addBill" width="410px" @close="innerVisibleClose" title="确认明细" :visible.sync="innerVisible" append-to-body>
        <template slot="title">
          <i class="el-icon-data-analysis dialog-title-my" style="color:#1296db;"></i>
          <span class="dialog-title-my my-title">确认明细</span>
        </template>
        <el-form :model="confirmForm" label-position="left" label-width="100px">
          <el-form-item label="用户名称：" required>
            <span>{{confirmForm.userId}}</span>
          </el-form-item>
          <el-form-item label="科目：" required>
            <span>{{confirmForm.subject}}</span>
          </el-form-item>
          <el-form-item label="数量：" required>
            <span>{{confirmForm.number}}</span>
          </el-form-item>
          <el-form-item label="正/负：">
            <span>{{confirmForm.just}}</span>
          </el-form-item>
          <el-form-item label="金额：" required>
            <span>{{confirmForm.money}}</span>
          </el-form-item>
          <el-form-item label="单价：">
            <span>{{confirmForm.price}}</span>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="confirmForm.note" type="textarea" :rows="4" disabled></el-input>
          </el-form-item>
        </el-form>
        <div class="button-center">
          <el-button type="primary" @click="addBill_submit()" :disabled="addBillDis">确 定</el-button>
          <el-button @click="innerVisible_cancel">取 消</el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 系统使用费 -->
    <el-dialog title="详情" :visible.sync="checkGuestShow" class="el-noteGuestShow" width="400px" @close="checkGuestClose">
      <template slot="title">
        <i class="el-icon-data-analysis dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">详情</span>
      </template>
      <el-form :model="checkForm" :label-position="labelLeft" label-width="115px">
        <el-form-item label="账单产生时间：">
          <el-input v-model="checkForm.gmtCreated" disabled></el-input>
        </el-form-item>
        <el-form-item label="用户名称：">
          <el-input v-model="checkForm.userName" disabled></el-input>
        </el-form-item>
        <el-form-item label="科目：">
          <el-input v-model="checkForm.category" disabled></el-input>
        </el-form-item>
        <el-form-item label="数量：">
          <el-input v-model="checkForm.count" disabled></el-input>
        </el-form-item>
        <el-form-item label="金额 (元)：">
          <el-input v-model="checkForm.money" disabled></el-input>
        </el-form-item>
        <el-form-item label="账户余额 (元)：">
          <el-input v-model="checkForm.currentBalance" disabled></el-input>
        </el-form-item>
        <el-form-item label="透支额度 (元)：">
          <el-input v-model="checkForm.currentOverdraft" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="checkForm.remark" type="textarea" :rows="4" disabled></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:'Bill',
  props:['roleMenu'],
  data(){
    return {
      loadingTab: false,
      loadingBom: false,
      pickerOptions:{
        disabledDate(time) {
            const start = new Date();
            start.setHours(23);
            start.setMinutes(59);
            start.setSeconds(59);
            start.setMilliseconds(59);
            return time.getTime() > start;
        },
        onPick({ maxDate, minDate }){
          if(minDate){
            sessionStorage.setItem('theTime', minDate.getTime());
          }
        },
        shortcuts: [{
          text: '本月记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本周记录',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('thisWeek');
            start = parseInt(start);
            picker.$emit('pick', [new Date(start), end]);
          }
        }, {
          text: '本日记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '此刻',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('theTime');
            if(start){
              start = parseInt(start);
              picker.$emit('pick', [new Date(start), end]);
              sessionStorage.removeItem('theTime');
            }
          }
        }]
      },
      condition:{
        callEndAtStart:[],
        userId:'',
        feeName:'',
      },
      justList:[
        {
          name:'正',
          id:1
        },
        {
          name:'负',
          id:2
        }
      ],
      loadingName:false,
      userNameList:[],
      feeNameList:[],
      tableData:[],
      bomTableData:[],
      addBillShow:false,
      addForm:{
        userId:'',
        subject:'',
        number:'',
        money:'',
        price:'',
        note:'',
        just:1
      },
      confirmForm:{
        userId:'',
        subject:'',
        number:'',
        money:'',
        price:'',
        note:'',
        just:''
      },
      userIdList:[],
      subjectList:[
        {
          id:'SYSTEM_USE',
          name:'系统服务费'
        },
        {
          id:'BILL',
          name:'话费'
        },
        {
          id:'RECHARGE',
          name:'充值'
        },
        {
          id:'OTHER',
          name:'其他'
        },
      ],
      bomPage:null,
      bomTotal:null,
      pageSize:10,
      innerVisible:false,
      checkGuestShow:false,
      checkForm:{
        userName:'',
        category:'',
        count:'',
        money:'',
        currentOverdraft:'',
        currentBalance:'',
        gmtCreated:'',
        remark:'',
      },
      labelLeft:"left",
      addBillDis:false,
      derivedDis:true,
    }
  },
  mounted(){
    document.title = "惠销平台管理系统-账单";
    this.theWeek();
    this.inquireUserList({pageSize: 1000,pageNo: 1, enabled: true});
    this.inquire();
    this.inquireSummary();
  },
  methods:{
    countInput(){//限制新增数量input输入
      let inputVal = this.addForm.number;
      inputVal = inputVal.replace(/[^\d]/g,'');
      this.addForm.number = inputVal;
    },
    moneyInput(){//限制新增金额input输入
      let inputVal = this.addForm.money;
      inputVal = this.$common.numberCheck(inputVal);
      let i = inputVal.indexOf(".");
      if (i !== -1) {
        inputVal = inputVal.substring(0, i+3);
      }
      this.addForm.money = inputVal;
    },
    checkGuest(val){//点击系统使用费
      this.checkGuestShow = true;
      this.checkForm={
        userName:val.userName,
        category:val.category,
        count:val.count,
        money:val.money,
        currentOverdraft:val.currentOverdraft,
        currentBalance:val.currentBalance,
        gmtCreated:val.gmtCreated,
        remark:val.remark,
      }
    },
    checkGuestClose(){//关闭系统使用费
      this.checkForm={
        userName:'',
        category:'',
        count:'',
        money:'',
        currentOverdraft:'',
        currentBalance:'',
        gmtCreated:'',
        remark:'',
      }
    },
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
    query(){//点击查询按钮
      this.loadingTab = true
      this.loadingBom = true
      this.inquire();
      this.inquireSummary();
    },
    inquireSummary(){//查询汇总栏
      let gmtCreatedStart = '';
      let gmtCreatedEnd = '';
      if(this.condition.callEndAtStart && this.condition.callEndAtStart.length>0){
        gmtCreatedStart = this.condition.callEndAtStart[0];
        gmtCreatedEnd = this.condition.callEndAtStart[1];
      }
      let params = {
          loginName:this.condition.userId,
          category:this.condition.feeName,
          gmtCreatedStart:gmtCreatedStart,
          gmtCreatedEnd:gmtCreatedEnd,
        }
      this.$http('/user/rechargeSummaryList',null,'get',null,params).then(res=>{
        setTimeout(() => {
          this.loadingTab = false
        }, 200)
        if(res.result == 200){
          let summary = res.data;
          this.tableData = summary;
        }
      })
    },
    inquire(){//查询明细栏
      let gmtCreatedStart = '';
      let gmtCreatedEnd = '';
      if(this.condition.callEndAtStart && this.condition.callEndAtStart.length>0){
        gmtCreatedStart = this.condition.callEndAtStart[0];
        gmtCreatedEnd = this.condition.callEndAtStart[1];
      }
      let params = {
					pageNo:1,
					pageSize:this.pageSize,
          loginName:this.condition.userId,
          category:this.condition.feeName,
          gmtCreatedStart:gmtCreatedStart,
          gmtCreatedEnd:gmtCreatedEnd,
        }
      this.bomPage = 1;
      this.derivedDis = true;
      this.inquireRechargeList(params);
    },
    inquireRechargeList(params){//查询明细表格
      this.$http('/user/rechargeList',null,'get',null,params).then(res=>{
        setTimeout(() => {
          this.loadingBom = false
        }, 200)
        if(res.result == 200){
          this.bomTotal = res.data.count;
          let list = res.data.list;
          list.map(item=>{
            item.categoryShow = true;
            item.unitPrice = 0;
            item.countNumber = item.count.split(" ")[0];
            if (Number(item.countNumber)) {
              item.unitPrice = (item.money/Number(item.countNumber)).toFixed(3);
            }
            if(item.category === "系统使用费" && item.relatedId>0){
              item.categoryShow = false;
            }
          })
          if(list.length>0 && list){
            this.derivedDis = false;
          }
          this.$nextTick(()=>{
            this.bomTableData = list;
          })
        }
      })
    },
    currentPage_bom(index){//当前页--明细栏
      let gmtCreatedStart = '';
      let gmtCreatedEnd = '';
      if(this.condition.callEndAtStart && this.condition.callEndAtStart.length>0){
        gmtCreatedStart = this.condition.callEndAtStart[0];
        gmtCreatedEnd = this.condition.callEndAtStart[1];
      }
      let params = {
          pageNo:index,
					pageSize:this.pageSize,
          loginName:this.condition.userId,
          category:this.condition.feeName,
          gmtCreatedStart:gmtCreatedStart,
          gmtCreatedEnd:gmtCreatedEnd,
      }
      this.loadingBom = true
      this.inquireRechargeList(params);
    },
    bomSizeChange(val) {//显示条数--明细栏
      let gmtCreatedStart = '';
      let gmtCreatedEnd = '';
      if(this.condition.callEndAtStart && this.condition.callEndAtStart.length>0){
        gmtCreatedStart = this.condition.callEndAtStart[0];
        gmtCreatedEnd = this.condition.callEndAtStart[1];
      }
      this.pageSize = val;
      this.bomPage = 1;
      let params = {
          pageNo:1,
					pageSize:this.pageSize,
          loginName:this.condition.userId,
          category:this.condition.feeName,
          gmtCreatedStart:gmtCreatedStart,
          gmtCreatedEnd:gmtCreatedEnd,
      }
      this.loadingBom = true
      this.inquireRechargeList(params);
    },
    pitchOn(){//金额输入框失去焦点
      this.addForm.price = '';
      if(this.addForm.money && this.addForm.number){
        this.addForm.price = (this.addForm.money/this.addForm.number).toFixed(2) + ' 元';
      }
    },
    inquireUserList(params){//查询公司名称
      this.$http('/user/list', null, 'get', null, params).then(res => {
        if(res.result == 200){
          let list = res.data.list;
          this.userIdList = [];
          this.userNameList = list;
          list.map(item=>{
            this.userIdList.push(item)
          })
        }
      })
    },
    // 公司名称获取焦点
    NameRemoteFocus() {
      this.inquireUserList({pageSize: 1000,pageNo: 1,enabled: true});
    },
    NameRemoteMethod(userName) {//搜索公司名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize:1000,
          pageNo:1,
          userName:userName,
          enabled: true
        }
        this.inquireUserList(params)
      }, 200);
    },
    addBill(){//点击新增
      this.addBillShow = true;
    },
    addBill_submit(){//提交新增表单
      let money = this.addForm.money
      if(this.addForm.just == 2){
        money = -Number(this.addForm.money);
        money = money.toString();
      }
      let data = {
        loginName:this.addForm.userId,
        category:this.addForm.subject,
        count:this.addForm.number,
        money:(money*1000).toFixed(0),
        remark:this.addForm.note,
      }
      this.addBillDis = true;
      // console.log(data)
      if(data.loginName && data.category && data.count && data.money){
        this.$http('/user/addPayOrder',data).then(res=>{
          this.addBillDis = false;
          if(res.result == 200){
            this.$message({message:'新增成功',type:'success'});
            this.query();
            this.addBill_cancel();
            this.innerVisible_cancel();
          }
        })
      }else{
        this.addBillDis = false;
        this.$message({message:'信息不完整',type:'warning'});
      }
    },
    addBill_cancel(){//取消新增表单
      this.addBillShow = false;
    },
    addBillClose(){//关闭新增表单
      this.addForm = {
        userId:'',
        subject:'',
        number:'',
        money:'',
        price:'',
        note:'',
        just:1
      }
    },
    innerVisibleClick(){//打开确认明细
      this.innerVisible = true;
      this.confirmForm.number = this.addForm.number;
      this.confirmForm.money = this.addForm.money;
      this.confirmForm.price = this.addForm.price;
      this.confirmForm.note = this.addForm.note;
      if(this.addForm.just == 1){
        this.confirmForm.just = '正';
      }else{
        this.confirmForm.just = '负';
      }
      this.userIdList.map(item=>{
        if(this.addForm.userId == item.loginName){
          this.confirmForm.userId = item.userName;
        }
      })
      this.subjectList.map(menu=>{
        if(menu.id == this.addForm.subject){
          this.confirmForm.subject = menu.name;
        }
      })
    },
    innerVisible_cancel(){//取消确认明细
      this.innerVisible = false;
    },
    innerVisibleClose(){//关闭确认明细
      this.addBillDis = false;
      this.confirmForm = {
        userId:'',
        subject:'',
        number:'',
        money:'',
        price:'',
        note:'',
        just:''
      }
    },
    derivedData(){//点击账单导出
      let gmtCreatedStart = '';
      let gmtCreatedEnd = '';
      if(this.condition.callEndAtStart && this.condition.callEndAtStart.length>0){
        gmtCreatedStart = this.condition.callEndAtStart[0];
        gmtCreatedEnd = this.condition.callEndAtStart[1];
      }
      let params = {
          loginName:this.condition.userId,
          category:this.condition.feeName,
          gmtCreatedStart:gmtCreatedStart,
          gmtCreatedEnd:gmtCreatedEnd,
        }
      let url = "";
      for(let i in params){
        if(params[i]){
          url+=(i+'='+params[i]+'&')
        }
      }
      let href = this.$constants.baseURL+"/user/exportBillReport?"+url;
      // window.location.href = href;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then(data=>{
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      })
    },
  }
}
</script>
<style scoped>
.bill{
  position: relative;
}
.displayBlock{
  clear: both;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.callTime,.batchName {
  margin-right: 6px;
}
.callTime,.batchName{
  float: right;
  text-align: left;
}
.batchName .checkboxClass,.batchName .checkboxClass .el-checkbox-group{
  display: inline-block;
}
.checkboxClass{
  margin-right: 40px;
}
.el-table {
  margin: 20px 0px;
}
.button-center {
  text-align: center
}
.el-addBill >>> .el-dialog__body{
  padding: 10px 20px 20px 20px;
}
.el-addBill .el-select,.el-addBill .el-input,.el-addBill .el-textarea{
  width: 250px;
}
.billTableClass .el-button{
  padding:0;
}
.el-noteGuestShow >>> .el-dialog__body{
  padding: 7px 25px;
}
</style>
<style>
.billTableClass tr td,.billTableClass tr th{
  padding: 8px 0 !important; 
}
</style>