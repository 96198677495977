<template>
	<div class="groupList">
		<div class='nav'>			
			<div class="time">
				创建时间：
				<el-date-picker class='startTime' value-format='yyyy-MM-dd HH:mm:ss' v-model="startTime" type="datetime"  placeholder="选择日期时间"> </el-date-picker>
				-
				<el-date-picker class='endTime' value-format='yyyy-MM-dd HH:mm:ss' v-model="endTime" type="datetime"  placeholder="选择日期时间"> </el-date-picker>
			</div> 
			<div class="groupName">
				群组名称：
				<el-input v-model="groupName" placeholder="请输入内容"></el-input>
			</div>
			<el-button type="primary"  @click='inquire()'>查询</el-button>			
		</div>
				
		查询结果:
		<i style="font-style: normal;">{{total}}条</i>
		<el-table :data="tableData" stripe style="width: 100%" border >
			<el-table-column type='index' prop="" label="序号" width="60" align='center'></el-table-column>
			<el-table-column prop="groupName" label="群组名称" width="" align='center'></el-table-column>
			<el-table-column prop="industry" label="所属行业" width="" align='center'></el-table-column>
			<el-table-column prop="totalCount" label="潜客数" width="120" align='center'></el-table-column>
			<el-table-column prop="remark" label="场景名称" width="" align='center'></el-table-column>
			<el-table-column prop="gmtCreated" label="创建时间" width="170" align='center'></el-table-column>                                                             <el-table-column prop="" label="操作" width="" align='center'>
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="detail(scope.row,scope.$index)">明细</el-button>
					<el-button type="text" size="small" @click="remove(scope.row)">删除</el-button>
					<el-button type="text" size="small" @click="SMS(scope.row)">短信</el-button>
					<el-button type="text" size="small" @click="callOut(scope.row)">机器外呼</el-button>
				</template>
			</el-table-column>
		</el-table>
		
		<!-- 分页 -->
		<el-pagination @current-change="currentPage_data" :current-page.sync="currentPage" :page-size= '10' layout="prev, pager, next,jumper" :total="total" :pager-count='5'></el-pagination>
	
  
	</div>
</template>

<script>
	export default {
		name:'groupList',
		data(){
			return {
				msg:'群组列表',
				groupName:'',
				startTime:'',
				endTime:'',
				tableData: null,
				currentPage:null,
				total:null
			}
		},
		mounted(){
			this.inquire(1);
		},
		methods:{			
			currentPage_data(index){//当前页
				this.inquire(index);
			},
			inquire(index){//查询列表				
				let data = {
						pageNo:index||this.currentPage,
						pageSize:10,
						dateStart:this.startTime,
						dateEnd:this.endTime,
						groupName:this.groupName						
					};
				this.$http('/sms/macGroupList',data).then(res=>{														
					if(res.result == 200){
						let arr = res.data.list;
						this.total = res.data.count;
						arr.map(item=>{
							item.gmtCreated = this.$common.transformTime(item.gmtCreated);
						});						
						this.tableData = arr;				  
					}
					
			})},
			detail(val){//明细
				this.$router.push({ path:'/detail', query:{ groupId:val.id }});
			},
			remove(val){//点击删除						
				this.$confirm('此操作将删除该群组任务, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {//删除操作						
							let data = {
									macGroupId:val.id		 				
								};
							this.$http('/sms/deleteMacGroup',data).then(res=>{
								if(res.result == 200){
									this.$message({ message: '删除成功！', type: 'success'});	
									this.inquire();
							}
						});												
					
				}).catch(() => {//取消
				
				});
				
		
			},
			SMS(val){//短信
				this.$router.push({path:'/SMSmarketing', query:{ groupId:val.id }});
			},
			callOut(val){//外呼
				this.$router.push({ path:'/machineCalling', query:{ groupId:val.id }});
			}
			
		}
	}
</script>


<style scoped="scoped">
	.nav{
		width: 1200px;
		margin-bottom: 20px;
	}
	.time,.groupName{
		width: 600px;
		margin-bottom: 20px;
	}
	.time{
		float: right;
		text-align: left;
	}
	.startTime{
		width: 200px;
		margin-right: 6px ;
	}
	.endTime{
		width: 200px;
		margin-left:7px ;
	}
	.groupName .el-input{
		width: 430px;
	}
	
	.nav .el-button{
		width: 120px;
		margin-top: 20px;
	}
	.el-table{
		margin:20px 0 30px 0 ;
	}
	.el-pagination{
		float: right;
		text-align: right;
	}
</style>

