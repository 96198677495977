<template>
  <el-drawer
    :visible.sync="speechTestShow"
    direction="rtl"
    :with-header="false"
    @closed="speechTestClosed"
    class="drawer_dialogue_style"
  >
    <div style="padding: 10px 20px">
      <span style="font-weight: bold; line-height: 30px">{{
        title || "话术测试"
      }}</span>
    </div>
    <div class="drawer_time_line" style="height: calc(100% - 50px)">
      <!-- 录音 -->
      <div
        class="audio_div"
        v-if="type === 'translation' && videoType !== 'videoType'"
      >
        <audio
          v-if="audioShow"
          ref="audio"
          :src="currentSrc"
          controls="controls"
          class="audio_class"
        ></audio>
      </div>
      <!-- 对话框 -->
      <div id="speech" class="dialogue_style" v-if="dialogueRound">
        <ul class="dialogue_style_ul">
          <li
            v-for="(item, index) in dialogueList"
            :key="index"
            class="dialogue_style_li"
          >
            <div v-if="item.contentType === 'DIALOGUE'">
              <!-- 返回话术 -->
              <div
                v-if="item.speaker !== 'CUSTOMER'"
                class="dialogue_div_service"
              >
                <div class="dialogue_style_service">
                  <div style="display: flex; justify-content: flex-start">
                    <div class="dialogue_style_service_avatar">
                      <el-avatar
                        size="small"
                        icon="el-icon-service"
                      ></el-avatar>
                    </div>
                    <div class="dialogue_style_service_content">
                      {{ item.Text }}
                    </div>
                  </div>
                  <div
                    class="dialogue_style_service_sign"
                    v-if="item.answerBranch"
                  >
                    {{ item.answerSign }}
                  </div>
                </div>
              </div>
              <!-- 发送话术 -->
              <div
                v-if="item.speaker === 'CUSTOMER'"
                class="dialogue_div_customer"
              >
                <div class="dialogue_style_customer">
                  <div style="display: flex; justify-content: flex-end">
                    <div class="dialogue_style_customer_content">
                      {{ item.Text }}
                    </div>
                    <div class="dialogue_style_customer_avatar">
                      <el-avatar
                        size="small"
                        icon="el-icon-s-custom"
                      ></el-avatar>
                    </div>
                  </div>
                  <div
                    class="dialogue_style_service_sign"
                    v-if="item.answerBranch"
                  >
                    {{ item.answerSign }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.contentType === 'TIPS'" class="dialogue_style_tips">
              <div class="dialogue_style_tips_text">{{ item.Text }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="dialogue_style" v-if="!dialogueRound">
        <ul class="dialogue_style_ul">
          <li
            v-for="(item, index) in dialogueList"
            :key="index"
            class="dialogue_style_li"
          >
            <!-- 返回话术 -->
            <div
              v-if="item.ChannelId === item.customerChannelId"
              class="dialogue_div_service"
            >
              <div class="dialogue_style_service">
                <div style="display: flex; justify-content: flex-start">
                  <div class="dialogue_style_service_avatar">
                    <el-avatar size="small" icon="el-icon-s-custom"></el-avatar>
                  </div>
                  <div class="dialogue_style_service_content">
                    {{ item.Text }}
                  </div>
                </div>
              </div>
            </div>
            <!-- 发送话术 -->
            <div v-else class="dialogue_div_customer">
              <div class="dialogue_style_customer">
                <div style="display: flex; justify-content: flex-end">
                  <div class="dialogue_style_customer_content">
                    {{ item.Text }}
                  </div>
                  <div class="dialogue_style_customer_avatar">
                    <el-avatar size="small" icon="el-icon-service"></el-avatar>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 输入对话 -->
      <div style="margin-top: 10px" v-if="type === 'test'">
        <el-input
          size="medium"
          placeholder="请输入对话"
          suffix-icon="el-icon-edit"
          style="width: calc(100% - 85px)"
          @keyup.enter.native="dialogueOutClick"
          :disabled="isDisabledOut"
          v-model="dialogueOut"
        >
        </el-input>
        <el-button
          :disabled="isDisabledOut"
          @click="dialogueOutClick"
          size="medium"
          >发送</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  name: "dialogueTranslation",
  props: [
    "WebSocket",
    "activeSpeechId",
    "title",
    "type",
    "dialogueData",
    "dialogueRound",
    "currentSrc",
    "videoType",
  ],
  data() {
    return {
      isDisabledOut: false,
      speechTestShow: false,
      dialogueList: [],
      startAiTestId: "",
      dialogueOut: "",
      audioShow: false,
    };
  },
  watch: {
    dialogueData(val) {
      if (this.type === "translation" && this.dialogueRound) {
        this.dialogueList = [];
        this.dialogueData.forEach((msg) => {
          let answerSign =
            "触发关键词【" +
            msg.answerKeyword +
            "】，匹配意图【" +
            msg.answerBranch +
            "】";
          if (msg.answerScenarioType === "GLOBAL_CONFIG") {
            if (msg.answerKeyword === "NO_IDENTIFY") {
              answerSign = "语音为识别";
            }
            if (msg.answerKeyword === "NO_REPLAY") {
              answerSign = "用户未回复";
            }
          }
          this.dialogueList.push({
            Text: msg.content,
            speaker: msg.speaker,
            contentType: "DIALOGUE",
            answerKeyword: msg.answerKeyword,
            answerBranch: msg.answerBranch,
            answerScenarioType: msg.answerScenarioType,
            answerSign: answerSign,
          });
        });
        this.$forceUpdate();
      }
      if (this.type === "translation" && !this.dialogueRound) {
        this.dialogueList = [];
        this.dialogueList = val;
      }
    },
  },
  mounted() {
    if (this.type === "test") {
      this.WebSocket.onmessage = (event) => {
        //接收数据
        console.log(event);
        let msg = JSON.parse(event.data);
        if (typeof msg === "string") {
          msg = JSON.parse(msg);
        }
        if (
          msg.eventType == "AI_TEST" &&
          msg.callerUuid === this.startAiTestId
        ) {
          let answerSign =
            "触发关键词【" +
            msg.answerKeyword +
            "】，匹配到回答意图【" +
            msg.answerBranch +
            "】";
          if (msg.answerScenarioType === "GLOBAL_CONFIG") {
            if (msg.answerKeyword === "NO_IDENTIFY") {
              answerSign = "语音为识别";
            }
            if (msg.answerKeyword === "NO_REPLAY") {
              answerSign = "用户未回复";
            }
          }
          this.dialogueList.push({
            Text: msg.content,
            speaker: msg.speaker,
            contentType: msg.contentType,
            answerKeyword: msg.answerKeyword,
            answerBranch: msg.answerBranch,
            answerScenarioType: msg.answerScenarioType,
            answerSign: answerSign,
          });
          this.$nextTick(() => {
            let ele = document.getElementById("speech");
            ele.scrollTop = ele.scrollHeight;
          });
        }
        if (msg.contentType === "TIPS" && msg.content === "通话已结束") {
          this.isDisabledOut = true;
        }
      };
    }
  },
  methods: {
    // 点击测试--文本
    speechTestClick() {
      this.speechTestShow = true;
      this.dialogueList = [];
      this.startAiTestId = Math.random().toString(10).substr(4, 12);
      this.$nextTick(() => {
        if (this.type === "test") {
          this.setStartAiTest();
        }
      });
      if (this.type === "translation") {
        setTimeout(() => {
          this.audioShow = true;
        }, 500);
      }
    },
    // 发起对话
    setStartAiTest() {
      let data = {
        callerUuid: this.startAiTestId,
        dialogueId: this.activeSpeechId,
      };
      this.$service("/startAiTestWithText", data).then((res) => {
        if (res.result === 200) {
        }
      });
    },
    // 点击发送对话
    dialogueOutClick() {
      if (!this.dialogueOut) {
        return;
      }
      let data = {
        callerUuid: this.startAiTestId,
        dialogueId: this.activeSpeechId,
        content: this.dialogueOut,
      };
      this.$service("/sendAiTestContent", data).then((res) => {
        if (res.result === 200) {
          this.dialogueOut = "";
        }
      });
    },
    // 关闭对话
    speechTestClosed() {
      this.audioShow = false;
      this.isDisabledOut = false;
      if (this.type === "test") {
        let data = {
          callerUuid: this.startAiTestId,
        };
        this.$http("/stopAiTest", data).then((res) => {
          if (res.result === 200) {
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.audio_div {
  width: 530px;
}
.audio_div .audio_class {
  width: 530px;
  height: 40px;
}
.dialogue_style {
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
  background: #f2f6fc;
}
.dialogue_style_li {
  margin: 15px 0;
  padding: 0 10px;
}
.drawer_dialogue_style >>> .el-drawer__body {
  height: 100%;
}
.drawer_dialogue_style .drawer_time_line {
  padding: 0 20px 20px;
  overflow-x: auto;
}
.dialogue_style_li .dialogue_div_service {
  display: flex;
  justify-content: flex-start;
}
.dialogue_style_li .dialogue_style_service {
  text-align: left;
}
.dialogue_style_li .dialogue_style_service .dialogue_style_service_avatar {
  margin-right: 10px;
}
.dialogue_style_li .dialogue_style_service .dialogue_style_service_sign {
  font-size: 12px;
  color: #fa541c;
  line-height: 22px;
  border: 1px solid #fa541c;
  background-color: #ffffff;
  border-radius: 3px;
  margin-top: 8px;
  margin-left: 38px;
  padding: 0 8px;
  display: inline-block;
}
.dialogue_style_li .dialogue_style_service .dialogue_style_service_content {
  max-width: 350px;
  padding: 8px 8px;
  background: #ffffff;
  border-radius: 5px;
  color: #303133;
  display: inline-block;
}
.dialogue_style_li .dialogue_div_customer {
  display: flex;
  justify-content: flex-end;
}
.dialogue_style_li .dialogue_style_customer {
  text-align: left;
}
.dialogue_style_li .dialogue_style_customer .dialogue_style_service_sign {
  font-size: 12px;
  color: #fa541c;
  line-height: 22px;
  border: 1px solid #fa541c;
  background-color: #ffffff;
  border-radius: 3px;
  margin-top: 8px;
  margin-right: 38px;
  padding: 0 8px;
  display: inline-block;
}
.dialogue_style_li .dialogue_style_customer .dialogue_style_customer_avatar {
  margin-left: 10px;
}
.dialogue_style_li .dialogue_style_customer .dialogue_style_customer_content {
  max-width: 350px;
  padding: 8px 8px;
  background: #90ee62;
  border-radius: 5px;
  color: #303133;
  display: inline-block;
}
.dialogue_style_li .dialogue_style_tips {
  display: flex;
  justify-content: center;
}
.dialogue_style_li .dialogue_style_tips .dialogue_style_tips_text {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 4px;
  padding: 0 4px;
  line-height: 22px;
  font-size: 12px;
}
</style>
