<template>
  <div class="smsContent">
    <el-input
      v-model="SMStextarea"
      type="textarea"
      placeholder="请输入短信内容"
      :rows="6"
      @input="inputChange"
      id="shortContentEnabled"
      style="width: 74%"
    ></el-input>
    <el-input
      v-model="SMStextareaPreview"
      type="textarea"
      placeholder="短信预览"
      :rows="6"
      disabled
      style="width: 25%"
    ></el-input>
    <span class="word">
      已输入
      <i>{{ SMS.totalNum }}</i
      >个字： 签名 <i>({{ SMS.signLength }})</i>+ 内容(
      <i>{{ SMS.contentNum }}</i
      >) ，共消耗 <i>{{ SMS.num }}</i
      >条短信
      <span class="template-sign">选择标签：</span>
      <span>
        <el-button
          type="text"
          :disabled="isEndNumDis"
          @click="endNumberChain"
          class="template2"
          >#尾号#</el-button
        >
        <el-button
          type="text"
          :disabled="isCityDis"
          @click="cityChain"
          class="template3"
          >#坐席短链#</el-button
        >
      </span>
    </span>
    <div>
      <span v-if="isCityDis"
        >点击回调
        <el-tooltip
          content="用户点击短信链接会触发点击回调"
          placement="top-start"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i> </el-tooltip
        >：
        <el-radio v-model="clickToCall" label="NONE">不加微信</el-radio>
        <el-radio v-model="clickToCall" label="URL_CLICK_CALLBACK"
          >链接点击回调</el-radio
        >
        <el-radio v-model="clickToCall" label="CLICK_SEND"
          >发送短信时立即加微信</el-radio
        >
        <div v-if="clickToCall !== 'NONE'">
          机器分组选择：
          <el-select
            v-model="wechatServer"
            placeholder="请选择服务器"
            @change="wechatClientGroupFocus(wechatServer, '1')"
          >
            <el-option
              v-for="item in wechatServerList"
              :key="item.server"
              :label="item.server"
              :value="item.server"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="wechatClientGroupId"
            @change="wechatClientGroupIdChange"
            placeholder="请选择机器分组"
          >
            <el-option
              v-for="item in wechatClientGroupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          域名:
          <el-select
            v-model="domain"
            placeholder="请选择域名"
            @change="shortUrlDomainChange"
          >
            <el-option
              v-for="(item, index) in shortUrlDomainList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "smsInputEnabled",
  props: [
    "textareaSMS",
    "callValue",
    "serverWechat",
    "clientGroupId",
    "smsSign",
    "shortUrlDomainList",
    "shortUrlDomain",
  ],
  data() {
    return {
      SMStextarea: "",
      SMStextareaPreview: "",
      isEndNumDis: false,
      isCityDis: false,
      clickToCall: "NONE",
      longUrl: "",
      SMS: {
        totalNum: 0,
        signLength: 0,
        contentNum: 0,
        num: 0,
      },
      wechatServer: "t9.innerchic.cn",
      wechatServerList: [],
      wechatClientGroupId: "",
      wechatClientGroupList: [],
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
      ],
      domain: undefined,
    };
  },
  watch: {
    smsSign(val) {
      if (val !== null) {
        this.smsTotal();
      }
    },
    SMStextarea(val) {
      if (val !== null) {
        this.smsTotal();
      }
    },
    textareaSMS(val) {
      // 短信内容
      if (val === undefined) {
        this.SMStextarea = "";
      } else {
        this.SMStextarea = val;
      }
      this.$emit("change", val);
    },
    // 监听点击调用
    clickToCall(val) {
      if (val !== "NONE") {
        this.wechatClientGroupFocus(this.serverWechat);
      }
      this.$emit("callValueChange", val);
    },
    callValue(val) {
      if (val) {
        this.clickToCall = val;
      } else {
        this.clickToCall = "NONE";
      }
      this.$emit("callValueChange", this.clickToCall);
    },
    serverWechat(val) {
      this.wechatServer = val;
    },
    clientGroupId(val) {
      if (val) {
        this.wechatClientGroupId = val;
      } else {
        this.wechatClientGroupId = "";
      }
    },
    shortUrlDomain: {
      immediate: true,
      handler(val) {
        this.domain = val;
      },
    },
  },
  methods: {
    shortUrlDomainChange(value) {
      this.$emit("shortUrlDomainChange", value);
    },
    // 服务器获取
    wechatClientGroupFocus(val, key) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      if (key) {
        this.wechatClientGroupId = "";
        this.$emit("wechatGroupIdChange", this.wechatClientGroupId);
      }
      this.getWechatClientGroupList(data);
      this.$emit("wechatServerChange", val);
    },
    // 机器分组获取
    wechatClientGroupIdChange(val) {
      this.$emit("wechatGroupIdChange", val);
      this.$forceUpdate();
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatClientGroupList = list;
        }
      });
    },
    // 短信输入
    inputChange(val) {
      this.$emit("change", val);
    },
    // 长链输入
    longUrlChange(val) {
      this.$emit("longUrlChange", val);
    },
    // 提示长链接格式是否正确
    compareToLongUrl(value) {
      if (value) {
        let httpIndex = value.indexOf("http://");
        let httpsIndex = value.indexOf("https://");
        if (httpIndex === 0 || httpsIndex === 0) {
          this.$emit("urlFormatChange", false);
          return;
        }
        this.$emit("urlFormatChange", true);
      }
    },
    // 插入尾号 点击尾号---挂机短信设置
    endNumberChain() {
      this.SMStextarea = this.$common.insertInputTxt(
        "#尾号#",
        "shortContentEnabled",
        this.SMStextarea
      );
      this.$emit("change", this.SMStextarea);
    },
    // 插入城市 点击城市---挂机短信设置
    cityChain() {
      this.SMStextarea = this.$common.insertInputTxt(
        "#坐席短链#",
        "shortContentEnabled",
        this.SMStextarea
      );
      this.$emit("change", this.SMStextarea);
    },
    // 计算短信字数
    smsTotal() {
      //文本域
      let SMStextarea = this.SMStextarea;
      if (SMStextarea === null) {
        return;
      }
      let index = -1;
      index = SMStextarea.indexOf("#坐席短链#");
      let endNum = SMStextarea.indexOf("#尾号#");
      this.SMS.signLength = 0;
      if (this.smsSign) {
        this.SMS.signLength = this.smsSign.length; // 标签长度计算
      }
      if (index !== -1) {
        this.SMS.contentNum = SMStextarea.length + 13 - 6; // 前缀无 21
        this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
        this.isCityDis = true;
      } else {
        this.SMS.contentNum = SMStextarea.length;
        this.SMS.totalNum = this.SMS.contentNum + this.SMS.signLength;
        this.isCityDis = false;
      }
      if (endNum !== -1) {
        this.isEndNumDis = true;
      } else {
        this.isEndNumDis = false;
      }
      if (this.SMS.totalNum < 71) {
        this.SMS.num = 1;
      } else {
        this.SMS.num = Math.ceil((this.SMS.totalNum - 70) / 67) + 1;
      }
      this.SMStextareaPreview = "【" + this.smsSign + "】" + SMStextarea;
      this.$forceUpdate();
      this.$emit("smsNum", this.SMS.num);
    },
  },
};
</script>
<style scoped>
.smsContent {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.smsContent .template {
  float: right;
  font-size: 14px;
  margin-bottom: 5px;
  color: #3a8ee6;
}
.smsContent .editor {
  position: absolute;
  top: -6px;
  left: 70px;
  color: #3a8ee6;
  font-size: 14px;
}
.word {
  display: block;
  width: 100%;
  line-height: 40px;
  text-align: right;
  font-style: normal;
  font-size: 12px;
  position: relative;
}
.word .template-sign {
  position: absolute;
  bottom: -4px;
  left: 0px;
  font-size: 13px;
  margin-bottom: 5px;
  margin-right: 10px;
}
.word .template2 {
  position: absolute;
  bottom: -4px;
  left: 70px;
  font-size: 13px;
  margin-bottom: 5px;
  /* margin-right: 10px; */
  color: #1890ff;
}
.word .template3 {
  position: absolute;
  bottom: -4px;
  left: 120px;
  font-size: 13px;
  margin-bottom: 5px;
  /* margin-right: 10px; */
  margin-left: 0px;
  color: #1890ff;
}
.word i {
  color: red;
  font-style: normal;
}
</style>
