<template>
  <div class="project">
    <div class="projectStatus">
      用户名称：
      <el-select
        v-model="condition.userName"
        filterable
        remote
        :loading="loadingLoginName"
        :remote-method="remoteMethodLoginName"
        @focus="NameRemoteFocus"
        placeholder="请选择用户名称"
      >
        <el-option
          v-for="item in loginNameList"
          :key="item.id"
          :label="item.label"
          :value="item.loginName"
        ></el-option>
      </el-select>
    </div>
    <div class="projectStatus">
      项目名称：
      <el-input
        v-model="condition.projectName"
        placeholder="请输入项目名称"
        clearable
      ></el-input>
    </div>
    <el-button type="primary" @click="inquire()">查询</el-button>
    <el-button type="primary" @click="triggerAdd">新增项目</el-button>
    <!-- 项目表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="用户名称"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="projectName"
        label="项目名称"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="category"
        label="类别"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="defaultRouteName"
        label="默认线路"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="defaultCallbackRouteName"
        label="默认回拨线路"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="defaultTeamName"
        label="默认队列"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="defaultPrologueName"
        label="默认任务类型"
        width="145"
        align="left"
        v-if="roleMenu == 'SUPER_MANAGER'"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.defaultTaskTypeLabel }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="260"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" v-copy="scope.row.token"
            >复制token</el-button
          >
          <el-button type="text" size="small" @click="questionManage(scope.row)"
            >问卷管理</el-button
          >
          <el-button type="text" size="small" @click="reviseProject(scope.row)"
            >修改</el-button
          >
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              其它操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-button
                type="text"
                size="small"
                @click="noticeClick(scope.row)"
                >公告</el-button
              >
              <el-button
                type="text"
                size="small"
                v-if="roleMenu === 'SUPER_MANAGER'"
                @click="singleDeduction(scope.row)"
                >成单扣除</el-button
              >
              <el-button
                type="text"
                size="small"
                v-if="roleMenu === 'SUPER_MANAGER'"
                @click="intervalClick(scope.row)"
                >间隔控制</el-button
              >
              <el-button
                type="text"
                size="small"
                v-if="roleMenu === 'SUPER_MANAGER'"
                @click="redialClick(scope.row)"
                >重拨控制</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="checkStandardClick(scope.row)"
                >录音质检标准</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="checkFailClick(scope.row)"
                >质检不通过原因</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="editAutoBalanceConfig(scope.row)"
                >自动并发控制</el-button
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增项目 -->
    <el-dialog
      :visible.sync="addProjectShow"
      class="el-addProject"
      top="10vh"
      width="800px"
      @close="closeProjectDialog"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">新增项目</span>
      </template>
      <el-divider content-position="left">基本信息</el-divider>
      <el-form
        inline
        label-position="left"
        label-width="125px"
        class="revise_form"
      >
        <el-form-item label="项目名称：" required>
          <el-input
            v-model="addProjectForm.projectName"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择用户：">
          <el-select
            v-model="addProjectForm.userNameId"
            filterable
            remote
            :loading="loadingLoginName"
            :remote-method="remoteMethodLoginName"
            @focus="NameRemoteFocus"
            placeholder="请选择用户"
            @change="userNameIdChange"
            clearable
          >
            <el-option
              v-for="item in loginNameList"
              :key="item.id"
              :value="item.loginName"
              :label="item.userName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认线路：" required>
          <el-select
            v-model="addProjectForm.defaultRouteId"
            placeholder="请选择线路"
            clearable
          >
            <el-option
              v-for="item in defaultRoute"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认回拨线路：" required>
          <el-select
            v-model="addProjectForm.defaultCallbackRouteId"
            placeholder="请选择回拨线路"
            clearable
          >
            <el-option
              v-for="item in defaultRoute"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认列队：" required>
          <el-select
            v-model="addProjectForm.defaultTeamId"
            placeholder="请选择列队"
            clearable
          >
            <el-option
              v-for="item in defaultTeam"
              :key="item.id"
              :value="item.id"
              :label="item.newName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择问卷：">
          <el-select
            v-model="addProjectForm.groupId"
            placeholder="请选择选择问卷"
            clearable
          >
            <el-option
              v-for="item in groupIdList"
              :key="item.id"
              :value="item.id"
              :label="item.groupName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-divider content-position="left">附加信息</el-divider>
      <el-form
        inline
        label-position="left"
        label-width="125px"
        class="revise_form"
      >
        <el-form-item label="项目类型：" required>
          <el-select
            v-model="addProjectForm.projectType"
            placeholder="请选择项目类型"
            clearable
          >
            <el-option
              v-for="item in projectTypeList"
              :key="item.id"
              :value="item.id"
              :label="item.category"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务类型：" style="width: 700px">
          <el-select v-model="taskTypePrologue" placeholder="请选择任务类型">
            <el-option
              v-for="item in taskTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div style="display: inline-block">
            <el-button type="text" @click="templateSelection('template')"
              >模板选择</el-button
            >
            <span>{{ template_name_label }}</span>
          </div>
        </el-form-item>
        <el-form-item label="黑名单选择：" style="width: 700px">
          <el-select
            v-model="addProjectForm.blackListSource"
            placeholder="请选择黑名单"
            multiple
            clearable
          >
            <el-option
              v-for="item in blackListSourceList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="隐藏号码：">
          <template slot="label">
            隐藏号码
            <el-tooltip class="item" placement="top-start">
              <div slot="content">开启后，坐席页面将隐藏手机号码</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-switch
            v-model="addProjectForm.defaultMobileVisible"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="submitProject()">提交</el-button>
        <el-button @click="cancelProject()">取消</el-button>
      </div>
    </el-dialog>
    <!-- 模板选择 -->
    <taksTypeTemplate
      ref="taksTypeTemplate"
      :taskType="taskTypePrologue"
      @selectTemplate="selectTemplate"
    ></taksTypeTemplate>
    <!-- 修改项目 -->
    <el-dialog
      :visible.sync="reviseProjectShow"
      top="10vh"
      class="el-addProject"
      width="800px"
      @close="closereviseProject"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改项目</span>
      </template>
      <el-divider content-position="left">基本信息</el-divider>
      <el-form
        inline
        label-position="left"
        label-width="125px"
        class="revise_form"
      >
        <el-form-item label="项目名称：" required>
          <el-input
            v-model="reviseProjectForm.projectName"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择用户：">
          <el-input
            v-model="reviseProjectForm.userName"
            placeholder="请输入用户"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="默认线路：" required>
          <el-select
            v-model="reviseProjectForm.defaultRouteId"
            placeholder="请选择线路"
            clearable
          >
            <el-option
              v-for="item in defaultRoute"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认回拨线路：" required>
          <el-select
            v-model="reviseProjectForm.defaultCallbackRouteId"
            placeholder="请选择回拨线路"
            clearable
          >
            <el-option
              v-for="item in defaultRoute"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认列队：" required>
          <el-select
            v-model="reviseProjectForm.defaultTeamId"
            placeholder="请选择列队"
            clearable
          >
            <el-option
              v-for="item in defaultTeam"
              :key="item.id"
              :value="item.id"
              :label="item.newName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-divider content-position="left">附加信息</el-divider>
      <el-form
        :model="reviseProjectForm"
        inline
        label-position="left"
        label-width="125px"
        class="revise_form"
      >
        <el-form-item label="项目类型：" required>
          <el-select
            v-model="reviseProjectForm.projectType"
            placeholder="请选择项目类型"
            clearable
          >
            <el-option
              v-for="item in projectTypeList"
              :key="item.id"
              :value="item.id"
              :label="item.category"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务类型：" style="width: 700px">
          <el-select v-model="taskTypePrologue" placeholder="请选择任务类型">
            <el-option
              v-for="item in taskTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div style="display: inline-block">
            <el-button type="text" @click="templateSelection('template')"
              >模板选择</el-button
            >
            <span>{{ template_name_label }}</span>
          </div>
        </el-form-item>
        <el-form-item label="黑名单选择：" style="width: 700px">
          <el-select
            v-model="reviseProjectForm.blackListSource"
            placeholder="请选择黑名单"
            multiple
            clearable
          >
            <el-option
              v-for="item in blackListSourceList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="隐藏号码：">
          <template slot="label">
            隐藏号码
            <el-tooltip class="item" placement="top-start">
              <div slot="content">开启后，坐席页面将隐藏手机号码</div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-switch
            v-model="reviseProjectForm.defaultMobileVisible"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="submitReviseProject()"
          >提 交</el-button
        >
        <el-button @click="cencelReviseProject()">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 问卷管理 -->
    <el-dialog
      :visible.sync="questionManageShow"
      class="el-questionManage"
      width="550px"
      @close="questionManageClick"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">问卷管理</span>
      </template>
      <el-select
        v-model="groupingName"
        placeholder="请选择问卷名"
        @change="optionTypeChange"
        size="mini"
        clearable
      >
        <el-option
          v-for="item in groupingNameTypeList"
          :key="item.id"
          :value="item.id"
          :label="item.groupName"
        ></el-option>
      </el-select>
      <el-transfer
        filterable
        filter-placeholder="请输入问题"
        v-model="questionManageValue"
        :data="questionManageData"
        :titles="['可选择', '使用中']"
        @change="handleChangeQuestion"
        target-order="push"
        @left-check-change="leftCheckChange"
        class="transferClass"
      ></el-transfer>
      <!-- 预览 -->
      <el-dialog
        width="550px"
        :visible.sync="previewVisible"
        append-to-body
        class="projectQuestion-form"
      >
        <template slot="title">
          <i class="el-icon-view dialog-title-my" style="color: #1296db"></i>
          <span class="dialog-title-my my-title">预览</span>
        </template>
        <el-form label-position="right">
          <el-form-item
            v-for="item in projectQuestionList"
            :key="item.id"
            :label="item.newQuestion"
            label-width="160px"
          >
            <el-radio-group v-if="item.questionType == 'RADIO'">
              <el-radio
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-radio
              >
            </el-radio-group>

            <el-input
              v-if="item.questionType == 'INPUT'"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'DISTRICT_SELECTOR'"
              :placeholder="'请选择' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'CAR_BRAND_SELECTOR'"
              :placeholder="'请选择' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'TEXT'"
              :rows="8"
              type="textarea"
              resize="none"
              class="question_textarea"
              disabled
            ></el-input>

            <el-checkbox-group
              v-if="item.questionType == 'CHECKBOX'"
              v-model="optionCheckList"
            >
              <el-checkbox
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 排序 -->
      <el-dialog
        width="400px"
        :visible.sync="setSortShow"
        append-to-body
        class="projectQuestion-form"
      >
        <template slot="title">
          <i
            class="el-icon-edit-outline dialog-title-my"
            style="color: #1296db"
          ></i>
          <span class="dialog-title-my my-title">排序</span>
        </template>
        <el-tree
          :data="questionManageList"
          node-key="id"
          ref="menuTree"
          draggable
          :allow-drop="allowDrop"
          @node-drop="handleDrop"
        >
        </el-tree>
      </el-dialog>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="chenageQuestionManage_submit()"
          >提 交</el-button
        >
        <el-button @click="previewClick">预 览</el-button>
        <el-button @click="setSort">排 序</el-button>
      </div>
    </el-dialog>
    <!-- 标注管理 -->
    <el-dialog
      title="标注管理"
      :visible.sync="taggingManageShow"
      class="el-taggingManage"
      width="380px"
      @close="closeTagging"
    >
      <el-form label-position="left" label-width="10px">
        <el-form-item label="" v-for="item in taggingManageList" :key="item.id">
          <img :src="item.flagSrc" width="18px" />
          <el-input
            v-model="item.label"
            :disabled="item.inputDisabled"
            @input="refresh(item.id)"
          ></el-input>
          <el-button
            type="text"
            size="small"
            @click="doModifyFlagSetting(item)"
            v-if="input['input_' + item.id]"
            >保存</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="modifyFlagSetting(item, true)"
            v-if="!item.disabled"
            :disabled="!item.editable"
            >禁用</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="modifyFlagSetting(item, false)"
            v-if="item.disabled"
            :disabled="!item.editable"
            >启用</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 公告 -->
    <el-dialog
      :visible.sync="noticeShow"
      class="el-taggingManage"
      width="380px"
      @close="noticeClose"
    >
      <template slot="title">
        <i
          class="el-icon-chat-dot-square dialog-title-my my-icon"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">公告</span>
      </template>
      <el-input
        v-model="notice"
        type="textarea"
        :rows="6"
        placeholder="请输入公告"
      ></el-input>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="notice_submit()">确 定</el-button>
        <el-button @click="notice_cencel()">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 成单扣除 -->
    <el-dialog
      :visible.sync="singleDeductionShow"
      width="450px"
      @close="singleDeductionDialog"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">成单扣除</span>
      </template>
      <el-form label-position="left" label-width="140px" class="el-form">
        <el-form-item label="单价(元)：" required>
          <el-input
            v-model="singleDeductionForm.unitPrice"
            @input="unitPriceInput"
            placeholder="请输入单价"
          ></el-input>
        </el-form-item>
        <el-form-item label="启用均单扣除：">
          <template slot="label">
            启用均单扣除
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                实际扣除 = Math.max(实际总成单数 x 单价, 人/天 x 保底均单 x
                单价)
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-switch
            v-model="enableAverageDeduction"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          ></el-switch>
          <el-input
            v-if="enableAverageDeduction"
            @input="miniAverageInput"
            v-model="singleDeductionForm.miniAverageInputCount"
            placeholder="请输入保底均单"
            style="width: 175px"
          ></el-input>
        </el-form-item>
        <el-form-item label="上午最低成单：">
          <el-input
            @input="noonDeductInput"
            v-model="singleDeductionForm.noonDeduct"
            placeholder="请输入最低成单数"
            :disabled="enableAverageDeduction"
          ></el-input>
        </el-form-item>
        <el-form-item label="下午最低成单：">
          <el-input
            @input="nightDeductInput"
            v-model="singleDeductionForm.nightDeduct"
            placeholder="请输入最低成单数"
            :disabled="enableAverageDeduction"
          ></el-input>
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="enabledDeductSwitch"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="submitDeduct()">提 交</el-button>
        <el-button @click="singleDeductionShow = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 间隔控制 -->
    <el-dialog
      :visible.sync="intervalShow"
      width="400px"
      @close="intervalClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">间隔控制</span>
      </template>
      <el-form label-position="left" label-width="115px" class="el-form">
        <el-form-item>
          <template slot="label">
            第一遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第一遍拨打后间隔 {{ intervalDate.first }} 天可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="intervalForm.intervalSecondsForFirst"
            placeholder="请选择第一遍间隔"
            @change="intervalSecondsForFirstChange"
          >
            <el-option
              v-for="item in intervalList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第二遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第二遍拨打后间隔 {{ intervalDate.second }} 天可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="intervalForm.intervalSecondsForSecond"
            placeholder="请选择第二遍间隔"
            @change="intervalSecondsForSecondChange"
          >
            <el-option
              v-for="item in intervalList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第三遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第三遍拨打后间隔 {{ intervalDate.third }} 天可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="intervalForm.intervalSecondsForThird"
            placeholder="请选择第三遍间隔"
            @change="intervalSecondsForThirdChange"
          >
            <el-option
              v-for="item in intervalList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="第四遍间隔：">
          <template slot="label">
            第四遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第四遍拨打后间隔 {{ intervalDate.fourth }} 天可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="intervalForm.intervalSecondsForFourth"
            placeholder="请选择第四遍间隔"
            @change="intervalSecondsForFourthChange"
          >
            <el-option
              v-for="item in intervalList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="enableTaskRecreateIntervalControl"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="interval_submit()">确定</el-button>
        <el-button @click="intervalShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 重拨控制 -->
    <el-dialog :visible.sync="redialShow" width="450px" @close="redialClose">
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">重拨控制</span>
      </template>
      <el-form label-position="left" label-width="115px" class="el-form">
        <el-form-item>
          <template slot="label">
            第一遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第一遍拨打后间隔 {{ oldRedialDate.first }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForFirst"
            placeholder="请选择第一遍间隔"
            @change="redialSecondsForFirstChange"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox v-model="redialCheck.firstCheck"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第二遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第二遍拨打后间隔 {{ oldRedialDate.second }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForSecond"
            placeholder="请选择第二遍间隔"
            @change="redialSecondsForSecondChange"
            :disabled="secondRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.secondCheck"
            :disabled="secondRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第三遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第三遍拨打后间隔 {{ oldRedialDate.third }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForThird"
            placeholder="请选择第三遍间隔"
            @change="redialSecondsForThirdChange"
            :disabled="thirdRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.thirdCheck"
            :disabled="thirdRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第四遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第四遍拨打后间隔 {{ oldRedialDate.four }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForFour"
            placeholder="请选择第四遍间隔"
            @change="redialSecondsForFourChange"
            :disabled="fourRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.fourCheck"
            :disabled="fourRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第五遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第五遍拨打后间隔 {{ oldRedialDate.five }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForFive"
            placeholder="请选择第五遍间隔"
            @change="redialSecondsForFiveChange"
            :disabled="fiveRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.fiveCheck"
            :disabled="fiveRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <template slot="label">
            第六遍间隔
            <el-tooltip class="item" placement="top-start">
              <div slot="content">
                第六遍拨打后间隔 {{ oldRedialDate.six }} 可以再次拨打
              </div>
              <i class="el-icon-question icon-color"></i> </el-tooltip
            >：
          </template>
          <el-select
            v-model="redialForm.intervalSecondsForSix"
            placeholder="请选择第六遍间隔"
            @change="redialSecondsForSixChange"
            :disabled="sixRedialDisabled"
            style="width: 150px; margin-right: 20px"
          >
            <el-option
              v-for="item in redialList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
          <el-checkbox
            v-model="redialCheck.sixCheck"
            :disabled="sixRedialDisabled"
            >重置拨打数据</el-checkbox
          >
        </el-form-item>
        <el-form-item label="启用：">
          <el-switch
            v-model="redialEnable"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="redial_submit()">确定</el-button>
        <el-button @click="redialShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 录音质检标准 -->
    <el-dialog
      :visible.sync="checkStandardShow"
      class="el-taggingManage"
      width="550px"
      @close="checkStandardClose"
    >
      <template slot="title">
        <i
          class="el-icon-chat-dot-square dialog-title-my my-icon"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">录音质检标准</span>
      </template>
      <el-input
        v-model="checkStandard"
        type="textarea"
        :rows="22"
        placeholder="请输入录音质检标准"
      ></el-input>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="checkStandard_submit()"
          >确 定</el-button
        >
        <el-button @click="checkStandardShow = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 质检不通过原因 -->
    <el-dialog
      :visible.sync="checkFailShow"
      class="el-taggingManage"
      width="500px"
      @close="checkFailClose"
    >
      <template slot="title">
        <i
          class="el-icon-chat-dot-square dialog-title-my my-icon"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">质检不通过原因</span>
      </template>
      <el-form ref="checkFailForm" label-width="100px" class="demo-dynamic">
        <el-form-item
          v-for="(item, index) in checkFailForm"
          :label="'失败原因' + (index + 1) + '：'"
          :key="index"
        >
          <el-input
            v-model="item.reason"
            placeholder="请输入失败原因"
          ></el-input>
          <el-button
            @click.prevent="removeFailDomain(item)"
            style="color: #ff0000b0"
            >删除</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="checkFail_submit()">确 定</el-button>
        <el-button @click="addFailDomain">新增失败原因</el-button>
        <el-button @click="checkFailShow = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 自动并发控制 -->
    <el-dialog
      title="修改自动并发控制"
      :visible.sync="editAutoBalanceConfigShow"
      width="500px"
    >
      <el-form label-width="100px">
        <el-form-item label="状态">
          <el-switch
            v-model="autoBalanceEnabled"
            active-color="#13ce66"
            @change="editAutoBalanceEnabledChange"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="期望等待时间" v-if="autoBalanceEnabled">
          <el-input
            v-model="waitingTime"
            placeholder="请输入期望等待时间"
            style="width: 240px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="editAutoBalanceConfig_submit()"
          >确 定</el-button
        >
        <el-button @click="editAutoBalanceConfigShow = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import taksTypeTemplate from "../pages/taksTypeTemplate.vue";
export default {
  name: "project",
  components: {
    taksTypeTemplate,
  },
  data() {
    return {
      checkFailForm: [{ reason: "" }],
      checkFailShow: false,
      checkStandardShow: false,
      checkStandard: "",
      addWechatClientGroupName: "",
      addWechatHelloWorld: "",
      taskTypePrologue: "",
      template_name_label: "",
      taskTypeList: [
        {
          label: "普通",
          value: "NORMAL",
        },
        {
          label: "开场白+人工",
          value: "PROLOGUE",
        },
        {
          label: "开场白+短信",
          value: "PROLOGUE_SMS",
        },
        {
          label: "开场白+微信",
          value: "PROLOGUE_WECHAT",
        },
        {
          label: "AI机器人",
          value: "AI_ROBOT",
        },
      ],
      intervalShow: false,
      intervalList: [
        {
          label: "1天",
          value: 86400,
        },
        {
          label: "2天",
          value: 172800,
        },
        {
          label: "3天",
          value: 259200,
        },
      ],
      intervalDate: {
        first: 1,
        second: 1,
        third: 1,
        fourth: 1,
      },
      intervalForm: {
        intervalSecondsForFirst: 86400,
        intervalSecondsForSecond: 86400,
        intervalSecondsForThird: 86400,
        intervalSecondsForFourth: 86400,
      },
      enableTaskRecreateIntervalControl: false,
      redialList: [
        {
          label: "不拨打",
          value: -1,
        },
        {
          label: "半小时",
          value: 1800,
        },
        {
          label: "1小时",
          value: 3600,
        },
        {
          label: "2小时",
          value: 7200,
        },
        {
          label: "3小时",
          value: 10800,
        },
        {
          label: "4小时",
          value: 14400,
        },
        {
          label: "5小时",
          value: 18000,
        },
        {
          label: "6小时",
          value: 21600,
        },
        {
          label: "12小时",
          value: 43200,
        },
        {
          label: "1天",
          value: 86400,
        },
        {
          label: "2天",
          value: 172800,
        },
        {
          label: "3天",
          value: 259200,
        },
      ],
      oldRedialDate: {
        first: "1小时",
        second: "1小时",
        third: "1小时",
        four: "1小时",
        five: "1小时",
        six: "1小时",
      },
      redialForm: {
        intervalSecondsForFirst: 3600,
        intervalSecondsForSecond: 3600,
        intervalSecondsForThird: 3600,
        intervalSecondsForFour: 3600,
        intervalSecondsForFive: 3600,
        intervalSecondsForSix: 3600,
      },
      redialCheck: {
        firstCheck: false,
        secondCheck: false,
        thirdCheck: false,
        fourCheck: false,
        fiveCheck: false,
        sixCheck: false,
      },
      redialEnable: false,
      redialShow: false,
      loginNameList: [],
      loadingLoginName: false,
      singleDeductionShow: false,
      singleDeductionForm: {
        unitPrice: "",
        noonDeduct: "",
        nightDeduct: "",
        miniAverageInputCount: "",
      },
      enabledDeductSwitch: false,
      enableAverageDeduction: false,
      loading: false,
      input: {},
      taggingManageList: [],
      taggingManageShow: false,
      condition: {
        projectName: "",
        userName: "",
      },
      previewVisible: false,
      questionManageValue: [],
      questionManageData: [],
      pinyin: [],
      questionManageShow: false,
      projectId: "",
      tableData: null,
      currentPage: null,
      total: null,
      addProjectShow: false,
      reviseProjectShow: false,
      projectTypeList: [],
      blackListSourceList: [],
      addProjectForm: {
        projectName: "",
        defaultTeamId: "",
        defaultRouteId: "",
        defaultCallbackRouteId: "",
        userNameId: "",
        groupId: "",
        projectType: "",
        blackListSource: [],
        defaultAddWechat: false,
        defaultMobileVisible: true,
      },
      reviseProjectForm: {
        projectName: "",
        defaultTeamId: "",
        defaultRouteId: "",
        defaultCallbackRouteId: "",
        userName: "",
        projectType: "",
        blackListSource: [],
        defaultAddWechat: false,
        defaultMobileVisible: true,
      },
      defaultTeam: [],
      defaultRoute: [],
      questionIds: [],
      projectQuestionList: [],
      optionCheckList: [],
      leftCheckId: [],
      userLoginName: "",
      groupingName: "",
      groupingNameTypeList: [],
      allSipQuestion: [],
      groupIdList: [],
      userNameIdList: [],
      noticeShow: false,
      notice: "",
      pageSize: 10,
      defaultTeamId: "",
      defaultRouteId: "",
      defaultCallbackRouteId: "",
      setSortShow: false,
      questionManageList: [],
      taskServer: "",
      taskId: "",
      editAutoBalanceConfigShow: false,
      waitingTime: "",
      autoBalanceEnabled: false,
    };
  },
  props: ["loginName", "roleMenu", "server"],
  computed: {
    secondRedialDisabled() {
      // 第二遍重拨间隔禁用控制
      if (this.redialForm.intervalSecondsForFirst === -1) {
        this.redialForm.intervalSecondsForSecond = -1;
        this.redialForm.intervalSecondsForThird = -1;
        this.redialForm.intervalSecondsForFour = -1;
        this.redialForm.intervalSecondsForFive = -1;
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForSecondChange(
          this.redialForm.intervalSecondsForSecond
        );
        this.redialSecondsForThirdChange(
          this.redialForm.intervalSecondsForThird
        );
        this.redialSecondsForFourChange(this.redialForm.intervalSecondsForFour);
        this.redialSecondsForFiveChange(this.redialForm.intervalSecondsForFive);
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.secondCheck = false;
        this.redialCheck.thirdCheck = false;
        this.redialCheck.fourCheck = false;
        this.redialCheck.fiveCheck = false;
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    thirdRedialDisabled() {
      // 第三遍重拨间隔禁用控制
      if (this.redialForm.intervalSecondsForSecond === -1) {
        this.redialForm.intervalSecondsForThird = -1;
        this.redialForm.intervalSecondsForFour = -1;
        this.redialForm.intervalSecondsForFive = -1;
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForThirdChange(
          this.redialForm.intervalSecondsForThird
        );
        this.redialSecondsForFourChange(this.redialForm.intervalSecondsForFour);
        this.redialSecondsForFiveChange(this.redialForm.intervalSecondsForFive);
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.thirdCheck = false;
        this.redialCheck.fourCheck = false;
        this.redialCheck.fiveCheck = false;
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    fourRedialDisabled() {
      if (this.redialForm.intervalSecondsForThird === -1) {
        this.redialForm.intervalSecondsForFour = -1;
        this.redialForm.intervalSecondsForFive = -1;
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForFourChange(this.redialForm.intervalSecondsForFour);
        this.redialSecondsForFiveChange(this.redialForm.intervalSecondsForFive);
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.fourCheck = false;
        this.redialCheck.fiveCheck = false;
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    fiveRedialDisabled() {
      if (this.redialForm.intervalSecondsForFour === -1) {
        this.redialForm.intervalSecondsForFive = -1;
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForFiveChange(this.redialForm.intervalSecondsForFive);
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.fiveCheck = false;
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
    sixRedialDisabled() {
      if (this.redialForm.intervalSecondsForFive === -1) {
        this.redialForm.intervalSecondsForSix = -1;
        this.redialSecondsForSixChange(this.redialForm.intervalSecondsForSix);
        this.redialCheck.sixCheck = false;
        return true;
      }
      return false;
    },
  },
  mounted() {
    document.title = "惠销平台管理系统-项目列表";
    this.inquire();
    this.inquireBlacklistFindList();
    // this.questionGroupListAdd();
    // this.inquireUserList();
    this.inquireLoginName({
      pageSize: 20,
      pageNo: 1,
      enabled: true,
    });
  },
  methods: {
    // 点击质检不通过原因
    checkFailClick(val) {
      this.checkFailShow = true;
      this.projectId = val.id;
      this.$http(
        "/sip/questionnaire/getQuestionnaireFailureReason",
        null,
        "get",
        null,
        { projectId: val.id }
      ).then((res) => {
        if (res.result === 200) {
          if (res.data && res.data.length > 1) {
            this.checkFailForm = [];
            res.data.forEach((item) => {
              let obj = {
                id: item.id,
                reason: item.reason,
              };
              this.checkFailForm.push(obj);
            });
          }
        }
      });
    },
    // 提交质检不通过原因
    checkFail_submit() {
      let data = {
        projectId: this.projectId,
        questionnaireFailureReasonItemList: this.checkFailForm,
      };
      this.$service(
        "/sip/questionnaire/updateQuestionnaireFailureReason",
        data
      ).then((res) => {
        if (res.result === 200) {
          this.checkFailShow = false;
          this.$message.success("提交成功");
        }
      });
    },
    // 关闭质检不通过原因
    checkFailClose() {
      this.checkFailForm = [{ reason: "" }];
    },
    // 添加失败原因
    addFailDomain() {
      this.checkFailForm.push({
        reason: "",
      });
    },
    // 删除失败原因
    removeFailDomain(item) {
      var index = this.checkFailForm.indexOf(item);
      if (index !== -1) {
        this.checkFailForm.splice(index, 1);
      }
    },
    editAutoBalanceEnabledChange(val) {
      if (val) {
        this.waitingTime = "";
      } else {
        this.waitingTime = 0;
      }
    },
    editAutoBalanceConfig(val) {
      this.projectId = val.id;
      this.$http("/projectAutoBalance/getConfig", null, "get", null, {
        projectId: val.id,
      }).then((res) => {
        if (res.result === 200) {
          this.editAutoBalanceConfigShow = true;
          if (res.data) {
            this.waitingTime = res.data.waitingTime / 1000;
            this.autoBalanceEnabled = res.data.enabled;
          } else {
            this.waitingTime = 0;
            this.autoBalanceEnabled = false;
          }
        }
      });
    },
    editAutoBalanceConfig_submit() {
      let data = {
        waitingTime: this.waitingTime * 1000,
        enabled: this.autoBalanceEnabled,
        projectId: this.projectId,
      };
      this.$service("/projectAutoBalance/editAutoBalanceConfig", data).then(
        (res) => {
          if (res.result === 200) {
            this.editAutoBalanceConfigShow = false;
            this.$message.success("修改成功");
          }
        }
      );
    },
    // 点击录音质检标准
    checkStandardClick(val) {
      this.checkStandardShow = true;
      this.projectId = val.id;
      this.$http(
        "/sip/questionnaire/getQuestionnaireStandard",
        null,
        "get",
        null,
        { projectId: val.id }
      ).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.checkStandard = res.data.content;
          }
        }
      });
    },
    // 提交录音质检标准
    checkStandard_submit() {
      let data = {
        projectId: this.projectId,
        content: this.checkStandard,
      };
      this.$service(
        "/sip/questionnaire/updateQuestionnaireStandard",
        data
      ).then((res) => {
        if (res.result === 200) {
          this.checkStandardShow = false;
          this.$message.success("提交成功");
        }
      });
    },
    // 关闭录音质检标准
    checkStandardClose() {
      this.checkStandard = "";
    },
    // 点击重拨控制
    redialClick(val) {
      this.redialShow = true;
      this.redialEnable = val.enableTaskAutoRecall;
      this.projectId = val.id;
      this.userLoginName = val.userLoginName;
      let data = {
        projectId: val.id,
        userLoginName: val.userLoginName,
      };
      this.$http(
        "/sip/project/taskRecallIntervalInfo",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          if (res.data.intervalInfo) {
            let intervalInfo = res.data.intervalInfo.split(",");
            intervalInfo = intervalInfo.filter((item) => item);
            this.redialForm = {
              intervalSecondsForFirst: Number(intervalInfo[0]),
              intervalSecondsForSecond: Number(intervalInfo[1]),
              intervalSecondsForThird: Number(intervalInfo[2]),
              intervalSecondsForFour: Number(intervalInfo[3]),
              intervalSecondsForFive: Number(intervalInfo[4]),
              intervalSecondsForSix: Number(intervalInfo[5]),
            };
          } else {
            this.redialForm = {
              intervalSecondsForFirst: 3600,
              intervalSecondsForSecond: 3600,
              intervalSecondsForThird: 3600,
              intervalSecondsForFour: 3600,
              intervalSecondsForFive: 3600,
              intervalSecondsForSix: 3600,
            };
          }
          if (res.data.resetIntervalInfo) {
            let resetIntervalInfo = res.data.resetIntervalInfo.split("");
            this.redialCheck = {
              firstCheck: resetIntervalInfo[0] == "1",
              secondCheck: resetIntervalInfo[1] == "1",
              thirdCheck: resetIntervalInfo[2] == "1",
              fourCheck: resetIntervalInfo[3] == "1",
              fiveCheck: resetIntervalInfo[4] == "1",
              sixCheck: resetIntervalInfo[5] == "1",
            };
          } else {
            this.redialCheck = {
              firstCheck: false,
              secondCheck: false,
              thirdCheck: false,
              fourCheck: false,
              fiveCheck: false,
              sixCheck: false,
            };
          }
        }
      });
    },
    // 确定重拨间隔
    redial_submit() {
      let intervalInfo = "";
      for (let i in this.redialForm) {
        intervalInfo += this.redialForm[i] + ",";
      }
      let resetIntervalInfo = "";
      for (let i in this.redialCheck) {
        if (this.redialCheck[i]) {
          resetIntervalInfo += "1";
        } else {
          resetIntervalInfo += "0";
        }
      }
      let data = {
        projectId: this.projectId,
        userLoginName: this.userLoginName,
        intervalInfo: intervalInfo,
        resetIntervalInfo: resetIntervalInfo,
        enableTaskAutoRecall: this.redialEnable,
      };
      this.$service("/sip/project/modifyTaskRecallIntervalInfo", data).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.redialShow = false;
            this.inquireSingleProject({
              pageNo: this.currentPage,
              pageSize: 10,
              id: this.projectId,
            });
          }
        }
      );
    },
    // 关闭重拨间隔
    redialClose() {
      this.redialEnable = false;
      this.redialForm = {
        intervalSecondsForFirst: 3600,
        intervalSecondsForSecond: 3600,
        intervalSecondsForThird: 3600,
        intervalSecondsForFour: 3600,
        intervalSecondsForFive: 3600,
        intervalSecondsForSix: 3600,
      };
    },
    // 选择第一遍重拨间隔
    redialSecondsForFirstChange(val) {
      this.oldRedialDate.first = this.setHoursTooltip(val);
    },
    // 选择第二遍重拨间隔
    redialSecondsForSecondChange(val) {
      this.oldRedialDate.second = this.setHoursTooltip(val);
    },
    // 选择第三遍重拨间隔
    redialSecondsForThirdChange(val) {
      this.oldRedialDate.third = this.setHoursTooltip(val);
    },
    // 选择第四遍重拨间隔
    redialSecondsForFourChange(val) {
      this.oldRedialDate.four = this.setHoursTooltip(val);
    },
    // 选择第五遍重拨间隔
    redialSecondsForFiveChange(val) {
      this.oldRedialDate.five = this.setHoursTooltip(val);
    },
    // 选择第六遍重拨间隔
    redialSecondsForSixChange(val) {
      this.oldRedialDate.six = this.setHoursTooltip(val);
    },
    // 处理间隔提示小时--提示
    setHoursTooltip(val) {
      switch (val) {
        case 1800:
          return "半小时";
          break;
        case 3600:
          return "1小时";
          break;
        case 7200:
          return "2小时";
          break;
        case 10800:
          return "3小时";
          break;
        case 14400:
          return "4小时";
          break;
        case 18000:
          return "5小时";
          break;
        case 21600:
          return "6小时";
          break;
        case 43200:
          return "12小时";
          break;
        case 86400:
          return "1天";
          break;
        case 172800:
          return "2天";
          break;
        case 259200:
          return "3天";
          break;
        case -1:
          return "不拨打";
          break;
      }
    },
    // 点击模板选择
    templateSelection(type) {
      this.$refs.taksTypeTemplate.init();
    },
    // 点击选择模板选择
    selectTemplate(val) {
      this.template_name_label = val.template_name_label;
      this.hangupSmsStrategyTemplateId = val.hangupSmsStrategyTemplateId;
    },
    unitPriceInput() {
      //限制input输入
      let inputVal = this.singleDeductionForm.unitPrice;
      inputVal = this.$common.numberCheck(inputVal);
      this.singleDeductionForm.unitPrice = inputVal;
    },
    miniAverageInput() {
      //限制input输入
      let inputVal = this.singleDeductionForm.miniAverageInputCount;
      inputVal = this.$common.numberCheck(inputVal);
      this.singleDeductionForm.miniAverageInputCount = inputVal;
    },
    noonDeductInput() {
      //限制input输入
      let inputVal = this.singleDeductionForm.noonDeduct;
      inputVal = this.$common.numberCheck(inputVal);
      this.singleDeductionForm.noonDeduct = inputVal;
    },
    nightDeductInput() {
      //限制input输入
      let inputVal = this.singleDeductionForm.nightDeduct;
      inputVal = this.$common.numberCheck(inputVal);
      this.singleDeductionForm.nightDeduct = inputVal;
    },
    // 选择第一遍间隔
    intervalSecondsForFirstChange(val) {
      this.intervalDate.first = this.setTooltip(val);
    },
    // 选择第二遍间隔
    intervalSecondsForSecondChange(val) {
      this.intervalDate.second = this.setTooltip(val);
    },
    // 选择第三遍间隔
    intervalSecondsForThirdChange(val) {
      this.intervalDate.third = this.setTooltip(val);
    },
    // 选择第四遍间隔
    intervalSecondsForFourthChange(val) {
      this.intervalDate.fourth = this.setTooltip(val);
    },
    // 点击间隔控制
    intervalClick(val) {
      this.intervalShow = true;
      this.enableTaskRecreateIntervalControl =
        val.enableTaskRecreateIntervalCheck;
      this.projectId = val.id;
      let data = {
        projectId: val.id,
      };
      this.getTaskRecreateIntervalInfo(data);
    },
    // 确定间隔控制
    interval_submit() {
      let data = {
        projectId: this.projectId,
        intervalSecondsForFirst: this.intervalForm.intervalSecondsForFirst,
        intervalSecondsForSecond: this.intervalForm.intervalSecondsForSecond,
        intervalSecondsForThird: this.intervalForm.intervalSecondsForThird,
        intervalSecondsForFourth: this.intervalForm.intervalSecondsForFourth,
        enableTaskRecreateIntervalControl:
          this.enableTaskRecreateIntervalControl,
      };
      this.setTaskRecreateIntervalInfo(data);
    },
    // 关闭间隔控制
    intervalClose() {
      this.enableTaskRecreateIntervalControl = false;
      this.intervalForm = {
        intervalSecondsForFirst: 86400,
        intervalSecondsForSecond: 86400,
        intervalSecondsForThird: 86400,
        intervalSecondsForFourth: 86400,
      };
      this.intervalDate = {
        first: 1,
        second: 1,
        third: 1,
        fourth: 1,
      };
    },
    // 处理间隔提示天数
    setTooltip(val) {
      switch (val) {
        case 86400:
          return 1;
          break;
        case 172800:
          return 2;
          break;
        case 259200:
          return 3;
          break;
      }
    },
    // 获取间隔时间
    getTaskRecreateIntervalInfo(data) {
      this.$http(
        "/sip/project/taskRecreateIntervalInfo",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          this.intervalForm = {
            intervalSecondsForFirst: res.data.intervalSecondsForFirst,
            intervalSecondsForSecond: res.data.intervalSecondsForSecond,
            intervalSecondsForThird: res.data.intervalSecondsForThird,
            intervalSecondsForFourth: res.data.intervalSecondsForFourth,
          };
          this.intervalDate = {
            first: this.setTooltip(res.data.intervalSecondsForFirst),
            second: this.setTooltip(res.data.intervalSecondsForSecond),
            third: this.setTooltip(res.data.intervalSecondsForThird),
            fourth: this.setTooltip(res.data.intervalSecondsForFourth),
          };
        }
      });
    },
    // 修改间隔时间
    setTaskRecreateIntervalInfo(data) {
      this.$service("/sip/project/modifyTaskRecreateIntervalInfo", data).then(
        (res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.intervalShow = false;
            this.inquireSingleProject({
              pageNo: this.currentPage,
              pageSize: 10,
              id: this.projectId,
            });
          }
        }
      );
    },
    // 用户名称获取焦点
    NameRemoteFocus() {
      this.inquireLoginName({
        pageSize: 20,
        pageNo: 1,
        enabled: true,
      });
    },
    //搜索用户名称
    remoteMethodLoginName(userName) {
      this.loadingLoginName = true;
      setTimeout(() => {
        this.loadingLoginName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireLoginName(params);
      }, 200);
    },
    // 查询用户名称
    inquireLoginName(params) {
      this.loginNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            item.label = item.userName;
            this.loginNameList.push(item);
          });
          // this.loginNameList.unshift({loginName:"",label:'全部'});
        }
      });
    },
    // 点击成单扣除
    singleDeduction(val) {
      this.singleDeductionShow = true;
      this.projectId = val.id;
      this.taskServer = val.server;
      this.enabledDeductSwitch = val.enableAutoDeduction;
      this.enableAverageDeduction = val.enableAverageDeduction;
      this.singleDeductionForm = {
        unitPrice: val.autoDeductionUnitPrice,
        noonDeduct: val.miniSuccessCountAtMorning,
        nightDeduct: val.miniSuccessCountAtAfternoon,
        miniAverageInputCount: val.miniAverageSuccessCount,
      };
    },
    // 提交成单扣除
    submitDeduct() {
      let data = {
        projectId: this.projectId,
        autoDeductionUnitPrice: (
          Number(this.singleDeductionForm.unitPrice) * 1000
        ).toFixed(0),
        enabled: this.enabledDeductSwitch,
        enableAverageDeduction: this.enableAverageDeduction,
      };
      if (this.enableAverageDeduction) {
        //启用均单扣除
        if (
          this.singleDeductionForm.miniAverageInputCount ||
          this.singleDeductionForm.miniAverageInputCount === 0
        ) {
          data.miniAverageSuccessCount = Number(
            this.singleDeductionForm.miniAverageInputCount
          );
        } else {
          data.miniAverageSuccessCount = 0;
        }
      } else {
        if (
          this.singleDeductionForm.noonDeduct ||
          this.singleDeductionForm.noonDeduct === 0
        ) {
          data.miniSuccessCountAtMorning = Number(
            this.singleDeductionForm.noonDeduct
          );
        } else {
          data.miniSuccessCountAtMorning = 0;
        }
        if (
          this.singleDeductionForm.nightDeduct ||
          this.singleDeductionForm.nightDeduct === 0
        ) {
          data.miniSuccessCountAtAfternoon = Number(
            this.singleDeductionForm.nightDeduct
          );
        } else {
          data.miniSuccessCountAtAfternoon = 0;
        }
      }
      if (
        this.singleDeductionForm.unitPrice ||
        this.singleDeductionForm.unitPrice === 0
      ) {
        let path = "https://" + this.taskServer + this.$constants.basePath;
        this.$service(
          "/sip/project/updateAutoDeductionInfo",
          data,
          "post",
          path,
          null
        ).then((res) => {
          if (res.result === 200) {
            this.singleDeductionShow = false;
            this.$message({ message: "修改成功", type: "success" });
            this.inquireSingleProject({
              pageNo: this.currentPage,
              pageSize: 10,
              id: this.projectId,
            });
          }
        });
      } else {
        this.$message({ message: "请输入单价", type: "warning" });
      }
    },
    // 关闭成单扣除
    singleDeductionDialog() {
      this.enabledDeductSwitch = false;
      this.enableAverageDeduction = false;
      this.singleDeductionForm = {
        unitPrice: 0,
        noonDeduct: 0,
        nightDeduct: 0,
        miniAverageInputCount: 0,
      };
    },
    // 点击排序
    setSort() {
      this.setSortShow = true;
      this.questionManageList = [];
      this.questionManageValue.forEach((menu) => {
        this.questionManageData.forEach((item) => {
          if (item.key === menu) {
            this.questionManageList.push({
              id: item.key,
              label: item.label,
            });
          }
        });
      });
    },
    allowDrop(draggingNode, dropNode, type) {
      //菜单拖拽时判定目标节点能否被放置
      if (type === "inner") {
        return false;
      } else {
        return true;
      }
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      //菜单拖拽成功时触发的事件
      let list = [];
      this.questionManageList.forEach((item) => {
        list.push(item.id);
      });
      this.questionManageValue = list;
      this.questionIds = list;
    },
    noticeClick(val) {
      //点击公告
      this.noticeShow = true;
      this.projectId = val.id;
      this.userLoginName = val.userLoginName;
      this.notice = val.notice;
    },
    notice_submit() {
      //点击公告确定
      let data = {
        projectId: this.projectId,
        loginName: this.userLoginName,
        notice: this.notice,
      };
      this.$http("/sip/project/modifyNotice", data).then((res) => {
        if (res.result == 200) {
          this.notice_cencel();
          this.inquireSingleProject({
            pageNo: this.currentPage,
            pageSize: 10,
            id: this.projectId,
          });
          this.$message({ message: "修改公告成功", type: "success" });
        }
      });
    },
    notice_cencel() {
      //点击公告取消
      this.noticeShow = false;
    },
    noticeClose() {
      //关闭公告
      this.notice = "";
    },
    userNameIdChange(loginName) {
      //请选择用户变化
      // console.log(loginName)
      let params = {
        pageNo: 1,
        pageSize: 1000,
        loginName: loginName,
      };
      this.defaultRoute = [];
      this.defaultTeam = [];
      this.inquireDefaultTeam(params);
      this.inquireDefaultRoute(params);
    },
    // inquireUserList() {//查询用户--新增
    //   let params = {
    //     pageSize: 1000,
    //     pageNo: 1,
    //   }
    //   this.$http('/user/list', null, 'get', null, params).then(res => {
    //     if (res.result == 200) {
    //       let list = res.data.list;
    //       this.userNameIdList = list;
    //     }
    //   })
    // },
    optionTypeChange(val) {
      //分组名改变
      let params = {
        groupId: val,
      };
      this.inquirequestionGroupDetail(params);
    },
    questionManageClick() {
      //关闭问卷管理
      this.groupingName = "";
    },
    inquirequestionGroupDetail(params) {
      //查询分组详情
      this.$http(
        "/sip/question/questionGroupDetail",
        null,
        "get",
        null,
        params
      ).then((res) => {
        if (res.result == 200) {
          let list = res.data.allSipQuestion;
          let sipList = res.data.sipQuestionGroup.sipQuestionList;
          // this.allSipQuestion = list;
          this.questionManageValue = [];
          this.questionManageData = [];
          this.pinyin = [];
          this.questionIds = [];
          list.forEach((item, index) => {
            this.pinyin.push(item.question);
            let label = "";
            if (item.optionList) {
              item.optionList.forEach((menu, i) => {
                label += menu.option + ",";
              });
            }
            label = this.$common.answerSplicing(label); // 问卷穿梭框显示内容处理(问题+答案)
            let obj = {
              label: item.question + label,
              key: item.id,
              pinyin: this.pinyin[index],
            };
            this.questionManageData.push(obj);
          });
          sipList.map((menu) => {
            this.questionManageValue.push(menu.id);
            this.questionIds.push(menu.id);
          });
        }
      });
    },
    currentPage_data(index) {
      //当前页
      this.currentPage = index;
      let params = {
        pageSize: this.pageSize,
        pageNo: index,
        projectName: this.condition.projectName,
        loginName: this.condition.userName,
      };
      this.inquireProject(params);
    },
    sizeChange(val) {
      //显示条数--分页
      this.pageSize = val;
      this.currentPage = 1;
      let params = {
        pageSize: this.pageSize,
        pageNo: 1,
        projectName: this.condition.projectName,
        loginName: this.condition.userName,
      };
      this.inquireProject(params);
    },
    inquire() {
      //查询列表
      this.currentPage = 1;
      let params = {
        pageSize: this.pageSize,
        pageNo: 1,
        projectName: this.condition.projectName,
        loginName: this.condition.userName,
      };
      this.inquireProject(params);
    },
    inquireProject(params) {
      //查询项目列表
      this.loading = true;
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.list;
          this.total = res.data.count;
          list.map((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            switch (item.defaultTaskType) {
              case "NORMAL":
                item.defaultTaskTypeLabel = "普通";
                break;
              case "PROLOGUE":
                item.defaultTaskTypeLabel = "开场白+人工";
                break;
              case "PROLOGUE_SMS":
                item.defaultTaskTypeLabel = "开场白+短信";
                break;
              case "PROLOGUE_WECHAT":
                item.defaultTaskTypeLabel = "开场白+微信";
                break;
              case "AI_ROBOT":
                item.defaultTaskTypeLabel = "AI机器人";
                break;
            }
          });
          this.tableData = list;
        }
      });
    },
    // 查询单个项目
    inquireSingleProject(params) {
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          // this.total = res.data.count;
          list.map((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            switch (item.defaultTaskType) {
              case "NORMAL":
                item.defaultTaskTypeLabel = "普通";
                break;
              case "PROLOGUE":
                item.defaultTaskTypeLabel = "开场白+人工";
                break;
              case "PROLOGUE_SMS":
                item.defaultTaskTypeLabel = "开场白+短信";
                break;
              case "PROLOGUE_WECHAT":
                item.defaultTaskTypeLabel = "开场白+微信";
                break;
              case "AI_ROBOT":
                item.defaultTaskTypeLabel = "AI机器人";
                break;
            }
            this.tableData.forEach((menu) => {
              if (item.id === menu.id) {
                for (let i in item) {
                  menu[i] = item[i];
                }
              }
            });
          });
        }
      });
    },
    reviseProject(val) {
      //修改项目
      this.reviseProjectShow = true;
      this.inquireDefaultTeam({
        pageNo: 1,
        pageSize: 1000,
        loginName: val.userLoginName,
      });
      this.inquireDefaultRoute({
        pageNo: 1,
        pageSize: 1000,
        loginName: val.userLoginName,
      });
      this.reviseProjectForm.projectName = val.projectName;
      this.addWechatHelloWorld = "";
      // this.addWechatHelloWorld = val.defaultWechatHelloWorld;
      this.reviseProjectForm.defaultMobileVisible = val.defaultMobileVisible;
      this.reviseProjectForm.userName = val.userName;
      this.reviseProjectForm.projectType = val.categoryId;
      this.reviseProjectForm.blackListSource = val.blackListSource;
      this.defaultTeamId = val.defaultTeamId;
      this.defaultRouteId = val.defaultRouteId;
      this.defaultCallbackRouteId = val.defaultCallbackRouteId;
      this.projectId = val.id;
      this.userLoginName = val.userLoginName;
      this.taskTypePrologue = val.defaultTaskType;
      this.$http("/sip/project/projectDetail", null, "get", null, {
        projectId: val.id,
      }).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.hangupSmsStrategyTemplateId =
              res.data.defaultHangupSmsStrategyTemplateId;
            this.template_name_label =
              res.data.defaultHangupSmsStrategyTemplateName;
          }
        }
      });
      this.getProjectTypeList();
    },
    deleteProject(id) {
      //删除项目
      let data = {
        projectId: id,
        delete: true,
      };
      this.$confirm("删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          this.$http("/sip/project/modify", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.inquire();
            }
          });
        })
        .catch(() => {});
    },
    triggerAdd() {
      //点击新增
      this.addProjectShow = true;
      this.inquireDefaultTeam({
        pageNo: 1,
        pageSize: 1000,
        loginName: this.loginName,
      });
      this.inquireDefaultRoute({
        pageNo: 1,
        pageSize: 1000,
        loginName: this.loginName,
      });
      this.questionGroupListAdd();
      this.getProjectTypeList();
    },
    getProjectTypeList() {
      this.$http("/sip/project/projectCategoryList", null, "get").then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            this.projectTypeList = list;
          }
        }
      );
    },
    closeProjectDialog() {
      //关闭新增项目
      this.addProjectForm = {
        projectName: "",
        defaultTeamId: "",
        defaultRouteId: "",
        defaultCallbackRouteId: "",
        userNameId: "",
        groupId: "",
        projectType: "",
        blackListSource: [],
        defaultAddWechat: false,
        defaultMobileVisible: true,
      };
      this.addWechatHelloWorld = "";
      this.addWechatClientGroupName = "";
      this.defaultTeam = [];
      this.defaultRoute = [];
      this.taskTypePrologue = "";
      this.template_name_label = "";
      this.hangupSmsStrategyTemplateId = "";
    },
    inquireDefaultTeam(params) {
      //查询默认列队
      this.$http("/sip/team/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.map((item) => {
              item.newName = item.name + "(" + item.clientCount + ")";
              if (item.id == this.defaultTeamId) {
                this.reviseProjectForm.defaultTeamId = item.id;
              }
            });
            this.defaultTeam = res.data.records;
          }
        }
      });
    },
    inquireDefaultRoute(params) {
      //查询默认线路
      this.$http("/route/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          if (res.data.list && res.data.list.length > 0) {
            res.data.list.map((item) => {
              if (item.id == this.defaultRouteId) {
                this.reviseProjectForm.defaultRouteId = item.id;
              }
              if (item.id == this.defaultCallbackRouteId) {
                this.reviseProjectForm.defaultCallbackRouteId = item.id;
              }
            });
            this.defaultRoute = res.data.list;
          }
        }
      });
    },
    inquireBlacklistFindList() {
      //查询黑名单列表
      this.blackListSourceList = [];
      this.$http("/blacklist/findList", null, "get", null).then((res) => {
        if (res.result == 200) {
          let list = res.data;
          list.forEach((item) => {
            this.blackListSourceList.push({
              value: item,
              label: item,
            });
          });
        }
      });
    },
    submitProject() {
      //提交新增
      let data = {
        projectName: this.addProjectForm.projectName,
        defaultTeamId: this.addProjectForm.defaultTeamId,
        defaultRouteId: this.addProjectForm.defaultRouteId,
        defaultCallbackRouteId: this.addProjectForm.defaultCallbackRouteId,
        loginName: this.addProjectForm.userNameId,
        questionGroupId: this.addProjectForm.groupId,
        categoryId: this.addProjectForm.projectType,
        defaultTaskType: this.taskTypePrologue,
        defaultHangupSmsStrategyTemplateId: this.hangupSmsStrategyTemplateId,
        defaultMobileVisible: this.addProjectForm.defaultMobileVisible,
        defaultWechatHelloWorld: this.addWechatHelloWorld,
        blackListSource: this.addProjectForm.blackListSource.join(","),
      };
      switch (this.taskTypePrologue) {
        case "NORMAL":
          data.defaultPrologueId = 0;
          break;
      }
      if (
        !data.projectName ||
        !data.defaultRouteId ||
        !data.defaultCallbackRouteId ||
        !data.defaultTeamId ||
        !data.categoryId
      ) {
        this.$message({ message: "信息不完整", type: "warning" });
        return;
      }
      this.$service("/sip/project/add", data).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "新增项目成功", type: "success" });
          this.inquire();
          this.addProjectShow = false;
        }
      });
    },
    cancelProject() {
      //取消新增醒目
      this.addProjectShow = false;
    },
    closereviseProject() {
      //关闭修改项目
      this.reviseProjectForm.defaultTeamId = "";
      this.reviseProjectForm.defaultRouteId = "";
      this.reviseProjectForm.defaultCallbackRouteId = "";
      this.reviseProjectForm.userName = "";
      this.reviseProjectForm.projectType = "";
      this.reviseProjectForm.blackListSource = [];
      this.addWechatHelloWorld = "";
      this.addWechatClientGroupName = "";
      this.reviseProjectForm.defaultMobileVisible = true;
      this.defaultTeam = [];
      this.defaultRoute = [];
      this.taskTypePrologue = "";
      this.template_name_label = "";
      this.hangupSmsStrategyTemplateId = "";
    },
    submitReviseProject() {
      //提交修改选项
      let data = {
        projectName: this.reviseProjectForm.projectName,
        defaultTeamId: this.reviseProjectForm.defaultTeamId,
        defaultRouteId: this.reviseProjectForm.defaultRouteId,
        defaultCallbackRouteId: this.reviseProjectForm.defaultCallbackRouteId,
        defaultMobileVisible: this.reviseProjectForm.defaultMobileVisible,
        categoryId: this.reviseProjectForm.projectType,
        blackListSource: this.reviseProjectForm.blackListSource.join(","),
        projectId: this.projectId,
        loginName: this.userLoginName,
        defaultTaskType: this.taskTypePrologue,
        defaultHangupSmsStrategyTemplateId: this.hangupSmsStrategyTemplateId,
        defaultWechatHelloWorld: this.addWechatHelloWorld,
        delete: false,
      };
      switch (this.taskTypePrologue) {
        case "NORMAL":
          data.defaultPrologueId = 0;
          break;
      }
      this.$service("/sip/project/modify", data).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "修改项目成功", type: "success" });
          this.inquireSingleProject({
            pageNo: this.currentPage,
            pageSize: 10,
            id: this.projectId,
          });
          this.reviseProjectShow = false;
        }
      });
    },
    cencelReviseProject() {
      //取消修改
      this.reviseProjectShow = false;
    },
    questionGroupListAdd() {
      //查询分组名--新增
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.loginName,
        groupName: "",
      };
      if (this.roleMenu == "SUPER_MANAGER") {
        params.queryTemplate = true;
      }
      this.$http(
        "/sip/question/questionGroupList",
        null,
        "get",
        null,
        params
      ).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          list.map((item) => {
            item.createdTime = this.$common.transformTime(item.gmtCreated);
          });
          this.groupIdList = list;
        }
      });
    },
    questionGroupList(loginName) {
      //查询分组名--问卷管理
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: loginName,
        groupName: "",
      };
      this.$http(
        "/sip/question/questionGroupList",
        null,
        "get",
        null,
        params
      ).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          list.map((item) => {
            item.createdTime = this.$common.transformTime(item.gmtCreated);
          });
          this.groupingNameTypeList = list;
        }
      });
    },
    questionManage(val) {
      //点击问卷管理
      this.questionManageShow = true;
      this.questionIds = [];
      this.projectId = val.id;
      this.userLoginName = val.userLoginName;
      this.inquireQuestion();
      this.questionGroupList(val.userLoginName);
      let newQuestionIds = [];
      if (val.questionIds) {
        newQuestionIds = val.questionIds.split(",");
      }
      newQuestionIds.forEach((item) => {
        item = parseInt(item);
        this.questionManageValue.push(item);
        this.questionIds.push(item);
      });
    },
    chenageQuestionManage_submit() {
      //提交问卷管理
      let newQuestionIds = this.questionIds.filter((item) => item);
      let data = {
        projectId: this.projectId,
        questionIds: newQuestionIds.join(","),
      };
      this.$http("/sip/project/updateQuestionList", data).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "问卷管理操作成功", type: "success" });
          this.inquireSingleProject({
            pageNo: this.currentPage,
            pageSize: 10,
            id: this.projectId,
          });
          this.questionManageShow = false;
        }
      });
    },
    leftCheckChange(val) {
      //穿梭框左侧选中
      this.leftCheckId = val;
    },
    handleChangeQuestion(value, direction, movedKeys) {
      //问卷管理值发生改变
      let changeQuestionId = value;
      let leftCheckId = this.leftCheckId;
      if (direction == "right") {
        let newRightArr = this.questionIds.concat(leftCheckId);
        let temp = []; //一个新的临时数组
        for (var i = 0; i < newRightArr.length; i++) {
          if (temp.indexOf(newRightArr[i]) == -1) {
            temp.push(newRightArr[i]);
          }
        }
        this.questionIds = temp;
      } else if (direction == "left") {
        this.questionIds = changeQuestionId;
      }
    },
    inquireQuestion() {
      //查询问卷
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.userLoginName,
      };
      // 清空数据
      this.questionManageData = [];
      this.questionManageValue = [];
      this.pinyin = [];
      this.$http("/sip/question/list", null, "get", null, params).then(
        (res) => {
          if (res.result == 200) {
            let list = res.data.list;
            list.forEach((item, index) => {
              this.pinyin.push(item.question);
              let label = "";
              if (item.optionList) {
                item.optionList.forEach((menu, i) => {
                  label += menu.option + ",";
                });
              }
              label = this.$common.answerSplicing(label); // 问卷穿梭框显示内容处理(问题+答案)
              let obj = {
                label: item.question + label,
                key: item.id,
                pinyin: this.pinyin[index],
              };
              this.questionManageData.push(obj);
            });
            this.allSipQuestion = list;
          }
        }
      );
    },
    previewClick() {
      //点击预览
      this.previewVisible = true;
      let idList = this.questionManageValue;
      let list = this.allSipQuestion;
      this.projectQuestionList = [];
      idList.map((i) => {
        list.map((j) => {
          if (i == j.id) {
            j.newQuestion = j.question + "：";
            this.projectQuestionList.push(j);
          }
        });
      });
    },
    taggingManage(projectId) {
      //点击标注管理
      this.taggingManageShow = true;
      let params = {
        projectId: projectId,
      };
      this.$http("/sip/flag/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data;
          list.map((item) => {
            item.flagSrc = require("@/assets/img/" +
              "flag_" +
              item.flag +
              ".png");
            item.inputDisabled = false;
            if (item.disabled || !item.editable) {
              item.inputDisabled = true;
            }
          });
          this.taggingManageList = list;
        }
      });
    },
    modifyFlagSetting(value, disabled) {
      //禁用or启用
      this.doModifyFlagSetting(value, disabled);
    },
    refresh(id) {
      //监听input
      this.input["input_" + id] = true;
    },
    doModifyFlagSetting(value, disabled) {
      //修改标注
      let data = {
        projectId: value.projectId,
        flag: value.flag,
        label: value.label,
        disabled: disabled,
      };
      this.$http("/sip/flag/modifyFlagSetting", data).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "操作成功", type: "success" });
          this.taggingManage(data.projectId);
        }
      });
    },
    closeTagging() {
      //关闭标注管理
      this.input = {};
    },
  },
};
</script>
<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>
<style scoped="scoped">
.smsTemplate >>> .el-table__fixed-right {
  height: 100% !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown {
  font-size: 12px;
  margin-left: 10px;
}
.el-dropdown-menu .el-button {
  display: block;
  width: 95px;
  border-radius: 0px;
}
.el-dropdown-menu .el-button + .el-button {
  margin: 0;
}
.el-dropdown-menu .el-button:hover {
  background: #ecf5ff;
}
.clear {
  margin-left: 10px;
  color: rgb(179, 174, 174);
}
.switch_style_m_w {
  position: relative;
}
.switch_style_m_w .addWechat_info {
  position: absolute;
  top: 0px;
  left: 50px;
  width: 600px;
}
.revise_form >>> .el-form-item {
  width: 340px;
}
.revise_form >>> .el-form-item .el-input,
.revise_form >>> .el-form-item .el-select {
  width: 210px;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
.connectedTable >>> .el-table__body .cell {
  font-size: 14px;
}
.connectedTable >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 0 0 20px 0;
}
.transferClass >>> .el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #c0c4cc !important;
  background-color: #fff;
  border-color: #ebeef5;
}
.prologue_class {
  display: flex;
  justify-content: space-between;
}
.el-questionManage >>> .el-dialog__body {
  padding: 0 20px;
}
.el-questionManage .el-select {
  margin-bottom: 10px;
}
.transferClass >>> .el-transfer-panel__item {
  display: block !important;
}
.transferClass >>> .el-transfer__button:first-child {
  margin-bottom: 10px !important;
}
.transferClass >>> .el-transfer__button {
  display: block !important;
  margin: 0 auto !important;
  padding: 10px !important;
  border-radius: 50% !important;
  font-size: 0 !important;
}
.el-taggingManage .el-input {
  width: 170px;
  margin: 0 30px;
}
.el-taggingManage .demo-dynamic .el-input {
  width: 200px;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.el-form-item .el-select {
  width: 95%;
}
.upload-demo >>> .el-upload-dragger {
  width: 320px !important;
}
.el-input {
  width: 95%;
}
.el-addProject .el-form {
  margin-right: 38px;
}
.projectStatus {
  margin-bottom: 20px;
}
.projectStatus .el-input,
.projectStatus .el-select {
  margin-left: 20px;
  width: 260px;
}
.projectStatus .el-button {
  display: block;
  margin: 20px 0;
}
.connectedCountTime .call-startTime {
  width: 70px !important;
  margin: 8px 8px 8px 24px;
}
.connectedCountTime .call-endTime {
  width: 70px !important;
  margin: 8px;
}
.gmtCreatedTime .startTime {
  width: 200px !important;
  margin: 8px;
}
.gmtCreatedTime .endTime {
  width: 200px !important;
  margin: 8px;
}
.el-addProject >>> .el-dialog__body {
  padding-bottom: 0;
  padding-top: 0;
}
.question_input {
  width: 60%;
}
.question_textarea {
  width: 80%;
}
</style>
