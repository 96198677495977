<template>
  <div class="monitor-content">
    <div class="monitor-id">
      <div
        class="monitorTaskClass"
        style="margin-right: 20px"
        v-if="roleMenu == 'SUPER_MANAGER'"
      >
        用户名称：
        <el-select
          v-model="userLoginName"
          filterable
          remote
          :loading="loadingName"
          :remote-method="remoteMethodLoginName"
          @focus="NameRemoteFocus"
          placeholder="请选择用户名称"
          @change="loginNameChange"
          clearable
          class="userName_id"
        >
          <el-option
            v-for="item in loginNameList"
            :key="item.id"
            :value="item.loginName"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="monitorTaskClass">
        任务名称：
        <el-select
          v-if="subtaskShow == '0'"
          v-model="taskId"
          filterable
          placeholder="请选择任务名称"
          class="select-id"
          @change="taskNameChange"
        >
          <el-option-group
            v-for="group in projectNameGroupList"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-option-group>
        </el-select>
        <el-select
          v-if="subtaskShow == '1'"
          v-model="taskId"
          filterable
          placeholder="请选择任务名称"
          class="select-id"
          @change="taskNameChange"
        >
          <el-option-group
            v-for="group in projectNameGroup_list"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <el-button
        type="primary"
        @click="inquire('onClick')"
        style="margin-left: 5px"
        >确定</el-button
      >
      <el-switch
        v-model="inquireOn"
        active-text="自动刷新"
        @change="switchChange"
        active-value="0"
        inactive-value="1"
        :disabled="switchAvailable"
        style="margin-left: 20px"
      >
      </el-switch>
      <el-switch
        v-model="subtaskShow"
        active-text="子任务"
        active-value="0"
        inactive-value="1"
        style="margin-left: 20px"
      >
      </el-switch>
      <div style="display: inline-block">
        <div class="task_icon">
          <el-tooltip
            :content="playPrologueTitle"
            v-if="playPrologueShow"
            placement="top"
          >
            <img class="img_k" src="@/assets/img/kaicbai.png" alt="" />
          </el-tooltip>
          <el-tooltip
            content="坐席可以查看手机号码"
            v-if="mobileVisibleShow"
            placement="top"
          >
            <img class="img_h" src="@/assets/img/hmcx.png" alt="" />
          </el-tooltip>
          <el-tooltip
            content="坐席可以看到微信添加按钮"
            v-if="addWechatShow"
            placement="top"
          >
            <img class="img_j" src="@/assets/img/jwx.png" alt="" />
          </el-tooltip>
          <el-tooltip
            content="连续异常过多, 降低拨打速度"
            v-if="exceptionLimitShow"
            placement="top"
          >
            <i class="el-icon-warning" style="color: #e6a23c"></i>
          </el-tooltip>
        </div>
      </div>
      <br />
      <span style="font-size: 14px" v-show="debugShow"
        >Debug：{{ callingMap }}</span
      >
    </div>
    <div class="overflowAuto">
      <div class="monitor">
        <!-- 外呼策略 -->
        <el-card class="bg-purple1">
          <div slot="header" class="clearfix">
            <h6>外呼策略{{ batchNumberTitle }}</h6>
            <el-popover
              placement="right"
              width="450"
              v-model="editWaitMillisShow"
              trigger="click"
              class="purple1-count-transfer"
              style="float: right"
            >
              <div style="font-weight: bold; margin-bottom: 10px">自动调整</div>
              <el-form class="el-form" label-width="130px">
                <el-form-item label="状态:">
                  <el-switch
                    v-model="editWaitMillisSwitch"
                    active-color="#13ce66"
                    @change="editWaitMillisSwitchChange"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item v-if="editWaitMillisSwitch">
                  <template slot="label">
                    期望等待时间
                    <el-tooltip placement="top">
                      <div slot="content">
                        等待时间低于正常水准的90%或超过110%会自动进行调整
                      </div>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                    :
                  </template>
                  <el-input
                    v-model="waitMillis"
                    style="width: 120px; margin-right: 5px"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-table
                :data="autoBalanceLogList"
                stripe
                style="width: 100%; margin-bottom: 10px"
                border
                size="mini"
              >
                <el-table-column prop="content" label="日志"></el-table-column>
              </el-table>
              <el-button
                type="primary"
                style="margin: auto; display: block"
                @click="editWaitMillisSubmit"
                >确定</el-button
              >
              <el-tooltip
                content="自动调整"
                placement="left"
                effect="light"
                slot="reference"
              >
                <el-button
                  class="el-icon-cpu opration"
                  @click="editWaitMillis(true)"
                  size="mini"
                  :disabled="setUpDis"
                  :style="{ color: editWaitMillisSwitch ? '#409eff' : '' }"
                ></el-button>
              </el-tooltip>
            </el-popover>
            <el-tooltip content="修改线路" placement="right" effect="light">
              <el-button
                class="el-icon-link opration"
                @click="switchLine"
                size="mini"
                :disabled="setUpDis"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="外呼策略" placement="left" effect="light">
              <el-button
                class="el-icon-s-operation opration"
                @click="clearfixSetUp"
                size="mini"
                :disabled="setUpDis"
              ></el-button>
            </el-tooltip>
          </div>
          <!-- 未启用挂机短信 -->
          <div v-if="!strategy_from_hangupsms_Show">
            <span>
              并发率
              <el-tooltip
                class="item"
                effect="light"
                :content="concurrentText"
                placement="top-start"
              >
                <i class="el-icon-question icon-color"></i>
              </el-tooltip>
              ：{{ strategy.routeMultiple }}
            </span>
            <span>
              呼叫超时(秒)
              <el-tooltip
                class="item"
                effect="light"
                :content="originateText"
                placement="top-start"
              >
                <i class="el-icon-question icon-color"></i>
              </el-tooltip>
              ：{{ strategy.originateTimeout }}
            </span>
            <span>
              坐席整理时间(秒)
              <el-tooltip
                class="item"
                effect="light"
                :content="answerText"
                placement="top-start"
              >
                <i class="el-icon-question icon-color"></i>
              </el-tooltip>
              ：{{ strategy.answerInterval }}
            </span>
            <span>
              坐席全满暂停外呼
              <el-tooltip
                class="item"
                effect="light"
                :content="stopText"
                placement="top-start"
              >
                <i class="el-icon-question icon-color"></i>
              </el-tooltip>
              ：{{ strategy.stopCallOnFullLabel }}
            </span>
            <span>
              坐席全满停止外呼(秒)
              <el-tooltip
                class="item"
                effect="light"
                :content="pauseText"
                placement="top-start"
              >
                <i class="el-icon-question icon-color"></i>
              </el-tooltip>
              ：{{ strategy.pauseSecondsOnFullLabel }}
            </span>
          </div>
          <!-- 启用挂机短信 -->
          <div v-if="strategy_from_hangupsms_Show">
            <span> 最大并发数 ：{{ maxRouteMultiple }} </span>
            <span>
              发挂机短信(秒)
              <el-tooltip
                class="item"
                effect="light"
                content="通话超过多少秒发送挂机短信"
                placement="top-start"
              >
                <i class="el-icon-question icon-color"></i>
              </el-tooltip>
              ：{{ smsSendTimeout }}
            </span>
            <span class="sms_content_style">
              <div class="sms_title">挂机短信内容：</div>
              <el-tooltip class="item" effect="light" placement="top-start">
                <div slot="content" class="sms_tooltip">{{ smsContent }}</div>
                <div>{{ smsContentLabel }}</div>
              </el-tooltip>
            </span>
          </div>
        </el-card>
        <!-- 拨打详情 -->
        <el-card class="bg-purple1">
          <div slot="header" class="clearfix">
            <h6>拨打详情{{ batchStatus }}</h6>
            <el-tooltip content="继续拨打" placement="right" effect="light">
              <el-button
                class="el-icon-video-play opration"
                @click="callPlay('play')"
                size="mini"
                :disabled="callPlayDis"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="暂停拨打" placement="left" effect="light">
              <el-button
                class="el-icon-video-pause opration"
                @click="callPlay('pause')"
                size="mini"
                :disabled="callpauseDis"
              ></el-button>
            </el-tooltip>
          </div>
          <span>
            拨打进度：<el-progress
              :text-inside="true"
              :stroke-width="18"
              :percentage="process.speedProcess"
              status="success"
            ></el-progress>
          </span>
          <div class="purple1-count">
            <span>拨打数：{{ process.dialProcess }}</span>
            <span>接通数：{{ process.connectedCount }}</span>
            <span>
              转接超时：
              <span
                class="purple1-count-transfer"
                v-if="roleMenu !== 'SUPER_MANAGER'"
                >{{ process.timeoutClearingCount }}</span
              >
              <el-popover
                placement="right"
                width="450"
                @show="transferClick"
                trigger="click"
                class="purple1-count-transfer"
              >
                <div style="font-weight: bold; margin-bottom: 10px">
                  超时统计
                </div>
                <!-- 转接时长列表 -->
                <el-table :data="transferData" size="mini" stripe border>
                  <el-table-column
                    property="customerCode"
                    label="客户编号"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    property="clientNumber"
                    label="坐席编号"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    width="100"
                    property="distributeMillisLabel"
                    label="转接时长(秒)"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    width="150"
                    property="callStartAtLabel"
                    label="拨打时间"
                    align="center"
                  ></el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination
                  class="transfer-pagination"
                  small
                  @current-change="clientCurrentPage_data"
                  :current-page.sync="clientCurrentPage"
                  :page-size="10"
                  layout="total, prev, pager, next, jumper"
                  :total="clientTotal"
                  :pager-count="5"
                  v-if="singlePage"
                ></el-pagination>
                <a
                  href="javascript:;"
                  slot="reference"
                  v-if="roleMenu === 'SUPER_MANAGER'"
                  style="color: #3a8ee6"
                  >{{ process.timeoutClearingCount }}</a
                >
              </el-popover>
            </span>
            <span>坐席转接率：{{ process.transferRate }}</span>
          </div>
          <div class="purple1-count" style="width: 140px">
            <span>当前并发：{{ process.currentConcurrency }}</span>
            <span>接通率：{{ process.connectedCountRate }}</span>
            <span>成单数：{{ process.successCount }}</span>
            <span>意向数：{{ process.needCallbackCount }}</span>
          </div>
        </el-card>
        <!-- 坐席状态 -->
        <el-card class="bg-purple2">
          <div slot="header" class="clearfix">
            <h6>坐席状态({{ clientTime }})</h6>
            <el-tooltip
              class="item"
              effect="light"
              content="坐席最近60通电话平均等待时间(秒)"
              placement="top-start"
            >
              <i class="el-icon-question icon-color"></i>
            </el-tooltip>
            <span style="display: inline-block; margin: 0 0 5px">{{
              sipTeamNameLabel
            }}</span>
          </div>
          <div class="statistics">
            <el-button
              :class="{ istalkclass: inCall }"
              type="text"
              @click="isScreen('talk')"
              >通话中：{{ talkingNum }}</el-button
            >
            <el-button
              :class="{ istalkclass: inWait }"
              type="text"
              @click="isScreen('wait')"
              >等待：{{ waitingNum }}</el-button
            >
            <el-button
              :class="{ istalkclass: inOn }"
              type="text"
              @click="isScreen('online')"
              >在线：{{ onlineNum }}</el-button
            >
            <el-button
              :class="{ istalkclass: inOff }"
              type="text"
              @click="isScreen('notRegist')"
              >离线：{{ notRegisteredNum }}</el-button
            >
          </div>
          <el-table
            :data="client"
            stripe
            style="width: 100%"
            height="555"
            border
          >
            <el-table-column
              prop="number"
              label="坐席编号"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <div class="client_number_td">
                  <div>{{ scope.row.number }}</div>
                  <div style="height: 18px">
                    <img
                      v-if="scope.row.status === 'TALKING'"
                      :src="require('@/assets/img/jianting.png')"
                      alt=""
                      width="18px"
                      @click="clickMonitor(scope.row.number)"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="receiveCount"
              label="接待数"
              width="75"
              align="center"
            ></el-table-column>
            <el-table-column prop label="状态(时长)" width align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.statusLabel }}{{ scope.row.clientTime }}</p>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <!-- 正在呼叫 -->
        <el-card class="bg-purple2">
          <div slot="header" class="clearfix">
            <h6>正在呼叫({{ outgoingTime }})</h6>
            <el-tooltip
              class="item"
              effect="light"
              content="客户最近60通电话平均等待时间(秒)"
              placement="top-start"
            >
              <i class="el-icon-question icon-color"></i>
            </el-tooltip>
          </div>
          <div class="statistics">
            <el-button
              :class="{ istalkclass: inTalk }"
              type="text"
              @click="isScreen('talking')"
              >拨打中：{{ callingNum }}</el-button
            >
            <el-button
              :class="{ istalkclass: inPark }"
              type="text"
              @click="isScreen('park')"
              >等待转接：{{ parkNum }}</el-button
            >
            <el-button
              :class="{ istalkclass: inPrologue }"
              type="text"
              @click="isScreen('prologue')"
              >开场白：{{ prologueNum }}</el-button
            >
          </div>
          <el-table
            :data="outgoing"
            stripe
            style="width: 100%"
            height="555"
            border
          >
            <el-table-column
              prop="customerCode"
              label="客户号码"
              width
              align="center"
            ></el-table-column>
            <el-table-column prop label="状态(时长)" width align="center">
              <template slot-scope="scope">
                <p>
                  {{ scope.row.statusLabel }}({{ scope.row.outgoingTime }} 秒)
                </p>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
      <!-- 折线图 -->
      <div class="echarts-card">
        <el-card class="card-lineEcharts">
          <div slot="header" class="clearfix">
            <h6>
              线路质量{{ lineName }}
              <el-tooltip class="item" effect="light" placement="top-start">
                <div slot="content">
                  <div>最近接通: 5分钟内接通数 / 5分钟内拨打数</div>
                  <div>接通: 累计接通数 / 累计拨打数</div>
                  <div v-if="roleMenu === 'SUPER_MANAGER'">
                    最近超过1分钟: 5分钟内超过1分钟数 / 5分钟内接通数
                  </div>
                  <div v-if="roleMenu === 'SUPER_MANAGER'">
                    最近秒挂: 5分钟内秒挂数 / 5分钟内接通数
                  </div>
                </div>
                <i class="el-icon-question icon-color"></i>
              </el-tooltip>
            </h6>
          </div>
          <div ref="countEcharts" class="lineEcharts"></div>
        </el-card>
        <el-card class="card-lineEcharts" v-if="roleMenu == 'SUPER_MANAGER'">
          <div slot="header" class="clearfix">
            <h6>系统压力{{ systemName }}</h6>
          </div>
          <div ref="systemEcharts" class="lineEcharts"></div>
        </el-card>
        <el-card class="card-lineEcharts">
          <div slot="header" class="clearfix">
            <h6>区域接通</h6>
            <div class="changeArea">
              <el-radio v-model="isArea" label="area">地区</el-radio>
              <el-radio v-model="isArea" label="city">城市</el-radio>
            </div>
          </div>
          <div ref="areaEcharts" class="lineEcharts"></div>
          <!-- <div ref="areaEcharts" class="lineEcharts" v-show='isArea=="area"'></div> -->
          <!-- <div ref="cityEcharts" class="lineEcharts" v-show='isArea=="city"'></div> -->
        </el-card>
        <!-- <el-card class="card-lineEcharts">
          <div slot="header" class="clearfix">
            <h6>拨打详情</h6>
          </div>
          <div ref="lineEcharts" class="lineEcharts"></div>
        </el-card> -->
      </div>
    </div>
    <!-- 监听电话 -->
    <el-dialog
      :visible.sync="monitorShow"
      width="380px"
      class="el-changeLine"
      @close="monitorClose"
    >
      <template slot="title">
        <i class="el-icon-warning dialog-title-my" style="color: #e6a23c"></i>
        <span class="dialog-title-my my-title">是否监听通话?</span>
      </template>
      <el-form label-position="left" label-width="90px" class="el-form">
        <el-form-item label="客户端：">
          <el-select
            v-model="eavesdropClientNumber"
            placeholder="请选择客户端"
            @change="eavesdropClientNumberChange"
          >
            <el-option
              v-for="item in clientNameList"
              :key="item.id"
              :value="item.clientNumber"
              :label="item.clientNumber"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" align="center">
        <el-button type="primary" @click="monitor_submit">确定</el-button>
        <el-button @click="monitorShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 外呼设置 -->
    <strategy-manage
      ref="strategyManage"
      title="外呼策略"
      :templateId="templateId"
      :taskId="taskId"
      :taskServer="taskServer"
      :userLoginName="loginName"
      :strategyForm="outsetSet"
      :textareaSMS="SMStextarea"
      :isHangupsms="strategy_from_hangupsms"
      :longUrlValue="longUrlValue"
      :smsDataLabelValue="smsDataLabelValue"
      :smsSign="smsSign"
      :prefixValue="prefixValue"
      :clickCallValue="clickCallValue"
      :platformArr="platformArr"
      :options="options"
      :loading="changStrategyDis"
      :roleMenu="roleMenu"
      @submit="changStrategy_submit"
    ></strategy-manage>
    <!-- 修改线路 -->
    <el-dialog
      title="修改线路"
      :visible.sync="changeLineShow"
      width="380px"
      class="el-changeLine"
      @close="closeLine"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改线路</span>
      </template>
      <el-form label-position="left" label-width="90px" class="el-form">
        <el-form-item label="选择线路：">
          <el-select v-model="lineId" placeholder="请选择线路">
            <el-option
              v-for="item in lineList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="changeLine_submit"
          :disabled="isChangeLineSubmit"
          >确定</el-button
        >
        <el-button @click="changeLine_cencel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import strategyManage from "../pages/strategyManage.vue";
import * as echarts from "echarts";
require("echarts/lib/chart/bar");
export default {
  components: {
    strategyManage,
  },
  data() {
    return {
      longUrlValue: "",
      smsDataLabelValue: "",
      smsSign: "",
      prefixValue: "https://",
      clickCallValue: "NONE",
      sipTeamNameLabel: "",
      playPrologueShow: false,
      playPrologueTitle: "",
      addWechatShow: false,
      exceptionLimitShow: false,
      mobileVisibleShow: false,
      templateId: "",
      isAddPlatform: true,
      props: {
        multiple: true,
        emitPath: false,
        value: "code",
        label: "name",
        checkStrictly: true,
      },
      options: [],
      platformArr: [],
      strategy_from_hangupsms: "",
      strategy_from_hangupsms_Show: false,
      SMS: {
        totalNum: 0,
        signLength: 0,
        contentNum: 0,
        num: 1,
      },
      isEndNumDis: false,
      isCityDis: false,
      SMStextarea: "",
      lineName: "",
      systemName: "",
      changStrategyDis: false,
      sipRouteId: "",
      isChangeLineSubmit: false,
      lineId: "",
      lineList: [],
      changeLineShow: false,
      userLoginName: "",
      waitingNum: "-",
      talkingNum: "-",
      notRegisteredNum: "-",
      onlineNum: "-",
      callingNum: "-",
      parkNum: "-",
      prologueNum: "-",
      outsetSet: {
        routeMultiple: "",
        originateTimeout: "",
        answerInterval: "",
        newStopCallOnFull: "",
        pauseSecondsOnFull: "",
        maxRouteMultiple: "",
        smsSendTimeout: "",
      },
      maxRouteMultiple: "",
      smsSendTimeout: "",
      smsContent: "",
      smsContentLabel: "",
      setUpDis: true,
      loginName: "",
      loginNameList: [],
      loadingName: false,
      debugShow: false,
      clientTime: "-",
      outgoingTime: "-",
      switchAvailable: true,
      inquireOn: "1",
      // uuid:'',
      strategy: {},
      process: {
        speedProcess: 0,
      },
      outgoing: [],
      client: [],
      taskId: "",
      projectNameList: [],
      subtaskShow: "1", //显示任务列表是否有子任务
      projectNameGroupList: [], //有子任务
      projectNameGroup_list: [], //无子任务
      concurrentText: "可用坐席数 * 并发率 = 最大同时外呼的线路数",
      originateText: "外呼时超过多少秒无人接听自动挂断电话",
      stopText: "坐席全忙时,暂停外呼",
      pauseText: " 坐席全忙时,暂停多少秒后继续外呼",
      answerText: "坐席接听电话后，暂停多少秒用于整理问卷",
      timer: null,
      categoryList: [],
      dialProcessList: [],
      noAnswerCountList: [],
      timeoutClearingCountList: [],
      userBusyCountList: [],
      exceptionCountList: [],
      connectedCountList: [],
      qualityList: [],
      connectedLineQuality: [],
      secondsHangList: [],
      exceedMinuteList: [],
      idleCpuList: [],
      idleCpuTimeList: [],
      sessionCountList: [],
      capsList: [],
      callingMap: null,
      connection: [],
      refillArr: [],
      timestamp: {},
      key: "",
      currentTask: "",
      oldCurrentTask: "",
      lineEcharts: null,
      routeEcharts: null,
      systemEcharts: null,
      lineSelected: {
        异常: false,
        无人接听: false,
        用户繁忙: false,
      },
      areaEcharts: null,
      areaNameList: [],
      areaConnectionList: [],
      cityEcharts: null,
      cityNameList: [],
      cityConnectionList: [],
      isArea: "area",
      callPlayDis: true,
      callpauseDis: true,
      id: "",
      inCall: false,
      inWait: false,
      inOff: true,
      inOn: true,
      inTalk: false,
      inPark: false,
      inPrologue: false,
      taskServer: "",
      transferData: [],
      batchNumber: "",
      clientCurrentPage: 1,
      clientTotal: null,
      batchNumberTitle: "",
      batchStatus: "",
      seriesDataRoute: [],
      unifiedLoading: null,
      monitorShow: false,
      clientNameList: [],
      eavesdropClientNumber: "",
      clientNumber: "",
      editWaitMillisShow: false,
      editWaitMillisSwitch: false,
      waitMillis: "",
      autoBalanceLogList: [],
    };
  },
  props: ["roleMenu", "server"],
  mounted() {
    document.title = "惠销平台管理系统-监控";
    this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    this.routeEcharts = echarts.init(this.$refs.countEcharts); //线路质量图
    this.areaEcharts = echarts.init(this.$refs.areaEcharts); //区域接通图
    if (this.roleMenu === "SUPER_MANAGER") {
      this.systemEcharts = echarts.init(this.$refs.systemEcharts); //系统压力图
    }
    window.addEventListener("resize", () => {
      this.routeEcharts.resize();
      this.areaEcharts.resize();
      if (this.systemEcharts) {
        this.systemEcharts.resize();
      }
    });
    this.getProvinceAndCity();
  },
  watch: {
    isArea(val) {
      this.areaEcharts.clear();
      if (val === "area" && this.areaEcharts) {
        //区域接通图--地区
        this.areaEcharts.setOption({
          title: {
            text: "",
          },
          color: ["#7aafb6"],
          tooltip: {
            trigger: "axis",
            formatter: function (data) {
              let res = data[0].name + "<br/>",
                val;
              data.map((item) => {
                val = item.value + "%";
                res += item.seriesName + ": " + val + "<br/>";
              });
              return res;
            },
          },
          grid: {
            left: "5%",
            right: "8%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            name: "区域",
            type: "category",
            // boundaryGap: false,
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: 30, //30度角倾斜显示
            },
            axisTick: {
              alignWithLabel: true,
            },
            data: this.areaNameList,
          },
          yAxis: {
            name: "百分比",
            type: "value",
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} %",
            },
            show: true,
          },
          series: this.areaConnectionList,
        });
        this.areaEcharts.resize();
      }
      if (val === "city" && this.areaEcharts) {
        //区域接通图--城市
        this.areaEcharts.setOption({
          title: {
            text: "",
          },
          color: ["#7aafb6"],
          tooltip: {
            trigger: "axis",
            formatter: function (data) {
              let res = data[0].name + "<br/>",
                val;
              data.map((item) => {
                val = item.value + "%";
                res += item.seriesName + ": " + val + "<br/>";
              });
              return res;
            },
          },
          grid: {
            left: "5%",
            right: "8%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            name: "区域",
            type: "category",
            // boundaryGap: false,
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: 30, //30度角倾斜显示
            },
            axisTick: {
              alignWithLabel: true,
            },
            data: this.cityNameList,
          },
          yAxis: {
            name: "百分比",
            type: "value",
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} %",
            },
            show: true,
          },
          series: this.cityConnectionList,
        });
        this.areaEcharts.resize();
      }
    },
  },
  computed: {
    // 超时统计列表分页小于10条不显示
    singlePage() {
      if (this.clientTotal > 10) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 查询客户端
    getEavesdropList() {
      this.$http("/eavesdropList", null, "get").then((res) => {
        if (res.result === 200) {
          this.clientNameList = res.data;
        }
      });
    },
    // 确定监听
    monitor_submit() {
      let data = {
        clientNumber: this.clientNumber,
        eavesdropClientNumber: this.eavesdropClientNumber,
      };
      if (!this.eavesdropClientNumber) {
        this.$message.warning("请选择客户端");
        return;
      }
      this.unifiedLoading = this.$loading({ message: "请注意接听软电话" });
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$http("/eavesdrop", null, "get", path, data)
        .then((res) => {
          this.unifiedLoading.close();
          if (res.result === 200) {
            this.monitorShow = false;
            this.$message.success("操作成功");
          }
        })
        .catch((err) => {
          this.unifiedLoading.close();
        });
    },
    // 关闭监听
    monitorClose() {
      this.eavesdropClientNumber = "";
    },
    // 监听客户端选择
    eavesdropClientNumberChange(val) {
      this.$common.setCookie("eavesdropClientNumber", val, 30);
    },
    // 点击坐席监听
    clickMonitor(number) {
      this.monitorShow = true;
      this.clientNumber = number;
      let clientNumberCookie = this.$common.getCookie("eavesdropClientNumber");
      if (clientNumberCookie) {
        this.eavesdropClientNumber = clientNumberCookie;
      }
      this.getEavesdropList();
    },
    getProvinceAndCity() {
      //查询区域
      this.$http("/getProvinceAndCity", null).then((res) => {
        if (res.result == 200) {
          this.options = [];
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.name = i;
            a.code = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.name = i + " " + j;
                b.code = i + ":" + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    // 查询平台
    getPlatformList() {
      this.$http("/sms/smsAccountInfo/list", null, "get").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.platformArr = list;
        }
      });
    },
    // 显示转接时长列表
    transferClick() {
      let params = {
        pageNo: 1,
        pageSize: 10,
        batchNumber: this.batchNumber,
      };
      this.getTimeoutClearingInfo(params);
    },
    // 查询转接时长列表
    getTimeoutClearingInfo(params) {
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$http(
        "/showTimeoutClearingInfo",
        null,
        "get",
        path,
        params
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.list;
          this.clientTotal = res.data.count;
          list.forEach((item) => {
            item.callStartAtLabel = this.$common.transformTime(
              item.callStartAt
            );
            item.distributeMillisLabel = Math.round(
              item.distributeMillis / 1000
            );
            if (!item.clientNumber) {
              item.clientNumber = "-";
            }
          });
          this.transferData = list;
        }
      });
    },
    // 转接时长列表当前页
    clientCurrentPage_data(index) {
      this.clientCurrentPage = index;
      let params = {
        pageNo: index,
        pageSize: 10,
        batchNumber: this.batchNumber,
      };
      this.getTimeoutClearingInfo(params);
    },
    //暂停拨打or继续拨打
    callPlay(val) {
      if (val === "play") {
        this.$confirm("确定开启任务?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        })
          .then(() => {
            let params = {
              id: this.id,
              loginName: this.loginName,
            };
            let path = "https://" + this.taskServer + this.$constants.basePath;
            this.$http("/sip/task/resumeTask", null, "get", path, params).then(
              (res) => {
                if (res.result == 200) {
                  this.inquire("onClick");
                  this.callPlayDis = true;
                  this.callpauseDis = false;
                  this.$message({ message: "继续拨打成功", type: "success" });
                }
              }
            );
          })
          .catch(() => {});
      } else if (val === "pause") {
        this.$confirm("确定暂停任务?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        })
          .then(() => {
            let params = {
              taskId: this.id,
              loginName: this.loginName,
            };
            let path = "https://" + this.taskServer + this.$constants.basePath;
            this.$http("/sip/task/pauseTask", null, "get", path, params).then(
              (res) => {
                if (res.result == 200) {
                  this.inquire("onClick");
                  this.callPlayDis = false;
                  this.callpauseDis = true;
                  this.$message({ message: "暂停拨打成功", type: "success" });
                }
              }
            );
          })
          .catch(() => {});
      }
    },
    setCacheObject(key, value, expireSeconds) {
      //储存缓存
      let cache = {
        data: value,
        expireSeconds: new Date().getTime() + expireSeconds * 1000,
      };
      sessionStorage.setItem(key, JSON.stringify(cache));
    },
    getCacheObject(key) {
      //读取缓存
      let cache = sessionStorage.getItem(key);
      if (!cache) {
        return null;
      }
      let cacheData = JSON.parse(cache);
      if (cacheData.expireSeconds < new Date().getTime()) {
        return null;
      }
      return cacheData.data;
    },
    drawLine() {
      //拨打详情图
      // 绘制图表
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["已拨打", "接通", "转接超时", "异常", "无人接听", "用户繁忙"],
          selected: this.lineSelected,
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          name: "时间",
          type: "category",
          boundaryGap: false,
          axisLabel: {
            interval: 3, //横轴信息全部显示
            // rotate: 30,//30度角倾斜显示
          },
          data: this.categoryList,
        },
        yAxis: {
          name: "数量",
          type: "value",
        },
        series: [
          {
            name: "已拨打",
            type: "line",
            data: this.dialProcessList,
          },
          {
            name: "接通",
            type: "line",
            data: this.connectedCountList,
          },
          {
            name: "转接超时",
            type: "line",
            data: this.timeoutClearingCountList,
          },
          {
            name: "异常",
            type: "line",
            data: this.exceptionCountList,
          },
          {
            name: "无人接听",
            type: "line",
            data: this.noAnswerCountList,
          },
          {
            name: "用户繁忙",
            type: "line",
            data: this.userBusyCountList,
          },
        ],
      };
      this.lineEcharts.on("legendselectchanged", (val) => {
        this.lineSelected = val.selected;
      });
      this.lineEcharts.setOption(option);
      // window.addEventListener("resize", () => { this.lineEcharts.resize();});
    },
    drawRoute() {
      //线路质量图
      let legendList = [];
      if (this.roleMenu === "SUPER_MANAGER") {
        legendList = ["最近接通", "接通", "最近超过1分钟", "最近秒挂"];
      } else {
        legendList = ["最近接通", "接通"];
      }
      // 绘制图表
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (data) {
            let res = data[0].name + "<br/>",
              val;
            data.map((item) => {
              val = item.value + "%";
              res += item.seriesName + ": " + val + "<br/>";
            });
            return res;
          },
        },
        color: ["#c23531", "#2f4554", "#67C23A", "#409EFF"],
        legend: {
          data: legendList,
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          name: "时间",
          type: "category",
          boundaryGap: false,
          axisLabel: {
            interval: 3, //横轴信息全部显示
            // rotate: 30,//30度角倾斜显示
          },
          data: this.qualityList,
        },
        yAxis: {
          name: "百分比",
          type: "value",
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value} %",
          },
          show: true,
        },
        series: this.seriesDataRoute,
      };
      this.routeEcharts.setOption(option);
      // window.addEventListener("resize", () => { this.routeEcharts.resize();});
    },
    drawSystem() {
      let max1 = Math.max(...this.idleCpuList);
      let max2 = 0;
      let sessionMax = Math.max(...this.sessionCountList);
      let capsMax = Math.max(...this.capsList);
      if (sessionMax > capsMax) {
        max2 = sessionMax;
      } else {
        max2 = capsMax;
      }
      let intervalY1 = Math.ceil(max1 / 6);
      let intervalY2 = Math.ceil(max2 / 6);
      //系统压力图
      //绘制图表
      this.systemEcharts.setOption({
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (data) {
            let res = data[0].name + "<br/>",
              val;
            data.map((item) => {
              val = item.value + "%";
              if (item.seriesName === "并发数" || item.seriesName === "caps") {
                res += item.seriesName + ": " + item.value + "<br/>";
              } else {
                res += item.seriesName + ": " + val + "<br/>";
              }
            });
            return res;
          },
        },
        legend: {
          data: ["CPU占用", "并发数", "caps"],
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          name: "时间",
          type: "category",
          boundaryGap: false,
          axisLabel: {
            interval: 3, //横轴信息全部显示
          },
          data: this.idleCpuTimeList,
        },
        yAxis: [
          {
            name: "CPU占用",
            type: "value",
            axisLabel: {
              formatter: "{value} %",
            },
            min: 0,
            max: intervalY1 * 6,
            splitNumber: 6,
            interval: intervalY1,
          },
          {
            name: "并发数/caps",
            type: "value",
            axisLabel: {
              formatter: function (value, index) {
                let val = value;
                return val;
              },
            },
            min: 0,
            max: intervalY2 * 6,
            splitNumber: 6,
            interval: intervalY2,
          },
        ],
        series: [
          {
            name: "CPU占用",
            type: "line",
            data: this.idleCpuList,
          },
          {
            name: "并发数",
            type: "line",
            yAxisIndex: 1,
            data: this.sessionCountList,
          },
          {
            name: "caps",
            type: "line",
            yAxisIndex: 1,
            data: this.capsList,
          },
        ],
      });
    },
    drawArea() {
      //区域接通图--地区
      //绘制图表
      if (this.isArea === "area") {
        //区域接通图--地区
        this.areaEcharts.setOption({
          title: {
            text: "",
          },
          color: ["#7aafb6"],
          tooltip: {
            trigger: "axis",
            formatter: function (data) {
              let res = data[0].name + "<br/>",
                val;
              data.map((item) => {
                val = item.value + "%";
                res += item.seriesName + ": " + val + "<br/>";
              });
              return res;
            },
          },
          grid: {
            left: "5%",
            right: "8%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            name: "区域",
            type: "category",
            // boundaryGap: false,
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: 30, //30度角倾斜显示
            },
            axisTick: {
              alignWithLabel: true,
            },
            data: this.areaNameList,
          },
          yAxis: {
            name: "百分比",
            type: "value",
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} %",
            },
            show: true,
          },
          series: this.areaConnectionList,
        });
      }
      if (this.isArea === "city") {
        //区域接通图--城市
        this.areaEcharts.setOption({
          title: {
            text: "",
          },
          color: ["#7aafb6"],
          tooltip: {
            trigger: "axis",
            formatter: function (data) {
              let res = data[0].name + "<br/>",
                val;
              data.map((item) => {
                val = item.value + "%";
                res += item.seriesName + ": " + val + "<br/>";
              });
              return res;
            },
          },
          grid: {
            left: "5%",
            right: "8%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            name: "区域",
            type: "category",
            // boundaryGap: false,
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: 30, //30度角倾斜显示
            },
            axisTick: {
              alignWithLabel: true,
            },
            data: this.cityNameList,
          },
          yAxis: {
            name: "百分比",
            type: "value",
            axisLabel: {
              show: true,
              interval: "auto",
              formatter: "{value} %",
            },
            show: true,
          },
          series: this.cityConnectionList,
        });
      }
    },
    drawCity() {
      //区域接通图--城市
      //绘制图表
      this.cityEcharts.setOption({
        title: {
          text: "",
        },
        color: ["#7aafb6"],
        tooltip: {
          trigger: "axis",
          formatter: function (data) {
            let res = data[0].name + "<br/>",
              val;
            data.map((item) => {
              val = item.value + "%";
              res += item.seriesName + ": " + val + "<br/>";
            });
            return res;
          },
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          name: "区域",
          type: "category",
          // boundaryGap: false,
          axisLabel: {
            interval: 0, //横轴信息全部显示
            rotate: 30, //30度角倾斜显示
          },
          axisTick: {
            alignWithLabel: true,
          },
          data: this.cityNameList,
        },
        yAxis: {
          name: "百分比",
          type: "value",
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value} %",
          },
          show: true,
        },
        series: this.cityConnectionList,
      });
      // window.addEventListener("resize", () => { this.cityEcharts.resize();});
    },
    async() {
      //刷新
      let _this = this;
      clearInterval(_this.timer);
      if (_this.inquireOn == "0") {
        _this.timer = setInterval(() => {
          _this.inquire();
          if (_this.inquireOn == "1") {
            clearInterval(_this.timer);
          }
        }, 3 * 1000);
      }
    },
    switchChange(val) {
      //滑块开关
      this.async();
    },
    editWaitMillis(show) {
      // show 初始查询时不打开弹框
      let path = "https://" + this.taskServer + this.$constants.basePath;
      let params = {
        batchNumber: this.batchNumber,
      };
      this.$http("/getAutoBalanceInfo", null, "get", path, params).then(
        (res) => {
          if (res.result == 200) {
            if (res.data) {
              this.editWaitMillisSwitch = true;
              this.waitMillis = (res.data.waitMillis / 1000).toFixed(2);
            } else {
              this.editWaitMillisSwitch = false;
              this.waitMillis = "";
            }
            this.$http("/autoBalanceLog", null, "get", path, params).then(
              (res) => {
                if (res.result == 200) {
                  this.autoBalanceLogList.splice(0);
                  res.data.forEach((item) => {
                    this.autoBalanceLogList.push({ content: item });
                  });
                  if (!this.editWaitMillisShow && show) {
                    this.editWaitMillisShow = true;
                  }
                }
              }
            );
          }
        }
      );
    },
    editWaitMillisSwitchChange() {
      if (this.clientTime && this.clientTime !== "-") {
        this.waitMillis = this.clientTime;
      } else {
        this.waitMillis = 30;
      }
    },
    editWaitMillisSubmit() {
      let params = {
        batchNumber: this.batchNumber,
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      if (this.editWaitMillisSwitch) {
        if (!this.waitMillis) {
          this.$message.warning("请填写期望等待时间");
          return;
        }
        params.waitMillis = this.waitMillis * 1000;
        this.$http("/registerAutoBalance", null, "get", path, params).then(
          (res) => {
            if (res.result == 200) {
              if (res.data) {
                this.$message.success("修改成功");
              } else {
                this.$message.error("修改失败");
              }
              this.editWaitMillisShow = false;
            }
          }
        );
      } else {
        this.$http(
          "/unRegisterAutoBalance",
          null,
          "get",
          path,
          params
        ).then((res) => {
          if (res.result == 200) {
            this.$message.success("修改成功");
            this.editWaitMillisShow = false;
          }
        });
      }
    },
    clearfixSetUp() {
      //点击外呼策略设置
      this.SMStextarea = undefined;
      this.longUrlValue = undefined;
      this.smsDataLabelValue = undefined;
      this.smsSign = undefined;
      this.prefixValue = "https://";
      this.clickCallValue = "NONE";
      this.outsetSet = {};
      this.$refs.strategyManage.init();
      this.getPlatformList();
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.isAddPlatform = true;
      this.getHangupStrategy(this.batchNumber, path);
    },
    getHangupStrategy(batchNumber, path, btnType) {
      // 启用挂机短信时点击策略管理
      let params = {
        batchNumber: batchNumber,
      };
      this.$http("/sip/task/getHangupStrategy", null, "get", path, params).then(
        (res) => {
          if (res.result == 200) {
            this.templateId = res.data.id;
            if (res.data.smsContent) {
              res.data.smsContentLabel = res.data.smsContent;
              if (res.data.smsContent.length > 60) {
                res.data.smsContentLabel =
                  res.data.smsContent.substring(0, 60) + "...";
              }
            }
            let hangupSmsRouteInfoList = res.data.hangupSmsRouteInfoList;
            if (hangupSmsRouteInfoList) {
              hangupSmsRouteInfoList.forEach((item) => {
                if (item.customerPhoneOperator) {
                  item.customerPhoneOperator =
                    item.customerPhoneOperator.split(",");
                } else {
                  item.customerPhoneOperator = [];
                }
                if (item.forbiddenArea) {
                  item.forbiddenArea = item.forbiddenArea.split(",");
                } else {
                  item.forbiddenArea = [];
                }
                item.platformLabel = item.platform + "_" + item.accessKey;
              });
            }
            this.strategy = res.data;
            this.strategy.originateTimeout = "";
            this.strategy.answerInterval = "";
            this.strategy.stopCallOnFull = false;
            this.strategy.pauseSecondsOnFull = "";
            this.strategy.addWechatIfMarkFlag = false;
            this.strategy.flagWechatClientGroupId = "";
            this.strategy.showAddWechatButton = false;
            this.strategy.clientWechatServer = "";
            this.strategy.clientWechatClientGroupId = "";
            if (this.strategy.routeMultiple) {
              this.strategy.routeMultiple = this.strategy.routeMultiple / 100;
            }
            if (res.data.strategyTemplateClientTalking) {
              this.strategy.showSendSmsButton =
                res.data.strategyTemplateClientTalking.showSendSmsButton;
              this.strategy.originateTimeout =
                res.data.strategyTemplateClientTalking.originateTimeout;
              this.strategy.answerInterval =
                res.data.strategyTemplateClientTalking.answerInterval;
              this.strategy.stopCallOnFull =
                res.data.strategyTemplateClientTalking.stopCallOnFull;
              this.strategy.pauseSecondsOnFull =
                res.data.strategyTemplateClientTalking.pauseSecondsOnFull;
              this.strategy.addWechatIfMarkFlag =
                res.data.strategyTemplateClientTalking.addWechatIfMarkFlag;
              this.strategy.flagWechatServer =
                res.data.strategyTemplateClientTalking.flagWechatServer;
              this.strategy.flagWechatClientGroupId =
                res.data.strategyTemplateClientTalking.flagWechatClientGroupId;
              this.strategy.showAddWechatButton =
                res.data.strategyTemplateClientTalking.showAddWechatButton;
              this.strategy.clientWechatServer =
                res.data.strategyTemplateClientTalking.clientWechatServer;
              this.strategy.clientWechatClientGroupId =
                res.data.strategyTemplateClientTalking.clientWechatClientGroupId;
              if (
                res.data.strategyTemplateClientTalking.stopCallOnFull == true
              ) {
                this.strategy.stopCallOnFullLabel = "是";
                this.strategy.pauseSecondsOnFullLabel = "-";
              } else if (res.data.strategy.stopCallOnFull == false) {
                this.strategy.stopCallOnFullLabel = "否";
              }
            }
            this.$nextTick(() => {
              this.isAddPlatform = false;
            });
            if (btnType !== "onClick") {
              this.outsetSet = res.data;
            }
            this.maxRouteMultiple = res.data.maxRouteMultiple;
            this.smsSendTimeout = res.data.smsSendTimeout;
            this.smsContent = res.data.smsContent;
            this.smsContentLabel = res.data.smsContentLabel;
            this.SMStextarea = res.data.smsContent;
            this.prefixValue = res.data.prefix;
            this.clickCallValue = res.data.smsClickCallbackType;
            this.longUrlValue = res.data.longUrl;
            this.smsDataLabelValue = res.data.sign;
            this.smsSign = res.data.smsSign;
          }
        }
      );
    },
    switchLine() {
      //点击切换线路
      this.changeLineShow = true;
      let params = {
        pageNo: 1,
        pageSize: 100,
        loginName: this.userLoginName,
        statusList: "CALLING,FINISHING",
        server: this.server,
        showUnVisiable: true,
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.projectNameGroupList = [];
      this.projectNameGroup_list = [];
      this.$http("/sip/task/list", null, "get", path, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          let groupList = []; //有子任务
          let group_list = []; //无子任务
          list.forEach((item) => {
            groupList.push(item.server);
            if (item.visiable) {
              group_list.push(item.server);
            }
            item.label = item.taskName + "(" + item.server + ")";
          });
          groupList = this.$common.unique(groupList);
          groupList = this.projectNameSort(groupList);
          group_list = this.$common.unique(group_list);
          group_list = this.projectNameSort(group_list);
          groupList.forEach((item) => {
            this.projectNameGroupList.push({
              label: item,
              options: [],
            });
          });
          group_list.forEach((item) => {
            this.projectNameGroup_list.push({
              label: item,
              options: [],
            });
          });
          list.forEach((menu) => {
            this.projectNameGroupList.forEach((item) => {
              if (item.label === menu.server) {
                let str = "";
                if (!menu.visiable) {
                  str = "子任务";
                }
                item.options.push({
                  label: menu.taskName + " ( " + menu.batchNumber + " ) " + str,
                  id: menu.id,
                });
              }
            });
          });
          list.forEach((menu) => {
            this.projectNameGroup_list.forEach((item) => {
              if (item.label === menu.server && menu.visiable) {
                item.options.push({
                  label:
                    menu.taskName +
                    " ( " +
                    menu.batchNumber +
                    " )" +
                    menu.userName,
                  id: menu.id,
                });
              }
            });
          });
          this.projectNameList = list;
          for (let i in this.projectNameList) {
            if (this.taskId == this.projectNameList[i].id) {
              this.sipRouteId = this.projectNameList[i].sipRouteId;
            }
          }
          this.inquireRoute(this.loginName);
        }
      });
    },
    changeLine_submit() {
      //确定修改线路
      let data = {
        taskId: this.taskId,
        routeId: this.lineId,
        loginName: this.loginName,
      };
      this.isChangeLineSubmit = true;
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.$http("/sip/task/modifyRoute", data, "post", path, null).then(
        (res) => {
          if (res.result == 200) {
            this.$message({ message: "修改成功", type: "success" });
            this.changeLine_cencel();
          }
          this.isChangeLineSubmit = false;
        }
      );
    },
    changeLine_cencel() {
      //取消修改线路
      this.changeLineShow = false;
    },
    closeLine() {
      //关闭修改线路
      this.lineList = [];
      this.lineId = "";
      this.isChangeLineSubmit = false;
    },
    inquireRoute(loginName) {
      //查询线路
      let params = {
        pageNo: 1,
        pageSize: 1000,
        loginName: loginName,
      };
      this.$http("/route/customerList", null, "get", null, params).then(
        (res) => {
          if (res.result == 200) {
            this.lineList = res.data.list;
            if (res.data.list && res.data.list.length > 0) {
              this.lineId = this.lineList[0].id;
              this.lineList.map((item) => {
                if (this.sipRouteId == item.id) {
                  this.lineId = this.sipRouteId;
                }
              });
            }
          }
        }
      );
    },
    changStrategy_submit(type) {
      // 确定修改策略
      let path = "https://" + this.taskServer + this.$constants.basePath;
      this.getHangupStrategy(this.batchNumber, path);
    },
    //坐席状态表、正在呼叫表筛选
    isScreen(val) {
      if (val === "talk") {
        this.inCall = !this.inCall;
      }
      if (val === "wait") {
        this.inWait = !this.inWait;
      }
      if (val === "online") {
        this.inOn = !this.inOn;
      }
      if (val === "notRegist") {
        this.inOff = !this.inOff;
      }
      if (val === "talking") {
        this.inTalk = !this.inTalk;
      }
      if (val === "park") {
        this.inPark = !this.inPark;
      }
      if (val === "prologue") {
        this.inPrologue = !this.inPrologue;
      }
    },
    inquire(btnType) {
      //查询内容
      let params = {
        taskId: this.taskId,
        loginName: this.loginName,
        server: this.taskServer,
      };
      let path = "https://" + this.taskServer + this.$constants.basePath;
      if (this.strategy_from_hangupsms_Show && btnType === "onClick") {
        this.getHangupStrategy(this.batchNumber, path, btnType);
      }
      for (let i in this.projectNameList) {
        if (this.taskId == this.projectNameList[i].id) {
          this.key = this.projectNameList[i].taskName + this.taskId;
        }
      }
      if (this.oldCurrentTask != this.currentTask) {
        this.lineSelected = {
          异常: false,
          无人接听: false,
          用户繁忙: false,
        };
      }
      this.oldCurrentTask = this.currentTask;
      if (params.taskId) {
        if (btnType === "onClick") {
          this.unifiedLoading = this.$loading({ message: "努力加载中..." });
        }
        setTimeout(() => {
          this.$http("/sip/task/status", null, "get", path, params)
            .then((res) => {
              if (btnType === "onClick") {
                this.unifiedLoading.close();
              }
              if (res.result == 200) {
                this.exceptionLimitShow = res.data.exceptionLimit;
                this.waitingNum = "-";
                this.talkingNum = "-";
                this.notRegisteredNum = "-";
                this.onlineNum = "-";
                this.callingNum = "-";
                this.parkNum = "-";
                this.prologueNum = "-";
                let reportList = res.data.reportList;
                let heartbeat = res.data.heartbeat;
                if (res.data.CALLING_MAP) {
                  this.callingMap = res.data.CALLING_MAP;
                  this.debugShow = true;
                }
                let time = res.data.timestamp;
                if (
                  res.data.clientWaitingTotalMillis &&
                  res.data.clientWaitingTotalCount
                ) {
                  let clientTime =
                    res.data.clientWaitingTotalMillis /
                    res.data.clientWaitingTotalCount /
                    1000;
                  clientTime = clientTime.toFixed(2);
                  this.clientTime = clientTime;
                }
                if (
                  res.data.customerWaitingTotalMillis &&
                  res.data.customerWaitingTotalCount
                ) {
                  let outgoingTime =
                    res.data.customerWaitingTotalMillis /
                    res.data.customerWaitingTotalCount /
                    1000;
                  outgoingTime = outgoingTime.toFixed(2);
                  this.outgoingTime = outgoingTime;
                }
                //坐席状态
                if (res.data.client && res.data.client.length > 0) {
                  let list = [];
                  let numberList = [];
                  let newNumberList = [];
                  let offNumberList = [];
                  this.waitingNum = 0;
                  this.talkingNum = 0;
                  this.notRegisteredNum = 0;
                  this.onlineNum = 0;
                  res.data.client.map((item) => {
                    let dateDiff = time - item.gmtCreated; //相差毫秒数
                    item.clientTime =
                      "(" + Math.round(dateDiff / 1000) + ")" + " 秒";
                    switch (item.status) {
                      case "WAITING_TASK":
                        item.statusLabel = "等待任务";
                        this.waitingNum++;
                        break;
                      case "USER_REGISTERED":
                        item.statusLabel = "在线";
                        this.onlineNum++;
                        break;
                      case "USER_NOT_REGISTERED":
                        item.statusLabel = "离线";
                        item.clientTime = "";
                        this.notRegisteredNum++;
                        break;
                      case "DISABLED":
                        item.statusLabel = "禁用";
                        item.clientTime = "";
                        this.notRegisteredNum++;
                        break;
                      case "TALKING":
                        item.statusLabel = "通话中";
                        this.talkingNum++;
                        break;
                      case "WRITING":
                        item.statusLabel = "整理中";
                        this.talkingNum++;
                        break;
                    }
                    // 如果选择显示的坐席分类为 true 放入不显示数组
                    if (this.inOff) {
                      if (
                        item.status == "USER_NOT_REGISTERED" ||
                        item.status == "DISABLED"
                      ) {
                        offNumberList.push(item.number);
                      }
                    }
                    if (this.inCall) {
                      if (item.status == "TALKING") {
                        offNumberList.push(item.number);
                      }
                    }
                    if (this.inWait) {
                      if (item.status == "WAITING_TASK") {
                        offNumberList.push(item.number);
                      }
                    }
                    if (this.inOn) {
                      if (item.status == "USER_REGISTERED") {
                        offNumberList.push(item.number);
                      }
                    }
                    numberList.push(item.number);
                  });
                  numberList.forEach((t, k) => {
                    let signIs = false;
                    offNumberList.forEach((n, l) => {
                      if (t == n) {
                        signIs = true;
                      }
                    });
                    if (!signIs) {
                      newNumberList.push(t);
                    }
                  });
                  // newNumberList = this.$common.unique(numberList)
                  newNumberList.sort((a, b) => a - b);
                  newNumberList.forEach((n, i) => {
                    res.data.client.forEach((c, j) => {
                      if (n == c.number) {
                        list.push(c);
                      }
                    });
                  });
                  this.client = list;
                } else {
                  this.client = [];
                }
                //正在呼叫
                if (res.data.outgoing && res.data.outgoing.length > 0) {
                  let newOutgoing = [];
                  let prologueList = [];
                  let transferList = [];
                  let dialList = [];
                  let offOutgoing = [];
                  let onOutgoing = [];
                  let outgoingList = [];
                  this.parkNum = 0;
                  this.prologueNum = 0;
                  this.callingNum = 0;
                  res.data.outgoing.map((item) => {
                    let dateDiff = time - item.gmtCreated; //相差毫秒数
                    item.outgoingTime = Math.round(dateDiff / 1000);
                    switch (item.status) {
                      case "PROLOGUE":
                        item.statusLabel = "开场白";
                        this.prologueNum++;
                        break;
                      case "RINGING":
                        item.statusLabel = "响铃中";
                        break;
                      case "USER_BUSY":
                        item.statusLabel = "用户拒接";
                        break;
                      case "NO_ANSWER":
                        item.statusLabel = "无人接听";
                        break;
                      case "ORIGINATOR_CANCEL":
                        item.statusLabel = "拨打取消";
                        break;
                      case "TALKING":
                        item.statusLabel = "通话中";
                        break;
                      case "NORMAL_CLEARING":
                        item.statusLabel = "拨打完成";
                        break;
                      case "SYSTEM_SHUTDOWN":
                        item.statusLabel = "系统挂断";
                        break;
                      case "NO_USER_RESPONSE":
                        item.statusLabel = "线路未响应";
                        break;
                      case "NO_ROUTE_DESTINATION":
                        item.statusLabel = "线路未响应";
                        break;
                      case "SUBSCRIBER_ABSENT":
                        item.statusLabel = "线路未响应";
                        break;
                      case "MEDIA_TIMEOUT":
                        item.statusLabel = "超时";
                        break;
                      case "USER_NOT_REGISTERED":
                        item.statusLabel = "坐席未注册";
                        break;
                      case "WAITING_OUTCALL":
                        item.statusLabel = "等待拨打";
                        break;
                      case "CALLING":
                        item.statusLabel = "拨打中";
                        this.callingNum++;
                        break;
                      case "TIMEOUT_CLEARING":
                        item.statusLabel = "转接超时";
                        break;
                      case "RECOVERY_ON_TIMER_EXPIRE":
                        item.statusLabel = "拨打超限";
                        break;
                      case "PARK":
                        item.statusLabel = "等待转接";
                        this.parkNum++;
                        break;
                      case "NORMAL_TEMPORARY_FAILURE":
                        item.statusLabel = "异常";
                        break;
                      case "OUT_OF_FEE":
                        item.statusLabel = "欠费";
                        break;
                      case "WRITING":
                        item.statusLabel = "整理中";
                        break;
                    }
                    // 等待转接、拨打中、开场白 分别放入对应数组
                    if (item.statusLabel !== "通话中") {
                      if (item.statusLabel == "等待转接") {
                        transferList.unshift(item.customerCode);
                      }
                      if (item.statusLabel == "拨打中") {
                        dialList.unshift(item.customerCode);
                      }
                      if (item.statusLabel == "开场白") {
                        prologueList.unshift(item.customerCode);
                      }
                    }
                    // 显示数组合并
                    newOutgoing = [
                      ...prologueList,
                      ...transferList,
                      ...dialList,
                    ];
                    // 如果选择显示的呼叫分类为true 放入不显示数组
                    if (this.inTalk) {
                      if (item.statusLabel == "拨打中") {
                        offOutgoing.push(item.customerCode);
                      }
                    }
                    if (this.inPark) {
                      if (item.statusLabel == "等待转接") {
                        offOutgoing.push(item.customerCode);
                      }
                    }
                    if (this.inPrologue) {
                      if (item.statusLabel == "开场白") {
                        offOutgoing.push(item.customerCode);
                      }
                    }
                  });
                  // 比较显示数组和不显示数组获取最终显示customerCode数组
                  newOutgoing.forEach((t, k) => {
                    let signIs = false;
                    offOutgoing.forEach((n, l) => {
                      if (t == n) {
                        signIs = true;
                      }
                    });
                    if (!signIs) {
                      onOutgoing.push(t);
                    }
                  });
                  onOutgoing.forEach((n, i) => {
                    res.data.outgoing.forEach((c, j) => {
                      if (n == c.customerCode) {
                        outgoingList.push(c);
                      }
                    });
                  });
                  this.outgoing = this.$common.unique(outgoingList);
                  // this.outgoing = outgoingList;
                } else {
                  this.outgoing = [];
                }
                //策略
                if (res.data.strategy) {
                  this.strategy = res.data.strategy;
                  this.strategy.originateTimeout = "";
                  this.strategy.answerInterval = "";
                  this.strategy.stopCallOnFull = false;
                  this.strategy.pauseSecondsOnFull = "";
                  this.strategy.addWechatIfMarkFlag = false;
                  this.strategy.flagWechatClientGroupId = "";
                  this.strategy.showAddWechatButton = false;
                  this.strategy.clientWechatServer = "";
                  this.strategy.clientWechatClientGroupId = "";
                  if (res.data.strategy.routeMultiple) {
                    res.data.strategy.routeMultiple =
                      res.data.strategy.routeMultiple / 100;
                  }
                  if (res.data.strategy.strategyTemplateClientTalking) {
                    this.strategy.originateTimeout =
                      res.data.strategy.strategyTemplateClientTalking.originateTimeout;
                    this.strategy.answerInterval =
                      res.data.strategy.strategyTemplateClientTalking.answerInterval;
                    this.strategy.stopCallOnFull =
                      res.data.strategy.strategyTemplateClientTalking.stopCallOnFull;
                    this.strategy.pauseSecondsOnFull =
                      res.data.strategy.strategyTemplateClientTalking.pauseSecondsOnFull;
                    this.strategy.addWechatIfMarkFlag =
                      res.data.strategy.strategyTemplateClientTalking.addWechatIfMarkFlag;
                    this.strategy.flagWechatServer =
                      res.data.strategy.strategyTemplateClientTalking.flagWechatServer;
                    this.strategy.flagWechatClientGroupId =
                      res.data.strategy.strategyTemplateClientTalking.flagWechatClientGroupId;
                    this.strategy.showAddWechatButton =
                      res.data.strategy.strategyTemplateClientTalking.showAddWechatButton;
                    this.strategy.clientWechatServer =
                      res.data.strategy.strategyTemplateClientTalking.clientWechatServer;
                    this.strategy.clientWechatClientGroupId =
                      res.data.strategy.strategyTemplateClientTalking.clientWechatClientGroupId;
                    if (this.strategy.stopCallOnFull == true) {
                      this.strategy.stopCallOnFullLabel = "是";
                      this.strategy.pauseSecondsOnFullLabel = "-";
                    } else if (this.strategy.stopCallOnFull == false) {
                      this.strategy.stopCallOnFullLabel = "否";
                    }
                  }
                }
                //拨打详情
                if (res.data.process) {
                  let process = res.data.process;
                  this.batchNumberTitle = "(" + process.batchNumber + ")";
                  switch (process.status) {
                    case "CREATING":
                      this.batchStatus = "(" + "创建中" + ")";
                      break;
                    case "CREATED":
                      this.batchStatus = "(" + "新建状态" + ")";
                      break;
                    case "QUEUING":
                      this.batchStatus = "(" + "排队中" + ")";
                      break;
                    case "WAITING_CALL":
                      this.batchStatus = "(" + "等待拨打" + ")";
                      break;
                    case "CALLING":
                      this.batchStatus = "(" + "拨打中" + ")";
                      break;
                    case "PAUSING":
                      this.batchStatus = "(" + "暂停中" + ")";
                      break;
                    case "PAUSED":
                      this.batchStatus = "(" + "已暂停" + ")";
                      break;
                    case "EXCEPTION":
                      this.batchStatus = "(" + "异常" + ")";
                      break;
                    case "FINISHED":
                      this.batchStatus = "(" + "已完成" + ")";
                      break;
                  }
                  this.sipTeamNameLabel = process.sipTeamName;
                  this.process.dialProcess =
                    process.calledCount + "/" + process.totalCallCount;
                  this.process.needCallbackCount =
                    process.needCallbackCount + process.successCount;
                  this.process.successCount = process.successCount;
                  this.process.connectedCount = process.connectedCount;
                  this.process.timeoutClearingCount =
                    process.timeoutClearingCount;
                  this.process.fee = process.fee;
                  this.process.currentConcurrency = process.currentConcurrency;
                  if (this.process.connectedCount == 0) {
                    this.process.transferRate = "-";
                    this.process.connectedCountRate = "-";
                  } else {
                    this.process.transferRate =
                      (process.connectedCount - process.timeoutClearingCount) /
                      process.connectedCount;
                    this.process.transferRate =
                      (this.process.transferRate * 100).toFixed(2) + "%";
                    this.process.connectedCountRate =
                      (process.connectedCount / process.calledCount) * 100;
                    this.process.connectedCountRate =
                      this.process.connectedCountRate.toFixed(2) + "%";
                  }
                  let speedProcess = (
                    process.calledCount /
                    (process.totalCallCount / 100)
                  ).toFixed(2);
                  this.process.speedProcess = parseFloat(speedProcess);
                  // 判断显示标记
                  if (process.playPrologue) {
                    this.playPrologueShow = true;
                  } else {
                    this.playPrologueShow = false;
                  }
                  if (process.type === "PROLOGUE") {
                    this.playPrologueTitle = "当前采用开场白+人工模式";
                  } else if (process.type === "PROLOGUE_SMS") {
                    this.playPrologueTitle = "当前采用开场白+短信/微信模式";
                  } else {
                    this.playPrologueTitle = "";
                  }
                  if (process.addWechat) {
                    this.addWechatShow = true;
                  } else {
                    this.addWechatShow = false;
                  }
                  if (process.mobileVisible) {
                    this.mobileVisibleShow = true;
                  } else {
                    this.mobileVisibleShow = false;
                  }
                }
                //系统压力图
                if (
                  heartbeat &&
                  heartbeat.length > 0 &&
                  this.roleMenu == "SUPER_MANAGER"
                ) {
                  this.idleCpuList = [];
                  this.idleCpuTimeList = [];
                  this.sessionCountList = [];
                  this.capsList = [];
                  heartbeat.map((item) => {
                    item.uptimeMsec = parseInt(item.uptimeMsec);
                    item.uptimeMsec = this.$common.transformTime(
                      item.uptimeMsec
                    );
                    item.uptimeMsec = item.uptimeMsec.substring(11);
                    this.idleCpuTimeList.push(item.uptimeMsec);
                    item.idleCpu = (100 - item.idleCpu).toFixed(2);
                    this.idleCpuList.push(item.idleCpu);
                    item.sessionCount = item.sessionCount;
                    this.sessionCountList.push(item.sessionCount);
                    this.capsList.push(item.caps);
                  });
                  this.drawSystem();
                } else {
                  if (this.systemEcharts) {
                    this.systemEcharts.clear();
                  }
                }
                //任务结束
                if (res.data.finished) {
                  // sessionStorage.removeItem(this.key);//清除缓存
                  if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                    this.inquireOn = "1";
                  }
                  this.$alert("任务已完成", "提示", {
                    confirmButtonText: "确定",
                    callback: (action) => {},
                  });

                  return;
                }
                this.lineName = "(" + res.data.routeName + ")";
                this.systemName = "(" + this.taskServer + ")";
                //线路质量图数据and拨打详情图数据
                if (reportList) {
                  this.analysisBrokenData(reportList);
                } else {
                  if (this.lineEcharts) {
                    this.lineEcharts.clear();
                  }
                  if (this.routeEcharts) {
                    this.routeEcharts.clear();
                  }
                }
                //区域接通图数据
                let areaRateCity = res.data.areaRateCity;
                let areaRateProvince = res.data.areaRateProvince;
                //城市数据
                if (areaRateCity) {
                  let cityList = [],
                    cityConnectedCountList = [],
                    cityConnectedCount,
                    cityCalledCount,
                    one,
                    two;
                  areaRateCity.forEach((c, i) => {
                    c.timestampLable = this.$common.transformTime(c.timestamp);
                  });
                  one = areaRateCity[0].data;
                  two = areaRateCity[11].data;
                  let obj2 = {};
                  for (let a in two) {
                    for (let d in one) {
                      if (d == a) {
                        cityCalledCount =
                          two[a].calledCount - one[d].calledCount;
                        cityConnectedCount =
                          two[a].connectedCount - one[d].connectedCount;
                        let ConnectedCount = (
                          (cityConnectedCount / cityCalledCount) *
                          100
                        ).toFixed(2);
                        if (ConnectedCount != "NaN") {
                          obj2[d] = ConnectedCount;
                          cityList.push(ConnectedCount);
                        }
                      }
                    }
                  }
                  cityList.sort((a, b) => b - a);
                  if (cityList.length > 10) {
                    cityList = cityList.slice(0, 10);
                  }
                  let newObj2 = [];
                  this.cityNameList = [];
                  for (let i of cityList) {
                    for (let k in obj2) {
                      if (i == obj2[k]) {
                        newObj2.push(k);
                      }
                    }
                  }
                  newObj2 = this.$common.unique(newObj2); //去重
                  if (newObj2.length > 10) {
                    newObj2 = newObj2.slice(0, 10);
                  }
                  for (let n of newObj2) {
                    this.cityNameList.push(n);
                  }
                  cityConnectedCountList[0] = {
                    name: "接通率",
                    type: "bar",
                    barWidth: 30,
                    data: cityList,
                  };
                  this.cityConnectionList = cityConnectedCountList;
                  // this.drawCity();
                } else {
                  if (this.cityEcharts) {
                    this.cityEcharts.clear();
                  }
                }
                //地区数据
                if (areaRateProvince) {
                  let areaList = [];
                  let areaConnectionList = [];
                  let top, tail, oneCalledCount, oneConnectedCount;
                  for (let c of areaRateProvince) {
                    c.timestampLable = this.$common.transformTime(c.timestamp);
                  }
                  top = areaRateProvince[0].data;
                  tail = areaRateProvince[11].data;
                  let obj = {};
                  for (let a in tail) {
                    for (let d in top) {
                      if (d == a) {
                        oneCalledCount =
                          tail[a].calledCount - top[d].calledCount;
                        oneConnectedCount =
                          tail[a].connectedCount - top[d].connectedCount;
                        let ConnectedCount = (
                          (oneConnectedCount / oneCalledCount) *
                          100
                        ).toFixed(2);
                        if (ConnectedCount != "NaN") {
                          obj[d] = ConnectedCount;
                          areaList.push(ConnectedCount);
                        }
                      }
                    }
                  }
                  areaList.sort((a, b) => b - a);
                  if (areaList.length > 10) {
                    areaList = areaList.slice(0, 10);
                  }
                  let newObj = [];
                  this.areaNameList = [];
                  for (let i of areaList) {
                    for (let k in obj) {
                      if (i == obj[k]) {
                        newObj.push(k);
                      }
                    }
                  }
                  newObj = this.$common.unique(newObj); //去重
                  if (newObj.length > 10) {
                    newObj = newObj.slice(0, 10);
                  }
                  for (let n of newObj) {
                    this.areaNameList.push(n);
                  }
                  areaConnectionList[0] = {
                    name: "接通率",
                    type: "bar",
                    barWidth: 30,
                    data: areaList,
                  };
                  this.areaConnectionList = areaConnectionList;
                  this.drawArea();
                } else {
                  if (this.areaEcharts) {
                    this.areaEcharts.clear();
                  }
                }
              }
            })
            .catch((err) => {
              if (btnType === "onClick") {
                this.unifiedLoading.close();
              }
            });
        }, 1000);
      } else {
        this.$message({ message: "请选择任务", type: "warning" });
      }
    },
    analysisBrokenData(reportList) {
      //线路质量图数据and拨打详情图数据
      if (reportList && reportList.length > 0) {
        this.dialProcessList = []; //拨打详情图--已拨打
        this.exceptionCountList = []; //拨打详情图--异常
        this.connectedCountList = []; //拨打详情图--接通
        this.noAnswerCountList = []; //拨打详情图--无人接听
        this.userBusyCountList = []; //拨打详情图--用户繁忙
        this.timeoutClearingCountList = []; //拨打详情图--转接超时
        this.categoryList = []; //拨打详情图--时间

        this.connection = []; //线路质量图--最近接通
        this.qualityList = []; //线路质量图--时间
        this.connectedLineQuality = []; //线路质量图--接通
        this.exceedMinuteList = []; //线路质量图--超过1分钟
        this.secondsHangList = []; //线路质量图--秒挂
        this.seriesDataRoute = []; // 线路质量图数据

        let dialProcessQuality,
          exceptionQuality,
          connectedQuality,
          noAnswerQuality,
          userBusyQuality,
          timeoutClearingQuality,
          connection,
          dialData,
          exception,
          exceedMinute,
          secondsHang;

        let exceedMinuteList = [];
        let secondsHangList = [];

        reportList.map((item) => {
          item.timestamp = this.$common.transformTime(item.timestamp);
          item.timestamp = item.timestamp.substring(11);
          this.categoryList.push(item.timestamp);
          for (let key in item.data) {
            switch (key) {
              case "已拨打":
                this.dialProcessList.push(item.data[key]);
                dialProcessQuality = item.data[key];
                break;
              case "异常":
                this.exceptionCountList.push(item.data[key]);
                exceptionQuality = item.data[key];
                break;
              case "接通":
                this.connectedCountList.push(item.data[key]);
                connectedQuality = item.data[key];
                break;
              case "无人接听":
                this.noAnswerCountList.push(item.data[key]);
                noAnswerQuality = item.data[key];
                break;
              case "用户繁忙":
                this.userBusyCountList.push(item.data[key]);
                userBusyQuality = item.data[key];
                break;
              case "转接超时":
                this.timeoutClearingCountList.push(item.data[key]);
                timeoutClearingQuality = item.data[key];
                break;
              case "超1分钟":
                exceedMinuteList.push(item.data[key]);
                break;
              case "秒挂":
                secondsHangList.push(item.data[key]);
                break;
            }
          }
          item.connectedQuality = (
            (connectedQuality * 100) /
            dialProcessQuality
          ).toFixed(2);
          item.exceptionQuality = (
            (exceptionQuality * 100) /
            dialProcessQuality
          ).toFixed(2);
          item.noAnswerQuality = (
            (noAnswerQuality * 100) /
            dialProcessQuality
          ).toFixed(2);
          item.userBusyQuality = (
            (userBusyQuality * 100) /
            dialProcessQuality
          ).toFixed(2);
          item.timeoutClearingQuality = (
            (timeoutClearingQuality * 100) /
            connectedQuality
          ).toFixed(2);
          if (item.connectedQuality == "NaN") {
            item.connectedQuality = 0;
          }
          if (item.exceptionQuality == "NaN") {
            item.exceptionQuality = 0;
          }
          if (item.noAnswerQuality == "NaN") {
            item.noAnswerQuality = 0;
          }
          if (item.userBusyQuality == "NaN") {
            item.userBusyQuality = 0;
          }
          if (item.timeoutClearingQuality == "NaN") {
            item.timeoutClearingQuality = 0;
          }
          if (item.connectedQuality > 100) {
            // 不能超过100%
            item.connectedQuality = 100;
          }
          if (item.exceptionQuality > 100) {
            // 不能超过100%
            item.exceptionQuality = 100;
          }
          if (item.noAnswerQuality > 100) {
            // 不能超过100%
            item.noAnswerQuality = 100;
          }
          if (item.userBusyQuality > 100) {
            // 不能超过100%
            item.userBusyQuality = 100;
          }
          if (item.timeoutClearingQuality > 100) {
            // 不能超过100%
            item.timeoutClearingQuality = 100;
          }

          let top = this.dialProcessList.length - 1;
          let tail =
            this.dialProcessList.length < 12
              ? 0
              : this.dialProcessList.length - 12;
          dialData = this.dialProcessList[top] - this.dialProcessList[tail];
          connection =
            this.connectedCountList[top] - this.connectedCountList[tail];
          // if (tail === 0) {// 如果不超过12个，拨打总数和接通数为最后一个
          //   dialData = this.dialProcessList[top]
          //   connection = this.connectedCountList[top]
          // }
          exceedMinute = exceedMinuteList[top] - exceedMinuteList[tail];
          secondsHang = secondsHangList[top] - secondsHangList[tail];
          exception =
            this.exceptionCountList[top] - this.exceptionCountList[tail];

          item.connection = ((connection * 100) / dialData).toFixed(2);
          item.exceedMinute = ((exceedMinute * 100) / connection).toFixed(2);
          item.secondsHang = ((secondsHang * 100) / connection).toFixed(2);
          item.exception = ((exception * 100) / dialData).toFixed(2);
          // 如果结构为NaN赋值0.00，结果为Infinity赋值100.00
          if (item.connection == "NaN") {
            item.connection = "0.00";
          }
          if (item.connection === Infinity) {
            item.connection = "100.00";
          }
          if (item.exceedMinute == "NaN") {
            item.exceedMinute = "0.00";
          }
          if (item.exceedMinute == Infinity) {
            item.exceedMinute = "100.00";
          }
          if (item.secondsHang == "NaN") {
            item.secondsHang = "0.00";
          }
          if (item.secondsHang == Infinity) {
            item.secondsHang = "100.00";
          }
          if (item.exception == "NaN") {
            item.exception = "0.00";
          }
          if (item.exception == Infinity) {
            item.exception = "100.00";
          }
          this.connection.push(item.connection);
          this.exceedMinuteList.push(item.exceedMinute);
          this.secondsHangList.push(item.secondsHang);
          this.qualityList.push(item.timestamp);
          this.connectedLineQuality.push(item.connectedQuality);
        });
        if (this.qualityList.length > 30) {
          let reduceLength = this.qualityList.length - 30;
          this.connection.splice(0, reduceLength);
          this.qualityList.splice(0, reduceLength);
          this.connectedLineQuality.splice(0, reduceLength);
          this.exceedMinuteList.splice(0, reduceLength);
          this.secondsHangList.splice(0, reduceLength);

          this.dialProcessList.splice(0, reduceLength);
          this.exceptionCountList.splice(0, reduceLength);
          this.connectedCountList.splice(0, reduceLength);
          this.noAnswerCountList.splice(0, reduceLength);
          this.userBusyCountList.splice(0, reduceLength);
          this.timeoutClearingCountList.splice(0, reduceLength);
          this.categoryList.splice(0, reduceLength);
        }

        if (this.roleMenu === "SUPER_MANAGER") {
          this.seriesDataRoute = [
            {
              name: "最近接通",
              type: "line",
              data: this.connection,
            },
            {
              name: "接通",
              type: "line",
              data: this.connectedLineQuality,
            },
            {
              name: "最近超过1分钟",
              type: "line",
              data: this.exceedMinuteList,
            },
            {
              name: "最近秒挂",
              type: "line",
              data: this.secondsHangList,
            },
          ];
        } else {
          this.seriesDataRoute = [
            {
              name: "最近接通",
              type: "line",
              data: this.connection,
            },
            {
              name: "接通",
              type: "line",
              data: this.connectedLineQuality,
            },
          ];
        }

        // this.drawLine();
        this.drawRoute();
      }
    },
    // 用户名称获取焦点
    NameRemoteFocus() {
      this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    remoteMethodLoginName(userName) {
      //搜索用户名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireLoginName(params);
      }, 200);
    },
    inquireLoginName(params) {
      //查询用户名称
      this.loginNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            item.label = item.userName;
            this.loginNameList.push(item);
          });
          this.inquire2({
            pageNo: 1,
            pageSize: 100,
            loginName: this.loginName,
            statusList: "CALLING,FINISHING",
            server: this.server,
            showUnVisiable: true,
          });
        }
      });
    },
    loginNameChange(userName) {
      //改变用户名称
      this.taskId = "";
      this.switchAvailable = true;
      this.callpauseDis = true;
      this.setUpDis = true;
      this.inquireOn = "1";
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.inquire2({
        pageNo: 1,
        pageSize: 100,
        loginName: this.userLoginName,
        statusList: "CALLING,FINISHING",
        server: this.server,
        showUnVisiable: true,
      });
    },
    taskNameChange(taskId) {
      //改变任务名称
      this.switchAvailable = false;
      this.callpauseDis = false;
      this.setUpDis = false;
      for (let i in this.projectNameList) {
        if (taskId == this.projectNameList[i].id) {
          this.loginName = this.projectNameList[i].userLoginName;
          this.currentTask = this.projectNameList[i].projectName + taskId;
          this.sipRouteId = this.projectNameList[i].sipRouteId;
          this.id = this.projectNameList[i].id;
          this.taskServer = this.projectNameList[i].server;
          this.batchNumber = this.projectNameList[i].batchNumber;
          this.strategy_from_hangupsms = this.projectNameList[i].type;
          if (
            this.projectNameList[i].type === "PROLOGUE_SMS" ||
            this.projectNameList[i].type === "PROLOGUE_WECHAT"
          ) {
            this.strategy_from_hangupsms_Show = true;
          } else {
            this.strategy_from_hangupsms_Show = false;
          }
          if (!this.projectNameList[i].visiable) {
            this.callPlayDis = true;
            this.callpauseDis = true;
            this.setUpDis = true;
          }
        }
      }
      this.editWaitMillis(false);
    },
    inquire2(params) {
      //查询任务名称
      let url = null;
      this.projectNameGroupList = []; //有子任务
      this.projectNameGroup_list = []; //无子任务
      this.$http("/sip/task/list", null, "get", url, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          let groupList = []; //有子任务
          let group_list = []; //无子任务
          list.forEach((item) => {
            groupList.push(item.server);
            if (item.visiable) {
              group_list.push(item.server);
            }
            item.label = item.taskName + "(" + item.server + ")";
          });
          this.projectNameList = list;
          groupList = this.$common.unique(groupList);
          groupList = this.projectNameSort(groupList);
          group_list = this.$common.unique(group_list);
          group_list = this.projectNameSort(group_list);
          groupList.forEach((item) => {
            this.projectNameGroupList.push({
              label: item,
              options: [],
            });
          });
          group_list.forEach((item) => {
            this.projectNameGroup_list.push({
              label: item,
              options: [],
            });
          });
          list.forEach((menu) => {
            this.projectNameGroupList.forEach((item) => {
              if (item.label === menu.server) {
                let str = "";
                if (!menu.visiable) {
                  str = "子任务";
                }
                item.options.push({
                  label: menu.taskName + " ( " + menu.batchNumber + " ) " + str,
                  id: menu.id,
                });
              }
            });
          });
          list.forEach((menu) => {
            this.projectNameGroup_list.forEach((item) => {
              if (item.label === menu.server && menu.visiable) {
                item.options.push({
                  label:
                    menu.taskName +
                    " ( " +
                    menu.batchNumber +
                    " )" +
                    menu.userName,
                  id: menu.id,
                });
              }
            });
          });
        }
      });
    },
    // 任务名称列表排序
    projectNameSort(data) {
      let newList = [];
      let groupList = [];
      for (let i in data) {
        let number = data[i].substring(7);
        if (number) {
          number = Number(number);
        } else {
          number = 0;
        }
        let obj = {
          server: data[i],
          number: number,
        };
        newList.push(obj);
      }
      newList = newList.sort((a, b) => {
        return a.number - b.number;
      });
      for (let i in newList) {
        groupList.push(newList[i].server);
      }
      return groupList;
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style scoped>
.task_icon {
  width: 100px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.task_icon img {
  width: 24px;
  padding-right: 10px;
}
.client_number_td {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sms_content_style {
  display: flex;
}
.sms_content_style .sms_title {
  flex-shrink: 0;
}
.sms_tooltip {
  width: 450px;
}
</style>

<style>
.transfer-pagination {
  float: right;
  text-align: right;
  margin-top: 10px;
}
.purple1-count-transfer {
  display: inline-block !important;
  margin-bottom: 0px !important;
}
.purple1-count-transfer .el-popover__reference-wrapper {
  margin-bottom: 0px !important;
}
.changeArea {
  float: right;
}
.changeArea .el-radio span {
  display: inline-block;
  margin: 0;
}
.statistics .el-button {
  padding: 0;
  /* display: inline-block !important;
  position: relative; */
  color: #000;
  font-size: 14px !important;
}
.statistics .istalkclass {
  color: #aaa5a5;
}
.bg-purple2 .statistics {
  padding: 7px 5px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ebeef5;
  height: 20px;
}
.statistics span {
  display: inline-block !important;
  position: relative;
  font-size: 14px !important;
}
.bg-purple2 .statistics .talking2 {
  left: 30px;
}
.bg-purple2 .statistics .talking3 {
  left: 70px;
}
.bg-purple2 .statistics .talking4 {
  left: 110px;
}
.bg-purple2 .el-table td {
  padding: 7px 0;
}
.monitorTaskClass {
  display: inline-block;
}
.lineEcharts {
  /* width: 600px; */
  width: 100%;
  height: 217px;
}
.card-lineEcharts {
  margin-bottom: 5px;
}
.echarts-card {
  /* flex-grow: 1; */
  /* width: 650px; */
  min-width: 500px;
  width: 700px;
  /* height: 350px; */
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  /* position: absolute; */
}
.el-progress {
  width: 250px;
  margin: 0;
  display: inline-block;
}
.overflowAuto {
  position: relative;
  /* width: 1500px; */
  /* overflow: auto; */
  display: flex;
  /* flex-wrap: wrap; */
}
.monitor-content {
  width: 100%;
}
.monitor {
  flex-shrink: 0;
  width: 765px;
  position: relative;
}
.bg-purple1 {
  width: 370px;
  height: 190px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
}
.purple1-count {
  width: 188px;
  float: left;
}
.bg-purple2 {
  width: 370px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
  height: 623px;
}
.bg-purple2 .el-card__body {
  padding: 0px !important;
}
.bg-purple1 .el-card__body {
  padding: 8px 20px !important;
}
.monitor span {
  display: block;
  margin: 0;
  /* text-align: left; */
  font-size: 14px;
  margin-bottom: 9px;
}
.monitor h6,
.echarts-card h6 {
  display: block;
  margin: 0;
  text-align: left;
  font-size: 16px;
  margin-bottom: 5px;
}
.purple2-text {
  text-align: left;
  padding: 3px 0 0 20px;
}
.bg-purple2 p {
  display: inline-block;
  width: 150px;
  margin: 0;
}
.bg-purple2 .el-table--border {
  border: 0px;
}
.p-text {
  width: 250px !important;
}
.monitor-id {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media screen and (min-width: 1650px) {
  .select-id {
    width: 450px;
  }
  .userName_id {
    width: 300px;
  }
}
@media screen and (max-width: 1650px) {
  .select-id {
    width: 300px;
  }
  .userName_id {
    width: 250px;
  }
}
@media screen and (max-width: 1480px) {
  .select-id {
    width: 200px;
  }
  .userName_id {
    width: 200px;
  }
}
.el-popover {
  display: inline;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.clearfix .opration,
.clearfix h6 {
  display: inline-block;
}
.clearfix {
  position: relative;
}
.clearfix .opration {
  float: right;
  padding: 2px !important;
  font-size: 20px;
  margin-left: 5px;
}
.el-card__header {
  padding: 5px 20px !important;
}
.card-lineEcharts .el-card__body {
  padding: 8px 20px !important;
}
.form-left {
  text-align: center;
  width: 100%;
}
.form-left .el-form-item__content {
  margin: 0 !important;
}
</style>
