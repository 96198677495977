<template>
  <div class="distributedTask">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="分布式任务列表" name="first">
        <div class="d_nav">
          <div class="d_nav_div">
            批次编号：
            <el-input
              v-model="distributedBatchNumber"
              placeholder="请输入批次编号"
              clearable
            ></el-input>
          </div>
          <div class="d_nav_div">
            状态：
            <el-select
              v-model="distributedStatus"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="item in projectStatusList"
                :key="item.status"
                :label="item.label"
                :value="item.status"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-button type="primary" @click="inquireDistributed(1, 'MASTER')"
          >查询</el-button
        >
        <el-table
          :data="distributedTableData"
          border=""
          v-loading="distributedLoading"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="parentBatchNumber"
            label="批次编号"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="taskName"
            label="任务名称"
            min-width="180"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="statusLabel"
            label="任务状态"
            width="170"
            align="center"
          >
            <template slot-scope="scope">
              <div class="m_statusLabel">
                <div class="m_statusLabel_title">
                  {{ scope.row.statusLabel }}
                </div>
                <div
                  v-show="
                    scope.row.status === 'CALLING' ||
                    scope.row.status === 'PAUSING'
                  "
                >
                  <i class="el-icon-loading"></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="executeServer"
            label="服务器"
            width="170"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="slaveCount" label="子任务数" width="130">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="
                  inquireDistributed(1, 'SLAVE', scope.row.parentBatchNumber)
                "
                >{{ scope.row.slaveCount }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="downloadedCallCount"
            label="已下发通话数"
            width="130"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="detailsClick(scope.row, 'DOWNLOAD')"
                >{{ scope.row.downloadedCallCount }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="uploadedCallCount"
            label="已上传通话数"
            width="130"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="detailsClick(scope.row, 'UPLOAD')"
                >{{ scope.row.uploadedCallCount }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="waitCallbackCallCount"
            label="等待回传通话数"
            width="130"
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="创建时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column
            prop
            label="操作"
            width="180"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="dataRecovery(scope.row, 'MASTER')"
                :disabled="dataRecoveryDisabled(scope.row)"
                >数据回收</el-button
              >
              <el-button
                type="text"
                @click="uploadCallResult(scope.row, 'MASTER')"
                :disabled="
                  scope.row.status === 'CALLING' ||
                  scope.row.status === 'PAUSING'
                "
                >结果上传</el-button
              >
              <!-- <el-button type="text" @click="resetStatus(scope.row, 'MASTER')" :disabled="scope.row.resetStatusDisabled">状态重置</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="distributedSizeChange"
          @current-change="distributedPageNoChange"
          :current-page.sync="distributedPageNo"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40, 100]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="distributedTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="分发列表" name="second">
        <div class="d_nav">
          <div class="d_nav_div">
            远程批次编号：
            <el-input
              v-model="downloadSignBatchNumber"
              placeholder="请输入远程批次编号"
              clearable
            ></el-input>
          </div>
          <div class="d_nav_div">
            执行批次编号：
            <el-input
              v-model="downloadSignExecuteBatchNumber"
              placeholder="请输入执行批次编号"
              clearable
            ></el-input>
          </div>
          <div class="d_nav_div">
            状态：
            <el-select
              v-model="downloadSignStatus"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="item in signStatusList"
                :key="item.status"
                :label="item.label"
                :value="item.status"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-button type="primary" @click="inquireDownloadSign(1)"
          >查询</el-button
        >
        <el-table
          :data="downloadSignTableData"
          border=""
          v-loading="downloadSignLoading"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="parentBatchNumber"
            label="批次编号"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="batchNumber"
            label="执行批次编号"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="size"
            label="数据量/实际量"
            min-width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.size }} / {{ scope.row.realSize }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="statusLabel"
            label="状态"
            width="170"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag :type="scope.row.statusColor" size="small">{{
                scope.row.statusLabel
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="cost" label="耗时" width="100">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="light"
                :content="
                  '准备耗时：' +
                  scope.row.prepareCost +
                  ' | ' +
                  '传输耗时：' +
                  scope.row.transferCost
                "
                placement="top-start"
              >
                <el-button type="text">{{ scope.row.cost }}</el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="retryCount"
            label="重试次数"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="remoteServer"
            label="远程服务器"
            min-width="170"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="executeServer"
            label="执行服务器"
            min-width="170"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="创建时间"
            width="170"
            align="center"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="downloadSignSizeChange"
          @current-change="downloadSignPageNoChange"
          :current-page.sync="downloadSignPageNo"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40, 100]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="downloadSignTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>

      <el-tab-pane label="上传列表" name="third">
        <div class="d_nav">
          <div class="d_nav_div">
            远程批次编号：
            <el-input
              v-model="uploadSignBatchNumber"
              placeholder="请输入远程批次编号"
              clearable
            ></el-input>
          </div>
          <div class="d_nav_div">
            执行批次编号：
            <el-input
              v-model="uploadSignExecuteBatchNumber"
              placeholder="请输入执行批次编号"
              clearable
            ></el-input>
          </div>
          <div class="d_nav_div">
            状态：
            <el-select
              v-model="uploadSignStatus"
              placeholder="请选择状态"
              clearable
            >
              <el-option
                v-for="item in signStatusList"
                :key="item.status"
                :label="item.label"
                :value="item.status"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-button type="primary" @click="inquireUploadSign(1)">查询</el-button>
        <el-table
          :data="uploadSignTableData"
          border=""
          v-loading="uploadSignLoading"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="parentBatchNumber"
            label="批次编号"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="batchNumber"
            label="执行批次编号"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="size"
            label="数据量/实际量"
            min-width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.size }} / {{ scope.row.realSize }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="statusLabel"
            label="状态"
            width="170"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag :type="scope.row.statusColor" size="small">{{
                scope.row.statusLabel
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="cost" label="耗时" width="100">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="light"
                :content="
                  '准备耗时：' +
                  scope.row.prepareCost +
                  ' | ' +
                  '传输耗时：' +
                  scope.row.transferCost
                "
                placement="top-start"
              >
                <el-button type="text">{{ scope.row.cost }}</el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="retryCount"
            label="重试次数"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="remoteServer"
            label="远程服务器"
            min-width="170"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="executeServer"
            label="执行服务器"
            min-width="170"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="创建时间"
            width="170"
            align="center"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="uploadSignSizeChange"
          @current-change="uploadSignPageNoChange"
          :current-page.sync="uploadSignPageNo"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40, 100]"
          layout="sizes, total, prev, pager, next,jumper"
          :total="uploadSignTotal"
          :pager-count="5"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- 详情列表 -->
    <el-dialog
      :visible.sync="detailsTableShow"
      width="900px"
      class="connectedTable"
      @close="detailsTableShowClose"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">{{ dialogTitle }}</span>
      </template>
      <el-table
        :data="downloadSignTableData"
        border=""
        v-loading="downloadSignLoading"
        style="width: 100%"
        size="mini"
      >
        <el-table-column
          type="index"
          label="编号"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="parentBatchNumber"
          label="批次编号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="batchNumber"
          label="执行批次编号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="size"
          label="数据量"
          min-width="80"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="statusLabel"
          label="状态"
          width="130"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag :type="scope.row.statusColor" size="small">{{
              scope.row.statusLabel
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="cost" label="耗时" width="70">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="light"
              :content="
                '准备耗时：' +
                scope.row.prepareCost +
                ' | ' +
                '传输耗时：' +
                scope.row.transferCost
              "
              placement="top-start"
            >
              <el-button type="text">{{ scope.row.cost }}</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="retryCount"
          label="重试次数"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="remoteServer"
          label="远程服务器"
          min-width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="executeServer"
          label="执行服务器"
          min-width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="gmtCreated"
          label="创建时间"
          width="150"
          align="center"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="downloadSignPageNoChange"
        :current-page.sync="downloadSignPageNo"
        :page-size="10"
        :page-sizes="[10, 20, 30, 40, 100]"
        layout="total, prev, pager, next, jumper"
        :total="downloadSignTotal"
        :pager-count="5"
      ></el-pagination>
    </el-dialog>
    <!-- 子任务列表 -->
    <el-dialog
      :visible.sync="slaveCountShow"
      width="1200px"
      class="connectedTable"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">子任务列表</span>
      </template>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="
            inquireDistributed(
              slaveCountPageNo,
              'SLAVE',
              distributedSlaveBatchNumber,
              'sx'
            )
          "
          >刷新</el-button
        >
      </div>
      <el-table
        :data="slaveTableData"
        border=""
        v-loading="slaveLoading"
        style="width: 100%"
        size="mini"
      >
        <el-table-column
          type="index"
          label="编号"
          width="50"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="parentBatchNumber" label="批次编号" width="100"></el-table-column> -->
        <el-table-column
          prop="batchNumber"
          label="执行批次编号"
          width="100"
        ></el-table-column>
        <!-- <el-table-column prop="taskName" label="任务名称" min-width="100" show-overflow-tooltip></el-table-column> -->
        <el-table-column
          prop="statusLabel"
          label="任务状态"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div class="m_statusLabel">
              <div class="m_statusLabel_title" style="width: 110px">
                {{ scope.row.statusLabel }}
              </div>
              <div
                v-show="
                  scope.row.status === 'CALLING' ||
                  scope.row.status === 'PAUSING'
                "
              >
                <i class="el-icon-loading"></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="executeServer"
          label="服务器"
          width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="serverCurrentMultiple"
          label="服务器并发"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="waitCallbackCallCount"
          label="等待回传通话数"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="downloadedCallCount"
          label="已下发通话数"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="uploadedCallCount"
          label="已上传通话数"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="gmtCreated"
          label="创建时间"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop
          label="操作"
          width="290"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                dataRecovery(scope.row, 'SLAVE', distributedSlaveBatchNumber)
              "
              :disabled="dataRecoveryDisabled(scope.row)"
              size="small"
              >数据回收</el-button
            >
            <el-button
              type="text"
              @click="
                uploadCallResult(
                  scope.row,
                  'SLAVE',
                  distributedSlaveBatchNumber
                )
              "
              :disabled="
                scope.row.status === 'CALLING' || scope.row.status === 'PAUSING'
              "
              size="small"
              >结果上传</el-button
            >
            <el-button
              type="text"
              @click="autoFixSipCallStatusClick(scope.row)"
              :disabled="scope.row.resetStatusDisabled"
              >数据修复</el-button
            >
            <el-button
              type="text"
              @click="pauseTask(scope.row)"
              :disabled="scope.row.pauseTaskShowDisabled"
              >暂停任务</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="slaveCountPageNoChange"
        :current-page.sync="slaveCountPageNo"
        :page-size="10"
        :page-sizes="[10, 20, 30, 40, 100]"
        layout="total, prev, pager, next, jumper"
        :total="slaveCountTotal"
        :pager-count="5"
      ></el-pagination>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "distributedTask",
  props: ["batchNumber"],
  data() {
    return {
      distributedLoading: false,
      downloadSignLoading: false,
      uploadSignLoading: false,
      slaveLoading: false,
      activeName: "first",
      distributedTableData: [],
      downloadSignTableData: [],
      uploadSignTableData: [],
      distributedPageNo: 1,
      distributedPageSize: 10,
      distributedTotal: 0,
      downloadSignPageNo: 1,
      downloadSignPageSize: 10,
      downloadSignTotal: 0,
      uploadSignPageNo: 1,
      uploadSignPageSize: 10,
      uploadSignTotal: 0,
      dialogTitle: "",
      detailsTableShow: false,
      distributedBatchNumber: "",
      distributedSlaveBatchNumber: "",
      downloadSignBatchNumber: "",
      downloadSignExecuteBatchNumber: "",
      uploadSignBatchNumber: "",
      uploadSignExecuteBatchNumber: "",
      distributedStatus: "",
      downloadSignStatus: "",
      uploadSignStatus: "",
      projectStatusList: [
        {
          status: "CREATING",
          label: "创建中",
        },
        {
          status: "CREATED",
          label: "新建状态",
        },
        {
          status: "ORDERING",
          label: "排序中",
        },
        {
          status: "QUEUING",
          label: "排队中",
        },
        {
          status: "WAITING_CALL",
          label: "等待拨打",
        },
        {
          status: "CALLING",
          label: "拨打中",
        },
        {
          status: "PAUSING",
          label: "暂停中",
        },
        {
          status: "PAUSED",
          label: "已暂停",
        },
        {
          status: "EXCEPTION",
          label: "异常",
        },
        {
          status: "FINISHED",
          label: "已完成",
        },
        {
          status: "WAIT_AUTO_RECALL",
          label: "等待自动重拨",
        },
      ],
      signStatusList: [
        {
          status: "WAITING",
          label: "等待执行",
        },
        {
          status: "DOING",
          label: "执行中",
        },
        {
          status: "SUCCESS",
          label: "执行成功",
        },
        {
          status: "FAILURE",
          label: "执行失败",
        },
        {
          status: "EXCEPTION",
          label: "异常",
        },
      ],
      slaveCountShow: false,
      slaveTableData: [],
      slaveCountPageNo: 1,
      slaveCountTotal: 0,
      unifiedLoading: null,
    };
  },
  mounted() {
    if (this.batchNumber) {
      this.distributedBatchNumber = this.batchNumber;
    } else {
      this.distributedBatchNumber = "";
    }
    this.inquireDistributed(1, "MASTER");
  },
  methods: {
    pauseTask(val) {
      //点击暂停拨打
      this.id = val.id;
      this.$confirm("暂停拨打, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            taskId: this.id,
            loginName: val.userLoginName,
          };
          let path = "https://" + val.executeServer + this.$constants.basePath;
          this.$http("/sip/task/pauseTask", null, "get", path, params).then(
            (res) => {
              if (res.result == 200) {
                this.inquireDistributed(
                  this.slaveCountPageNo,
                  "SLAVE",
                  this.distributedSlaveBatchNumber,
                  "sx"
                );
                this.$message({ message: "暂停成功", type: "success" });
              }
            }
          );
        })
        .catch(() => {});
    },
    // 计算数据回收是否禁用
    dataRecoveryDisabled(row) {
      if (row.status === "PAUSED" || row.status === "FINISHED") {
        if (row.waitCallbackCallCount > 0) {
          return false;
        }
        return true;
      }
      return true;
    },
    // 点击重置状态
    resetStatus(val, role) {
      this.$confirm("重置状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            batchNumber: val.batchNumber,
          };
          let path = "https://" + val.executeServer + this.$constants.basePath;
          this.unifiedLoading = this.$loading({ message: "重置状态中..." });
          this.$service(
            "/distribute/fetchBackCustomerCode",
            params,
            "post",
            path
          )
            .then((res) => {
              this.unifiedLoading.close();
              if (res.result == 200) {
                this.$alert(
                  "重置条数：" + res.data.uploadCount + "条",
                  "消息",
                  {
                    confirmButtonText: "确定",
                  }
                );
                this.inquireDistributed(1, role);
              }
            })
            .catch((err) => {
              this.unifiedLoading.close();
            });
        })
        .catch(() => {});
    },
    // 点击数据回收
    dataRecovery(val, role, parentBatchNumber) {
      this.$confirm(
        "数据回收会将子任务没有拨打的号码返还到主任务, 等待下次分发, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        }
      )
        .then(() => {
          let params = {
            batchNumber: val.batchNumber,
          };
          let path = "https://" + val.executeServer + this.$constants.basePath;
          this.unifiedLoading = this.$loading({ message: "数据回收中..." });
          this.$service(
            "/distribute/fetchBackCustomerCode",
            params,
            "post",
            path
          )
            .then((res) => {
              this.unifiedLoading.close();
              if (res.result == 200) {
                // this.$message({ message: "数据回收成功", type: "success" });
                this.$alert(
                  "回收条数：" + res.data.uploadCount + "条",
                  "消息",
                  {
                    confirmButtonText: "确定",
                  }
                );
                this.inquireDistributed(1, role, parentBatchNumber);
              }
            })
            .catch((err) => {
              this.unifiedLoading.close();
            });
        })
        .catch(() => {});
    },
    // 点击结果上传
    uploadCallResult(val, role, parentBatchNumber) {
      this.$confirm("结果上传, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            batchNumber: val.batchNumber,
          };
          let path = "https://" + val.executeServer + this.$constants.basePath;
          this.unifiedLoading = this.$loading({ message: "结果上传中..." });
          this.$service(
            "/distribute/uploadCallResultToMaster",
            params,
            "post",
            path
          )
            .then((res) => {
              this.unifiedLoading.close();
              if (res.result == 200) {
                // this.$message({ message: "结果上传成功", type: "success" });
                this.$alert(
                  "回传条数：" + res.data.uploadCount + "条",
                  "消息",
                  {
                    confirmButtonText: "确定",
                  }
                );
                this.inquireDistributed(1, role, parentBatchNumber);
              }
            })
            .catch((err) => {
              this.unifiedLoading.close();
            });
        })
        .catch(() => {});
    },
    // 点击数据修复
    autoFixSipCallStatusClick(val) {
      this.$confirm("数据修复, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            batchNumber: val.batchNumber,
          };
          let path = "https://" + val.executeServer + this.$constants.basePath;
          this.unifiedLoading = this.$loading({ message: "数据修复中..." });
          this.$http("/autoFixSipCallStatus", null, "get", path, params)
            .then((res) => {
              this.unifiedLoading.close();
              if (res.result == 200) {
                this.$alert(
                  "修复条数：" + res.data.uploadCount + "条",
                  "消息",
                  {
                    confirmButtonText: "确定",
                  }
                );
                this.inquireDistributed(
                  1,
                  "SLAVE",
                  this.distributedSlaveBatchNumber
                );
              }
            })
            .catch((err) => {
              this.unifiedLoading.close();
            });
        })
        .catch(() => {});
    },
    // 切换tab
    handleClick() {
      if (this.activeName === "first") {
        this.inquireDistributed(1, "MASTER");
      }
      if (this.activeName === "second") {
        this.inquireDownloadSign(1);
      }
      if (this.activeName === "third") {
        this.inquireUploadSign(1);
      }
    },
    // 点击详情
    detailsClick(val, key) {
      if (key === "DOWNLOAD") {
        this.dialogTitle = "分发列表";
        this.detailsTableShow = true;
        this.downloadSignBatchNumber = val.parentBatchNumber;
        this.inquireDownloadSign(1);
      }
      if (key === "UPLOAD") {
        this.dialogTitle = "上传列表";
        this.detailsTableShow = true;
        this.uploadSignBatchNumber = val.parentBatchNumber;
        this.downloadSignPageNo = 1;
        this.inquireUploadSign(1);
      }
    },
    // 查询分布式表格
    getDistributeTaskList(data, role) {
      this.distributedLoading = true;
      this.slaveLoading = true;
      this.$http("/distribute/distributeTaskList", null, "get", null, data)
        .then((res) => {
          this.distributedLoading = false;
          this.slaveLoading = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.statusLabel = this.distributedStatusTransformation(
                item.status
              );
              item.resetStatusDisabled = true;
              item.pauseTaskShowDisabled = true;
              if (item.status === "PAUSED" || item.status === "FINISHED") {
                item.resetStatusDisabled = false;
              }
              if (item.status === "CALLING") {
                item.pauseTaskShowDisabled = false;
              }
            });
            if (role === "MASTER") {
              this.distributedTableData = list;
              this.distributedTotal = res.data.total;
            }
            if (role === "SLAVE") {
              this.slaveTableData = list;
              this.slaveCountTotal = res.data.total;
            }
          }
        })
        .catch((err) => {
          this.distributedLoading = false;
          this.slaveLoading = false;
        });
    },
    // 点击查询分布式任务列表
    inquireDistributed(index, role, parentBatchNumber, sx) {
      let pageSize = 10;
      if (role === "MASTER") {
        if (index) {
          this.distributedPageNo = index;
        }
        pageSize = this.distributedPageSize;
      }
      if (role === "SLAVE") {
        if (index) {
          this.slaveCountPageNo = index;
        }
        if (sx !== "sx") {
          this.slaveTableData = [];
        }
        this.slaveCountShow = true;
      }
      let data = {
        pageNo: index,
        pageSize: pageSize,
        parentBatchNumber: this.distributedBatchNumber,
        status: this.distributedStatus,
        role: role,
      };
      if (parentBatchNumber) {
        this.distributedSlaveBatchNumber = parentBatchNumber;
        data.parentBatchNumber = this.distributedSlaveBatchNumber;
      }
      this.getDistributeTaskList(data, role);
    },
    // 子任务列表---翻页
    slaveCountPageNoChange(index) {
      this.inquireDistributed(index, "SLAVE", this.distributedSlaveBatchNumber);
    },
    // 分布式任务列表---翻页
    distributedPageNoChange(index) {
      this.inquireDistributed(index, "MASTER");
    },
    // 分布式任务列表---每页
    distributedSizeChange(index) {
      this.distributedPageSize = index;
      this.inquireDistributed(1, "MASTER");
    },
    // 查询分发、上传列表
    getTransInfoList(data) {
      this.downloadSignLoading = true;
      this.uploadSignLoading = true;
      this.$http("/distribute/transInfoList", null, "get", null, data)
        .then((res) => {
          this.downloadSignLoading = false;
          this.uploadSignLoading = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.cost = item.prepareCost + item.transferCost;
              let statusRes = this.signStatusTransformation(item.status);
              item.statusLabel = statusRes.label;
              item.statusColor = statusRes.color;
            });
            this.downloadSignTableData = list;
            this.uploadSignTableData = list;
            this.downloadSignTotal = res.data.total;
            this.uploadSignTotal = res.data.total;
          }
        })
        .catch((err) => {
          this.downloadSignLoading = false;
          this.uploadSignLoading = false;
        });
    },
    // 点击查询分发列表
    inquireDownloadSign(index) {
      if (index) {
        this.downloadSignPageNo = index;
      }
      let data = {
        pageNo: this.downloadSignPageNo,
        pageSize: this.downloadSignPageSize,
        type: "DOWNLOAD",
        parentBatchNumber: this.downloadSignBatchNumber,
        status: this.downloadSignStatus,
      };
      this.getTransInfoList(data);
    },
    // 分发列表---翻页
    downloadSignPageNoChange(index) {
      if (this.dialogTitle === "上传列表") {
        this.inquireUploadSign(index);
        return;
      }
      this.inquireDownloadSign(index);
    },
    // 分发列表---每页
    downloadSignSizeChange(index) {
      this.downloadSignPageSize = index;
      this.inquireDownloadSign(1);
    },
    // 点击查询上传列表
    inquireUploadSign(index) {
      if (index) {
        this.uploadSignPageNo = index;
      }
      let data = {
        pageNo: this.uploadSignPageNo,
        pageSize: this.uploadSignPageSize,
        type: "UPLOAD",
        parentBatchNumber: this.uploadSignBatchNumber,
        status: this.uploadSignStatus,
      };
      this.getTransInfoList(data);
    },
    // 上传列表---翻页
    uploadSignPageNoChange(index) {
      this.inquireUploadSign(index);
    },
    // 上传列表---每页
    uploadSignSizeChange(index) {
      this.uploadSignPageSize = index;
      this.inquireUploadSign(1);
    },
    // 关闭详情
    detailsTableShowClose() {
      this.downloadSignTableData = [];
      this.downloadSignBatchNumber = "";
      this.uploadSignBatchNumber = "";
      this.dialogTitle = "";
    },
    // 分发、上传列表状态转换
    signStatusTransformation(val) {
      let res = {
        label: "",
        color: "",
      };
      switch (val) {
        case "WAITING":
          res = {
            label: "等待执行",
            color: "warning",
          };
          return res;
          break;
        case "DOING":
          res = {
            label: "执行中",
            color: "warning",
          };
          return res;
          break;
        case "SUCCESS":
          res = {
            label: "执行成功",
            color: "success",
          };
          return res;
          break;
        case "FAILURE":
          res = {
            label: "执行失败",
            color: "danger",
          };
          return res;
          break;
        case "EXCEPTION":
          res = {
            label: "异常",
            color: "danger",
          };
          return res;
          break;
      }
    },
    // 分布式任务列表状态转换
    distributedStatusTransformation(val) {
      switch (val) {
        case "CREATING":
          return "创建中";
          break;
        case "ORDERING":
          return "排序中";
          break;
        case "CREATED":
          return "新建状态";
          break;
        case "WAITING_CALL":
          return "等待拨打";
          break;
        case "CALLING":
          return "拨打中";
          break;
        case "PAUSING":
          return "暂停中";
          break;
        case "PAUSED":
          return "已暂停";
          break;
        case "CLOSED":
          return "已关闭";
          break;
        case "EXCEPTION":
          return "异常";
          break;
        case "QUEUING":
          return "排队中";
          break;
        case "FINISHED":
          return "已完成";
          break;
        case "WAIT_AUTO_RECALL":
          return "等待自动重拨";
          break;
      }
    },
  },
};
</script>
<style scoped>
.distributedTask .el-pagination {
  float: right;
  margin-top: 20px;
}
.distributedTask .el-table {
  margin-top: 20px;
}
.distributedTask .connectedTable >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
.distributedTask .connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.d_nav {
  margin-bottom: 20px;
}
.d_nav .d_nav_div {
  display: inline-block;
  margin-right: 20px;
}
.d_nav .d_nav_div .el-input,
.d_nav .d_nav_div .el-select {
  width: 250px;
}
.m_statusLabel {
  display: flex;
  justify-content: center;
}
.m_statusLabel_title {
  width: 120px;
}
</style>
