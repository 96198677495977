<template>
  <div class="addFansMange">
    <div class="nav">
      <div class="batchName">
        运营：
        <el-input
          v-model="operationalUsers"
          placeholder="请输入运营"
          clearable
        ></el-input>
      </div>
      <div class="batchName">
        标签：
        <el-select
          v-model="selectNotesValue"
          placeholder="请选择标签"
          clearable
        >
          <el-option
            v-for="item in notesValueList"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="batchName">
        日期：
        <el-date-picker
          v-model="abnormalGmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          :clearable="false"
        ></el-date-picker>
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button type="success" @click="exportClick" :disabled="downloadFansDis"
      >导出</el-button
    >
    <el-button @click="addExcelUserLabelClick">导入</el-button>
    <el-button @click="copyLabelClick">一键打标</el-button>
    <el-table
      :data="addFansTableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="operationalUsers"
        label="运营"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="label"
        label="标签"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <!-- ---------------------------------- -->
      <el-table-column
        prop="fanCount"
        label="进粉机器数"
        min-width="150"
        align="center"
      >
        <!-- <template slot="header" slot-scope="scope">
          <span>来源进粉量</span>
          <el-tooltip
            class="item"
            effect="light"
            content="通过好友申请数量"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template> -->
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="450"
            @show="transferClick(scope.row.clientCodeMaps)"
            trigger="click"
            class="purple1-count-transfer"
          >
            <!-- 来源数据 -->
            <el-table :data="transferData" size="mini" stripe border>
              <el-table-column
                property="source"
                label="分组"
                align="center"
              ></el-table-column>
              <el-table-column
                property="fanCount"
                label="数量"
                align="center"
              ></el-table-column>
            </el-table>
            <a href="javascript:;" slot="reference" style="color: #3a8ee6">{{
              scope.row.fanCount
            }}</a>
          </el-popover>
        </template>
      </el-table-column>
      <!-- --------------------------------------- -->
      <el-table-column
        prop="date"
        label="日期"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="90"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="batchNotes(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 修改标签 -->
    <el-dialog
      title="修改标签"
      :visible.sync="batchNotesShow"
      width="450px"
      @close="batchNotesClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改标签</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="标签：" required>
          <el-select v-model="notesValue" placeholder="请选择标签">
            <el-option
              v-for="item in notesValueList"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="batchNotes_submit()"
            >确认</el-button
          >
          <el-button @click="batchNotesShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      :visible.sync="addPayShow"
      custom-class="el-addBindshow"
      width="500px"
      @close="addPayClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">导入</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="导入文件：" required>
          <el-upload
            class="upload-demo"
            drag
            :data="addUploadData"
            :action="addUrl"
            :on-success="successNumberUpload"
            :on-error="errorNumberUpload"
            ref="addUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
          <div class="titlt-show">请上传excel格式的文件(带表头)</div>
          <div class="titlt-show">只需要三列数据: 日期, 运营用户, 标签</div>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addPay_submit()">确认</el-button>
          <el-button @click="addPayShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "addFansMange",
  props: ["roleMenu", "loginName"],
  data() {
    return {
      transferData: [],
      operationalUsers: "",
      selectNotesValue: "",
      id: "",
      downloadFansDis: true,
      batchNotesShow: false,
      notesValue: "",
      notesValueList: [
        {
          label: "正常进粉",
        },
        {
          label: "三分之一进粉",
        },
        {
          label: "三分之二进粉",
        },
        {
          label: "二分之一进粉",
        },
        {
          label: "六分之一进粉",
        },
        {
          label: "主管进粉",
        },
        {
          label: "请假一天",
        },
        {
          label: "请假半天",
        },
        {
          label: "新人进粉",
        },
        {
          label: "停粉",
        },
        {
          label: "控粉",
        },
        {
          label: "离职",
        },
      ],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      addFansTableData: [],
      loadingTable: false,
      abnormalGmtCreatedDate: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      addUploadData: {},
      addUrl: this.$constants.baseURL + "/wechatUser/addExcelUserLabel",
      fileList: [],
      cookie: true,
      addPayShow: false,
    };
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.abnormalGmtCreatedDate = [start, end];
    this.inquire(1);
    this.theWeek();
  },
  methods: {
    // 点击一键打标
    copyLabelClick() {
      this.$http("/wechatUser/copyLabel").then((res) => {
        if (res.result === 200) {
          this.$message.success("打标成功");
        }
      });
    },
    // 点击导入
    addExcelUserLabelClick() {
      this.addPayShow = true;
    },
    // 确定提交上报
    addPay_submit() {
      this.$refs.addUpload.submit();
    },
    //导入成功
    successNumberUpload(res, file, fileList) {
      this.$refs.addUpload.clearFiles();
      if (res.result == 200) {
        this.$message({ message: "文件上传成功！", type: "success" });
        this.addPayShow = false;
        this.inquire(1);
      }
    },
    //导入失败
    errorNumberUpload(err, file, fileList) {
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    handleExceed(files, fileList) {
      //限制上传文件数量提示
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 关闭导入
    addPayClose() {
      this.$refs.addUpload.clearFiles();
    },
    // 点击修改标签
    batchNotes(val) {
      this.id = val.id;
      this.notesValue = val.label;
      this.batchNotesShow = true;
    },
    // 确定修改标签
    batchNotes_submit() {
      let data = {
        id: this.id,
        label: this.notesValue,
      };
      this.$service("/wechatUser/editUserLabel", data).then((res) => {
        if (res.result === 200) {
          this.batchNotesShow = false;
          this.$message.success("修改成功");
          this.inquire();
        }
      });
    },
    // 关闭修改标签
    batchNotesClose() {
      this.notesValue = "";
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.abnormalGmtCreatedDate) {
        gmtCreatedStart = this.abnormalGmtCreatedDate[0].substring(0, 10);
        gmtCreatedEnd = this.abnormalGmtCreatedDate[1].substring(0, 10);
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        label: this.selectNotesValue,
        operationalUsers: this.operationalUsers,
        startDate: gmtCreatedStart,
        endDate: gmtCreatedEnd,
      };
      this.getFindWechatUserLabelList(data);
    },
    // 翻页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 每页
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 查询进粉量列表
    getFindWechatUserLabelList(data) {
      this.downloadFansDis = true;
      this.$http(
        "/wechatUser/findWechatUserLabelList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.date = this.$common.transformTime(item.date).substring(0, 10);
            item.fanCount = 0;
            for (let i in item.clientCodeMaps) {
              item.fanCount += item.clientCodeMaps[i].length;
            }
          });
          if (list.length > 0) {
            this.downloadFansDis = false;
          }
          this.addFansTableData = list;
          this.total = res.data.total;
        }
      });
    },
    // 点击导出
    exportClick() {
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.abnormalGmtCreatedDate) {
        gmtCreatedStart = this.abnormalGmtCreatedDate[0].substring(0, 10);
        gmtCreatedEnd = this.abnormalGmtCreatedDate[1].substring(0, 10);
      }
      let data = {
        start: gmtCreatedStart,
        end: gmtCreatedEnd,
      };
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + "=" + data[i] + "&";
        }
      }
      let href = this.$constants.baseURL + "/wechatUser/export?" + url;
      window.location.href = href;
    },
    // 每周
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 显示来源数据
    transferClick(val) {
      this.transferData = [];
      for (let i in val) {
        let data = {};
        data = {
          source: i,
          fanCount: val[i].length,
        };
        this.transferData.push(data);
      }
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-addBindshow .el-input,
.el-addBindshow .el-select {
  width: 80%;
}
.titlt-show {
  font-size: 14px;
  color: #e6a23c;
  line-height: 18px;
}
</style>