<template>
  <div class="SMSmarketingRecord">
    <div class="nav">
      <div class="date-send">发送时间：
        <el-date-picker
          v-model="time"
          type="datetimerange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="defaultTime"
          align="right"
        ></el-date-picker>
      </div>
      <div class="nav-input">名称：
        <el-input v-model="msgName" placeholder="请输入活动名称" clearable></el-input>
      </div>
      <div class="nav-input">状态：
        <el-select v-model="status" placeholder="请选择状态" @change='statusChange' clearable>
				  <el-option v-for="item in statusList" :key='item.status' :value="item.status" :label='item.label'>
				</el-option> 
			</el-select>
      </div>
    </div>
    <div class="search-btn">
      <el-button type="primary" class="inquire" @click="inquire()">查询</el-button>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" border>
      <el-table-column type="index" prop label="序号" width="60" align="center"></el-table-column>
      <el-table-column prop="msgName" label="活动名称" width="120" align="center"></el-table-column>
      <el-table-column prop="activitySubCode" label="活动批次" width="120" align="center"></el-table-column>
      <el-table-column prop="planSendNums" label="条数" width="120" align="center"></el-table-column>
      <el-table-column prop="content" label="内容" width align></el-table-column>
      <!-- <el-table-column prop="msgFee" label="费用" width="120" align="center"></el-table-column> -->
      <el-table-column prop="statusLabel"  label="发送状态" width="140" align="center"></el-table-column>
      <!-- <el-table-column prop="gmtCreated" label="提交时间" width="170" align="center"></el-table-column> -->
      <el-table-column prop="sendTime" label="发送时间" width="170" align="center"></el-table-column>
      <el-table-column label="操作" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="viewConnected(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页功能-->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next, jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 发送详情表 -->
    <el-dialog title="详情表" width="900px" :visible.sync="sendShow" class='connectedTable' @close="sendClose">
      <el-table :data="detailsTable" stripe style="width: 100%" border size="mini">
        <el-table-column type="index" prop label="序号" width="50" align="center"></el-table-column>
        <el-table-column prop="content" label="内容" width align></el-table-column>
        <el-table-column prop="statusLabel" label="发送状态" width='130' align='center'></el-table-column>
        <el-table-column prop="submitTime" label="提交时间" width="150" align="center"></el-table-column>
        <el-table-column prop="gmtCreated" label="创建时间" width="150" align="center"></el-table-column>
      </el-table>
      <!-- 详情表分页功能 -->
      <el-pagination
        @current-change="currentPage_details"
        :current-page.sync="detailsPage"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="detailsTotal"
        :pager-count="5"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SmmSmarketingRecords",
  data() {
    return {
      msg: "短信营销记录",
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        onPick({ maxDate, minDate }){
          if(minDate){
            sessionStorage.setItem('theTime', minDate.getTime());
          }
        },
        shortcuts: [{
          text: '本月记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本周记录',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('thisWeek');
            start = parseInt(start);
            picker.$emit('pick', [new Date(start), end]);
          }
        }, {
          text: '本日记录',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '此刻',
          onClick(picker) {
            const end = new Date();
            let start = sessionStorage.getItem('theTime');
            if(start){
              start = parseInt(start);
              picker.$emit('pick', [new Date(start), end]);
              sessionStorage.removeItem('theTime');
            }
          }
        }]
      },
      defaultTime:['00:00:00','23:59:59'],
      startTime: "",
      endTime: "",
      time: [],
      total: null,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      sendShow: false,
      detailsTable: [],
      msgName: '',
      detailsTotal: null,
      detailsPage: 1,
      planId: '',
      status: '',
      statusList: [
        {
          status: 'CREATING',
          label: '创建中'
        },
        {
          status: 'WAITING',
          label: '等待中'
        },
        {
          status: 'COMMITED',
          label: '已提交'
        },
        {
          status: 'SENDING',
          label: '发送中'
        },
        {
          status: 'FINISHED',
          label: '已完成'
        },
        {
          status: 'COMMIT_FAILURE',
          label: '提交失败'
        },
        {
          status: 'SEND_FAILURE',
          label: '发送失败'
        },
      ]
    };
  },
  mounted() {
    this.inquire(1);
    this.theWeek();
  },
  methods: {
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
    // 状态处理-1
    stateHandling1(val){
      switch (val) {
        case "CREATING":
          return "创建中";
          break;
        case "WAITING":
          return "等待中";
          break;
        case "COMMITED":
          return "已提交";
          break;
        case "SENDING":
          return "发送中";
          break;
        case "FINISHED":
          return "已完成";
          break;
        case "COMMIT_FAILURE":
          return "提交失败";
          break;
        case "SEND_FAILURE":
          return "发送失败";
          break;
      }
    },
    // 状态处理-2
    stateHandling2(val){
      switch (val) {
        case "WAIT_SUBMIT":
          return "等待提交运营商";
          break;
        case "SUBMIT_SUCCESS":
          return "提交成功";
          break;
        case "SUBMIT_FAILURE":
          return "提交失败";
          break;
        case "SUCCESS":
          return "发送成功";
          break;
        case "BLANK_NUMBER":
          return "空号";
          break;
        case "DOWNTIME":
          return "停机";
          break;
        case "WRONG_NUMBER":
          return "手机号格式错误";
          break;
        case "BLACKLIST":
          return "黑名单";
          break;
        case "UNKNOWN_OPERATOR":
          return "未知运营商";
          break;
        case "OVER_SEND":
          return "超频";
          break;
        case "ILLEGAL_SIGN":
          return "非法签名";
          break;
        case "OTHER":
          return "其它";
          break;
      }
    },
    // 选择搜索状态
    statusChange(status){
      this.status = status;
    },
    //点击查询
    inquire(index) {
      let data = {
        startTime: this.time[0],
        endTime: this.time[1],
        msgName: this.msgName,
        status: this.status,
        pageNo: index || this.currentPage,
        pageSize: this.pageSize
      };
      this.$http("/sms/short/url/msg/list", data).then(res => {
        if (res.result == 200) {
          let list = res.data.records;
          this.total = res.data.total;
          //数据处理
          list.map(item => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.sendTime = this.$common.transformTime(item.sendTime);
            item.statusLabel = this.stateHandling1(item.sendStatus);// 状态处理
          });
          this.tableData = list;
        }
      });
    },
    //请求当前页数据
    currentPage_data(index) {
      this.inquire(index);
    },
    // 当前页显示条数
    sizeChange(index){
      this.currentPage = 1;
      this.pageSize = index;
      this.inquire();
    },
    //请求详情表当前页数据
    currentPage_details(index) {
      let data = {
        pageNo: index,
        pageSize: 10,
        planId: this.planId
      }
      this.getDetailsList(data);
    },
    // 查询详情表
    getDetailsList(data){
      this.$http('/sms/short/url/msg/detail/list',null,'get',null,data).then(res =>{
        if (res.result === 200) {
          let list = res.data.records;
          this.detailsTotal = res.data.total;
          list.forEach(item => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            if (item.submitTime) {
              item.submitTime = this.$common.transformTime(item.submitTime);
            }
            item.statusLabel = this.stateHandling2(item.sendStatus);// 状态处理
          });
          this.detailsTable = list;
        }
      })
    },
    // 点击发送状态
    viewConnected(val){
      this.planId = val.id;
      this.sendShow = true;
      let data = {
        pageNo: 1,
        pageSize: 10,
        planId: val.id
      }
      this.getDetailsList(data);
    },
    // 关闭发送详情
    sendClose(){
      this.detailsTable = [];
    }
  }
};
</script>

<style scoped="scoped">
.nav {
  width: 1200px;
  margin-bottom: 20px;
}
.nav-input, .date-send{
  width: 600px;
  margin-bottom: 20px;
}
.date-send {
  float: right;
  text-align: left;
}
.nav-input .el-input, .nav-input .el-select {
  width: 430px;
}
.search-btn {
  margin-top: 20px;
}
/* .inquire {
  float: right;
  width: 120px;
} */
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
}
.connectedTable >>> .el-dialog__body::after {
  display:block;
  content:'';
  clear:both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 0 0 20px 0;
}
</style>