<template>
  <div id="gameDataAnalysis" class="gameDataAnalysis">
    <div class="nav">
      <div class="taskName">子渠道标识：
        <el-input v-model="condition.channel" placeholder="请输入子渠道标识" clearable></el-input>
      </div>
      <div class="taskName">备注：
        <el-input v-model="condition.remark" placeholder="请输入备注" clearable></el-input>
      </div>
      <div class="taskName">日期：
        <el-date-picker
          v-model="gameCallEndAtStart"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          :clearable="false"
        ></el-date-picker>
      </div>
    </div>
    <div class="nav">
      <div class="taskName">产品：
        <i style="display: inline-block;margin-left: 42px"></i>
        <el-input v-model="condition.products" placeholder="输入框(多个以空格分隔)" clearable></el-input>
      </div>
      <div class="taskName">
        <el-checkbox v-model="condition.smsSendSuccessCountStart">过滤未发送短信批次</el-checkbox>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquireYouxi(1)">查询</el-button>
      <el-button type="primary" @click="synchronizeTodayData">同步今日数据</el-button>
      <el-button type="primary" @click="longUrlUpdate">更新长链信息</el-button>
      <el-button @click="exportClick" :disabled="exportClickDisabled">导出</el-button>
    </div>
    <!-- 游戏数据表 -->
    <el-table
      :data="gameTableData.slice((this.gameCurrentPage - 1) * this.gamePageSize, (this.gameCurrentPage - 1) * this.gamePageSize + this.gamePageSize)"
      stripe
      style="width: 100%"
      border=""
      class="tableDataClass"
      :summary-method="getExpensesSummaries"
      show-summary
      v-loading="loadingList"
      @sort-change="sortChange"
      :default-sort = "{prop: 'registerCount', order: 'descending'}"
    >
      <el-table-column type="index" label="编号" width="60" align="center"></el-table-column>
      <el-table-column prop="channel" label="子渠道标识" min-width="100" align="center"></el-table-column>
      <el-table-column prop="remark" label="备注" min-width="200" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="smsSendSuccessCount" label="短信发送成功数" width="150" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="registRateLabel" label="注册率%" width="110" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="registerCount" label="注册人数" width="110" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="paySum" label="充值次数" width="110" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="payCount" label="充值人数" width="110" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="payMoney" label="充值金额" width="110" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="payNew" label="新增充值金额" width="130" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="newPayCount" label="新增充值人数" width="130" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="active2Count" label="次日存留数" width="120" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="active7Count" label="7日存留数" width="110" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="payRate" label="付费率%" width="110" align="center" sortable="custom"></el-table-column>
      <el-table-column prop="longUrl" label="长链" width="150" align="center" show-overflow-tooltip></el-table-column>
    </el-table>
    <!-- 游戏数据分页 -->
    <el-pagination
      @size-change="gameSizeChange"
      @current-change="currentPage_gameData"
      :current-page.sync="gameCurrentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="gameTableData.length"
    ></el-pagination>
    <!-- 上传长链信息 -->
    <el-dialog
      title="更新长链信息"
      :visible.sync="longUrlUpdateShow"
      width="750px"
      @close="longUrlUpdateClose"
    >
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">更新长链信息</span>
      </template>
      <el-form label-position="left">
        <!-- <el-form-item label="日期：" label-width="80px">
          <el-date-picker
            v-model="longUrlUpdateDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="长链：" label-width="70px">
          <el-input v-model="longUrlUpdateContent" type="textarea" :rows="8" placeholder="子渠道标识 长链 备注"></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="longUrlUpdate_submit()">确定</el-button>
          <el-button @click="longUrlUpdateShow = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "gameDataAnalysis",
  data() {
    return {
      exportClickDisabled: true,
      tableProp: "registerCount",
      tableOrder: "descending",
      longUrlUpdateShow: false,
      longUrlUpdateDate: "",
      longUrlUpdateContent: "",
      condition: {
        channel: "",
        remark: "",
        products: "",
        smsSendSuccessCountStart: true
      },
      gameTableData: [],
      loadingList: false,
      gameCurrentPage: 1,
      gamePageSize: 10,
      gameTotal: 0,
      gameSummary: [],
      gameCallEndAtStart: [],
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            }
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            }
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      unifiedLoading: null,
      oldGameTableData: []
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-游戏数据分析";
    this.theWeek();
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.gameCallEndAtStart = [start, end];
    // this.longUrlUpdateDate = start.substring(0, 10);
    this.inquireYouxi(1);
  },
  methods: {
    theWeek(){
      this.$http('/user/thisWeek',null).then(res=>{
        if(res.result == 200){
          let start = res.data;
          sessionStorage.setItem('thisWeek', start);
        }
      })
    },
    // 点击导出
    exportClick() {
      let data = {
        channel: this.condition.channel,
        remark: this.condition.remark,
        products: this.condition.products,
      };
      if (this.condition.smsSendSuccessCountStart) {
        data.smsSendSuccessCountStart = 1;
      }
      if (this.gameCallEndAtStart) {
        data.gmtCreatedStart = this.gameCallEndAtStart[0];
        data.gmtCreatedEnd = this.gameCallEndAtStart[1];
      }
      let url = "";
      for (let i in data) {
        if (data[i]) {
          url += i + '=' + data[i] + '&';
        }
      }
      let href = this.$constants.baseURL+"/report/youxiRegistInfo/export?"+url;
      window.location.href = href;
    },
    // 表格排序
    sortChange({ column, prop, order }) {
      this.tableProp = prop;
      this.tableOrder = order;
      this.tableSort();
    },
    // 表格排序方法
    tableSort() {
      let gameTableData = this.$common.deepClone(this.oldGameTableData);
      switch (this.tableOrder) {
        case "ascending":
          this.gameTableData = gameTableData.sort(this.ascending(this.tableProp));
          break;
        case "descending":
          this.gameTableData = gameTableData.sort(this.descending(this.tableProp));
          break;
        default:
          this.gameTableData = this.oldGameTableData;
          break;
      }
    },
    // 升序
    ascending(prop) {
      return function(a, b) {
        return a[prop] - b[prop];
      }
    },
    // 降序
    descending(prop) {
      return function(a, b) {
        return b[prop] - a[prop];
      }
    },
    // 点击上传长链信息
    longUrlUpdate() {
      this.longUrlUpdateShow = true;
    },
    // 确定上传长链信息
    longUrlUpdate_submit() {
      let data = {
        // date: this.longUrlUpdateDate,
        content: this.longUrlUpdateContent
      }
      this.$service("/report/updateLongUrlInfo",data).then(res => {
        if (res.result === 200) {
          this.$message.success("更新成功");
          this.longUrlUpdateShow = false;
          this.inquireYouxi(1);
        }
      })
    },
    // 关闭上传长链信息
    longUrlUpdateClose() {
      // let start = new Date();
      // start = this.$common.transformTime(start.valueOf());
      // this.longUrlUpdateDate = start.substring(0, 10);
      this.longUrlUpdateContent = "";
    },
    // 点击同步今日数据
    synchronizeTodayData() {
      this.unifiedLoading = this.$loading({message: "数据同步中..."});
      this.$http("/report/syncTodayRegistInfo",null,'get').then(res => {
        this.unifiedLoading.close();
        if (res.result === 200) {
          this.$message.success("数据同步成功");
          this.inquireYouxi(1);
        }
      }).catch(err => {
        this.unifiedLoading.close();
      })
    },
    // 点击查询游戏数据列表
    inquireYouxi(index) {
      if (index) {
        this.gameCurrentPage = index;
      }
      let data = {
        // pageNo: index || this.gameCurrentPage,
        // pageSize: this.gamePageSize,
        channel: this.condition.channel,
        remark: this.condition.remark,
        products: this.condition.products,
      };
      if (this.condition.smsSendSuccessCountStart) {
        data.smsSendSuccessCountStart = 1;
      }
      if (this.gameCallEndAtStart) {
        data.gmtCreatedStart = this.gameCallEndAtStart[0];
        data.gmtCreatedEnd = this.gameCallEndAtStart[1];
      }
      this.getYouxiRegistInfo(data);
    },
    // 查询游戏数据
    getYouxiRegistInfo(data) {
      this.loadingList = true;
      this.$http("/report/youxiRegistInfo", null, "get", null, data).then(
        res => {
          this.loadingList = false;
          if (res.result === 200) {
            this.gameTotal = res.data.total;
            let list = res.data.list;
            let registSummaryInfo = res.data.registSummaryInfo;
            if (list) {
              list.forEach(item => {
                if (item.registerCount > 0) {
                  item.payRate = (item.newPayCount / item.registerCount *100).toFixed(2);
                  if (item.smsSendSuccessCount > 0) {
                    item.registRateLabel = (item.registerCount / item.smsSendSuccessCount *100).toFixed(3);
                  } else {
                    item.registRateLabel = "0.000";
                  }
                } else {
                  item.payRate = "0.00";
                  item.registRateLabel = "0.000";
                }
              })
              this.gameTableData = list;
              this.oldGameTableData = this.$common.deepClone(this.gameTableData);
              this.exportClickDisabled = true;
              if (this.gameTableData && this.gameTableData.length > 0) {
                this.exportClickDisabled = false;
              }
              this.tableSort();
              if (registSummaryInfo) {
                if (registSummaryInfo.registerCount > 0) {
                  registSummaryInfo.payRate = (registSummaryInfo.newPayCount / registSummaryInfo.registerCount *100).toFixed(2);
                  if (registSummaryInfo.smsSendSuccessCount > 0) {
                    registSummaryInfo.registRateLabel = (registSummaryInfo.registerCount / registSummaryInfo.smsSendSuccessCount *100).toFixed(3);
                  } else {
                    registSummaryInfo.registRateLabel = "0.000";
                  }
                } else {
                  registSummaryInfo.payRate = "0.00";
                  registSummaryInfo.registRateLabel = "0.000";
                }
              }
              this.gameSummary = registSummaryInfo;
            }
          }
        }
      ).catch(err => {
        this.loadingList = false;
      })
    },
    // 游戏数据翻页
    currentPage_gameData(pageNo) {
      // this.inquireYouxi(pageNo);
    },
    // 游戏数据--pageSize
    gameSizeChange(pageSize) {
      this.gamePageSize = pageSize;
      this.gameCurrentPage = 1;
      // this.inquireYouxi(1);
    },
    // 合计行线路费用统计
    getExpensesSummaries(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        for (let i in this.gameSummary) {
          if (i == column.property) {
            arr[index] = this.gameSummary[i];
          }
        }
      });

      return arr;
    }
  }
};
</script>
<style scoped>
.button-center {
  text-align: center
}
.nav .el-input,
.nav .el-select {
  width: 300px;
}
.companyName,
.callTime,
.taskName,
.batchName {
  width: 420px;
  margin-bottom: 20px;
  display: inline-block;
}
.callTime >>> .el-input__inner {
  width: 430px;
}
.callTime,
.batchName {
  margin-right: 6px;
}
.callTime,
.batchName {
  float: right;
  text-align: left;
}
.operate {
  margin-bottom: 20px;
}
.el-table {
  margin-bottom: 20px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>

