<template>
  <div class="bindRelation">
    <div class="nav">
      <div class="batchName">运营：
        <el-input v-model="operator" placeholder="请输入运营"></el-input>
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button type="primary" @click="addBind">新增</el-button>
    <el-button type="success" v-if="roleMenu=='SUPER_MANAGER' || 'WECHAT_MANAGER'" @click="exportClick" :disabled="derivedDis">导出</el-button>
    <el-table
      :data="bindTableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="loginName"
        label="运营"
        width
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="productUrl"
        label="产品链接"
        width
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="productType"
        label="产品名称"
        width
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="90"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button 
            type="text" 
            size="small" 
            @click="deleteClick(scope.row)" 
            :disabled="(roleMenu === 'SUPER_MANAGER' || roleMenu === 'WECHAT_MANAGER' || roleMenu === 'WECHAT_PRODUCT_MANAGER') ? false : true" 
            style="color: #ff0000b0"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增 -->
    <el-dialog
      title="新增"
      :visible.sync="addBindShow"
      custom-class="el-addBindshow"
      width="500px"
      @close="addBindClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">新增</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="运营：">
          <el-input
            v-model="loginName"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="产品名称：" required>
          <el-select v-model="productType" placeholder="请选择产品名称">
            <el-option
              v-for="item in productTypeList"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品链接：" required>
          <el-input
            v-model="productUrl"
            placeholder="请输入产品链接"
          ></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addBind_submit()">确认</el-button>
          <el-button @click="addBindShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "bindRelation",
  props: ["roleMenu","loginName"],
  data() {
    return {
      derivedDis: true,
      bindTableData: [],
      loadingTable: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      addBindShow: false,
      productUrl: "",
      productType: "",
      productTypeList: [],
      operator: ""
    };
  },
  mounted() {
    this.inquire(1);
    this.getLabelList();
  },
  methods: {
    // 点击导出
    exportClick() {
      let params = {
        loginName: this.operator,
      };
      let url = "";
      for(let i in params){
        if(params[i]){
          url+=(i+'='+params[i]+'&')
        }
      }
      let href = this.$constants.baseURL+"/wechat/tab/export?"+url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then(data=>{
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      })
    },
    // 点击新增
    addBind() {
      this.addBindShow = true;
    },
    // 确定提交新增
    addBind_submit() {
      let data = {
        productType: this.productType,
        productUrl: this.productUrl,
      };
      this.$service("/wechat/tab/addWeChatProductUrl", data).then((res) => {
        if (res.result === 200) {
          this.addBindShow = false;
          this.$message.success("新增成功");
          this.inquire();
        }
        if (res.result === 400) {
          this.addBindShow = false;
          this.$message.warning(res.message);
          this.inquire();
        }
      });
    },
    // 关闭新增
    addBindClose() {
      this.productType = "";
      this.productUrl = "";
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        loginName: this.operator
      };
      this.getWeChatProduct(data);
    },
    // 翻页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 每页
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 请求微信运营绑定数据
    getWeChatProduct(data) {
      this.loadingTable = true;
      this.derivedDis = true;
      this.$service("/wechat/tab/findWeChatProductUrl", data).then((res) => {
        this.loadingTable = false;
        if (res.result === 200) {
          let list = res.data.records;
          this.bindTableData = list;
          this.total = res.data.total;
          if (this.bindTableData.length > 0) {
            this.derivedDis = false;
          }
        }
      }).catch(err => {
        this.loadingTable = false;
      })
    },
    // 请求产品列表
    getLabelList() {
      this.$http("wechat/labelList",null,'get',null,{category: 'LESSON'}).then(res => {
        if (res.result === 200) {
          this.productTypeList = res.data;
        }
      })
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm("【"+val.loginName+"-"+val.productUrl+"-"+val.productType+"】删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
      .then(() => {
        let data = {
          id:val.id
        }
        this.$service('/wechat/tab/deleteUserProductBind',data).then(res=>{
          if(res.result == 200){
            this.$message({message:'删除成功',type:'success'});
            this.inquire();
          }
        })
      })
      .catch(() => {});
    }
  },
};
</script>
<style scoped>
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.el-addBindshow .el-input,
.el-addBindshow .el-select {
  width: 80%;
}
</style>