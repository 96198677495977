<template>
  <div class="informationSet">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="短信长链绑定" name="first">
				<longUrlBind v-if="longUrlBindShow"></longUrlBind>
			</el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import longUrlBind from "./longUrlBind";
export default {
	name: "informationSet",
	components: {
		longUrlBind
	},
  data() {
    return {
			activeName: "first",
			longUrlBindShow: false
		};
  },
  mounted() {
		this.longUrlBindShow = true;
	},
  methods: {
		handleClick(val) {
			console.log(val)
		}
	},
};
</script>
<style scoped>
</style>