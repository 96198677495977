<template>
  <div class="robotStatustucs">
    <div class="nav">
      <div class="batchName">
        平台名称：
        <!-- <el-input
          v-model="platform"
          placeholder="请输入平台名称"
          clearable
        ></el-input> -->
        <el-select v-model="platform" placeholder="请选择平台">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="batchName">
        项目名称：
        <el-input
          v-model="projectName"
          placeholder="请输入项目名称"
          clearable
        ></el-input>
      </div>
      <div class="callTime">
        调用时间：
        <i style="margin-left: 16px"></i>
        <el-date-picker
          v-model="gmtCreated"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          :clearable="false"
        ></el-date-picker>
      </div>
      <el-button type="primary" @click="inquire(1)">查询</el-button>
    </div>
    <!-- :span-method="objectSpanMethod" -->
    <el-table
      :data="tableData"
      style="width: 100%"
      v-loading="loadingTable"
      border
      @sort-change="tableSortChange"
    >
      <el-table-column prop="platform" label="平台" align="center">
      </el-table-column>
      <el-table-column
        prop="batchName"
        label="拨打项目"
        align="center"
        width="300"
      >
      </el-table-column>
      <el-table-column
        prop="connectCount"
        label="接通量"
        sortable="custom"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="wishCount" label="意向数" align="center">
      </el-table-column>
      <el-table-column prop="addFansCount" label="进粉数" align="center">
      </el-table-column>
      <el-table-column prop="connectRate" label="接通率" sortable="custom" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.connectRate > 0"
            >{{ (row.connectRate * 100).toFixed(2) }}%</span
          >
          <span v-if="row.connectRate == 0">0%</span>
        </template>
      </el-table-column>
      <el-table-column prop="dialPassRate" label="接通意向率" sortable="custom" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.dialPassRate > 0"
            >{{ (row.dialPassRate * 100).toFixed(2) }}%</span
          >
          <span v-if="row.dialPassRate == 0">0%</span>
        </template>
      </el-table-column>
      <el-table-column prop="addFansPassRate" label="招呼通过率" sortable="custom" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.addFansPassRate > 0"
            >{{ (row.addFansPassRate * 100).toFixed(2) }}%</span
          >
          <span v-if="row.addFansPassRate == 0">0%</span>
        </template>
      </el-table-column>
      <el-table-column prop="payRate" label="支付率" sortable="custom" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.payRate > 0"
            >{{ (row.payRate * 100).toFixed(2) }}%</span
          >
          <span v-if="row.payRate == 0">0%</span>
        </template>
      </el-table-column>
      <el-table-column prop="fee" label="单个加粉话费成本" sortable="custom" align="center">
      </el-table-column>
      <el-table-column prop="totalFee" label="单个加粉总成本" sortable="custom" align="center">
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="taskPageSizeChange"
      @current-change="currentTaskPageNo"
      :current-page.sync="taskPageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="taskTotal"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "robotStatustucs",
  data() {
    return {
      projectName: "",
      platform: "",
      sort: "",
      options: [
        {
          value: "九维",
          label: "九维",
        },
        {
          value: "艾达",
          label: "艾达",
        },
      ],
      taskPageNo: 1,
      taskPageSize: 10,
      taskTotal: 0,
      gmtCreated: [],
      tableData: [],
      loadingTable: true,
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "近三个月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let sm = start.getMonth() - 2;
              start.setMonth(sm);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.gmtCreated = [start, end];
    this.inquire(1);
  },
  methods: {
    // 排序
    tableSortChange(column) {
      // console.log(column);
      if (column.order === "descending") {
        this.sort = column.prop + "&DESC";
      } else if(column.order === "ascending"){
        this.sort = column.prop + "&ASC";
      }else{
        this.sort = '';
      }
      this.inquire();
    },
    // 翻页
    currentTaskPageNo(index) {
      this.inquire(index);
    },
    // 每页
    taskPageSizeChange(index) {
      this.taskPageSize = index;
      this.inquire(1);
    },
    inquire(index) {
      if (index) {
        this.taskPageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.gmtCreated) {
        gmtCreatedStart = this.gmtCreated[0];
        gmtCreatedEnd = this.gmtCreated[1];
      }
      let data = {
        pageNo: this.taskPageNo,
        pageSize: this.taskPageSize,
        start: gmtCreatedStart,
        end: gmtCreatedEnd,
        batchName: this.projectName,
        platform: this.platform,
        sort: this.sort,
      };
      this.getList(data);
    },
    //   获取数据
    getList(data) {
      this.$http("/robot/reportList", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          this.taskTotal = res.data.total;
          this.loadingTable = false;
          this.tableData = res.data.records;
        }
      });
    },
    getTableData() {
      let spanOneArr = [],
        spanTwoArr = [],
        concatOne = 0,
        concatTwo = 0;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
          spanTwoArr.push(1);
        } else {
          if (item.platform === this.tableData[index - 1].platform) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr,
        two: spanTwoArr,
      };
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (columnIndex === 0) {
        const _row = this.getTableData(this.JZPFData).one[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
};
</script>
<style scoped>
.data_style {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.nav .batchName .el-select {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.upload-demo >>> .el-upload-dragger {
  width: 304px;
}
.records_style >>> .el-dialog__body::after {
  display: block;
  content: "";
  clear: both;
}
</style>