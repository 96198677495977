//对$axios的二次封装

import axios from "axios";
import constants from "./constants.js";

const service = function service(url, data, type, baseURL, params, header) {
  return new Promise((resolve, reject) => {
    if (
      baseURL &&
      constants.baseURL == "http://localhost:8080/backendApiPrefix"
    ) {
      baseURL = constants.baseURL;
    }
    axios({
      method: type || "post",
      baseURL: baseURL || constants.baseURL, // 将自动加在 url 前面，除非 url 是一个绝对 URL
      url: url,
      data: data,
      params: params,
      withCredentials: true, //  表示跨域请求时允许携带cookie
      header,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default service;
