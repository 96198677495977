<template>
  <div class="telephoneTraffic">
    <div class="nav_menu">
      <div class="batchName">
        队列名称：
        <el-select
          v-model="condition.teamId"
          placeholder="请选择列队"
          @focus="teamRemoteFocus"
          @change="teamChange"
          clearable
        >
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="companyName">
        用户名称：
        <el-select
          v-model="condition.loginName"
          placeholder="请选择用户"
          filterable
          remote
          :loading="loadingName"
          :remote-method="NameRemoteMethod"
          @change="loginNameChange"
          @focus="NameRemoteFocus"
          clearable
        >
          <el-option
            v-for="item in userNameList"
            :key="item.id"
            :value="item.loginName"
            :label="item.userName"
          ></el-option>
        </el-select>
      </div>
      <div class="callTime">
        时间：
        <el-date-picker
          v-model="condition.gmtCreated"
          v-show="reportUnit == 'DAY'"
          type="daterange"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="callPickerOptions"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <el-date-picker
          v-model="condition.month"
          v-show="reportUnit == 'MONTH'"
          type="month"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择月"
        >
        </el-date-picker>
        <el-date-picker
          v-model="condition.week"
          v-show="reportUnit == 'WEEK'"
          type="week"
          :picker-options="weekPickerOptions"
          format="yyyy 第 WW 周"
          placeholder="选择周"
        >
        </el-date-picker>
      </div>
      <div class="batchName">
        <div class="checkboxClass">
          离线坐席是否显示：
          <el-radio v-model="onlyShowOnline" label="false">是</el-radio>
          <el-radio v-model="onlyShowOnline" label="true">否</el-radio>
        </div>
      </div>
      <div class="taskName">
        客服名称：
        <el-select
          v-model="condition.number"
          @focus="numberRemoteFocus"
          placeholder="请选择坐席"
          multiple
          filterable
          remote
          :remote-method="numberRemoteRemoteMethod"
        >
          <el-option
            v-for="item in numberList"
            :key="item.id"
            :value="item.number"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <div class="taskName">
        统计周期：
        <el-select
          v-model="reportUnit"
          placeholder="请选择"
          @change="reportUnitChange"
        >
          <el-option
            v-for="item in reportUnitList"
            :key="item.reportUnit"
            :label="item.label"
            :value="item.reportUnit"
          >
          </el-option>
        </el-select>
      </div>
      <div class="operate">
        <el-button type="primary" @click="inquireClick">查询</el-button>
        <el-button
          type="success"
          @click="exportReportList"
          :disabled="exportDisabled"
          >导出</el-button
        >
      </div>
    </div>
    <!-- 话务统计表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
      @sort-change="batchOrder"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="number"
        label="坐席编号"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="clientName"
        label="客服"
        min-width="150"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="date"
        label="日期"
        :width="dateWidth"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="onlineSeconds"
        label="登录总时长"
        width="120"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="idleSeconds"
        label="休息总时长"
        width="120"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="distributedCallCount"
        label="转接量"
        width="90"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="answeredCallCount"
        label="应答量"
        width="76"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="answerRate"
        label="应答率"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="waitingSeconds"
        label="等待总时长"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="averageWaitingSeconds"
        label="平均等待时长"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="talkingSeconds"
        label="通话总时长"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="averageTalkingSeconds"
        label="平均通话时长"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column prop="efficiency" width="115" align="center">
        <template slot="header" slot-scope="scope">
          <span>工作利用率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="通话总时长/登录总时长"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="successCount"
        label="成单量"
        width="90"
        align="center"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="conversionRate"
        width="110"
        align="center"
        sortable="custom"
      >
        <template slot="header" slot-scope="scope">
          <span>转化率</span>
          <el-tooltip
            class="item"
            effect="light"
            content="成单量/应答量"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      @size-change="sizeChange"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "TelephoneTraffic",
  data() {
    return {
      dateWidth: "115",
      loading: false,
      loadingName: false,
      userNameList: [],
      weekPickerOptions: {
        firstDayOfWeek: 1,
      },
      callPickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      condition: {
        gmtCreated: [],
        teamId: "",
        callEndAtStart: "",
        callEndAtEnd: "",
        number: [],
        week: "",
        month: "",
        loginName: "",
      },
      teamList: [],
      numberList: [],
      currentPage: null,
      total: null,
      reportUnitList: [
        {
          reportUnit: "DAY",
          label: "日报表",
        },
        {
          reportUnit: "WEEK",
          label: "周报表",
        },
        {
          reportUnit: "MONTH",
          label: "月报表",
        },
      ],
      reportUnit: "DAY",
      onlyShowOnline: "false",
      tableData: [],
      exportDisabled: true,
      pageSize: 10,
      number: null,
      userloginName: "",
      userName: "",
      order: {},
    };
  },
  props: ["loginName"],
  mounted() {
    document.title = "惠销平台管理系统-话务统计";
    let end = new Date();
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.callEndAtEnd = end;
    this.condition.gmtCreated = [start, end];
    this.theWeek();
    this.inquireUserList({ pageSize: 1000, pageNo: 1, enabled: true });
    this.inquireTeam({
      pageSize: 1000,
      pageNo: 1,
      loginName: this.loginName,
    });
    this.inquireNumber({
      pageSize: 1000,
      pageNo: 1,
      loginName: this.loginName,
    });
    this.userName = this.loginName;
    this.inquire(1);
  },
  methods: {
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    inquire(index, val) {
      //查询
      this.dataProcessing(); //查询参数处理
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        teamId: this.condition.teamId,
        loginName: this.userName,
        clientNumbers: this.number,
        onlyShowOnline: this.onlyShowOnline,
        gmtCreatedStart: this.condition.callEndAtStart,
        gmtCreatedEnd: this.condition.callEndAtEnd,
        summaryType: this.reportUnit,
      };
      if (val) {
        for (let i in val) {
          if (val[i]) {
            params[i] = val[i];
          }
        }
      }
      if (!params.gmtCreatedStart || !params.gmtCreatedEnd) {
        this.$message({ message: "请选择时间", type: "warning" });
        return;
      }
      this.inquireReportList(params);
    },
    dataProcessing() {
      //查询参数处理
      this.number = this.condition.number.join(",");
      if (this.reportUnit == "DAY") {
        let gmtCreatedStart = "";
        let gmtCreatedEnd = "";
        if (this.condition.gmtCreated && this.condition.gmtCreated.length > 0) {
          gmtCreatedStart = this.condition.gmtCreated[0];
          gmtCreatedEnd = this.condition.gmtCreated[1];
        }
        this.condition.callEndAtStart = gmtCreatedStart;
        this.condition.callEndAtEnd = gmtCreatedEnd;
      }
      if (this.reportUnit == "WEEK") {
        this.condition.callEndAtStart = "";
        this.condition.callEndAtEnd = "";
        if (this.condition.week) {
          let start = this.condition.week - 24 * 3600 * 1000;
          let end = start + 6 * 24 * 3600 * 1000;
          end = new Date(end);
          end.setHours(23);
          end.setMinutes(59);
          end.setSeconds(59);
          end.setMilliseconds(59);
          start = this.$common.transformTime(start);
          end = this.$common.transformTime(end.getTime());
          this.condition.callEndAtStart = start;
          this.condition.callEndAtEnd = end;
        }
      }
      if (this.reportUnit == "MONTH") {
        this.condition.callEndAtStart = "";
        this.condition.callEndAtEnd = "";
        if (this.condition.month) {
          let start = this.condition.month;
          let end = new Date(start);
          let m = end.getMonth() + 1;
          end.setMonth(m);
          end = end - 1000;
          end = this.$common.transformTime(end);
          this.condition.callEndAtStart = start;
          this.condition.callEndAtEnd = end;
        }
      }
    },
    inquireReportList(params) {
      //查询话务列表
      this.loading = true;
      this.$http("/sip/client/reportList", null, "get", null, params).then(
        (res) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (res.result == 200) {
            let list = res.data.list;
            this.total = res.data.count;
            this.exportDisabled = true;
            // list.forEach((item) => {
            //   if (item.answeredCallCount > 0) {
            //     item.conversionRate =
            //       ((item.successCount / item.answeredCallCount) * 100).toFixed(
            //         2
            //       ) + "%";
            //   } else {
            //     item.conversionRate = "-";
            //   }
            // });
            this.tableData = list;
            if (this.tableData && this.tableData.length > 0) {
              this.exportDisabled = false;
            }
            if (this.reportUnit === "DAY") {
              this.dateWidth = "115";
            } else {
              this.dateWidth = "235";
            }
          }
        }
      );
    },
    currentPage_data(index) {
      //当前页
      let params = {};
      params.onlineMillisOrder = this.order["onlineSeconds"];
      params.idleMillisOrder = this.order["idleSeconds"];
      params.distributedCallCountOrder = this.order["distributedCallCount"];
      params.successCountOrder = this.order["successCount"];
      params.conversionRateOrder = this.order["conversionRate"];
      this.inquire(index, params);
    },
    sizeChange(index) {
      //显示条数--分页
      this.pageSize = index;
      let params = {};
      params.onlineMillisOrder = this.order["onlineSeconds"];
      params.idleMillisOrder = this.order["idleSeconds"];
      params.distributedCallCountOrder = this.order["distributedCallCount"];
      params.successCountOrder = this.order["successCount"];
      params.conversionRateOrder = this.order["conversionRate"];
      this.inquire(1, params);
    },
    reportUnitChange(val) {
      //统计周期变化
      this.condition.gmtCreated = [];
      this.condition.week = "";
      this.condition.month = "";
    },
    // 表格排序
    batchOrder(val) {
      this.order = {};
      if (val.order == "descending") {
        val.order = "desc";
      } else if (val.order == "ascending") {
        val.order = "asc";
      }
      let params = {};
      switch (val.prop) {
        case "onlineSeconds":
          this.order["onlineSeconds"] = val.order;
          break;
        case "idleSeconds":
          this.order["idleSeconds"] = val.order;
          break;
        case "distributedCallCount":
          this.order["distributedCallCount"] = val.order;
          break;
        case "successCount":
          this.order["successCount"] = val.order;
          break;
        case "conversionRate":
          this.order["conversionRate"] = val.order;
          break;
      }
      params.onlineMillisOrder = this.order["onlineSeconds"];
      params.idleMillisOrder = this.order["idleSeconds"];
      params.distributedCallCountOrder = this.order["distributedCallCount"];
      params.successCountOrder = this.order["successCount"];
      params.conversionRateOrder = this.order["conversionRate"];
      this.inquire(this.currentPage, params);
    },
    endTime(val) {
      let a = val.substring(0, 10);
      a = a + " 23:59:59";
      this.condition.callEndAtEnd = a;
    },
    inquireClick() {
      //点击查询
      this.inquire(1);
    },
    exportReportList() {
      //点击导出
      let number = this.condition.number.join(",");
      let params = {
        pageSize: 10,
        pageNo: this.currentPage,
        teamId: this.condition.teamId,
        loginName: this.userName,
        clientNumbers: number,
        onlyShowOnline: this.onlyShowOnline,
        gmtCreatedStart: this.condition.callEndAtStart,
        gmtCreatedEnd: this.condition.callEndAtEnd,
        summaryType: this.reportUnit,
        onlineMillisOrder: this.order["onlineSeconds"],
        idleMillisOrder: this.order["idleSeconds"],
        distributedCallCountOrder: this.order["distributedCallCount"],
        successCountOrder: this.order["successCount"],
        conversionRateOrder: this.order["conversionRate"],
      };
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      let href =
        this.$constants.baseURL + "/sip/client/exportReportList?" + url;
      this.exportDisabled = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.exportDisabled = data;
        }, 4000);
      });
      // window.location.href = href;
    },
    inquireUserList(params) {
      //查询公司名称
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          this.userNameList = list;
          this.userNameList.forEach((item) => {
            if (item.loginName === this.userName) {
              this.condition.loginName = this.userName;
            }
          });
        }
      });
    },
    // 选择用户名称
    loginNameChange(val) {
      this.userName = val;
      this.condition.teamId = "";
      this.condition.number = [];
    },
    // 用户名称获取焦点
    NameRemoteFocus() {
      this.inquireUserList({ pageSize: 1000, pageNo: 1, enabled: true });
    },
    NameRemoteMethod(userName) {
      //搜索用户名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize: 1000,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireUserList(params);
      }, 200);
    },
    inquireTeam(params) {
      //查询列队
      this.$http("/sip/team/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          this.teamList = list;
        }
      });
    },
    // 客服名称获取焦点
    numberRemoteFocus() {
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.condition.loginName,
      };
      this.inquireNumber(params);
    },
    // 客服名称搜索
    numberRemoteRemoteMethod(val) {
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.condition.loginName,
        number: val,
      };
      this.inquireNumber(params);
    },
    // 选择队列获取焦点
    teamRemoteFocus() {
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.condition.loginName,
      };
      this.inquireTeam(params);
    },
    // 选择队列
    teamChange(val) {
      this.userloginName = "";
      this.teamList.forEach((item) => {
        if (val == item.id) {
          this.userloginName = item.userLoginName;
        }
      });
    },
    inquireNumber(params) {
      //查询坐席
      this.$http("/sip/client/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          this.numberList = res.data.list;
        }
      });
    },
  },
};
</script>
<style scoped>
.companyName,
.callTime,
.taskName,
.batchName {
  width: 530px;
  margin-bottom: 20px;
}
.callTime >>> .el-input__inner {
  width: 331px;
}
.callTime .el-input {
  width: 331px;
}
.callTime,
.batchName {
  margin-right: 6px;
}
.callTime,
.batchName {
  float: right;
  text-align: left;
}
.batchName .checkboxClass,
.batchName .checkboxClass .el-checkbox-group {
  display: inline-block;
}
.checkboxClass {
  margin-right: 40px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>