<template>
	<div class="passengerSelect">
		<div class='nav'>
			<div class="time">
					起止时间：
					<el-date-picker class="startTime" v-model="condition.startTime" type="datetime" placeholder="起始时间"  value-format='yyyy-MM-dd HH:mm:ss'></el-date-picker>
					-
					<el-date-picker class="endTime" v-model="condition.endTime" type="datetime" placeholder="终止时间" value-format='yyyy-MM-dd HH:mm:ss'></el-date-picker>
			</div>
			<div class="equip">
					设备名称：
					<el-select v-model="condition.boxNumber" placeholder="请选择设备" >
							<el-option v-for="item in equipList" :key="item.id"  :value="item.boxNumber" :label="item.boxName"></el-option>
					</el-select> 
			</div> 
			<div class="distance">
					采集距离：
					<el-input v-model="condition.distance" placeholder="留空表示不限制距离 (单位: 米)" class='long'></el-input>
			</div>
			<div class="mac">
					mac码<i></i>：
					<el-input v-model="condition.mac" placeholder="请输入mac码" ></el-input>
			</div>			
			<div class="guest">
					顾客类型： 
					<el-checkbox v-model="condition.newCustomer">新顾客</el-checkbox>
					<el-checkbox v-model="condition.oldCustomer">老顾客</el-checkbox>
			</div>
			<div class="signList">
				标签列表：
				<el-select v-model="condition.signId" placeholder="请选择标签" >
						<el-option v-for="item in signList" :key="item.id"  :value="item.id" :label = 'item.tagName'></el-option>
				</el-select> 
			</div>
			<div class="filter">
					过滤已提交：
					<el-radio v-model="condition.filter" label="true" >过滤</el-radio>
					<el-radio v-model="condition.filter" label="false">不过滤</el-radio>
			</div>
			<div class="operate">
					<el-button type="primary" @click='inquire()'>查询</el-button>
					<el-button type="success" @click='reset()'>重置</el-button>
			</div>
			
		</div>
		
		查询结果:
		<i style="font-style: normal;">{{totalNum}}条</i> 
		<el-table stripe ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="selectChange" border align='center' class='table' >
				<el-table-column type="selection" prop="" label="22" width="60" class='column' align='center' ></el-table-column>
				<el-table-column type='index' prop="" label="序号" width="60" align='center'></el-table-column>
				<el-table-column prop="mac" label="mac" width="" align='center'></el-table-column>
				<el-table-column prop="brand" label="品牌" width="" align='center'></el-table-column>
				<el-table-column prop="boxNumber" label="设备ID" width="" align='center'></el-table-column>
				<el-table-column prop='lastActiveTime' label="时间" width="200" align='center'></el-table-column>
				<el-table-column prop="lastActiveDistance" label="采集距离" width="" align='center'></el-table-column>
				<el-table-column prop="newCome" label="顾客类型" width="120" align='center'></el-table-column>		   
		</el-table>
		
  	<el-button type="primary" @click="click_createGroups()" class='createGroupBtn'>创建群组</el-button>
  	<!--分页功能--> 		 
		<el-pagination   @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :pager-count="5" :total="totalNum"></el-pagination>
		
		<!-- 创建群组 -->		
		<el-dialog  :visible.sync="createGroups" custom-class='el-createGroups' width='450px'  >
				<div slot="title" class="dialog-title" >
					<h3>营销任务</h3>
				</div>	
				<el-form :model="form">
						<el-form-item label="" :label-width="formWidth">
								<b>有效数据：</b> 
								<span style="color: lightseagreen;">{{form.num}}</span> 条
						</el-form-item>
						<el-form-item label="" :label-width="formWidth">
								<b>群组名称：</b> 
								<el-input v-model="form.name" autocomplete="off" placeholder='请输入客群名称'></el-input>
						</el-form-item>
						<el-form-item label="" :label-width="formWidth">
								<b>所属行业：</b>
								<el-select v-model="form.industry" placeholder="请选择所属行业">
										<el-option v-for="item in industry" :key="item" :label="item" :value="item"></el-option>
								</el-select>
						</el-form-item>
						<el-form-item label="" :label-width="formWidth">
								<b>场景名称：</b>
								<el-input v-model="form.scene" autocomplete="off" placeholder='请输入场景名称'></el-input>
						</el-form-item>
				</el-form>		  
				<div slot="footer" class="dialog-footer" align="center">
				<el-button type="primary" @click="createGroups_submit()">提 交</el-button>
				<el-button @click="createGroups_cancel()" >取 消</el-button>
				</div>
		</el-dialog>
		
		<!-- 开始营销 -->
		<el-dialog  :visible.sync="startMarketing" custom-class='el-dialog2'  width='500px' >
				<div slot="title" class="dialog-title" >
					<h3>开始营销</h3>
				</div>	
				<b class="word">群组创建成功，群组名称：<i>{{form.name}}</i>，共计<i> {{num}} </i>条。</b>		
				<div slot="footer" class="dialog-footer" align="center">
						<el-button type="primary" @click="startMarketing_sms()" >导入短信系统</el-button>
						<el-button type="primary" @click="startMarketing_phone()" v-show='hide'>导入呼叫系统</el-button>
						<el-button @click="startMarketing_cancel()" >取 消</el-button>
				</div>
		</el-dialog>
  
	</div>
</template>

<script>
	export default {
		name:'PassengerSelect',
		data(){
			return {
				msg:'潜客筛选',
				equipList:'' ,
				condition:{
						boxNumber:'',
						startTime:'',
						endTime:'',
						distance:'',
						newCustomer:'',
						oldCustomer:'',
						filter:'true',
						mac:'',
						signId:''
				},						
				signList:[],
				tableData: [],
				selectAll:'',
				allArr:[],																					
				currentPage:1,
				totalNum:null,
				num:'',
				industry:['家居装修','医疗美容','房地产','信用卡','会展','教育','旅游','游戏','理财','个贷','网贷','婚恋','其他'],				
				
				createGroups: false,
				form: {
						num:'',
						name: '',
						industry: '',
						scene:'',
						ids:''
				},
				startMarketing:false,
				formWidth: '30px',
				hide:true
										
			}
		},
		watch:{
			equipList(val){
				if(val.length< 2) {
					this.$confirm('设备列表为空，请跳转至 设备管理!', '提示', {
						confirmButtonText: '跳转',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {//跳转操作													
						this.$router.push('/equipmentManager');																
					});
				}
			}
		},
		
		mounted(){
			this.getSignList();			
			this.inquire();
			this.getBoxNumber();
				
			let index = sessionStorage.getItem('machineCalling');
			if(index == -1){
					this.hide = false;
			}else {
					this.hide = true;
			}
				
		},
		methods:{
			getBoxNumber(){// 获取设备列表
				let data = {
						pageNo:1,
						pageSize:1000
					};
				this.$http('/box/list',data).then(res=>{
					if(res.result == 200){
						let list = res.data.list;
							list.unshift({id:22,boxName:'不勾选设备',boxNumber:null});										
							this.equipList = list;
					}				
				});
			},
			getSignList(){//获取标签列表
				let data = {
						pageNo: 1,
						pageSize: 1000
				};
				this.$http('/tag/tagList',data).then(res=>{				
					if(res.result == 200){
						let list = res.data.list;
							list.unshift({id:null,tagName:'不勾选标签'});	
							this.signList = list;
					}
				});
			},
			
			inquire(){// 点击查询								
				let data = {
						pageNo: this.currentPage,
						pageSize: 10,
						boxNumbers:this.condition.boxNumber,
						lastActiveDateStart:this.condition.startTime,
						lastActiveDateEnd:this.condition.endTime,
						maxDistance:this.condition.distance,
						needNewCustomer:this.condition.newCustomer,
						needOldCustomer:this.condition.oldCustomer,
						excludeSmsSubmited:this.condition.filter, 
						mac: this.condition.mac,
						tags:this.condition.signId						
				};
			this.$http('/customer/list',data).then(res=>{
				if(res.result == 200) {
					let list = res.data.list;
					this.totalNum = res.data.count;
					//数据处理
					list.map(item=>{
						item.lastActiveTime = this.$common.transformTime(item.lastActiveTime);	
						if(item.lastActiveDistance == -1) {
								item.lastActiveDistance = '未知';
						}else {
								item.lastActiveDistance +='米';
						}
						if(item.smsSendCount) {
								item.smsSendCount = '是';
						}else {
								item.smsSendCount = '否';
						}
						if(item.newCome == 1) {
								item.newCome = '新客';
						}else {
								item.newCome = '老客';
						}	
							
					});
					
					this.tableData = list;											
				} 

				
			})},
			reset(){//重置按钮
				this.condition = {
						boxNumber:'',
						startTime:'',
						endTime:'',
						distance:'',
						newCustomer:'',
						oldCustomer:'',
						filter:'true',
						mac:'',
						signId:''
				}
			},
			selectChange(data){//选中列表
					if(data.length > 0){
						this.form.num = data.length;
					}
					if(data.length == 0){
						this.form.num = '';
					}
					this.selectAll = '';
					data.map(item=>{
						this.selectAll += item.id+',';
					});
			},
			click_createGroups(){//点击创建群组
				if(this.form.num == ''){
					this.form.num = this.totalNum;
				}
				this.createGroups = true;
			},
     	handleCurrentChange(val) {//请求当前页数据								
				this.inquire();												
		  },
				
			createGroups_cancel(){//取消群组
					this.form.name = '';
					this.form.industry = '';
					this.form.scene = '';					
					this.$refs.multipleTable.clearSelection();	//取消多选				
					this.createGroups = false;
			},
		  createGroups_submit(){//提交群组
				if(this.form.name!=''&&this.form.industry!=''&&this.form.scene!=''){
					let data = {
								boxNumbers:this.condition.boxNumber,
								lastActiveDateStart:this.condition.startTime,
								lastActiveDateEnd:this.condition.endTime,
								maxDistance:this.condition.distance,
								needNewCustomer:this.condition.newCustomer,
								needOldCustomer:this.condition.oldCustomer,
								excludeSmsSended:this.condition.filter,						
								groupName:this.form.name,
								industry:this.form.industry ,
								remark:this.form.scene,
								ids:this.selectAll																			
						};
					this.$http('/sms/addMacGroup',data).then(res=>{						
						if (res.result == 200) {
								this.$message({message: '群组创建成功！',type: 'success'});
								this.num = res.data.totalCount;
								this.$refs.multipleTable.clearSelection();	//取消多选	
								this.createGroups = false;
								this.startMarketing = true;					
						}
					});	
				}else {
							this.$message({message: '信息填写不完整！',type: 'warning'});		
				}
    },
		startMarketing_sms(){//跳转短信系统		    						
						this.form.name = '';
						this.form.industry = '' ;
						this.form.scene = '';
						this.$router.push({ path:'/SMSmarketing', query:{ groupId:this.groupId } });
						this.startMarketing = false;
		     },
		startMarketing_phone(){//外呼跳转		    						
						this.form.name = '';
						this.form.industry = '' ;
						this.form.scene = '';
						this.$router.push({ path:'/machineCalling', query:{ groupId:this.groupId } });						
						this.startMarketing = false;
		    },
		startMarketing_cancel(){//取消营销
						this.startMarketing = false;
						this.form.name = '';
						this.form.industry = '' ;
						this.form.scene = '';
		    }
		}
	}
</script>

<style scoped="scoped">
	.passengerSelect{
		position: relative;
		/* min-height: 900px; */
	  margin-bottom: 50px;
	}
	.nav{
		width: 1200px;
		margin-bottom: 20px;
	}
	.time,.equip,.mac,.distance,.guest,.filter,.operate,.signList{
		width: 600px;
		margin-bottom: 20px;
	}
	.time,.distance,.guest{
		float: right;
		text-align: left;
	}
	.equip .el-select,.mac .el-input,.distance .el-input,.signList .el-select{
		width: 430px;
	}
	.time .startTime {
		width: 200px !important;
		margin-right: 6px;
	}
	.time .endTime{
		width: 200px !important;
		margin-left:7px ;
	}
	.mac i{
		margin-left: 16px;
	}
	.operate{
		margin-top: 40px;
	}
	.operate .el-button{
		width: 120px;
	}
	.createGroupBtn{
		width: 120px;
	}
	.table{
		margin:20px 0 30px 0;
	}
	.el-pagination{
		float: right;
		text-align: right;
	}
	.el-dialog .el-input,.el-dialog .el-select{
		width: 250px;
	}
	.el-dialog b{
		color:#657180 ;
		font-size: 17px;
	}
	.el-dialog2 .word{
		display: block;
		text-align: center;
	}
	.el-dialog b i{
		font-style: normal;
		color: lightseagreen;
	}
	.dialog-footer .el-button {
		width: 120px;
	}
</style>