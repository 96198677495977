<template>
  <div class="m-login">
    <div class="m-content">
      <div class="m-img">
        <!-- <h2>惠销平台管理系统</h2> -->
        <img src="@/assets/img/denglu.svg" alt="" />
      </div>
      <div class="m-denglu">
        <div class="m-login-title">
          <h2>惠销平台管理系统</h2>
          <el-input
            v-model="user.name"
            placeholder="账号"
            prefix-icon="iconfont icon-denglu"
            :spellcheck="false"
          ></el-input>
          <el-input
            :type="type"
            v-model="user.password"
            placeholder="密码"
            ref="password"
            autocomplete="new-password"
            prefix-icon="iconfont icon-icon_password"
            class="transform"
          ></el-input>
          <i
            class="iconfont icon-yanjing1 mark"
            @click="transform()"
            ref="icon"
          ></i>
        </div>
        <div class="m-btn">
          <el-button type="primary" round @click="login" :loading="toLoginShow"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="onHome">
    <div class="home">
      <div class="login">
        <h2>{{msg}}</h2>
        <el-input
          v-model="user.name"
          placeholder="账号"
          prefix-icon="iconfont icon-denglu"
          :spellcheck="false"
        ></el-input>
        <el-input
          :type="type"
          v-model="user.password"
          placeholder="密码"
          ref="password"
          autocomplete="new-password"
          prefix-icon="iconfont icon-icon_password"
          class="transform"
        ></el-input>
        <i class="iconfont icon-yanjing1 mark" @click="transform()" ref="icon"></i>
        <div class="operate">
          <el-button type="primary" @click="login" v-show="loginShow">登录</el-button>
          <el-button type="primary" disabled v-show="toLoginShow">登录中...</el-button>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import "../assets/icon-font/iconfont.css";
import loginHttp from "../components/js/loginHttp.js";

export default {
  name: "Home",
  data() {
    return {
      user: {
        name: "",
        password: "",
      },
      type: "password",
      loginShow: true,
      toLoginShow: false,
    };
  },
  watch: {
    password(val) {
      let _this = this;
      this.$refs.password.$el.onkeydown = function (e) {
        if (
          e.keyCode == 13 &&
          _this.user.name != "" &&
          _this.user.password != ""
        ) {
          _this.login();
        }
      };
    },
  },
  computed: {
    password() {
      return this.user.password;
    },
  },
  mounted() {
    document.title = "惠销平台管理系统";
    this.autologin();
  },
  methods: {
    // 检查session自动登录
    autologin() {
      loginHttp("/user/userInfo", null).then((res) => {
        if (res.result == 200) {
          let route = "/menu",
            menu = null;
          let menuArr = res.data.menus;
          if (menuArr && menuArr.length > 0) {
            menu = menuArr[0].subMenus;
            if (menu) {
              route = JSON.parse(menu[0].extraMsg).index;
            } else {
              route = JSON.parse(menuArr[0].extraMsg).index;
            }
          }
          if (res.data.indexUrl) {
            route = res.data.indexUrl;
          }
          this.loginSuccess(route);
        }
      });
    },
    login() {
      //用户登录
      if (this.user.name !== "" && this.user.password !== "") {
        this.loginShow = false;
        this.toLoginShow = true;

        // 用户名密码
        this.$http("/user/login", this.user)
          .then((res) => {
            this.toLoginShow = false;
            if (res.result == 200) {
              //成功
              let route = "/menu",
                menu = null;
              this.$http("/user/userInfo", null).then((res) => {
                if (res.result == 200) {
                  let menuArr = res.data.menus;
                  if (menuArr && menuArr.length > 0) {
                    menu = menuArr[0].subMenus;
                    if (menu) {
                      route = JSON.parse(menu[0].extraMsg).index;
                    } else {
                      route = JSON.parse(menuArr[0].extraMsg).index;
                    }
                  }
                  if (res.data.indexUrl) {
                    route = res.data.indexUrl;
                  }
                  this.loginSuccess(route);
                }
              });
            }
          })
          .catch((err) => {
            this.loginShow = true;
            this.toLoginShow = false;
          });
      } else {
        //用户名密码为空
        this.$message({
          message: "用户名和密码均不能为空！",
          type: "warning",
        });
      }
    },
    // 登录成功
    loginSuccess(route) {
      let isRouter = true;
      let oldUserName = sessionStorage.getItem("userName"); //获取上次登录名
      sessionStorage.setItem("userName", JSON.stringify(this.user.name)); //缓存登录名
      if (oldUserName) {
        //判断是否第一次登录
        if (
          oldUserName.substring(1, oldUserName.length - 1) !== this.user.name
        ) {
          //判断登录名是否一致
          isRouter = false;
        }
      }
      let oldUrl = sessionStorage.getItem("oldUrl"); //获取上次页面url
      if (oldUrl !== "-1" && oldUrl && isRouter) {
        let sign = oldUrl.indexOf("#");
        let length = this.$constants.baseURL.length - 5;
        if (sign) {
          length = this.$constants.baseURL.length - 6;
        }
        let url = oldUrl.substring(length, oldUrl.length - 1);
        this.$router.push(`${url}`);
        sessionStorage.removeItem("oldUrl");
      } else {
        this.$router.push(`${route}`);
      }
      this.$message({ message: "登录成功！", type: "success" });
    },
    transform() {
      //密码显示与隐藏
      if (this.type == "password") {
        this.$refs.icon.classList.remove("icon-yanjing1");
        this.$refs.icon.classList.add("icon-yanjing");
        this.type = "text";
      } else {
        this.$refs.icon.classList.remove("icon-yanjing");
        this.$refs.icon.classList.add("icon-yanjing1");
        this.type = "password";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.m-login {
  background-image: url("../assets/img/bg.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  background-color: #f9f9fa;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.m-login .m-content {
  min-width: 810px;
  max-width: 1024px;
  min-height: 512px;
  max-height: 672px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 16px;
  /* padding: 32px; */
  box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04),
    0 6px 22px 4px rgba(7, 48, 114, 0.12),
    0 6px 10px -4px rgba(14, 13, 26, 0.12);
  display: flex;
  align-items: stretch;
}
.m-login .m-content .m-img {
  width: 50%;
  /* height: 100%; */
  background: rgba(212, 216, 219, 0.15);
  border-radius: 16px 0 0 16px;
}
.m-login .m-content .m-denglu {
  width: 50%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.m-login .m-content .m-img h2 {
  /* text-align: center; */
  margin: 40px 32px 90px 32px;
  color: #3f3e3e;
}
.m-login .m-content .m-img img {
  text-align: center;
  margin: 155px 32px 0px 32px;
}
.m-login .m-content .m-denglu .m-login-title {
  width: 80%;
  position: relative;
}
.m-login .m-content .m-denglu .m-login-title h2 {
  position: absolute;
  color: #3f3e3e;
  top: -105px;
  left: 0px;
}
.m-login .m-content .m-denglu .m-login-title .el-input {
  margin: 20px 0px;
}
.m-login .m-content .m-denglu .m-btn {
  width: 80%;
  text-align: center;
}
.m-login .m-content .m-denglu .m-btn .el-button {
  width: 100%;
  margin-top: 30px;
}
.onHome {
  width: 100%;
  height: 100%;
  position: absolute;
  /* display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto; */
  background: #123356;
}
.login {
  width: 500px;
  height: 300px;
  box-sizing: border-box;
  margin: 200px auto 0;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 2%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 10;
  padding-top: 25px;
}

.login h2 {
  text-align: center;
  margin-bottom: 50px;
  color: white;
}
.login .el-input {
  display: block;
  width: 360px;
  margin: 0px auto;
}
.login .el-input:nth-of-type(2) {
  margin: 25px auto;
}
.mark {
  font-size: 20px;
  position: absolute;
  top: 110px;
  right: 12px;
  color: #708998;
}
.operate {
  text-align: center;
}
.login .el-button {
  width: 360px;
  margin: 0;
}
</style>
