<template>
  <el-dialog title="标签检索" :visible.sync="visibleTagShow" @close="closeTag" width="500px">
    <template slot="title">
      <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
      <span class="dialog-title-my my-title">标签检索</span>
      <el-tooltip class="item" effect="light" content="不同的分组取交集" placement="top-start">
        <i class="el-icon-question" style="color: #909399"></i>
      </el-tooltip>
    </template>
    <el-form
      :model="dynamicValidateForm"
      ref="dynamicValidateForm"
      label-width="100px"
      class="demo-dynamic"
    >
      <el-form-item
        v-for="(domain, index) in dynamicValidateForm.domains"
        :label="'分组' + (index + 1)+ '：'"
        :key="index"
      >
        <el-cascader
          v-model="domain.wechatLabelList"
          :options="options"
          :props="props"
          clearable
          :show-all-levels="false"
          collapse-tags
          placeholder="选择标签"
        ></el-cascader>
        <el-button @click.prevent="removeDomain(domain)" v-if="index !== 0">删除</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">确定</el-button>
        <el-button @click="addDomain">新增分组</el-button>
        <el-button @click="visibleTagShow = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  name: "tagRetrieval",
  props: ["tagList"],
  data() {
    return {
      props: { multiple: true },
      options: [],
      visibleTagShow: false,
      dynamicValidateForm: {}
    };
  },
  mounted() {},
  methods: {
    // 初始化
    init() {
      this.$nextTick(() => {
        this.visibleTagShow = true;
        this.getSearchTag();
        if (this.tagList) {
          this.dynamicValidateForm.domains = this.tagList;
        } else {
          this.dynamicValidateForm = {
            domains: [
              {
                wechatLabelList: []
              }
            ]
          };
        }
      });
    },
    // 查询标签列表
    getSearchTag() {
      this.$http("/wechat/labelList", null, "get", null, {category: ""}).then(res => {
        if (res.result === 200) {
          let list = res.data;
          let tagArr = []; //标签数组
          let labelCategoryArr = []; //分类数组
          list.forEach(item => {
            labelCategoryArr.push(item.labelCategory);
          });
          labelCategoryArr = this.$common.unique(labelCategoryArr);
          labelCategoryArr.forEach((item, index) => {
            tagArr.push({
              value: index + "-",
              label: item,
              children: []
            });
          });
          list.forEach(menu => {
            let labelRemark = "";
            if (menu.labelRemark) {
              labelRemark = "( " + menu.labelRemark + " )";
            }
            tagArr.forEach(i => {
              if (i.label === menu.labelCategory) {
                i.children.push({
                  value: menu.label,
                  label: menu.label + labelRemark
                });
              }
            });
          });
          this.options = tagArr;
        }
      });
    },
    // 点击确定
    submitForm() {
      let tagArr = [];
      let tags = "";
      let list = this.dynamicValidateForm.domains;
      list.forEach(item => {
        let tagList = item.wechatLabelList;
        let arr = [];
        tagList.forEach(menu => {
          if (menu.length > 0) {
            arr.push(menu[1]);
          }
        });
        tagArr.push(arr.join(","));
      });
      tags = tagArr.join("|");
      let data = {
        tags: tags,
        wechatLabelList: this.$common.deepClone(
          this.dynamicValidateForm.domains
        )
      };
      this.$emit("submit", data);
      this.visibleTagShow = false;
    },
    // 点击删除
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    // 点击新增
    addDomain() {
      this.dynamicValidateForm.domains.push({
        wechatLabelList: []
      });
    },
    // 关闭弹框
    closeTag() {
      this.options = [];
      this.dynamicValidateForm = {
        domains: []
      };
    }
  }
};
</script>
<style scoped>
</style>

