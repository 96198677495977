<template>
  <el-dialog
    title="编辑标签"
    :visible.sync="visibleTagOperation"
    @close="closeTagOperation"
    width="650px"
  >
    <template slot="title">
      <i class="el-icon-edit-outline dialog-title-my" style="color:#1296db;"></i>
      <span class="dialog-title-my my-title">编辑标签</span>
    </template>
    <div class="tag-list">
      <el-tag
        v-for="(tag, index) in tagLabelLiat"
        :key="index"
        @close="tagHandleClose(tag)"
        type="info"
        closable
      >{{tag}}</el-tag>
    </div>
    <div class="tag-add">
      <i>绑定:</i>
      <el-button @click="existingTags">已有标签</el-button>
      <i></i>
      <el-cascader
        v-model="existingTagsValue"
        :options="options"
        :props="props"
        clearable
        :show-all-levels="false"
        collapse-tags
        placeholder="选择标签"
        v-if="isExistingTagsShow"
        @change="existingTagsChange"
      ></el-cascader>
      <i></i>
      <el-button @click="newTag">新建标签</el-button>
      <div class="tag-add-label" v-if="isAddTagShow">
        <i>标签名称:</i>
        <el-input placeholder="请输入标签名称" v-model="tagName"></el-input>
        <el-button type="primary" @click="addTag">添加标签</el-button>
      </div>
    </div>
    <template slot="footer">
      <div style="display: flex; justify-content: center">
        <el-button key="back" @click="visibleTagOperation = false">取消</el-button>
        <el-button key="submit" type="primary" :loading="loading" @click="addTagOk">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: "tagOperation",
  props: ["customerId", "customerData", "callerUuid"],
  data() {
    return {
      visibleTagOperation: false,
      isExistingTagsShow: false,
      isAddTagShow: false,
      loading: false,
      tagName: "",
      tagLabelLiat: [],
      tagLabelLiatCopy: [],
      existingTagsValue: [],
      props: { multiple: true },
      options: [],
    };
  },
  mounted() {},
  computed: {
    originalData() {
      return this.customerData;
    }
  },
  methods: {
    // 初始化
    init() {
      this.$nextTick(() => {
        this.visibleTagOperation = true;
        this.tagLabelLiat = [];
        this.existingTagsValue = [];
        if (this.originalData) {
          this.originalData.forEach(item => {
            this.tagLabelLiat.push(item.label);
          });
        }
      });
    },
    // 点击已有标签
    existingTags() {
      this.isExistingTagsShow = !this.isExistingTagsShow;
      this.isAddTagShow = false;
      this.getSearchTag();
    },
    // 查询标签列表
    getSearchTag() {
      this.$http("/wechat/labelList", null, "get", null, {category: "COMMON"}).then(res => {
        if (res.result === 200) {
          let list = res.data;
          let tagArr = []; //标签数组
          let labelCategoryArr = []; //分类数组
          list.forEach(item => {
            labelCategoryArr.push(item.labelCategory);
          });
          labelCategoryArr = this.$common.unique(labelCategoryArr);
          labelCategoryArr.forEach((item, index) => {
            tagArr.push({
              value: index + "-",
              label: item,
              children: []
            });
          });
          list.forEach(menu => {
            let labelRemark = "";
            if (menu.labelRemark) {
              labelRemark = "( " + menu.labelRemark + " )";
            }
            tagArr.forEach(i => {
              if (i.label === menu.labelCategory) {
                i.children.push({
                  value: menu.label,
                  label: menu.label + labelRemark
                });
              }
            });
          });
          this.options = tagArr;
        }
      });
    },
    // 点击新建标签
    newTag() {
      this.isAddTagShow = !this.isAddTagShow;
      this.isExistingTagsShow = false;
    },
    // 添加标签
    addTag() {
      const tagName = this.tagName;
      let tagLabelLiat = this.tagLabelLiat;
      if (tagName && tagLabelLiat.indexOf(tagName) === -1) {
        tagLabelLiat = [...tagLabelLiat, tagName];
      } else {
        this.$message.warning("标签已绑定");
      }
      this.tagLabelLiat = tagLabelLiat;
      this.tagName = "";
    },
    // 选择已有标签
    existingTagsChange(val) {
      this.tagLabelLiatCopy = this.$common.unique(val);
      this.tagLabelLiatCopy.forEach(item => {
        this.tagLabelLiat.push(item[1]);
      });
      this.tagLabelLiat = this.$common.unique(this.tagLabelLiat);
    },
    // 删除标签数组
    tagHandleClose(removedTag) {
      const tags = this.tagLabelLiat.filter(tag => tag !== removedTag);
      this.tagLabelLiat = tags;
      this.existingTagsValue = [];
      tags.forEach(item => {
        this.tagLabelLiatCopy.forEach(menu => {
          if (item === menu[1]) {
            this.existingTagsValue.push(menu);
          }
        });
      });
    },
    // 确定操作标签
    addTagOk() {
      let data = {
        wechatCustomerId: this.customerId,
        labelList: this.tagLabelLiat,
        category: "COMMON",
        callerUuid: this.callerUuid
      };
      this.loading = true;
      this.$service("/wechat/updateLabel", data)
        .then(res => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.visibleTagOperation = false;
            this.$emit("submit", false);
          }
        })
        .catch(err => {
          this.loading = false;
        });
    },
    // 关闭编辑标签
    closeTagOperation() {
      this.tagName = "";
      this.tagLabelLiat = [];
      this.existingTagsValue = [];
      this.isAddTagShow = false;
      this.isExistingTagsShow = false;
      this.$emit("cancel", false);
    }
  }
};
</script>
<style scoped>
.tag-list {
  border: 2px dashed #dddada;
  height: 150px;
  padding: 10px;
}
.tag-list .tag-content {
  display: inline-block;
}
.tag-add {
  margin-top: 20px;
}
.tag-add i {
  font-style: normal;
  margin-right: 10px;
}
.tag-add .tag-add-label {
  display: inline-block;
}
.tag-add .tag-add-label i {
  font-style: normal;
  margin: 0px 10px;
}
.tag-add .tag-add-label .el-input {
  width: 130px;
  margin-right: 10px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>

