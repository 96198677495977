<template>
  <div class="right_menu" id="right_menu">
    <ul>
      <li 
        @click="deleteClick" 
        :class="{
          'disabled': isdeleteDisabled
        }"
      >删除</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "right_menu",
  props: {
    menuData: Object,
    viewOnly: Boolean
  },
  data() {
    return {
      isDisabled: false,
      isdeleteDisabled: false,
    }
  },
  mounted() {
    let div1 = document.getElementById("right_menu");
    div1.style.left = this.menuData.left;
    div1.style.top = this.menuData.top;
    div1.style.zIndex = 3000;
    div1.style.position = "absolute";
    div1.style.display = "block";
    if (this.menuData.starter) {
      this.isdeleteDisabled = true;
    }
  },
  methods: {
    // 点击测试
    testSound() {
      let nodeId = this.menuData.nodeId;
      let type = this.menuData.type;
      if (this.isDisabled) {
        return
      }
      if (nodeId) {
        this.$emit("testSound", nodeId);
        return
      }
      this.$emit("testSound", null, type);
    },
    // 点击删除
    deleteClick() {
      if (this.isdeleteDisabled) {
        return
      }
      if (this.menuData.clickType === "node") {
        let nodeId = this.menuData.nodeId;
        this.$emit("deleteNodeClick", nodeId);
      }
      if (this.menuData.clickType === "line") {
        let sourceId = this.menuData.sourceId;
        let targetId = this.menuData.targetId;
        this.$emit("deleteLineClick", sourceId, targetId);
      }
    }
  }
}
</script>