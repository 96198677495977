<template>
  <div class="prologueTemplate">
    <!-- 开场白列表 -->
    <el-dialog
      title="开场白列表"
      :visible.sync="prologueListShow"
      width="800px"
      class="connectedTable"
      :append-to-body="appendToBody"
      @close="prologueListClose"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">开场白列表</span>
      </template>
      <el-table
        :data="prologueTableData"
        stripe
        style="width: 100%"
        border
        size="mini"
      >
        <el-table-column
          type="index"
          label="编号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="开场白名称"
          min-width="130"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="fileName"
          label="文件名称"
          min-width="130"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          min-width="130"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="gmtCreated"
          label="创建时间"
          width="170"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="100" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="playRecord(scope.row)"
              >播放</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="selectionClick(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="prologueCurrentPage_data"
        :current-page.sync="prologueCurrentPage"
        :page-size="10"
        layout="total,prev, pager, next,jumper"
        :total="prologueTotal"
        :pager-count="5"
      ></el-pagination>
      <!-- 播放录音 -->
      <el-dialog
        class="customDialog"
        :visible.sync="playRecordShow"
        width="300px"
        @close="closePlayRecordShow"
        append-to-body
      >
        <audio
          ref="audio"
          :src="currentSrc"
          autoplay="autoplay"
          controlsList="nodownload"
          controls="controls"
        ></audio>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "prologueTemplate",
  props: {
    appendToBody: {
      // Dialog 自身是否插入至 body 元素上。
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      playFlag: true, //播放状态
      currentSrc: "",
      prologueListShow: false,
      playRecordShow: false,
      prologueCurrentPage: 1,
      prologueTotal: 0,
      prologueTableData: [],
    };
  },
  mounted() {},
  methods: {
    // 打开弹框
    init() {
      this.prologueListShow = true;
      this.inquirePrologue();
    },
    // 点击取消
    close() {
      this.prologueListShow = false;
    },
    // 查询--开场白列表
    inquirePrologue(index) {
      let data = {
        pageNo: index || 1,
        pageSize: 10,
      };
      this.getPrologueList(data);
    },
    // 查询开场白列表
    getPrologueList(data) {
      this.$http("/prologue/list", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          });
          this.prologueTotal = res.data.total;
          this.prologueTableData = list;
        }
      });
    },
    // 开场白--分页
    prologueCurrentPage_data(index) {
      this.inquirePrologue(index);
    },
    // 关闭选择开场白
    prologueListClose() {
      this.prologueTableData = [];
      this.prologueCurrentPage = 1;
    },
    // 选中开场白
    selectionClick(val) {
      let data = {
        id: val.id,
        title: val.title
      }
      this.$emit("select", data);
      this.prologueListShow = false;
    },
    // 点击播放开场白
    playRecord(val) {
      this.playRecordShow = true;
      this.currentSrc = `https://${val.server}${this.$constants.basePath}/prologue/prePlayPrologue?id=${val.id}`;
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        // this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    // 关闭播放
    closePlayRecordShow() {
      //播放录音关闭
      this.$refs.audio.pause();
      this.playFlag = false;
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.connectedTable >>> .el-table__body .cell {
  font-size: 14px;
}
.connectedTable >>> .el-dialog__body::after {
  display:block;
  content:'';
  clear:both;
}
.connectedTable >>> .el-dialog__body {
  padding: 10px 20px;
}
.connectedTable >>> .el-table {
  margin: 0 0 20px 0;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
</style>