<template>
  <div class="operationRecord">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="任务日志" name="task">
        <operationRecordTask v-if="isTask"></operationRecordTask>
      </el-tab-pane>
      <el-tab-pane label="账号日志" name="account">
        <operationRecordAccount v-if="isAccount"></operationRecordAccount>
      </el-tab-pane>
      <el-tab-pane label="线路日志" name="route">
        <operationRecordRoute v-if="isRoute"></operationRecordRoute>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import operationRecordAccount from './operationRecordAccount.vue';
import operationRecordTask from './operationRecordTask.vue';
import operationRecordRoute from './operationRecordRoute.vue';
export default {
  name: "operationRecord",
  components: {
    operationRecordAccount,
    operationRecordTask,
    operationRecordRoute
  },
  data() {
    return {
      activeName: 'task',
      isTask: false,
      isAccount: false,
      isRoute: false,
    }
  },
  mounted() {
    this.isTask = true;
  },
  methods: {
    handleClick(val) {
      if (this.activeName === 'task') {
        this.isTask = true;
        this.isAccount = false;
        this.isRoute = false;
      }
      if (this.activeName === 'account') {
        this.isTask = false;
        this.isRoute = false;
        this.isAccount = true;
      }
      if (this.activeName === 'route') {
        this.isTask = false;
        this.isRoute = true;
        this.isAccount = false;
      }
    }
  },
}
</script>
<style scoped>
.nav_menu_m {
  margin-bottom: 20px;
}
.nav_menu_m .nav_item {
  display: inline-block;
  margin-right: 20px;
}
.nav_menu_m .nav_item .el-input,
.nav_menu_m .nav_item .el-cascader,
.nav_menu_m .nav_item .el-select {
  width: 250px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
</style>