<template>
  <div class="route-list">
    <div>
      <div class="name-class">
        线路名称：
        <i style="margin-left: 16px"></i>
        <el-input
          v-model="lineName"
          placeholder="请输入线路名称"
          clearable
        ></el-input>
      </div>
      <div class="name-class">
        状态：
        <el-select v-model="projectStatus" placeholder="请选择状态" clearable>
          <el-option
            v-for="item in projectStatusList"
            :key="item.status"
            :value="item.status"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div>
      <div class="name-class" v-if="roleMenu === 'SUPER_MANAGER'">
        线路供应商：
        <el-input
          v-model="providerName"
          placeholder="请输入线路供应商"
          clearable
        ></el-input>
      </div>
      <div class="name-class">
        标签：
        <el-select
          v-model="routeTags"
          multiple
          placeholder="请输选择标签"
          clearable
        >
          <el-option
            v-for="item in routeTagList"
            :key="item.id"
            :value="item.tag"
            :label="item.tag"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div>
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button type="primary" @click="addClick">新增线路</el-button>
    </div>
    <!-- 线路表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        type
        prop="name"
        label="线路名称"
        min-width="220"
        align="left"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
          <span v-for="(item, index) in scope.row.tagList" :key="index">
            <el-tag size="mini" style="margin-right: 5px">{{ item }}</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="provider"
        label="线路供应商"
        min-width="150"
        align="left"
        show-overflow-tooltip
        v-if="roleMenu === 'SUPER_MANAGER'"
      ></el-table-column>
      <el-table-column
        type
        prop="ip"
        label="IP地址"
        width="135"
        align="left"
      ></el-table-column>
      <el-table-column
        type
        prop="port"
        label="端口"
        width="85"
        align="left"
      ></el-table-column>
      <el-table-column
        type
        prop="prefix"
        label="呼叫前缀"
        width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        type
        prop="displayNumber"
        label="主叫号码"
        width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        type
        prop="city"
        label="归属地"
        width="110"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        type
        prop="maxConcurrencyLabel"
        label="最大并发数"
        width="105"
        align="center"
      ></el-table-column>
      <el-table-column type prop label="状态" width="70" align="center">
        <template slot-scope="scope">
          <el-tag :type="statusType['type_' + scope.row.id]" size="small">{{
            scope.row.routeStatus
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        type
        prop="feeLabel"
        label="成本"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        type
        prop="remark"
        label="备注"
        width="200"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        type
        prop="routeType"
        label="线路类型"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        type
        prop="codec"
        label="编码"
        width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        type
        prop="gmtCreated"
        label="创建时间"
        width="170"
        align="center"
      ></el-table-column>
      <el-table-column
        type
        prop
        label="操作"
        :width="roleMenu === 'SUPER_MANAGER' ? '250' : '180'"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="Prohibit(scope.row)"
            v-if="scope.row.enabled && roleMenu === 'SUPER_MANAGER'"
            >禁用</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="unProhibit(scope.row)"
            v-show="!scope.row.enabled && roleMenu === 'SUPER_MANAGER'"
            >启用</el-button
          >
          <el-button type="text" size="small" @click="modifyShow(scope.row)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="modifyRouteUserRelation(scope.row)"
            :disabled="scope.row.type === 'COMBINATION_ROUTE'"
            >告警设置</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="testShow(scope.row)"
            :disabled="scope.row.type === 'COMBINATION_ROUTE'"
            >测试</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteShow(scope.row)"
            style="color: #ff0000b0"
            v-if="roleMenu === 'SUPER_MANAGER'"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 新增线路 -->
    <el-dialog
      :visible.sync="addRoute"
      class="el-addRoute"
      :width="form.authentic === 'SINGLE_ROUTE' ? '900px' : '500px'"
      @closed="addRouteClose"
      top="8vh"
    >
      <template slot="title">
        <i
          class="el-icon-circle-plus-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">新增线路</span>
      </template>
      <el-form
        :model="form"
        :label-position="labelLeft"
        label-width="120px"
        :inline="form.authentic === 'SINGLE_ROUTE'"
      >
        <el-form-item label="线路类型：">
          <el-select
            v-model="form.authentic"
            placeholder="请选择线路类型"
            @change="fictitiousChange(form.authentic)"
          >
            <el-option
              v-for="item in fictitiousList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="注册方式：" v-show="fictitiousShow">
          <el-select
            v-model="form.registType"
            placeholder="请选择注册方式"
            @change="checked(form.registType)"
          >
            <el-option
              v-for="item in routeRegistType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="返回状态：" v-show="returnStateShow">
          <el-select
            v-model="form.returnState"
            placeholder="请选择返回状态"
            @change="returnStateChange(form.returnState)"
          >
            <el-option
              v-for="item in returnStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="线路名称：" required>
          <el-input v-model="form.name" placeholder="请输入线路名称"></el-input>
        </el-form-item>
        <el-form-item
          label="线路供应商："
          v-show="fictitiousShow"
          v-if="roleMenu === 'SUPER_MANAGER'"
        >
          <el-input
            v-model="form.provider"
            placeholder="请输入线路供应商"
          ></el-input>
        </el-form-item>
        <el-form-item label="IP地址：" v-show="fictitiousShow" required>
          <el-input v-model="form.ip" placeholder="请输入IP地址"></el-input>
        </el-form-item>
        <el-form-item label="端口：" v-show="fictitiousShow" required>
          <el-input v-model="form.port" placeholder="请输入端口"></el-input>
        </el-form-item>
        <el-form-item label="呼叫前缀：" v-show="fictitiousShow">
          <el-input
            v-model="form.prefix"
            placeholder="请输入呼叫前缀"
          ></el-input>
        </el-form-item>
        <el-form-item label="主叫号码：" v-show="fictitiousShow">
          <el-input
            v-model="form.displayNumber"
            placeholder="请输入主叫号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="归属地：" v-show="fictitiousShow">
          <el-input v-model="form.city" placeholder="请输入归属地"></el-input>
        </el-form-item>
        <el-form-item label="成本：">
          <el-input v-model="form.fee" placeholder="请输入成本"></el-input>
        </el-form-item>
        <el-form-item label="最大并发：" v-show="fictitiousShow">
          <el-input
            v-model="form.maxConcurrency"
            @input="
              form.maxConcurrency = form.maxConcurrency.replace(/[^\d]/g, '')
            "
            placeholder="请输入最大并发"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号：" v-show="catched">
          <el-input
            v-model="form.login_name"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码：" v-show="catched">
          <el-input v-model="form.passwd" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="盲区限制：" v-show="fictitiousShow">
          <template slot="label">
            盲区限制
            <el-tooltip placement="top-start" effect="light">
              <i class="el-icon-question"></i>
              <div slot="content">
                <div>排除选择地区</div>
              </div> </el-tooltip
            >：
          </template>
          <el-cascader
            v-model="form.blindAreas"
            placeholder="可以试试搜索"
            :options="options"
            :props="props"
            filterable
            :show-all-levels="false"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="选择运营商：" v-show="fictitiousShow">
          <el-select
            v-model="form.operators"
            placeholder="请选择运营商"
            multiple
          >
            <el-option
              v-for="item in operatorsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="拨打时间限制："
          style="display: block"
          v-show="fictitiousShow"
          label-width="130px"
        >
          <template slot="label">
            拨打时间限制
            <el-tooltip placement="top-start" effect="light">
              <i class="el-icon-question"></i>
              <div slot="content">
                <div>开启拨打时间限制, 开启后无法在拨打时间之外进行外呼</div>
              </div> </el-tooltip
            >：
          </template>
          <el-switch
            v-model="form.timeControlEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
          <div v-if="form.timeControlEnabled">
            上午：
            <el-time-select
              placeholder="起始时间"
              v-model="form.startTimeAtMorning"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '12:00',
              }"
            >
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-model="form.endTimeAtMorning"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '12:00',
                minTime: form.startTimeAtMorning,
              }"
            >
            </el-time-select>
          </div>
          <div v-if="form.timeControlEnabled" style="margin-top: 8px">
            下午：
            <el-time-select
              placeholder="起始时间"
              v-model="form.startTimeAtAfternoon"
              :picker-options="{
                start: '12:00',
                step: '00:05',
                end: '24:00',
              }"
            >
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-model="form.endTimeAtAfternoon"
              :picker-options="{
                start: '12:00',
                step: '00:05',
                end: '24:00',
                minTime: form.startTimeAtAfternoon,
              }"
            >
            </el-time-select>
          </div>
        </el-form-item>
        <el-form-item label="选择标签：" style="display: block">
          <el-select
            v-model="form.routeTag"
            multiple
            placeholder="请输选择标签"
            clearable
          >
            <el-option
              v-for="item in routeTagList"
              :key="item.id"
              :value="item.tag"
              :label="item.tag"
            ></el-option>
          </el-select>
          <el-button
            type="text"
            style="margin-left: 10px"
            @click="addRouteTag"
            v-if="roleMenu === 'SUPER_MANAGER'"
            >标签管理</el-button
          >
        </el-form-item>
        <el-form-item label="备注：" style="display: block">
          <el-input
            v-model="form.note"
            type="textarea"
            :rows="4"
            placeholder="请输入备注"
            :style="{
              width: form.authentic === 'SINGLE_ROUTE' ? '700px' : '340px',
            }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="addRoute_submit()">提 交</el-button>
        <el-button @click="addRoute_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="修改线路"
      :visible.sync="modifyRoute"
      class="el-addRoute"
      :width="isFictitious ? '1050px' : '900px'"
      @close="modifyRouteClose"
      top="8vh"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">修改线路</span>
      </template>
      <!-- 真实线路表单 -->
      <el-form
        :model="modifyForm"
        :label-position="labelLeft"
        v-if="!isFictitious"
        :inline="!isFictitious"
        label-width="120px"
      >
        <el-form-item label="线路名称：">
          <el-input
            v-model="modifyForm.name"
            placeholder="请输入线路名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="线路供应商：" v-if="roleMenu === 'SUPER_MANAGER'">
          <el-input
            v-model="modifyForm.provider"
            placeholder="请输入线路供应商"
          ></el-input>
        </el-form-item>
        <el-form-item label="IP地址：">
          <el-input
            v-model="modifyForm.ip"
            placeholder="请输入IP地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="端口：">
          <el-input
            v-model="modifyForm.port"
            placeholder="请输入端口"
          ></el-input>
        </el-form-item>
        <el-form-item label="呼叫前缀：">
          <el-input
            v-model="modifyForm.prefix"
            placeholder="请输入呼叫前缀"
          ></el-input>
        </el-form-item>
        <el-form-item label="主叫号码：">
          <el-input
            v-model="modifyForm.displayNumber"
            placeholder="请输入主叫号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="归属地：">
          <el-input
            v-model="modifyForm.city"
            placeholder="请输入归属地"
          ></el-input>
        </el-form-item>
        <el-form-item label="成本：">
          <el-input
            v-model="modifyForm.fee"
            placeholder="请输入成本"
          ></el-input>
        </el-form-item>
        <el-form-item label="盲区限制：">
          <template slot="label">
            盲区限制
            <el-tooltip placement="top-start" effect="light">
              <i class="el-icon-question"></i>
              <div slot="content">
                <div>排除选择地区</div>
              </div> </el-tooltip
            >：
          </template>
          <el-cascader
            v-model="modifyForm.blindAreas"
            placeholder="可以试试搜索"
            :options="options"
            :props="props"
            filterable
            :show-all-levels="false"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="选择运营商：">
          <el-select
            v-model="modifyForm.operators"
            placeholder="请选择运营商"
            multiple
          >
            <el-option
              v-for="item in operatorsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="拨打时间限制："
          style="display: block"
          label-width="130px"
        >
          <template slot="label">
            拨打时间限制
            <el-tooltip placement="top-start" effect="light">
              <i class="el-icon-question"></i>
              <div slot="content">
                <div>开启拨打时间限制, 开启后无法在拨打时间之外开启任务</div>
              </div> </el-tooltip
            >：
          </template>
          <el-switch
            v-model="modifyForm.timeControlEnabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
          <div v-if="modifyForm.timeControlEnabled">
            上午：
            <el-time-select
              placeholder="起始时间"
              v-model="modifyForm.startTimeAtMorning"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '12:00',
              }"
            >
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-model="modifyForm.endTimeAtMorning"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '12:00',
                minTime: modifyForm.startTimeAtMorning,
              }"
            >
            </el-time-select>
          </div>
          <div v-if="modifyForm.timeControlEnabled" style="margin-top: 8px">
            下午：
            <el-time-select
              placeholder="起始时间"
              v-model="modifyForm.startTimeAtAfternoon"
              :picker-options="{
                start: '12:00',
                step: '00:05',
                end: '24:00',
              }"
            >
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-model="modifyForm.endTimeAtAfternoon"
              :picker-options="{
                start: '12:00',
                step: '00:05',
                end: '24:00',
                minTime: modifyForm.startTimeAtAfternoon,
              }"
            >
            </el-time-select>
          </div>
        </el-form-item>
        <el-form-item label="最大并发：">
          <div style="width: 360px">
            <el-radio
              v-model="radioConcurrent"
              label="unlimited"
              style="margin: 0"
              :disabled="isDisabledRoute"
              >无限制</el-radio
            >
            <el-radio
              v-model="radioConcurrent"
              label="limit"
              :disabled="isDisabledRoute"
              >限制</el-radio
            >
            <el-input
              v-model="modifyForm.maxConcurrency"
              @input="
                modifyForm.maxConcurrency = modifyForm.maxConcurrency.replace(
                  /[^\d]/g,
                  ''
                )
              "
              placeholder="请输入最大并发"
              v-if="radioConcurrent === 'limit'"
              style="width: 55%"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="选择编码：">
          <span>{{ modifyForm.coded }}</span>
          <el-button type="text" size="small" @click="codeChange()"
            >修改</el-button
          >
        </el-form-item>
        <el-form-item label="选择标签：" style="display: block">
          <el-select
            v-model="routeTag"
            multiple
            placeholder="请选择标签"
            clearable
          >
            <el-option
              v-for="item in routeTagList"
              :key="item.id"
              :value="item.tag"
              :label="item.tag"
            ></el-option>
          </el-select>
          <el-button
            type="text"
            style="margin-left: 10px"
            @click="addRouteTag"
            v-if="roleMenu === 'SUPER_MANAGER'"
            >标签管理</el-button
          >
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="modifyForm.note"
            type="textarea"
            :rows="4"
            placeholder="请输入备注"
            style="width: 700px"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 虚拟线路表单 -->
      <div class="fictitious-modify" v-if="isFictitious">
        <div>
          线路名称：<i style="width: 25px; display: inline-block"></i>
          <el-input
            v-model="modifyForm.name"
            placeholder="请输入线路名称"
          ></el-input>
        </div>
        <div>
          <el-button
            type="primary"
            size="small"
            @click="addFictitiousRoute()"
            :disabled="addDis"
            >新增</el-button
          >
          <div style="margin-top: 10px">
            路由策略
            <el-tooltip placement="top-start" effect="light">
              <i class="el-icon-question"></i>
              <div slot="content">
                <div>轮询：按照给定的顺序，依次尝试线路</div>
                <div>
                  随机：按照线路实际可用并发进行加权随机，线路可用并发越多，选中该线路概率越大
                </div>
              </div>
            </el-tooltip>
            ：<i style="width: 5px; display: inline-block"></i>
            <el-radio v-model="modifyForm.concurrentType" label="WITH_ORDER"
              >轮询</el-radio
            >
            <el-radio v-model="modifyForm.concurrentType" label="RANDOM"
              >随机</el-radio
            >
          </div>
          <el-table
            :data="fictitiousTableData"
            stripe
            style="width: 100%"
            border
          >
            <el-table-column prop label="区域" width="260" align="center">
              <template slot-scope="scope">
                <el-cascader
                  v-model="scope.row.distributeValue"
                  placeholder="可以试试搜索"
                  :options="options"
                  :props="props"
                  collapse-tags
                  filterable
                  :show-all-levels="false"
                  :disabled="scope.row.defaultRoute"
                  size="small"
                  clearable
                >
                </el-cascader>
              </template>
            </el-table-column>
            <el-table-column prop label="排除区域" width="100" align="center">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.invertAreaSelection"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop label="运营商" width="170" align="center">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.operatorsList"
                  placeholder="请选择运营商"
                  size="small"
                  multiple
                  collapse-tags
                  :disabled="scope.row.defaultRoute"
                >
                  <el-option
                    v-for="item in operatorsList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop
              label="线路名称"
              width="100"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.native.prevent="
                    routeClick(scope.$index, scope.row, '单选')
                  "
                  class="ellipsis"
                >
                  {{ scope.row.routeName }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop label="拨打次数" width="170" align="center">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.callTimesList"
                  placeholder="请选择拨打次数"
                  size="small"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in timesList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop
              label="备用线路"
              width="100"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.native.prevent="
                    routeClick(scope.$index, scope.row, '多选')
                  "
                  class="ellipsis"
                >
                  {{
                    scope.row.spareRouteName
                      ? scope.row.spareRouteName
                      : "请选择线路"
                  }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop
              label="默认"
              width="60"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.defaultRoute"
                  @change="defaultRouteClick(scope.$index, $event)"
                  :disabled="modifyForm.concurrentType === 'RANDOM'"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop width="110" align="center">
              <template slot="header">
                <span>最大并发数</span>
                <el-tooltip
                  class="item"
                  effect="light"
                  content="-1表示没有限制"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.maxConcurrentCount"
                  size="small"
                  :disabled="
                    scope.row.defaultRoute ||
                    modifyForm.concurrentType === 'RANDOM' ||
                    scope.row.maxConcurrentCountDis
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop label="顺序" align="center">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.order"
                  size="small"
                  :disabled="modifyForm.concurrentType === 'RANDOM'"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop
              label="操作"
              width="120"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.native.prevent="
                    setCost(scope.$index, fictitiousTableData, scope.row.id)
                  "
                  :disabled="scope.row.setCostDisabled"
                  >费用设置</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click.native.prevent="
                    routeDelete(scope.$index, fictitiousTableData, scope.row.id)
                  "
                  style="color: #ff0000b0"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <span
            >选择标签：<i style="width: 20px; display: inline-block"></i
          ></span>
          <el-select
            v-model="routeTag"
            multiple
            placeholder="请选择标签"
            clearable
            style="width: 250px"
          >
            <el-option
              v-for="item in routeTagList"
              :key="item.id"
              :value="item.tag"
              :label="item.tag"
            ></el-option>
          </el-select>
          <el-button
            type="text"
            style="margin-left: 10px"
            @click="addRouteTag"
            v-if="roleMenu === 'SUPER_MANAGER'"
            >标签管理</el-button
          >
        </div>
        <div>
          <span class="note-class"
            >备注：<i
              style="width: 53px; display: inline-block; clear: both"
            ></i
          ></span>
          <el-input
            v-model="modifyForm.note"
            type="textarea"
            :rows="5"
            placeholder="请输入备注"
          ></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="modifyRoute_submit()"
          >提 交</el-button
        >
        <el-button @click="modifyRoute_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 修改线路选择 -->
    <el-dialog
      title="线路选择"
      :visible.sync="lineSelectionShow"
      class="el-virtualRoute"
      width="400px"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">线路选择</span>
      </template>
      <el-form>
        <el-form-item
          label="线路选择："
          :label-width="formWidth"
          v-if="routeClickType == '多选'"
        >
          <el-cascader
            v-if="spareRouteRefresh"
            v-model="spareRouteInfo"
            :options="virtualRouteListCopy"
            placeholder="请选择线路"
            :props="spareRouteProps"
            ref="spareRoute"
            :show-all-levels="false"
            collapse-tags
          ></el-cascader>
        </el-form-item>
        <el-form-item label="线路选择：" :label-width="formWidth" v-else>
          <el-cascader
            v-model="routeNameId"
            :options="virtualRouteList"
            placeholder="请选择线路"
            :props="routeProps"
            ref="route"
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="virtualRoute_submit()"
          >确 定</el-button
        >
        <el-button @click="virtualRoute_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 选择编码 -->
    <el-dialog
      title="选择编码"
      :visible.sync="codeChangeShow"
      class="el-virtualRoute"
      width="400px"
      @close="codedClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">选择编码</span>
      </template>
      <div>
        <el-checkbox-group v-model="codedCheckList">
          <el-checkbox label="G729"></el-checkbox>
          <el-checkbox label="PCMU"></el-checkbox>
          <el-checkbox label="PCMA"></el-checkbox>
          <el-checkbox label="G722"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="code_submit()">确 定</el-button>
        <el-button @click="code_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 费用设置 -->
    <el-dialog
      title="费用设置 ( 元 )"
      :visible.sync="setCostChangeShow"
      class="el-virtualRoute"
      width="400px"
      @close="setCostClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">费用设置 ( 元 ) </span>
      </template>
      <el-form label-position="rigth">
        <el-form-item
          :label="item.userLoginName"
          label-width="150px"
          v-for="(item, index) in setCostList"
          :key="index"
        >
          <el-input
            v-model="setCostInput[item.userLoginName]"
            placeholder="请输入费用"
            v-on:input="feeCostInput(item.userLoginName)"
            style="width: 120px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="setCost_submit()">确 定</el-button>
        <el-button @click="setCost_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 测试 -->
    <el-dialog
      title="测试"
      :visible.sync="testNumberShow"
      class="el-addRoute"
      width="500px"
      @close="testClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title"
          >【{{ testRouteName }}】线路测试</span
        >
      </template>
      <el-form :label-position="labelLeft">
        <el-form-item label="号码：" label-width="70px">
          <autocomplete-mobile
            v-if="testNumberShow"
            :number="testNumber"
            name="testModile"
            @change="testNumberChange"
            style="width: 300px"
          ></autocomplete-mobile>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="test_submit()">确 认</el-button>
        <el-button @click="testNumberShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 标签管理 -->
    <el-dialog
      title="标签管理"
      :visible.sync="addRouteTagShow"
      class="el-addRoute"
      width="400px"
      @close="tagClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">标签管理</span>
      </template>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        circle
        @click="addTag"
        style="margin: 0 0 10px 16px"
      ></el-button>
      <el-tree
        :data="tagTreeList"
        :props="defaultProps"
        node-key="id"
        ref="menuTree"
        :expand-on-click-node="false"
        draggable
        :allow-drop="allowDropTag"
        @node-drop="handleDropTag"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button
              style="font-size: 12px; color: #ff0000b0"
              type="text"
              @click="() => remove(node, data)"
              >删除</el-button
            >
          </span>
        </span>
      </el-tree>
      <div style="padding: 10px 0"></div>
    </el-dialog>
    <!-- 新增标签 -->
    <el-dialog
      :visible.sync="addNewTagShow"
      custom-class="el-addTeam"
      width="400px"
      @close="addNewTagClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">新增标签</span>
      </template>
      <el-form :model="addTag_form" label-position="left">
        <el-form-item label="标签名称：" label-width="90px">
          <el-input
            v-model="addTag_form.tag"
            placeholder="请输入标签名称"
          ></el-input>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addNewTag_submit()">确定</el-button>
          <el-button @click="addNewTagShow = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 测试呼叫中 -->
    <el-dialog
      :visible.sync="inCallShow"
      width="300px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="download-tips"
    >
      <div class="download-div">
        <i class="el-icon-loading loadingClass"></i>
        <span class="download-text">正在呼叫中...</span>
      </div>
    </el-dialog>
    <!-- 告警设置 -->
    <el-dialog
      :visible.sync="routeUserRelationShow"
      width="450px"
      @close="routeUserRelationClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">告警设置</span>
      </template>
      <el-form label-position="left">
        <el-form-item label="用户选择：" label-width="90px">
          <el-select
            v-model="userName"
            multiple
            placeholder="请选择用户"
            filterable
            remote
            :remote-method="userNameMethod"
            @focus="userNameFocus"
            style="width: 300px"
          >
            <el-option
              v-for="item in userNameList"
              :key="item.id"
              :label="item.userName"
              :value="item.loginName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div style="color: #e6a23c; margin-bottom: 20px">
          <i class="el-icon-warning"></i>
          线路出现异常将通知指定用户
        </div>
        <div class="button-center">
          <el-button type="primary" @click="routeUserRelation_submit()"
            >确定</el-button
          >
          <el-button @click="routeUserRelationShow = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import autocompleteMobile from "../pages/autocompleteMobile.vue";
export default {
  props: ["roleMenu", "powers"],
  components: {
    autocompleteMobile,
  },
  data() {
    return {
      routeUserRelationShow: false,
      userNameList: [],
      userName: [],
      radioConcurrent: "unlimited",
      addNewTagShow: false,
      addTag_form: {
        tag: "",
      },
      routeTags: [],
      tagTreeList: [],
      defaultProps: {
        children: "children",
        label: "tag",
      },
      addRouteTagShow: false,
      routeProps: {
        multiple: false,
        emitPath: false,
      },
      spareRouteProps: {
        multiple: true,
        emitPath: false,
      },
      virtualRouteListCopy: [],
      routeTag: [],
      routeTagList: [],
      providerName: "",
      inCallShow: false,
      oldRouteId: {},
      setCostList: [],
      setCostForm: {},
      setCostFormIndex: null,
      setCostInput: {},
      setCostChangeShow: false,
      codedCheckList: [],
      codeChangeShow: false,
      returnStateShow: false,
      returnStateList: [
        {
          label: "NO_ROUTE_DESTINATION",
          value: "NO_ROUTE_DESTINATION",
        },
      ],
      lineName: "",
      projectStatus: "true",
      projectStatusList: [
        {
          status: "true",
          label: "可用",
        },
        {
          status: "false",
          label: "不可用",
        },
      ],
      loading: false,
      props: {
        multiple: true,
        emitPath: false,
        value: "code",
        label: "name",
        checkStrictly: true,
      },
      options: [],
      modifyRoute: false,
      statusType: {},
      routeId: "",
      deletedShow: false,
      tableData: null,
      currentPage: null,
      total: null,
      addRoute: false,
      catched: false,
      labelLeft: "left",
      routeRegistType: [
        {
          value: "IP",
          label: "ip注册",
        },
        {
          value: "GATEWAY",
          label: "网关注册",
          disabled: true,
        },
      ],
      modifyForm: {
        city: "",
        name: "",
        provider: "",
        prefix: "",
        displayNumber: "",
        fee: "",
        note: "",
        blindAreas: [],
        operators: [],
        timeControlEnabled: false,
        startTimeAtMorning: "",
        endTimeAtMorning: "",
        startTimeAtAfternoon: "",
        endTimeAtAfternoon: "",
        coded: "",
        ip: "",
        port: "",
        maxConcurrency: "",
        concurrentType: "WITH_ORDER",
      },
      form: {
        returnState: "NO_ROUTE_DESTINATION",
        name: "",
        provider: "",
        ip: "",
        port: "",
        prefix: "",
        displayNumber: "",
        city: "",
        registType: "IP",
        login_name: "",
        passwd: "",
        note: "",
        blindAreas: [],
        operators: [],
        timeControlEnabled: false,
        startTimeAtMorning: "",
        endTimeAtMorning: "",
        startTimeAtAfternoon: "",
        endTimeAtAfternoon: "",
        authentic: "SINGLE_ROUTE",
        routeTag: [],
        maxConcurrency: -1,
        fee: "",
      },
      formWidth: "100px",
      testNumber: "",
      testNumberShow: false,
      testRouteName: "",
      fictitiousShow: true,
      fictitiousList: [
        {
          label: "真实线路",
          value: "SINGLE_ROUTE",
        },
        {
          label: "组合线路",
          value: "COMBINATION_ROUTE",
        },
        {
          label: "禁呼线路",
          value: "DISABLE_ROUTE",
        },
      ],
      fictitiousTableData: [],
      isFictitious: true,
      isDisabledRoute: true,
      distributeTypeList: [
        {
          label: "区域",
          value: "AREA",
        },
      ],
      operatorsList: [
        {
          label: "中国移动",
          value: "中国移动",
        },
        {
          label: "中国联通",
          value: "中国联通",
        },
        {
          label: "中国电信",
          value: "中国电信",
        },
        {
          label: "其它",
          value: "其它",
        },
      ],
      lineSelectionShow: false,
      routeNameId: "",
      spareRouteInfo: [],
      virtualRouteList: [],
      virtualRouteId: "",
      deleteList: [],
      tableIndex: "",
      addDis: false,
      pageSize: 10,
      defaultRouteFee: {},
      routeClickType: "单选",
      spareRouteRefresh: false,
      timesList: [
        { label: "首拨", value: "0" },
        { label: "重拨1", value: "1" },
        { label: "重拨2", value: "2" },
      ],
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-线路管理";
    this.inquire(1);
    this.getProvinceAndCity();
    this.getRouteTagList();
  },
  methods: {
    // 搜索用户
    userNameMethod(val) {
      this.getUserList(val);
    },
    // 用户选择获取焦点
    userNameFocus() {
      this.getUserList();
    },
    // 查询用户列表
    getUserList(userName) {
      let params = {
        pageNo: 1,
        pageSize: 100,
        userName: userName,
        enabled: true,
      };
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result === 200) {
          let list = res.data.list;
          this.userNameList = list;
        }
      });
    },
    // 查询线路告警用户
    getRouteUserRelation(id) {
      this.$http("/routeUserRelation/list", null, "get", null, {
        routeId: id,
      }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.userName.push(item.userLoginName);
          });
        }
      });
    },
    // 点击告警设置
    modifyRouteUserRelation(val) {
      this.routeUserRelationShow = true;
      this.routeId = val.id;
      this.getUserList();
      this.getRouteUserRelation(val.id);
    },
    // 确定告警设置
    routeUserRelation_submit() {
      let data = {
        routeId: this.routeId,
        userLoginName: this.userName,
      };
      this.$service("/routeUserRelation/update", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("设置成功");
          this.routeUserRelationShow = false;
        }
      });
    },
    // 关闭告警设置
    routeUserRelationClose() {
      this.userName = [];
    },
    // 点击标签管理
    addRouteTag() {
      this.addRouteTagShow = true;
      this.getRouteTagList();
    },
    // 关闭关标签管理
    tagClose() {
      this.tagTreeList = [];
    },
    // 标签拖拽时判定目标节点能否被放置
    allowDropTag(draggingNode, dropNode, type) {
      if (type === "inner") {
        return false;
      } else {
        return true;
      }
    },
    // 标签拖拽成功时触发的事件
    handleDropTag(draggingNode, dropNode, dropType, ev) {
      let tagTreeList = [];
      this.tagTreeList.forEach((item) => {
        tagTreeList.push(item.tag);
      });
      let data = {
        tagList: tagTreeList,
      };
      this.$service("/route/changeRouteTagOrder", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("修改成功");
          this.getRouteTagList();
        }
      });
    },
    // 删除标签
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      // children.splice(index, 1);
      let tagTreeList = [];
      let newData = {
        tag: children[index].tag,
      };
      this.$service("/route/deleteRouteTag", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.getRouteTagList();
        }
      });
    },
    // 点击新增标签
    addTag() {
      this.addNewTagShow = true;
    },
    // 确定新增标签
    addNewTag_submit() {
      let tagTreeList = [];
      this.tagTreeList.forEach((item) => {
        tagTreeList.push(item.tag);
      });
      tagTreeList.push(this.addTag_form.tag);
      let data = {
        tag: this.addTag_form.tag,
        order: tagTreeList.length - 1,
      };
      this.$service("/route/addRouteTag", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.addNewTagShow = false;
          this.getRouteTagList();
        }
      });
    },
    // 关闭新增标签
    addNewTagClose() {
      this.addTag_form = {
        tag: "",
      };
    },
    // 点击编码修改
    codeChange() {
      this.codeChangeShow = true;
    },
    // 确定编码修改
    code_submit() {
      this.modifyForm.coded = this.codedCheckList.join(",");
      this.code_cancel();
    },
    // 取消编码修改
    code_cancel() {
      this.codeChangeShow = false;
    },
    // 关闭编码修改
    codedClose() {},
    getProvinceAndCity() {
      //查询区域
      this.$http("/getProvinceAndCity", null).then((res) => {
        if (res.result == 200) {
          this.options = [];
          let data = res.data;
          for (let i in data) {
            let a = {};
            a.name = i;
            a.code = i;
            a.children = [];
            if (data[i] && data[i].length > 0) {
              for (let j of data[i]) {
                let b = {};
                b.name = i + " " + j;
                b.code = i + ":" + j;
                a.children.push(b);
              }
            }
            this.options.push(a);
          }
        }
      });
    },
    testShow(val) {
      //点击测试
      this.routeId = val.id;
      this.testRouteName = val.name;
      this.testNumberShow = true;
    },
    test_submit() {
      //测试确认
      // this.inCallShow = true;
      let data = {
        phone: this.testNumber,
        routeId: this.routeId,
      };
      if (this.testNumber.length === 8 || this.testNumber.length === 11) {
        this.$common.numberSetCookie(this.testNumber, "testModile");
        this.$http("/sip/call/echo", data)
          .then((res) => {
            this.inCallShow = false;
            if (res.result == 200) {
              this.testNumberShow = false;
              this.$message({ message: "呼叫成功", type: "success" });
            }
          })
          .catch((err) => {
            this.inCallShow = false;
          });
      } else {
        this.$message.warning("手机号码有误，请重填");
      }
      // let checkMobile = this.$common.checkMobile(this.testNumber);
      // if (!checkMobile) {
      //   return
      // }
    },
    // 测试号码change
    testNumberChange(val) {
      this.testNumber = val;
    },
    testClose() {
      //测试关闭
      this.testNumber = "";
      this.testRouteName = "";
    },
    currentPage_data(index) {
      //当前页
      this.currentPage = index;
      this.inquire(index);
    },
    sizeChange(index) {
      //显示条数--分页
      this.currentPage = 1;
      this.pageSize = index;
      this.inquire(1);
    },
    inquire(index) {
      //查询列表
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: index || this.currentPage,
        name: this.lineName,
        enabled: this.projectStatus,
        provider: this.providerName.trim(),
        tags: this.routeTags.join(","),
      };
      this.inquireRoute(params);
    },
    inquireRoute(params) {
      //查询线路管理列表
      this.loading = true;
      this.$http("/route/list", null, "get", null, params).then((res) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data.list;
          this.total = res.data.count;
          for (let i = 0; i < list.length; i++) {
            if (list[i].enabled) {
              this.statusType["type_" + list[i].id] = "success";
            } else {
              this.statusType["type_" + list[i].id] = "info";
            }
          }
          list.map((item) => {
            switch (item.type) {
              case "SINGLE_ROUTE":
                item.routeType = "真实线路";
                break;
              case "COMBINATION_ROUTE":
                item.routeType = "组合线路";
                break;
              case "DISABLE_ROUTE":
                item.routeType = "禁呼线路";
                break;
            }
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.feeLabel = item.fee + " 元";
            item.routeStatus = "禁用";
            if (item.enabled) {
              item.routeStatus = "可用";
            }
            item.maxConcurrencyLabel = item.maxConcurrency;
            if (item.maxConcurrency === -1) {
              item.maxConcurrencyLabel = "无限制";
            }
            // switch (item.status) {
            //   case "ENABLED":
            //     item.routeStatus = "可用";
            //     break;
            //   case "DISABLED":
            //     item.routeStatus = "禁用";
            //     break;
            // }
          });
          this.tableData = list;
        }
      });
    },
    addRoute_submit() {
      //新增提交
      if (this.form.authentic === "SINGLE_ROUTE") {
        //真实线路
        if (
          this.form.name != "" &&
          this.form.ip != "" &&
          this.form.port != "" &&
          this.form.registType != ""
        ) {
          let data = {};
          if (this.form.registType == "GATEWAY") {
            //真实线路
            data = {
              name: this.form.name,
              provider: this.form.provider,
              ip: this.form.ip,
              port: this.form.port,
              prefix: this.form.prefix,
              displayNumber: this.form.displayNumber,
              city: this.form.city,
              registType: this.form.registType,
              login_name: this.form.login_name,
              passwd: this.form.passwd,
              blindAreas: this.form.blindAreas.join(","),
              operators: this.form.operators.join(","),
              timeControlEnabled: this.form.timeControlEnabled,
              startTimeAtMorning: this.form.startTimeAtMorning,
              endTimeAtMorning: this.form.endTimeAtMorning,
              startTimeAtAfternoon: this.form.startTimeAtAfternoon,
              endTimeAtAfternoon: this.form.endTimeAtAfternoon,
              remark: this.form.note,
              type: this.form.authentic,
              tagList: this.form.routeTag,
              maxConcurrency: Number(this.form.maxConcurrency),
              fee: (this.form.fee * 1000).toFixed(0),
            };
          } else if (this.form.registType == "IP") {
            data = {
              name: this.form.name,
              provider: this.form.provider,
              ip: this.form.ip,
              port: this.form.port,
              prefix: this.form.prefix,
              displayNumber: this.form.displayNumber,
              city: this.form.city,
              registType: this.form.registType,
              remark: this.form.note,
              type: this.form.authentic,
              tagList: this.form.routeTag,
              maxConcurrency: Number(this.form.maxConcurrency),
              fee: (this.form.fee * 1000).toFixed(0),
              blindAreas: this.form.blindAreas.join(","),
              operators: this.form.operators.join(","),
              timeControlEnabled: this.form.timeControlEnabled,
              startTimeAtMorning: this.form.startTimeAtMorning,
              endTimeAtMorning: this.form.endTimeAtMorning,
              startTimeAtAfternoon: this.form.startTimeAtAfternoon,
              endTimeAtAfternoon: this.form.endTimeAtAfternoon,
            };
          }
          this.$service("/route/add", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "新增线路成功", type: "success" });
              this.addRoute_cancel();
              this.inquire(1);
            }
          });
        } else {
          this.$message({ message: "信息填写不完整！", type: "warning" });
        }
      } else if (this.form.authentic === "COMBINATION_ROUTE") {
        //虚拟线路
        if (this.form.name != "") {
          let data = {
            name: this.form.name,
            remark: this.form.note,
            type: this.form.authentic,
            tagList: this.form.routeTag,
            fee: (this.form.fee * 1000).toFixed(0),
          };
          this.$service("/route/add", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "新增线路成功", type: "success" });
              this.addRoute_cancel();
              this.inquire(1);
            }
          });
        } else {
          this.$message({ message: "信息填写不完整！", type: "warning" });
        }
      } else if (this.form.authentic === "DISABLE_ROUTE") {
        //禁用线路
        if (this.form.name != "") {
          let data = {
            name: this.form.name,
            displayNumber: this.form.returnState,
            remark: this.form.note,
            type: this.form.authentic,
            tagList: this.form.routeTag,
            fee: (this.form.fee * 1000).toFixed(0),
          };
          this.$service("/route/add", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "新增线路成功", type: "success" });
              this.addRoute_cancel();
              this.inquire(1);
            }
          });
        } else {
          this.$message({ message: "信息填写不完整！", type: "warning" });
        }
      }
    },
    addClick() {
      //点击新增
      this.addRoute = true;
      this.getRouteTagList();
    },
    addRoute_cancel() {
      //新增取消
      this.addRoute = false;
    },
    addRouteClose() {
      //新增窗口关闭
      this.form = {
        returnState: "NO_ROUTE_DESTINATION",
        name: "",
        provider: "",
        ip: "",
        port: "",
        prefix: "",
        displayNumber: "",
        city: "",
        registType: "IP",
        login_name: "",
        passwd: "",
        blindAreas: [],
        operators: [],
        timeControlEnabled: false,
        startTimeAtMorning: "",
        endTimeAtMorning: "",
        startTimeAtAfternoon: "",
        endTimeAtAfternoon: "",
        note: "",
        authentic: "SINGLE_ROUTE",
        routeTag: [],
        maxConcurrency: -1,
        fee: "",
      };
      this.catched = false;
      this.fictitiousShow = true;
      this.returnStateShow = false;
    },
    fictitiousChange(val) {
      //线路类型选择
      switch (val) {
        case "SINGLE_ROUTE":
          this.fictitiousShow = true;
          this.returnStateShow = false;
          break;
        case "COMBINATION_ROUTE":
          this.fictitiousShow = false;
          this.returnStateShow = false;
          this.catched = false;
          break;
        case "DISABLE_ROUTE":
          this.fictitiousShow = false;
          this.returnStateShow = true;
          break;
      }
    },
    checked(val) {
      // ip注册、网关注册
      if (val == "IP") {
        this.catched = false;
      } else {
        this.catched = true;
      }
    },
    returnStateChange(val) {
      // 选择返回状态
    },
    routeGroupList(routeId) {
      //获取修改线路列表
      let params = {
        routeId: routeId,
      };
      this.$http("/route/routeGroupList", null, "get", null, params).then(
        (res) => {
          if (res.result == 200) {
            this.$http("/route/list", null, "get", null, {
              pageSize: 1000,
              pageNo: 1,
              virtual: false,
              enabled: true,
            }).then((resData) => {
              if (resData.result === 200) {
                let routeList = resData.data.list;
                let list = res.data;
                if (list && list.length > 0) {
                  list.map((item) => {
                    let a = item.distributeValue.split(",");
                    if (item.callTimes) {
                      item.callTimesList = item.callTimes.split(",");
                    } else {
                      item.callTimesList = [];
                    }
                    item.distributeValue = a;
                    item.maxConcurrentCountDis = false;
                    item.operatorsList = [];
                    if (item.operatorValue) {
                      item.operatorsList = item.operatorValue.split(",");
                    }
                    // item.setCostDisabled = true;
                    let key = "setFee" + item.distributeRoute;
                    sessionStorage.removeItem(key); // 每次请求修改线路列表清除缓存
                    routeList.forEach((menu) => {
                      if (
                        item.distributeRoute === menu.id &&
                        menu.type === "DISABLE_ROUTE"
                      ) {
                        item.maxConcurrentCountDis = true;
                      }
                    });
                  });
                }
                this.fictitiousTableData = list;
                this.$nextTick(() => {
                  this.addDis = false;
                });
              }
            });
          }
        }
      );
    },
    routeClick(index, val, routeClickType) {
      this.routeClickType = routeClickType; //多选还是单选
      this.spareRouteRefresh = false;
      this.lineSelectionShow = true;
      this.$nextTick(() => {
        if (routeClickType == "多选") {
          this.spareRouteInfo.splice(0);
          if (val.spareRouteInfo) {
            this.spareRouteInfo.push(
              ...val.spareRouteInfo.split(",").map((i) => i * 1)
            );
          }
        } else {
          this.routeNameId = val.distributeRoute;
          this.oldRouteId[index] = val.distributeRoute; // 记录修改的线路
        }
        this.tableIndex = index; // 表格行
        this.spareRouteRefresh = true;
      });
    },
    virtualRoute_submit() {
      //修改--点击线路确定
      let isDisabledRoute = false;
      if (this.tableIndex || this.tableIndex == 0) {
        if (this.routeClickType == "多选") {
          this.fictitiousTableData[this.tableIndex].spareRouteInfo =
            this.spareRouteInfo.join(",");
          this.$http("/route/list", null, "get", null, {
            pageSize: 1000,
            pageNo: 1,
            virtual: false,
            enabled: true,
          }).then((resData) => {
            if (resData.result === 200) {
              let routeList = resData.data.list;
              let arr = [];
              routeList.forEach((n) => {
                this.spareRouteInfo.forEach((item) => {
                  if (n.id === item) {
                    arr.push(n.name);
                  }
                });
              });
              this.fictitiousTableData[this.tableIndex].spareRouteName =
                arr.join(",");
            }
          });
        } else {
          // 线路修改
          this.fictitiousTableData[
            this.tableIndex
          ].maxConcurrentCountDis = false;
          this.$http("/route/list", null, "get", null, {
            pageSize: 1000,
            pageNo: 1,
            virtual: false,
            enabled: true,
          }).then((resData) => {
            if (resData.result === 200) {
              let routeList = resData.data.list;
              routeList.forEach((n) => {
                if (n.id === this.routeNameId && n.type === "DISABLE_ROUTE") {
                  this.fictitiousTableData[
                    this.tableIndex
                  ].maxConcurrentCountDis = true;
                  this.fictitiousTableData[this.tableIndex].maxConcurrentCount =
                    -1;
                }
              });
            }
          });
          this.fictitiousTableData[this.tableIndex].distributeRoute =
            this.routeNameId;
          this.virtualRouteList.map((menu) => {
            if (menu.children) {
              menu.children.map((item) => {
                if (
                  this.fictitiousTableData[this.tableIndex].distributeRoute ==
                  item.id
                ) {
                  this.fictitiousTableData[this.tableIndex].routeName =
                    item.name;
                  if (item.type === "DISABLE_ROUTE") {
                    isDisabledRoute = true;
                  }
                }
              });
            }
          });
        }
      }
      //  新增线路--费用设置默认值
      if (!this.fictitiousTableData[this.tableIndex].id) {
        this.setCostList = this.defaultRouteFee;
        let cache = {};
        this.defaultRouteFee.forEach((item) => {
          cache[item.userLoginName] = (item.fee / 1000).toString();
          // 是禁呼线路
          if (isDisabledRoute) {
            cache[item.userLoginName] = "0";
          }
        });
        this.fictitiousTableData[this.tableIndex].setCostInput = cache;
        let key =
          "setFee" + this.fictitiousTableData[this.tableIndex].distributeRoute;
        sessionStorage.setItem(key, JSON.stringify(cache)); // 缓存修改的费用
      }
      this.virtualRoute_cancel();
      if (
        this.oldRouteId[this.tableIndex] ===
        this.fictitiousTableData[this.tableIndex].distributeRoute
      ) {
        //判断线路是否改变
        return;
      }
      if (this.fictitiousTableData[this.tableIndex].id) {
        // if(this.oldRouteId[this.tableIndex]){
        //   this.$message({message:"需要在费用设置中重新设置费用",type:"warning"});
        // }
        this.oldRouteId[this.tableIndex] =
          this.fictitiousTableData[this.tableIndex].distributeRoute; // 记录修改的线路
        let cache = {};
        this.defaultRouteFee.forEach((item) => {
          cache[item.userLoginName] = (item.fee / 1000).toString();
          // 是禁呼线路
          if (isDisabledRoute) {
            cache[item.userLoginName] = "0";
          }
        });
        this.fictitiousTableData[this.tableIndex].setCostInput = cache;
        let key =
          "setFee" + this.fictitiousTableData[this.tableIndex].distributeRoute;
        sessionStorage.setItem(key, JSON.stringify(cache)); // 缓存修改的费用
        // this.fictitiousTableData[this.tableIndex].setCostInput = {};//清空费用设置
      }
    },
    virtualRoute_cancel() {
      //修改--点击线路取消
      this.lineSelectionShow = false;
    },
    virtualRouteClose() {
      //修改--点击线路关闭
      // this.routeTagId = [];
    },
    addFictitiousRoute() {
      //修改--新增虚拟线路
      let obj = {
        distributeType: "AREA",
        distributeValue: [],
        order: "",
        defaultRoute: false,
        invertAreaSelection: false,
        distributeRoute: "",
        maxConcurrentCount: -1,
        routeName: "请选择线路",
        spareRouteName: "请选择线路",
        spareRouteInfo: "",
      };
      this.fictitiousTableData.push(obj);
    },
    routeDelete(index, rows, id) {
      //修改--虚拟线路删除
      if (id) {
        this.deleteList.push(id);
      }
      rows.splice(index, 1);
    },
    setCost(index, rows, id) {
      //修改--点击虚拟线路设置费用
      this.tableIndex = index;
      if (!id) {
        if (this.fictitiousTableData[this.tableIndex].setCostInput) {
          this.setCostInput =
            this.fictitiousTableData[this.tableIndex].setCostInput;
        }
      }
      if (!rows[index].distributeRoute) {
        this.$message({ message: "请先选择线路", type: "warning" });
        return;
      }
      this.setCostChangeShow = true;
      if (id) {
        this.getFindRouteUser({
          routeId: rows[index].distributeRoute,
          parentRouteId: this.routeId,
        });
      }
    },
    setCost_submit() {
      //修改-虚拟线路设置费用--确定
      this.fictitiousTableData[this.tableIndex].setCostInput =
        this.setCostInput;
      let cache = {};
      cache = this.setCostInput;
      let key =
        "setFee" + this.fictitiousTableData[this.tableIndex].distributeRoute;
      sessionStorage.setItem(key, JSON.stringify(cache)); // 缓存修改的费用
      this.setCost_cancel();
    },
    setCost_cancel() {
      //修改-虚拟线路设置费用--取消
      this.setCostChangeShow = false;
    },
    setCostClose() {
      //修改-虚拟线路设置费用--关闭
      this.setCostInput = {};
    },
    getFindRouteUser(data) {
      //获取费用设置信息
      this.setCostList = [];
      this.$http("/route/findRouteUser", null, "get", null, data).then(
        (res) => {
          if (res.result == 200) {
            let list = res.data.list;
            let isSetFee = true; // 是否按照费用设置信息填充
            let cache = sessionStorage.getItem("setFee" + data.routeId); // 读取修改费用
            let cacheData = JSON.parse(cache);
            if (cacheData) {
              isSetFee = false;
              for (let i in cacheData) {
                this.setCostInput[i] = cacheData[i];
              }
            }
            if (isSetFee) {
              this.setCostInput = {};
              list.forEach((item) => {
                this.setCostInput[item.userLoginName] = (
                  item.fee / 1000
                ).toString();
              });
            }
            this.setCostList = list;
          }
        }
      );
    },
    // 费用设置内容修改
    feeCostInput(key) {
      let text = this.setCostInput[key];
      this.setCostInput[key] = text;
      this.$forceUpdate();
    },
    // 查询线路标签
    getRouteTagList() {
      this.virtualRouteList.splice(0);
      this.virtualRouteListCopy.splice(0);
      this.$http("/route/routeTagList", null, "get").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.routeTagList = this.$common.deepClone(list);
          this.tagTreeList = this.routeTagList;
          list.map((item) => {
            item.value = item.tag;
            item.label = item.tag;
            item.name = item.tag;
            item.children = [];
          });
          this.virtualRouteList.push(...list);
          this.virtualRouteListCopy.push(...list);
          let obj = {
            name: "",
            value: "全部",
            label: "全部",
            children: [],
          };
          this.virtualRouteList.unshift(obj);
          this.virtualRouteListCopy.unshift(obj);
          this.virtualRouteList.forEach((item) => {
            this.getChildrenList(item.name).then((res) => {
              this.$set(item, "children", res);
            });
          });
          this.virtualRouteListCopy.forEach((item) => {
            this.getChildrenList(item.name).then((res) => {
              this.$set(item, "children", res);
            });
          });
        }
      });
    },
    getChildrenList(tag) {
      let params = {
        pageSize: 1000,
        pageNo: 1,
        virtual: false,
        enabled: true,
        tag,
      };
      let list = [];
      return this.$http("/route/list", null, "get", null, params).then(
        (res) => {
          if (res.result == 200) {
            list = res.data.list;
            list.forEach((item) => {
              item.label = item.name;
              item.value = item.id;
            });
            return list;
          }
        }
      );
    },
    modifyShow(val) {
      //点击修改
      this.modifyRoute = true;
      this.addDis = true;
      this.isFictitious = false;
      if (val.type === "COMBINATION_ROUTE") {
        this.isFictitious = true;
        this.$http("/route/findDefaultRouteFee", null, "get", null, {
          parentRouteId: val.id,
        }).then((res) => {
          if (res.result === 200) {
            this.defaultRouteFee = res.data;
          }
        });
      }
      this.routeId = val.id;
      this.modifyForm.name = val.name;
      this.modifyForm.city = val.city;
      this.modifyForm.provider = val.provider;
      this.modifyForm.prefix = val.prefix;
      this.modifyForm.displayNumber = val.displayNumber;
      this.modifyForm.fee = val.fee;
      if (val.maxConcurrency === -1) {
        this.radioConcurrent = "unlimited";
      } else {
        this.radioConcurrent = "limit";
      }
      this.modifyForm.maxConcurrency = val.maxConcurrency;
      this.modifyForm.note = val.remark;
      this.modifyForm.coded = val.codec;
      this.modifyForm.ip = val.ip;
      this.modifyForm.port = val.port;
      this.modifyForm.concurrentType = val.concurrentType;
      if (val.blindAreas) {
        this.modifyForm.blindAreas = val.blindAreas.split(",");
      }
      if (val.operators) {
        this.modifyForm.operators = val.operators.split(",");
      }
      this.modifyForm.timeControlEnabled = val.timeControlEnabled;
      this.modifyForm.startTimeAtMorning = val.startTimeAtMorning;
      this.modifyForm.endTimeAtMorning = val.endTimeAtMorning;
      this.modifyForm.startTimeAtAfternoon = val.startTimeAtAfternoon;
      this.modifyForm.endTimeAtAfternoon = val.endTimeAtAfternoon;
      this.routeTag = val.tagList;
      if (val.codec) {
        this.codedCheckList = val.codec.split(",");
      }
      this.isDisabledRoute = false;
      if (val.type === "DISABLE_ROUTE") {
        this.isDisabledRoute = true;
        this.radioConcurrent = "unlimited";
      }
      this.routeGroupList(val.id);
    },
    modifyRoute_submit() {
      //确定修改
      if (this.isFictitious) {
        // 虚拟线路提交
        let data = {
          routeId: this.routeId,
          routeName: this.modifyForm.name,
          remark: this.modifyForm.note,
          concurrentType: this.modifyForm.concurrentType,
        };
        let isAjax = true;
        let isdefault = []; // 默认顺序
        let orderList = []; // 排序列表
        data.deleteList = this.deleteList;
        data.itemList = []; // 虚拟线路修改参数表
        data.routeFeeItemList = []; // 路线费项目表
        data.tagList = this.routeTag; // 标签数组

        for (let c in this.fictitiousTableData) {
          let arr = this.fictitiousTableData[c].distributeValue;
          let setCostInput = this.fictitiousTableData[c].setCostInput;
          let distributeValue = "";
          let operatorValueArr = this.fictitiousTableData[c].operatorsList;
          if (setCostInput) {
            let setArr = Object.keys(setCostInput);
            if (setArr.length > 0) {
              for (let item in setCostInput) {
                let obj = {
                  // 费用设置参数
                  userLoginName: item,
                  routeId: this.fictitiousTableData[c].distributeRoute,
                  fee: setCostInput[item] * 1000,
                };
                data.routeFeeItemList.push(obj);
              }
            }
          }
          if (arr && arr.length > 0) {
            distributeValue = arr.join(",");
          }
          let obj = {
            // 每条虚拟线路修改参数
            distributeRoute: this.fictitiousTableData[c].distributeRoute,
            operatorValue: operatorValueArr.join(","),
            distributeValue: distributeValue,
            order: Number(this.fictitiousTableData[c].order),
            defaultRoute: this.fictitiousTableData[c].defaultRoute,
            invertAreaSelection:
              this.fictitiousTableData[c].invertAreaSelection,
            maxConcurrentCount: Number(
              this.fictitiousTableData[c].maxConcurrentCount
            ),
            spareRouteInfo: this.fictitiousTableData[c].spareRouteInfo,
            callTimes: this.fictitiousTableData[c].callTimesList.join(","),
          };
          if (this.fictitiousTableData[c].id) {
            obj.id = this.fictitiousTableData[c].id;
          }
          data.itemList.push(obj);
          orderList.push(this.fictitiousTableData[c].order);
          if (this.fictitiousTableData[c].defaultRoute) {
            isdefault.push(this.fictitiousTableData[c].order);
          }
          if (!this.fictitiousTableData[c].distributeRoute) {
            // 线路为空
            isAjax = false;
            break;
          }
        }
        let num = 0; // 判断默认线路是否选择、默认线路顺序是否最大、默认线路只能有一条
        let adopt = false;
        let maxOrder = orderList.sort((a, b) => b - a)[0]; // 默认线路顺序必须最大
        for (let i of isdefault) {
          num++;
          if (i >= maxOrder) {
            adopt = true;
          }
        }
        if (!isAjax) {
          this.$message({ message: "线路不能为空", type: "warning" });
          return;
        }
        if (!this.modifyForm.concurrentType) {
          this.$message({ message: "请选择路由策略类型", type: "warning" });
          return;
        }
        if (this.modifyForm.concurrentType === "WITH_ORDER") {
          // 路由策略为轮询判断默认线路
          if (num < 1) {
            this.$message({ message: "默认线路不能为空", type: "warning" });
            return;
          }
          if (num === 1 && !adopt) {
            this.$message({ message: "默认线路顺序必须最大", type: "warning" });
            return;
          }
          if (num > 1) {
            this.$message({ message: "默认线路只能有一条", type: "warning" });
            return;
          }
          if (!isdefault[0]) {
            this.$message({ message: "默认线路顺序必须最大", type: "warning" });
            return;
          }
        }
        this.$service("/route/updateGroupBatch", data).then((res) => {
          if (res.result == 200) {
            this.$message({ message: "修改成功", type: "success" });
            this.modifyRoute_cancel();
            this.inquire();
          }
        });
        return;
      }

      // 真实线路提交
      let data = {
        routeId: this.routeId,
        name: this.modifyForm.name,
        city: this.modifyForm.city,
        provider: this.modifyForm.provider,
        prefix: this.modifyForm.prefix,
        displayNumber: this.modifyForm.displayNumber,
        remark: this.modifyForm.note,
        fee: (this.modifyForm.fee * 1000).toFixed(0),
        codec: this.modifyForm.coded,
        ip: this.modifyForm.ip,
        port: this.modifyForm.port,
        tagList: this.routeTag,
        blindAreas: this.modifyForm.blindAreas.join(","),
        operators: this.modifyForm.operators.join(","),
        timeControlEnabled: this.modifyForm.timeControlEnabled,
        startTimeAtMorning: this.modifyForm.startTimeAtMorning,
        endTimeAtMorning: this.modifyForm.endTimeAtMorning,
        startTimeAtAfternoon: this.modifyForm.startTimeAtAfternoon,
        endTimeAtAfternoon: this.modifyForm.endTimeAtAfternoon,
      };
      if (this.radioConcurrent === "unlimited") {
        data.maxConcurrency = -1;
      } else {
        data.maxConcurrency = Number(this.modifyForm.maxConcurrency);
      }
      this.$service("/route/modify", data).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "修改成功", type: "success" });
          this.modifyRoute_cancel();
          this.inquire();
        }
      });
    },
    modifyRoute_cancel() {
      //取消修改
      this.modifyRoute = false;
    },
    modifyRouteClose() {
      //关闭修改弹窗
      this.modifyForm = {
        city: "",
        name: "",
        provider: "",
        prefix: "",
        displayNumber: "",
        fee: "",
        note: "",
        blindAreas: [],
        operators: [],
        timeControlEnabled: false,
        startTimeAtMorning: "",
        endTimeAtMorning: "",
        startTimeAtAfternoon: "",
        endTimeAtAfternoon: "",
        ip: "",
        port: "",
        maxConcurrency: "",
        concurrentType: "WITH_ORDER",
      };
      this.routeTag = [];
      this.fictitiousTableData = [];
      this.deleteList = [];
      this.codedCheckList = [];
      this.oldRouteId = {};
      this.radioConcurrent = "unlimited";
      this.defaultRouteFee = {};
    },
    // 默认线路点击--组合线路
    defaultRouteClick(index, val) {
      if (val) {
        this.fictitiousTableData[index].maxConcurrentCount = -1;
      }
    },
    //点击删除
    deleteShow(val) {
      this.routeId = val.id;
      this.$confirm("删除【" + val.name + "】线路, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            routeId: this.routeId,
            delete: true,
            disable: false,
          };
          this.$service("/route/modify", params, "post", null, null).then(
            (res) => {
              if (res.result == 200) {
                this.$message({ message: "删除成功", type: "success" });
                this.inquire();
              }
            }
          );
        })
        .catch(() => {});
    },
    Prohibit(val) {
      //点击禁用
      this.routeId = val.id;
      this.$confirm("禁用【" + val.name + "】线路, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            routeId: this.routeId,
            delete: false,
            disable: true,
          };
          this.$service("/route/modify", params, "post", null, null).then(
            (res) => {
              if (res.result == 200) {
                this.$message({ message: "禁用成功", type: "success" });
                this.inquire();
              }
            }
          );
        })
        .catch(() => {});
    },
    unProhibit(val) {
      //点击启用
      this.routeId = val.id;
      this.$confirm("启用【" + val.name + "】线路, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            routeId: this.routeId,
            delete: false,
            disable: false,
            enable: true,
          };
          this.$service("/route/modify", params, "post", null, null).then(
            (res) => {
              if (res.result == 200) {
                this.$message({ message: "启用成功", type: "success" });
                this.inquire();
              }
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>
<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.button-center {
  width: 165px;
  margin: 0 auto;
}
.name-class {
  display: inline-block;
  margin-right: 10px;
}
.name-class .el-input,
.name-class .el-select {
  width: 230px;
  margin-bottom: 10px;
}
.el-cascader {
  margin-bottom: 0px !important;
}
.fictitious-modify >>> .el-cascader__search-input {
  min-width: 5px;
}
.fictitious-modify .el-input {
  width: 260px;
}
.fictitious-modify .el-textarea {
  width: 80%;
  position: relative;
  left: 95px;
}
.fictitious-modify .note-class {
  display: inline-block;
  position: absolute;
}
.fictitious-modify div {
  margin-bottom: 10px;
}
.fictitious-modify .el-table {
  margin: 10px 0px;
}
.fictitious-modify .el-table .el-input,
.fictitious-modify .el-table .el-select {
  width: 100%;
  margin-bottom: 0px;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.el-virtualRoute .el-form-item .el-select {
  width: 240px;
}
.el-virtualRoute >>> .el-dialog__body {
  padding: 10px 20px;
}
.el-addRoute .el-form-item .el-select,
.el-addRoute .el-form-item .el-cascader,
.el-addRoute .el-form-item .el-input {
  width: 250px;
}
.el-addRoute .el-form-item .el-date-editor {
  width: 200px;
}
.el-addRoute >>> .el-dialog__body {
  padding: 20px 20px 0px 20px;
}
.ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
