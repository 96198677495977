<template>
	<div class="details">
		<div class="nav">
			<div class="time">
					起止时间：
					<el-date-picker class="startTime" value-format='yyyy-MM-dd HH:mm:ss' v-model="startTime" type="datetime"  placeholder="选择日期时间"> </el-date-picker>
					-
					<el-date-picker class="endTime" value-format='yyyy-MM-dd HH:mm:ss' v-model="endTime" type="datetime"  placeholder="选择日期时间"> </el-date-picker>
			</div>	
			<div class="equip">
					设备名称：
					<el-select v-model="equipId" placeholder="请选择设备">
						<el-option v-for="item in equipList" :key="item.id" :label="item.boxName" :value="item.boxNumber"></el-option>				
					</el-select>
			</div>	
			<div class="sign">
					标签列表：
					<el-select v-model="signId" placeholder="请选择标签">
						<el-option v-for="item in signList" :key="item.id" :label="item.tagName" :value="item.id"></el-option>				
					</el-select>
			</div>	
			<div class="distance">
					采集距离：
					<el-input v-model="distance" placeholder="留空表示不限制距离 (单位:米)"></el-input>
			</div>	
			<div class="guest">
					顾客类型：
					<el-checkbox v-model="newCustomer" >新顾客</el-checkbox>
					<el-checkbox v-model="oldCustomer" >老顾客</el-checkbox>
			</div>
			<div class="phone">
					手机号码：
					<el-input v-model="phone" placeholder=""></el-input>			
			</div>
			<div class="operate">
					<el-button type="primary" @click='inquire()'>查询</el-button>
					<el-button type="primary" @click="removeTags()">批量删除</el-button>
					<el-button type="primary" @click='click_addTags()'>批量添加标签</el-button>
			</div>
		</div>
		
		查询结果:
		<i style="font-style: normal;">{{total}}条</i>
		<el-table :data="tableData" stripe style="width: 100%" border @selection-change="checked" ref='multipleTable'>
			<el-table-column type="selection" width="60" class='column' align='center' ></el-table-column>
			<el-table-column type='index' prop="" label="序号" width="60" align='center'></el-table-column>
			<el-table-column prop="mac" label="mac" width="" align='center'></el-table-column>
			<el-table-column prop="brand" label="品牌" width="" align='center'></el-table-column>
			<el-table-column prop="boxNumber" label="设备ID" width="" align='center'></el-table-column>
			<el-table-column prop="gmtCreated" label="时间" width="170" align='center'></el-table-column>
			<el-table-column prop="lastActiveDistance" label="距离" width="" align='center'></el-table-column>
			<el-table-column prop="newCome" label="新老客" width="" align='center'></el-table-column>                                                          
			<el-table-column prop="tagList" label="标签" width="" align='center' >
				<template slot-scope="scope">
						<el-tag type="success" size="mini" v-for = 'item in scope.row.tagList' :key='item.id'  closable @close="handleClose(item,scope.$index)"  >{{item.tagName}}</el-tag>
				</template>
			</el-table-column>                                 
			<el-table-column prop="" label="操作" width="" align='center'>
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="removeTags(scope.row)">删除</el-button>
					<el-button type="text" size="small" @click="click_addTags(scope.row)">添加标签</el-button>
				</template>
			</el-table-column>
		</el-table>		
		<!-- 分页 -->
		<el-pagination @current-change="currentPage_data" :current-page.sync="currentPage" :page-size= '10' layout="prev, pager, next,jumper" :total="total" :pager-count='5'></el-pagination>
		<el-button type="primary" @click='clickGroups()' class='createGroup'>创建群组</el-button>				
		
		<!-- 添加标签 -->
		<el-dialog  :visible.sync="addtags"  width="500px" custom-class='el-addtags'  >
			<div slot="title" class="dialog-title" >
				<h3>添加标签</h3>
			</div>
			<el-form :model="addtags_form">
				<el-form-item label="" :label-width="formWidth">
					<b>标签名称：</b>
					<el-input v-model="addtags_form.tag" placeholder="请输入标签名称"></el-input>	
				</el-form-item>
			</el-form>							
			<div slot="footer" class="dialog-footer footer" align="center" >				
				<el-button type="primary" @click="addtags_submit()">提 交</el-button> 
				<el-button @click="addtags_cancel()">取 消</el-button>
			</div>
		</el-dialog>
		
		<!-- 创建群组 -->		
		<el-dialog :visible.sync="createGroup" custom-class='el-createGroup' width='450px'  >
			<div slot="title" class="dialog-title" >
				<h3>营销任务</h3>
			</div>	
			<el-form :model="form">
				<el-form-item label="" :label-width="formWidth">
					<b>有效数据：</b> 
					<span style="color: lightseagreen;">{{form.num}}</span> 条
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>群组名称：</b>
					<el-input v-model="form.name" autocomplete="off" placeholder='请输入客群名称'></el-input>
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>所属行业：</b>
					<el-select v-model="form.industry" placeholder="请选择所属行业">
						<el-option v-for="item in industry" :key="item" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" :label-width="formWidth">
					<b>场景名称：</b>
					<el-input v-model="form.scene" autocomplete="off" placeholder='请输入场景名称'></el-input>
				</el-form-item> 
			</el-form>		  
			<div slot="footer" class="dialog-footer footer" align="center">
				<el-button type="primary" @click='createGroup_submit()'>提 交</el-button>
				<el-button @click='createGroup_cancel()' >取 消</el-button>				
			</div>
		</el-dialog>
		
		<!-- 开始营销 -->
		<el-dialog  :visible.sync="jump" custom-class='startMarketing' width='500px' >
			<div slot="title" class="dialog-title" >
				<h3>开始营销</h3>
			</div>	
			<b class="word">群组创建成功，群组名称：<i>{{form.name}}</i>，共计<i> {{num}} </i>条。</b>		
			<div slot="footer" class="dialog-footer footer" align="center">
				<el-button type="primary" @click="jump_sms()" >导入短信系统</el-button>
				<el-button type="primary" @click="jump_phone()" v-show='hide'>导入呼叫系统</el-button>
				<el-button @click="jump_cancel()" >取 消</el-button>
			</div>
		</el-dialog>
				
	</div>
</template>

<script>
	export default {
		name:'detail',
		data(){
			return {
				msg:'明细',
				equipList:'',
				equipId:'',
				startTime:'',
				endTime:'',
				distance:'',
				signList:'',
				signId:'',
				newCustomer:false,
				oldCustomer:false,
				phone:'',
				
				currentPage:1,
				tableData:null,
				total:null,
				
				addtags:false,
				addtags_form:{
					tag:''
				},
				ids:'',
				oneId:'',
				
				createGroup:false,
				industry:['家居装修','医疗美容','房地产','信用卡','会展','教育','旅游','游戏','理财','个贷','网贷','婚恋','其他'],	
				form: {
					num:0,
					name: '',
					industry: '',
					scene:'',
					ids:''
				},
				
				jump:false,
				num:'',
				groupId:'',
				
				formWidth: '30px',
				hide:true
		
			}
		},
		mounted(){
			this.getBoxNumber();
			this.getSignList();			
			this.inquire(1);
			let index = sessionStorage.getItem('machineCalling');
			if(index == -1){
					this.hide = false;
			}else {
					this.hide = true;
			}
		},
		methods:{
			getBoxNumber(){// 设备列表
				let data = {
							pageNo: 1,
							pageSize: 1000
				};
				this.$http('/box/list',data).then(res=>{				
					if(res.result == 200){
						let list = res.data.list;
						list.unshift({boxName:'不勾选设备',id:999,boxNumber:null})
						this.equipList = list;	
					}
				});
			},
			handleClose(val,index){//移除表格标签				
				 	let data = {
						tagDetailId:val.id
				 	};
				  this.$http('/tag/deleteTag',data).then(res=>{
						if(res.result == 200){//删除标签操作
							this.tableData[index].tagList.splice(this.tableData[index].tagList.indexOf(val),1);						
						}
				});
				 
			},
			checked(val){//批量选中
				this.form.num = val.length;
				if(val.length){
					let arr = [];
					val.map(item=>{
						arr.push(item.id);
					});
					this.ids = arr.join();
				}else {
					this.ids = '';
				}
			},
			clickGroups(){//点击创建群组
				if(this.form.num == ''){
					this.form.num = this.total;
				}
				this.createGroup = true;
			},
			currentPage_data(index){//当前页
				this.inquire(index);
			},
			inquire(index){//查询
				let rule = (/^1(3|4|5|7|8)\d{9}$/.test(this.phone));
				if(this.phone == ''&&!rule ||rule) {				
					let data = {
						pageNo: index||this.currentPage,
						pageSize: 10,
						boxNumbers:this.equipId,
						lastActiveDateStart:this.startTime,
						lastActiveDateEnd:this.endTime,
						maxDistance:this.distance,
						needNewCustomer:this.newCustomer,
						needOldCustomer:this.oldCustomer,
						macGroupId:	this.$route.query.groupId,
						tags:this.signId,	
						mobile:	this.phone		
					};
					this.$http('/customer/macGroupCustomerList',data).then(res=>{
						if(res.result == 200){
							this.total = res.data.count;																								
							let list = res.data.list;
							list.map(item=>{
								item.gmtCreated = this.$common.transformTime(item.gmtCreated);
								if(item.lastActiveDistance == -1){
									item.lastActiveDistance = '未知';
								}else{
									item.lastActiveDistance += '米';
								}
								if(item.newCome == 1){
									item.newCome = '新客';
								}else {
									item.newCome = '老客';
								}					
							});									
							this.tableData = list;											
						}
					});			
				}else {
					this.$message({ message: '手机号码有误，请重新填写!', type: 'warning'}); 
				} 				
			},
			click_addTags(val){//点击按钮添加标签
					if(val){
						this.oneId = val.id;
					}	
					this.addtags = true;
			},
			addtags_submit(){//添加标签
				let tag = this.addtags_form.tag.trim();
				if(tag.length){	
					let data = {
							tagName: tag,
							ids: this.oneId || this.ids,						
							boxNumbers:this.equipId,
							lastActiveDateStart:this.startTime,
							lastActiveDateEnd:this.endTime,
							maxDistance:this.distance,
							needNewCustomer:this.newCustomer,
							needOldCustomer:this.oldCustomer,
							macGroupId:	this.$route.query.groupId,
							tags:this.signId,	
							mobile:	this.phone	
						};
					this.$http('/tag/addTag',data).then(res=>{
						if(res.result == 200){
							let _this = this;
							this.getSignList();
							setTimeout(()=>{
								_this.inquire();
							},1000);						
							this.addtags_cancel();
							this.$message({message: '添加标签成功！',type: 'success'});						
						}
					});			
				}else {
				this.$message({message: '标签名不能为空！',type: 'warning'});
				}		
			},
		  addtags_cancel(){//取消添加标签
				this.addtags_form.tag = '' ;
				this.oneId = '';
				this.ids = '';
				this.$refs.multipleTable.clearSelection();
				this.addtags = false ;
		  },
		  getSignList(){//获取标签列表
			 let data = {
						pageNo: 1,
						pageSize: 1000
				};
			 this.$http('/tag/tagList',data).then(res=>{				
					if(res.result == 200){
						let list = res.data.list;
						list.unshift({id:null,tagName:'不勾选标签'});
						this.signList = list;
					}
			 });
		  },
			removeTags(val){//确认删除
				 if(val || this.ids) {
						 this.$confirm('此操作将删除该项数据, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						 }).then(() => {
							  if(val){//单个删除
								  this.deleteTags(val.id);
							  }else{//批量删除
								  this.deleteTags(this.ids);
							  }
								this.ids = '';
								this.$refs.multipleTable.clearSelection();																
						 }).catch(() => {//取消
							  this.ids = '';
							  this.$refs.multipleTable.clearSelection();
						 });				 
				 }else {
					 this.$message({message: '没有勾选任何数据！',type: 'warning'});
				 }	
				
			},
			deleteTags(val){//删除标签
				 let data = {
						ids: val			
				 };
				 this.$http('/sms/deleteMacGroupDetail',data).then(res=>{				
					if(res.result == 200){
							this.inquire();			 								
							this.$message({message: '删除成功！',type: 'success'});				
					}
			 });
			},
			createGroup_cancel(){//群组取消
				 this.form = {
					 industry:'',
					 scene:'',
					 name:''
				 };
				 this.$refs.multipleTable.clearSelection();
				 this.createGroup = false;
			},
			createGroup_submit(){//创建群组
					let name = this.form.name.trim(),
							industry = this.form.industry.trim(),
							scene = this.form.scene.trim();
					if(name.length &&industry.length &&scene.length){
						let data = {
									boxNumbers:this.equipId,
									lastActiveDateStart:this.startTime,
									lastActiveDateEnd:this.endTime,
									maxDistance:this.distance,
									tags:this.signId,
									needNewCustomer:this.newCustomer,
									needOldCustomer:this.oldCustomer,
									mobile:this.phone,					
									groupName:name,
									industry:industry ,
									remark:scene,
									ids:this.ids																		
							};
						this.$http('/sms/addMacGroupFormGroup',data).then(res=>{				
							if (res.result == 200) {
									this.$message({ message: '群组创建成功！',type: 'success'});
									this.groupId = res.data.id;
									this.num = res.data.totalCount;
									this.$refs.multipleTable.clearSelection();
									this.createGroup = false;
									this.jump = true;					
							}
						});	
				 }else {
						this.$message({message: '信息填写不完整！',type: 'warning'});		
				 }
			},
			jump_cancel(){//取消营销
				 this.form = {
					 industry:'',
					 scene:'',
					 name:''
				 };
				 this.$refs.multipleTable.clearSelection();
				 this.jump = false;		 
			},
			jump_sms(){//导入短信系统
				this.$router.push({path:'/SMSmarketing',query:{groupId:this.groupId}});
				this.jump = false;
			},
			jump_phone(){//导入呼叫系统
				 this.$router.push({ path:'/machineCalling', query:{ groupId:this.groupId }});
				 this.jump = false;
			}
	
   }
 }
</script>


<style scoped="scoped">
	
	.nav{
		width: 1200px;
		margin-bottom: 20px;
	}
	.time,.equip,.sign,.distance,.guest,.phone{
		width: 600px;
		margin-bottom: 20px;
	}
	.equip .el-select,.sign .el-select,.distance .el-input,.phone .el-input{
		width: 430px;
	}
	
	.time,.sign,.guest{
		float: right;
		text-align: left;
	}
	.guest{		
		line-height: 38px;
	}
	.startTime{
		width: 200px;
		margin-right: 6px;
	}
	.endTime{
		width: 200px;
		margin-left: 7px;
	}
	.operate{
		width: 400px;
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
	}
	.operate .el-button{
		width: 120px;
	}
	.el-table{
		margin:20px 0 30px 0;
	}
	.el-pagination{
		float: right;
		text-align: right;
	}
	.el-dialog .el-input{
		width: 300px;
	}
	.el-dialog b{
		color:#657180 ;
		font-size: 17px;
	}
	.el-dialog b i{
		font-style: normal;
		color: lightseagreen;
	}
	.createGroup{
		width: 120px;
	}	
	.group-dialog .el-input,.group-dialog .el-select{
		width: 250px;
	}	
	.footer .el-button{
		width: 120px;
	}
	.startMarketing .word{
		display: block;
		text-align: center;
	}
	.el-tag {
		margin: 3px;
	}
</style>
