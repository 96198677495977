<template>
  <div class="passengerNum">
    <!-- 搜索条件 -->
    <div class="nav">
      <div class="time">
        起止时间：
        <el-date-picker
          v-model="startTime"
          type="date"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd "
        ></el-date-picker>
      </div>
      <div class="equip">
        设备名称：
        <el-select v-model="boxNumber" placeholder="">
          <el-option
            v-for="item in equipList"
            :key="item.id"
            :label="item.boxName"
            :value="item.boxNumber"
          ></el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="inquire()">信息查询</el-button>
    </div>
    <!-- 内容展示 -->
    <router-view name="third"></router-view>
  </div>
</template>

<script>
export default {
  name: "PassengerNum",
  data() {
    return {
      msg: "客流统计",
      startTime: "",
      equipList: [],
      boxNumber: "",
    };
  },
  watch: {
    boxNumber(val) {
      if (val == "没有设备！") {
        this.$confirm("设备列表为空，请先跳转至 设备管理 页面!", "提示", {
          confirmButtonText: "跳转",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          //跳转操作
          this.$router.push("/equipmentManager");
        });
      }
    },
  },
  mounted() {
    this.getBoxNumber(); //设备列表
    this.startTime = this.getTime();
  },
  methods: {
    getBoxNumber() {
      // 设备列表
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.$http("/box/list", data).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          console.log(list);
          if (list.length) {
            this.equipList = list;
            this.boxNumber = list[0].boxNumber;
            this.inquire(); //客流统计
          } else {
            this.boxNumber = "没有设备！";
          }
        }
      });
    },
    getTime() {
      // 时间格式 y-m-d
      let time = new Date(),
        y = time.getFullYear(),
        m = time.getMonth() + 1,
        d = time.getDate();
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      return y + "-" + add0(m) + "-" + add0(d);
    },
    inquire() {
      //点击查询
      this.startTime = this.startTime.trim();
      let data = {
        dateStart: this.startTime + " 00:00:00",
        dateEnd: this.startTime + " 23:59:59",
        boxNumber: this.boxNumber,
      };
      this.$http("/box/dashboard", data).then((res) => {
        if (res.result == 200) {
          let data = res.data;
          if (JSON.stringify(data) == "{}") {
            this.$router.push({ path: "/nodata" });
          } else {
            data = JSON.stringify(data);
            sessionStorage.setItem("res", data);
            this.$router.push({ path: "/charts" });
          }
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
.nav {
  width: 1200px;
  margin-bottom: 30px;
}
.time,
.equip {
  width: 600px;
}
.time {
  float: right;
  text-align: left;
}
.time .el-date-editor,
.equip .el-select {
  width: 430px;
}
.nav .el-button {
  width: 120px;
  margin-top: 30px;
}
.explain {
  font-style: normal;
  color: blue;
  font-size: 14px;
}

/* 用户信息 */
.showInfo {
  display: flex;
  justify-content: space-between;
}
.showInfo li {
  width: 15%;
  min-width: 200px;
  line-height: 50px;
  border-radius: 3px;
  color: #34bfa3;
  box-shadow: 0px 0px 1px 1px lightgray;
}
.showInfo li:before {
  display: inline-block;
  font-size: 50px;
  margin: 10% 0 0 10%;
}
.showInfo li i {
  display: block;
  width: 45%;
  float: right;
  font-style: normal;
  color: #8f8f8f;
}

/* 绘制图形 */

.content22 div,
.pie li {
  height: 380px;
  min-width: 300px;
  border-radius: 3px;
  box-shadow: 0px 0px 1px 1px lightgray;
}
.content22 b {
  display: block;
  font-style: normal;
  line-height: 50px;
  color: #66b1ff;
  font-size: 18px;
  padding-left: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid lightgray;
}
.content22 span {
  width: 100%;
  display: inline-block;
  height: 300px;
}
.passengerAnalysis {
  margin: 90px 0 50px 0;
}
.pie {
  display: flex;
  justify-content: space-between;
}
.pie li {
  width: 30%;
}
.phoneBrand {
  width: 100%;
  margin-top: 30px;
}
.editor {
  color: #34bfa3;
  font-size: 14px;
}

/* 隐藏样式 */
.content {
  position: absolute;
  top: 138px;
  left: -3px;
  z-index: 9;
  width: 101%;
  height: 910px;
  line-height: 200px;
  text-align: center;
  font-size: 20px;
  color: #0000005c;
  background: white;
  /* box-shadow: 0px 0px 1px 1px white; */
}
</style>
