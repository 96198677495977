<template>
  <div class="knowledgeFlow">
    <div class="nav">
      <div class="batchName">
        流程名称：
        <el-input v-model="flowName" placeholder="请输入流程名称" clearable></el-input>
      </div>
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button @click="addFlow">添加新流程</el-button>
    </div>
    <el-table
      :data="tableListData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
      height="calc(100% - 145px)"
    >
      <el-table-column prop="id" label="编号" width="70" align="center"></el-table-column>
      <el-table-column prop="name" label="流程名称" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="gmtCreated" label="创建时间" width="190" align="left"></el-table-column>
      <el-table-column prop label="操作" width="130" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modifyClick(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="deleteClick(scope.row)" style="color: #F56C6C">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <!-- <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination> -->
    <!-- 增加流程 -->
    <el-dialog 
      title="添加流程" 
      :visible.sync="flowShow" 
      width="500px" 
      @close="flowClose"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="流程名称:" required>
          <el-input 
            v-model="flowForm.name" 
            placeholder="请输入词条" 
            maxlength="12"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="flow_submit" :loading="loading">确定</el-button>
        <el-button @click="flowShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "knowledgeFlow",
  props: {
    activeSpeechId: [String, Number],
    activeSpeechName: String
  },
  data() {
    return {
      loading: false,
      flowName: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableListData: [],
      loadingTable: false,
      flowShow: false,
      flowForm: {
        name: ""
      }
    }
  },
  mounted() {
    this.inquire();
  },
  methods: {
    // 点击查询
    inquire() {
      let data = {
        dialogueId: this.activeSpeechId,
        type: "SECONDARY"
      }
      this.getFlowList(data);
    },
    // 查询流程列表
    getFlowList(data) {
      this.loadingTable = true;
      this.$http("/dialogueScenario/dialogueScenarioList",null,"get",null,data).then(res => {
        this.loadingTable = false;
        if (res.result === 200) {
          let list = res.data;
          this.tableListData = list;
        }
      }).catch(err => {
        this.loadingTable = false;
      })
    },
    // 分页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 页数
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 点击新增
    addFlow() {
      this.flowShow = true;
    },
    // 点击修改
    modifyClick(val) {
      this.$router.push({path: "/knowledgePanle", query: { 
        dialogueId: val.dialogueId, 
        scenarioId: val.id, 
        name: this.activeSpeechName 
      }})
    },
    // 确定确定
    flow_submit() {
      let data = {
        dialogueId: this.activeSpeechId,
        name: this.flowForm.name,
        type: "SECONDARY"
      }
      if (!this.flowForm.name) {
        this.$message.success("请输入流程名称");
        return
      }
      this.loading = true;
      this.$service("/dialogueScenario/addDialogueScenario",data).then(res => {
        this.loading = false;
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.inquire();
          this.flowShow = false;
        }
      }).catch(err => {
        this.loading = false;
      })
    },
    // 关闭新增
    flowClose() {
      this.flowForm = {
        name: ""
      }
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm('此操作将删除【'+val.name+'】流程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: val.id
        }
        this.$service("/dialogueScenario/deleteDialogueScenario",data).then(res => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.inquire();
          }
        })
      }).catch(() => {
               
      });
    },
  },
}
</script>
<style scoped>
.knowledgeFlow {
  height: 100%;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  /* margin-bottom: 20px; */
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
</style>