<template>
  <div class="robotAddPowder">
    <div class="nav">
      <div class="batchName">
        客户编号：
        <el-input
          v-model="condition.customerCode"
          placeholder="请输入客户编号"
          clearable
        ></el-input>
      </div>
      <div class="callTime">
        调用时间：
        <i style="margin-left: 16px"></i>
        <el-date-picker
          v-model="condition.gmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
        ></el-date-picker>
      </div>
    </div>
    <div class="nav">
      <div class="batchName">
        意愿：
        <i style="margin-left: 32px"></i>
        <el-select
          v-model="condition.willingInfos"
          clearable
          placeholder="请选择意愿"
          @change="statusChange"
          multiple
          collapse-tags
        >
          <el-option
            v-for="item in willingInfoList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="batchName">
        <el-checkbox v-model="wechatAdded">过滤已加微信</el-checkbox>
        <el-tooltip
          content="勾选会过滤掉已加微信的记录"
          placement="right"
          effect="light"
        >
          <i class="el-icon-question" style="color: #909399"></i>
        </el-tooltip>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button type="primary" @click="reAdd" :disabled="isReAdd"
        >重新添加</el-button
      >
      <el-button type="primary" @click="setGroup">分组设置</el-button>
    </div>
    <!-- 机器人加粉列表 -->
    <el-table
      :data="robotAddPowderTableData"
      stripe
      style="width: 100%"
      border=""
      v-loading="loadingTable"
    >
      <el-table-column
        prop="id"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerCode"
        label="客户编号"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="platform"
        label="平台"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callMillis"
        label="拨打时长(秒)"
        width="165"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="willingInfo"
        label="意愿"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.willingInfoType" size="small">{{
            scope.row.willingInfolabel
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="callStatus"
        label="状态"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.callStatusType" size="small">{{
            scope.row.callStatuslabel
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="addFanFlag"
        label="是否已加微信"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.addFanFlag">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="调用时间"
        width="170"
        align="center"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="325"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="playRecord(scope.row)"
            :disabled="!scope.row.audioUrl"
            >播放录音</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <my-pagination
      totalShow
      onePageNoShow
      pageSizeShow
      :tableList="robotAddPowderTableData"
      @inquireTotal="inquire(null, 'total')"
      @inquireOne="inquire(1)"
      @currentPageNo="currentPageNo"
      @currentPageSize="currentPageSize"
      class="el-pagination"
    ></my-pagination>
    <!-- 重新添加 -->
    <el-dialog
      title="重新添加"
      :visible.sync="reAddShow"
      width="400px"
      @close="reAddClose"
    >
      <template slot="title">
        <i class="el-icon-warning dialog-title-my" style="color: #e6a23c"></i>
        <span class="dialog-title-my my-title">重新添加</span>
      </template>
      <el-form label-width="100px" label-position="left">
        <el-form-item label="服务器：">
          <el-select
            v-model="wechatClientGroupIdServer"
            placeholder="请选择服务器"
            style="width: 95%"
            @change="wechatClientGroupFocus(wechatClientGroupIdServer, 'query')"
          >
            <el-option
              v-for="item in wechatServerList"
              :key="item.server"
              :label="item.server"
              :value="item.server"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机器分组：">
          <el-select
            v-model="addWechatClientGroupId"
            placeholder="请选择机器分组"
            style="width: 95%"
            clearable
          >
            <el-option
              v-for="item in wechatClientGroupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="color: #e6a23c">1. 将会重新添加 {{ total }} 个好友</div>
      <div style="color: #e6a23c">2. 只有未加微信的状态可以重新添加</div>
      <div style="color: #e6a23c">3. 两次添加会间隔10秒</div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="reAdd_submit">确定</el-button>
        <el-button @click="reAddShow = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 分组设置 -->
    <el-dialog
      title="分组设置"
      :visible.sync="groupingShow"
      width="400px"
      @close="groupClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">分组设置</span>
      </template>
      <el-form label-width="100px" label-position="left">
        <el-form-item label="启用：">
          <el-switch
            v-model="groupEnable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="分组设置：" v-if="groupEnable" required>
          <el-select
            v-model="wechatClientGroupIdServer"
            placeholder="请选择服务器"
            style="width: 95%; margin-bottom: 15px"
            @change="wechatClientGroupFocus(wechatClientGroupIdServer, 'query')"
          >
            <el-option
              v-for="item in wechatServerList"
              :key="item.server"
              :label="item.server"
              :value="item.server"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="addWechatClientGroupId"
            placeholder="请选择机器分组"
            style="width: 95%"
            clearable
          >
            <el-option
              v-for="item in wechatClientGroupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="group_submit">确定</el-button>
        <el-button @click="groupingShow = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      class="customDialog"
      :visible.sync="playRecordShow"
      width="300px"
      @close="closeDialog"
    >
      <audio
        ref="audio"
        :src="currentSrc"
        autoplay="autoplay"
        controlsList="nodownload"
        controls="controls"
      ></audio>
    </el-dialog>
  </div>
</template>
<script>
import myPagination from "../pages/myPagination.vue";
export default {
  name: "robotAddPowder",
  components: {
    myPagination,
  },
  data() {
    return {
      condition: {
        customerCode: "",
        gmtCreatedDate: [],
        willingInfos: [],
      },
      wechatAdded: false,
      willingInfoList: [
        {
          value: 1,
          label: "强烈意向",
        },
        {
          value: 2,
          label: "有意向",
        },
        {
          value: 3,
          label: "需筛选",
        },
        {
          value: 4,
          label: "拒绝",
        },
        {
          value: 5,
          label: "未知",
        },
      ],
      isReAdd: true,
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loadingTable: false,
      robotAddPowderTableData: [],
      reAddShow: false,
      wechatClientGroupIdServer: "t9.innerchic.cn",
      wechatServerList: [
        {
          server: "t1.innerchic.cn",
        },
        {
          server: "t9.innerchic.cn",
        },
      ],
      addWechatClientGroupId: "",
      wechatClientGroupList: [],
      groupingShow: false,
      groupEnable: false,
      playRecordShow: false,
      currentSrc: "",
    };
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.gmtCreatedDate = [start, end];
    this.theWeek();
    this.inquire(1);
  },
  methods: {
    closeDialog() {
      //播放录音关闭
      this.$refs.audio.pause();
    },
    playRecord(val) {
      //播放录音
      this.currentSrc = val.audioUrl;
      this.playRecordShow = true;
      this.$nextTick(() => {
        this.$refs.audio.play();
      });
    },
    // 点击重新添加
    reAdd() {
      this.reAddShow = true;
      this.wechatClientGroupFocus(this.wechatClientGroupIdServer);
    },
    // 提交重新添加
    reAdd_submit() {
      if (!this.addWechatClientGroupId) {
        this.$message.warning("请选择机器分组");
        return;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let data = {
        customerCodeOrPhone: this.condition.customerCode,
        willingInfos: "",
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
        totalCount: this.total,
        wechatClientGroupId: this.addWechatClientGroupId,
      };
      if (this.condition.willingInfos) {
        data.willingInfos = this.condition.willingInfos.join(",");
      }
      if (this.wechatAdded) {
        data.wechatAdded = false;
      }
      this.$http("/robot/reAddCustomerForRobot", data).then((res) => {
        if (res.result === 200) {
          this.reAddShow = false;
          this.$message.success("操作成功");
        }
      });
    },
    // 关闭重新添加
    reAddClose() {
      this.wechatClientGroupIdServer = "t9.innerchic.cn";
      this.addWechatClientGroupId = "";
    },
    // 点击分组设置
    setGroup() {
      this.groupingShow = true;
      this.getGroupInfo();
    },
    // 提交分组设置
    group_submit() {
      let data = {
        wechatGroupId: this.addWechatClientGroupId,
      };
      // 判断是否开启
      if (this.groupEnable) {
        if (!this.addWechatClientGroupId) {
          this.$message.warning("机器分组不能为空");
          return;
        }
        this.$service("/robot/enableAddFans", data).then((res) => {
          if (res.result === 200) {
            this.groupingShow = false;
            this.$message.success("设置成功");
          }
        });
      } else {
        this.$http("/robot/suspendAddFans").then((res) => {
          if (res.result === 200) {
            this.groupingShow = false;
            this.$message.success("设置成功");
          }
        });
      }
    },
    // 关闭分组设置
    groupClose() {
      this.groupEnable = false;
      this.wechatClientGroupIdServer = "t9.innerchic.cn";
      this.addWechatClientGroupId = "";
    },
    // 查询当前分组设置
    getGroupInfo() {
      this.$http("/robot/getAddFansConfig", null, "get").then((res) => {
        if (res.result === 200) {
          if (res.data) {
            res.data.enabled === "true"
              ? (this.groupEnable = true)
              : (this.groupEnable = false);
            this.addWechatClientGroupId = res.data.wechatClientGroupId;
            this.wechatClientGroupIdServer = res.data.wechatClientGroupServer;
            this.wechatClientGroupFocus(res.data.wechatClientGroupServer);
          }
        }
      });
    },
    // 机器分组获取焦点
    wechatClientGroupFocus(val, sign) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        server: val,
      };
      if (sign === "query") {
        this.addWechatClientGroupId = "";
      }
      this.getWechatClientGroupList(data);
    },
    // 查询机器分组
    getWechatClientGroupList(data) {
      this.$service(
        "/wechat/wechatClientGroupList",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.wechatClientGroupList = list;
        }
      });
    },
    // 状态搜索选项change
    statusChange(val) {},
    // 点击查询
    inquire(index, isTotal) {
      if (index) {
        this.pageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.condition.gmtCreatedDate) {
        gmtCreatedStart = this.condition.gmtCreatedDate[0];
        gmtCreatedEnd = this.condition.gmtCreatedDate[1];
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        customerCodeOrPhone: this.condition.customerCode,
        willingInfos: "",
        gmtCreatedStart: gmtCreatedStart,
        gmtCreatedEnd: gmtCreatedEnd,
      };
      if (this.condition.willingInfos) {
        data.willingInfos = this.condition.willingInfos.join(",");
      }
      if (this.wechatAdded) {
        data.wechatAdded = false;
      }
      if (isTotal) {
        data.queryTotalCount = true;
      }
      this.getRobotAddPowderList(data, isTotal);
    },
    // 查询机器人加粉列表
    getRobotAddPowderList(data, isTotal) {
      if (!isTotal) {
        this.loadingTable = true;
      }
      this.isReAdd = true;
      this.$http("/robot/callbackList", null, "get", null, data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.total = res.data.total;
            if (res.data.total > 0 && this.wechatAdded) {
              this.isReAdd = false;
            }
            if (isTotal) {
              this.$message.success("数据总共 " + res.data.total + " 条");
              return;
            }
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              switch (item.willingInfo) {
                case 1:
                  item.willingInfoType = "success";
                  item.willingInfolabel = "强烈意向";
                  break;
                case 2:
                  item.willingInfoType = "success";
                  item.willingInfolabel = "有意向";
                  break;
                case 3:
                  item.willingInfoType = "warning";
                  item.willingInfolabel = "需筛选";
                  break;
                case 4:
                  item.willingInfoType = "danger";
                  item.willingInfolabel = "拒绝";
                  break;
                case 5:
                  item.willingInfoType = "warning";
                  item.willingInfolabel = "未知";
                  break;
              }
              switch (item.callStatus) {
                case 0:
                  item.callStatusType = "success";
                  item.callStatuslabel = "已经接通";
                  break;
                case 1:
                  item.callStatusType = "danger";
                  item.callStatuslabel = "未接通";
                  break;
              }
              if (item.callMillis) {
                item.callMillis = item.callMillis / 1000;
              }
            });
            this.robotAddPowderTableData = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPageNo(pageNo) {
      this.inquire(pageNo);
    },
    currentPageSize(pageSize) {
      this.pageSize = Number(pageSize);
      this.inquire(1);
    },
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
  },
};
</script>
<style scoped>
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
}
.nav .callTime .el-date-editor {
  width: 360px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.nav .batchName .el-select {
  width: 260px;
  margin: 0 16px 20px 16px;
}
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
</style>
