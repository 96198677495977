<template>
  <div class="paymentStatistics">
    <div class="nav">
      <div class="batchName">
        运营：
        <el-input v-model="operator" placeholder="请输入运营"></el-input>
      </div>
      <div class="batchName">
        时间：
        <el-date-picker
          v-model="abnormalGmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          :clearable="false"
        ></el-date-picker>
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-button
      type="primary"
      @click="addPay"
      v-if="roleMenu === 'WECHAT_PRODUCT_MANAGER' || roleMenu === 'SUPER_MANAGER' ? true : false"
    >
      上报
    </el-button>
    <el-table
      :data="payTableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="operator"
        label="运营"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="label"
        label="标签"
        min-width="150"
        align="center"
        v-if="dateRangeShow"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.label }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop
        :width="flexColumnWidth(item.label)"
        align="left"
        :label="item.label"
        v-for="(item, key) in productTypeList"
        :key="key"
      >
        <template slot-scope="scope">
          <div v-for="(val, key) in scope.row.mapProduct" :key="key">
            <span v-show="item.label == key">{{ val }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="lineTotal" label="统计" width="100" align="center"></el-table-column>
      <!-- <el-table-column prop label="操作" width="90" align="center" fixed="right">
        <template slot-scope="scope">
          
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 上报 -->
    <el-dialog
      title="上报"
      :visible.sync="addPayShow"
      custom-class="el-addBindshow"
      width="500px"
      @close="addPayClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">上报</span>
      </template>
      <el-form label-position="left" label-width="100px">
        <el-form-item label="产品名称：" required>
          <el-select v-model="productType" placeholder="请选择产品名称" @change="productTypeChange">
            <el-option
              v-for="item in productTypeList"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业绩系数：">
          <el-input v-model="productTypeRate" readonly></el-input>
        </el-form-item>
        <el-form-item label="上报文件：" required>
          <el-upload
            class="upload-demo"
            drag
            :data="addUploadData"
            :action="addUrl"
            :on-success="successNumberUpload"
            :on-error="errorNumberUpload"
            ref="addUpload"
            :auto-upload="false"
            :file-list="fileList"
            :with-credentials="cookie"
            :limit="1"
            :on-exceed="handleExceed"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
          <div class="titlt-show">请上传excel格式的文件(带表头)</div>
          <div class="titlt-show">只需要三列数据: 日期, 链接, 支付量</div>
        </el-form-item>
        <div class="button-center">
          <el-button type="primary" @click="addPay_submit()">确认</el-button>
          <el-button @click="addPayShow = false">取 消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "paymentStatistics",
  props: ["roleMenu", "loginName"],
  data() {
    return {
      abnormalGmtCreatedDate: [],
      payTableData: [],
      loadingTable: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      productTypeList: [],
      operator: "",
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      addPayShow: false,
      productType: "",
      productTypeRate: "",
      addUploadData: {},
      addUrl: this.$constants.baseURL + "/wechat/tab/addExcelWeChatScore",
      fileList: [],
      cookie: true,
      dateRangeShow: false
    };
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.abnormalGmtCreatedDate = [start, end];
    this.inquire(1);
    this.getLabelList();
    this.theWeek();
  },
  methods: {
    // 上传产品选择
    productTypeChange(val) {
      this.productTypeList.forEach(item => {
        if (item.label === val) {
          this.productTypeRate = "";
          if (item.rate) {
            this.productTypeRate = item.rate / 100;
          }
        }
      })
    },
    getDateRangeShow() {
      if (this.abnormalGmtCreatedDate) {
        let end = new Date(this.abnormalGmtCreatedDate[1]),
          start = new Date(this.abnormalGmtCreatedDate[0]);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
        start = start.valueOf();
        end = end.valueOf();
        let diff = start - end;
        if (diff === 0) {
          this.dateRangeShow = true;
          return 
        }
      }
      this.dateRangeShow = false;
    },
    // 点击上报
    addPay() {
      this.addPayShow = true;
    },
    // 确定提交上报
    addPay_submit() {
      if (!this.productType) {
        this.$message.warning("请选择产品名称");
        return;
      }
      this.addUploadData.productType = this.productType;
      this.$refs.addUpload.submit();
    },
    //上报成功
    successNumberUpload(res, file, fileList) {
      this.$refs.addUpload.clearFiles();
      if (res.result == 200) {
        this.$message({ message: "文件上传成功！", type: "success" });
        this.addPayShow = false;
        this.inquire(1);
      }
    },
    //上报失败
    errorNumberUpload(err, file, fileList) {
      let message = JSON.parse(err.message);
      this.$message.warning(message.message);
    },
    handleExceed(files, fileList) {
      //限制上传文件数量提示
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 关闭上报
    addPayClose() {
      this.productType = "";
      this.productTypeRate = "";
      this.$refs.addUpload.clearFiles();
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.abnormalGmtCreatedDate) {
        gmtCreatedStart = this.abnormalGmtCreatedDate[0].substring(0, 10);
        gmtCreatedEnd = this.abnormalGmtCreatedDate[1].substring(0, 10);
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        dateStart: gmtCreatedStart,
        datedEnd: gmtCreatedEnd,
        operator: this.operator,
      };
      this.getWeChatProduct(data);
    },
    // 翻页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 每页
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 请求支付统计数据
    getWeChatProduct(data) {
      this.loadingTable = true;
      this.getDateRangeShow();
      this.$service("/wechat/tab/findWeChatUserProductOrderPay", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.payTableData = list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 请求产品列表
    getLabelList() {
      this.$http("wechat/labelList", null, "get", null, {
        category: "LESSON",
      }).then((res) => {
        if (res.result === 200) {
          this.productTypeList = res.data;
        }
      });
    },
    flexColumnWidth(val) {
      //表格列宽
      let flexWidth = null;
      let widthList = [];
      flexWidth = this.$common.calculatedLength(val);
      if (flexWidth < 100) {
        // 最小值100
        flexWidth = 100;
      }
      widthList.push(flexWidth);
      widthList.sort((a, b) => b - a);
      return widthList[0] + 20 + "px";
    },
    // 每周
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-addBindshow .el-input,
.el-addBindshow .el-select {
  width: 80%;
}
.titlt-show {
  font-size: 14px;
  color: #e6a23c;
  line-height: 18px;
}
</style>