<template>
  <div class="connectionAnalysis">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="费用统计" name="expenses">
        <div class="nav">
          <div class="callTime">
            日期：
            <el-date-picker
              v-model="conditionExpenses.callEndAtStart"
              value-format="yyyy-MM-dd"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              align="right"
            >
            </el-date-picker>
          </div>
          <div class="companyName">
            用户名称：
            <el-select
              v-model="conditionExpenses.userLoginName"
              placeholder="请选择用户名称"
              filterable
              remote
              :loading="loadingLoginName"
              :remote-method="remoteMethodLoginName"
              clearable
              @focus="userNameFocus"
            >
              <el-option
                v-for="item in loginNameList"
                :key="item.id"
                :value="item.loginName"
                :label="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="taskName">
            线路名称：
            <el-select
              v-model="conditionExpenses.lineName"
              placeholder="请选择线路"
              filterable
              remote
              :loading="loadingLine"
              :remote-method="ExpensesMethod"
              clearable
              @focus="lineNameFocus"
            >
              <el-option
                v-for="item in lineNameList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquireExpenses()"
              >查询</el-button
            >
            <el-button
              type="success"
              @click="exportExpenses()"
              :disabled="isExportExpenses"
              >导出</el-button
            >
          </div>
        </div>
        <!-- 线路费用统计表 -->
        <el-table
          :data="expensesTableData"
          stripe
          style="width: 100%"
          border
          class="tableDataClass"
          :summary-method="getExpensesSummaries"
          show-summary
          @sort-change="connectionExpensesOrder"
          v-loading="loadingList"
        >
          <el-table-column
            type="index"
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="date"
            label="日期"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="userName"
            label="用户名称"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="routeName"
            label="线路"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="unitCount"
            label="拨打时长(分钟)"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="fee"
            label="费用(元)"
            min-width="100"
            align="center"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleExpensesSizeChange"
          @current-change="currentPage_ExpensesData"
          :current-page.sync="currentPageExpenses"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="totalExpenses"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="接通分析" name="analysis">
        <div class="nav">
          <div class="callTime">
            日期：
            <el-date-picker
              v-model="condition.callEndAtStart"
              value-format="yyyy-MM-dd"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              align="right"
            >
            </el-date-picker>
          </div>
          <div class="companyName">
            省份：
            <i style="margin-left: 31px"></i>
            <el-input
              v-model="condition.province"
              placeholder="请输入省份"
              clearable
            ></el-input>
          </div>
          <div class="batchName">
            <div>
              <el-checkbox v-model="filterCity" class="checkbox"
                >过滤城市</el-checkbox
              >
            </div>
          </div>
          <div class="taskName">
            城市：
            <i style="margin-left: 31px"></i>
            <el-input
              v-model="condition.city"
              placeholder="请输入城市"
              clearable
            ></el-input>
          </div>
          <div class="batchName">
            拨打次数：
            <el-select
              v-model="condition.recycleOrder"
              placeholder="请选择拨打次数"
            >
              <el-option
                v-for="item in timesList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="taskName">
            线路名称：
            <el-select
              v-model="condition.lineName"
              placeholder="请选择线路"
              filterable
              remote
              :loading="loadingLine"
              :remote-method="lineRemoteMethod"
              clearable
              @focus="lineNameFocus"
            >
              <el-option
                v-for="item in lineNameList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquireAnaltsis()"
              >查询</el-button
            >
            <el-button
              type="success"
              @click="exportAnaltsis()"
              :disabled="isExportAnaltsis"
              >导出</el-button
            >
          </div>
        </div>
        <!-- 线路接通分析表 -->
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          border
          class="tableDataClass"
          :summary-method="getSummaries"
          show-summary
          @sort-change="connectionAnalysisOrder"
          v-loading="loadingList"
        >
          <el-table-column
            type="index"
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="date"
            label="日期"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="routeName"
            label="线路"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="callCount"
            label="拨打数"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="connectCount"
            label="接通数"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="province"
            label="省份"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="city"
            label="城市"
            min-width="100"
            align="center"
            show-overflow-tooltip
            v-if="!filterCity"
          ></el-table-column>
          <el-table-column
            prop="answerRate"
            label="接通率"
            min-width="100"
            align="center"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="currentPage_data"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <!-- 数据说明 -->
        <div class="dataExplain_class">数据说明:</div>
        <div class="dataExplain_class">
          <span>接通率 = 接通数/拨打数(按照省份和城市分类统计)</span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="并发分析" name="concurrent">
        <div class="nav">
          <div class="callTime">
            日期：
            <el-date-picker
              v-model="conditionConcurrent.callEndAtStart"
              value-format="yyyy-MM-dd"
              type="daterange"
              unlink-panels
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="defaultTime"
              align="right"
            >
            </el-date-picker>
          </div>
          <div class="taskName">
            线路名称：
            <el-select
              v-model="conditionConcurrent.lineName"
              placeholder="请选择线路"
              filterable
              remote
              :loading="loadingLine"
              :remote-method="concurrentMethod"
              clearable
              @focus="lineNameFocus"
            >
              <el-option
                v-for="item in lineNameList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </div>
          <div class="operate">
            <el-button type="primary" @click="inquireConcurrent(1)"
              >查询</el-button
            >
          </div>
        </div>
        <!-- 并发分析统计表 -->
        <el-table
          :data="concurrentTableData"
          stripe
          style="width: 100%"
          border
          class="tableDataClass"
          v-loading="loadingList"
        >
          <el-table-column
            type="index"
            label="编号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="date"
            label="日期"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="routeName"
            label="线路"
            min-width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="currentCount"
            label="实时并发"
            min-width="100"
            align="center"
          >
            <template slot="header">
              <span>实时并发</span>
              <el-tooltip
                class="item"
                effect="light"
                content="实时数据"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="maxCount"
            label="最高并发"
            min-width="100"
            align="center"
          >
            <template slot="header">
              <span>最高并发</span>
              <el-tooltip
                class="item"
                effect="light"
                content="每分钟统计"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="210"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="concurrentTrends(scope.row)"
                >查看并发趋势</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="currentResetClick(scope.row)"
                >重置并发</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @size-change="handleConcurrentSizeChange"
          @current-change="currentPage_Concurrent"
          :current-page.sync="currentPageConcurrent"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          :pager-count="5"
          layout="sizes, total, prev, pager, next, jumper"
          :total="totalConcurrent"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- 并发趋势图 -->
    <el-dialog
      title="并发趋势图(近一小时)"
      :visible.sync="concurrentShow"
      width="950px"
      @close="concurrentClose"
    >
      <div ref="connectCountEcharts" class="lineEcharts"></div>
    </el-dialog>
    <!-- 重置并发 -->
    <el-dialog
      title="重置并发"
      :visible.sync="currentResetShow"
      width="450px"
      @close="currentResetClose"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">重置并发</span>
      </template>
      <el-form label-position="left" label-width="100px" class="el-form">
        <el-form-item label="实时并发：">
          <el-input
            v-model="currentValue"
            placeholder="请输入实时并发"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="currentReset">确定</el-button>
        <el-button @click="currentResetShow = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
require("echarts/lib/chart/bar");
export default {
  name: "connectionAnalysis",
  data() {
    return {
      routeId: "",
      currentValue: "",
      currentResetShow: false,
      concurrentShow: false,
      concurrentTableData: [],
      currentPageConcurrent: 1,
      pageSizeConcurrent: 10,
      totalConcurrent: null,
      conditionConcurrent: {
        callEndAtStart: [],
        lineName: "",
      },
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        onPick({ maxDate, minDate }) {
          if (minDate) {
            sessionStorage.setItem("theTime", minDate.getTime());
          }
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              const end = new Date();
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              picker.$emit("pick", [new Date(start), end]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "此刻",
            onClick(picker) {
              const end = new Date();
              let start = sessionStorage.getItem("theTime");
              if (start) {
                start = parseInt(start);
                picker.$emit("pick", [new Date(start), end]);
                sessionStorage.removeItem("theTime");
              }
            },
          },
        ],
      },
      defaultTime: ["00:00:00", "23:59:59"],
      condition: {
        callEndAtStart: [],
        province: "",
        city: "",
        lineName: "",
        recycleOrder: "0",
      },
      conditionExpenses: {
        callEndAtStart: [],
        province: "",
        city: "",
        lineName: "",
        userLoginName: "",
      },
      lineNameList: [],
      loadingLine: false,
      tableData: [],
      expensesTableData: [],
      listTotal: [],
      loadingList: false,
      currentPage: null,
      currentPageExpenses: null,
      pageSize: 10,
      pageSizeExpenses: 10,
      total: null,
      totalExpenses: null,
      filterCity: false,
      expensesFilterCity: false,
      order: {},
      activeName: "expenses",
      isExportAnaltsis: true,
      isExportExpenses: true,
      loginNameList: [],
      loadingLoginName: false,
      ExpensesTotal: [],
      connectCountEcharts: null,
      concurrentTimeList: [],
      concurrentValueList: [],
      timesList: [
        { label: "首拨", value: "0" },
        { label: "重拨1", value: "1" },
        { label: "重拨2", value: "2" },
      ],
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-线路分析";
    this.theWeek();
    let end = new Date(),
      start = new Date();
    start = this.$common.transformTime(start.valueOf()).substr(0, 10);
    end = this.$common.transformTime(end.valueOf()).substr(0, 10);
    this.condition.callEndAtStart = [start, end];
    this.conditionExpenses.callEndAtStart = [start, end];
    this.conditionConcurrent.callEndAtStart = [start, end];
    this.inquireExpenses();
    this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    this.inquireRoute();
  },
  methods: {
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 点击重置并发
    currentResetClick(val) {
      this.currentValue = val.currentCount;
      this.routeId = val.routeId;
      this.currentResetShow = true;
    },
    // 确定重置并发
    currentReset() {
      let data = {
        value: this.currentValue,
        id: this.routeId,
      };
      this.$service("/route/resetRouteMultipleRedisCache", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("重置成功");
          this.currentResetShow = false;
          this.inquireConcurrent();
        }
      });
    },
    // 关闭重制并发
    currentResetClose() {
      this.currentValue = "";
    },
    // 点击关闭并发趋势
    concurrentClose() {
      if (this.connectCountEcharts) {
        this.connectCountEcharts.clear();
      }
      this.connectCountEcharts = null;
    },
    // 点击查看并发趋势
    concurrentTrends(val) {
      this.concurrentShow = true;
      this.concurrentTimeList = [];
      this.concurrentValueList = [];
      let data = {
        routeId: val.routeId,
        date: val.date,
      };
      this.$http("/route/multiple/detail/list", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item) => {
              item.time = this.$common.transformTime(item.currentTime * 1000);
              item.time = item.time.substr(11);
              this.concurrentTimeList.push(item.time);
              this.concurrentValueList.push(item.concurrentCount);
            });
            this.drawConcurrent();
          }
        }
      );
    },
    // 绘制并发趋势图
    drawConcurrent() {
      //并发趋势图
      this.connectCountEcharts = echarts.init(this.$refs.connectCountEcharts);
      // 绘制图表
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (data) {
            let res = data[0].name + "<br/>",
              val;
            data.map((item) => {
              val = item.value;
              res += item.seriesName + ": " + val + "<br/>";
            });
            return res;
          },
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          name: "时间",
          type: "category",
          boundaryGap: false,
          axisLabel: {
            interval: 3, //横轴信息全部显示
            // rotate: 30,//30度角倾斜显示
          },
          data: this.concurrentTimeList,
        },
        yAxis: {
          name: "数量",
          type: "value",
        },
        series: [
          {
            name: "并发数量",
            type: "line",
            data: this.concurrentValueList,
            smooth: true,
          },
        ],
      };
      this.connectCountEcharts.setOption(option);
    },
    // 查询并发分析统计表
    inquireConcurrent(index) {
      if (index) {
        this.currentPageConcurrent = index;
      }
      let timeStart = "";
      let timeEnd = "";
      if (this.conditionConcurrent.callEndAtStart) {
        timeStart = this.conditionConcurrent.callEndAtStart[0];
        timeEnd = this.conditionConcurrent.callEndAtStart[1];
      }
      let data = {
        pageNo: this.currentPageConcurrent,
        pageSize: this.pageSizeConcurrent,
        start: timeStart,
        end: timeEnd,
        routeId: this.conditionConcurrent.lineName,
      };
      this.multipleList(data);
    },
    // 搜索线路并发分析
    concurrentMethod(lineName) {
      this.inquireRoute(lineName);
    },
    // 并发分析统计表数据查询
    multipleList(data) {
      this.loadingList = true;
      this.$http("/route/multiple/list", null, "get", null, data).then(
        (res) => {
          setTimeout(() => {
            this.loadingList = false;
          }, 200);
          if (res.result === 200) {
            let list = res.data.data;
            let currentTime = new Date();
            currentTime.setHours(0);
            currentTime.setMinutes(0);
            currentTime.setSeconds(0);
            currentTime.setMilliseconds(0);
            currentTime = currentTime.getTime();
            list.forEach((item) => {
              item.recordTime = new Date(item.date + " 00:00:00").getTime();
              if (item.recordTime < currentTime) {
                item.currentCount = "-";
              }
            });
            this.totalConcurrent = res.data.total;
            this.concurrentTableData = list;
          }
        }
      );
    },
    // 并发分析统计表显示分页
    handleConcurrentSizeChange(index) {
      this.pageSizeConcurrent = index;
      this.inquireConcurrent(1);
    },
    // 并发分析统计表分页
    currentPage_Concurrent(index) {
      this.inquireConcurrent(index);
    },
    // 用户名称选择框获取焦点
    userNameFocus() {
      this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    //搜索用户名称
    remoteMethodLoginName(userName) {
      this.loadingLoginName = true;
      setTimeout(() => {
        this.loadingLoginName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireLoginName(params);
      }, 200);
    },
    // 查询用户名称
    inquireLoginName(params) {
      this.loginNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            item.label = item.userName;
            this.loginNameList.push(item);
          });
        }
      });
    },
    // 切换tab标签页
    handleClick(tab, event) {
      this.isExportAnaltsis = true;
      this.isExportExpenses = true;
      this.inquireRoute();
      if (this.activeName === "analysis") {
        this.inquireAnaltsis();
      }
      if (this.activeName === "concurrent") {
        this.inquireConcurrent(1);
      }
      if (this.activeName === "expenses") {
        this.inquireExpenses();
        this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
      }
    },
    // 点击查询线路接通分析
    inquireAnaltsis(index) {
      if (!index) {
        this.currentPage = 1;
      }
      let timeStart = "";
      let timeEnd = "";
      if (this.condition.callEndAtStart) {
        timeStart = this.condition.callEndAtStart[0];
        timeEnd = this.condition.callEndAtStart[1];
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        timeStart: timeStart,
        timeEnd: timeEnd,
        province: this.condition.province,
        city: this.condition.city,
        routeId: this.condition.lineName,
        provinceGroup: this.filterCity,
        recycleOrder: this.condition.recycleOrder,
      };
      this.isExportAnaltsis = true;
      this.getConnectionAnalysisList(data);
    },
    // 查询表格线路接通分析
    getConnectionAnalysisList(data) {
      this.loadingLine = true;
      this.loadingList = true;
      this.$service(
        "/call400Stats/routeAnalysis/list",
        null,
        "get",
        null,
        data
      ).then((res) => {
        setTimeout(() => {
          this.loadingLine = false;
          this.loadingList = false;
        }, 200);
        if (res.result === 200) {
          let list = res.data.page.records;
          this.total = res.data.page.total;
          list.forEach((item) => {
            item.answerRate = "0.00%";
            if (item.connectCount > 0) {
              item.answerRate =
                ((item.connectCount / item.callCount) * 100).toFixed(2) + "%";
            }
          });
          this.tableData = list;
          if (this.tableData.length > 0) {
            this.isExportAnaltsis = false;
          } else {
            this.isExportAnaltsis = true;
          }
          let data = res.data.summary;
          if (data) {
            data.answerRate = "0.00%";
            if (data.connectCount > 0) {
              data.answerRate =
                ((data.connectCount / data.callCount) * 100).toFixed(2) + "%";
            }
            this.listTotal = data;
          }
        }
      });
    },
    // 当前页线路接通分析
    currentPage_data(index) {
      this.currentPage = index;
      this.inquireAnaltsis(index);
    },
    // 显示条数线路接通分析
    handleSizeChange(val) {
      this.pageSize = val;
      this.inquireAnaltsis();
    },
    // 合计行线路接通分析
    getSummaries(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        for (let i in this.listTotal) {
          if (i == column.property) {
            arr[index] = this.listTotal[i];
          }
        }
      });
      return arr;
    },
    // 查询线路接通分析
    inquireRoute(name) {
      let params = {
        pageNo: 1,
        pageSize: 1000,
        name: name,
        enabled: true,
        reverseType: "COMBINATION_ROUTE",
      };
      this.loadingLine = true;
      this.$http("/route/list", null, "get", null, params).then((res) => {
        this.loadingLine = false;
        if (res.result === 200) {
          let list = res.data.list;
          this.lineNameList = [];
          list.forEach((item) => {
            let obj = {
              id: item.id,
              name: item.name,
            };
            this.lineNameList.push(obj);
          });
        }
      });
    },
    // 线路选择框获取焦点
    lineNameFocus() {
      this.inquireRoute();
    },
    // 搜索线路接通分析
    lineRemoteMethod(lineName) {
      this.inquireRoute(lineName);
    },
    // 表格排序线路接通分析
    connectionAnalysisOrder(val) {
      this.order = {};
      if (val.order == "descending") {
        val.order = "desc";
      } else {
        val.order = "asc";
      }
      switch (val.prop) {
        case "date":
          this.order["date"] = val.order;
          break;
      }
      let timeStart = "";
      let timeEnd = "";
      if (this.condition.callEndAtStart) {
        timeStart = this.condition.callEndAtStart[0];
        timeEnd = this.condition.callEndAtStart[1];
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        timeStart: timeStart,
        timeEnd: timeEnd,
        province: this.condition.province,
        city: this.condition.city,
        routeId: this.condition.lineName,
        provinceGroup: this.filterCity,
        dateOrder: this.order["date"],
        recycleOrder: this.condition.recycleOrder,
      };
      this.getConnectionAnalysisList(data);
    },
    // 点击查询线路费用统计
    inquireExpenses(index) {
      if (!index) {
        this.currentPageExpenses = 1;
      }
      let timeStart = "";
      let timeEnd = "";
      if (this.conditionExpenses.callEndAtStart) {
        timeStart = this.conditionExpenses.callEndAtStart[0];
        timeEnd = this.conditionExpenses.callEndAtStart[1];
      }
      let data = {
        pageNo: this.currentPageExpenses,
        pageSize: this.pageSizeExpenses,
        userLoginName: this.conditionExpenses.userLoginName,
        startTime: timeStart,
        endTime: timeEnd,
        routeId: this.conditionExpenses.lineName,
      };
      this.isExportExpenses = true;
      this.getRouteDetailList(data);
      this.getExpensesTotal(data);
    },
    // 查询线路费用统计
    getRouteDetailList(data) {
      this.loadingList = true;
      this.$http(
        "/call400Stats/routeDetail/list",
        null,
        "get",
        null,
        data
      ).then((res) => {
        setTimeout(() => {
          this.loadingList = false;
        }, 200);
        if (res.result === 200) {
          let list = res.data.data;
          this.totalExpenses = res.data.total;
          this.expensesTableData = list;
          if (this.expensesTableData.length > 0) {
            this.isExportExpenses = false;
          } else {
            this.isExportExpenses = true;
          }
        }
      });
    },
    // 当前页线路费用统计
    currentPage_ExpensesData(index) {
      this.currentPageExpenses = index;
      this.inquireExpenses(index);
    },
    // 显示条数线路费用统计
    handleExpensesSizeChange(val) {
      this.pageSizeExpenses = val;
      this.inquireExpenses();
    },
    // 搜索线路费用统计
    ExpensesMethod(lineName) {
      this.inquireRoute(lineName);
    },
    // 查询线路费用统计合计
    getExpensesTotal(data) {
      this.$http(
        "/call400Stats/routeDetail/total",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          let data = res.data;
          this.ExpensesTotal = data;
        }
      });
    },
    // 合计行线路费用统计
    getExpensesSummaries(param) {
      const { columns, data } = param;
      let arr = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          arr[index] = "合计";
          return;
        }
        for (let i in this.ExpensesTotal) {
          if (i == column.property) {
            arr[index] = this.ExpensesTotal[i];
          }
        }
      });

      return arr;
    },
    // 表格排序线路费用统计
    connectionExpensesOrder(val) {
      this.order = {};
      if (val.order == "descending") {
        val.order = "desc";
      } else {
        val.order = "asc";
      }
      switch (val.prop) {
        case "date":
          this.order["date"] = val.order;
          break;
      }
    },
    // 点击导出费用统计表
    exportExpenses() {
      let params = {
        pageNo: this.currentPageExpenses,
        pageSize: this.pageSizeExpenses,
        routeId: this.conditionExpenses.lineName,
        userLoginName: this.conditionExpenses.userLoginName,
      };
      if (this.conditionExpenses.callEndAtStart) {
        params.startTime = this.conditionExpenses.callEndAtStart[0];
        params.endTime = this.conditionExpenses.callEndAtStart[1];
      }
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      let href =
        this.$constants.baseURL + "/call400Stats/routeDetail/export?" + url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      });
    },
    // 点击导出接通分析表
    exportAnaltsis() {
      let timeStart = "";
      let timeEnd = "";
      if (this.condition.callEndAtStart) {
        timeStart = this.condition.callEndAtStart[0];
        timeEnd = this.condition.callEndAtStart[1];
      }
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        province: this.condition.province,
        city: this.condition.city,
        routeId: this.condition.lineName,
        provinceGroup: this.filterCity,
        recycleOrder: this.condition.recycleOrder,
        timeStart: timeStart,
        timeEnd: timeEnd,
      };
      let url = "";
      for (let i in params) {
        url += i + "=" + params[i] + "&";
      }
      let href =
        this.$constants.baseURL + "/call400Stats/routeAnalysis/export?" + url;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      });
    },
  },
};
</script>
<style scoped>
.connectionAnalysis >>> .el-tabs__content {
  overflow: auto;
}
.lineEcharts {
  width: 920px;
  height: 350px;
}
.dataExplain_class {
  font-size: 14px;
  color: #606266;
}
.el-table {
  margin-bottom: 20px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav {
  width: 1200px;
}
.nav .el-input,
.nav .el-select {
  width: 300px;
}
.companyName,
.callTime,
.taskName,
.batchName {
  width: 700px;
  margin-bottom: 20px;
}
.callTime >>> .el-input__inner {
  width: 430px;
}
.callTime,
.batchName {
  margin-right: 6px;
}
.callTime,
.batchName {
  float: right;
  text-align: left;
}
.batchName .el-input {
  width: 230px;
}
.operate {
  margin-bottom: 20px;
}
.checkbox >>> .el-checkbox__label {
  font-size: 16px;
  color: #333;
}
</style>
