<template>
  <div class="customStatus">
    <div class="nav_menu">
      <div class="callCount">
        拨打次数：
        <el-input
          v-model="condition.callCountLower"
          placeholder="下限"
          class="startTime"
        ></el-input>
        -
        <el-input
          v-model="condition.callCountUpper"
          placeholder="上限"
          class="endTime"
        ></el-input>
      </div>
      <div class="companyName">
        用户名称：
        <el-select
          v-model="condition.userId"
          placeholder="请选择公司名称"
          @change="userIdChange"
          filterable
          remote
          :loading="loadingName"
          :remote-method="NameRemoteMethod"
          @focus="NameRemoteFocus"
          clearable
        >
          <el-option
            v-for="item in userNameList"
            :key="item.id"
            :value="item.id"
            :label="item.userName"
          ></el-option>
        </el-select>
      </div>
      <div class="callTime">
        导入日期：
        <el-date-picker
          v-model="condition.callEndAtStart"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="defaultTime"
          align="right"
        >
        </el-date-picker>
      </div>
      <div class="companyName">
        批次名称：
        <el-input
          v-model="condition.batchName"
          placeholder="请输入批次名称"
          clearable
        ></el-input>
      </div>
      <div class="record">
        <div>
          <el-checkbox v-model="hasNote" class="checkbox">有备注</el-checkbox>
        </div>
      </div>
      <div class="taskName">
        项目名称：
        <el-select
          v-model="condition.projectName"
          placeholder="请选择项目名称"
          filterable
          remote
          :loading="loadingProject"
          :remote-method="projectRemoteMethod"
          @focus="projectIdRemoteFocus"
          clearable
        >
          <el-option
            v-for="item in projectNameList"
            :key="item.id"
            :value="item.projectName"
            :label="item.projectNameLabel"
          ></el-option>
        </el-select>
      </div>

      <div class="operate">
        <el-button type="primary" @click="inquire()">查询</el-button>
        <el-button type="success" @click="derivedData()" :disabled="derivedDis"
          >导出数据</el-button
        >
      </div>
    </div>
    <!-- 详情表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      @sort-change="importDateOrder"
      @cell-click="handleCurrentChange"
      class="tableDataClass"
      v-loading="loading"
    >
      <el-table-column
        type="index"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="importDate"
        label="导入日期"
        sortable="custom"
        width="130"
        align="center"
      >
        <template slot="header">
          <span>导入日期</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="importDateText"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="projectName"
        label="项目"
        min-width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="batchName"
        label="批次名称"
        min-width="200"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="total"
        label="数据量"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callCount"
        label="拨打次数"
        sortable="custom"
        width="130"
        align="center"
      >
        <template slot="header">
          <span>拨打次数</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="callCountText"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="answerCountFirstThreeTimes"
        label="前三遍接通量"
        width="130"
        align="center"
      >
        <template slot="header">
          <span>前三遍接通量</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="answerCountFirstThreeTimesText"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="answerRateFirstThreeTimes"
        label="前三遍接通率"
        sortable="custom"
        width="150"
        align="center"
      >
        <template slot="header">
          <span>前三遍接通率</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="answerRateFirstThreeTimesText"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="answerCount"
        label="接通量"
        width="100"
        align="center"
      >
        <template slot="header">
          <span>接通量</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="answerCountText"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="answerRate"
        label="接通率"
        sortable="custom"
        width="110"
        align="center"
      >
        <template slot="header">
          <span>接通率</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="answerRateText"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop label="成单量" width="100" align="center">
        <el-table-column
          prop
          :label="item"
          v-for="(item, index) in orderList"
          :key="index"
          :width="flexColumnWidth(item)"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(val, key) in scope.row.order" :key="key">
              <span v-show="item == key">{{ val }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="orderRate"
        label="成单率"
        sortable="custom"
        width="110"
        align="center"
      >
        <template slot="header">
          <span>成单率</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="orderRateText"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="conversionRate"
        label="接通转化率"
        sortable="custom"
        width="140"
        align="center"
      >
        <template slot="header">
          <span>接通转化率</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="conversionRateText"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="fee"
        label="话费"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        label="备注"
        min-width="200"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-input
            ref="input"
            size="small"
            v-model="scope.row.note"
            placeholder="请输入内容"
            @blur="handleEdit(scope.row)"
            style="width: 100%"
            class="noteDisabled"
            :class="{ active: onVisible == scope.row.id }"
          ></el-input>
          <span :class="{ noteDisabled: onVisible == scope.row.id }">{{
            scope.row.note
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      :pager-count="5"
      layout="sizes, total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <div class="displayBlock"></div>
    <!-- 总计表 -->
    <el-table
      :data="tableDataCount"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
    >
      <el-table-column
        prop="name"
        label=""
        min-width="220"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="total"
        label="数据量"
        width="115"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="answerCount"
        label="接通量"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="answerRate"
        label="接通率"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="answerCountFirstThreeTimes"
        label="前三遍接通量"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="answerRateFirstThreeTimes"
        label="前三遍接通率"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column prop label="成单量" align="center">
        <el-table-column
          prop
          :label="item"
          v-for="(item, index) in headerList"
          :key="index"
          :width="flexColumnWidth(item)"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(val, key) in scope.row.order" :key="key">
              <span v-show="item == key">{{ val }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="orderRate"
        label="成单率"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="conversionRate"
        label="接通转化率"
        width="115"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="fee"
        label="话费"
        width="100"
        align="center"
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "customStatus",
  data() {
    return {
      loading: false,
      derivedDis: true,
      start: "",
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        onPick({ maxDate, minDate }) {
          if (minDate) {
            sessionStorage.setItem("theTime", minDate.getTime());
          }
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              const end = new Date();
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              picker.$emit("pick", [new Date(start), end]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "此刻",
            onClick(picker) {
              const end = new Date();
              let start = sessionStorage.getItem("theTime");
              if (start) {
                start = parseInt(start);
                picker.$emit("pick", [new Date(start), end]);
                sessionStorage.removeItem("theTime");
              }
            },
          },
        ],
      },
      defaultTime: ["00:00:00", "23:59:59"],
      onVisible: null,
      importDateText: "批次首次开始拨打日期",
      callCountText: "状态为“完成”的次数",
      answerCountFirstThreeTimesText: "前三遍接通的数据量",
      answerRateFirstThreeTimesText: "前三遍接通量/（接通量+未接通量）",
      answerCountText: "所有遍数接通的数据量",
      answerRateText: "接通量/（接通量+未接通量）",
      orderRateText: "成单量合计/（接通量+未接通量）",
      conversionRateText: "成单量合计/接通量",
      tableDataCount: null,
      orderList: [],
      headerList: [],
      text: {},
      hasNote: false,
      pageSize: 10,
      userNameList: [],
      projectNameList: [],
      isActive: {},
      isNoData: false,
      currentPage: null,
      total: null,
      condition: {
        companyName: "",
        batchName: "",
        userId: "",
        projectName: "",
        callEndAtStart: "",
        callEndAtEnd: "",
        callCountUpper: "",
        callCountLower: "",
        loginName: "",
      },
      tableData: [],
      letter: "", //默认不排序 // 排序字段
      original: false, //默认从小到大排列
      order: {},
      currentIndex: null,
      loadingName: false,
      loadingProject: false,
    };
  },
  mounted() {
    // if(!this.tableData.length>0){
    //   this.isNoData = true;
    // }
    document.title = "惠销平台管理系统-拨打统计";
    this.userNameList = [];
    this.inquireUserList({ pageSize: 1000, pageNo: 1, enabled: true });
    this.inquireProject();
    this.theWeek();
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.condition.callEndAtStart = [start, end];
    this.inquire(this.currentPage);
  },
  methods: {
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
    // 公司名称获取焦点
    NameRemoteFocus() {
      this.inquireUserList({ pageSize: 1000, pageNo: 1, enabled: true });
    },
    NameRemoteMethod(userName) {
      //搜索公司名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize: 1000,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireUserList(params);
      }, 200);
    },
    // 项目搜索获取焦点
    projectIdRemoteFocus() {
      let params = {
        pageNo: 1,
        pageSize: 20,
        loginName: this.condition.loginName,
      };
      this.inquireProject(params);
    },
    projectRemoteMethod(projectName) {
      //搜索项目名称
      this.loadingProject = true;
      setTimeout(() => {
        this.loadingProject = false;
        let params = {
          pageNo: 1,
          pageSize: 20,
          loginName: this.condition.loginName,
          projectName: projectName,
        };
        this.inquireProject(params);
      }, 200);
    },
    handleCurrentChange(row, column, cell, event) {
      //选中编辑列
      if (column.label == "备注") {
        this.onVisible = row.id;
        this.onCellClick(event, row.id);
      } else {
        this.onVisible = null;
      }
    },
    onCellClick(event, id) {
      //选中后自动获取光标
      if (this.currentIndex == id) {
        return;
      }
      this.$nextTick(() => {
        this.currentIndex = id;
        if (event.target.children[0]) {
          event.target.children[0].querySelector("input").focus();
        }
      });
    },
    handleEdit(row) {
      //编辑完成
      this.onVisible = null;
      let data = {
        id: row.id,
        note: row.note,
      };
      this.$http(
        "/call400Stats/stats/list/modifyNote",
        null,
        "post",
        null,
        data
      ).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "备注修改成功", type: "success" });
          this.inquire(this.currentPage);
        }
      });
    },
    handleSizeChange(val) {
      //显示条数
      this.pageSize = val;
      this.inquire();
    },
    currentPage_data(index) {
      //当前页
      this.currentPage = index;
      this.inquire(index);
    },
    orderFn(letter, original) {
      this.letter = letter; //排序字段
      this.original = original; //排序方式
    },
    inquire(index) {
      //查询
      if (!index) {
        this.currentPage = 1;
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        fuzzyBatchName: this.condition.batchName,
        projectName: this.condition.projectName,
        userId: this.condition.userId,
        callCountLessThan: this.condition.callCountUpper,
        callCountGreaterThanOrEqualTo: this.condition.callCountLower,
        importDateOrder: this.order["importDate"],
        callCountOrder: this.order["callCount"],
        answerRateFirstThreeTimesOrder: this.order["answerRateFirstThreeTimes"],
        answerRateOrder: this.order["answerRate"],
        conversionRateOrder: this.order["conversionRate"],
        orderRateOrder: this.order["orderRate"],
        hasNote: this.hasNote,
      };
      if (this.condition.callEndAtStart) {
        params.importDateStart = this.condition.callEndAtStart[0];
        params.importDateEnd = this.condition.callEndAtStart[1];
      }
      this.derivedDis = true;
      this.loading = true;
      this.$http("/call400Stats/stats/list", null, "get", null, params).then(
        (res) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (res.result == 200) {
            let list = [];
            if (res.data && res.data.length == 0) {
              this.total = 0;
            }
            if (res.data && res.data.data) {
              list = res.data.data.list;
              this.orderList = res.data.header;
              if (res.data.header && res.data.header.length > 0) {
                this.orderList.unshift("合计");
              }
              // if(!list.length>0){
              //   this.isNoData = true;
              //   return;
              // }
              let obj = {};
              this.total = res.data.data.count;
              list.map((item) => {
                item.importDate = this.$common.transformTime(item.importDate);
                item.importDate = item.importDate.substring(0, 10);
              });
            }
            if (list.length > 0) {
              this.derivedDis = false;
            }
            this.$nextTick(() => {
              this.tableData = list;
            });
          }
        }
      );
      this.$http(
        "/call400Stats/stats/list/total",
        null,
        "get",
        null,
        params
      ).then((res) => {
        if (res.result == 200) {
          let list = [];
          if (res.data) {
            list.push(res.data.total);
            list.push(res.data.totalThree);
            this.headerList = res.data.header;
            if (this.headerList.length > 0) {
              this.headerList.unshift("合计");
            }
            list[0].name = "合计";
            list[1].name = "拨打三遍以上批次合计";
          }
          list.map((item) => {
            item.order["合计"] = item.sum;
          });
          this.$nextTick(() => {
            this.tableDataCount = list;
          });
        }
      });
    },
    derivedData() {
      //导出数据
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        fuzzyBatchName: this.condition.batchName,
        projectName: this.condition.projectName,
        userId: this.condition.userId,
        callCountLessThan: this.condition.callCountUpper,
        callCountGreaterThanOrEqualTo: this.condition.callCountLower,
        importDateOrder: this.order["importDate"],
        callCountOrder: this.order["callCount"],
        answerRateFirstThreeTimesOrder: this.order["answerRateFirstThreeTimes"],
        answerRateOrder: this.order["answerRate"],
        conversionRateOrder: this.order["conversionRate"],
        orderRateOrder: this.order["orderRate"],
      };
      if (this.condition.callEndAtStart) {
        params.importDateStart = this.condition.callEndAtStart[0];
        params.importDateEnd = this.condition.callEndAtStart[1];
      }
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      let href = this.$constants.baseURL + "/call400Stats/stats/export?" + url;
      // let href = 'http://192.168.1.129:9997'+"/call400Stats/stats/export?"+url;
      // window.location.href = href;
      this.derivedDis = true;
      this.$common.promiseDownload(href).then((data) => {
        setTimeout(() => {
          this.derivedDis = data;
        }, 4000);
      });
    },
    userIdChange(userId) {
      //选择公司名称改变
      this.condition.projectName = "";
      this.condition.loginName = "";
      this.userNameList.forEach((item) => {
        if (userId === item.id) {
          this.condition.loginName = item.loginName;
        }
      });
    },
    inquireUserList(params) {
      //查询公司名称
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let list = res.data.list;
          this.userNameList = list;
        }
      });
    },
    inquireProject(params) {
      //查询项目名称
      this.projectNameList = [];
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        let list = res.data.list;
        let obj = {};
        list.map((item) => {
          obj = {
            projectName: item.projectName,
            projectNameLabel: item.projectName,
            id: item.id,
          };
          this.projectNameList.push(obj);
        });
      });
    },
    remarks(id) {
      //备注
      this.text["text_" + id] = id;
      this.$forceUpdate();
    },
    loseFocus() {
      //input失去焦点
      this.text = {};
    },
    importDateOrder(val) {
      //查询排序
      this.order = {};
      if (val.order == "descending") {
        val.order = "desc";
      } else {
        val.order = "asc";
      }
      switch (val.prop) {
        case "importDate":
          this.order["importDate"] = val.order;
          break;
        case "callCount":
          this.order["callCount"] = val.order;
          break;
        case "answerRateFirstThreeTimes":
          this.order["answerRateFirstThreeTimes"] = val.order;
          break;
        case "answerRate":
          this.order["answerRate"] = val.order;
          break;
        case "conversionRate":
          this.order["conversionRate"] = val.order;
          break;
        case "orderRate":
          this.order["orderRate"] = val.order;
          break;
      }
      this.inquire(this.currentPage);
    },
    // 计算表头宽度
    flexColumnWidth(val) {
      let flexWidth = null;
      flexWidth = this.$common.calculatedLength(val);
      if (flexWidth < 80) {
        flexWidth = 80;
      }
      return flexWidth + 20 + "px";
    },
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector("input").focus();
      },
    },
  },
  computed: {},
};
</script>
<style scoped="scoped">
.active {
  display: block !important;
}
.noteDisabled {
  display: none;
}
.displayBlock {
  clear: both;
}
.callTime >>> .el-input__inner {
  width: 430px;
}
.isActive {
  color: #409eff !important;
}
.noData {
  height: 65px;
  color: #909399;
}
.tableScroll {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}
table,
table tr th,
table tr td {
  border: 1px solid #ebeef5;
  vertical-align: middle;
  padding: 12px 0;
}
table {
  min-width: 1700px;
  min-height: 25px;
  text-align: center;
  table-layout: fixed;
  border-collapse: collapse;
  color: #606266;
  font-size: 14px;
}
thead {
  color: #909399;
  background-color: #f5f7fa;
  user-select: none;
}
tbody tr td,
tfoot tr td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
tbody tr:nth-child(odd) {
  background: #fff;
}
tbody tr:nth-child(even) {
  background: #fafafa;
}
.el-table {
  margin-bottom: 20px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.record {
  float: right;
  width: 530px;
  margin-bottom: 20px;
}
.record div {
  display: inline-block;
  width: 200px;
}
.checkbox >>> .el-checkbox__label {
  font-size: 16px;
  color: #333;
}
.callTime .startTime,
.callCount .startTime {
  width: 200px !important;
  margin-right: 6px;
}
.callTime .endTime,
.callCount .endTime {
  width: 200px !important;
  margin-left: 7px;
}
.callCount,
.callTime {
  float: right;
  text-align: left;
}
.elIcon {
  display: inline-block;
  position: absolute;
  width: 20px;
  left: 95px;
  top: 32px;
}
.elIcon-a {
  display: inline-block;
  position: absolute;
  width: 20px;
  left: 78px;
  top: 32px;
}
.elIcon-e {
  display: inline-block;
  position: absolute;
  width: 20px;
  left: 101px;
  top: 32px;
}
.elIcon-q {
  display: inline-block;
  position: absolute;
  width: 20px;
  left: 78px;
  top: 32px;
}
.elIcon-r {
  display: inline-block;
  position: absolute;
  width: 20px;
  left: 78px;
  top: 32px;
}
.elIcon-w {
  display: inline-block;
  position: absolute;
  width: 20px;
  left: 85px;
  top: 32px;
}
.el-icon {
  float: left;
  height: 8px;
  color: #c0c4cc;
}
.operate {
  margin-bottom: 20px;
}
</style>