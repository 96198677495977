<template>
  <div class="banned">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="当日封号汇总表" name="first">
        <banned-collect v-if="bannedCollectShow"></banned-collect>
      </el-tab-pane>
      <el-tab-pane label="当日销售封号明细表" name="second">
        <banned-detail v-if="bannedDetailShow"></banned-detail>
      </el-tab-pane>
      <el-tab-pane label="月封号率折线图" name="third">
        <banned-line v-if="bannedLineShow"></banned-line>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import bannedCollect from "./bannedCollect.vue";
import bannedDetail from "./bannedDetail.vue";
import bannedLine from "./bannedLine.vue";

export default {
  name: "banned",
  components: {
    bannedCollect,
    bannedDetail,
    bannedLine,
  },
  data() {
    return {
      activeName: "first",
      bannedCollectShow: true,
      bannedDetailShow: false,
      bannedLineShow: false,
    };
  },
  methods: {
    handleClick() {
      if (this.activeName === "first") {
        this.bannedCollectShow = true;
        this.bannedDetailShow = false;
        this.bannedLineShow = false;
      }
      if (this.activeName === "second") {
        this.bannedCollectShow = false;
        this.bannedDetailShow = true;
        this.bannedLineShow = false;
      }
      if (this.activeName === "third") {
        this.bannedCollectShow = false;
        this.bannedDetailShow = false;
        this.bannedLineShow = true;
      }
    },
  },
};
</script>

<style>
</style>