<template>
  <div class="call">
    <div class="nav">
      <div class="calleeNumber">
        客户编号：
        <el-input
          v-model="condition.customerCode"
          placeholder="多个以逗号分割"
          clearable
        ></el-input>
      </div>
      <div class="callerNumber">
        坐席编号：
        <el-input
          v-model="condition.clientNumber"
          placeholder="请输入坐席编号"
          clearable
        ></el-input>
      </div>
      <div class="callerNumber" style="width: 530px">
        完成时间：
        <el-date-picker
          class="startTime"
          v-model="condition.callStartAtStart"
          type="datetime"
          placeholder="起始时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        ></el-date-picker>
        -
        <el-date-picker
          class="endTime"
          v-model="condition.callStartAtEnd"
          type="datetime"
          placeholder="终止时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="callerNumber">
        批次编号：
        <el-input
          v-model="condition.batchNumber"
          placeholder="请输入批次编号"
          clearable
        ></el-input>
      </div>
      <div class="callerNumber">
        项目列表：
        <el-select
          v-model="condition.projectId"
          filterable
          remote
          :loading="loading"
          :remote-method="remoteMethod"
          @focus="projectIdRemoteFocus"
          placeholder="请选择项目名称"
          @change="projectIdChange"
          clearable
        >
          <el-option
            v-for="item in projectNameList"
            :key="item.id"
            :value="item.id"
            :label="item.projectName"
          ></el-option>
        </el-select>
      </div>
      <div class="callerNumber" style="width: 530px">
        成单时间：
        <el-date-picker
          class="startTime"
          v-model="condition.flagModifiedAtStart"
          type="datetime"
          placeholder="起始时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        ></el-date-picker>
        -
        <el-date-picker
          class="endTime"
          v-model="condition.flagModifiedAtEnd"
          type="datetime"
          placeholder="终止时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="callerNumber" v-if="roleMenu == 'SUPER_MANAGER'">
        用户名称：
        <el-select
          v-model="condition.userName"
          filterable
          remote
          :loading="loadingName"
          :remote-method="NameRemoteMethod"
          placeholder="请选择用户名称"
          @change="loginNameChange"
          @focus="nameRemoteFocus"
          clearable
        >
          <el-option
            v-for="item in userNameList"
            :key="item.id"
            :value="item.loginName"
            :label="item.label"
          ></el-option>
        </el-select>
      </div>
      <div class="callerNumber">
        任务列表：
        <el-select
          v-model="condition.taskId"
          filterable
          remote
          :loading="loadingTask"
          :remote-method="taskRemoteMethod"
          @focus="taskRemoteFocus"
          placeholder="请选择任务名称"
          @change="taskIdChange"
          clearable
        >
          <el-option
            v-for="item in taskNameList"
            :key="item.id"
            :value="item.id"
            :label="item.taskName"
          ></el-option>
        </el-select>
      </div>
      <div class="callerNumber">
        队列名称：
        <!-- <div class="callerNumber" :class="roleMenu=='SUPER_MANAGER' ? 'callerNumberWidth' : ''">队列名称： -->
        <el-select
          v-model="condition.teamId"
          placeholder="请选择队列名称"
          :disabled="teamSelectDisabled"
          clearable
        >
          <el-option
            v-for="item in teamNameList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>
      <!-- <div class="callerNumber" >城市名称：
        <el-select v-model="condition.cityId" filterable placeholder="请选择城市" multiple>
          <el-option
          v-for="item in cityList"
          :key="item.id"
          :value="item.tagName"
          :label="item.tagName"
          ></el-option>
        </el-select>
      </div> -->
      <!-- <div :class="roleMenu=='SUPER_MANAGER' ? 'callerNumber' : 'callSecondsTime'" v-if="roleMenu=='SUPER_MANAGER'"> -->
      <div class="callSecondsTime" v-if="roleMenu == 'SUPER_MANAGER'">
        旗帜选择：
        <el-select
          v-model="condition.flagslist"
          multiple
          placeholder="请选择旗帜"
          @change="flagPitchOn"
        >
          <el-option
            v-for="item in flagsFilter"
            :key="item.id"
            :value="item.id"
            :label="item.label"
          >
            <span><img :src="item.src" width="18px" /></span>
            <span>{{ item.label }}</span>
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- <div class="operate"> -->
    <div :class="{ operate: roleMenu !== 'SUPER_MANAGER' }">
      <el-button type="primary" @click="inquire(1)">查询</el-button>
      <el-button
        type="primary"
        @click="downloadForm()"
        :disabled="downloadFormDisabled"
        v-if="accountType !== 'clone'"
        >下载表单</el-button
      >
      <el-button
        type="primary"
        @click="exportRecording()"
        :disabled="exportRecordDisabled"
        v-if="accountType !== 'clone'"
        >导出录音</el-button
      >
      <el-button type="text" @click="exportRecord()">{{
        voiceFileNameLabel
      }}</el-button>
      <el-button type="text" @click="selfFiltering()">{{
        selfFiltertext
      }}</el-button>
    </div>
    <!-- 问卷列表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      class="answer-class"
      v-loading="loadingTable"
    >
      <el-table-column
        prop="batchNumber"
        label="批次编号"
        width="90"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="customerCode"
        label="客户编号"
        width="130"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="clientNumber"
        label="坐席编号"
        width="130"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="clientName"
        label="坐席名称"
        width="130"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="callSeconds"
        label="通话时长"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerProvince"
        label="归属地-省"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerCity"
        label="归属地-城市"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callEndAt"
        label="完成时间"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="flagModifiedAt"
        label="成单时间"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column
        prop
        :label="item.question"
        v-for="(item, key) in header"
        :key="key"
        :width="flexColumnWidth(item, item.questionType)"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div
            v-if="
              questionTypeList.indexOf(item.questionType) > -1 ? true : false
            "
          >
            <div v-for="(val, key) in scope.row.answerMap" :key="key">
              <span
                v-show="item.id == key"
                v-if="
                  answerInputList.indexOf(item.questionType) > -1 ? true : false
                "
                >{{ val }}</span
              >
              <span
                v-show="item.id == key"
                v-if="item.questionType == 'CHECKBOX'"
                >{{ scope.row.option["option_" + item.id] }}</span
              >
            </div>
          </div>
          <div v-for="(menu, index) in item.optionList" :key="index">
            <span v-if="item.questionType == 'RADIO'">
              <span v-show="scope.row.option['option_' + item.id] == menu.id">{{
                menu.option
              }}</span>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop
        label="操作"
        width="160"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="playRecord(scope.row)"
            :disabled="scope.row.playVoiceDisabled"
            >播放录音</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="downloadVoice(scope.row)"
            :disabled="scope.row.playVoiceDisabled"
            >下载录音</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 播放录音 -->
    <el-dialog
      class="customDialog"
      :visible.sync="playRecordShow"
      width="300px"
      @close="closeDialog"
    >
      <audio
        ref="audio"
        :src="currentSrc"
        autoplay="autoplay"
        controlsList="nodownload"
        controls="controls"
      ></audio>
    </el-dialog>
    <!-- 录音格式 -->
    <el-dialog
      title="录音格式"
      :visible.sync="recordShow"
      width="500px"
      @close="closeRecord"
    >
      <template slot="title">
        <i class="el-icon-download dialog-title-my" style="color: #1296db"></i>
        <span class="dialog-title-my my-title">录音格式</span>
      </template>
      <el-form :inline="true" label-width="100px">
        <el-form-item label="录音格式:">
          <el-select
            v-model="voiceFileNameFormat"
            placeholder="请选择录音格式"
            clearable
          >
            <el-option
              v-for="item in voiceFileNameFormatList"
              :key="item.id"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="voiceFileNameChange"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 下载提示 -->
    <el-dialog
      :visible.sync="downloadTips"
      width="300px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeDownload"
      class="download-tips"
    >
      <div class="download-div">
        <i class="el-icon-loading loadingClass"></i>
        <span class="download-text">文件正在下载中...</span>
      </div>
    </el-dialog>
    <!-- 自定义过滤 -->
    <el-dialog
      :visible.sync="selfFilterShow"
      title="自定义过滤"
      width="500px"
      @close="selfFilterClose"
      class="selfFilterClass"
    >
      <template slot="title">
        <i
          class="el-icon-edit-outline dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">自定义过滤</span>
      </template>
      <el-form label-width="140px" label-position="right">
        <el-form-item
          v-for="item in projectQuestionList"
          :key="item.id"
          :label="item.question"
        >
          <el-input
            v-if="
              item.questionType == 'INPUT' ||
              item.questionType == 'TEXT' ||
              item.questionType == 'DISTRICT_SELECTOR' ||
              item.questionType == 'CAR_BRAND_SELECTOR'
            "
            :placeholder="'请输入' + item.question"
            v-model="options[item.id]"
            class="question_input"
          ></el-input>
          <el-select
            v-if="
              item.questionType == 'RADIO' || item.questionType == 'CHECKBOX'
            "
            v-model="optionCheckList[item.id]"
            multiple
            :placeholder="'请选择' + item.question"
            clearable
          >
            <el-option
              v-for="menu in item.optionList"
              :key="menu.id"
              :value="menu.id"
              :label="menu.option"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="selfFilterClick">确 定</el-button>
        <el-button @click="unselfFilterClick">取 消</el-button>
        <el-button @click="clearance">清空条件</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "answerList",
  data() {
    return {
      questionTypeList: [
        "INPUT",
        "TEXT",
        "DISTRICT_SELECTOR",
        "CAR_BRAND_SELECTOR",
        "CHECKBOX",
      ],
      answerInputList: [
        "INPUT",
        "TEXT",
        "DISTRICT_SELECTOR",
        "CAR_BRAND_SELECTOR",
      ],
      cityList: [],
      teamNameList: [],
      loadingTable: false,
      selfFiltertext: "自定义过滤",
      optionCheckList: {},
      selfFilterShow: false,
      projectQuestionList: [],
      diyQuestionFilterList: [], // 自定意过滤问题筛选列表
      options: {},
      flagsFilter: [
        { src: require("@/assets/img/flag_1.png"), label: "成单", id: "1" },
        { src: require("@/assets/img/flag_2.png"), label: "回拨", id: "2" },
        {
          src: require("@/assets/img/flag_3.png"),
          label: "非目标客群",
          id: "3",
        },
        { src: require("@/assets/img/flag_4.png"), label: "无意向", id: "4" },
      ],
      downloadTips: false,
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
      },
      loading: false,
      loadingTask: false,
      loadingName: false,
      flagList: [],
      downloadFormDisabled: true,
      exportRecordDisabled: true,
      optionTable: [],
      header: [],
      callId: "",
      tableData: [],
      currentPage: null,
      total: null,
      callStatus_show: false,
      cancelShow: false,
      playRecordShow: false,
      currentSrc: null, //录音url
      playFlag: true, //播放状态
      projectNameList: [],
      taskNameList: [],
      userNameList: [],
      headerId: {},
      condition: {
        cityId: [],
        projectId: "",
        customerCode: "",
        clientNumber: "",
        taskId: "",
        flagslist: [],
        callStartAtStart: "",
        callStartAtEnd: "",
        userName: "",
        batchNumber: "",
        flagModifiedAtStart: "",
        flagModifiedAtEnd: "",
        teamId: "",
      },
      registerName: "",
      pageSize: 10,
      recordShow: false,
      voiceFileNameFormatList: [
        {
          id: 1,
          value: "format1",
          label: "日期_坐席编号_客户编号",
        },
        {
          id: 2,
          value: "format3",
          label: "日期^客户编号",
        },
        {
          id: 3,
          value: "format2",
          label: "日期_坐席编号_手机号码",
        },
        {
          id: 4,
          value: "format4",
          label: "日期_手机号",
        },
        {
          id: 5,
          value: "format5",
          label: "900",
        },
        {
          id: 6,
          value: "format6",
          label: "手机号",
        },
      ],
      voiceFileNameFormat: "",
      voiceFileNameLabel: "录音格式",
      downloadTimer: null,
      downloadId: null,
      server: "",
    };
  },
  props: ["roleMenu", "loginName", "accountType"],
  computed: {
    teamSelectDisabled() {
      // 是否禁用队列名称
      if (this.roleMenu === "SUPER_MANAGER" && !this.condition.userName) {
        return true;
      } else {
        return false;
      }
    },
    isTeam() {
      // 是否为超级管理员，查询用户名称时查询队列
      if (this.roleMenu === "SUPER_MANAGER") {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    document.title = "惠销平台管理系统-问卷列表";
    this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    let strDate = new Date();
    strDate.setHours(0);
    strDate.setMinutes(0);
    strDate.setSeconds(0);
    strDate.setMilliseconds(0);
    strDate = this.$common.transformTime(strDate.valueOf());
    this.condition.callStartAtStart = strDate;
    this.condition.flagModifiedAtStart = strDate;
    this.inquirelabel();
  },
  methods: {
    inquirelabel() {
      //查询城市
      this.$http("/prospectiveCustomer/cityList", null).then((res) => {
        if (res.result == 200) {
          this.cityList = res.data;
        }
      });
    },
    // 查询队列
    getTeamList(data) {
      this.$http("/sip/team/list", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.teamNameList = list;
        }
      });
    },
    //清空自定意过滤条件
    clearance() {
      this.options = {};
      this.optionCheckList = {};
    },
    //查询问题
    projectQuestionWithCache(projectId) {
      this.diyQuestionFilterList = [];
      let params = {
        projectId: projectId,
      };
      this.$http(
        "/sip/question/projectQuestionList",
        null,
        "get",
        null,
        params
      ).then((res) => {
        if (res.result == 200) {
          this.optionCheckList = {};
          this.projectQuestionList = res.data.sipQuestionList;
          if (this.projectQuestionList) {
            this.projectQuestionList.map((item) => {
              if (
                item.questionType == "CHECKBOX" ||
                item.questionType == "RADIO"
              ) {
                this.$set(this.optionCheckList, item.id, []);
              }
            });
          }
        }
      });
    },
    //点击自定意过滤
    selfFiltering() {
      let isHttp = false;
      if (this.condition.projectId) {
        isHttp = true;
      }
      if (isHttp) {
        // 根据自定意过滤问题筛选列表来填充自定意过滤表单
        this.selfFilterShow = true;
        if (this.diyQuestionFilterList.length > 0) {
          let list = this.diyQuestionFilterList;
          for (const i in list) {
            if (list[i].type === "INPUT") {
              this.options[list[i].questionId] = list[i].answer;
            } else if (list[i].type === "CHECKBOX") {
              let arr = list[i].answer.split(",");
              let arr1 = [];
              if (list[i].answer) {
                arr.map((c) => {
                  c = Number(c);
                  arr1.push(c);
                });
              }
              this.$set(this.optionCheckList, list[i].questionId, arr1);
            }
          }
        }
      } else {
        this.$message({ message: "请选择项目", type: "warning" });
      }
    },
    //确定自定意过滤
    selfFilterClick() {
      this.diyQuestionFilterList = []; // 自定意过滤问题筛选列表
      let list = this.projectQuestionList;
      let textList = []; // 显示自定义过滤文字
      for (let i in this.options) {
        // INPUT类型
        if (this.options[i]) {
          list.forEach((c) => {
            if (c.id == i) {
              textList.push(c.question + ":" + this.options[i]);
            }
          });
          let data = {
            questionId: i,
            type: "INPUT",
            answer: this.options[i],
          };
          this.diyQuestionFilterList.push(data);
        }
      }
      for (let j in this.optionCheckList) {
        // CHECKBOX类型
        if (this.optionCheckList[j].length > 0) {
          list.forEach((k) => {
            if (k.id == j) {
              let text = [];
              k.optionList.forEach((d) => {
                this.optionCheckList[j].forEach((a) => {
                  if (a == d.id) {
                    text.push(d.option);
                  }
                });
              });
              textList.push(k.question + ":" + text);
            }
          });
          let item = {
            questionId: j,
            type: "CHECKBOX",
            answer: this.optionCheckList[j].join(","),
          };
          this.diyQuestionFilterList.push(item);
        }
      }
      if (textList.length > 0) {
        let selfText = textList.join("|");
        this.selfFiltertext = selfText;
        if (selfText.length > 30) {
          // 长度超过30个字符
          this.selfFiltertext = selfText.substr(0, 30) + "...";
        }
      } else {
        this.selfFiltertext = "自定义过滤";
      }
      this.selfFilterShow = false;
    },
    //取消自定意过滤
    unselfFilterClick() {
      this.selfFilterShow = false;
    },
    //关闭自定意过滤
    selfFilterClose() {
      // this.projectQuestionList = []
    },
    //关闭下载提示
    closeDownload() {
      if (this.downloadTimer) {
        clearInterval(this.downloadTimer);
        this.downloadTimer = null;
      }
    },
    //刷新下载状态
    downloadStatus() {
      this.downloadTimer = setInterval(() => {
        let path = "https://" + this.server + this.$constants.basePath;
        this.$http("/sip/downloadFile/checkDownloadStatus", null, "get", path, {
          id: this.downloadId,
        }).then((res) => {
          if (res.result == 200) {
            let status = res.data;
            if (status === "DOWNLOADABLE") {
              // let href = this.$constants.baseURL+"/sip/downloadFile/download?id="+this.downloadId;
              // window.location.href = href;
              this.$http(
                "/sip/downloadFile/getRemoteDownloadUrl",
                null,
                "get",
                path,
                { id: this.downloadId }
              ).then((res) => {
                clearInterval(this.downloadTimer);
                this.downloadTimer = null;
                this.downloadTips = false;
                if (res.result == 200) {
                  let href = res.data;
                  window.location.href = href;
                }
              });
            } else if (status === "EXCEPTION") {
              clearInterval(this.downloadTimer);
              this.downloadTimer = null;
              this.downloadTips = false;
              this.$message.error("下载失败");
            }
          } else {
            this.downloadTips = false;
            clearInterval(this.downloadTimer);
            this.downloadTimer = null;
          }
        });
      }, 2000);
    },
    flexColumnWidth(val, type) {
      //表格列宽
      let flexWidth = null;
      let widthList = [];
      if (this.tableData.length > 0) {
        this.tableData.forEach((item) => {
          // 问卷有值
          if (item.answerMap) {
            for (let i in item.answerMap) {
              if (val.id == i) {
                // 有答案
                if (val.question.length >= item.answerMap[i].length) {
                  // 表头和内容字数一样按表头计算
                  flexWidth = this.$common.calculatedLength(val.question);
                  if (flexWidth < 100) {
                    // 最小值100
                    flexWidth = 100;
                  }
                  widthList.push(flexWidth);
                } else {
                  flexWidth = this.$common.calculatedLength(item.answerMap[i]); // 按内容计算
                  if (flexWidth < 100) {
                    flexWidth = 100;
                  }
                  widthList.push(flexWidth);
                }
              } else {
                // 无答案
                flexWidth = this.$common.calculatedLength(val.question); // 按表头计算
                if (flexWidth < 80) {
                  flexWidth = 80;
                }
                widthList.push(flexWidth);
              }
            }
          } else {
            // 有表头无答案
            flexWidth = this.$common.calculatedLength(val.question); // 按表头计算
            if (flexWidth < 80) {
              flexWidth = 80;
            }
            widthList.push(flexWidth);
          }
        });
      } else {
        // 问卷无值
        flexWidth = this.$common.calculatedLength(val.question); // 按表头计算
        if (flexWidth < 80) {
          flexWidth = 80;
        }
        widthList.push(flexWidth);
      }
      widthList.sort((a, b) => b - a);
      return widthList[0] + 20 + "px";
    },
    // 用户名称获取焦点
    nameRemoteFocus() {
      this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
    },
    NameRemoteMethod(userName) {
      //搜索用户名称
      this.loadingName = true;
      setTimeout(() => {
        this.loadingName = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          userName: userName,
          enabled: true,
        };
        this.inquireLoginName(params);
      }, 200);
    },
    // 项目搜索获取焦点
    projectIdRemoteFocus() {
      let params = {
        pageSize: 20,
        pageNo: 1,
        loginName: this.condition.userName,
      };
      this.inquireProject(params);
    },
    remoteMethod(projectName) {
      //搜索项目
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          loginName: this.condition.userName,
          projectName: projectName,
        };
        this.inquireProject(params);
      }, 200);
    },
    // 任务搜索获取焦点
    taskRemoteFocus() {
      let params = {
        pageSize: 1000,
        pageNo: 1,
        loginName: this.condition.userName,
        projectId: this.condition.projectId,
      };
      this.inquireTask(params);
    },
    taskRemoteMethod(taskName) {
      //搜索任务
      this.loadingTask = true;
      setTimeout(() => {
        this.loadingTask = false;
        let params = {
          pageSize: 20,
          pageNo: 1,
          taskName: taskName,
          loginName: this.condition.userName,
          projectId: this.condition.projectId,
        };
        this.inquireTask(params);
      }, 200);
    },
    currentPage_data(index) {
      //当前页
      this.inquire(index);
    },
    sizeChange(index) {
      //显示条数--分页
      this.pageSize = index;
      this.inquire(1);
    },
    inquire(index) {
      //查询列表
      if (this.roleMenu === "SUPER_MANAGER") {
      } else {
        this.condition.flagslist = ["1"];
      }
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        taskId: this.condition.taskId,
        customerCodes: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        teamId: this.condition.teamId,
        callStartAtStart: this.condition.callStartAtStart,
        callStartAtEnd: this.condition.callStartAtEnd,
        flagModifiedAtStart: this.condition.flagModifiedAtStart,
        flagModifiedAtEnd: this.condition.flagModifiedAtEnd,
        projectId: this.condition.projectId,
        loginName: this.condition.userName,
        batchNumber: this.condition.batchNumber,
        voiceFileNameFormat: this.voiceFileNameFormat,
        flagList: this.condition.flagslist,
        diyQuestionFilterList: this.diyQuestionFilterList,
      };
      this.inquireAnswerList(params);
    },
    inquireAnswerList(params) {
      //查询问卷列表
      if (this.registerName) {
        params.loginName = this.registerName;
      }
      let isHttp = false;
      if (params.taskId) {
        isHttp = true;
      }
      if (params.projectId) {
        isHttp = true;
      }
      if (params.batchNumber) {
        isHttp = true;
      }
      if (isHttp) {
        this.loadingTable = true;
        let path = "https://" + this.server + this.$constants.basePath;
        this.$service(
          "/sip/downloadFile/answerList",
          params,
          "post",
          path
        ).then((res) => {
          setTimeout(() => {
            this.loadingTable = false;
          }, 200);
          if (res.result == 200) {
            let list = res.data.list;
            this.header = res.data.header;
            this.total = res.data.count;
            let str = "";
            res.data.header.map((menu) => {
              this.headerId[menu.id] = menu.questionType;
            });
            list.map((item) => {
              //数据转换
              item.customerAttribution = "";
              if (item.customerProvince && item.customerCity) {
                item.customerAttribution =
                  item.customerProvince + " " + item.customerCity;
              }
              item.option = {};
              item.arrAnswer = [];
              if (item.answerMap) {
                for (let key in item.answerMap) {
                  item.option["option_" + key] = item.answerMap[key];
                  if (this.headerId[key] == "CHECKBOX") {
                    item.option["option_" + key] =
                      item.answerMap[key].split(",");
                    this.header.map((h) => {
                      if (h.id == key) {
                        for (let i in h.optionList) {
                          for (let j in item.option["option_" + key]) {
                            if (
                              h.optionList[i].id ==
                              item.option["option_" + key][j]
                            ) {
                              item.option["option_" + key][j] =
                                h.optionList[i].option;
                            }
                          }
                        }
                      }
                    });
                    item.option["option_" + key] =
                      item.option["option_" + key].join("-");
                    // item.arrAnswer.push(item.option['option_'+key]);
                  }
                }
              }
              item.callEndAt = this.$common.transformTime(item.callEndAt);
              item.flagModifiedAt = this.$common.transformTime(
                item.flagModifiedAt
              );
              item.callAnsweredAt = this.$common.transformTime(
                item.callAnsweredAt
              );
              item.callReceivedAt = this.$common.transformTime(
                item.callReceivedAt
              );
              if (item.callSeconds == -1) {
                item.callSeconds = "-";
              } else {
                item.callSeconds = `${item.callSeconds} 秒`;
              }
              if (item.ringSeconds == -1) {
                item.ringSeconds = "-";
              } else {
                item.ringSeconds = `${item.ringSeconds} 秒`;
              }
              if (item.distributeSeconds == -1) {
                item.distributeSeconds = "-";
              } else {
                item.distributeSeconds = `${item.distributeSeconds} 秒`;
              }
              item.playVoiceDisabled = true;
              switch (item.callStatus) {
                case "UNALLOCATED_NUMBER": {
                  item.callStatus = "未分配号码";
                  break;
                }
                case "RINGING":
                  item.callStatus = "响铃中";
                  break;
                case "USER_BUSY":
                  item.callStatus = "用户拒接";
                  break;
                case "NO_ANSWER":
                  item.callStatus = "无人接听";
                  break;
                case "ORIGINATOR_CANCEL":
                  item.callStatus = "拨打取消";
                  break;
                case "TALKING":
                  item.callStatus = "通话中";
                  break;
                case "NORMAL_CLEARING":
                  item.callStatus = "拨打完成";
                  item.playVoiceDisabled = false;
                  break;
                case "SYSTEM_SHUTDOWN":
                  item.callStatus = "系统挂断";
                  break;
                case "NO_USER_RESPONSE":
                  item.callStatus = "线路未响应";
                  break;
                case "NO_ROUTE_DESTINATION":
                  item.callStatus = "线路未响应";
                  break;
                case "SUBSCRIBER_ABSENT":
                  item.callStatus = "线路未响应";
                  break;
                case "MEDIA_TIMEOUT":
                  item.callStatus = "超时";
                  break;
                case "USER_NOT_REGISTERED":
                  item.callStatus = "坐席未注册";
                  break;
                case "WAITING_OUTCALL":
                  item.callStatus = "等待拨打";
                  break;
                case "CALLING":
                  item.callStatus = "拨打中";
                  break;
                case "TIMEOUT_CLEARING":
                  item.callStatus = "转接超时";
                  break;
                case "RECOVERY_ON_TIMER_EXPIRE":
                  item.callStatus = "拨打超限";
                  break;
                case "PARK":
                  item.callStatus = "等待转接";
                  break;
                case "NORMAL_TEMPORARY_FAILURE":
                  item.callStatus = "异常";
                  break;
                case "EXCEPTION":
                  item.callStatus = "异常";
                  break;
                case "OUT_OF_FEE":
                  item.callStatus = "欠费";
                  break;
              }
              switch (item.distributed) {
                case true:
                  item.distributed = "是";
                  break;
                case false:
                  item.distributed = "否";
                  break;
              }
              switch (item.connected) {
                case true:
                  item.connected = "是";
                  break;
                case false:
                  item.connected = "否";
                  break;
              }
            });
            this.$nextTick(() => {
              this.tableData = list;
              if (this.tableData && this.tableData.length > 0) {
                this.downloadFormDisabled = false;
                this.exportRecordDisabled = false;
              } else {
                this.downloadFormDisabled = true;
                this.exportRecordDisabled = true;
              }
            });
          }
        });
      } else {
        this.$message({ message: "请选择项目或任务", type: "warning" });
      }
    },
    playRecord(val) {
      //播放录音
      this.playRecordShow = true;
      if (val) {
        this.callId = val.id;
      }
      let callId = this.callId;
      let path = "https://" + this.server + this.$constants.basePath;
      this.currentSrc = `${path}/sip/playVoice?callId=${callId}&loginName=${val.userLoginName}`;
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        // this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    downloadVoice(val) {
      //下载录音
      let params = {
        callId: val.id,
        loginName: val.userLoginName,
        voiceFileNameFormat: "format1",
      };
      let url = "";
      for (let i in params) {
        if (params[i]) {
          url += i + "=" + params[i] + "&";
        }
      }
      let path = "https://" + this.server + this.$constants.basePath;
      let href = path + "/sip/downloadVoice?" + url;
      window.location.href = href;
    },
    closeDialog() {
      //播放录音关闭
      this.$refs.audio.pause();
      this.playFlag = false;
    },
    inquireLoginName(params) {
      //查询用户名称
      this.userNameList = [];
      this.$http("/user/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newuserNameList = res.data.list;
          newuserNameList.map((item) => {
            item.label = item.userName;
            this.userNameList.push(item);
          });
          if (this.isTeam) {
            // 是否是超级管理员
            let data = {
              pageNo: 1,
              pageSize: 100,
              loginName: this.loginName,
            };
            this.getTeamList(data);
          }
        }
      });
    },
    loginNameChange(userName) {
      //改变用户名称
      this.condition.projectId = "";
      this.condition.taskId = "";
      this.condition.teamId = "";
      if (userName) {
        let data = {
          pageNo: 1,
          pageSize: 100,
          loginName: userName,
        };
        this.getTeamList(data);
      }
    },
    inquireProject(params) {
      //查询项目名称
      this.projectNameList = [];
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newProjectNameList = res.data.list;
          newProjectNameList.map((item) => {
            this.projectNameList.push(item);
          });
        }
      });
    },
    projectIdChange(projectId) {
      //项目名称改变
      this.projectNameList.map((item) => {
        if (item.id == projectId) {
          this.registerName = item.userLoginName;
          this.server = item.server;
        }
      });
      this.condition.taskId = "";
      // let params = {
      //   pageSize:1000,
      //   pageNo:1,
      //   // loginName:this.registerName,
      //   projectId:this.condition.projectId
      // }
      // this.inquireTask(params);
      if (projectId) {
        this.projectQuestionWithCache(this.condition.projectId);
      } else {
        this.options = {};
        this.optionCheckList = {};
      }
    },
    taskIdChange(taskId) {
      //任务名称改变
      this.taskNameList.map((item) => {
        if (item.id == taskId) {
          this.registerName = item.userLoginName;
          this.server = item.server;
        }
      });
    },
    inquireTask(params) {
      //查询任务名称
      this.taskNameList = [];
      this.$http("/sip/task/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newTaskNameList = res.data.list;
          newTaskNameList.map((item) => {
            this.taskNameList.push(item);
          });
        }
      });
    },
    downloadForm() {
      //下载表单
      let params = {
        taskId: this.condition.taskId,
        customerCodes: this.condition.customerCode,
        projectId: this.condition.projectId,
        teamId: this.condition.teamId,
        clientNumber: this.condition.clientNumber,
        callStartAtStart: this.condition.callStartAtStart,
        callStartAtEnd: this.condition.callStartAtEnd,
        flagModifiedAtStart: this.condition.flagModifiedAtStart,
        flagModifiedAtEnd: this.condition.flagModifiedAtEnd,
        loginName: this.condition.userName,
        batchNumber: this.condition.batchNumber,
        flagList: this.condition.flagslist,
        voiceFileNameFormat: this.voiceFileNameFormat,
        category: "ANSWER_EXCEL_LIST",
        diyQuestionFilterList: this.diyQuestionFilterList,
      };
      if (this.registerName) {
        params.loginName = this.registerName;
      }
      let isHttp = false;
      if (params.taskId) {
        isHttp = true;
      }
      if (params.projectId) {
        isHttp = true;
      }
      if (params.batchNumber) {
        isHttp = true;
      }
      if (isHttp) {
        this.downloadFormDisabled = true;
        let path = "https://" + this.server + this.$constants.basePath;
        this.$service(
          "/sip/downloadFile/addDownloadTask",
          params,
          "post",
          path
        ).then((res) => {
          this.downloadFormDisabled = false;
          if (res.result == 200) {
            this.downloadTips = true;
            this.downloadId = res.data.id;
            this.downloadStatus();
          }
        });
        // let url = '';
        // for(let i in params){
        //   if(params[i]){
        //     url+=(i+'='+params[i]+'&')
        //   }
        // }
        // let href = this.$constants.baseURL+"/sip/call/exportAnswerList?"+url;
        // this.downloadFormDisabled = true;
        // this.$common.promiseDownload(href).then(data=>{
        //   setTimeout(() => {
        //     this.downloadFormDisabled = data;
        //   }, 4000);
        // })
        // window.location.href = href;
      } else {
        this.$message({ message: "请选择项目或任务", type: "warning" });
      }
    },
    exportRecording() {
      //导出录音
      let params = {
        pageSize: 10,
        pageNo: this.currentPage,
        taskId: this.condition.taskId,
        customerCodes: this.condition.customerCode,
        clientNumber: this.condition.clientNumber,
        callStartAtStart: this.condition.callStartAtStart,
        callStartAtEnd: this.condition.callStartAtEnd,
        flagModifiedAtStart: this.condition.flagModifiedAtStart,
        flagModifiedAtEnd: this.condition.flagModifiedAtEnd,
        projectId: this.condition.projectId,
        teamId: this.condition.teamId,
        loginName: this.condition.userName,
        batchNumber: this.condition.batchNumber,
        voiceFileNameFormat: this.voiceFileNameFormat,
        flagList: this.condition.flagslist,
        category: "ANSWER_RECORD_LIST",
        diyQuestionFilterList: this.diyQuestionFilterList,
      };
      if (this.registerName) {
        params.loginName = this.registerName;
      }
      let isHttp = false;
      if (params.taskId) {
        isHttp = true;
      }
      if (params.projectId) {
        isHttp = true;
      }
      if (params.batchNumber) {
        isHttp = true;
      }
      if (isHttp) {
        this.exportRecordDisabled = true;
        let path = "https://" + this.server + this.$constants.basePath;
        this.$service(
          "/sip/downloadFile/addDownloadTask",
          params,
          "post",
          path
        ).then((res) => {
          this.exportRecordDisabled = false;
          if (res.result == 200) {
            this.downloadTips = true;
            this.downloadId = res.data.id;
            this.downloadStatus();
          }
        });
        // let url = '';
        // for(let i in params){
        //   if(params[i]){
        //     url+=(i+'='+params[i]+'&')
        //   }
        // }
        // let href = this.$constants.baseURL+"/sip/recordingFiles/download?"+url;
        // this.exportRecordDisabled = true;
        // this.$common.promiseDownload(href).then(data=>{
        //   setTimeout(() => {
        //     this.exportRecordDisabled = data;
        //   }, 4000);
        // })
        // window.location.href = href;
      } else {
        this.$message({ message: "请选择项目或任务", type: "warning" });
      }
    },
    //选择录音格式
    voiceFileNameChange() {
      this.voiceFileNameLabel = "录音格式";
      if (this.voiceFileNameFormat) {
        switch (this.voiceFileNameFormat) {
          case "format1":
            this.voiceFileNameLabel = "日期_坐席编号_客户编号";
            break;
          case "format2":
            this.voiceFileNameLabel = "日期_坐席编号_手机号码";
            break;
          case "format3":
            this.voiceFileNameLabel = "日期^客户编号";
            break;
          case "format4":
            this.voiceFileNameLabel = "日期_手机号";
            break;
          case "format5":
            this.voiceFileNameLabel = "900";
            break;
          case "format6":
            this.voiceFileNameLabel = "手机号";
            break;
        }
      }
      this.recordShow = false;
    },
    //点击录音格式
    exportRecord() {
      this.recordShow = true;
    },
    //关闭录音格式
    closeRecord() {
      // this.voiceFileNameFormat = ''
    },
    flagPitchOn(val) {
      //flags改变
      let flagList = val;
      // console.log(val)
      this.condition.flagList = flagList;
    },
  },
};
</script>

<style scoped="scoped">
.answer-class >>> .el-tooltip {
  width: 100% !important;
}
.callerTask {
  position: absolute;
  top: 60px;
  left: 650px;
  width: 330px;
}
.el-checkbox-group {
  width: 500px;
  display: inline-block;
}
.iconColor {
  color: #1fbd1f;
}
.call {
  position: relative;
  margin-bottom: 50px;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav {
  width: 1210px;
  position: relative;
}
.callTime {
  position: absolute;
  width: 510px;
  top: 0px;
  left: 650px;
}
.operate {
  /* position: absolute;
    top: 120px;
    left: 0px; */
  /* float: left; */
  clear: both;
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.callerNumberWidth {
  width: 564px !important;
}
.callerNumber,
.calleeNumber,
.callSecondsTime,
.callStatus,
.projectStatus {
  width: 330px;
  margin-bottom: 20px;
}
.nav .el-input,
.nav .el-select {
  width: 200px;
}
.callerNumber,
.calleeNumber {
  text-align: left;
  float: left;
}
.callSecondsTime .call-startTime {
  width: 200px !important;
  margin-right: 6px;
}
.callSecondsTime .call-endTime {
  width: 200px !important;
  margin-left: 7px;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
.question_input {
  width: 217px;
}
.selfFilterClass >>> .el-dialog__body {
  padding-bottom: 0;
}
</style>
