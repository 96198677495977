<template>
  <div class="recording-down">
    <div class="projectStatus">
      <div class="search">
        产品名称：
        <el-input
          v-model="condition.name"
          placeholder="请输入产品名称"
          clearable
        ></el-input>
      </div>
      <div class="search">
        品牌：
        <el-input
          v-model="condition.brand"
          placeholder="请输入品牌"
          clearable
        ></el-input>
      </div>
    </div>
    <div class="projectStatus">
      <div class="search">
        产品编码：
        <el-input
          v-model="condition.code"
          placeholder="请输入产品编码"
          clearable
        ></el-input>
      </div>
      <div class="search">
        价位：
        <!-- <el-input
          v-model="condition.category"
          placeholder="请输入价位"
          clearable
        ></el-input> -->
        <el-select
          v-model="condition.category"
          clearable
          placeholder="请选择价位"
          style="width: 260px"
        >
          <el-option
            v-for="item in categoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <el-button type="primary" @click="inquire()">查询</el-button>
    <!-- 录音列表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingList"
    >
      <el-table-column
        prop="id"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="code"
        label="产品编码"
        width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="name"
        label="产品名称"
        min-width="200"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="price"
        label="价格"
        width="100"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="brand"
        label="品牌"
        width="100"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="category"
        label="价位"
        width="100"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="gmtCreated"
        label="创建时间"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column
        prop
        label="操作"
        width="70"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editRecord(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 编辑产品 -->
    <el-dialog
      title="编辑产品"
      :visible.sync="productShow"
      class="el-recharge"
      width="550px"
      @close="closeproductShow"
    >
      <template slot="title">
        <i class="el-icon-edit dialog-title-my" style="color: #1296db"></i>
        <span class="dialog-title-my my-title">编辑产品</span>
      </template>
      <el-form label-width="100px" class="el-form">
        <el-form-item label="产品名称：">
          <el-input v-model="name" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品编码：">
          <el-input
            disabled
            v-model="code"
            placeholder="请输入产品编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌：">
          <el-input v-model="brand" placeholder="请输入品牌"></el-input>
        </el-form-item>
        <el-form-item label="价格：">
          <el-input v-model="price" placeholder="请输入价格"></el-input>
        </el-form-item>
        <el-form-item label="价位：">
          <!-- <el-input v-model="category" placeholder="请输入价位"></el-input> -->
          <el-select
            v-model="category"
            clearable
            placeholder="请选择价位"
            style="width: 100%"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <div class="button-center">
          <el-button type="primary" @click="product_submit()">确 定</el-button>
          <el-button @click="productShow = false">取 消</el-button>
        </div> -->
      </el-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <el-button type="primary" @click="product_submit()">确 定</el-button>
          <el-button @click="productShow = false">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentPage: null,
      total: null,
      pageSize: 10,
      condition: {
        code: "",
        name: "",
        brand: "",
        category: "",
      },
      loadingList: false,
      productShow: false,
      code: "",
      name: "",
      brand: "",
      price: "",
      category: "",
      productId: "",
      categoryList: [
        {
          label: "低端",
          value: "低端",
        },
        {
          label: "中端",
          value: "中端",
        },
        {
          label: "高端",
          value: "高端",
        },
      ],
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-白酒订单";
    this.inquire(1);
    // this.inquireLoginName({ pageNo: 1, pageSize: 20, enabled: true });
  },
  methods: {
    // 查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let params = {
        pageNo: index || this.currentPage,
        pageSize: this.pageSize,
        code: this.condition.code,
        name: this.condition.name,
        brand: this.condition.brand,
        category: this.condition.category,
      };
      this.recordInquire(params);
    },
    // 查询白酒订单
    recordInquire(params) {
      this.loadingList = true;
      this.$http("/liquor/productPage", null, "get", null, params).then(
        (res) => {
          setTimeout(() => {
            this.loadingList = false;
          }, 200);
          if (res.result == 200) {
            let list = res.data.records;
            this.total = res.data.total;
            list.forEach((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              //   item.downDis = true;
            });
            this.tableData = list;
          }
        }
      );
    },
    // 分页显示条数
    sizeChange(index) {
      this.pageSize = index;
      this.currentPage = 1;
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        code: this.condition.code,
        name: this.condition.name,
        brand: this.condition.brand,
        category: this.condition.category,
      };
      this.recordInquire(params);
    },
    // 当前页
    currentPage_data(index) {
      let params = {
        pageSize: this.pageSize,
        pageNo: index,
        code: this.condition.code,
        name: this.condition.name,
        brand: this.condition.brand,
        category: this.condition.category,
      };
      this.recordInquire(params);
    },
    // 点击编辑
    editRecord(val) {
      this.productShow = true;
      this.code = val.code;
      this.name = val.name;
      this.brand = val.brand;
      this.price = val.price;
      this.category = val.category;
      this.productId = val.id;
    },
    product_submit() {
      let data = {
        id: this.productId,
        code: this.code,
        name: this.name,
        brand: this.brand,
        price: this.price,
        category: this.category,
      };
      this.$service("/liquor/editProduct", data).then((res) => {
        if (res.result === 200) {
          this.productShow = false;
          this.$message({ message: "编辑成功", type: "success" });
          this.inquire(1);
        }
      });
    },
    closeproductShow() {
      this.code = "";
      this.name = "";
      this.brand = "";
      this.price = "";
      this.category = "";
      this.productId = "";
    },
  },
};
</script>

<style scoped>
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.projectStatus {
  margin-bottom: 20px;
}
.projectStatus .search {
  display: inline-block;
  margin-right: 30px;
}
.projectStatus .el-input {
  /* margin-left: 20px; */
  width: 260px;
}
</style>

