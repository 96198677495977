<template>
  <div class="prologueManage">
    <div class="add-sound-btn">
      <el-button type="primary" @click="addSound">新增</el-button>
    </div>
    <div class="card-class">
      <div v-for="item in prologueList" :key="item.id">
        <el-card class="box-card" :body-style="{ paddingBottom: '0px' }">
          <div slot="header" class="clearfix">
            <span>{{item.title}}</span>
            <a href="javascript:;" @click="deleteClick(item)">
              <i class="el-icon-delete" style="color: #ff0000b0; float: right;"></i>
            </a>
          </div>
          <div class="sound-content">
            <div class="sound-show">{{item.remark}}</div>
            <div class="btn-paly">
              <el-button type="text" class="button" @click="playRecord(item)">播放</el-button>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 新增录音 -->
    <el-dialog
      :visible.sync="addSoundShow"
      custom-class="el-addProject"
      @close="closeAddSound"
      width="500px"
      class="addProjectClass"
    >
      <template slot="title">
        <i class="el-icon-circle-plus-outline dialog-title-my" style="color:#1296db;"></i>
        <span class="dialog-title-my my-title">新增录音</span>
      </template>
      <div class="add-record-content">
        <div class="record-form">
          <span class="title">录音名称:</span>
          <el-input v-model="soundRecordName" placeholder="请输入录音名称"></el-input>
        </div>
        <div class="record-form">
          <span class="title">录音说明:</span>
          <el-input  type="textarea" :rows="4" v-model="soundRecordPrologue" placeholder="请输入录音说明"></el-input>
        </div>
        <div class="soundRecord-class">
          <audio id="soundRecord" controls autoplay controlsList="nodownload" v-show="isShowAuido"></audio>
          <div class="show-sound" v-show="isRecordIng">
            <i class="icon-m" :class="goToRecord ? 'el-icon-microphone': 'el-icon-turn-off-microphone'"></i>
            <div class="text-m">{{recordSoundText}}</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="playRecording()" :disabled="isPlaySound">播放</el-button>
        <el-button @click="startRecording()">{{soundRecordTitle}}</el-button>
        <el-button type="primary" @click="uploadAudio()" :disabled="isUploadSound">上传</el-button>
      </div>
    </el-dialog>
    <!-- 播放录音 -->
    <el-dialog
      class="customDialog"
      :visible.sync="playRecordShow"
      width="300px"
      @close="closePlayRecordShow"
    >
      <audio ref="audio" :src="currentSrc" autoplay="autoplay" controlsList="nodownload" controls="controls"></audio>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "prologueManage",
  props: ['server'],
  data() {
    return {
      isShowAuido: false,
      isRecordIng: false,
      isUploadSound: true,
      soundRecordTitle: "开始录音",
      recordSoundText: "正在录音中...",
      goToRecord: true,
      isSoundReocrd: "start",
      isPlaySound: true,
      addSoundShow: false,
      recorder: null,
      audio: null,
      audioInput: null,
      context: null,
      audioData: {},
      soundRecordName: "",
      soundRecordPrologue: "",
      playRecordShow: false,
      currentSrc: "",
      playFlag: true,
      prologueList: [],
      isAddNext: false
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-开场白管理";
    this.getPrologueList();
  },
  methods: {
    // 查询录音文件
    getPrologueList(){
      let data = {
        pageNo: 1,
        pageSize: 10
      }
      this.$http("/prologue/list",null,"get",null,data).then(res => {
        if (res.result === 200) {
          let list = res.data.records;
          this.isAddNext = false;
          if (list.length >= 5) {
            this.isAddNext = true;
          }
          this.prologueList = list;
        }
      })
    },
    //点击删除
    deleteClick(val) {
      this.$confirm("删除该录音文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning"
      })
      .then(() => {
        let data = {
          id: val.id
        };
        this.$service("/prologue/deletePrologue",data).then(res => {
            if (res.result == 200) {
              this.$message({ message: "删除成功", type: "success" });
              this.getPrologueList();
            } 
          }
        );
      })
      .catch(() => {});
    },
    // 点击新增
    addSound() {
      if (this.isAddNext) {
        this.$message({message: "最多上传5个录音",type: "warning"});
        return
      }
      this.addSoundShow = true;
      this.$nextTick(() => {
        this.audio = document.getElementById("soundRecord");
      })
    },
    // 关闭新增
    closeAddSound() {
      if (this.recorder) {
        this.stop();
        this.clear();
      }
      this.audio.src = "";
      this.isPlaySound = true;
      this.isSoundReocrd = "start";
      this.soundRecordTitle = "开始录音";
      this.audio = null;
      this.recorder = null;
      this.context = null;
      this.audioInput = null;
      this.soundRecordName = "";
      this.soundRecordPrologue = "";
      this.isUploadSound = true;
      this.recordSoundText = "正在录音中...";
      this.goToRecord = true;
      this.isShowAuido = false;
      this.isRecordIng = false;
    },
    // 点击播放录音
    playRecord(val) {
      this.playRecordShow = true;
      this.currentSrc = `${this.$constants.baseURL}/prologue/prePlayPrologue?id=${val.id}`;
      let lastRunTime = Date.now(),
          currentTime = Date.now(),
          protectTime = 100;
      if((currentTime-lastRunTime) < protectTime){
        return;//两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        // this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    // 关闭播放录音
    closePlayRecordShow() {
      //播放录音关闭
      this.$refs.audio.pause();
      this.playFlag = false;
    },
    // 开始录音
    startRecording() {
      this.isShowAuido = false;
      if (this.isSoundReocrd === "start") {
        if (this.recorder) {
          this.stop();
          this.clear();
          this.audio.src = "";
        }
        this.getUserMedia(
          {
            audio: true,
            video: false
          },
          this.gotStream
        );
      } else if (this.isSoundReocrd === "stop") {
        this.stop();
        this.isSoundReocrd = "next";
        this.soundRecordTitle = "继续录音";
        this.recordSoundText = "录音已暂停";
        this.isPlaySound = false;
        this.isUploadSound = false;
        this.goToRecord = false;
      } else if (this.isSoundReocrd === "next") {
        this.again();
        this.isSoundReocrd = "stop";
        this.soundRecordTitle = "暂停录音";
        this.recordSoundText = "正在录音中...";
        this.isPlaySound = true;
        this.isUploadSound = true;
        this.goToRecord = true;
        this.isRecordIng = true;
      }
    },
    // 播放录音--新增
    playRecording() {
      this.isSoundReocrd = "start";
      this.soundRecordTitle = "重新录音";
      this.isShowAuido = true;
      this.isRecordIng = false;
      this.play(this.audio);
    },
    // 上传新增录音
    uploadAudio() {
      if (!this.soundRecordName) {
        return this.$message({message: "请输入录音名称", type: "warning"});
      }
      if (!this.soundRecordPrologue) {
        return this.$message({message: "请输入录音说明", type: "warning"});
      }
      let data = new FormData();
      data.append("title", this.soundRecordName);
      data.append("remark", this.soundRecordPrologue);
      data.append("file", this.getBlob());
      let path = "https://" + this.server + this.$constants.basePath;
      this.$service("/prologue/addPrologue",data,"post",path).then(res => {
        if (res.result === 200) {
          this.addSoundShow = false;
          this.$message({message: "上传成功", type: "success"});
          this.getPrologueList();
        }
      })
    },
    // 兼容处理
    getUserMedia(dictionary, callback) {
      // 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
      // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          var getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia;

          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }

          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      navigator.mediaDevices
        .getUserMedia(dictionary)
        .then(stream => {
          this.gotStream(stream);
        })
        .catch((err) => {
          const { name } = err;
          let errorMessage;
          switch (name) {
            // 用户拒绝
            case 'NotAllowedError':
            case 'PermissionDeniedError':
            errorMessage = '用户已禁止网页调用录音设备';
            break;
            // 没接入录音设备
            case 'NotFoundError':
            case 'DevicesNotFoundError':
            errorMessage = '录音设备未找到';
            break;
            // 其它错误
            case 'NotSupportedError':
            errorMessage = '不支持录音功能';
            break;
            default:
            errorMessage = '录音调用错误';
          }
          this.$message({message: errorMessage, type: "warning"});
          console.log(errorMessage);
        });
    },
    // 创建
    gotStream(stream, config) {
      config = config || {};
      config.sampleBits = config.sampleBits || 8; //采样数位 8, 16
      config.sampleRate = config.sampleRate || 44100 / 6; //采样率(1/6 44100)

      //创建一个音频环境对象
      var audioContext = window.AudioContext || window.webkitAudioContext;
      this.context = new audioContext();
      //将声音输入这个对像
      this.audioInput = this.context.createMediaStreamSource(stream);
      //设置音量节点
      var volume = this.context.createGain();
      this.audioInput.connect(volume);
      //创建缓存，用来缓存声音
      var bufferSize = 4096;
      // 第二个和第三个参数指的是输入和输出都是单声道,2是双声道。
      this.recorder = this.context.createScriptProcessor(bufferSize, 2, 2);
      this.isSoundReocrd = "stop";
      this.soundRecordTitle = "暂停录音";
      this.recordSoundText = "正在录音中...";
      this.isPlaySound = true;
      this.isUploadSound = true;
      this.goToRecord = true;
      this.isRecordIng = true;

      this.audioData = {
        size: 0, //录音文件长度
        buffer: [], //录音缓存
        inputSampleRate: this.context.sampleRate, //输入采样率
        inputSampleBits: 16, //输入采样数位 8, 16
        outputSampleRate: config.sampleRate, //输出采样率
        outputSampleBits: config.sampleBits, //输出采样数位 8, 16
        input: function(data) {
          this.buffer.push(new Float32Array(data));
          this.size += data.length;
        },
        compress: function() {
          //合并压缩
          //合并
          var data = new Float32Array(this.size);
          var offset = 0;
          for (var i = 0; i < this.buffer.length; i++) {
            data.set(this.buffer[i], offset);
            offset += this.buffer[i].length;
          }
          //压缩
          var compression = parseInt(
            this.inputSampleRate / this.outputSampleRate
          );
          var length = data.length / compression;
          var result = new Float32Array(length);
          var index = 0,
            j = 0;
          while (index < length) {
            result[index] = data[j];
            j += compression;
            index++;
          }
          return result;
        },
        encodeWAV: function() {
          var sampleRate = Math.min(
            this.inputSampleRate,
            this.outputSampleRate
          );
          var sampleBits = Math.min(
            this.inputSampleBits,
            this.outputSampleBits
          );
          var bytes = this.compress();
          var dataLength = bytes.length * (sampleBits / 8);
          var buffer = new ArrayBuffer(44 + dataLength);
          var data = new DataView(buffer);

          var channelCount = 1; //单声道
          var offset = 0;

          var writeString = function(str) {
            for (var i = 0; i < str.length; i++) {
              data.setUint8(offset + i, str.charCodeAt(i));
            }
          };

          // 资源交换文件标识符
          writeString("RIFF");
          offset += 4;
          // 下个地址开始到文件尾总字节数,即文件大小-8
          data.setUint32(offset, 36 + dataLength, true);
          offset += 4;
          // WAV文件标志
          writeString("WAVE");
          offset += 4;
          // 波形格式标志
          writeString("fmt ");
          offset += 4;
          // 过滤字节,一般为 0x10 = 16
          data.setUint32(offset, 16, true);
          offset += 4;
          // 格式类别 (PCM形式采样数据)
          data.setUint16(offset, 1, true);
          offset += 2;
          // 通道数
          data.setUint16(offset, channelCount, true);
          offset += 2;
          // 采样率,每秒样本数,表示每个通道的播放速度
          data.setUint32(offset, sampleRate, true);
          offset += 4;
          // 波形数据传输率 (每秒平均字节数) 单声道×每秒数据位数×每样本数据位/8
          data.setUint32(
            offset,
            channelCount * sampleRate * (sampleBits / 8),
            true
          );
          offset += 4;
          // 快数据调整数 采样一次占用字节数 单声道×每样本的数据位数/8
          data.setUint16(offset, channelCount * (sampleBits / 8), true);
          offset += 2;
          // 每样本数据位数
          data.setUint16(offset, sampleBits, true);
          offset += 2;
          // 数据标识符
          writeString("data");
          offset += 4;
          // 采样数据总数,即数据总大小-44
          data.setUint32(offset, dataLength, true);
          offset += 4;
          // 写入采样数据
          if (sampleBits === 8) {
            for (var i = 0; i < bytes.length; i++, offset++) {
              var s = Math.max(-1, Math.min(1, bytes[i]));
              var val = s < 0 ? s * 0x8000 : s * 0x7fff;
              val = parseInt(255 / (65535 / (val + 32768)));
              data.setInt8(offset, val, true);
            }
          } else {
            for (var i = 0; i < bytes.length; i++, offset += 2) {
              var s = Math.max(-1, Math.min(1, bytes[i]));
              data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
            }
          }
          return new Blob([data], { type: "audio/mp3" });
        }
      };

      //音频采集
      this.recorder.onaudioprocess = e => {
        this.audioData.input(e.inputBuffer.getChannelData(0));
        //record(e.inputBuffer.getChannelData(0));
      };

      this.start();
    },
    //开始录音
    start() {
      console.log("录音开始");
      this.audioInput.connect(this.recorder);
      this.recorder.connect(this.context.destination);
    },
    //停止
    stop() {
      console.log("录音停止");
      this.recorder.disconnect();
    },
    //继续
    again() {
      console.log("录音继续");
      this.recorder.connect(this.context.destination);
    },
    //结束
    end() {
      console.log("录音结束");
      this.context.close();
    },
    //获取音频文件
    getBlob() {
      // this.stop();
      return this.audioData.encodeWAV();
    },
    //回放
    play() {
      console.log("录音播放");
      this.audio.src = window.URL.createObjectURL(this.getBlob());
      console.log(this.audio.src);
    },
    // 清除
    clear() {
      this.audioData.buffer = [];
      this.audioData.size = 0;
    },
  }
};
</script>
<style scoped>
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
.sound-content .sound-show {
  font-size: 14px;
  color: #777;
}
.sound-content .btn-paly {
  text-align: right;
}
.add-sound-btn {
  margin-bottom: 20px;
}
.card-class {
  display: flex;
  flex-wrap: wrap;
}
.box-card {
  width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.box-card  >>> .el-card__header {
  padding: 10px 20px;
}
.add-record-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-record-content .record-form {
  margin-bottom: 10px;
  width: 375px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.soundRecord-class {
  width: 300px;
  height: 65px;
}
.add-record-content .show-sound {
  text-align: center;
}
.add-record-content .show-sound .icon-m{
  font-size: 40px;
}
.add-record-content .show-sound .text-m{
  font-size: 16px;
}
.add-record-content .record-form .title {
  font-size: 15px;
}
.add-record-content .record-form .el-input, .add-record-content .record-form .el-textarea {
  width: 290px;
}
</style>

