<template>
  <div class="bannedCollect">
    <!-- <el-button type="primary" @click="inquire()">查询</el-button> -->
    <div class="nav">
      <div class="batchName">
        日期：
        <el-date-picker
          v-model="abnormalGmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
        ></el-date-picker>
      </div>
      <div class="batchName">
        运营：<el-input
          v-model="operators"
          placeholder="请输入运营"
          clearable
        ></el-input>
      </div>
    </div>
    <div class="operate">
      <el-button type="primary" @click="inquire()">查询</el-button>
    </div>
    <!-- 当天封号列表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingList"
    >
      <el-table-column
        type="index"
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="日期"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="illegalCount"
        label="封号机器数"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="workingCount"
        label="跑任务机器数"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="illegalCountRate" label="封号率" align="center">
        <template slot-scope="scope">
          {{ scope.row.illegalCountRate }}%
        </template>
      </el-table-column>
      <!-- <el-table-column prop label="操作" width="70" align="center" fixed="right">
         <template slot-scope="scope">
            <el-button type="text" size="small" @click="downRecord(scope.row,scope.$index)" :disabled="scope.row.downDis">下载</el-button>
         </template>
       </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <!-- <el-pagination
      @size-change="sizeChange"
      @current-change="currentPage_data"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentPage: null,
      total: null,
      pageSize: 10,
      loadingList: false,
      abnormalGmtCreatedDate: [],
      operators: "",
      // gmtCreatedStart: "",
      // gmtCreatedEnd: "",
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    // this.gmtCreatedStart = start;
    // this.gmtCreatedEnd = end;
    this.abnormalGmtCreatedDate = [start, end];
    this.inquire();
  },
  methods: {
    // 查询
    inquire() {
      let params = {
        // pageNo: 1,
        // pageSize: this.pageSize,
        start: this.abnormalGmtCreatedDate[0],
        end: this.abnormalGmtCreatedDate[1],
        operators: this.operators,
      };

      this.recordInquire(params);
    },
    // 当天封号列表
    recordInquire(params) {
      this.loadingList = true;
      this.$http(
        "/exceptionWechatClientReport/count",
        null,
        "get",
        null,
        params
      ).then((res) => {
        setTimeout(() => {
          this.loadingList = false;
        }, 200);
        if (res.result == 200) {
          let list = res.data;
          // this.total = res.data.total;
          this.tableData = list;
        }
      });
    },
    // 分页显示条数
    sizeChange(index) {
      this.pageSize = index;
      this.currentPage = 1;
      let params = {
        pageSize: this.pageSize,
        pageNo: 1,
      };
      this.recordInquire(params);
    },
    // 当前页
    currentPage_data(index) {
      let params = {
        pageSize: this.pageSize,
        pageNo: index,
      };
      this.recordInquire(params);
    },
  },
};
</script>

<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.projectStatus {
  margin-bottom: 20px;
}
.projectStatus .el-input {
  margin-left: 20px;
  width: 260px;
}
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-date-picker {
  width: 260px;
  margin-right: 20px;
}
</style>

