<template>
  <div class="vehicleSelect">
    <div class="car_select">
      <el-form :inline="true">
        <el-form-item label="区域">
          <el-select
            v-model="carArea"
            filterable
            remote
            :loading="carLoading"
            :remote-method="carAreaRemoteMethod"
            placeholder="请选择区域"
            @change="carAreaChange"
            :disabled="disabled"
            size="medium"
          >
            <el-option
              v-for="(item, index) in carAreaList"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌">
          <el-select
            v-model="carBrandSelect"
            filterable
            remote
            :loading="carLoading"
            :remote-method="carBrandSelectRemoteMethod"
            placeholder="请选择品牌"
            @change="carBrandChange"
            :disabled="disabled"
            size="medium"
          >
            <el-option
              v-for="item in carBrandSelectList"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="car_brand">
      <el-form :inline="true">
        <el-form-item label="品牌">
          <el-input
            placeholder="请输入品牌"
            v-model="carBrand"
            @input="pitchOnBrand"
            size="medium"
            :disabled="disabled"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="厂家">
          <el-input
            placeholder="请输入厂家"
            v-model="carManufactor"
            @input="pitchOnManufactor"
            :disabled="disabled"
            readonly
            size="medium"
          ></el-input>
        </el-form-item>
        <el-form-item label="车系">
          <el-input
            placeholder="请输入车系"
            v-model="carSeries"
            @input="pitchOnSeries"
            size="medium"
            :disabled="disabled"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "vehicleSelect",
  props: ["carInformation", "disabled"],
  data() {
    return {
      carArea: "",
      carBrand: "",
      carSeries: "",
      carManufactor: "",
      carLoading: false,
      carAreaList: [],
      carBrandSelect: "",
      carBrandSelectList: [],
      province: "",
      city: "",
    };
  },
  watch: {
    carInformation(val) {
      this.setData(val);
    },
  },
  mounted() {
    this.getCarArea({ provinceOrCity: "" });
  },
  methods: {
    // 查询汽车区域
    getCarArea(data) {
      this.$http("/carProvinceInfo", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.provinceInfo;
          list.forEach((item) => {
            if (item.province === item.city) {
              item.label = item.city;
            } else {
              item.label = item.province + " " + item.city;
            }
          });
          this.carAreaList = list;
        }
      });
    },
    // 搜索汽车区域
    carAreaRemoteMethod(val) {
      let data = {
        provinceOrCity: val,
      };
      this.getCarArea(data);
    },
    // 选择汽车区域
    carAreaChange(val) {
      this.carAreaList.forEach((item) => {
        if (item.label === val) {
          this.province = item.province;
          this.city = item.city;
        }
      });
      this.carBrandSelect = "";
      this.carBrand = "";
      this.carSeries = "";
      this.carManufactor = "";
      let carInfo = val;
      this.$emit("parentObtain", carInfo);
      let data = {
        province: this.province,
        city: this.city,
        type: "",
      };
      this.getCarBrand(data);
    },
    // 查询汽车品牌
    getCarBrand(data) {
      this.$http("/carBrandInfo", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.carBrandInfoList;
          list.forEach((item) => {
            item.label = item.brand + "/" + item.vendor + "/" + item.type;
          });
          this.carBrandSelectList = list;
        }
      });
    },
    // 搜索汽车品牌
    carBrandSelectRemoteMethod(val) {
      let data = {
        province: this.province,
        city: this.city,
        type: val,
      };
      this.getCarBrand(data);
    },
    // 选择汽车品牌
    carBrandChange(val) {
      this.carBrandSelectList.forEach((item) => {
        if (item.label === val) {
          this.carBrand = item.brand;
          this.carManufactor = item.vendor;
          this.carSeries = item.type;
        }
      });
      let carInfo =
        this.carArea +
        "/" +
        this.carBrand +
        "/" +
        this.carManufactor +
        "/" +
        this.carSeries;
      this.$emit("parentObtain", carInfo);
    },
    // 品牌输入
    pitchOnBrand(val) {
      let carInfo =
        this.carArea +
        "/" +
        val +
        "/" +
        this.carManufactor +
        "/" +
        this.carSeries;
      this.$emit("parentObtain", carInfo);
    },
    // 车系输入
    pitchOnSeries(val) {
      let carInfo =
        this.carArea +
        "/" +
        this.carBrand +
        "/" +
        this.carManufactor +
        "/" +
        val;
      this.$emit("parentObtain", carInfo);
    },
    // 厂家输入
    pitchOnManufactor(val) {
      let carInfo =
        this.carArea + "/" + this.carBrand + "/" + val + "/" + this.carSeries;
      this.$emit("parentObtain", carInfo);
    },
    // 输出数据
    setData(val) {
      if (val) {
        this.$emit("parentMethod", val);
        let infoList = val.split("/");
        for (let i in infoList) {
          if (i == 0) {
            this.carArea = infoList[i];
            let area = infoList[i].split(" ");
            if (area.length >= 2) {
              this.province = area[0];
              this.city = area[1];
            } else {
              this.province = area[0];
              this.city = area[0];
            }
            let data = {
              province: this.province,
              city: this.city,
              type: "",
            };
            this.getCarBrand(data);
            this.carBrand = "";
            this.carSeries = "";
            this.carManufactor = "";
            this.carBrandSelect = "";
          }
          if (i == 1) {
            this.carBrand = infoList[i];
          }
          if (i == 2) {
            this.carManufactor = infoList[i];
          }
          if (i == 3) {
            this.carSeries = infoList[i];
          }
        }
        if (this.carBrand && this.carSeries && this.carManufactor) {
          this.carBrandSelect =
            this.carBrand + "/" + this.carManufactor + "/" + this.carSeries;
        }
      } else {
        this.province = "";
        this.city = "";
        this.carArea = "";
        this.carBrandSelect = "";
        this.carBrand = "";
        this.carSeries = "";
        this.carManufactor = "";
      }
      this.getCarArea({ provinceOrCity: "" });
    },
  },
};
</script>
<style scoped>
.vehicleSelect {
  width: 100%;
}
.car_select {
  width: 100%;
}
.car_brand {
  width: 100%;
}
.car_brand >>> .el-input {
  width: 169px;
}
.car_select >>> .el-select {
  width: 169px;
}
</style>
