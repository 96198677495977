<template>
  <div class="customService" :style="{ height: mianHeight + 'px' }">
    <!-- 客服信息 -->
    <div class="nav">
      <div class="client">
        坐席编号：
        <span class="clientNumber">{{ number }}</span>
        <el-tag :type="type" v-show="statusVisible">{{ status }}</el-tag>
        <el-button
          v-if="echoShow"
          type="primary"
          size="small"
          plain
          @click="echoTest"
          >回音测试</el-button
        >
        <div class="setStatus">
          <a
            href="javascript:void(0);"
            v-show="onLineShow"
            @click="setStatusOn"
            :disabled="onLineDis"
            >上线</a
          >
          <a
            href="javascript:void(0);"
            v-show="offLineShow"
            @click="setStatusOff"
            :disabled="offLineDis"
            >下线</a
          >
        </div>
        <el-button
          v-if="isReconnect"
          type="warning"
          size="medium"
          plain
          @click="reconnect()"
          >连接断开，点击重连</el-button
        >
      </div>
      <div class="task">
        当前任务：
        <span class="taskName">{{ taskName }}</span>
        <span class="taskStatus">{{ taskStatus }}</span>
        <span v-if="prologueImgShow" class="kaicb_img">
          <el-tooltip class="item" effect="light" placement="top-start">
            <div slot="content">
              <div>当前任务处于开场白模式，开场白模式下，</div>
              <div>接到电话直接问答即可(不需要说开场白)</div>
            </div>
            <img
              src="@/assets/img/kaicbai.png"
              alt=""
              width="100%"
              style="vertical-align: middle; padding-bottom: 7px"
            />
          </el-tooltip>
        </span>
      </div>
      <span>今日接待：{{ dailyReceived }}</span>
      <div class="notice-class" v-show="noticeShow">
        <el-input
          v-model="notice"
          type="textarea"
          :rows="6"
          placeholder="暂无公告"
          disabled
        ></el-input>
      </div>
    </div>
    <!-- 搜索条件 -->
    <div class="receive">
      <div class="callSeconds">
        旗帜选择：
        <el-select
          v-model="flagsNumber"
          multiple
          placeholder="请选择旗帜"
          @change="acquisition"
          collapse-tags
          size="small"
        >
          <el-option
            v-for="item in flagsFilter"
            :key="item.id"
            :value="item.id"
            :label="item.label"
          >
            <span><img :src="item.src" width="18px" /></span>
            <span>{{ item.label }}</span>
          </el-option>
        </el-select>
      </div>
      <div class="callSeconds">
        拨打时长：
        <el-input
          class="call-startTime"
          v-model="condition.callSecondsStart"
          placeholder="开始"
          size="small"
        ></el-input>
        -
        <el-input
          class="call-endTime"
          v-model="condition.callSecondsEnd"
          placeholder="结束"
          size="small"
        ></el-input>
      </div>
      <div class="sound-file" v-if="inWhitelist">
        <span>开场白：</span>
        <div
          v-for="(item, index) in recordNameList"
          :key="item.id"
          class="sound-file-div"
        >
          <el-tooltip
            class="item"
            effect="light"
            :content="item.remark"
            placement="top"
          >
            <span>{{ item.title }}</span>
          </el-tooltip>
          <div class="icon-sound">
            <i
              v-if="!item.isPlay"
              @click="playSound(item, index)"
              class="el-icon-video-play cursor-m"
              style="color: #67c23a"
              :class="{ disabledColor: otherSound }"
            ></i>
            <i
              v-if="item.isPlay"
              class="el-icon-loading"
              style="color: #409eff"
            ></i>
            <i
              v-if="item.isPlay"
              @click="pauseSound(item, index)"
              class="el-icon-video-pause cursor-m"
              style="color: #f56c6c"
            ></i>
          </div>
        </div>
      </div>
      <div class="callSeconds">
        <el-button type="primary" size="medium" @click="inquire()"
          >查询</el-button
        >
        <el-button type="warning" size="medium" @click="getDraft()"
          >草稿{{ idLength }}</el-button
        >
        <div class="wechat_btn">
          <el-button
            v-if="isAddwechatShow"
            type="primary"
            plain
            size="medium"
            @click="addWechatStatus('addClick')"
            >添加微信</el-button
          >
          <el-button
            v-if="showSendSmsButton"
            plain
            size="medium"
            @click="sendSMSClick"
            >发送短信</el-button
          >
        </div>
      </div>
    </div>
    <!-- 左侧列表 -->
    <div class="item-class">
      <div v-for="item in list" :index="item.id" :key="item.id">
        <div
          class="tag-style"
          :class="{ active: isActive == item.id }"
          @click="can(item)"
        >
          <span class="calleeNumber">{{ item.displayNumber }}</span>
          <span class="attribution"
            >{{ item.customerAttribution }} {{ item.tailNumber }}
            <div class="isAbsolute" v-if="imgCreated[item.id]">
              <div class="flagsImg" v-for="menu in item.flagsList" :key="menu">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="flagLabel[menu]"
                  placement="top-start"
                >
                  <img
                    :src="require('@/assets/img/flag_' + menu + '.png')"
                    width="18px"
                  />
                </el-tooltip>
              </div>
            </div>
            <!-- <span class="tailNumber"></span> -->
          </span>
          <div class="tag-time">
            <p>{{ item.callSeconds }}</p>
            <p>
              <el-button
                type="text"
                size="small"
                @click="callBack(item)"
                v-show="callBackShow['callBack_' + item.id]"
                >回拨</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="queryMobile(item)"
                v-show="queryMobileShow['queryMobile_' + item.id]"
                >查看</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="playRecord(item)"
                v-show="recordShow['record_' + item.id]"
                >播放</el-button
              >
            </p>
            <p>{{ item.callEndAt }}</p>
          </div>
          <i
            class="iconfont icon-waihurenwu"
            v-show="phoneShow['phone_show_' + item.id]"
            @click="hangUp(item.id)"
          ></i>
        </div>
      </div>
    </div>
    <!-- 问题表单 -->
    <div class="container-style" v-show="mainShow">
      <el-form label-position="right" :disabled="containerDisabled">
        <el-form-item
          label="客户分类："
          :label-width="formWidth"
          class="flagImg"
        >
          <el-radio-group
            v-model="flagList"
            @change="flagPitchOn"
            :disabled="flagPitchDisabled"
          >
            <el-radio
              v-for="item in sipFlagSettingList"
              :key="item.id"
              :label="item.flag"
              v-show="flagShow['flag_' + item.id]"
            >
              <img :src="item.flagSrc" />{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-for="item in projectQuestionList"
          :key="item.id"
          :label="item.newQuestion"
          :label-width="formWidth"
        >
          <el-radio-group
            v-if="item.questionType == 'RADIO'"
            v-model="optionId[item.id]"
            @change="
              pitchOn('RADIO', item.id, item.questionCode, item.optionList)
            "
          >
            <el-radio
              v-for="muen in item.optionList"
              :key="muen.id"
              :label="muen.id"
              >{{ muen.option }}</el-radio
            >
          </el-radio-group>

          <el-input
            size="medium"
            v-if="item.questionType == 'INPUT'"
            v-model="optionId[item.id]"
            v-on:input="pitchOn('INPUT', item.id, item.questionCode)"
            :placeholder="'请输入' + item.question"
          ></el-input>

          <el-input
            size="medium"
            v-if="item.questionType == 'TEXT'"
            v-model="optionId[item.id]"
            :rows="4"
            type="textarea"
            resize="none"
            ref="remark"
            :placeholder="'请输入' + item.question"
            v-on:input="pitchOn('TEXT', item.id, item.questionCode)"
          ></el-input>

          <el-checkbox-group
            v-if="item.questionType == 'CHECKBOX'"
            v-model="optionCheckList[item.id]"
          >
            <el-checkbox
              v-for="muen in item.optionList"
              :key="muen.id"
              :label="muen.id"
              @change="pitchOn('CHECKBOX', muen.questionId, item.questionCode)"
              >{{ muen.option }}</el-checkbox
            >
          </el-checkbox-group>

          <area-select
            :disabled="containerDisabled"
            v-if="item.questionType == 'DISTRICT_SELECTOR'"
            style="width: 82%"
            @parentObtain="obtainAddress"
            @parentMethod="getArea"
            :detailedAddress="detailedAddress"
          ></area-select>

          <vehicle-select
            :disabled="containerDisabled"
            v-if="item.questionType == 'CAR_BRAND_SELECTOR'"
            @parentMethod="setCarDetails"
            @parentObtain="getCarDetails"
            :carInformation="carInformation"
          ></vehicle-select>
        </el-form-item>
        <el-form-item :label-width="formWidth">
          <el-button type="primary" @click="onSubmit" :disabled="onSubmitDis">{{
            submitText
          }}</el-button>
          <el-button type="primary" plain @click="historicalChanges"
            >查看历史变更</el-button
          >
          <el-button type="warning" plain @click="draft" v-show="draftShow"
            >读取草稿</el-button
          >
          <el-dropdown trigger="click" @command="otherOperations">
            <el-button type="info" plain>
              其它操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Blacklist"
                >加入黑名单</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>
    </div>
    <!-- 分页 -->
    <div class="paginationClass">
      <el-button
        class="btn-next btn-first"
        @click="inquire()"
        :disabled="disabledPrevBtn"
        >第一页</el-button
      >
      <el-button
        icon="el-icon-arrow-left"
        class="btn-next btn-first"
        @click="currentPage_data('prev')"
        :disabled="disabledPrevBtn"
        >上一页</el-button
      >
      <div class="currentPage-class">{{ currentPage }}</div>
      <el-button
        class="btn-next btn-first"
        @click="currentPage_data('next')"
        :disabled="disabledNextBtn"
        >下一页
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </div>
    <!-- 查看历史变更 -->
    <el-dialog
      width="1360px"
      title="历史变更"
      :visible.sync="changeVisible"
      class="historical"
      @close="closeHistoricalDialog"
    >
      <template slot="title">
        <i
          class="el-icon-data-analysis dialog-title-my"
          style="color: #1296db"
        ></i>
        <span class="dialog-title-my my-title">历史变更</span>
      </template>
      <div class="changeClass tableChange">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          border
          size="mini"
          highlight-current-row
          @current-change="handleCurrentChange"
          ref="singleTable"
          v-loading="historLoading"
        >
          <el-table-column
            type="index"
            prop
            label="编号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="clientNumber"
            label="坐席"
            min-width="100"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="gmtCreated"
            label="时间"
            min-width="150"
            align="left"
          ></el-table-column>
        </el-table>
      </div>
      <div class="changeClass formChange" v-show="historicalShow">
        <el-form label-position="right">
          <el-form-item
            label="客户分类："
            :label-width="formWidth"
            class="flagImg"
          >
            <el-radio-group v-model="disabledFlagList">
              <el-radio
                v-for="item in sipFlagSettingList"
                :key="item.id"
                :label="item.flag"
                v-show="flagShow['flag_' + item.id]"
                disabled
              >
                <img :src="item.flagSrc" width="18px" />{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-for="item in sipQuestionList"
            :key="item.id"
            :label="item.question"
            :label-width="formWidth"
          >
            <el-radio-group
              v-if="item.questionType == 'RADIO'"
              v-model="options[item.id]"
            >
              <el-radio
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-radio
              >
            </el-radio-group>

            <el-input
              v-if="item.questionType == 'INPUT'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'DISTRICT_SELECTOR'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'CAR_BRAND_SELECTOR'"
              v-model="options[item.id]"
              :placeholder="'请输入' + item.question"
              class="question_input"
              disabled
            ></el-input>

            <el-input
              v-if="item.questionType == 'TEXT'"
              v-model="options[item.id]"
              :rows="8"
              type="textarea"
              resize="none"
              ref="remark"
              :placeholder="'请输入' + item.question"
              disabled
            ></el-input>

            <el-checkbox-group
              v-if="item.questionType == 'CHECKBOX'"
              v-model="optionsCheckList"
            >
              <el-checkbox
                v-for="muen in item.optionList"
                :key="muen.id"
                :label="muen.id"
                disabled
                >{{ muen.option }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="historicalColse">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 播放录音 -->
    <el-dialog
      class="customDialog"
      :visible.sync="playRecordShow"
      width="300px"
      @close="closeDialog"
    >
      <audio
        ref="audio"
        :src="currentSrc"
        autoplay="autoplay"
        controlsList="nodownload"
        controls="controls"
      ></audio>
    </el-dialog>
    <!-- 确认加入黑名单 -->
    <el-dialog
      title="提示"
      :visible.sync="outerVisible"
      width="422px"
      class="addBlacklistClass"
    >
      <span>
        <i class="el-icon-warning warningClass"></i>
        <span style="padding: 0px 32px">是否将该客户加入黑名单</span>
      </span>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="addBlacklist_submit"
          >确 定</el-button
        >
        <el-button size="small" @click="addBlacklist_cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 回拨等待 -->
    <el-dialog
      :visible.sync="loadingBox"
      width="300px"
      @close="callBackWaitclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="download-tips"
    >
      <div class="download-div">
        <i class="el-icon-loading loadingClass"></i>
        <span class="download-text">{{ callBackWaitText }}</span>
      </div>
    </el-dialog>
    <!-- 回音测试等待 -->
    <el-dialog
      :visible.sync="echoTestShow"
      width="300px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="download-tips"
    >
      <div class="download-div">
        <i class="el-icon-loading loadingClass"></i>
        <span class="download-text">正在呼叫中...</span>
      </div>
    </el-dialog>
    <!-- 添加微信 -->
    <el-dialog
      width="500px"
      title="添加微信"
      :visible.sync="wechatVisible"
      @close="wechatClose"
    >
      <div class="wechat_content">
        <div>微信：</div>
        <div style="position: relative">
          <el-input
            v-model="wechatInput"
            placeholder="请输入微信号或手机号"
            style="width: 180px"
            clearable
          ></el-input>
          <div class="wechat_cx" style="line-height: 25px">
            <span v-if="wechatStatus === 'CREATING'" class="wechat_cx_w"
              >请求发送中{{ errorMessage }}</span
            >
            <span v-else-if="wechatStatus === 'NOT_FOUND'" class="wechat_cx_e"
              >未查询到好友{{ errorMessage }}</span
            >
            <span v-else-if="wechatStatus === 'SUBMITTED'" class="wechat_cx_w"
              >已提交好友申请{{ errorMessage }}</span
            >
            <span
              v-else-if="wechatStatus === 'SUBMIT_FAILURE'"
              class="wechat_cx_e"
              >好友申请异常{{ errorMessage }}</span
            >
            <span v-else-if="wechatStatus === 'ADDEDFRIEND'" class="wechat_cx_s"
              >已经是好友{{ errorMessage }}</span
            >
            <span v-else-if="wechatStatus === 'PASSED'" class="wechat_cx_s"
              >通过好友申请{{ errorMessage }}</span
            >
            <!-- <span v-else-if="wechatStatus === 'REJECTED'" class="wechat_cx_e">拒绝好友申请</span> -->
            <span v-else-if="wechatStatus === 'EXPIRED'" class="wechat_cx_e"
              >好友申请已过期{{ errorMessage }}</span
            >
            <span v-else-if="wechatStatus === 'NO_REQUEST'" class="wechat_cx_w"
              >未加微信{{ errorMessage }}</span
            >
            <span v-else-if="wechatStatus === 'error'" class="wechat_cx_w"
              >未知{{ errorMessage }}</span
            >
          </div>
        </div>
        <div class="wechat_content_btn">
          <el-button
            type="primary"
            size="medium"
            @click="addWechatItem"
            :loading="addWechatBtnLoading"
            :disabled="isAddWechatDisabled"
            >添加</el-button
          >
          <el-button
            :disabled="!wechatStatusBtnShow"
            type="primary"
            size="medium"
            @click="addWechatStatus('statusClick')"
            >查询状态</el-button
          >
        </div>
      </div>
      <div style="line-height: 25px">微信昵称： {{ nickName }}</div>
      <div style="line-height: 25px">客服昵称： {{ clientNickName }}</div>
      <div style="line-height: 25px">状态：</div>
    </el-dialog>
    <!-- 微信添加等待 -->
    <el-dialog
      :visible.sync="addWxLoading"
      width="300px"
      @close="addWxClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="download-tips"
    >
      <div class="download-div">
        <i class="el-icon-loading loadingClass"></i>
        <span class="download-text">微信验证中...</span>
      </div>
    </el-dialog>
    <!-- 发送短信 -->
    <el-dialog
      width="450px"
      title="发送短信"
      :visible.sync="sendSMSShow"
      @close="sendSMSClose"
    >
      <el-form label-position="left" label-width="130px" class="el-form">
        <el-form-item label="客户编号：">
          <span style="font-weight: bold">{{ customerCode }}</span>
        </el-form-item>
        <el-form-item label="短信长链分组：" required>
          <el-select v-model="smsLongUrlGroup" placeholder="请选择短信长链分组">
            <el-option
              v-for="item in smsLongUrlGroupList"
              :key="item.id"
              :label="item.groupName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-tag :type="hangupSmsStatus.color" style="small">{{
            hangupSmsStatus.status
          }}</el-tag>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button
          type="primary"
          @click="sendSMS_submit"
          :disabled="senfSMSDisabled"
          v-if="hangupSmsStatus.status === '未发送'"
          >确 定</el-button
        >
        <el-button @click="sendSMSShow = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="shouldNotice"
      width="500px"
      title="红线提醒"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div style="height: 150px">
        <div style="text-indent: 2em">
          {{ shouldNoticeContent }}
        </div>
        <div style="color: #ff000085; text-align: center; margin-top: 50px">
          {{ displaySecond }}秒后关闭
        </div>
      </div>
    </el-dialog>
    <!-- 悬浮按钮 -->
    <!-- <el-button class="suspension_btn">修复通话</el-button> -->
    <el-dropdown trigger="click" class="suspension_drop">
      <el-button
        icon="el-icon-setting"
        circle
        class="suspension_btn"
      ></el-button>
      <el-dropdown-menu slot="dropdown">
        <el-button type="text" @click="repairCall" class="suspension_item_btn"
          >修复通话中状态</el-button
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import "../../assets/icon-font/iconfont.css";
import areaSelect from "../pages/areaselect.vue";
import vehicleSelect from "../pages/vehicleSelect.vue";
// import "@/components/js/sip-0.5.0.js";
export default {
  inject: ["reload"],
  props: ["uuid", "inWhitelist"],
  components: {
    "area-select": areaSelect,
    "vehicle-select": vehicleSelect,
  },
  data() {
    return {
      smsLongUrlGroup: "",
      smsLongUrlGroupList: [],
      senfSMSDisabled: false,
      hangupSmsStatus: {
        status: "",
        color: "",
      },
      showSendSmsButton: false,
      sendSMSShow: false,
      errorMessage: "",
      addWechatBtnLoading: false,
      clientNickName: "",
      nickName: "",
      wechatStatusBtnShow: false,
      addWecahtTimer: null,
      addWxLoading: false,
      isAddwechatShow: false,
      wechatStatus: "",
      wechatInput: "",
      wechatInputCopy: "",
      wechatVisible: false,
      prologueImgShow: false,
      mianHeight: 0,
      echoTestShow: false,
      otherSound: false,
      recordNameList: [],
      echoTestWaitText: "正在呼叫...",
      callBackWaitText: "正在呼叫...",
      loadingBox: false,
      loading: false,
      detailedAddress: "",
      echoShow: false,
      outerVisible: false,
      isReconnect: false,
      recordShow: {},
      playRecordShow: false,
      currentSrc: null, //录音url
      playFlag: true, //播放状态
      optionsCheckList: [],
      draftShow: false,
      imgCreated: {},
      historicalShow: false,
      options: {},
      sipQuestionList: [],
      disabledFlagList: "",
      tableData: null,
      historLoading: false,
      changeVisible: false,
      submitText: "提交",
      queryMobileShow: {},
      flagLabel: {
        1: "成单",
        2: "回拨",
        3: "非目标客群",
        4: "无意向",
        5: "异议处理",
      },
      callBackShow: {},
      containerDisabled: false,
      callFlag: {},
      flagShow: {},
      sipFlagSettingList: [],
      flagsNumber: [],
      flagsFilter: [
        {
          src: require("@/assets/img/flag_1.png"),
          label: "成单",
          id: "1",
        },
        {
          src: require("@/assets/img/flag_2.png"),
          label: "回拨",
          id: "2",
        },
        {
          src: require("@/assets/img/flag_3.png"),
          label: "非目标客群",
          id: "3",
        },
        {
          src: require("@/assets/img/flag_4.png"),
          label: "无意向",
          id: "4",
        },
        {
          src: require("@/assets/img/flag_5.png"),
          label: "异议处理",
          id: "5",
        },
      ],
      flagData: {},
      flagList: "",
      isIntention: false,
      taskStatus: "",
      taskName: "",
      activeCalleeNumber: "",
      statusVisible: false,
      currentIndex: "", //防止重复点击值
      projectId: 1,
      answerData: {
        callId: "",
        taskId: "",
      },
      projectQuestionList: [],
      optionId: {},
      optionCheckList: {},
      optionCheckListTmp: [],
      ua: null,
      // uuid: "",
      uaConfig: {
        authorizationUser: "",
        password: "",
        uri: "",
        displayName: "",
        wsServers: "",
      },
      condition: {
        callSecondsStart: "",
        callSecondsEnd: "",
        flags: "",
      },
      list: [],
      index: 1,
      formWidth: "150px",
      isActive: false, //选中的列的id
      total: null,
      currentPage: 1,
      status: "",
      type: "",
      onLine: "onLine",
      offLine: "offLine",
      onLineShow: false,
      offLineShow: false,
      mainShow: true,
      phoneShow: {},
      number: "",
      dailyReceived: "",
      onLineDis: false,
      offLineDis: false,
      activeCalleeIndex: "",
      socket: null,
      numbers: 1,
      flag: "",
      callbackTimes: "",
      questionListId: {},
      customerCode: "",
      batchNumber: "",
      clientNumber: "",
      callerUuid: "",
      draftId: "",
      idList: [],
      idLength: "",
      notice: "",
      noticeShow: false,
      onSubmitDis: false,
      userName: "",
      tab: "newList",
      calleeNumber: "",
      userLoginName: "",
      flagPitchDisabled: false,
      carInformation: "",
      shouldNotice: false,
      shouldNoticeContent: "",
      timer: "",
      displaySecond: 0,
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-客服";
    let main = document.getElementById("app");
    this.mianHeight = main.offsetHeight - 140;
    window.onresize = () => {
      //监听窗口变化
      let app = document.getElementById("app");
      this.mianHeight = app.offsetHeight - 140;
    };
    this.getUserName();
    this.openWebSocket();
    this.getNotice();
    if (this.inWhitelist) {
      this.getPrologueList();
    }
  },
  computed: {
    // 禁用上一页
    disabledPrevBtn() {
      if (this.currentPage === 1) {
        return true;
      }
      return false;
    },
    // 禁用下一页
    disabledNextBtn() {
      if (this.list.length < 6) {
        return true;
      }
      return false;
    },
    // 判断是否点击微信添加
    isAddWechat() {
      if (this.wechatInput === this.wechatInputCopy) {
        return true;
      } else {
        return false;
      }
    },
    // 微信添加按钮禁用
    isAddWechatDisabled() {
      let statusList = ["REJECTED", "SUBMIT_FAILURE", "NOT_FOUND", "EXPIRED"];
      let index = statusList.indexOf(this.wechatStatus);
      if (this.isAddWechat && index === -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 点击修复通话
    repairCall() {
      this.$confirm(
        "系统异常后，点击按钮会把'通话中'状态调整为'已注册'， 是否调整？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          cancelButtonClass: "btn-custom-cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$http("/sip/client/fixWrongStatus", null, "get").then((res) => {
            if (res.result == 200) {
              this.$message({ message: "操作成功", type: "success" });
              this.getUserName();
            }
          });
        })
        .catch(() => {});
    },
    // 请求通话记录对应表单状态
    getCallExtensionInfo(flag) {
      let data = {
        taskId: this.answerData.taskId,
        batchNumber: this.batchNumber,
        callId: this.answerData.callId,
        customerCode: this.customerCode,
      };
      this.$http("/sip/client/callExtensionInfo", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            if (flag && !res.data.successFlagChangeable) {
              // 客户分类确定后不能修改表单
              this.flagPitchDisabled = true;
            } else {
              this.flagPitchDisabled = false;
            }
            this.isAddwechatShow = res.data.addWechat;
            this.showSendSmsButton = res.data.showSendSmsButton;
          }
        }
      );
    },
    // 点击发送短信
    sendSMSClick() {
      this.sendSMSShow = true;
      this.sendSMSStatus();
    },
    // 查询发送状态
    sendSMSStatus() {
      let data = {
        callerUuid: this.callerUuid,
        batchNumber: this.batchNumber,
        userLoginName: this.userLoginName,
      };
      this.$http(
        "/sip/client/clientTalkingHangupSmsStatus",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          this.smsLongUrlGroupList = res.data.longUrlGroupList;
          if (res.data.defaultLongUrlGroupId) {
            this.smsLongUrlGroup = res.data.defaultLongUrlGroupId;
          }
          switch (res.data.status) {
            case "WAIT_SUBMIT":
              this.hangupSmsStatus.status = "等待提交运营商";
              this.hangupSmsStatus.color = "warning";
              break;
            case "SUBMITTING":
              this.hangupSmsStatus.status = "提交中";
              this.hangupSmsStatus.color = "warning";
              break;
            case "SUBMIT_SUCCESS":
              this.hangupSmsStatus.status = "提交成功";
              this.hangupSmsStatus.color = "success";
              break;
            case "SUBMIT_FAILURE":
              this.hangupSmsStatus.status = "提交失败";
              this.hangupSmsStatus.color = "danger";
              break;
            case "SUCCESS":
              this.hangupSmsStatus.status = "发送成功";
              this.hangupSmsStatus.color = "success";
              break;
            case "FAILURE":
              this.hangupSmsStatus.status = "发送失败";
              this.hangupSmsStatus.color = "danger";
              break;
            case "UNKNOWN":
              this.hangupSmsStatus.status = "未知";
              this.hangupSmsStatus.color = "info";
              break;
            case "NOT_SEND":
              this.hangupSmsStatus.status = "未发送";
              this.hangupSmsStatus.color = "";
              break;
            default:
              this.hangupSmsStatus.status = "未知";
              this.hangupSmsStatus.color = "info";
              break;
          }
        }
      });
    },
    // 确定发送短信
    sendSMS_submit() {
      let data = {
        callerUuid: this.callerUuid,
        longUrlGroupId: this.smsLongUrlGroup,
      };
      if (!data.longUrlGroupId) {
        this.$message.warning("请选择短信长链分组");
        return;
      }
      this.senfSMSDisabled = true;
      this.$service("/sip/client/sendClientTalkingHangupSms", data)
        .then((res) => {
          this.senfSMSDisabled = false;
          if (res.result === 200) {
            this.sendSMSShow = false;
            this.$message.success("发送成功");
          }
        })
        .catch((err) => {
          this.senfSMSDisabled = false;
        });
    },
    // 关闭发送短信
    sendSMSClose() {
      this.hangupSmsStatus = {
        status: "",
        color: "",
      };
      this.senfSMSDisabled = false;
      this.smsLongUrlGroup = "";
      this.smsLongUrlGroupList = [];
    },
    // 点击添加微信
    addWechatStatus(type) {
      this.wechatVisible = true;
      let data = {
        callerUuid: this.callerUuid,
      };
      this.getCustomerInfo(data, type);
    },
    // 关闭添加微信
    wechatClose() {
      this.wechatInput = "";
      this.clientNickName = "";
      this.nickName = "";
      this.wechatStatus = "";
      this.wechatInputCopy = "";
      this.wechatStatusBtnShow = false;
    },
    // 查询微信信息
    getCustomerInfo(data, sign) {
      this.$http("/wechat/getCustomerInfo", null, "get", null, data).then(
        (res) => {
          if (res.result === 200) {
            let obj = res.data;
            if (obj) {
              this.wechatStatusBtnShow = true;
              this.wechatInput = obj.submitAccount;
              this.wechatInputCopy = obj.submitAccount;
              this.wechatStatus = obj.status;
              this.clientNickName = obj.clientNickName;
              this.nickName = obj.nickName;
              this.errorMessage = "";
              if (obj.errorMessage) {
                this.errorMessage = "(" + obj.errorMessage + ")";
              }
              switch (obj.status) {
                case "CREATING":
                  obj.statuslabel = "请求发送中";
                  break;
                case "NOT_FOUND":
                  obj.statuslabel = "未查询到好友";
                  break;
                case "SUBMITTED":
                  obj.statuslabel = "已提交好友申请";
                  break;
                case "ADDEDFRIEND":
                  obj.statuslabel = "已经是好友";
                  break;
                case "SUBMIT_FAILURE":
                  obj.statuslabel = "好友申请异常";
                  break;
                case "EXPIRED":
                  obj.statuslabel = "好友申请已过期";
                  break;
                case "NO_REQUEST":
                  obj.statuslabel = "未加微信";
                  break;
                // case 'REJECTED':
                //   obj.statuslabel = '拒绝好友申请';
                //   break;
                case "PASSED":
                  obj.statuslabel = "通过好友申请";
                  this.wechatStatusBtnShow = false;
                  break;
                default:
                  obj.statuslabel = "未知";
                  this.wechatStatus = "error";
                  break;
              }
              if (sign === "statusClick") {
                // let statusList = [
                //   'CREATING',
                //   'NOT_FOUND',
                //   'SUBMITTED',
                //   'SUBMIT_FAILURE',
                //   'ADDEDFRIEND',
                //   // 'REJECTED',
                //   'EXPIRED',
                //   'error'
                // ]
                // let index = statusList.indexOf(obj.status);
                // if (index !== -1) {
                //   this.$message(obj.statuslabel);
                // }
                this.$message.success("查询成功");
              }
              if (obj.status !== "SUBMITTED") {
                if (this.addWecahtTimer) {
                  clearInterval(this.addWecahtTimer);
                  this.addWecahtTimer = null;
                  this.addWxLoading = false;
                }
              }
            } else {
              this.wechatInput = this.customerCode;
            }
          }
        }
      );
    },
    // 请求十次微信状态
    getTenCustomerInfo() {
      let data = {
        callerUuid: this.callerUuid,
      };
      let num = 1;
      this.addWecahtTimer = null;
      this.addWecahtTimer = setInterval(() => {
        if (num > 10) {
          clearInterval(this.addWecahtTimer);
          this.addWecahtTimer = null;
          this.addWxLoading = false;
          return;
        }
        num++;
        this.getCustomerInfo(data);
      }, 1000);
    },
    // 关闭微信添加等待
    addWxClose() {
      if (this.addWecahtTimer) {
        clearInterval(this.addWecahtTimer);
        this.addWecahtTimer = null;
      }
    },
    // 点击添加微信信息
    addWechatItem() {
      let data = {
        callerUuid: this.callerUuid,
        batchNumber: this.batchNumber,
        clientNumber: this.clientNumber,
        customerCode: this.customerCode,
        submitAccount: this.wechatInput,
        userLoginName: this.userLoginName,
        taskId: this.answerData.taskId,
      };
      this.addWechatBtnLoading = true;
      this.$service("/wechat/addFans", data)
        .then((res) => {
          this.addWechatBtnLoading = false;
          if (res.result === 200) {
            // this.wechatVisible = false;
            this.addWxLoading = true;
            this.getTenCustomerInfo();
          }
        })
        .catch((err) => {
          this.addWechatBtnLoading = false;
        });
    },
    // 获取汽车信息
    setCarDetails(val) {
      let carInfo = this.getCacheObject("car_" + this.isActive);
      if (carInfo) {
        this.draftIdHold();
        this.carInformation = val;
      }
    },
    // 获取子组件汽车信息
    getCarDetails(val) {
      this.carInformation = val;
      this.setCacheObject(
        "car_" + this.isActive,
        this.carInformation,
        3 * 60 * 60
      );
    },
    // 查询录音文件
    getPrologueList() {
      let data = {
        pageNo: 1,
        pageSize: 10,
      };
      this.$http("/prologue/list", null, "get", null, data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.isPlay = false;
          });
          this.recordNameList = list;
        }
      });
    },
    // 播放开场白
    playSound(val, index) {
      let data = {
        id: val.id,
      };
      if (this.otherSound) {
        // 只能播放一个录音文件
        return;
      }
      this.$service("/prologue/playPrologue", data).then((res) => {
        this.otherSound = true;
        if (res.result === 200) {
          this.recordNameList.forEach((item) => {
            if (item.id === val.id) {
              this.$set(this.recordNameList[index], "isPlay", true);
            }
          });
        }
      });
    },
    // 关闭开场白
    pauseSound(val, index) {
      this.$service("/prologue/stopPlayPrologue").then((res) => {
        this.otherSound = false;
        if (res.result === 200) {
          this.recordNameList.forEach((item) => {
            if (item.id === val.id) {
              this.$set(this.recordNameList[index], "isPlay", false);
            }
          });
        }
      });
    },
    // 关闭回拨等待
    callBackWaitclose() {
      this.loadingBox = false;
      this.loading = false;
    },
    // 获得地址
    obtainAddress(val) {
      let address = this.getCacheObject("address_" + this.isActive);
      if (address) {
        this.draftIdHold();
        this.detailedAddress = val;
      }
    },
    //获取子组件地址
    getArea(val) {
      // this.draftIdHold();
      this.detailedAddress = val;
      this.setCacheObject(
        "address_" + this.isActive,
        this.detailedAddress,
        3 * 60 * 60
      );
    },
    echoTest() {
      //回音测试
      this.echoTestShow = true;
      this.$http("/sip/call/echoTest", null)
        .then((res) => {
          this.echoTestShow = false;
          if (res.result == 200) {
            this.$message({ message: "呼叫成功", type: "success" });
          }
        })
        .catch((err) => {
          this.echoTestShow = false;
        });
    },
    getUserName() {
      //获取登录坐席
      this.$http("/user/userInfo", null).then((res) => {
        if (res.result == 200) {
          this.userName = res.data.userName;
          this.inquire();
        }
      });
    },
    getNotice() {
      //获取公告
      this.$http("/sip/project/getCurrentNotice").then((res) => {
        if (res.result == 200) {
          if (res.data) {
            this.notice = res.data;
            this.noticeShow = true;
          } else {
            this.notice = "";
            this.noticeShow = false;
          }
        }
      });

      this.$http("/sip/client/redLineNotice").then((res) => {
        if (res.result == 200) {
          this.shouldNoticeContent = res.data.content;
          this.shouldNotice = res.data.shouldNotice;
          this.displaySecond = res.data.displaySecond;
          this.timer = setInterval(() => {
            if (this.displaySecond == 0) {
              this.shouldNotice = false;
              clearInterval(this.timer);
              this.timer = null;
            } else {
              this.displaySecond--;
            }
          }, 1000);
        }
      });
    },
    otherOperations(command) {
      //点击其它操作
      if (command == "Blacklist") {
        this.outerVisible = true;
      }
    },
    addBlacklist_submit() {
      //确认加入黑名单
      let data = {
        customerCode: this.customerCode,
        sources: "教育",
      };
      this.$http("/blacklist/add", data).then((res) => {
        if (res.result == 200) {
          this.$message({ message: "加入黑名单成功", type: "success" });
          this.addBlacklist_cancel();
        }
      });
    },
    addBlacklist_cancel() {
      //取消加入黑名单
      this.outerVisible = false;
    },
    inquire() {
      //查询左边数据
      this.tab = "newList";
      let params = {
        pageSize: 6,
        pageNo: 1,
        callSecondsStart: this.condition.callSecondsStart,
        callSecondsEnd: this.condition.callSecondsEnd,
        flags: this.condition.flags,
        queryCallbackList: true,
      };
      this.currentPage = 1;
      this.inquireCallList(params);
    },
    inquireCallList(params) {
      //查询callList
      this.$http("/sip/client/callList", null, "get", null, params).then(
        (res) => {
          if (res.result == 200) {
            let draftIds = this.getCacheObject("draftId_" + this.userName);
            if (draftIds && draftIds.length > 0) {
              draftIds.map((k, i) => {
                let answerResult = this.getCacheObject("draft_" + k);
                let flags = this.getCacheObject("draftFlag_" + k);
                let address = this.getCacheObject("address_" + k);
                let carInfo = this.getCacheObject("car_" + k);
                if (!answerResult && !flags && !address && !carInfo) {
                  draftIds.splice(i, 1);
                }
              });
              this.setCacheObject(
                "draftId_" + this.userName,
                draftIds,
                3 * 60 * 60
              );
              this.idLength = "(" + draftIds.length + ")";
              this.idList = draftIds;
            } else {
              this.idLength = "";
            }
            let list = res.data.list;
            this.total = res.data.count;
            this.mainShow = true;
            list.map((item) => {
              item.activeCalleeNumber = item.id.toString();
              item.displayNumber = item.customerCode;
              if (item.callSeconds == -1) {
                this.recordShow["record_" + item.id] = false;
                item.callSeconds = "";
                item.callEndAt = "";
              } else {
                this.recordShow["record_" + item.id] = true;
                item.callEndAt = this.$common.transformTime(item.callEndAt);
                item.callEndAt = item.callEndAt.replace(/\-/g, "/");
                item.callEndAt = item.callEndAt.substring(5);
                item.callSeconds = Math.round(item.callSeconds);
                item.callSeconds = item.callSeconds + " 秒";
              }
              item.customerAttribution = "";
              if (item.customerProvince && item.customerCity) {
                item.customerAttribution =
                  item.customerProvince + " " + item.customerCity;
                item.carInformation =
                  item.customerProvince + " " + item.customerCity;
                if (item.customerProvince === item.customerCity) {
                  item.carInformation = item.customerProvince;
                }
              }
              if (item.mobileVisible == true && item.flag == 1) {
                this.queryMobileShow["queryMobile_" + item.id] = true;
              }
              if (item.flag) {
                this.imgCreated[item.id] = true;
                item.flagsList = item.flag.toString();
              } else {
                this.imgCreated[item.id] = false;
              }
              if (item.flag == "2") {
                this.callBackShow["callBack_" + item.id] = true;
              }
              if (item.callStatus == "TALKING") {
                this.phoneShow["phone_show_" + item.id] = true;
              } else {
                this.phoneShow["phone_show_" + item.id] = false;
              }
            });
            if (list && list.length > 0) {
              this.activeCalleeNumber = list[0].activeCalleeNumber;
              this.can(list[0]);
            } else {
              this.mainShow = false;
            }

            this.list = list;
          }
        }
      );
    },
    inquireRegist() {
      //查询坐席状态
      this.$http("/sip/client/regist", null, "get", null, null).then((res) => {
        if (res.result == 200) {
          let status = res.data.status;
          if (res.data.taskName) {
            this.taskName = res.data.taskName;
          }
          if (res.data.playPrologue) {
            this.prologueImgShow = true;
          } else {
            this.prologueImgShow = false;
          }
          if (res.data.taskStatus) {
            switch (res.data.taskStatus) {
              case "CALLING":
                res.data.newTaskStatus = "进行中";
                break;
              case "PAUSING":
                res.data.newTaskStatus = "暂停中";
                break;
              case "CREATED":
                res.data.newTaskStatus = "新建";
                break;
            }
            this.taskStatus = "(" + res.data.newTaskStatus + ")";
          }
          this.echoShow = false; //回音测试
          switch (status) {
            case "WAITING_TASK":
              this.status = "等待任务";
              this.type = "warning";
              this.onLineShow = false;
              this.offLineShow = true;
              break;
            case "USER_REGISTERED":
              this.status = "已注册";
              this.type = "success";
              this.onLineShow = true;
              this.offLineShow = false;
              this.echoShow = true;
              break;
            case "USER_NOT_REGISTERED":
              this.status = "未注册";
              this.type = "danger";
              this.onLineShow = false;
              this.offLineShow = false;
              break;
            case "DISABLED":
              this.status = "禁用";
              this.type = "info";
              this.onLineShow = false;
              this.offLineShow = false;
              break;
            case "TALKING":
              this.status = "通话中";
              this.type = "success";
              this.onLineShow = false;
              this.offLineShow = false;
              break;
            case "WRITING":
              this.status = "整理中";
              this.type = "warning";
              this.onLineShow = false;
              this.offLineShow = true;
              break;
          }
          this.statusVisible = true;
          this.dailyReceived = res.data.dailyReceived;
          this.number = res.data.number;
          this.uaConfig.authorizationUser = res.data.authorizationUser;
          this.uaConfig.password = res.data.password;
          this.uaConfig.uri = res.data.uri;
          this.uaConfig.displayName = res.data.displayName;
          this.uaConfig.wsServers = res.data.wsServers;
          let data = {
            uuid: this.uuid,
            type: "LOGIN",
            subUuid: this.getUuid(),
          };
          this.socket.send(JSON.stringify(data));
        }
      });
    },
    getUuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    setStatusOn() {
      //上线
      if (this.onLineDis) {
        return;
      }
      this.onLineDis = true;
      let params = {
        status: this.onLine,
      };
      this.$http("/sip/client/setStatus", null, "get", null, params)
        .then((res) => {
          if (res.result == 200) {
            this.onLineShow = false;
            this.offLineShow = true;
            this.onLineDis = false;
            this.$message({ message: "上线成功", type: "success" });
          }
        })
        .catch((err) => {
          this.onLineDis = false;
        });
    },
    setStatusOff() {
      //下线
      if (this.offLineDis) {
        return;
      }
      this.offLineDis = true;
      let params = {
        status: this.offLine,
      };
      this.$http("/sip/client/setStatus", null, "get", null, params)
        .then((res) => {
          if (res.result == 200) {
            this.onLineShow = true;
            this.offLineShow = false;
            this.offLineDis = false;
            this.$message({ message: "下线成功", type: "success" });
          }
        })
        .catch((err) => {
          this.offLineDis = false;
        });
    },
    currentPage_data(val) {
      //当前页
      if (val === "prev") {
        --this.currentPage;
      }
      if (val === "next") {
        ++this.currentPage;
      }
      if (this.tab == "newList") {
        let params = {
          pageSize: 6,
          pageNo: this.currentPage,
          callSecondsStart: this.condition.callSecondsStart,
          callSecondsEnd: this.condition.callSecondsEnd,
          flags: this.condition.flags,
          queryCallbackList: true,
        };
        this.inquireCallList(params);
      } else if (this.tab == "draft") {
        let draftIds = this.getCacheObject("draftId_" + this.userName);
        if (draftIds && draftIds.length > 0) {
          draftIds = draftIds.join(",");
          let params = {
            pageSize: 6,
            pageNo: this.currentPage,
            callSecondsStart: this.condition.callSecondsStart,
            callSecondsEnd: this.condition.callSecondsEnd,
            flags: this.condition.flags,
            queryCallbackList: true,
            callIds: draftIds,
          };
          this.inquireCallList(params);
        } else {
          this.total = 0;
          this.list = [];
        }
      }
    },
    // 判断客户分类是否禁用
    flagChangeableInfo(data, flag) {
      this.$http(
        "/sip/client/flagChangeableInfo",
        null,
        "get",
        null,
        data
      ).then((res) => {
        if (res.result === 200) {
          if (flag && !res.data.successFlagChangeable) {
            // 客户分类确定后不能修改表单
            this.flagPitchDisabled = true;
          } else {
            this.flagPitchDisabled = false;
          }
        }
      });
    },
    can(val) {
      //左边选中填充右边
      this.customerCode = val.customerCode;
      this.callerUuid = val.callerUuid;
      this.batchNumber = val.batchNumber;
      this.clientNumber = val.clientNumber;
      this.activeCalleeIndex = val.id.toString();
      this.userLoginName = val.userLoginName;
      this.answerData.taskId = val.taskId;
      this.answerData.callId = val.id;
      this.getCallExtensionInfo(val.flag);
      if (this.currentIndex == val.id) {
        //防止重复点击
        return;
      }
      this.calleeNumber = val.customerCode;
      this.draftShow = false;
      this.currentIndex = val.id;
      this.isActive = val.id;
      this.optionId = {};
      this.mainShow = true;
      this.answerData.projectId = val.projectId;
      this.callbackTimes = val.callbackTimes;
      this.projectQuestionWithCache(
        val.projectId,
        val.id,
        val.callbackTimes,
        val.callerUuid
      );
      let orgAnswerResult = this.getCacheObject("answer_" + val.id);
      let answerResult = this.getCacheObject("draft_" + val.id);
      let flags = this.getCacheObject("draftFlag_" + val.id);
      let address = this.getCacheObject("address_" + val.id);
      let carInfo = this.getCacheObject("car_" + val.id);
      if (answerResult || flags || address || carInfo) {
        this.draftShow = true;
      }
    },
    projectQuestionWithCache(projectId, id, callbackTimes, callerUuid) {
      //读取问题
      let key = "project_" + projectId;
      let projectResult = this.getCacheObject(key);
      let params = {
        projectId: projectId,
      };
      this.$http(
        "/sip/question/projectQuestionList",
        null,
        "get",
        null,
        params
      ).then((res) => {
        if (res.result == 200) {
          this.optionCheckList = {};
          this.projectQuestionList = res.data.sipQuestionList;
          if (this.projectQuestionList) {
            this.projectQuestionList.map((item) => {
              if (item.questionType == "CHECKBOX") {
                this.optionCheckList[item.id] = [];
              }
            });
          }
          if (res.data.sipFlagSettingList) {
            res.data.sipFlagSettingList.map((item) => {
              if (item.flag < 6) {
                item.flagSrc = require("@/assets/img/flag_" +
                  item.flag +
                  ".png");
                item.flag = item.flag.toString();
                switch (item.disabled) {
                  case true:
                    this.flagShow["flag_" + item.id] = false;
                    break;
                  case false:
                    this.flagShow["flag_" + item.id] = true;
                    break;
                }
              }
            });
          } else {
            res.data.sipFlagSettingList = [];
          }

          this.sipFlagSettingList = res.data.sipFlagSettingList;
          let projectQuestionList = this.projectQuestionList;
          if (projectQuestionList && projectQuestionList.length > 0) {
            this.setCacheObject(key, projectQuestionList, 60);
          }
          this.questionListId = {};
          if (projectQuestionList) {
            this.projectQuestionList.map((item) => {
              item.newQuestion = item.question + "：";
              if (item.optionList) {
                item.optionList.map((meun) => {
                  meun.id = meun.id.toString();
                });
              }
              this.questionListId[item.id] = item.questionType;
            });
          }
          this.loadAnswerWithCache(id, callbackTimes, callerUuid);
        }
      });
    },
    loadAnswerWithCache(id, callbackTimes, callerUuid) {
      //读取答案
      this.submitText = "提交";
      this.carInformation = "";
      let key = "answer_" + id;
      let optionId = [];
      let flagList = "";
      let optionIds = "";
      let answerResult = this.getCacheObject(key);
      let flags = this.getCacheObject("flag_" + id);
      let address = this.getCacheObject("address_" + id);
      let carInfo = this.getCacheObject("car_" + id);
      if (!address) {
        this.detailedAddress = "";
      }
      this.flag = flags;
      let loadParams = {
        callId: id,
        callerUuid: callerUuid,
        callbackTimes: callbackTimes,
        loginName: this.userLoginName,
      };
      this.containerDisabled = true;
      this.$http(
        "/sip/question/loadAnswer",
        null,
        "get",
        null,
        loadParams
      ).then((res) => {
        this.containerDisabled = false;
        if (res.result == 200) {
          let index = null;
          let loadAnswerList = res.data.list;
          flagList = "";
          this.flag = res.data.flag.toString();
          if (res.data.flag) {
            this.submitText = "修改";
            flagList = res.data.flag.toString();
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].id == loadParams.callId) {
                index = i;
              }
            }
            let call = this.list[index];
            call.flagsList = flagList;
            this.list[index] = call;
            this.$forceUpdate();
            this.setCacheObject("flag_" + id, flagList, 5 * 60);
          }
          this.flagList = flagList;

          this.flagData["flag_" + id] = flagList.split(",");
          this.flagData["flag_" + id].map((item) => {
            if (item == "2") {
              this.callBackShow["callBack_" + id] = true;
            }
          });
          optionId = [];
          if (loadAnswerList.length > 0) {
            loadAnswerList.map((item) => {
              if (item.answer) {
                this.submitText = "修改";
              }
              this.optionId[item.questionId] = item.answer;
              optionId = item.answer.split(",");
              if (this.questionListId[item.questionId] == "DISTRICT_SELECTOR") {
                this.detailedAddress = item.answer;
              }
              if (
                this.questionListId[item.questionId] == "CAR_BRAND_SELECTOR"
              ) {
                this.carInformation = item.answer;
              }
              if (this.questionListId[item.questionId] == "CHECKBOX") {
                this.optionCheckList[item.questionId] = item.answer.split(",");
              }
            });
            let md5 = JSON.stringify(this.optionId);
            md5 = this.$md5(md5);
            loadAnswerList.push(md5);
            this.setCacheObject(key, loadAnswerList, 5 * 60);
          } else {
            this.detailedAddress = "";
            this.list.forEach((item) => {
              if (this.currentIndex === item.id) {
                this.carInformation = item.carInformation;
              }
            });
            this.optionId = {};
            this.optionCheckList[id] = [];
          }

          let a = this.projectQuestionList;
          this.projectQuestionList = [];
          this.projectQuestionList = a;
        }
      });
    },
    setCacheObject(key, value, expireSeconds) {
      //储存缓存
      let cache = {
        data: value,
        expireSeconds: new Date().getTime() + expireSeconds * 1000,
      };
      sessionStorage.setItem(key, JSON.stringify(cache));
    },
    getCacheObject(key) {
      //读取缓存
      let cache = sessionStorage.getItem(key);
      if (!cache) {
        return null;
      }
      let cacheData = JSON.parse(cache);
      if (cacheData.expireSeconds < new Date().getTime()) {
        return null;
      }
      return cacheData.data;
    },
    draft() {
      //读取草稿
      let answerResult = this.getCacheObject("draft_" + this.isActive);
      let flags = this.getCacheObject("draftFlag_" + this.isActive);
      let address = this.getCacheObject("address_" + this.isActive);
      let carInfo = this.getCacheObject("car_" + this.isActive);
      if (flags) {
        this.flagList = flags;
        this.flag = flags;
      }
      if (address) {
        this.detailedAddress = address;
      } else {
        this.detailedAddress = "";
      }
      if (carInfo) {
        this.carInformation = carInfo;
      } else {
        // this.carInformation = '';
      }
      if (answerResult) {
        delete answerResult.md5;
        this.optionId = answerResult;
        for (let i in answerResult) {
          if (this.questionListId[i] == "CHECKBOX") {
            this.optionCheckList[i] = answerResult[i];
            if (typeof answerResult[i] == "string") {
              this.optionCheckList[i] = answerResult[i].split(",");
            }
          }
        }
      } else {
        this.optionId = {};
      }
    },
    draftIdHold() {
      //草稿id保存
      if (this.draftId != this.isActive) {
        this.idList.push(this.isActive);
      }
      this.draftId = this.isActive;
      this.idList = this.$common.unique(this.idList); //去重
      if (this.idList && this.idList.length > 0) {
        this.idLength = "(" + this.idList.length + ")";
      } else {
        this.idLength = "";
      }
      this.setCacheObject("draftId_" + this.userName, this.idList, 3 * 60 * 60);
    },
    getDraft() {
      //获取草稿记录条数
      this.tab = "draft";
      this.currentPage = 1;
      let draftIds = this.getCacheObject("draftId_" + this.userName);
      if (draftIds && draftIds.length > 0) {
        draftIds = draftIds.join(",");
        let params = {
          pageSize: 6,
          pageNo: 1,
          callSecondsStart: this.condition.callSecondsStart,
          callSecondsEnd: this.condition.callSecondsEnd,
          flags: this.condition.flags,
          queryCallbackList: true,
          callIds: draftIds,
        };
        this.inquireCallList(params);
      } else {
        this.total = 0;
        this.list = [];
      }
    },
    pitchOn(type, id, questionCode, optionList) {
      //选项改变
      this.draftIdHold();
      let answer = "";
      if (type === "CHECKBOX") {
        answer = this.optionCheckList[id];
        this.optionId[id] = answer;
      } else if (type === "INPUT" || type === "TEXT") {
        answer = this.optionId[id];
        this.optionId[id] = answer;
      } else {
        answer = this.optionId[id];
        this.optionId[id] = answer;
      }
      let newAnswerResult = this.optionId;
      let md5 = JSON.stringify(newAnswerResult);
      md5 = this.$md5(md5);
      newAnswerResult["md5"] = md5;
      this.setCacheObject(
        "draft_" + this.isActive,
        newAnswerResult,
        3 * 60 * 60
      );
      delete this.optionId.md5;
      let a = this.projectQuestionList;
      this.projectQuestionList = [];
      this.projectQuestionList = a;
    },
    flagPitchOn(val) {
      //旗帜选项改变
      this.draftIdHold();
      this.flag = val;
      this.setCacheObject("draftFlag_" + this.isActive, val, 3 * 60 * 60);
    },
    acquisition(val) {
      //请选择旗帜
      this.condition.flags = val.join(",");
    },
    onSubmit() {
      //保存答案
      this.onSubmitDis = true;
      let callbackTimes = null;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == this.currentIndex) {
          callbackTimes = this.list[i].callbackTimes;
        }
      }
      if (this.flag && callbackTimes == 0) {
        this.imgCreated[this.isActive] = true;
      } else {
        this.imgCreated[this.isActive] = false;
      }
      if (this.flag == "0") {
        this.imgCreated[this.isActive] = false;
      }
      for (let i in this.optionId) {
        if (typeof this.optionId[i] == "object") {
          this.optionId[i] = this.optionId[i].join(",");
        }
      }
      if (this.detailedAddress) {
        for (let i in this.questionListId) {
          if (this.questionListId[i] === "DISTRICT_SELECTOR") {
            this.optionId[i] = this.detailedAddress;
          }
        }
      }
      if (this.carInformation) {
        for (let i in this.questionListId) {
          if (this.questionListId[i] === "CAR_BRAND_SELECTOR") {
            this.optionId[i] = this.carInformation;
          }
        }
      }
      let optionId = JSON.stringify(this.optionId);
      let data = {
        taskId: this.answerData.taskId,
        callId: this.answerData.callId,
        projectId: this.answerData.projectId,
        flag: this.flag,
        callbackTimes: callbackTimes,
        data: optionId,
        loginName: this.userLoginName,
        callerUuid: this.callerUuid,
      };
      this.$confirm("是否继续" + this.submitText + "?", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          this.$http("/sip/question/updateAnswerBatch", data).then((res) => {
            this.onSubmitDis = false;
            if (res.result == 200) {
              let look = false;
              if (res.data) {
                if (res.data.mobileVisiable && this.flag == "1") {
                  this.queryMobileShow["queryMobile_" + this.isActive] = true;
                  look = true;
                } else {
                  this.queryMobileShow["queryMobile_" + this.isActive] = false;
                }
              } else {
                this.queryMobileShow["queryMobile_" + this.isActive] = false;
              }
              this.flagData["flag_" + this.answerData.callId] =
                this.flag.split(",");
              this.callBackShow["callBack_" + this.answerData.callId] = false;
              this.flagData["flag_" + this.answerData.callId].map((item) => {
                if (item == "2") {
                  this.callBackShow[
                    "callBack_" + this.answerData.callId
                  ] = true;
                }
              });
              let draftIds = this.getCacheObject("draftId_" + this.userName);
              if (draftIds && draftIds.length > 0) {
                draftIds.map((k, i) => {
                  if (k == data.callId) {
                    draftIds.splice(i, 1);
                  }
                });
                this.idList = draftIds;
                if (draftIds && draftIds.length > 0) {
                  this.idLength = "(" + draftIds.length + ")";
                } else {
                  this.idLength = "";
                }
                this.setCacheObject(
                  "draftId_" + this.userName,
                  draftIds,
                  3 * 60 * 60
                );
              }
              sessionStorage.removeItem("answer_" + data.callId); //清除缓存
              sessionStorage.removeItem("flag_" + data.callId); //清除缓存
              sessionStorage.removeItem("draft_" + data.callId); //清除缓存
              sessionStorage.removeItem("draftFlag_" + data.callId); //清除缓存
              sessionStorage.removeItem("address_" + data.callId); //清除缓存
              sessionStorage.removeItem("car_" + data.callId); //清除缓存
              this.draftShow = false;
              let a = this.projectQuestionList;
              this.projectQuestionList = [];
              this.projectQuestionList = a;
              let index = null;
              for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id == data.callId) {
                  index = i;
                }
              }
              let call = this.list[index];
              if (!look) {
                call.displayNumber = call.customerCode;
              }
              call.flagsList = this.flag.toString();
              call.flag = Number(this.flag);
              call.carInformation = this.carInformation;
              this.list[index] = call;
              this.can(this.list[index]);
              this.$forceUpdate();
              this.$message({ message: "保存成功", type: "success" });
            }
          });
        })
        .catch(() => {
          this.onSubmitDis = false;
          this.callBackShow["callBack_" + this.answerData.callId] = false;
        });
    },
    historicalChanges() {
      //查看历史变更
      this.changeVisible = true;
      let params = {
        callId: this.answerData.callId,
        pageNo: 1,
        pageSize: 6,
        clientNumber: this.number,
        callbackTimes: this.callbackTimes,
        loginName: this.userLoginName,
        callerUuid: this.callerUuid,
      };
      this.historLoading = true;
      this.$http("/sip/question/answerHistory", null, "get", null, params).then(
        (res) => {
          this.historLoading = false;
          if (res.result == 200) {
            let list = res.data;
            list.map((item) => {
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              if (item.sipQuestionList && item.sipQuestionList.length > 0) {
                item.sipQuestionList.map((menu) => {
                  menu.question = menu.question + "：";
                });
              }
            });
            this.tableData = list;
            this.setCurrent(this.tableData[0]);
          }
        }
      );
    },
    historicalColse() {
      //点击关闭查看历史变更
      this.changeVisible = false;
      this.historLoading = false;
    },
    setCurrent(row) {
      //选中的行
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      //选择历史
      let disabledFlagList = [];
      if (val) {
        this.options = {};
        this.historicalShow = true;
        this.disabledFlagList = "";
        this.optionsCheckList = [];
        let flags = JSON.parse(val.flags);
        if (flags) {
          for (let key in flags) {
            disabledFlagList.push(key);
          }
        }
        disabledFlagList = disabledFlagList.join(",");
        this.disabledFlagList = disabledFlagList.substring(0, 1);
        let sipQuestionList = val.sipQuestionList;
        if (sipQuestionList && sipQuestionList.length > 0) {
          sipQuestionList.map((item) => {
            if (item.optionList) {
              item.optionList.map((menu) => {
                menu.id = menu.id.toString();
              });
            }
          });
        }
        let answer = JSON.parse(val.answer);
        let optionsCheck = "";
        this.sipQuestionList = sipQuestionList;
        for (let i in answer) {
          optionsCheck += answer[i] + ",";
          for (let j in sipQuestionList) {
            if (i == sipQuestionList[j].id) {
              this.options[i] = answer[i];
            }
          }
        }
        this.optionsCheckList = optionsCheck.split(",");
      }
    },
    closeHistoricalDialog() {
      //关闭历史弹窗
      this.historicalShow = false;
      this.tableData = [];
    },
    openWebSocket() {
      //打开websocket
      let baseURL = this.$constants.baseURL;
      if (baseURL.indexOf("test") !== -1) {
        baseURL = baseURL.substring(8);
      } else {
        baseURL = baseURL.substring(8);
      }
      // 打开webSocket
      if (!window.WebSocket) {
        window.WebSocket = window.MozWebSocket;
      }
      if (process.env.VUE_APP_ENVIRONMENT === "prod") {
        this.socket = new WebSocket(
          "wss://" + baseURL + "/ws/clientHandler"
        );
      } else {
        this.socket = new WebSocket(
          "wss://huixiao.test.huiyu.org.cn/api/ws/clientHandler"
        );
      }

      this.socket.onmessage = (event) => {
        // 接收数据 event.data
        console.log(event);
        let msg = JSON.parse(event.data);
        let dailyReceived = this.dailyReceived;
        if (msg.eventType == "ERROR") {
          // this.$message({message:msg.message,type:'error'});
          this.$notify.error({
            title: "提示",
            message: msg.message,
            position: "bottom-right",
            duration: 0,
          });
        }
        if (msg.eventType == "TRY_LOGOUT") {
          //注销账号
          this.$http("/user/logout", null).then((res) => {
            if (res.result == 200) {
              this.$router.push("/");
              this.$notify.close();
            }
          });
        }
        if (msg.eventType == "PROLOGUE_CHANGED") {
          // 接收开启消息如果当前开场白模式已开启不再提示
          if (msg.playPrologue) {
            if (!this.prologueImgShow) {
              this.prologueImgShow = true;
              this.$notify({
                title: "提示",
                message: "开场白模式已开启",
                position: "top-right",
                type: "info",
                duration: 4500,
              });
            }
          } else {
            // 接收关闭消息如果当前开场白模式已关闭不再提示
            if (this.prologueImgShow) {
              this.prologueImgShow = false;
              this.$notify({
                title: "提示",
                message: "开场白模式已关闭",
                position: "top-right",
                type: "info",
                duration: 4500,
              });
            }
          }
        }
        if (msg.eventType == "TASK_FINISHED") {
          this.taskStatus = "(已结束)";
          this.prologueImgShow = false;
        }
        if (msg.eventType == "TASK_STARTED") {
          this.taskStatus = "(进行中)";
          this.prologueImgShow = msg.playPrologue;
        }
        if (msg.eventType == "PAUSING") {
          this.taskStatus = "(暂停中)";
          this.prologueImgShow = msg.playPrologue;
        }
        if (msg.eventType == "CALL_BACK_FAILURE") {
          this.loading = false;
          this.loadingBox = false;
          this.$message({ message: msg.message, type: "warning" });
        }
        if (msg.taskName) {
          this.taskName = msg.taskName;
        }
        if (msg.eventType == "BRIDGE") {
          this.draftShow = false;
          this.phoneShow["phone_show_" + msg.callId] = true;
          let customerAttribution = "";
          let carInformation = "";
          if (msg.customerProvince && msg.customerCity) {
            customerAttribution = msg.customerProvince + " " + msg.customerCity;
            carInformation = msg.customerProvince + " " + msg.customerCity;
            if (msg.customerProvince === msg.customerCity) {
              carInformation = msg.customerCity;
            }
          }
          if (msg.customerCode) {
            this.loading = false;
            this.loadingBox = false;
          }
          let customer = {
            // calleeNumber: msg.calleeNumber,
            displayNumber: msg.customerCode,
            callEndAt: "",
            id: msg.callId,
            taskId: msg.taskId,
            projectId: msg.projectId,
            activeCalleeNumber: msg.callId.toString(),
            customerAttribution: customerAttribution,
            mobileVisible: msg.mobileVisible,
            callbackTimes: msg.callbackTimes,
            customerCode: msg.customerCode,
            tailNumber: msg.tailNumber,
            userLoginName: msg.userLoginName,
            callerUuid: msg.callerUuid,
            batchNumber: msg.batchNumber,
            clientNumber: msg.clientNumber,
            addWechat: msg.addWechat,
            carInformation: carInformation,
          };
          this.userLoginName = msg.userLoginName;
          this.dailyReceived = dailyReceived + 1;
          this.list.unshift(customer);
          if (customer.mobileVisible == true) {
            // this.queryMobileShow['queryMobile_'+msg.callId] = true;
          }
          this.customerCode = this.list[0].customerCode;
          if (this.list.length == 1) {
            // 只有一个数据时指向第一个
            this.currentIndex = this.list[0].id;
            this.callerUuid = this.list[0].callerUuid;
            this.batchNumber = this.list[0].batchNumber;
            this.clientNumber = this.list[0].clientNumber;
            this.isAddwechatShow = this.list[0].addWechat;
            this.showSendSmsButton = this.list[0].showSendSmsButton;
            this.isActive = this.list[0].id;
            this.answerData.callId = customer.id;
            this.answerData.taskId = customer.taskId;
            this.answerData.projectId = customer.projectId;
            this.flagChangeableInfo(
              { callId: msg.callId, customerCode: msg.customerCode },
              ""
            );
            this.projectQuestionWithCache(
              this.list[0].projectId,
              this.list[0].id,
              msg.callbackTimes,
              msg.callerUuid
            );
          }
          if (this.activeCalleeIndex == this.list[1].activeCalleeNumber) {
            this.isActive = customer.id;
            this.activeCalleeIndex = customer.activeCalleeNumber;
          }
          if (this.list && this.list.length > 6) {
            this.list.splice(6, 1);
            let tmp = this.list;
            this.list = tmp;
          }
          if (this.list.length > 0 && this.currentIndex == this.list[1].id) {
            // 当前选中的是第一个时新电话进来指向第一个
            this.currentIndex = this.list[0].id;
            this.callerUuid = this.list[0].callerUuid;
            this.batchNumber = this.list[0].batchNumber;
            this.clientNumber = this.list[0].clientNumber;
            this.isAddwechatShow = this.list[0].addWechat;
            this.showSendSmsButton = this.list[0].showSendSmsButton;
            this.answerData.callId = customer.id;
            this.answerData.taskId = customer.taskId;
            this.answerData.projectId = customer.projectId;
            this.carInformation = carInformation; // 当前选中是第一个时，汽车区域默认当前区域
            this.flagChangeableInfo(
              { callId: msg.callId, customerCode: msg.customerCode },
              ""
            );
            this.projectQuestionWithCache(
              this.list[0].projectId,
              this.list[0].id,
              msg.callbackTimes,
              msg.callerUuid
            );
          }
          let answerResult = this.getCacheObject("draft_" + this.isActive);
          let flags = this.getCacheObject("draftFlag_" + this.isActive);
          let address = this.getCacheObject("address_" + this.isActive);
          let carInfo = this.getCacheObject("car_" + this.isActive);
          if (answerResult || flags || address || carInfo) {
            this.draftShow = true;
          }
        } else if (msg.eventType == "STATUS_CHANGED") {
          switch (msg.status) {
            case "WAITING_TASK":
              this.status = "等待任务";
              this.type = "warning";
              this.onLineShow = false;
              this.offLineShow = true;
              this.echoShow = false;
              break;
            case "USER_REGISTERED":
              this.status = "已注册";
              this.type = "success";
              this.onLineShow = true;
              this.offLineShow = false;
              this.echoShow = true;
              break;
            case "USER_NOT_REGISTERED":
              this.status = "未注册";
              this.type = "danger";
              this.onLineShow = false;
              this.offLineShow = false;
              this.echoShow = false;
              break;
            case "DISABLED":
              this.status = "禁用";
              this.type = "info";
              this.onLineShow = false;
              this.offLineShow = false;
              this.echoShow = false;
              break;
            case "TALKING":
              this.status = "通话中";
              this.type = "success";
              this.onLineShow = false;
              this.offLineShow = false;
              this.echoShow = false;
              break;
            case "WRITING":
              this.status = "整理中";
              this.type = "warning";
              this.onLineShow = false;
              this.offLineShow = true;
              this.echoShow = false;
              break;
          }
        } else if (msg.eventType == "UN_BRIDGE") {
          let index = null;
          this.recordShow["record_" + msg.callId] = true;
          this.phoneShow["phone_show_" + msg.callId] = false;
          this.activeCalleeNumber = this.activeCalleeIndex;
          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].id == msg.callId) {
              index = i;
            }
          }
          msg.date = msg.date.replace(/\-/g, "/");
          msg.callSeconds = Math.round(msg.callSeconds);
          let call = this.list[index];
          call.callEndAt = msg.date.substring(5);
          call.callSeconds = msg.callSeconds + " 秒";
          this.list[index] = call;
          this.$forceUpdate();
        }
      };
      this.socket.onopen = () => {
        this.numbers = 1;
        this.inquireRegist();
      };
      this.socket.onclose = () => {
        this.isReconnect = true;
      };
    },
    reconnect() {
      //刷新页面
      this.reload();
    },
    hangUp(callId) {
      //挂断电话
      let data = {
        callId: callId,
      };
      this.$confirm("挂断电话，是否继续?", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        type: "waring",
      })
        .then(() => {
          this.$http("/sip/forceClearing", data).then((res) => {
            if (res.result == 200) {
              this.phoneShow["phone_show_" + callId] = false;
              this.$message({ message: "挂断成功", type: "success" });
            }
          });
        })
        .catch(() => {});
    },
    callBack(val) {
      //回拨
      this.$confirm("回拨, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "btn-custom-cancel",
        type: "warning",
      })
        .then(() => {
          let params = {
            callId: val.id,
            loginName: val.userLoginName,
          };
          this.$http("/sip/client/callBack", null, "get", null, params).then(
            (res) => {
              if (res.result == 200) {
                this.$message({ message: res.data.data, type: "success" });
                this.loading = true;
                this.loadingBox = true;
                this.callBackWaitText = `正在呼叫 ${val.customerCode} ...`;
              }
            }
          );
        })
        .catch(() => {});
    },
    queryMobile(val) {
      //查看
      let params = {
        callId: val.id,
        customerCode: val.customerCode,
        loginName: val.userLoginName,
      };
      this.$http("/sip/call/queryMobile", null, "get", null, params).then(
        (res) => {
          if (res.result == 200) {
            let index = null;
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].id == params.callId) {
                index = i;
              }
            }
            let call = this.list[index];
            call.displayNumber = res.data;
            this.list[index] = call;
            this.$forceUpdate();
          }
        }
      );
    },
    playRecord(val) {
      //播放录音
      this.playRecordShow = true;
      if (val) {
        this.callId = val.id;
      }
      let callId = this.callId;
      this.currentSrc = `${this.$constants.baseURL}/sip/playVoice?callId=${callId}`;
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        // this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    closeDialog() {
      //播放录音关闭
      this.$refs.audio.pause();
      this.playFlag = false;
    },
  },
  beforeDestroy() {
    this.socket.close();
    this.socket = null;
    this.$notify.close();
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style scoped>
.wechat_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wechat_cx {
  width: 100%;
  position: absolute;
  padding: 5px 0;
}
.wechat_cx .wechat_cx_s {
  color: #67c23a;
  position: absolute;
  top: 50px;
  left: 5px;
}
.wechat_cx .wechat_cx_w {
  color: #e6a23c;
  position: absolute;
  top: 50px;
  left: 5px;
}
.wechat_cx .wechat_cx_e {
  color: #f56c6c;
  position: absolute;
  top: 50px;
  left: 5px;
}
.kaicb_img {
  width: 25px;
  height: 25px;
  display: inline-block;
}
.error-close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  z-index: 9999;
  color: #ffffff;
}
#elDialog {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 108%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.3);
}
#elDialog .loagingClass {
  position: absolute;
  top: 20%;
  left: 36%;
  width: 335px;
  height: 120px;
  border-radius: 10px;
}
.loagingClass >>> .el-loading-mask {
  border-radius: 10px;
}
.loagingClass >>> .el-loading-spinner {
  margin-top: -33px;
  text-align: left;
  left: 8%;
}
.loagingClass >>> .el-loading-spinner i {
  color: #ffffff;
}
.loagingClass >>> .el-icon-loading {
  font-size: 60px;
}
.loagingClass >>> .el-loading-text {
  color: #ffffff;
  display: inline-block;
  position: absolute;
  top: 17px;
  left: 80px;
  width: 200px;
  font-size: 18px;
}
.notice-class {
  float: right;
  /* position: absolute;
    top: 0;
    left: 800px; */
  width: 370px;
}
.el-dropdown {
  margin-left: 10px;
}
.addBlacklistClass >>> .el-dialog {
  border-radius: 4px;
}
.addBlacklistClass >>> .el-dialog__header {
  padding: 15px 15px 10px;
}
.addBlacklistClass >>> .el-dialog__body {
  padding: 10px 15px;
  position: relative;
}
.addBlacklistClass >>> .el-dialog__footer {
  padding: 5px 15px 10px 15px;
}
.addBlacklistClass .warningClass {
  position: absolute;
  font-size: 24px;
  color: #f52424;
  top: 7px;
}
/* .el-main{
    overflow: auto !important;
  } */
.historical >>> .el-dialog__body {
  position: relative;
}
.dialog-footer {
  clear: both;
}
.changeClass {
  display: inline-block;
  width: 48%;
}
.tableChange {
  float: left;
  margin-bottom: 20px;
}
.formChange {
  float: right;
}
.formChange .el-form-item {
  margin-bottom: 2px;
}
.tag-time .el-button + .el-button {
  margin: 0;
}
.isAbsolute {
  position: absolute;
  top: 49px;
  left: 136px;
}
.flagsImg {
  display: inline-block;
}
.flagsImg img {
  padding-top: 8px;
}
.flagImg .el-checkbox {
  margin-right: 20px;
}
.container-style img {
  width: 18px;
}
.nav {
  width: 100%;
  margin-bottom: 5px;
}
.client .clientNumber,
.task .taskName {
  font-weight: bold;
  font-size: 20px;
}
.task,
.client {
  min-width: 280px;
  display: inline-block;
  margin-right: 20px;
}
.client .el-button {
  margin-left: 10px;
}
.receive {
  height: 35px;
  vertical-align: middle;
  position: relative;
  height: 145px;
}
.receive .sound-file {
  position: absolute;
  top: 0px;
  left: 415px;
  font-size: 16px;
}
.sound-file-div {
  display: inline-block;
  margin-right: 10px;
}
.sound-file-div .icon-sound {
  display: inline-block;
  font-size: 18px;
}
.cursor-m {
  cursor: pointer;
}
.disabledColor {
  color: #c0c4cc !important;
  cursor: not-allowed;
}
.call-startTime,
.call-endTime {
  width: 90px !important;
}
.callSeconds {
  margin: 10px 0;
  position: relative;
}
.callSeconds .wechat_btn {
  position: absolute;
  top: 0px;
  left: 357px;
}
.callSeconds .el-select {
  width: 195px;
}
.client .el-tag {
  margin-left: 15px;
}

.setStatus {
  display: inline-block;
  font-size: 12px;
  margin-left: 5px;
}

.setStatus a {
  color: #409eff;
}

.number {
  width: 300px;
  margin-bottom: 5px;
}

.nav .el-input {
  width: 217px;
}

.number >>> .el-input {
  width: 60%;
}

.nick >>> .el-input {
  width: 37%;
}

.number >>> .el-input__inner {
  height: 30px;
  margin-top: 4px;
}

.el-aside {
  width: 307px !important;
  height: 515px;
  padding: 0px 0px 20px 20px;
  margin-bottom: 20px;
  overflow: hidden;
  border-right: 1px solid #dcdfe6;
  position: relative;
}
.suspension_drop {
  position: fixed;
  bottom: 45px;
  right: 60px;
}
.suspension_btn {
  font-size: 25px;
  padding: 6px;
}
.el-dropdown-menu .el-button + .el-button {
  margin: 0;
}
.el-dropdown-menu .el-button:hover {
  background: #ecf5ff;
}
.suspension_item_btn {
  display: block;
  padding: 12px 10px;
}
.el-container {
  overflow: hidden;
}
.customService {
  position: relative;
  min-height: 781px;
}
.customService:after {
  display: block;
  content: "";
  clear: both;
}
.paginationClass {
  position: relative;
  display: flex;
  justify-content: flex-end;
  bottom: -13px;
  left: 0px;
  width: 300px;
}
.paginationClass .currentPage-class {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  color: #606266;
  padding: 0 8px;
}
.paginationClass .btn-next {
  padding: 0;
  margin: 0 5px;
  background-color: #f5f7fa;
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
  background-size: 16px;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
  box-sizing: border-box;
  font-size: 13px;
  border: none;
}
.paginationClass .btn-first {
  font-size: 12px;
  padding: 0 10px;
}
.item-class {
  width: 301px;
  border-right: 1px solid #dcdfe6;
}
/* .el-main {
    padding: 0px 0px 20px 20px;
    overflow: hidden;
  } */

.tag-style {
  position: relative;
  width: 300px;
  /* height: 85px; */
  text-align: left;
  font-size: 23px;
  border-bottom: 1px solid #dcdfe6;
  margin-bottom: -1px;
}

.tag-style span {
  font-weight: bold;
  display: block;
  height: 25px;
  margin-bottom: 5px;
  display: block;
  width: 200px;
}
.tag-style .calleeNumber {
  position: relative;
  top: 10px;
  left: 8px;
}
.tag-style .attribution {
  font-weight: normal;
  font-size: 15px;
  margin-top: 26px;
  width: 130px;
  display: inline-block;
  padding-left: 8px;
}
.tag-style .tailNumber {
  font-weight: normal;
  font-size: 15px;
  width: 40px;
  display: inline-block;
}
.tag-time {
  position: absolute;
  width: 110px;
  font-size: 14px;
  left: 182px;
  top: 8px;
  text-align: right;
}
.tag-time p {
  display: block;
  margin: 0;
  width: 110px;
  height: 25px;
}
.tag-time .el-button {
  padding: 0;
}
.tab-callee >>> .el-tabs__item {
  height: 87px;
}

.active {
  background-color: #ecf5ff;
}

.container-style {
  position: absolute;
  left: 300px;
  top: 180px;
  width: 840px;
  height: 600px;
  overflow: auto;
}

.age .el-input {
  width: 135px;
}

.remark .el-textarea {
  width: 95%;
}
.container-style .el-textarea {
  width: 95%;
}
.container-style .el-form-item {
  margin-bottom: 2px;
}

.holdButton {
  width: 70px;
  margin-left: 80px;
}

.icon-waihurenwu {
  position: absolute;
  right: 113px;
  top: 17px;
  color: #f00;
}

#ua {
  border: 1px solid #dcdfe6;
  width: 22%;
  border-radius: 10px;
  padding-right: 5%;
  text-align: right;
}

.ua-uri >>> .el-input {
  width: 48%;
}

.el-radio-group {
  margin-right: 10px;
}

.el-radio-group >>> .el-radio__label {
  padding-left: 5px;
}

.el-checkbox {
  margin-right: 10px;
}

.el-input {
  width: 30%;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
.question_input {
  width: 100%;
}
</style>

<style>
.btn-custom-cancel {
  float: right;
  margin-left: 10px !important;
}
</style>
