<template>
  <div class="payCountStatistics">
    <div class="nav">
      <div class="batchName">
        日期：
        <el-date-picker
          v-model="abnormalGmtCreatedDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="daterange"
          unlink-panels
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
        ></el-date-picker>
      </div>
    </div>
    <el-button type="primary" @click="inquire(1)">查询</el-button>
    <el-table
      :data="payCountTableData"
      stripe
      style="width: 100%"
      border
      v-loading="loadingTable"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="date"
        label="日期"
        min-width="100"
        align="left"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop
        :width="flexColumnWidth(item.label)"
        align="left"
        :label="item.label"
        v-for="(item, key) in productTypeList"
        :key="key"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div v-for="(val, key) in scope.row.mapCount" :key="key">
            <el-tooltip content="Top center" placement="top">
              <div slot="content">
                当日: {{ scope.row.mapProduct[key] }} | 当月：{{
                  scope.row.countProduct[key]
                }}
              </div>
              <el-button type="text" v-show="item.label == key">{{
                val
              }}</el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop label="操作" width="90" align="center" fixed="right">
        <template slot-scope="scope">
          
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      :current-page.sync="pageNo"
      :page-size="10"
      @size-change="sizeChange"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "payCountStatistics",
  props: ["roleMenu", "loginName"],
  data() {
    return {
      abnormalGmtCreatedDate: [],
      payCountTableData: [],
      loadingTable: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      productTypeList: [],
      pickerOptions: {
        disabledDate(time) {
          const start = new Date();
          start.setHours(23);
          start.setMinutes(59);
          start.setSeconds(59);
          start.setMilliseconds(59);
          return time.getTime() > start;
        },
        shortcuts: [
          {
            text: "本月记录",
            onClick(picker) {
              let start = new Date();
              start.setDate(1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              let end = new Date();
              end.setDate(1);
              end.setHours(0);
              end.setMinutes(0);
              end.setSeconds(0);
              end.setMilliseconds(0);
              let m = end.getMonth() + 1;
              end.setMonth(m);
              end = end - 1000;
              picker.$emit("pick", [start, new Date(end)]);
            },
          },
          {
            text: "本周记录",
            onClick(picker) {
              let start = sessionStorage.getItem("thisWeek");
              start = parseInt(start);
              const end = start + 7 * 24 * 3600 * 1000 - 1000;
              picker.$emit("pick", [new Date(start), new Date(end)]);
            },
          },
          {
            text: "本日记录",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              end.setMilliseconds(59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    let end = new Date(),
      start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(59);
    start = this.$common.transformTime(start.valueOf());
    end = this.$common.transformTime(end.valueOf());
    this.abnormalGmtCreatedDate = [start, end];
    this.inquire(1);
    this.getLabelList();
    this.theWeek();
  },
  methods: {
    // 点击查询
    inquire(index) {
      if (index) {
        this.pageNo = index;
      }
      let gmtCreatedStart = "";
      let gmtCreatedEnd = "";
      if (this.abnormalGmtCreatedDate) {
        gmtCreatedStart = this.abnormalGmtCreatedDate[0].substring(0, 10);
        gmtCreatedEnd = this.abnormalGmtCreatedDate[1].substring(0, 10);
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        dateStart: gmtCreatedStart,
        datedEnd: gmtCreatedEnd,
      };
      this.getWeChatProduct(data);
    },
    // 翻页
    currentPage_data(index) {
      this.inquire(index);
    },
    // 每页
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(1);
    },
    // 请求成单量数据
    getWeChatProduct(data) {
      this.payCountTableData = [];
      this.loadingTable = true;
      this.$service("/wechat/tab/findWeChatProductPayCount", data).then(
        (res) => {
          this.total = 0;
          this.loadingTable = false;
          if (res.result === 200) {
            if (res.data.data) {
              let list = res.data.data.records;
              list.forEach((item) => {
                item.date = this.$common
                  .transformTime(item.date)
                  .substring(0, 10);
                item.mapCount = {};
                for (let i in item.countProduct) {
                  item.mapCount[i] = "0 / " + item.countProduct[i];
                  for (let j in item.mapProduct) {
                    if (i === j) {
                      item.mapCount[i] =
                        item.mapProduct[j] + " / " + item.countProduct[i];
                    }
                  }
                }
              });
              this.payCountTableData = list;
              this.total = res.data.data.total;
            }
          }
        }
      ).catch(err => {
        this.loadingTable = false;
      })
    },
    // 请求产品列表
    getLabelList() {
      this.$http("wechat/labelList", null, "get", null, {
        category: "LESSON",
      }).then((res) => {
        if (res.result === 200) {
          this.productTypeList = res.data;
        }
      });
    },
    flexColumnWidth(val) {
      //表格列宽
      let flexWidth = null;
      let widthList = [];
      flexWidth = this.$common.calculatedLength(val);
      if (flexWidth < 100) {
        // 最小值100
        flexWidth = 100;
      }
      widthList.push(flexWidth);
      widthList.sort((a, b) => b - a);
      return widthList[0] + 20 + "px";
    },
    // 每周
    theWeek() {
      this.$http("/user/thisWeek", null).then((res) => {
        if (res.result == 200) {
          let start = res.data;
          sessionStorage.setItem("thisWeek", start);
        }
      });
    },
  },
};
</script>
<style scoped>
.el-table {
  margin: 20px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.button-center {
  text-align: center;
}
.nav .companyName,
.nav .callTime,
.nav .batchName {
  display: inline-block;
  margin-bottom: 20px;
}
.nav .callTime .el-date-editor {
  width: 260px;
}
.nav .companyName .el-input,
.nav .callTime .el-input,
.nav .batchName .el-input {
  width: 260px;
  margin-right: 20px;
}
.nav .batchName .el-select {
  width: 260px;
  margin-right: 20px;
}
.el-addBindshow .el-input,
.el-addBindshow .el-select {
  width: 80%;
}
.titlt-show {
  font-size: 14px;
  color: #e6a23c;
  line-height: 18px;
}
</style>