<template>
	<div class="personalCenter">	
		<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="select" active-text-color='#3BC1C4'>
			  <el-menu-item index="1">基本信息</el-menu-item>
			  <el-menu-item index="2" >账户余额</el-menu-item>
			  <el-menu-item index="3" disabled>线路管理</el-menu-item>
		</el-menu>
		
		<!-- 基本信息 -->
		<div class='basicInfo' v-show="show">
			<img src="@/assets/img/download.jpg" alt="图片">
			<br>
			<span><b>用户名称：</b><i>{{userName}}</i></span>
			<span><b>登录名称：</b><i>{{loginName}}</i></span>
			<el-button type="primary" size='mini' @click='changePassword=true'>修改密码</el-button>			
		</div>
		
		<!-- 账户余额 -->
		<div class="accountBalance" v-show="!show">
			<span><b>账户余额：</b><i>{{balance}}</i></span>
		</div>
		
		<!-- 修改密码 -->
		<el-dialog :visible.sync="changePassword" custom-class='el-changePassword' width = "450px" >
				<div slot="title" class="dialog-title" >
					<h3>修改密码</h3>
				</div>	
				<el-form :model="form">
						<el-form-item label="" :label-width="formWidth" >
								<b>原密码<i></i>：</b>
								<el-input type='password' v-model="form.password" placeholder="请输入原密码" ></el-input>
						</el-form-item>
						<el-form-item label="" :label-width="formWidth">
								<b>新密码<i></i>：</b>
								<el-input type='password' v-model="form.newPassword" placeholder='请输入新密码'></el-input>
						</el-form-item>				 
						<el-form-item label="" :label-width="formWidth">
								<b>确认密码：</b>
								<el-input type='password' v-model="form.confirmPassword"  placeholder='请再次输入新密码'></el-input>
						</el-form-item>
			</el-form>	  
			<div slot="footer" class="dialog-footer" align="center">
					<el-button type="primary" @click="changePassword_submit()">提 交</el-button>
					<el-button @click="changePassword_cancel()" >取 消</el-button>					
			</div>
		</el-dialog>	
		
		
	</div>
</template>

<script>
	export default {
		name:'PersonalCenter',
		data(){
			return{
				msg:'个人中心',
				userName:'',
				loginName:'',
				balance:'',
				activeIndex:'1',
				
				changePassword:false,
				form:{
					password:'',
					newPassword:'',
					confirmPassword:''
				},
				formWidth:'30px',
				
				show:true,
		
			}
		},
		mounted(){		
			this.userInfo();			
		},
		methods:{
			userInfo(){// 用户信息
				this.$http('/user/userInfo',{}).then(res=>{
					if(res.result == 200){
							this.balance = (res.data.balance /100).toFixed(2) + '元';
							this.loginName = res.data.loginName;
							this.userName = res.data.userName;							
					}		
				});
			},
			select(index) {//选中
				if(index == '1'){
					this.show = true;	
				}else {
					this.show = false;	
					this.userInfo();
				}
		  },
		 changePassword_submit(){//修改密码
				if(this.form.password!==''&& this.form.newPassword!==''&& this.form.newPassword ==this.form.confirmPassword){
						let data = {
									password: this.form.password,
									newPassword: this.form.newPassword
						};
						this.$http('/user/resetPassword',data).then(res=>{						
								if(res.result == 200){
									this.$message({ message:'密码修改成功！' ,type: 'success'});
									this.changePassword_cancel();
								}
							});
				}else {
					this.$message({message:'内容填写错误，请重新填写！' ,type: 'warning'});
				}		
		  },
			changePassword_cancel(){//取消修改			
				this.form = {
					password:'',
					newPassword:'',
					confirmPassword:''
				}
				this.changePassword = false;
			}
		}
	}
</script>

<style scoped="scoped">
	.personalCenter{
		min-height: 760px;
		box-shadow: 0px 0px 1px 1px lightgray;
		margin-bottom: 30px; 
	}
	.basicInfo,.accountBalance{
		padding: 20px;
	}
	.basicInfo img{
		width: 100px;
		margin-bottom: 20px;
	}
	span {
		display: block;
		margin-bottom: 20px;
	}
	 span b{
		color: rgb(101, 113, 128);
		font-size: 14px;
		font-weight: 700;
	}
	span i{
		font-style: normal;
		font-size: 14px;
		margin-left: 30px;
	}
	.el-dialog .el-input,.el-dialog .el-select{
		width: 250px;
	}
	.el-dialog b{
		color:#657180 ;
		font-size: 17px;
	}
	.el-dialog b i{
		font-style: normal;
		color: lightseagreen;
		margin-left: 16px;
	}
	.dialog-footer .el-button {
		width: 120px;
	}
</style>
