<template>
  <div class="call">
    <div class="nav">
      <div class="callTime">
        完成时间：
        <el-date-picker
          class="startTime"
          v-model="condition.callStartAtStart"
          :clearable="false"
          type="datetime"
          placeholder="起始时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
        -
        <el-date-picker
          class="endTime"
          v-model="condition.callStartAtEnd"
          :clearable="false"
          type="datetime"
          placeholder="终止时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </div>
      <div class="calleeNumber">
        客户编号：
        <el-input
          v-model="condition.customerCode"
          placeholder="请输入客户编号"
        ></el-input>
      </div>
      <div class="operate">
        <el-button type="primary" @click="inquire(1)">查询</el-button>
        <el-button type="success" @click="reset()">重置</el-button>
      </div>
    </div>
    <!-- 回拨记录表 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      border
      v-loading="loading"
    >
      <el-table-column
        type="index"
        prop
        label="编号"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="customerCode"
        label="客户编号"
        width="130"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="clientNumber"
        label="坐席编号"
        width="130"
        align="left"
      ></el-table-column>
      <el-table-column
        prop="ringSeconds"
        label="响铃时长"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callSeconds"
        label="通话时长"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="distributeSeconds"
        label="转接时长"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="connected"
        label="是否接通"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="distributed"
        label="是否转接"
        width
        align="center"
      ></el-table-column>
      <el-table-column
        prop="fee"
        label="费用"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="callEndAt"
        label="完成时间"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column prop label="通话状态" width align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.callStatus }}</span>
          <i
            v-show="scope.row.callStatus == '通话中'"
            class="el-icon-loading"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
        prop
        label="操作"
        width="90"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            size="small"
            @click="cancel(scope.row)"
            :disabled="scope.row.forceClearingDisabled"
          >强拆</el-button> -->
          <!-- <el-button
            type="text"
            size="small"
            @click="listen(scope.row)"
            :disabled="scope.row.monitorDisabled"
          >监听</el-button> -->
          <el-button
            type="text"
            size="small"
            @click="playRecord(scope.row)"
            :disabled="scope.row.playVoiceDisabled"
            >播放录音</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="currentPage_data"
      @size-change="sizeChange"
      :current-page.sync="currentPage"
      :page-size="10"
      :page-sizes="[10, 20, 30, 40]"
      layout="sizes, total, prev, pager, next,jumper"
      :total="total"
      :pager-count="5"
    ></el-pagination>
    <!-- 播放录音 -->
    <el-dialog
      class="customDialog"
      :visible.sync="playRecordShow"
      width="300px"
      @close="closeDialog"
    >
      <audio
        ref="audio"
        :src="currentSrc"
        autoplay="autoplay"
        controlsList="nodownload"
        controls="controls"
      ></audio>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "call",
  props: ["server"],
  data() {
    return {
      loading: false,
      pageSize: 10,
      callId: "",
      tableData: null,
      currentPage: null,
      total: null,
      callStatus_show: false,
      cancelShow: false,
      playRecordShow: false,
      currentSrc: null, //录音url
      playFlag: true, //播放状态
      projectNameList: [
        {
          id: "",
          projectName: "全部",
        },
      ],
      taskNameList: [
        {
          id: "",
          taskName: "全部",
        },
      ],
      condition: {
        taskId: "",
        projectId: "",
        customerCode: "",
        clientNumber: "",
        callStartAtStart: "",
        callStartAtEnd: "",
        callStatus: [],
      },
      callStatusList: [
        {
          callStatus: "",
          label: "全部",
        },
        {
          callStatus: "RINGING",
          label: "响铃中",
        },
        {
          callStatus: "USER_BUSY",
          label: "用户拒接",
        },
        {
          callStatus: "NO_ANSWER",
          label: "无人接听",
        },
        {
          callStatus: "ORIGINATOR_CANCEL",
          label: "拨打取消",
        },
        {
          callStatus: "TALKING",
          label: "通话中",
        },
        {
          callStatus: "NORMAL_CLEARING",
          label: "拨打完成",
        },
        {
          callStatus: "SYSTEM_SHUTDOWN",
          label: "系统挂断",
        },
        {
          callStatus: "NO_USER_RESPONSE",
          label: "线路未响应",
        },
        {
          callStatus: "USER_NOT_REGISTERED",
          label: "坐席未注册",
        },
        {
          callStatus: "WAITING_OUTCALL",
          label: "等待拨打",
        },
        {
          callStatus: "CALLING",
          label: "拨打中",
        },
        {
          callStatus: "TIMEOUT_CLEARING",
          label: "转接超时",
        },
        {
          callStatus: "PARK",
          label: "等待转接",
        },
        {
          callStatus: "UNALLOCATED_NUMBER",
          label: "未分配号码",
        },
        {
          callStatus: "NORMAL_TEMPORARY_FAILURE",
          label: "异常",
        },
        {
          callStatus: "OUT_OF_FEE",
          label: "欠费",
        },
        {
          callStatus: "RECOVERY_ON_TIMER_EXPIRE",
          label: "拨打超限",
        },
        {
          callStatus: "MEDIA_TIMEOUT",
          label: "超时",
        },
        {
          callStatus: "BLACKLIST",
          label: "黑名单",
        },
      ],
    };
  },
  mounted() {
    document.title = "惠销平台管理系统-回拨记录";
    let strDate = new Date();
    let endDate = new Date();
    strDate.setHours(0);
    strDate.setMinutes(0);
    strDate.setSeconds(0);
    strDate.setMilliseconds(0);
    let strTime = strDate.getTime() - 6 * 24 * 60 * 60 * 1000;
    strDate = this.$common.transformTime(strTime);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(59);
    endDate = this.$common.transformTime(endDate.valueOf());
    this.condition.callStartAtStart = strDate;
    this.condition.callStartAtEnd = endDate;
    this.inquire(1);
  },
  methods: {
    //当前页
    currentPage_data(index) {
      this.inquire(index);
    },
    //显示条数
    sizeChange(index) {
      this.pageSize = index;
      this.inquire(index);
    },
    //点击强拆
    cancel(val) {
      if (val) {
        this.callId = val.id;
      }
      this.$confirm("是否继续强拆?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            callId: this.callId,
          };
          this.$http("/sip/forceClearing", data).then((res) => {
            if (res.result == 200) {
              this.$message({ message: "强拆成功", type: "success" });
              let currentPage = this.currentPage;
              this.inquire(currentPage);
            }
          });
        })
        .catch(() => {});
    },
    //监听
    listen(val) {
      console.log(val);
    },
    //播放录音
    playRecord(val) {
      this.playRecordShow = true;
      if (val) {
        this.callId = val.id;
      }
      let callId = this.callId;
      this.currentSrc = `${this.$constants.baseURL}/sip/playVoice?callId=${callId}`;
      let lastRunTime = Date.now(),
        currentTime = Date.now(),
        protectTime = 100;
      if (currentTime - lastRunTime < protectTime) {
        return; //两次执行太过频繁，直接退出
      }
      if (this.playFlag == false) {
        // this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.playFlag = true;
      }
    },
    //播放录音关闭
    closeDialog() {
      this.$refs.audio.pause();
      this.playFlag = false;
    },
    //点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let callStatus = this.condition.callStatus.join(",");
      let params = {
        pageSize: this.pageSize,
        pageNo: this.currentPage,
        customerCode: this.condition.customerCode,
        callStartAtStart: this.condition.callStartAtStart,
        callStartAtEnd: this.condition.callStartAtEnd,
        queryCallbackList: true,
      };
      this.inquireList(params);
    },
    //查询回拨记录
    inquireList(params) {
      this.loading = true;
      let path = "https://" + this.server + this.$constants.basePath;
      this.$http("/sip/call/listCallbackInfo", null, "get", path, params).then(
        (res) => {
          setTimeout(() => {
            this.loading = false;
          }, 200);
          if (res.result == 200) {
            let list = res.data.list;
            this.total = res.data.count;
            list.map((item) => {
              //数据转换
              item.callEndAt = this.$common.transformTime(item.callEndAt);
              item.callAnsweredAt = this.$common.transformTime(
                item.callAnsweredAt
              );
              item.callReceivedAt = this.$common.transformTime(
                item.callReceivedAt
              );
              if (item.callSeconds == -1) {
                item.callSeconds = "-";
              } else {
                item.callSeconds = `${item.callSeconds} 秒`;
              }
              if (item.ringSeconds == -1) {
                item.ringSeconds = "-";
              } else {
                item.ringSeconds = `${item.ringSeconds} 秒`;
              }
              if (item.distributeSeconds == -1) {
                item.distributeSeconds = "-";
              } else {
                item.distributeSeconds = `${item.distributeSeconds} 秒`;
              }
              item.forceClearingDisabled = true;
              item.playVoiceDisabled = true;
              item.monitorDisabled = true;
              switch (item.callStatus) {
                case "CONNECTED":
                  item.callStatus = "接通";
                  item.playVoiceDisabled = false;
                  break;
                case "NO_ANSWER":
                  item.callStatus = "无人接听";
                  break;
                case "USER_BUSY":
                  item.callStatus = "占线";
                  break;
                case "EXCEPTION":
                  item.callStatus = "无法接通";
                  break;
                // case "RINGING":
                //   item.callStatus = "响铃中";
                //   break;
                // case "UNALLOCATED_NUMBER":
                //   item.callStatus = "未分配号码";
                //   break;
                // case "USER_BUSY":
                //   item.callStatus = "用户拒接";
                //   break;
                // case "NO_ANSWER":
                //   item.callStatus = "无人接听";
                //   break;
                // case "ORIGINATOR_CANCEL":
                //   item.callStatus = "拨打取消";
                //   break;
                // case "TALKING":
                //   item.callStatus = "通话中";
                //   item.forceClearingDisabled = false
                //   item.monitorDisabled = false
                //   break;
                // case "NORMAL_CLEARING":
                //   item.callStatus = "拨打完成";
                //   item.playVoiceDisabled = false;
                //   break;
                // case "SYSTEM_SHUTDOWN":
                //   item.callStatus = "系统挂断";
                //   break;
                // case "NO_USER_RESPONSE":
                //   item.callStatus = "线路未响应";
                //   break;
                // case "NO_ROUTE_DESTINATION":
                //   item.callStatus = "线路未响应";
                //   break;
                // case "SUBSCRIBER_ABSENT":
                //   item.callStatus = "线路未响应";
                //   break;
                // case "MEDIA_TIMEOUT":
                //   item.callStatus = "超时";
                //   break;
                // case "USER_NOT_REGISTERED":
                //   item.callStatus = "坐席未注册";
                //   break;
                // case "WAITING_OUTCALL":
                //   item.callStatus = "等待拨打";
                //   break;
                // case "CALLING":
                //   item.callStatus = "拨打中";
                //   break;
                // case "TIMEOUT_CLEARING":
                //   item.callStatus = "转接超时";

                //   break;
                // case "PARK":
                //   item.callStatus = "等待转接";
                //   break;
                // case "NORMAL_TEMPORARY_FAILURE":
                //   item.callStatus = "异常";
                //   break;
                // case "EXCEPTION":
                //   item.callStatus = "异常";
                //   break;
                // case "OUT_OF_FEE":
                //   item.callStatus = "欠费";
                //   break;
                // case "RECOVERY_ON_TIMER_EXPIRE":
                //   item.callStatus = "拨打超限";
                //   break;
                // case "BLACKLIST":
                //   item.callStatus = "黑名单";
                //   break;
              }
              switch (item.distributed) {
                case true:
                  item.distributed = "是";
                  break;
                case false:
                  item.distributed = "否";
                  break;
              }
              switch (item.connected) {
                case true:
                  item.connected = "是";
                  break;
                case false:
                  item.connected = "否";
                  break;
              }
            });
            this.tableData = list;
          }
        }
      );
    },
    //重置
    reset() {
      this.condition = {
        customerCode: "",
        clientNumber: "",
        callStartAtStart: "",
        callStartAtEnd: "",
        callStatus: [],
      };
      this.inquire(1);
    },
    //查询项目名称
    inquireProject() {
      let params = {
        pageSize: 1000,
        pageNo: 1,
      };
      this.$http("/sip/project/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newProjectNameList = res.data.list;
          newProjectNameList.map((item) => {
            this.projectNameList.push(item);
          });
        }
      });
    },
    //查询任务名称
    inquireTask() {
      let params = {
        pageSize: 1000,
        pageNo: 1,
      };
      this.$http("/sip/task/list", null, "get", null, params).then((res) => {
        if (res.result == 200) {
          let newTaskNameList = res.data.list;
          newTaskNameList.map((item) => {
            this.taskNameList.push(item);
          });
        }
      });
    },
    //项目名称获取焦点
    acquisition() {
      this.inquireProject();
      this.projectNameList = [];
      let obj = {
        id: "",
        projectName: "全部",
      };
      this.projectNameList.push(obj);
    },
    //任务名称获取焦点
    acquisitionTask() {
      this.inquireTask();
      this.taskNameList = [];
      let obj = {
        id: "",
        taskName: "全部",
      };
      this.taskNameList.push(obj);
    },
  },
};
</script>

<style scoped="scoped">
.call {
  position: relative;
  margin-bottom: 50px;
}
.el-table {
  margin: 30px 0px;
}
.el-pagination {
  float: right;
  text-align: right;
  margin-bottom: 20px;
}
.nav {
  width: 1060px;
  margin-bottom: 20px;
}
.callerNumber,
.calleeNumber,
.callSecondsTime,
.callTime,
.callStatus,
.projectStatus,
.taskStatus {
  width: 530px;
  margin-bottom: 20px;
}
.nav .el-input,
.nav .el-select {
  width: 430px;
}
.callTime,
.calleeNumber {
  float: right;
  text-align: left;
}
.callSecondsTime .call-startTime {
  width: 200px !important;
  margin-right: 6px;
}
.callSecondsTime .call-endTime {
  width: 200px !important;
  margin-left: 7px;
}
.callTime .startTime {
  width: 200px !important;
  margin-right: 6px;
}
.callTime .endTime {
  width: 200px !important;
  margin-left: 7px;
}
.customDialog >>> .el-dialog {
  border-radius: 50px;
  background: transparent;
}
.customDialog >>> .el-dialog__header {
  padding: 0px;
}
.customDialog >>> .el-dialog__body {
  padding: 0px;
  font-size: 0px;
}
</style>
